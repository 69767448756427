import React from 'react';
import {
  IconBrowse,
  IconBuy,
  IconGetPaid,
  IconPostIt,
  IconShipIt,
  IconVerify,
} from '../../components';
import { getDefaultBuyerSellerInfo, getDefaultTradeInInfo } from '../../shopConfig/shops/default';
import CustomIcon from '../../components/CustomIcon/CustomIcon';
import { HowItWorksCustomImagesType } from '../../types/contentful/imagesConfig';
import {
  BuyerInfo,
  BuyerSellerInfo,
  SellerInfo,
  TradeInInfoType,
} from '../../types/shopConfig/shopConfigV2';

export const ABOUT_PAGES_CONTENT_WIDTH = '1128px';

// Grep codebase for references to `about/:tabName` before changing strings.
export enum AboutPageTab {
  Info = 'info',
  Buyer = 'buyer',
  Seller = 'seller',
  TradeIn = 'tradeIn',
  Support = 'support',
  AboutTreet = 'aboutTreet',
  Protection = 'protection',
}

export interface AboutNavBarTab {
  text: string;
  tab: AboutPageTab;
}

type BuyerSellerContent = {
  image?: React.ReactNode;
  title: string;
  description: string;
};

const defaultIcons = {
  [BuyerInfo.Browse]: <IconBrowse key={BuyerInfo.Browse} />,
  [BuyerInfo.Buy]: <IconBuy key={BuyerInfo.Buy} />,
  [BuyerInfo.Verify]: <IconVerify key={BuyerInfo.Verify} />,
  [SellerInfo.PostIt]: <IconPostIt key={SellerInfo.PostIt} />,
  [SellerInfo.ShipIt]: <IconShipIt key={SellerInfo.ShipIt} />,
  [SellerInfo.GetPaid]: <IconGetPaid key={SellerInfo.GetPaid} />,
  [TradeInInfoType.Submit]: <IconPostIt key={TradeInInfoType.Submit} />,
  [TradeInInfoType.Ship]: <IconShipIt key={TradeInInfoType.Ship} />,
  [TradeInInfoType.Earn]: <IconGetPaid key={TradeInInfoType.Earn} />,
};

type iconTitles = BuyerInfo | SellerInfo | TradeInInfoType;

type IconCollectionType = Partial<{ [iconType in iconTitles]: any }>;

const getIconCollection = (contentfulIcons: HowItWorksCustomImagesType | undefined) => {
  if (!contentfulIcons) {
    return defaultIcons;
  }

  const customIcons: IconCollectionType = defaultIcons;

  contentfulIcons.faqImagesCollection?.items.forEach((image) => {
    defaultIcons[image.title as iconTitles] = (
      <CustomIcon
        url={image.url}
        altText={image.title}
        key={image.title}
        width="100px"
        height="100px"
      />
    );
  });

  return customIcons;
};

export const getBuyerContentConfig = (
  creditPayoutPercentage: number,
  cashPayoutPercentage: number,
  customIcons: HowItWorksCustomImagesType | undefined,
  buyerSellerInfoOverrides?: Partial<BuyerSellerInfo>
): BuyerSellerContent[] => {
  const icons = getIconCollection(customIcons);

  const buyerSellerInfo = {
    ...getDefaultBuyerSellerInfo(creditPayoutPercentage, cashPayoutPercentage),
    ...buyerSellerInfoOverrides,
  };

  return [
    {
      image: icons[BuyerInfo.Browse],
      ...buyerSellerInfo[BuyerInfo.Browse],
    },
    {
      image: icons[BuyerInfo.Buy],
      ...buyerSellerInfo[BuyerInfo.Buy],
    },
    {
      image: icons[BuyerInfo.Verify],
      ...buyerSellerInfo[BuyerInfo.Verify],
    },
  ];
};

export const getSellerContentConfig = (
  creditPayoutPercentage: number,
  cashPayoutPercentage: number,
  customIcons: HowItWorksCustomImagesType | undefined,
  buyerSellerInfoOverrides?: Partial<BuyerSellerInfo>
): BuyerSellerContent[] => {
  const buyerSellerInfo = {
    ...getDefaultBuyerSellerInfo(creditPayoutPercentage, cashPayoutPercentage),
    ...buyerSellerInfoOverrides,
  };
  const icons = getIconCollection(customIcons);

  return [
    {
      image: icons[SellerInfo.PostIt],
      ...buyerSellerInfo[SellerInfo.PostIt],
    },
    {
      image: icons[SellerInfo.ShipIt],
      ...buyerSellerInfo[SellerInfo.ShipIt],
    },
    {
      image: icons[SellerInfo.GetPaid],
      ...buyerSellerInfo[SellerInfo.GetPaid],
    },
  ];
};

export const getTradeInContentConfig = (
  shopName: string,
  customIcons: HowItWorksCustomImagesType | undefined,
  buyerSellerInfoOverrides?: Partial<BuyerSellerInfo>,
  includeIcons?: boolean
): BuyerSellerContent[] => {
  const tradeInInfo = {
    ...getDefaultTradeInInfo(shopName),
    ...buyerSellerInfoOverrides,
  };
  const icons = getIconCollection(customIcons);

  return [
    {
      ...(includeIcons && { image: icons[TradeInInfoType.Submit] }),
      ...tradeInInfo[TradeInInfoType.Submit],
    },
    {
      ...(includeIcons && { image: icons[TradeInInfoType.Ship] }),
      ...tradeInInfo[TradeInInfoType.Ship],
    },
    {
      ...(includeIcons && { image: icons[TradeInInfoType.Earn] }),
      ...tradeInInfo[TradeInInfoType.Earn],
    },
  ];
};

export const getAboutNavbarTabs = (
  allowBuy: boolean,
  allowSell: boolean,
  allowTradeIn: boolean,
  isTradeInOnly: boolean,
  shouldUseAboutTreetPages: boolean
): AboutNavBarTab[] => [
  {
    tab: AboutPageTab.Info,
    text: 'About',
  },
  ...(allowBuy
    ? [
        {
          text: 'Buying',
          tab: AboutPageTab.Buyer,
        },
      ]
    : []),
  ...(allowSell
    ? [
        {
          text: 'Selling',
          tab: AboutPageTab.Seller,
        },
      ]
    : []),
  ...(allowTradeIn
    ? [
        {
          text: isTradeInOnly ? 'Selling' : 'Trade-Ins',
          tab: AboutPageTab.TradeIn,
        },
      ]
    : []),
  {
    text: 'Support',
    tab: AboutPageTab.Support,
  },
  ...(shouldUseAboutTreetPages
    ? [] // About Treet Info is hidden into a separate set of subpages.
    : [
        {
          text: 'Treet',
          tab: AboutPageTab.AboutTreet,
        },
        ...(allowBuy ? [{ text: 'Buyer Protection', tab: AboutPageTab.Protection }] : []),
      ]),
];
