import { getSizeFilterConfig } from '../filters/size';
import { getBrandDirectFilterConfig } from '../filters/brandDirect';
import { getDefaultShopConfig } from './default';
import * as custom from '../filters/marketplace-custom-config';
import { getConditionsFilterConfig } from '../filters/condition';

const opheliaandindigoSizeOptions = ['XS', 'XS/S', 'S', 'S/M', 'M', 'M/L', 'L', 'XL'];

export const opheliaandindigoShopConfig = {
  ...getDefaultShopConfig(),
  filters: [
    ...custom.filters,
    getConditionsFilterConfig(),
    getBrandDirectFilterConfig(),
    getSizeFilterConfig({ sizes: opheliaandindigoSizeOptions }),
  ],
};
