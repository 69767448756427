import { Box } from '@material-ui/core';
import React, { FC } from 'react';
import { OnChange } from 'react-final-form-listeners';
import { FieldTextInput } from '../../components';
import { required } from '../../util/validators';

export const USER_GENERATED_TITLE = 'userGeneratedTitle';

interface ItemNameProps {
  onChange?: (values: any) => void;
}

const ItemNameForm: FC<ItemNameProps> = (props) => {
  const { onChange = () => {} } = props;

  return (
    <Box pb={1}>
      <FieldTextInput
        id={USER_GENERATED_TITLE}
        name={USER_GENERATED_TITLE}
        label="Item Name"
        placeholder="Enter a name for your listing"
        validate={required('Please specify a name.')}
      />
      <OnChange name={USER_GENERATED_TITLE}>{(value) => onChange(value)}</OnChange>
    </Box>
  );
};

export default ItemNameForm;
