import React from 'react';
import { useIconColor } from '../../../hooks/useIconColor';

interface IconCheckCircleFilledProps {
  className?: string;
  color?: string;
  width?: string;
  height?: string;
}

const IconCheckCircleFilled: React.FC<IconCheckCircleFilledProps> = (props) => {
  const { className, color, height, width } = props;

  const { iconColor } = useIconColor();
  const fillColor = color || iconColor;

  return (
    <svg
      className={className}
      width={width || '32'}
      height={height || '32'}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 4C9.37258 4 4 9.37258 4 16C4 22.6274 9.37258 28 16 28C22.6274 28 28 22.6274 28 16C28 9.37258 22.6274 4 16 4ZM14.3333 21.45L21.7241 13.6897L20.2758 12.3103L14.3333 18.55L11.7241 15.8103L10.2758 17.1897L14.3333 21.45Z"
        fill={fillColor}
      />
    </svg>
  );
};

export default IconCheckCircleFilled;
