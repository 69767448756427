/* eslint-disable import/prefer-default-export */
import compact from 'lodash/compact';
import { ListingWithAuthorAndImages } from '../../types/sharetribe/listing';

interface GetOpenListingsParams {
  shopName: string | undefined;
  listingIds: string[];
  subdomain?: string;
}

// Returns only the open listings from the listing ids passed into the params.
// Will loop through all pages.
export const getOpenListings = async (sdk: any, params: GetOpenListingsParams) => {
  const { shopName, listingIds, subdomain } = params;
  const queryParams = {
    pub_shopName: shopName,
    include: ['author', 'images', 'currentStock'],
    'fields.image': ['variants.default'],
    'limit.images': 1,
  };

  let listings: ListingWithAuthorAndImages[] = [];
  let included: any[] = [];
  for (let i = 0; i < listingIds.length; i += 100) {
    // eslint-disable-next-line no-await-in-loop
    const listingsResponse = await sdk.listings.query({
      ...queryParams,
      ids: listingIds.slice(i, i + 100),
      // Pass in a unique subdomain for each shop, so that we don't run into potential browser
      // caching issues across subdomains. See https://github.com/TreetCo/treet/pull/1088
      // for more details.
      subdomain,
    });
    listings = compact(listings.concat(listingsResponse?.data.data));
    included = compact(included.concat(listingsResponse?.data.included));
  }

  const totalResponse = { data: { data: listings, included } };
  return totalResponse;
};
