import { Box } from '@material-ui/core';
import React, { FC } from 'react';
import classNames from 'classnames';
import { useLocation } from 'react-router-dom';
import { useShopConfig, useShopConfigV2 } from '../../hooks/shopConfig';
import { useIsMobile } from '../../hooks/useIsMobile';
import TypographyWrapper from '../TypographyWrapper/TypographyWrapper';
import { PAGE_TO_PATH } from '../../routeConfiguration';
import { getBasePath } from '../../util/routes';
import { getOptimizedImageSrcs } from '../../util/contentful';
import css from './SectionFooterActionV2.module.css';

interface SectionFooterActionV2Props {
  headerEl?: React.ReactNode;
  description?: string;
  actionEl?: React.ReactNode;
  rootClassName?: string;
}

const SectionFooterActionV2: FC<SectionFooterActionV2Props> = (
  props: SectionFooterActionV2Props
) => {
  const { headerEl, description, actionEl, rootClassName } = props;

  const isMobile = useIsMobile();
  const shopConfig = useShopConfig();
  const shopConfigV2 = useShopConfigV2();
  const { css: shopConfigV2Css } = useShopConfigV2();
  const location = useLocation();

  const pagesToHideFooter = shopConfigV2?.images?.hideFooterOnPages;
  const { pathname } = location;
  const basePath = getBasePath(pathname);

  if (pagesToHideFooter?.map((page) => PAGE_TO_PATH[page]).includes(basePath)) {
    return null;
  }

  const footerImageV2Default = shopConfigV2?.images?.footerImage?.url;
  const footerImageV2Mobile = shopConfigV2?.images?.footerImageMobile?.url;
  const disableFooterTint = shopConfigV2Css?.disableFooterTint;
  const footerImageV2 = (isMobile && footerImageV2Mobile) || footerImageV2Default;

  const {
    styles: { footerImageUrl, footerImageUrlSmall },
  } = shopConfig;
  const defaultRootClassName = classNames(css.root, { [css.rootTint]: !disableFooterTint });

  const getBackgroundImageStyle = () => {
    const imgSrc = isMobile
      ? footerImageV2 || footerImageUrlSmall
      : footerImageV2 || footerImageUrl;

    const optimizedImageSrcs = getOptimizedImageSrcs(imgSrc, isMobile ? '600' : undefined);
    const optimizedImage = optimizedImageSrcs.at(-1);

    return { backgroundImage: optimizedImage };
  };

  return (
    <Box className={rootClassName || defaultRootClassName} style={getBackgroundImageStyle()}>
      <Box
        p="24px 16px"
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
      >
        {headerEl}
        <Box pt={2}>
          <TypographyWrapper
            variant="body1"
            typographyOverrides={{
              style: { color: 'white', textAlign: 'center', maxWidth: '600px' },
            }}
          >
            {description}
          </TypographyWrapper>
        </Box>
        {!!actionEl && (
          <Box pt={2} className={css.actions}>
            {actionEl}
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default SectionFooterActionV2;
