import React from 'react';
import PropTypes from 'prop-types';

const NoImageIcon = (props) => {
  const { className } = props;

  return (
    <svg className={className} viewBox="0 0 222 260" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M52.0874 155.272L36 182.419H187L158.159 116.487C157.548 115.092 155.665 114.863 154.739 116.072L134.601 142.358C133.456 143.851 131.426 144.346 129.723 143.545L120.266 139.097C116.759 137.447 112.615 137.972 109.63 140.445L89.5238 157.102C88.4373 158.002 86.957 158.263 85.6281 157.789L65.2462 150.519C60.2877 148.75 54.7713 150.743 52.0874 155.272Z"
        fill="#A7A7A7"
      />
      <ellipse cx="80.5" cy="91.1906" rx="22.5" ry="22.1906" fill="#A7A7A7" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M222 0H0V260H222V0ZM206 16H16V198H206V16Z"
        fill="#A7A7A7"
      />
    </svg>
  );
};

const { string } = PropTypes;

NoImageIcon.defaultProps = {
  className: null,
};

NoImageIcon.propTypes = {
  className: string,
};

export default NoImageIcon;
