import { defaultShoesConditionsOptions, getConditionsFilterConfig } from '../filters/condition';
import { getDefaultShopConfig } from './default';
import * as custom from '../filters/marketplace-custom-config';
import { getSizeFilterConfig } from '../filters/size';

const helmSizes = [
  '6',
  '7',
  '7.5',
  '8',
  '8.5',
  '9',
  '9.5',
  '10',
  '10.5',
  '11',
  '11.5',
  '12',
  '13',
  '14',
  '15',
  '16',
  'S',
  'M',
  'L',
  'XL',
  'XXL',
];

export const helmShopConfig = {
  ...getDefaultShopConfig(),
  filters: [
    ...custom.filters,
    getConditionsFilterConfig({ options: defaultShoesConditionsOptions }),
    getSizeFilterConfig({ sizes: helmSizes }),
  ],
};
