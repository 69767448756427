import { Box } from '@material-ui/core';
import React, { FC } from 'react';

interface CustomIconProps {
  url: string;
  altText: string;
  width?: string;
  height?: string;
  className?: string;
  imgStyleOverrides?: any;
}

const CustomIcon: FC<CustomIconProps> = (props: CustomIconProps) => {
  const { url, altText, width, height, className, imgStyleOverrides } = props;

  if (!url) return null;

  return (
    <Box width={width} height={height}>
      <img
        style={{ height: '100%', maxWidth: '100%', ...imgStyleOverrides }}
        src={url}
        alt={altText}
        className={className}
      />
    </Box>
  );
};

export default CustomIcon;
