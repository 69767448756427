import classNames from 'classnames';
import React, { FC } from 'react';
import { IconSpinner } from '../../components';
import { ensurePaymentMethodCard } from '../../util/data';
import OneTimePaymentWithCard from './OneTimePaymentWithCard';
import PaymentMethodSelector from './PaymentMethodSelector';

import css from './StripePaymentForm.module.css';

interface PaymentFieldsProps {
  defaultPaymentMethod: any;
  error?: string;
  handleChangePaymentMethod: (paymentMethod: string) => void;
  handleStripeElementRef: (el: any) => void;
  isCardValueValid: boolean;
  isLoading: boolean;
  isSubmitting: boolean;
  selectedPaymentMethod: string;
}

const PaymentFields: FC<PaymentFieldsProps> = (props: PaymentFieldsProps) => {
  const {
    defaultPaymentMethod,
    error,
    handleChangePaymentMethod,
    handleStripeElementRef,
    isCardValueValid,
    isLoading,
    isSubmitting,
    selectedPaymentMethod,
  } = props;

  const ensuredDefaultPaymentMethod = ensurePaymentMethodCard(defaultPaymentMethod);
  const showPaymentMethodSelector = ensuredDefaultPaymentMethod.id;
  const hasCardError = !!(error && !isSubmitting);
  const cardClasses = classNames(css.card, {
    [css.cardSuccess]: isCardValueValid,
    [css.cardError]: hasCardError,
  });

  if (isLoading) {
    return (
      <p className={css.spinner}>
        <IconSpinner />
      </p>
    );
  }

  return (
    <>
      {showPaymentMethodSelector ? (
        <PaymentMethodSelector
          cardClasses={cardClasses}
          changePaymentMethod={handleChangePaymentMethod}
          defaultPaymentMethod={ensuredDefaultPaymentMethod}
          handleStripeElementRef={handleStripeElementRef}
          hasCardError={hasCardError}
          error={error}
          paymentMethod={selectedPaymentMethod}
        />
      ) : (
        <OneTimePaymentWithCard
          cardClasses={cardClasses}
          handleStripeElementRef={handleStripeElementRef}
          hasCardError={hasCardError}
          error={error}
        />
      )}
    </>
  );
};

export default PaymentFields;
