import { getDefaultShopConfig } from './default';
import * as custom from '../filters/marketplace-custom-config';
import { getConditionsFilterConfig } from '../filters/condition';
import { getBrandDirectFilterConfig } from '../filters/brandDirect';
import { getSizeFilterConfig } from '../filters/size';

const gerganaivanovaSizes = ['XS', 'XS/S', 'S', 'S/M', 'M', 'M/L', 'L', 'XL', 'XXL'];

export const gerganaivanovaShopConfig = {
  ...getDefaultShopConfig(),
  filters: [
    ...custom.filters,
    getConditionsFilterConfig(),
    getBrandDirectFilterConfig(),
    getSizeFilterConfig({ sizes: gerganaivanovaSizes }),
  ],
};
