import merge from 'lodash/merge';
import { denormalisedResponseEntities } from '../../util/data';
import { storableError } from '../../util/errors';
import { currentUserShowSuccess, fetchCurrentUser } from '../../ducks/user.duck';
import { apolloClient } from '../../apollo';
import { UpdateUserDocument } from '../../types/apollo/generated/types.generated';
import * as log from '../../util/log';

// ================ Action types ================ //

export const SAVE_CONTACT_DETAILS_REQUEST = 'app/ContactDetailsPage/SAVE_CONTACT_DETAILS_REQUEST';
export const SAVE_CONTACT_DETAILS_SUCCESS = 'app/ContactDetailsPage/SAVE_CONTACT_DETAILS_SUCCESS';
export const SAVE_EMAIL_ERROR = 'app/ContactDetailsPage/SAVE_EMAIL_ERROR';
export const SAVE_PHONE_NUMBER_ERROR = 'app/ContactDetailsPage/SAVE_PHONE_NUMBER_ERROR';

export const SAVE_CONTACT_DETAILS_CLEAR = 'app/ContactDetailsPage/SAVE_CONTACT_DETAILS_CLEAR';

// ================ Reducer ================ //

const initialState = {
  saveEmailError: null,
  savePhoneNumberError: null,
  saveContactDetailsInProgress: false,
  contactDetailsChanged: false,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case SAVE_CONTACT_DETAILS_REQUEST:
      return {
        ...state,
        saveContactDetailsInProgress: true,
        saveEmailError: null,
        savePhoneNumberError: null,
        contactDetailsChanged: false,
      };
    case SAVE_CONTACT_DETAILS_SUCCESS:
      return { ...state, saveContactDetailsInProgress: false, contactDetailsChanged: true };
    case SAVE_EMAIL_ERROR:
      return { ...state, saveContactDetailsInProgress: false, saveEmailError: payload };
    case SAVE_PHONE_NUMBER_ERROR:
      return { ...state, saveContactDetailsInProgress: false, savePhoneNumberError: payload };

    case SAVE_CONTACT_DETAILS_CLEAR:
      return {
        ...state,
        saveContactDetailsInProgress: false,
        saveEmailError: null,
        savePhoneNumberError: null,
        contactDetailsChanged: false,
      };

    default:
      return state;
  }
}

// ================ Action creators ================ //

export const saveContactDetailsRequest = () => ({ type: SAVE_CONTACT_DETAILS_REQUEST });
export const saveContactDetailsSuccess = () => ({ type: SAVE_CONTACT_DETAILS_SUCCESS });
export const saveEmailError = (error) => ({
  type: SAVE_EMAIL_ERROR,
  payload: error,
  error: true,
});
export const savePhoneNumberError = (error) => ({
  type: SAVE_PHONE_NUMBER_ERROR,
  payload: error,
  error: true,
});

export const saveContactDetailsClear = () => ({ type: SAVE_CONTACT_DETAILS_CLEAR });

// ================ Thunks ================ //

/**
 * Make a phone number update request to the API and return the current user.
 */
const requestSavePhoneNumber = (params) => (dispatch, getState, sdk) => {
  const { phoneNumber } = params;

  return sdk.currentUser
    .updateProfile(
      { protectedData: { phoneNumber } },
      {
        expand: true,
        include: ['profileImage'],
        'fields.image': ['variants.square-small', 'variants.square-small2x'],
      }
    )
    .then((response) => {
      const entities = denormalisedResponseEntities(response);
      if (entities.length !== 1) {
        throw new Error('Expected a resource in the sdk.currentUser.updateProfile response');
      }

      const currentUser = entities[0];
      return currentUser;
    })
    .catch((e) => {
      dispatch(savePhoneNumberError(storableError(e)));
      // pass the same error so that the SAVE_CONTACT_DETAILS_SUCCESS
      // action will not be fired
      throw e;
    });
};

/**
 * Make a email update request to the API and return the current user.
 */
const requestSaveEmail = (params) => (dispatch, getState, sdk) => {
  const { email, currentEmail, currentPassword } = params;

  // TODO (anniew|TREET-3495) Update PG user when email changes

  return sdk.currentUser
    .changeEmail(
      { email, currentPassword },
      {
        expand: true,
        include: ['profileImage'],
        'fields.image': ['variants.square-small', 'variants.square-small2x'],
      }
    )
    .then((response) => {
      const entities = denormalisedResponseEntities(response);
      if (entities.length !== 1) {
        throw new Error('Expected a resource in the sdk.currentUser.changeEmail response');
      }
      const currentUser = entities[0];

      return apolloClient
        .mutate({
          mutation: UpdateUserDocument,
          variables: {
            input: {
              email: currentEmail,
              newEmail: email,
            },
          },
        })
        .then(() => currentUser)
        .catch((e) => {
          // Log error to sentry only without disrupting the user flow.
          // (TODO|TREET-3578): Once Users data is fully migrated off of Sharetribe, start throwing
          // error if updating PG User fails.
          log.error(e, 'postgres-update-user-email-failed', {
            oleEmail: currentEmail,
            newEmail: email,
          });
          return currentUser;
        });
    })
    .catch((e) => {
      dispatch(saveEmailError(storableError(e)));
      // pass the same error so that the SAVE_CONTACT_DETAILS_SUCCESS
      // action will not be fired
      throw e;
    });
};

/**
 * Save email and update the current user.
 */
const saveEmail = (params) => (dispatch) =>
  dispatch(requestSaveEmail(params))
    .then((user) => {
      dispatch(currentUserShowSuccess(user));
      dispatch(saveContactDetailsSuccess());
    })
    // error action dispatched in requestSaveEmail
    .catch(() => null);

/**
 * Save phone number and update the current user.
 */
const savePhoneNumber = (params) => (dispatch) =>
  dispatch(requestSavePhoneNumber(params))
    .then((user) => {
      dispatch(currentUserShowSuccess(user));
      dispatch(saveContactDetailsSuccess());
    })
    // error action dispatched in requestSavePhoneNumber
    .catch(() => null);

/**
 * Save email and phone number and update the current user.
 */
const saveEmailAndPhoneNumber = (params) => (dispatch) => {
  const { email, currentEmail, phoneNumber, currentPassword } = params;

  // order of promises: 1. email, 2. phone number
  const promises = [
    dispatch(requestSaveEmail({ email, currentEmail, currentPassword })),
    dispatch(requestSavePhoneNumber({ phoneNumber })),
  ];

  return Promise.all(promises)
    .then((values) => {
      // Array of two user objects is resolved
      // the first one is from the email update
      // the second one is from the phone number update

      const saveEmailUser = values[0];
      const savePhoneNumberUser = values[1];

      // merge the protected data from the user object returned
      // by the phone update operation
      const { protectedData } = savePhoneNumberUser.attributes.profile;
      const phoneNumberMergeSource = { attributes: { profile: { protectedData } } };

      const currentUser = merge(saveEmailUser, phoneNumberMergeSource);
      dispatch(currentUserShowSuccess(currentUser));
      dispatch(saveContactDetailsSuccess());
    })
    .catch(() => null);
};

/**
 * Update contact details, actions depend on which data has changed
 */
// eslint-disable-next-line consistent-return
export const saveContactDetails = (params) => (dispatch) => {
  dispatch(saveContactDetailsRequest());

  const { email, currentEmail, phoneNumber, currentPhoneNumber, currentPassword } = params;
  const emailChanged = email !== currentEmail;
  const phoneNumberChanged = phoneNumber !== currentPhoneNumber;

  if (emailChanged && phoneNumberChanged) {
    return dispatch(saveEmailAndPhoneNumber({ email, currentEmail, currentPassword, phoneNumber }));
  }
  if (emailChanged) {
    return dispatch(saveEmail({ email, currentEmail, currentPassword }));
  }
  if (phoneNumberChanged) {
    return dispatch(savePhoneNumber({ phoneNumber }));
  }
};

export const loadData = () =>
  // Since verify email happens in separate tab, current user's data might be updated
  fetchCurrentUser();
