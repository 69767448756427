import { ICustomIcon } from '../types/contentful/types.generated';
import { useShopConfig } from './shopConfig';

export enum CustomIconType {
  Favorite = 'FAVORITE',
  FavoriteFilled = 'FAVORITE_FILLED',
  User = 'USER',
  ShoppingBag = 'SHOPPING_BAG',
  Search = 'SEARCH',
  Filter = 'FILTER',
  BuyerProtectionFilled = 'BUYER_PROTECTION_FILLED',
  CashFilled = 'CASH_FILLED',
  TreeFilled = 'TREE_FILLED',
}

export const useCustomIcon = (iconType: CustomIconType) => {
  const { css: shopCss } = useShopConfig();
  const { customIconsCollection } = shopCss;
  return customIconsCollection?.items?.find((icon: ICustomIcon) => icon.type === iconType);
};
