import {
  FilterId,
  GenericFilterOption,
  SearchFilterType,
  SearchNavBarSections,
} from '../../types/filters/filters';
import { FilterQueryParam } from '../../types/search/search';

interface GetGenderFilterConfigInputs {
  options?: GenericFilterOption[];
}

// (1/07/2022): Currently this filter only works for Frenzy searches because there is no
// gender data stored in Sharetribe.
export const getGenderFilterConfig = (params?: GetGenderFilterConfigInputs) => {
  const { options = [] } = params || {};
  return {
    id: FilterId.Gender,
    label: 'Gender',
    type: SearchFilterType.Checkbox,
    group: 'primary',
    queryParamNames: [FilterQueryParam.gender],
    searchNavBarSection: SearchNavBarSections.Filter,
    config: {
      options,
    },
  };
};
