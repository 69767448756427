/* eslint-disable import/prefer-default-export */
export const builderButtonInputs = [
  {
    name: 'variant',
    type: 'string',
    enum: ['PRIMARY', 'SECONDARY', 'INLINE', 'DANGER', 'SOCIAL_LOGIN', 'CUSTOM'], // matches types of ButtonVariant.
  },
  {
    name: 'children',
    type: 'text',
  },
];
