/**
 * This is a wrapper component for different Layouts.
 * Navigational 'aside' content should be added to this wrapper.
 */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Box } from '@material-ui/core';
import { TabNav } from '..';
import { useIsMobile } from '../../hooks/useIsMobile';
import { useShopConfig } from '../../hooks/shopConfig';

import css from './LayoutWrapperSideNav.module.css';

const TabNavFooter = (props) => {
  const { footer } = props;
  return (
    <Box position="sticky" bottom={0} left={0}>
      {footer}
    </Box>
  );
};

const LayoutWrapperSideNav = (props) => {
  const {
    className,
    rootClassName,
    tabsClassName,
    children,
    tabs,
    footer,
    ctaButton,
    bgcolor,
    getMore,
  } = props;

  const { css: brandCss } = useShopConfig();
  const isMobile = useIsMobile();

  const classes = classNames(rootClassName || css.root, className);
  const tabsClasses = classNames(tabsClassName, css.tabs);

  return (
    <Box className={classes} bgcolor={bgcolor || brandCss?.backgroundColor}>
      {children}
      {tabs ? (
        <TabNav
          rootClassName={tabsClasses}
          tabRootClassName={css.tab}
          tabs={tabs}
          getMore={getMore}
        />
      ) : null}

      {ctaButton || null}
      {!isMobile && footer ? <TabNavFooter footer={footer} /> : null}
    </Box>
  );
};

LayoutWrapperSideNav.defaultProps = {
  className: null,
  rootClassName: null,
  tabsClassName: null,
  children: null,
  tabs: null,
  footer: null,
  ctaButton: null,
  bgcolor: null,
  getMore: null,
};

const { node, string, array } = PropTypes;

LayoutWrapperSideNav.propTypes = {
  children: node,
  className: string,
  rootClassName: string,
  tabsClassName: string,
  tabs: array,
  footer: node,
  ctaButton: React.ReactElement,
  bgcolor: string,
  getMore: React.ReactElement,
};

export default LayoutWrapperSideNav;
