import { getDefaultShopConfig } from './default';
import * as custom from '../filters/marketplace-custom-config';
import { getBrandDirectFilterConfig } from '../filters/brandDirect';
import { Conditions, getConditionsFilterConfig } from '../filters/condition';
import { getSizeFilterConfig } from '../filters/size';

const vivobarefootcaSizes: string[] = [
  'Womens US 4.5 (35)',
  'Womens US 5 (36)',
  'Womens US 6 (37)',
  'Womens US 7 (38)',
  'Womens US 8 (39)',
  'Womens US 9 (40)',
  'Womens US 10 (41)',
  'Womens US 10.5 (42)',
  'Womens US 11 (43)',
  'Mens US 7 (40)',
  'Mens US 8 (41)',
  'Mens US 9 (42)',
  'Mens US 10 (43)',
  'Mens US 11 (44)',
  'Mens US 12 (45)',
  'Mens US 12.5 (46)',
  'Mens US 13 (47)',
  'Mens US 14 (48)',
  'Mens US 15 (49)',
  'Toddlers US 5 (20)',
  'Toddlers US 5.5 (21)',
  'Toddlers US 6 (22)',
  'Toddlers US 7 (23)',
  'Toddlers US 8 (24)',
  'Preschool US 9 (25)',
  'Preschool US 9.5 (26)',
  'Preschool US 10 (27)',
  'Preschool US 11 (28)',
  'Preschool US 11.5 (29)',
  'Kids US 12 (30)',
  'Kids US 13 (31)',
  'Kids US 1 (32)',
  'Kids US 2 (33)',
  'Kids US 3 (34)',
  'Juniors US 4 (35)',
  'Juniors US 4.5 (36)',
  'Juniors US 5.5 (37)',
  'Juniors US 6 (38)',
];

const vivobarefootcaConditionOptions = [
  {
    key: Conditions.NewWithTags,
    label: 'New',
    description:
      'Your shoes have never been worn (other than perhaps trying it on), you may or may not have the box. There are no quirks or issues whatsoever.',
  },
  {
    key: Conditions.Excellent,
    label: 'Excellent',
    description:
      'Your shoes have only been lightly used and have been extremely well maintained. There may be some small signs of wear, but nothing major.',
  },
  {
    key: Conditions.Good,
    label: 'Second Life',
    description:
      'You’ve worn the shoes and they’re well maintained. There may be some quirks like a small stain or a scuff mark, but they’re overall in good shape.',
  },
];

export const vivobarefootcaShopConfig = {
  ...getDefaultShopConfig(),
  filters: [
    ...custom.filters,
    getConditionsFilterConfig({ options: vivobarefootcaConditionOptions }),
    getBrandDirectFilterConfig(),
    getSizeFilterConfig({ sizes: vivobarefootcaSizes }),
  ],
};
