import { getActivePromoConfigs } from '../shopConfig/configHelper';
import { useShopConfig } from './shopConfig';

/**
 * This hook gives us access to active brand promos.
 */
// eslint-disable-next-line import/prefer-default-export
export function useActivePromos() {
  const shopConfig = useShopConfig();

  const {
    activePromos,
    activeDiscountCodePromo,
    activeSlashBdPricesPromo,
    activePayoutCreditBoostPromo,
  } = getActivePromoConfigs(shopConfig?.promoConfigsCollection);

  const promoCreditPayoutPercentage =
    activePayoutCreditBoostPromo?.promoTypeConfig?.promoPayoutPercentage;
  const activePromoHighlightBanners = activePromos?.map((promo) => promo?.promoHighlightBanner);
  const activePromoBrandDirectIconUrl =
    activeSlashBdPricesPromo?.promoTypeConfig?.promoBrandDirectIcon?.url;

  return {
    activePromos,
    activePromoHighlightBanners,
    activePromoBrandDirectIconUrl,
    activeDiscountCodePromo,
    activeSlashBdPricesPromo,
    activePayoutCreditBoostPromo,
    promoCreditPayoutPercentage,
  };
}
