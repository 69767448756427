import React, { ElementType, FC, ReactElement } from 'react';
import { MessageDescriptor, FormattedMessage as ReactIntlFormattedMessage } from 'react-intl';

interface FormattedMessageProps extends MessageDescriptor {
  values?: any;
  tagName?: ElementType;
  children?: ReactElement;
}

const FormattedMessage: FC<FormattedMessageProps> = (props: FormattedMessageProps) => (
  <ReactIntlFormattedMessage {...props}>{(...content) => content}</ReactIntlFormattedMessage>
);

export default FormattedMessage;
