import { getConditionsFilterConfig } from '../filters/condition';
import { getSizeFilterConfig } from '../filters/size';
import { getBrandDirectFilterConfig } from '../filters/brandDirect';
import { getDefaultShopConfig } from './default';
import * as custom from '../filters/marketplace-custom-config';

const timininousSizes = [
  'Newborn',
  '3-6 M',
  '6-9 M',
  '9-12 M',
  '1 T',
  '2T',
  '3T',
  '4T',
  '5T',
  '6',
  '7',
  '8',
  '9',
  '10',
];

export const timininousShopConfig = {
  ...getDefaultShopConfig(),
  filters: [
    ...custom.filters,
    getConditionsFilterConfig(),
    getBrandDirectFilterConfig(),
    getSizeFilterConfig({ sizes: timininousSizes }),
  ],
};
