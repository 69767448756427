import React, { FC } from 'react';
import { Box } from '@material-ui/core';
import TypographyWrapper, { TypographyWeight } from '../TypographyWrapper/TypographyWrapper';
import ListingFeedbackHeader from './ListingFeedbackHeader';
import { ListingWithAuthor } from '../../types/sharetribe/listing';
import { IconPromote } from '..';
import { useShopConfig } from '../../hooks/shopConfig';
import {
  getDarkerBrandCssBackgroundColor,
  getPercentAlteredHexColor,
} from '../../util/colors/colors';
import { defaultTreetStyles } from '../../shopConfig/config';
import { useIsMobile } from '../../hooks/useIsMobile';

interface ListingFeedbackSuccessPaneProps {
  listing: ListingWithAuthor;
}

const ListingFeedbackSuccessPane: FC<ListingFeedbackSuccessPaneProps> = (props) => {
  const { listing } = props;

  const isMobile = useIsMobile();
  const { css: brandCss } = useShopConfig();
  const darkerBackgroundColor = getDarkerBrandCssBackgroundColor(brandCss);
  const borderCss = `1px solid ${
    getPercentAlteredHexColor(darkerBackgroundColor, -8) || defaultTreetStyles.gray20
  }`;

  const sellerName = listing.author?.attributes?.profile?.displayName;
  return (
    <>
      <ListingFeedbackHeader headerCopy="Message Sent!" />
      <Box
        pt={{ xs: 10, md: 5 }}
        pb={{ xs: 24, md: 5 }}
        px={isMobile ? 4 : 6}
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
      >
        <Box pb={4}>
          <Box
            bgcolor={darkerBackgroundColor}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              borderRadius: '50%',
              width: 120,
              height: 120,
            }}
          >
            <IconPromote />
          </Box>
        </Box>
        <Box pb={4}>
          <TypographyWrapper
            variant="body1"
            weight={TypographyWeight.Bold}
            typographyOverrides={{
              style: { textAlign: 'center' },
            }}
          >
            {`Your listing update request has been sent${sellerName ? ` to ${sellerName}.` : '.'}`}
          </TypographyWrapper>
        </Box>
        <Box pb={4}>
          <TypographyWrapper
            variant="body1"
            typographyOverrides={{
              style: { textAlign: 'center' },
            }}
          >
            We’ll let you know if this listing is updated!
          </TypographyWrapper>
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          border={borderCss}
          bgcolor={darkerBackgroundColor || defaultTreetStyles.gray10}
          p={3}
        >
          <Box pb={1}>
            <TypographyWrapper
              variant="body2"
              weight={TypographyWeight.Bold}
              typographyOverrides={{
                style: { textAlign: 'center' },
              }}
            >
              Other people are looking at this item
            </TypographyWrapper>
          </Box>
          <TypographyWrapper
            variant="body2"
            typographyOverrides={{
              style: { textAlign: 'center' },
            }}
          >
            We’ve sent your feedback to the seller but remember that once this item sells it’s gone
            forever!
          </TypographyWrapper>
        </Box>
      </Box>
    </>
  );
};

export default ListingFeedbackSuccessPane;
