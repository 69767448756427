/**
 * This is a wrapper component for different Layouts.
 * Topbar should be added to this wrapper.
 */
import React from 'react';
import PropTypes, { any, bool } from 'prop-types';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import TreetShopNavBar from '../TreetShopNavBar/TreetShopNavBar';
import { useFeatureFlags } from '../../hooks/useFeatureFlags';
import { processReferrerData, Referrer } from '../../util/sessionHelpers/referrerSessionHelpers';
import { Feature } from '../../util/featureFlags';
import css from './LayoutWrapperTopbar.module.css';

const LayoutWrapperTopbar = (props) => {
  const { className, rootClassName, children, shouldHideTreetShopNavbar, elRef } = props;
  const classes = classNames(rootClassName || css.root, className);

  const { treetId } = useSelector((state) => state.initial);

  const { referrer } = processReferrerData();
  const isTreetShopReferred = referrer === Referrer.TreetShop;

  const isTreetShop = treetId === 'treet';
  const isTreetShopEnabled = useFeatureFlags(Feature.TreetShop);
  const shouldShowTreetShopNavBar =
    isTreetShopReferred && !shouldHideTreetShopNavbar && !isTreetShop && isTreetShopEnabled;

  return (
    <div className={classes} ref={elRef}>
      {shouldShowTreetShopNavBar && <TreetShopNavBar />}
      {children}
    </div>
  );
};

LayoutWrapperTopbar.defaultProps = {
  className: null,
  rootClassName: null,
  shouldHideTreetShopNavbar: false,
  elRef: null,
};

const { node, string } = PropTypes;

LayoutWrapperTopbar.propTypes = {
  children: node.isRequired,
  className: string,
  rootClassName: string,
  shouldHideTreetShopNavbar: bool,
  elRef: any,
};

export default LayoutWrapperTopbar;
