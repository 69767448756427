import { getBrandDirectFilterConfig } from '../filters/brandDirect';
import { getConditionsFilterConfig } from '../filters/condition';
import { getSizeFilterConfig } from '../filters/size';
import { getDefaultShopConfig } from './default';
import * as custom from '../filters/marketplace-custom-config';
import { BuilderSections } from '../../util/builder';
import { getGenderFilterConfig } from '../filters/gender';

const dixxonSizes = [
  'XS',
  'S',
  'M',
  'L',
  'L Tall',
  'XL',
  'XL Tall',
  '2X',
  '2X Tall',
  '3X',
  '4X',
  '5X',
  'YXS',
  'YS',
  'YM',
  'YL',
  'YXL',
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  '10',
  '11',
  '12',
  '13',
  '14',
  '30',
  '32',
  '34',
  '36',
  '38',
  '40',
  '42',
  '44',
  '46',
  '48',
];

export const dixxonShopConfig = {
  ...getDefaultShopConfig(),
  filters: [
    ...custom.filters,
    getConditionsFilterConfig(),
    getSizeFilterConfig({
      sizes: dixxonSizes,
    }),
    getBrandDirectFilterConfig(),
    getGenderFilterConfig(),
  ],
  builderConfig: {
    sections: {
      [BuilderSections.LandingPageContent]: '164c539717c547cd8354cd4a9474ce4c',
    },
  },
};
