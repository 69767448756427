import { Box } from '@material-ui/core';
import classNames from 'classnames';
import React, { FC } from 'react';
import { useShopConfigV2 } from '../../hooks/shopConfig';
import { useIsMobile } from '../../hooks/useIsMobile';
import { useStyleType } from '../../hooks/useStyleType';
import { TextWithLineBreaks, TypographyWrapper } from '..';
import css from './SectionHeroV2.module.css';
import { getOptimizedImageSrcs } from '../../util/contentful';

interface SectionHeroV2Props {
  actionEl?: React.ReactNode;
  bottomActionEl?: React.ReactNode;
  imageUrl?: string;
  shouldAnimate?: boolean;
}

const SectionHeroV2: FC<SectionHeroV2Props> = (props: SectionHeroV2Props) => {
  const { actionEl, bottomActionEl, imageUrl, shouldAnimate = false } = props;

  const { copy, images, css: shopConfigV2Css } = useShopConfigV2();
  const isMobile = useIsMobile();
  const { isCondensedStyle } = useStyleType();

  const disableHeaderTint = shopConfigV2Css?.disableHeaderTint;
  const headerContentStyle = shopConfigV2Css?.headerContentStyle;
  const shouldUseH5ForSubheader = !!shopConfigV2Css?.h5;

  const heroImageDefault = imageUrl || images?.heroImage?.url;
  const heroImageMobile = imageUrl || images?.heroImageMobile?.url;
  const heroImage = (isMobile && heroImageMobile) || heroImageDefault;

  const { heroTitle = '', heroSubtitle = '' } = copy || {};
  const isClientSide = typeof window !== 'undefined';
  const overrideStyles = { textWrap: 'balance' };

  const optimizedImageSrcs = getOptimizedImageSrcs(heroImage, isMobile ? '600' : undefined);
  const fallbackImageUrl = optimizedImageSrcs.at(-1);
  const imageSrcSet = optimizedImageSrcs.join(', ') || undefined;

  return (
    <div className={css.root}>
      {!disableHeaderTint && <div className={css.imageOverlay} />}
      <img
        className={css.image}
        src={fallbackImageUrl}
        srcSet={imageSrcSet}
        fetchpriority="high"
        alt="shop header"
      />
      {/* Prevent animation from running twice */}
      {isClientSide && (
        <>
          <Box
            p={{ xs: '32px 16px', md: '64px 32px 32px' }}
            className={css.content}
            style={isMobile ? headerContentStyle?.mobile : headerContentStyle?.desktop}
          >
            <Box className={classNames({ [css.titleAnimation]: shouldAnimate })}>
              <TypographyWrapper variant="h6" component="h1">
                <TextWithLineBreaks text={heroTitle} />
              </TypographyWrapper>
            </Box>
            <Box
              mt={isCondensedStyle ? 0 : 1}
              className={classNames({ [css.subtitleAnimation]: shouldAnimate })}
            >
              <TypographyWrapper
                typographyOverrides={
                  shouldUseH5ForSubheader
                    ? undefined
                    : { style: { ...overrideStyles, color: 'white' } }
                }
                variant={shouldUseH5ForSubheader ? 'h5' : isMobile ? 'body1' : 'h2'}
              >
                <TextWithLineBreaks text={heroSubtitle} />
              </TypographyWrapper>
            </Box>
            <Box mt={3} className={classNames({ [css.actionElAnimation]: shouldAnimate })}>
              {actionEl}
            </Box>
          </Box>
          {bottomActionEl && (
            <Box className={css.bottomActionElWrapper}>
              <Box className={classNames({ [css.bottomActionElAnimation]: shouldAnimate })}>
                {bottomActionEl}
              </Box>
            </Box>
          )}
        </>
      )}
    </div>
  );
};

export default SectionHeroV2;
