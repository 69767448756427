/* eslint-disable import/prefer-default-export */
import { useSelector } from 'react-redux';
import { CurrentUser } from '../types/sharetribe/currentUser';
import { Feature, isFeatureEnabled } from '../util/featureFlags';

/**
 * Returns whether the given feature is enabled for the user and/or treetId.
 */
export function useFeatureFlags(feature: Feature): boolean {
  const treetId = useSelector<any>((state) => state.initial.treetId) as string;
  const currentUser = useSelector<any>((state) => state.user.currentUser) as CurrentUser | null;
  return isFeatureEnabled(feature, treetId, currentUser);
}
