/* eslint-disable import/prefer-default-export */
import { Conditions, getConditionsFilterConfig } from '../filters/condition';
import { getBrandDirectFilterConfig } from '../filters/brandDirect';
import { getSizeFilterConfig } from '../filters/size';
import { getDefaultShopConfig, getDefaultTradeInShopConfig } from './default';
import * as custom from '../filters/marketplace-custom-config';

const vitalydesignSizes: string[] = ['4', '5', '6', '7', '8', '9', '10', '11', '12'];

export const vitalydesignConditionsOptions = [
  {
    key: Conditions.NewWithTags,
    label: 'New In Box',
    description: 'Your item has never been worn and is still inside the original box / packaging.',
  },
  {
    key: Conditions.Excellent,
    label: 'Excellent Condition',
    description:
      'Your item has only been lightly used and has been extremely well maintained. There may be some small signs of wear, but nothing major.',
  },
  {
    key: Conditions.Good,
    label: 'Good Condition',
    description:
      'You’ve worn this item and it’s well maintained. There may be some quirks, but it’s overall in good shape.',
  },
];

const vitalydesignAdditionalPayoutSettings = {
  additionalShopifyDiscountCodeRequirements: {
    // TODO extract into a fragment
    mutation: { minimumRequirement: { subtotal: { greaterThanOrEqualToSubtotal: '50.00' } } },
  },
  creditCodeHelperText:
    'Code can only be used on vitalydesign.com for purchases over $50. Limit one code per checkout.',
};

export const vitalydesignShopConfig = {
  ...getDefaultShopConfig(),
  ...getDefaultTradeInShopConfig(),
  filters: [
    ...custom.filters,
    getBrandDirectFilterConfig(),
    getConditionsFilterConfig({ options: vitalydesignConditionsOptions }),
    getSizeFilterConfig({ sizes: vitalydesignSizes }),
  ],
  additionalPayoutSettings: vitalydesignAdditionalPayoutSettings,
};
