import React, { FC, ReactNode } from 'react';
import { useSelector } from 'react-redux';
import {
  Footer,
  LayoutSingleColumn,
  LayoutWrapperFooter,
  LayoutWrapperMain,
  LayoutWrapperTopbar,
  Page,
} from '..';
import { TopbarContainer } from '../../containers';
import { isScrollingDisabled } from '../../ducks/UI.duck';

interface PageWrapperProps {
  title: string;
  children: ReactNode;
  mainClassName?: string;
}

const PageWrapper: FC<PageWrapperProps> = (props: PageWrapperProps) => {
  const { children, title, mainClassName } = props;

  const scrollingDisabled = useSelector<any>((state) => isScrollingDisabled(state));

  return (
    <Page title={title} scrollingDisabled={scrollingDisabled}>
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer />
        </LayoutWrapperTopbar>
        <LayoutWrapperMain className={mainClassName}>{children}</LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </Page>
  );
};

export default PageWrapper;
