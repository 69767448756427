import React, { FC } from 'react';
import { Box } from '@material-ui/core';
import { useFormState } from 'react-final-form';
import ListingFeedbackHeader from './ListingFeedbackHeader';
import TypographyWrapper, { TypographyWeight } from '../TypographyWrapper/TypographyWrapper';
import css from './ListingFeedbackModal.module.css';
import ListingFeedbackToggleButton from './ListingFeedbackToggleButton';
import {
  buttonDetails,
  ListingFeedbackCategory,
  ListingFeedbackFormField,
} from './ListingFeedback.utils';
import { FieldTextInput } from '..';
import { composeValidators, maxLength, required } from '../../util/validators';

const ListingFeedbackFreeformPane: FC = () => {
  const { values } = useFormState();

  const selectedCategory =
    values[ListingFeedbackFormField.Category] || ListingFeedbackCategory.Other;
  const selectedCategoryButtonDetails = buttonDetails[selectedCategory as ListingFeedbackCategory];

  const notesRequired = required('Notes are required.');
  const notesMaxLength = maxLength('Exceeded Max Length of 1000 Characters.', 1000);
  let notesValidators;
  if (selectedCategory === ListingFeedbackCategory.Other) {
    notesValidators = composeValidators(
      notesMaxLength,
      selectedCategory === ListingFeedbackCategory.Other ? notesRequired : null
    );
  } else {
    notesValidators = notesMaxLength;
  }

  return (
    <Box display="flex" flexDirection="column">
      <ListingFeedbackHeader />
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        className={css.paneBody}
      >
        <Box mt={2}>
          <ListingFeedbackToggleButton
            Icon={selectedCategoryButtonDetails.Icon}
            structuredText={selectedCategoryButtonDetails.structuredText}
            value={selectedCategoryButtonDetails.key}
            aria-label={selectedCategoryButtonDetails.key}
            className="Mui-selected"
            style={{ cursor: 'default' }}
          />
        </Box>
        <Box width="75%">
          <Box my={2}>
            <TypographyWrapper variant="body1" typographyOverrides={{ align: 'center' }}>
              Any other details? Please specify the information you’d like the seller to update
              their listing with.
            </TypographyWrapper>
          </Box>
          <FieldTextInput
            id={ListingFeedbackFormField.Note}
            name={ListingFeedbackFormField.Note}
            g
            type="textarea"
            placeholder="eg. Can you take more photos of the item quirks?"
            className={css.freeformTextArea}
            style={{ border: 'none', minHeight: '140px' }}
            validate={notesValidators}
          />
        </Box>
        <Box my={2} width="65%">
          <TypographyWrapper
            variant="body1"
            weight={TypographyWeight.Bold}
            typographyOverrides={{ align: 'center', style: { fontSize: '12px' } }}
          >
            This is not a chat platform. You will not get a direct response from the seller, only a
            notification when the listing is updated.
            <br /> <br />
            Your note will be moderated by the Treet team.
          </TypographyWrapper>
        </Box>
      </Box>
    </Box>
  );
};

export default ListingFeedbackFreeformPane;
