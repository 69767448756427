/* eslint-disable import/prefer-default-export */
import { SeelAddress } from '../types/seel/seel';
import { CurrentUser } from '../types/sharetribe/currentUser';
import { ListingWithAuthorAndImages } from '../types/sharetribe/listing';
import { getBundleDisplayNumber } from './bundles';
import { getListingUrl } from './urlHelpers';
import { isDev, isTest } from './envHelpers';
import { CountryCode } from '../types/apollo/generated/types.generated';

export const SEEL_RETURN_WINDOW_DAYS = 7;
export const SEEL_RETURN_ASSURANCE = 'Return Assurance';

export const buildClaimUrl = (email: string, orderNumber: string) => {
  const baseUrl =
    isTest || isDev ? 'https://resolve-test.seel.com/treet' : 'https://resolve.seel.com/treet';
  return `${baseUrl}?email=${encodeURIComponent(email)}&order_number=${getBundleDisplayNumber(
    orderNumber
  )}&partner=treet`;
};

export const getReturnInsuranceQuoteParams = (
  listings: ListingWithAuthorAndImages[],
  canonicalRootUrl: string,
  treetId: string,
  sizeVariantOptionName: string,
  shippingAddress?: SeelAddress,
  currentUser?: CurrentUser
) => ({
  items: listings.map((listing) => {
    const { price, publicData = {} } = listing.attributes;
    const { category, color, condition, conditionInfo, originalPrice } = publicData;
    return {
      product_url: getListingUrl(canonicalRootUrl, listing),
      quantity: 1, // Right now, all of our listings are purchased individually
      price: price.amount / 100,
      title: listing.attributes.title,
      description: conditionInfo?.notes,
      extra_info: {
        product_id: listing.id.uuid,
        category_1: category,
        retail_price: originalPrice,
        shipping_origin: CountryCode.Us,
        condition,
        treet_shop_id: treetId,
        shop_url: canonicalRootUrl,
        size: publicData[sizeVariantOptionName],
        color,
      },
    };
  }),
  shipping_address: shippingAddress,
  extra_info: {
    customer_id: currentUser?.id.uuid,
    customer_email: currentUser?.attributes.email,
    seller_id: listings[0]?.author.id.uuid,
    seller_username: listings[0]?.author.attributes.profile.displayName,
    is_verified_direct_brand: listings[0]?.attributes.publicData?.isBrandDirect,
  },
});
