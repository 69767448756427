import { Box, Typography } from '@material-ui/core';
import React, { FC } from 'react';
import { useIsMobile } from '../../hooks/useIsMobile';
import { useStyleType } from '../../hooks/useStyleType';

type InformationModuleContent = {
  title: string;
  description: string;
  image?: React.ReactNode;
};

export enum ContentDirection {
  Vertical = 'VERTICAL',
  Horizontal = 'HORIZONTAL',
}

interface InformationModuleProps {
  content: InformationModuleContent[];
  direction?: ContentDirection;
  contentContainerOverrides?: any;
}

const InformationModule: FC<InformationModuleProps> = (props: InformationModuleProps) => {
  const { content, direction = ContentDirection.Vertical, contentContainerOverrides = {} } = props;

  const { isCondensedStyle } = useStyleType();

  const isMobile = useIsMobile();

  const isHorizontal = !isMobile && direction === ContentDirection.Horizontal;

  if (isHorizontal) {
    return (
      <Box display="flex">
        {content.map((c, index) => {
          const hasImage = !!c.image;
          const isLast = content.length - 1 === index;

          return (
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              mr={isLast ? {} : { xs: 1, sm: 3, md: 5, lg: 9 }}
              textAlign="center"
              flex={1}
              key={c.title}
              {...contentContainerOverrides}
            >
              {hasImage && <Box mb={2}>{c.image}</Box>}
              <Typography
                variant={hasImage ? 'body1' : 'h2'}
                style={{ fontWeight: 'bold', paddingBottom: isCondensedStyle ? '12px' : '8px' }}
              >
                {c.title}
              </Typography>
              <Typography variant="body1">{c.description}</Typography>
            </Box>
          );
        })}
      </Box>
    );
  }

  return (
    <>
      {content.map((c, index) => {
        const hasImage = !!c.image;
        const isLast = content.length - 1 === index;

        return (
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent={hasImage ? 'flex-start' : 'center'}
            textAlign={hasImage ? 'left' : 'center'}
            width="100%"
            mb={isLast ? 0 : 3}
            key={c.title}
            {...contentContainerOverrides}
          >
            {hasImage && <Box mr={2}>{c.image}</Box>}
            <Box display="flex" flexDirection="column">
              <Typography variant={hasImage ? 'body1' : 'h2'} style={{ fontWeight: 'bold' }}>
                {c.title}
              </Typography>
              <Box mt={1}>
                <Typography variant="body1">{c.description}</Typography>
              </Box>
            </Box>
          </Box>
        );
      })}
    </>
  );
};

export default InformationModule;
