import moment from 'moment';
import { getUrlSearchParams } from '../urlHelpers';
import { clearData, getStoredData, storeData } from './sessionHelpersUtils';

export const REFERRER_STORAGE_KEY = 'Referrer';

export enum Referrer {
  TreetShop = 'treet_shop',
}

export const storeReferrerData = (referrer: string) => {
  if (referrer) {
    const data = { referrer, storedAt: new Date() };
    storeData(REFERRER_STORAGE_KEY, data);
  }
};

export const getStoredReferrerData = () => {
  const { referrer = null, storedAt = null } = getStoredData(REFERRER_STORAGE_KEY);
  // If sessionStore contains freshly saved data (max 7 days old), use it
  const isFreshlySaved = storedAt ? moment(storedAt).isAfter(moment().subtract(7, 'days')) : false;

  if (referrer && isFreshlySaved) return { referrer };

  // Clear data if invalid
  clearData(REFERRER_STORAGE_KEY);
  return {};
};

export const processReferrerData = () => {
  const { referrer: storedReferrer } = getStoredReferrerData();
  if (!storedReferrer) {
    const urlSearchParams = getUrlSearchParams();
    const urlReferrer = urlSearchParams.get('referrer')?.toLowerCase();
    if (urlReferrer) storeReferrerData(urlReferrer);
    return { referrer: urlReferrer };
  }

  return { referrer: storedReferrer };
};
