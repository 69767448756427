import { Dispatch } from 'redux';
import { Listing } from '../../types/sharetribe/listing';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { RequestStatus } from '../../types/requestStatus';
import { DEFAULT_SOLD_LISTINGS_PAGE_SIZE, getTopSoldListings } from '../../util/ducks/soldListings';
import { denormalisedResponseEntities } from '../../util/data';
import * as log from '../../util/log';

// ================ Action types ================ //

export const FETCH_SOLD_LISTINGS_REQUEST = 'app/AboutPage/FETCH_SOLD_LISTINGS_REQUEST';
export const FETCH_SOLD_LISTINGS_SUCCESS = 'app/AboutPage/FETCH_SOLD_LISTINGS_SUCCESS';
export const FETCH_SOLD_LISTINGS_ERROR = 'app/AboutPage/FETCH_SOLD_LISTINGS_ERROR';

interface FetchSoldListingsRequest {
  type: typeof FETCH_SOLD_LISTINGS_REQUEST;
}

interface FetchSoldListingsSuccess {
  type: typeof FETCH_SOLD_LISTINGS_SUCCESS;
  soldListings: Listing[];
}

interface FetchSoldListingsError {
  type: typeof FETCH_SOLD_LISTINGS_ERROR;
}

type AboutPageActionType =
  | FetchSoldListingsRequest
  | FetchSoldListingsSuccess
  | FetchSoldListingsError;

// ================ Reducer ================ //

export interface AboutPageState {
  fetchSoldListingsStatus: RequestStatus;
  soldListings: Listing[];
}

const initialState: AboutPageState = {
  fetchSoldListingsStatus: RequestStatus.Ready,
  soldListings: [],
};

export default function AboutPageReducer(
  state: AboutPageState = initialState,
  action: AboutPageActionType
): AboutPageState {
  switch (action.type) {
    case FETCH_SOLD_LISTINGS_REQUEST: {
      return { ...state, fetchSoldListingsStatus: RequestStatus.Pending };
    }
    case FETCH_SOLD_LISTINGS_SUCCESS: {
      return {
        ...state,
        soldListings: action.soldListings,
        fetchSoldListingsStatus: RequestStatus.Success,
      };
    }
    case FETCH_SOLD_LISTINGS_ERROR: {
      return { ...state, fetchSoldListingsStatus: RequestStatus.Error };
    }
    default: {
      return state;
    }
  }
}

// ================ Action creators ================ //

const fetchSoldListingsRequest = () => ({ type: FETCH_SOLD_LISTINGS_REQUEST });

const fetchSoldListingsSuccess = (soldListings: Listing[]) => ({
  type: FETCH_SOLD_LISTINGS_SUCCESS,
  soldListings,
});

const fetchSoldListingsError = () => ({ type: FETCH_SOLD_LISTINGS_ERROR });

// ================ Thunks ================ //

export const fetchSoldListings = () => async (dispatch: Dispatch) => {
  dispatch(fetchSoldListingsRequest());

  let soldListings;
  try {
    const soldListingsResponse = await getTopSoldListings(1, DEFAULT_SOLD_LISTINGS_PAGE_SIZE);
    dispatch(addMarketplaceEntities(soldListingsResponse));
    soldListings = denormalisedResponseEntities(soldListingsResponse);
    dispatch(fetchSoldListingsSuccess(soldListings));
  } catch (e) {
    log.error(e, 'about-page-fetch-sold-listings-failed', {});
    dispatch(fetchSoldListingsError());
  }
  return soldListings;
};
