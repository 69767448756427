import React, { ReactElement } from 'react';

interface ConditionalWrapperProps {
  condition: boolean;
  wrapper: (children?: ReactElement) => ReactElement;
  children?: ReactElement;
}

const ConditionalWrapper: React.FC<ConditionalWrapperProps> = (props) => {
  const { condition, wrapper, children } = props;
  return condition ? wrapper(children) : children || null;
};

export default ConditionalWrapper;
