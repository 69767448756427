import { getBrandDirectFilterConfig } from '../filters/brandDirect';
import { defaultClothesConditionsOptions, getConditionsFilterConfig } from '../filters/condition';
import { getSizeFilterConfig } from '../filters/size';
import { getDefaultShopConfig } from './default';
import * as custom from '../filters/marketplace-custom-config';

const maboclothierSizeOptions = [
  'XXSMALL',
  'XSMALL',
  'SMALL',
  'MEDIUM',
  'LARGE',
  'XLARGE',
  'XXLARGE',
  '3m',
  '6m',
  '12m',
  '1y',
  '18m',
  '2/3',
  '4/5',
  '6/7',
  '8/9',
  '10/11',
  '12/13',
  '14/15',
];

export const maboclothierShopConfig = {
  ...getDefaultShopConfig(),
  filters: [
    ...custom.filters,
    getBrandDirectFilterConfig(),
    getConditionsFilterConfig({ options: defaultClothesConditionsOptions }),
    getSizeFilterConfig({ sizes: maboclothierSizeOptions }),
  ],
};
