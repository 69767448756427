import { getDefaultShopConfig } from './default';
import {
  Conditions,
  defaultClothesConditionsOptions,
  getConditionsFilterConfig,
} from '../filters/condition';
import * as custom from '../filters/marketplace-custom-config';
import { getSizeFilterConfig } from '../filters/size';
import { BuilderSections } from '../../util/builder';
import { getColorFilterConfig } from '../filters/color';
import { getGenderFilterConfig } from '../filters/gender';
import { User } from '../../types/sharetribe/user';

const sizes = [
  '5 (Womens)',
  '5.5 (Womens)',
  '6 (Womens)',
  '6.5 (Womens)',
  '7 (Womens)',
  '7.5 (Womens)',
  '8 (Womens)',
  '8.5 (Womens)',
  '9 (Womens)',
  '9.5 (Womens)',
  '10 (Womens)',
  '10.5 (Womens)',
  '11 (Womens)',
  '12 (Womens)',
  '7 (Mens)',
  '7.5 (Mens)',
  '8 (Mens)',
  '8.5 (Mens)',
  '9 (Mens)',
  '9.5 (Mens)',
  '10 (Mens)',
  '10.5 (Mens)',
  '11 (Mens)',
  '11.5 (Mens)',
  '12 (Mens)',
  '12.5 (Mens)',
  '13 (Mens)',
  '14 (Mens)',
  '15 (Mens)',
  'OS',
  'XS',
  'S',
  'M',
  'L',
  'XL',
  'XXL',
  '3XL',
  '32',
  '33',
  '34',
  '35',
  '36',
  '37',
  '38',
  '39',
  '40',
  '41',
  '42',
  '43',
  '44',
];

const handleTecovasCustomCreditDescription = (data: {
  bundle: {
    shipFromAddress: { state: string };
  };
  recipient: User;
}) => {
  const { state } = data.bundle?.shipFromAddress || {};
  const { email } = data.recipient?.attributes || {};

  return `Email: ${email}, State: ${state || 'Unknown'}`;
};

const tecovasConditionsOptions = [
  ...defaultClothesConditionsOptions.slice(0, 3),
  {
    key: Conditions.Good,
    label: 'Good Condition',
    description:
      'You’ve worn this item and it’s well maintained. There may be some quirks like a small stain or a loose thread, but it’s overall in good shape. You will need to take pictures of any quirks the item has.',
  },
];

export const tecovasShopConfig = {
  ...getDefaultShopConfig(),
  filters: [
    ...custom.filters,
    getConditionsFilterConfig({ options: tecovasConditionsOptions }),
    getSizeFilterConfig({ sizes }),
    getColorFilterConfig(),
    getGenderFilterConfig(),
  ],
  builderConfig: {
    sections: {
      [BuilderSections.LandingPageContent]: 'fb80b42e5cd04f07bbf4a050a8afa7ab',
      [BuilderSections.AboutPageNavbarContent]: 'e805bce405d9496bb79f7823a8ab2303',
      [BuilderSections.ListingPageTitleAndVariantDetailsContent]:
        '45df355f4afe4403a763f679b971e792',
    },
  },
  additionalPayoutSettings: {
    buildCustomCreditDescription: handleTecovasCustomCreditDescription,
  },
};
