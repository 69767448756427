/* eslint-disable import/prefer-default-export */
import { getSizeFilterConfig } from '../filters/size';
import { getDefaultShopConfig } from './default';
import * as custom from '../filters/marketplace-custom-config';
import { getConditionsFilterConfig } from '../filters/condition';
import { BuilderSections } from '../../util/builder';

const bandierSizeOptions = [
  'OS',
  'XXS',
  'XS',
  'S',
  'M',
  'L',
  'XL',
  '2XL',
  '1X',
  '2X',
  '5',
  '5.5',
  '6',
  '6.5',
  '7',
  '7.5',
  '8',
  '8.5',
  '9',
  '9.5',
  '10',
  '10.5',
  '11',
];

export const bandierShopConfig = {
  ...getDefaultShopConfig(),
  filters: [
    ...custom.filters,
    getConditionsFilterConfig(),
    getSizeFilterConfig({ sizes: bandierSizeOptions }),
  ],
  builderConfig: {
    sections: {
      [BuilderSections.LandingPageContent]: 'b81257926383420ba80d60d63929c1d1',
    },
  },
};
