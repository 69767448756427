import { isArray, isEmpty, mergeWith } from 'lodash';

// returns if there are any intersected elements between 2 arrays
export function hasIntersection(arr1, arr2) {
  if (!arr1 || !arr2) {
    return false;
  }
  return arr2.filter((a) => arr1.some((b) => a === b)).length > 0;
}

export function capitalizeFirstLetter(text) {
  if (!text || text.length < 1) {
    return text;
  }
  return text.charAt(0).toUpperCase() + text.slice(1);
}

const gcd = (a, b) => (b ? gcd(b, a % b) : a);

// Based off of https://gist.github.com/redteam-snippets/3934258
/**
 *
 * @param {float} decimal Decimal representation of a aspect ratio like 1.5
 * @returns {object} Fraction representation of an aspect ratio like 2:3
 */
export const decimalToFraction = (decimal) => {
  if (decimal === 1) {
    return {
      top: 1,
      bottom: 1,
      display: `${1}:${1}`,
      flippedDisplay: `${1}:${1}`,
    };
  }
  let top = decimal.toString().replace(/\d+[.]/, '');
  const bottom = 10 ** top.length;
  if (decimal > 1) {
    top = +top + Math.floor(decimal) * bottom;
  }
  const x = gcd(top, bottom);
  return {
    top: top / x,
    bottom: bottom / x,
    display: `${top / x}:${bottom / x}`,
    flippedDisplay: `${bottom / x}:${top / x}`,
  };
};

// Based off of https://gist.github.com/jonathantneal/d3a259ebeb46de7ab0de#file-closestaspectratio-js
export const nearestNormalAspectRatio = (width, height, side, maxWidth = 8, maxHeight = 8) => {
  const ratio = (width * 100) / (height * 100),
    ratiosW = new Array(maxWidth).join(',').split(','),
    ratiosH = new Array(maxHeight).join(',').split(','),
    ratiosT = {},
    ratios = {};
  let match = null,
    key = null;

  ratiosW.forEach((empty, ratioW) => {
    let ratioWCopy = ratioW;
    ++ratioWCopy;

    ratiosH.forEach((_, ratioH) => {
      let ratioHCopy = ratioH;
      ++ratioHCopy;

      const ratioX = (ratioWCopy * 100) / (ratioHCopy * 100);

      if (!ratiosT[ratioX]) {
        ratiosT[ratioX] = true;

        ratios[`${ratioWCopy}:${ratioHCopy}`] = ratioX;
      }
    });
  });

  // eslint-disable-next-line no-restricted-syntax
  for (key in ratios) {
    if (
      !match ||
      (!side && Math.abs(ratio - ratios[key]) < Math.abs(ratio - ratios[match])) ||
      (side < 0 &&
        ratios[key] <= ratio &&
        Math.abs(ratio - ratios[key]) < Math.abs(ratio - ratios[match])) ||
      (side > 0 &&
        ratios[key] >= ratio &&
        Math.abs(ratio - ratios[key]) < Math.abs(ratio - ratios[match]))
    ) {
      match = key;
    }
  }

  const [x, y] = match.split(':');
  return { displayRatio: match, ratio: Number(x) / Number(y) };
};

// Deep merges 2 objects, but doesn't merge arrays. Also overrides null values
export const deepMergeObjects = (objA, objB) =>
  mergeWith(objA, objB, (a, b) => {
    // Override nulls
    if (b === null) {
      return a;
    }

    // If array, don't merge
    return isArray(b) && !isEmpty(b) ? b : undefined;
  });
