/* eslint-disable import/prefer-default-export */
import { getDefaultShopConfig, getDefaultTradeInShopConfig } from './default';
import * as custom from '../filters/marketplace-custom-config';
import { Conditions, getConditionsFilterConfig } from '../filters/condition';
import { getSizeFilterConfig } from '../filters/size';
import { BuilderSections } from '../../util/builder';
import { getGenderFilterConfig } from '../filters/gender';

const kurufootwearSizes = [
  '5',
  '5.5',
  '6',
  '6.5',
  '7',
  '7.5',
  '8',
  '8.5',
  '9',
  '9.5',
  '10',
  '10.5',
  '11',
  '11.5',
  '12',
  '12.5',
  '13',
  '13.5',
  '14',
];

const kurufootwearConditions = [
  {
    key: Conditions.NewWithTags,
    label: 'New In Box',
    shortLabel: 'New in Box',
    description: 'Your shoes have never been worn and they’re still in the original box.',
  },
  {
    key: Conditions.NewWithoutTags,
    label: 'New Without Box',
    shortLabel: 'New w/o Box',
    description:
      'Your shoes have never been worn (other than perhaps trying it on), but you no longer have the box. There are no quirks or issues whatsoever.',
  },
  {
    key: Conditions.Excellent,
    label: 'Excellent Condition',
    shortLabel: 'Excellent',
    description:
      'Your shoes have only been lightly used and have been extremely well maintained. There may be some small signs of wear, but nothing major.',
  },
  {
    key: Conditions.Good,
    label: 'Good Condition',
    shortLabel: 'Good',
    description:
      'You’ve worn the shoes and they’re well maintained. There may be some quirks like a small stain or a scuff mark, but they’re overall in good shape.',
  },
];

export const kurufootwearShopConfig = {
  ...getDefaultShopConfig(),
  ...getDefaultTradeInShopConfig(),
  filters: [
    ...custom.filters,
    getGenderFilterConfig(),
    getConditionsFilterConfig({ options: kurufootwearConditions }),
    getSizeFilterConfig({ sizes: kurufootwearSizes }),
  ],
  builderConfig: {
    sections: {
      [BuilderSections.LandingPageContent]: 'c07a4746372f47a4a916dc07c6d923f6',
    },
  },
};
