import { ThunkDispatch } from 'redux-thunk';
import { UpdateGeneralSavedSearchEmailSubscriptionParams } from '../types/models/savedSearch';
import { RequestStatus } from '../types/requestStatus';
import { updateGeneralSavedSearchEmailSubscription } from './user.duck';

// ================ Action types ================ //

const SET_STORAGE_KEY = 'app/subscribeModal/SET_STORAGE_KEY';

const SUBSCRIBE_EMAIL_REQUEST = 'app/subscribeModal/SUBSCRIBE_EMAIL_REQUEST';
const SUBSCRIBE_EMAIL_SUCCESS = 'app/subscribeModal/SUBSCRIBE_EMAIL_SUCCESS';
const SUBSCRIBE_EMAIL_ERROR = 'app/subscribeModal/SUBSCRIBE_EMAIL_ERROR';

const SUBSCRIBE_SIZES_REQUEST = 'app/subscribeModal/SUBSCRIBE_SIZES_REQUEST';
const SUBSCRIBE_SIZES_SUCCESS = 'app/subscribeModal/SUBSCRIBE_SIZES_SUCCESS';
const SUBSCRIBE_SIZES_ERROR = 'app/subscribeModal/SUBSCRIBE_SIZES_ERROR';

interface SetStorageKey {
  type: typeof SET_STORAGE_KEY;
  storageKey: string;
}

interface SubscribeEmailRequest {
  type: typeof SUBSCRIBE_EMAIL_REQUEST;
}

interface SubscribeEmailSuccess {
  type: typeof SUBSCRIBE_EMAIL_SUCCESS;
  email: string;
}

interface SubscribeEmailError {
  type: typeof SUBSCRIBE_EMAIL_ERROR;
}

interface SubscribeSizesRequest {
  type: typeof SUBSCRIBE_SIZES_REQUEST;
}

interface SubscribeSizesSuccess {
  type: typeof SUBSCRIBE_SIZES_SUCCESS;
}

interface SubscribeSizesError {
  type: typeof SUBSCRIBE_SIZES_ERROR;
}

type SubscribeModalActionType =
  | SetStorageKey
  | SubscribeEmailRequest
  | SubscribeEmailSuccess
  | SubscribeEmailError
  | SubscribeSizesRequest
  | SubscribeSizesSuccess
  | SubscribeSizesError;

// ================ Reducer ================ //

export interface SubscribeModalState {
  storageKey: string;
  email: string | null;
  subscribeEmailStatus: RequestStatus;
  subscribeSizesStatus: RequestStatus;
}

const initialState: SubscribeModalState = {
  storageKey: '',
  email: null,
  subscribeEmailStatus: RequestStatus.Ready,
  subscribeSizesStatus: RequestStatus.Ready,
};

export default function reducer(
  state: SubscribeModalState = initialState,
  action: SubscribeModalActionType
): SubscribeModalState {
  switch (action.type) {
    case SET_STORAGE_KEY: {
      return { ...state, storageKey: action.storageKey };
    }
    case SUBSCRIBE_EMAIL_REQUEST: {
      return { ...state, subscribeEmailStatus: RequestStatus.Pending };
    }
    case SUBSCRIBE_EMAIL_SUCCESS: {
      return {
        ...state,
        email: action.email,
        subscribeEmailStatus: RequestStatus.Success,
      };
    }
    case SUBSCRIBE_EMAIL_ERROR: {
      return { ...state, subscribeEmailStatus: RequestStatus.Error };
    }
    case SUBSCRIBE_SIZES_REQUEST: {
      return { ...state, subscribeSizesStatus: RequestStatus.Pending };
    }
    case SUBSCRIBE_SIZES_SUCCESS: {
      return { ...state, subscribeSizesStatus: RequestStatus.Success };
    }
    case SUBSCRIBE_SIZES_ERROR: {
      return { ...state, subscribeSizesStatus: RequestStatus.Error };
    }
    default: {
      return state;
    }
  }
}

// ================ Action creators ================ //

export const setStorageKey = (storageKey: string): SetStorageKey => ({
  type: SET_STORAGE_KEY,
  storageKey,
});

const subscribeEmailRequest = () => ({ type: SUBSCRIBE_EMAIL_REQUEST });

const subscribeEmailSuccess = (email: string) => ({ type: SUBSCRIBE_EMAIL_SUCCESS, email });

const subscribeEmailError = () => ({ type: SUBSCRIBE_EMAIL_ERROR });

const subscribeSizesRequest = () => ({ type: SUBSCRIBE_SIZES_REQUEST });

const subscribeSizesSuccess = () => ({ type: SUBSCRIBE_SIZES_SUCCESS });

const subscribeSizesError = () => ({ type: SUBSCRIBE_SIZES_ERROR });

/* ================ Thunks ================ */

export const subscribeEmail =
  (params: UpdateGeneralSavedSearchEmailSubscriptionParams) =>
  async (dispatch: ThunkDispatch<any, any, any>) => {
    const { email } = params;
    dispatch(subscribeEmailRequest());

    try {
      const response = await dispatch(updateGeneralSavedSearchEmailSubscription(params));
      dispatch(subscribeEmailSuccess(email));
      return response;
    } catch (e) {
      // Error is already logged
      dispatch(subscribeEmailError());
      return null;
    }
  };

export const subscribeSizes =
  (params: UpdateGeneralSavedSearchEmailSubscriptionParams) =>
  async (dispatch: ThunkDispatch<any, any, any>) => {
    dispatch(subscribeSizesRequest());

    try {
      const response = await dispatch(updateGeneralSavedSearchEmailSubscription(params));
      dispatch(subscribeSizesSuccess());
      return response;
    } catch (e) {
      // Error is already logged
      dispatch(subscribeSizesError());
      return null;
    }
  };
