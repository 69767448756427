import { getDefaultShopConfig } from './default';
import * as custom from '../filters/marketplace-custom-config';
import { getBrandDirectFilterConfig } from '../filters/brandDirect';
import { getConditionsFilterConfig } from '../filters/condition';
import { getSizeFilterConfig } from '../filters/size';

const elfinLosAngelesSizes = [
  'Newborn',
  'Infant',
  '0 - 3 months',
  '3 - 6 months',
  '6 - 12 months',
  '12 - 18 months',
  '18 - 24 months',
  '2T',
  '2/3T',
  '3T',
  '3/4T',
  '4T',
  '4/5T',
  '5T',
  '5Y',
  '6Y',
  '7Y',
  '8Y',
  '9Y',
  '10Y',
  'XS',
  'S',
  'M',
  'L',
  'XL',
  'OS',
];

export const elfinlosangelesShopConfig = {
  ...getDefaultShopConfig(),
  filters: [
    ...custom.filters,
    getBrandDirectFilterConfig(),
    getConditionsFilterConfig(),
    getSizeFilterConfig({ sizes: elfinLosAngelesSizes }),
  ],
};
