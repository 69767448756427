import { getDefaultShopConfig } from './default';
import * as custom from '../filters/marketplace-custom-config';
import { getBrandDirectFilterConfig } from '../filters/brandDirect';
import { getConditionsFilterConfig } from '../filters/condition';
import { getSizeFilterConfig } from '../filters/size';

const westernandcoSizeOptions = [
  'Small',
  'Medium',
  'Large',
  'X-Large',
  'XX-Large',
  '3-6 mo',
  '6-12 mo',
  '12-18 mo',
  '18-24 mo',
  '2T',
  '3T',
  '4T',
  '5T',
  '6T',
];

export const westernandcoShopConfig = {
  ...getDefaultShopConfig(),
  filters: [
    ...custom.filters,
    getConditionsFilterConfig(),
    getBrandDirectFilterConfig(),
    getSizeFilterConfig({ sizes: westernandcoSizeOptions }),
  ],
};
