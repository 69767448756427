/**
 * A text field with phone number formatting and validation.
 */
import React, { FC } from 'react';
import { Field, FieldRenderProps } from 'react-final-form';
import classNames from 'classnames';
import { useTheme } from '@material-ui/core';
import PhoneInput from 'react-phone-number-input/input';
import { TypographyWrapper, ValidationError } from '..';
import { useUserCountryConfig } from '../../hooks/useCountryConfig';
import { useShopConfig } from '../../hooks/shopConfig';
import { FontConfigSection } from '../../types/shopConfig/shopConfigV2';
import { CountryCode } from '../../types/apollo/generated/types.generated';
import css from './FieldPhoneNumberInput.module.css';

export interface FieldPhoneNumberInputProps extends FieldRenderProps<string> {
  name: string;
  country?: CountryCode;
  className?: string;
  showAsOptional?: boolean;
}

const FieldPhoneNumberInputComponent: FC<FieldPhoneNumberInputProps> = (props) => {
  const { className, country, id, label, input, meta, showAsOptional, ...rest } = props;
  const { fontConfig } = useShopConfig();
  const theme = useTheme();
  const { countryCode: userCountryCode } = useUserCountryConfig();

  if (label && !id) {
    throw new Error('Id required when a label is given');
  }

  const { valid, invalid, touched, error } = meta;
  const labelTypographyVariant = fontConfig[FontConfigSection.FieldLabel];
  const body1Font = theme?.typography?.body1;

  // Error message and input error styles are only shown if the
  // field has been touched and the validation has failed.
  const hasError = !!(touched && invalid && error);
  const fieldMeta = { touched: hasError, error };

  const inputClasses = classNames(css.input, {
    [css.inputSuccess]: valid,
    [css.inputError]: hasError,
  });
  const inputProps = {
    className: inputClasses,
    id,
    type: 'text',
    ...input,
    ...rest,
  };

  const classes = classNames(css.root, className);
  return (
    <div className={classes}>
      {label ? (
        <label htmlFor={id}>
          <TypographyWrapper
            variant={labelTypographyVariant}
            typographyOverrides={{ style: { fontWeight: 'bold' }, display: 'inline' }}
          >
            {label}
          </TypographyWrapper>
          {showAsOptional && (
            <span className={css.optional}>
              <TypographyWrapper
                variant={labelTypographyVariant}
                typographyOverrides={{ display: 'inline' }}
              >
                {' (Optional)'}
              </TypographyWrapper>
            </span>
          )}
        </label>
      ) : null}
      <PhoneInput style={body1Font} country={country || userCountryCode} {...inputProps} />
      <ValidationError fieldMeta={fieldMeta} />
    </div>
  );
};

const FieldPhoneNumberInput: FC<FieldPhoneNumberInputProps> = (props) => (
  <Field component={FieldPhoneNumberInputComponent} {...props} />
);

export default FieldPhoneNumberInput;
