import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { IHighlightBanner } from '../../types/contentful/types.generated';
import HighlightBanner from './HighlightBanner';

interface GeneralShopConfigHighlightBannerProps {
  currentPage?: string;
}

const GeneralShopConfigHighlightBanner: FC<GeneralShopConfigHighlightBannerProps> = ({
  currentPage,
}) => {
  const { generalShopConfig, treetId } = useSelector<any>((state) => state.initial) as any;

  const getHighlightBannerToDisplay = () => {
    const possibleBanners = generalShopConfig?.highlightBannersCollection?.items || [];
    return possibleBanners.find((banner: IHighlightBanner) => {
      const { includedTreetIds, excludedTreetIds, shouldShowBanner, includedPages } = banner;

      return (
        (!includedTreetIds || includedTreetIds.includes(treetId)) &&
        (!excludedTreetIds || !excludedTreetIds.includes(treetId)) &&
        (!includedPages || (currentPage && includedPages?.includes(currentPage))) &&
        shouldShowBanner
      );
    });
  };

  const highlightBanner = getHighlightBannerToDisplay();

  if (!highlightBanner) {
    return null;
  }

  return <HighlightBanner text={highlightBanner.text} color={highlightBanner.color} />;
};

export default GeneralShopConfigHighlightBanner;
