import { getDefaultShopConfig } from './default';
import * as custom from '../filters/marketplace-custom-config';
import { getBrandDirectFilterConfig } from '../filters/brandDirect';
import { getConditionsFilterConfig } from '../filters/condition';
import { getSizeFilterConfig } from '../filters/size';
import { BuilderSections } from '../../util/builder';

const asrvSizes = ['OS', 'XS', 'S', 'S/M', 'M', 'M/L', 'L', 'L/XL', 'XL', 'XXL'];

export const asrvShopConfig = {
  ...getDefaultShopConfig(),
  filters: [
    ...custom.filters,
    getConditionsFilterConfig(),
    getBrandDirectFilterConfig(),
    getSizeFilterConfig({ sizes: asrvSizes }),
  ],
  builderConfig: {
    sections: {
      [BuilderSections.LandingPageContent]: 'a10b58d2f72b41e29049c7adab83214e',
      [BuilderSections.ListingCardDetails]: 'c40017afa549438da6a29340651ba18e',
    },
  },
};
