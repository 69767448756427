// Sendgrid contact lists
export const RELEASES_AND_DISCOUNTS_GROUP_ID = 22503;
export const ITEM_REQUEST_NOTIFICATIONS_GROUP_ID = 27210;
export const LISTING_FEEDBACK_BUYER_UPDATES_GROUP_ID = 27948;

// keep in sync with server/helpers/emailDesign/constants.ts
export const LISTING_FEEDBACK_BUYER_UPDATE_EMAIL_UTM_CAMPAIGN =
  'listing-feedback-buyer-update-email';

// listName is used for tracking purposes
const SENDGRID_CONTACT_LISTS = {
  updatesSubscribeList: {
    listName: 'Sendgrid Updates Subscribe List',
    listId: '096e230f-1602-486f-beab-2befc5a5b447',
  },
  treetShopSubscribeList: {
    listName: 'Sendgrid Treet Shop Subscribe List',
    listId: 'fd1ecbd8-0b03-42a0-88ff-b34fbe91efe3',
  },
};

export default SENDGRID_CONTACT_LISTS;
