import { Box, Typography } from '@material-ui/core';
import React, { FC } from 'react';
import { defaultTreetStyles } from '../../shopConfig/config';
import TextWithLineBreaks from '../TextWithLineBreaks/TextWithLineBreaks';

interface HighlightBannerProps {
  text: string;
  color?: string;
}

const HighlightBanner: FC<HighlightBannerProps> = (props: HighlightBannerProps) => {
  const { text, color } = props;
  return (
    <Box bgcolor={color || defaultTreetStyles.gray80} p={2} textAlign="center">
      <Typography variant="body1" style={{ color: 'white' }}>
        <TextWithLineBreaks text={text} />
      </Typography>
    </Box>
  );
};

export default HighlightBanner;
