import React, { FC } from 'react';

interface IconShopifySyncProps {
  className?: string;
}
const IconShopifySync: FC<IconShopifySyncProps> = (props: IconShopifySyncProps) => {
  const { className } = props;
  return (
    <svg
      className={className}
      width="40"
      height="40"
      viewBox="0 0 288 227"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M159.7 59.5068C159.7 59.5068 158.3 59.9068 156 60.6068C155.6 59.3068 155 57.8068 154.2 56.2068C151.6 51.2068 147.7 48.5068 143.1 48.5068C142.8 48.5068 142.5 48.5068 142.1 48.6068C142 48.4068 141.8 48.3068 141.7 48.1068C139.7 45.9068 137.1 44.9068 134 45.0068C128 45.2068 122 49.5068 117.2 57.2068C113.8 62.6068 111.2 69.4068 110.5 74.7068C103.6 76.8068 98.8 78.3068 98.7 78.4068C95.2 79.5068 95.1 79.6068 94.7 82.9068C94.1 85.4068 85 155.907 85 155.907L160.6 169.007V59.3068C160.2 59.4068 159.9 59.4068 159.7 59.5068ZM142.2 64.9068C138.2 66.1068 133.8 67.5068 129.5 68.8068C130.7 64.1068 133.1 59.4068 135.9 56.3068C137 55.2068 138.5 53.9068 140.2 53.1068C141.9 56.7068 142.3 61.6068 142.2 64.9068ZM134.1 49.0068C135.5 49.0068 136.7 49.3068 137.7 49.9068C136.1 50.7068 134.5 52.0068 133 53.5068C129.2 57.6068 126.3 64.0068 125.1 70.1068C121.5 71.2068 117.9 72.3068 114.6 73.3068C116.7 63.8068 124.8 49.3068 134.1 49.0068ZM122.4 104.007C122.8 110.407 139.7 111.807 140.7 126.907C141.4 138.807 134.4 146.907 124.3 147.507C112.1 148.307 105.4 141.107 105.4 141.107L108 130.107C108 130.107 114.7 135.207 120.1 134.807C123.6 134.607 124.9 131.707 124.8 129.707C124.3 121.307 110.5 121.807 109.6 108.007C108.8 96.5068 116.4 84.8068 133.2 83.7068C139.7 83.3068 143 84.9068 143 84.9068L139.2 99.3068C139.2 99.3068 134.9 97.3068 129.8 97.7068C122.4 98.2068 122.3 102.907 122.4 104.007ZM146.2 63.7068C146.2 60.7068 145.8 56.4068 144.4 52.8068C149 53.7068 151.2 58.8068 152.2 61.9068C150.4 62.4068 148.4 63.0068 146.2 63.7068Z"
        fill="black"
      />
      <path
        d="M163.1 168.607L194.5 160.807C194.5 160.807 181 69.5068 180.9 68.9068C180.8 68.3068 180.3 67.9068 179.8 67.9068C179.3 67.9068 170.5 67.7068 170.5 67.7068C170.5 67.7068 165.1 62.5068 163.1 60.5068V168.607Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M139.5 31.1875C94.0401 31.1875 57.1875 68.0401 57.1875 113.5H29.75C29.75 52.8868 78.8868 3.75 139.5 3.75C165.875 3.75 190.108 13.0709 209.034 28.5839L222.198 15.4195L233.268 70.7694L177.918 59.6994L189.506 48.1119C175.641 37.4898 158.322 31.1875 139.5 31.1875ZM45.7318 156.231L56.8018 211.581L69.9662 198.416C88.8918 213.929 113.125 223.25 139.5 223.25C200.113 223.25 249.25 174.113 249.25 113.5H221.812C221.812 158.96 184.96 195.812 139.5 195.812C120.678 195.812 103.359 189.51 89.4943 178.888L101.082 167.301L45.7318 156.231Z"
        fill="black"
      />
    </svg>
  );
};

export default IconShopifySync;
