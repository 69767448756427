/* eslint-disable import/prefer-default-export */

/**
 * Returns an array of src urls, ordered by the most optimal to the least (fallback).
 */
export const getOptimizedImageSrcs = (src?: string, width?: string) => {
  if (!src) return [];

  const imageUrl = new URL(src);

  let fallbackImageUrl = src;
  if (width) {
    if (imageUrl.searchParams.has('w')) {
      imageUrl.searchParams.delete('w');
    }
    imageUrl.searchParams.append('w', width);
    fallbackImageUrl = String(imageUrl.href);
  }

  if (imageUrl.searchParams.has('fm')) {
    imageUrl.searchParams.delete('fm');
  }
  imageUrl.searchParams.append('fm', 'webp');

  // Loads webp image if supported, otherwise falls back to non-webp
  return [imageUrl.href, fallbackImageUrl];
};
