// eslint-disable-next-line import/no-cycle
import { compact, isEmpty, isEqual, sortBy } from 'lodash';
import moment from 'moment';
import { CustomerExperience } from '../types/shopConfig/shopConfigV2';
import { Feature, getFeatureValue } from '../util/featureFlags';
import { getConditionLabel } from '../util/listings/listing';
import config from './config';
import { buildShopConfig, combineV1AndV2Config } from './configV2';
import {
  IBrandPromos,
  IPromoTypeDiscountCode,
  IPromoTypePayoutCreditBoost,
  IPromoTypeSlashBdPrices,
} from '../types/contentful/types.generated';
import { isDev, isStaging, isTest } from '../util/envHelpers';

export function getShopConfigHelper(treetId: string, contentfulShopConfig: any) {
  const { shopConfigs } = config;

  // If V1 exists, use that
  if (treetId in shopConfigs) {
    // If both exist, we need to combine them
    if (contentfulShopConfig) {
      const shopConfigV2 = buildShopConfig(contentfulShopConfig);
      return combineV1AndV2Config((shopConfigs as any)[treetId], shopConfigV2);
    }
    return (shopConfigs as any)[treetId];
  }

  return {};
}

// If using in a functional component, use getShopConfig in ./hooks.ts instead
export function getShopConfig(treetId: string, shopConfigV2: any) {
  return getShopConfigHelper(treetId, shopConfigV2);
}

//  Category related functions
export function isParentCategory(categoryConfig: any) {
  return !categoryConfig.parentCategories || categoryConfig.parentCategories.length < 1;
}

export function getSubcategories(categories: any[]) {
  return categories.filter((category) => !isParentCategory(category));
}

export function getSubcategoriesForCategory(categories: any[], chosenCategory: string) {
  const subcategories = categories.filter((category) =>
    category.parentCategories?.includes(chosenCategory)
  );

  return subcategories;
}

export function getCanonicalRootUrlForEnv(canonicalRootUrl: string) {
  if (process.env.REACT_APP_ENV === 'production') {
    return canonicalRootUrl;
  }
  return window.location.origin;
}

// Returns an object like {
//   size: 'XS',
//   red: 'red',
//   condition: 'New With Tags',
// }

type ItemVariantsAndCondition = {
  [x: string]: string;
};
export function getItemVariantsAndCondition(
  listing: any,
  variantOptions: string[],
  filterConfig: any
) {
  // add variants the listing has
  const itemInfo: ItemVariantsAndCondition = variantOptions.reduce((result, variant) => {
    const newResult: ItemVariantsAndCondition = { ...result };
    if (listing?.attributes?.publicData?.[variant]) {
      newResult[variant] = listing?.attributes?.publicData?.[variant];
    }
    return newResult;
  }, {});

  // add condition
  itemInfo.condition = getConditionLabel(listing.attributes.publicData.condition, filterConfig);
  return itemInfo;
}

// Get all the possible options for a filter
export function getFilterOptions(shopConfig: any, filterConfigId: string): any {
  return shopConfig?.filters?.find((filter: any) => filter?.id === filterConfigId)?.config?.options;
}

export function getShopsCanonicalRootUrl(
  shopsProductionUrl: string | undefined,
  treetId: string
): any {
  if (isDev) {
    return `http://${treetId}.localhost:3000`;
  }
  if (isTest) {
    return `https://${treetId}.treet-test.co`;
  }
  if (isStaging) {
    return `https://${treetId}.treet-staging.co`;
  }
  return shopsProductionUrl;
}

export const getEnabledCustomerExperiences = (
  experiencesFromConfig: CustomerExperience[] | undefined,
  wasBuyEnabled?: boolean | undefined
) => {
  const experiencesOverride = getFeatureValue(Feature.EnabledCustomerExperiences);

  let experiences = !isEmpty(experiencesOverride) ? experiencesOverride : experiencesFromConfig;
  experiences = experiences?.sort();

  // Flags to indicate strict shop types.
  const isShopClosed = isEmpty(experiences);
  const isBrandDirectOnly = isEqual(experiences, [CustomerExperience.Buy]);
  const isTradeInOnly = isEqual(experiences, [CustomerExperience.TradeIn]);
  const isMarketplaceOnly = isEqual(experiences, [CustomerExperience.Buy, CustomerExperience.Sell]);
  const isBuyAndTradeInOnly = isEqual(experiences, [
    CustomerExperience.Buy,
    CustomerExperience.TradeIn,
  ]);

  // Flags to indicate if certain experience combinations are enabled on the shop.
  const allowTradeIn = !!experiences?.includes(CustomerExperience.TradeIn);
  const allowMarketplace = [CustomerExperience.Buy, CustomerExperience.Sell].every(
    (marketplaceExperience) => experiences?.includes(marketplaceExperience)
  );
  const allowTradeInAndMarketplace = [
    CustomerExperience.Buy,
    CustomerExperience.Sell,
    CustomerExperience.TradeIn,
  ].every((marketplaceExperience) => experiences?.includes(marketplaceExperience));
  const allowBuy = !!experiences?.includes(CustomerExperience.Buy);
  const allowSell = !!experiences?.includes(CustomerExperience.Sell);
  const allowList = [CustomerExperience.Sell, CustomerExperience.TradeIn].some(
    (marketplaceExperience) => experiences?.includes(marketplaceExperience)
  );

  // Flag to indicate listing options. As of 01/27/23, listing options currently
  // supported are: SELL, TRADE_IN.
  const isListTradeInOnly = allowTradeIn && !allowSell;
  const isListSellOnly = allowSell && !allowTradeIn;
  const hasBuyHistory = allowBuy || (wasBuyEnabled ?? false);
  const hasOrderHistory = !isTradeInOnly || hasBuyHistory;

  return {
    // Flags to indicate strict shop types.
    isShopClosed,
    isBrandDirectOnly,
    isTradeInOnly,
    isMarketplaceOnly,
    isBuyAndTradeInOnly,
    // Flags to indicate if certain experience combinations are enabled on the shop.
    allowTradeIn,
    allowMarketplace,
    allowBuy,
    allowSell,
    allowList,
    allowTradeInAndMarketplace,
    // Flag to indicate listing options.
    isListTradeInOnly,
    isListSellOnly,
    // Flag to indicate if the shop has buy enabled currently or in the past
    // Relevant for shops that have briefly enabled the shop experience for a drop
    hasBuyHistory,
    hasOrderHistory,
  };
};

enum PromoTypes {
  DiscountCode = 'DISCOUNT_CODE',
  SlashBdPrices = 'SLASH_BD_PRICES',
  PayoutCreditBoost = 'PAYOUT_CREDIT_BOOST',
}

export type DiscountCodePromoType = Omit<IBrandPromos, 'promoTypeConfig'> & {
  promoTypeConfig: IPromoTypeDiscountCode;
};
export type SlashBdPricesPromoType = Omit<IBrandPromos, 'promoTypeConfig'> & {
  promoTypeConfig: IPromoTypeSlashBdPrices;
};
export type PayoutCreditBoostPromoType = Omit<IBrandPromos, 'promoTypeConfig'> & {
  promoTypeConfig: IPromoTypePayoutCreditBoost;
};

/**
 * Gets oldest active promo of each promo type for a brand.
 *
 */
export const getActivePromoConfigs = (
  promoConfigsCollection: { items: IBrandPromos[] } | undefined
):
  | {
      activePromos: IBrandPromos[];
      activeDiscountCodePromo: DiscountCodePromoType | undefined;
      activeSlashBdPricesPromo: SlashBdPricesPromoType | undefined;
      activePayoutCreditBoostPromo: PayoutCreditBoostPromoType | undefined;
    }
  | Record<string, never> => {
  const { items } = promoConfigsCollection || {};

  if (isEmpty(items)) {
    return {};
  }

  const allActivePromos = sortBy(
    items!.filter((item: IBrandPromos) =>
      moment().isBetween(item?.promoStartDate, item?.promoEndDate)
    ),
    ['promoStartDate']
  );

  const activeDiscountCodePromo = allActivePromos.filter(
    (promo) => promo?.promoType === PromoTypes.DiscountCode
  )[0] as DiscountCodePromoType;

  const activeSlashBdPricesPromo = allActivePromos.filter(
    (promo) => promo?.promoType === PromoTypes.SlashBdPrices
  )[0] as SlashBdPricesPromoType;

  const activePayoutCreditBoostPromo = allActivePromos.filter(
    (promo) => promo?.promoType === PromoTypes.PayoutCreditBoost
  )[0] as PayoutCreditBoostPromoType;

  return {
    activePromos: compact([
      activeDiscountCodePromo,
      activeSlashBdPricesPromo,
      activePayoutCreditBoostPromo,
    ]),
    activeDiscountCodePromo,
    activeSlashBdPricesPromo,
    activePayoutCreditBoostPromo,
  };
};
