import { Box, Typography } from '@material-ui/core';
import React, { FC } from 'react';
import { Button, DialogWithCloseButton, Divider, NamedLink } from '..';
import { useShopConfigV2 } from '../../hooks/shopConfig';
import { ButtonVariant } from '../Button/Button';

import css from './HeroActions.module.css';

interface ComingSoonModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const ComingSoonModal: FC<ComingSoonModalProps> = (props: ComingSoonModalProps) => {
  const { isOpen, onClose } = props;

  const { treetShopName } = useShopConfigV2();

  return (
    <DialogWithCloseButton
      allowFullScreen={false}
      maxWidth="sm"
      open={isOpen}
      onClose={onClose}
      headerClassName={css.dialogHeader}
      closeButtonClassName={css.dialogCloseButton}
    >
      <Box display="flex" alignItems="center" justifyContent="center" style={{ height: '60px' }}>
        <Typography variant="h2">Shop Is Opening Soon</Typography>
      </Box>
      <Divider className={css.divider} />
      <Box p="20px">
        <Typography variant="body1" style={{ margin: 0 }}>
          The ability to purchase items will be launching soon! Until then, list an item on{' '}
          {treetShopName} to be an early seller. Early sellers get premier placement in the grand
          opening of the shop.
        </Typography>
      </Box>
      <Box p="0 20px 20px">
        <NamedLink name="NewListingPage" style={{ textDecoration: 'none' }}>
          <Button variant={ButtonVariant.Primary}>Sell Your Item</Button>
        </NamedLink>
      </Box>
    </DialogWithCloseButton>
  );
};

export default ComingSoonModal;
