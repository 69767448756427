import { getDefaultShopConfig, getDefaultTradeInShopConfig } from './default';
import * as custom from '../filters/marketplace-custom-config';
import { getBrandDirectFilterConfig } from '../filters/brandDirect';
import { Conditions, getConditionsFilterConfig } from '../filters/condition';
import { getSizeFilterConfig } from '../filters/size';

const osoandmeSizes = [
  '3M',
  '6M',
  '12M',
  '24M',
  '3/4',
  '5/6',
  '7/8',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  'O/S',
];

const osoandmeConditionsOptions = [
  {
    key: Conditions.NewWithTags,
    label: 'New With Tags',
    description:
      'Your item has never been worn and the original hanging tags are still on. For this option, you’ll need to take pictures of the retail tags.',
  },
  {
    key: Conditions.NewWithoutTags,
    label: 'New Without Tags',
    description:
      'Your item has never been worn (other than perhaps trying it on), but the tags have been removed. There are no quirks or issues whatsoever.',
  },
  {
    key: Conditions.Excellent,
    label: 'Excellent Condition',
    description:
      'Your item has only been lightly used and has been extremely well maintained. Your item may have been worn several times but is in generally like-new quality.',
  },
];

export const osoandmeShopConfig = {
  ...getDefaultShopConfig(),
  ...getDefaultTradeInShopConfig(),
  filters: [
    ...custom.filters,
    getConditionsFilterConfig({ options: osoandmeConditionsOptions }),
    getBrandDirectFilterConfig(),
    getSizeFilterConfig({ sizes: osoandmeSizes }),
  ],
};
