import { ThunkDispatch } from 'redux-thunk';
import { TradeInPayoutOption } from '../../util/constants';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { fetchCurrentUser } from '../../ducks/user.duck';
import { DisputesInfo } from '../../types/forms/disputeFormData';
import { BundleInfo } from '../../types/models/bundle';
import { AppThunk } from '../../types/redux/AppThunk';
import { RequestStatus } from '../../types/requestStatus';
import {
  fetchListings as fetchListingsApiRequest,
  queueBuyerDisputeEmail,
  setInventory,
  updateBundlePayout,
  updateListingLegacy as updateListingApiRequest,
  updateShippingStatus,
  validateAddress,
} from '../../util/api';
import { denormalisedResponseEntities } from '../../util/data';
import { storableError } from '../../util/errors';
import * as log from '../../util/log';
import { getDisputeDynamicTemplateData } from './utils';
import { SharetribeAddress } from '../../types/sharetribe/address';
import { getShopConfig } from '../../shopConfig/configHelper';
import { POWERED_BY_TREET_TYPES } from '../../shopConfig/config';
import { Listing, ListingWithImages, OwnListing } from '../../types/sharetribe/listing';
import { calculateListingPayoutUpdateFields } from '../../util/listings/listing';

export const MAX_ORDERS_PER_QUERY = 200;

// ================ Action types ================ //

const VALIDATE_SHIPPING_ADDRESS_REQUEST =
  'app/ManagePurchasesPage/VALIDATE_SHIPPING_ADDRESS_REQUEST';
const VALIDATE_SHIPPING_ADDRESS_SUCCESS =
  'app/ManagePurchasesPage/VALIDATE_SHIPPING_ADDRESS_SUCCESS';
const VALIDATE_SHIPPING_ADDRESS_ERROR = 'app/ManagePurchasesPage/VALIDATE_SHIPPING_ADDRESS_ERROR';

const UPDATE_BUNDLE_ITEMS_PAYOUT_REQUEST =
  'app/ManagePurchasesPage/UPDATE_BUNDLE_ITEMS_PAYOUT_REQUEST';
const UPDATE_BUNDLE_ITEMS_PAYOUT_SUCCESS =
  'app/ManagePurchasesPage/UPDATE_BUNDLE_ITEMS_PAYOUT_SUCCESS';
const UPDATE_BUNDLE_ITEMS_PAYOUT_ERROR = 'app/ManagePurchasesPage/UPDATE_BUNDLE_ITEMS_PAYOUT_ERROR';

const FILE_CLAIM_REQUEST = 'app/ManagePurchasesPage/FILE_CLAIM_REQUEST';
const FILE_CLAIM_SUCCESS = 'app/ManagePurchasesPage/FILE_CLAIM_SUCCESS';
const FILE_CLAIM_ERROR = 'app/ManagePurchasesPage/FILE_CLAIM_ERROR';

const SET_TRADE_IN_CREDIT_REQUEST = 'app/ManagePurchasesPage/SET_TRADE_IN_CREDIT_REQUEST';
const SET_TRADE_IN_CREDIT_SUCCESS = 'app/ManagePurchasesPage/SET_TRADE_IN_CREDIT_SUCCESS';
const SET_TRADE_IN_CREDIT_ERROR = 'app/ManagePurchasesPage/SET_TRADE_IN_CREDIT_ERROR';

const FETCH_BUNDLE_LISTINGS_REQUEST = 'app/ManagePurchasesPage/FETCH_BUNDLE_LISTINGS_REQUEST';
const FETCH_BUNDLE_LISTINGS_SUCCESS = 'app/ManagePurchasesPage/FETCH_BUNDLE_LISTINGS_SUCCESS';
const FETCH_BUNDLE_LISTINGS_ERROR = 'app/ManagePurchasesPage/FETCH_BUNDLE_LISTINGS_ERROR';
const SET_SEARCH_VALUE = 'app/ManagePurchasesPage/SET_SEARCH_VALUE';

const UPDATE_BUNDLE_SHIPPING_REQUEST = 'app/ManagePurchasesPage/UPDATE_BUNDLE_SHIPPING_REQUEST';
const UPDATE_BUNDLE_SHIPPING_SUCCESS = 'app/ManagePurchasesPage/UPDATE_BUNDLE_SHIPPING_SUCCESS';
const UPDATE_BUNDLE_SHIPPING_ERROR = 'app/ManagePurchasesPage/UPDATE_BUNDLE_SHIPPING_ERROR';

const CREATE_LISTING_DRAFT_REQUEST = 'app/ManagePurchasesPage/CREATE_LISTING_DRAFT_REQUEST';
const CREATE_LISTING_DRAFT_SUCCESS = 'app/ManagePurchasesPage/CREATE_LISTING_DRAFT_SUCCESS';
const CREATE_LISTING_DRAFT_ERROR = 'app/ManagePurchasesPage/CREATE_LISTING_DRAFT_ERROR';

const SET_STOCK_REQUEST = 'app/ManagePurchasesPage/SET_STOCK_REQUEST';
const SET_STOCK_SUCCESS = 'app/ManagePurchasesPage/SET_STOCK_SUCCESS';
const SET_STOCK_ERROR = 'app/ManagePurchasesPage/SET_STOCK_ERROR';

interface ValidateShippingAddressRequest {
  type: typeof VALIDATE_SHIPPING_ADDRESS_REQUEST;
}
interface ValidateShippingAddressSuccess {
  type: typeof VALIDATE_SHIPPING_ADDRESS_SUCCESS;
}
interface ValidateShippingAddressError {
  type: typeof VALIDATE_SHIPPING_ADDRESS_ERROR;
  error: any;
}

interface UpdateBundleItemsPayoutRequest {
  type: typeof UPDATE_BUNDLE_ITEMS_PAYOUT_REQUEST;
}
interface UpdateBundleItemsPayoutSuccess {
  type: typeof UPDATE_BUNDLE_ITEMS_PAYOUT_SUCCESS;
}
interface UpdateBundleItemsPayoutError {
  type: typeof UPDATE_BUNDLE_ITEMS_PAYOUT_ERROR;
  error: any;
}

interface FileClaimRequest {
  type: typeof FILE_CLAIM_REQUEST;
}
interface FileClaimSuccess {
  type: typeof FILE_CLAIM_SUCCESS;
}
interface FileClaimError {
  type: typeof FILE_CLAIM_ERROR;
  error: any;
}

interface SetTradeInCreditRequest {
  type: typeof SET_TRADE_IN_CREDIT_REQUEST;
}
interface SetTradeInCreditSuccess {
  type: typeof SET_TRADE_IN_CREDIT_SUCCESS;
}
interface SetTradeInCreditError {
  type: typeof SET_TRADE_IN_CREDIT_ERROR;
  error: any;
}

interface FetchBundleListingsRequest {
  type: typeof FETCH_BUNDLE_LISTINGS_REQUEST;
}
interface FetchBundleListingsSuccess {
  type: typeof FETCH_BUNDLE_LISTINGS_SUCCESS;
}
interface FetchBundleListingsError {
  type: typeof FETCH_BUNDLE_LISTINGS_ERROR;
  error: any;
}

interface SetSearchValue {
  type: typeof SET_SEARCH_VALUE;
  searchValue: string;
}

interface UpdateBundleShippingRequest {
  type: typeof UPDATE_BUNDLE_SHIPPING_REQUEST;
}
interface UpdateBundleShippingSuccess {
  type: typeof UPDATE_BUNDLE_SHIPPING_SUCCESS;
}
interface UpdateBundleShippingError {
  type: typeof UPDATE_BUNDLE_SHIPPING_ERROR;
  error: any;
}

interface CreateListingDraftRequest {
  type: typeof CREATE_LISTING_DRAFT_REQUEST;
}
interface CreateListingDraftSuccess {
  type: typeof CREATE_LISTING_DRAFT_SUCCESS;
  listingDraft: Partial<OwnListing>;
}
interface CreateListingDraftError {
  type: typeof CREATE_LISTING_DRAFT_ERROR;
  error: Error;
}

interface SetStockRequest {
  type: typeof SET_STOCK_REQUEST;
}
interface SetStockSuccess {
  type: typeof SET_STOCK_SUCCESS;
}
interface SetStockError {
  type: typeof SET_STOCK_ERROR;
  error: Error;
}

type ManagePurchasesPageActionType =
  | ValidateShippingAddressRequest
  | ValidateShippingAddressSuccess
  | ValidateShippingAddressError
  | UpdateBundleItemsPayoutRequest
  | UpdateBundleItemsPayoutSuccess
  | UpdateBundleItemsPayoutError
  | FileClaimRequest
  | FileClaimSuccess
  | FileClaimError
  | SetTradeInCreditRequest
  | SetTradeInCreditSuccess
  | SetTradeInCreditError
  | FetchBundleListingsRequest
  | FetchBundleListingsSuccess
  | FetchBundleListingsError
  | SetSearchValue
  | UpdateBundleShippingRequest
  | UpdateBundleShippingSuccess
  | UpdateBundleShippingError
  | CreateListingDraftRequest
  | CreateListingDraftSuccess
  | CreateListingDraftError
  | SetStockRequest
  | SetStockSuccess
  | SetStockError;

// ================ Reducer ================ //

export interface ManagePurchasesPageState {
  validateShippingAddressStatus: RequestStatus;
  validateShippingAddressError: any | null;
  updateBundleItemsPayoutStatus: RequestStatus;
  updateBundleItemsPayoutError: any | null;
  fileClaimStatus: RequestStatus;
  fileClaimError: any | null;
  setTradeInCreditStatus: RequestStatus;
  setTradeInCreditError: any | null;
  fetchBundleListingsStatus: RequestStatus;
  fetchBundleListingsError: any | null;
  searchValue: string;
  updateBundleShippingStatus: RequestStatus;
  updateBundleShippingError: any | null;
  createListingDraftStatus: RequestStatus;
  createListingDraftError: Error | null;
  listingDraft: Partial<OwnListing> | null;
  setStockStatus: RequestStatus;
  setStockError: Error | null;
}

const initialState = {
  validateShippingAddressStatus: RequestStatus.Ready,
  validateShippingAddressError: null,
  updateBundleItemsPayoutStatus: RequestStatus.Ready,
  updateBundleItemsPayoutError: null,
  fileClaimStatus: RequestStatus.Ready,
  fileClaimError: null,
  setTradeInCreditStatus: RequestStatus.Ready,
  setTradeInCreditError: null,
  fetchBundleListingsStatus: RequestStatus.Ready,
  fetchBundleListingsError: null,
  searchValue: '',
  updateBundleShippingStatus: RequestStatus.Ready,
  updateBundleShippingError: null,
  createListingDraftStatus: RequestStatus.Ready,
  createListingDraftError: null,
  listingDraft: null,
  setStockStatus: RequestStatus.Ready,
  setStockError: null,
};

export default function managePurchasesPageReducer(
  state: ManagePurchasesPageState = initialState,
  action: ManagePurchasesPageActionType
): ManagePurchasesPageState {
  switch (action.type) {
    case VALIDATE_SHIPPING_ADDRESS_REQUEST:
      return {
        ...state,
        validateShippingAddressStatus: RequestStatus.Pending,
        validateShippingAddressError: null,
      };
    case VALIDATE_SHIPPING_ADDRESS_SUCCESS:
      return { ...state, validateShippingAddressStatus: RequestStatus.Success };
    case VALIDATE_SHIPPING_ADDRESS_ERROR:
      return {
        ...state,
        validateShippingAddressStatus: RequestStatus.Error,
        validateShippingAddressError: action.error,
      };
    case UPDATE_BUNDLE_ITEMS_PAYOUT_REQUEST: {
      return {
        ...state,
        updateBundleItemsPayoutStatus: RequestStatus.Pending,
        updateBundleItemsPayoutError: null,
      };
    }
    case UPDATE_BUNDLE_ITEMS_PAYOUT_SUCCESS: {
      return {
        ...state,
        updateBundleItemsPayoutStatus: RequestStatus.Success,
      };
    }
    case UPDATE_BUNDLE_ITEMS_PAYOUT_ERROR: {
      return {
        ...state,
        updateBundleItemsPayoutStatus: RequestStatus.Error,
        updateBundleItemsPayoutError: action.error,
      };
    }
    case FILE_CLAIM_REQUEST: {
      return {
        ...state,
        fileClaimStatus: RequestStatus.Pending,
        fileClaimError: null,
      };
    }
    case FILE_CLAIM_SUCCESS: {
      return {
        ...state,
        fileClaimStatus: RequestStatus.Success,
      };
    }
    case FILE_CLAIM_ERROR: {
      return {
        ...state,
        fileClaimStatus: RequestStatus.Error,
        fileClaimError: action.error,
      };
    }
    case SET_TRADE_IN_CREDIT_REQUEST: {
      return {
        ...state,
        setTradeInCreditStatus: RequestStatus.Pending,
        setTradeInCreditError: null,
      };
    }
    case SET_TRADE_IN_CREDIT_SUCCESS: {
      return {
        ...state,
        setTradeInCreditStatus: RequestStatus.Success,
      };
    }
    case SET_TRADE_IN_CREDIT_ERROR: {
      return {
        ...state,
        setTradeInCreditStatus: RequestStatus.Error,
        setTradeInCreditError: action.error,
      };
    }
    case FETCH_BUNDLE_LISTINGS_REQUEST: {
      return {
        ...state,
        fetchBundleListingsStatus: RequestStatus.Pending,
        fetchBundleListingsError: null,
      };
    }
    case FETCH_BUNDLE_LISTINGS_SUCCESS: {
      return {
        ...state,
        fetchBundleListingsStatus: RequestStatus.Success,
      };
    }
    case FETCH_BUNDLE_LISTINGS_ERROR: {
      return {
        ...state,
        fetchBundleListingsStatus: RequestStatus.Error,
        fetchBundleListingsError: action.error,
      };
    }
    case SET_SEARCH_VALUE: {
      return {
        ...state,
        searchValue: action.searchValue,
      };
    }
    case UPDATE_BUNDLE_SHIPPING_REQUEST: {
      return {
        ...state,
        updateBundleShippingStatus: RequestStatus.Pending,
        updateBundleShippingError: null,
      };
    }
    case UPDATE_BUNDLE_SHIPPING_SUCCESS: {
      return {
        ...state,
        updateBundleShippingStatus: RequestStatus.Success,
      };
    }
    case UPDATE_BUNDLE_SHIPPING_ERROR: {
      return {
        ...state,
        updateBundleShippingStatus: RequestStatus.Error,
        updateBundleShippingError: action.error,
      };
    }
    case CREATE_LISTING_DRAFT_REQUEST: {
      return {
        ...state,
        createListingDraftStatus: RequestStatus.Pending,
        createListingDraftError: null,
      };
    }
    case CREATE_LISTING_DRAFT_SUCCESS: {
      return {
        ...state,
        createListingDraftStatus: RequestStatus.Success,
        listingDraft: action.listingDraft,
      };
    }
    case CREATE_LISTING_DRAFT_ERROR: {
      return {
        ...state,
        createListingDraftStatus: RequestStatus.Error,
        createListingDraftError: action.error,
      };
    }
    case SET_STOCK_REQUEST: {
      return {
        ...state,
        setStockStatus: RequestStatus.Pending,
        setStockError: null,
      };
    }
    case SET_STOCK_SUCCESS: {
      return {
        ...state,
        setStockStatus: RequestStatus.Success,
      };
    }
    case SET_STOCK_ERROR: {
      return {
        ...state,
        setStockStatus: RequestStatus.Error,
        setStockError: action.error,
      };
    }
    default:
      return state;
  }
}

// ================ Action creators ================ //

const validateShippingAddressRequest = () => ({ type: VALIDATE_SHIPPING_ADDRESS_REQUEST });
const validateShippingAddressSuccess = () => ({ type: VALIDATE_SHIPPING_ADDRESS_SUCCESS });
const validateShippingAddressError = (error: any) => ({
  type: VALIDATE_SHIPPING_ADDRESS_ERROR,
  error,
});

const updateBundleItemsPayoutRequest = () => ({ type: UPDATE_BUNDLE_ITEMS_PAYOUT_REQUEST });
const updateBundleItemsPayoutSuccess = () => ({
  type: UPDATE_BUNDLE_ITEMS_PAYOUT_SUCCESS,
});
const updateBundleItemsPayoutError = (error: any) => ({
  type: UPDATE_BUNDLE_ITEMS_PAYOUT_ERROR,
  error,
});

const fileClaimRequest = () => ({ type: FILE_CLAIM_REQUEST });
const fileClaimSuccess = () => ({
  type: FILE_CLAIM_SUCCESS,
});
const fileClaimError = (error: any) => ({ type: FILE_CLAIM_ERROR, error });

const setTradeInCreditRequest = () => ({ type: SET_TRADE_IN_CREDIT_REQUEST });
const setTradeInCreditSuccess = () => ({ type: SET_TRADE_IN_CREDIT_SUCCESS });
const setTradeInCreditError = (error: any) => ({ type: SET_TRADE_IN_CREDIT_ERROR, error });

const fetchBundleListingsRequest = () => ({ type: FETCH_BUNDLE_LISTINGS_REQUEST });
const fetchBundleListingsSuccess = () => ({
  type: FETCH_BUNDLE_LISTINGS_SUCCESS,
});
const fetchBundleListingsError = (error: any) => ({
  type: FETCH_BUNDLE_LISTINGS_ERROR,
  error,
});

export const setSearchValue = (searchValue: string) => ({
  type: SET_SEARCH_VALUE,
  searchValue,
});

const updateBundleShippingRequest = () => ({
  type: UPDATE_BUNDLE_SHIPPING_REQUEST,
});
const updateBundleShippingSuccess = () => ({
  type: UPDATE_BUNDLE_SHIPPING_SUCCESS,
});
const updateBundleShippingError = (error: any) => ({
  type: UPDATE_BUNDLE_SHIPPING_ERROR,
  error,
});

const createListingDraftRequest = () => ({ type: CREATE_LISTING_DRAFT_REQUEST });
const createListingDraftSuccess = (listingDraft: Partial<OwnListing>) => ({
  type: CREATE_LISTING_DRAFT_SUCCESS,
  listingDraft,
});
const createListingDraftError = (error: Error) => ({
  type: CREATE_LISTING_DRAFT_ERROR,
  error,
});

const setStockRequest = () => ({ type: SET_STOCK_REQUEST });
const setStockSuccess = () => ({ type: SET_STOCK_SUCCESS });
const setStockError = (error: Error) => ({ type: CREATE_LISTING_DRAFT_ERROR, error });

/* ================ Thunks ================ */

export const validateShippingAddress =
  (address: SharetribeAddress) => async (dispatch: ThunkDispatch<any, any, any>) => {
    dispatch(validateShippingAddressRequest());

    try {
      const response = await validateAddress(address);
      dispatch(validateShippingAddressSuccess());
      return response;
    } catch (e) {
      const error = e.error || storableError(e);
      dispatch(validateShippingAddressError(error));
      // Throw the storable error so that submission validation displays the correct text
      throw error;
    }
  };

type DisputedItemForTradeInCredit = { bundleItemId: string; listing: Listing };

const updateBundleItemsPayout =
  (params: {
    bundleId: string;
    disputesInfo: DisputesInfo;
    payoutOptions: TradeInPayoutOption[];
    disputedItems: DisputedItemForTradeInCredit[];
  }): AppThunk<Promise<any>> =>
  async (dispatch: ThunkDispatch<any, any, any>) => {
    const { bundleId, disputesInfo, payoutOptions, disputedItems } = params;
    dispatch(updateBundleItemsPayoutRequest());

    try {
      const updateParams = disputedItems.map(({ bundleItemId, listing }) => {
        const { claimTypeKey } = disputesInfo[bundleItemId];
        if (!claimTypeKey) {
          throw new Error(
            `Malformed dispute info for bundle item ${bundleItemId}. No claimTypeKey provided.`
          );
        }
        return calculateListingPayoutUpdateFields(claimTypeKey, listing, payoutOptions);
      });

      const discountLineItem = await updateBundlePayout({
        bundleId,
        updateParams,
      });

      dispatch(updateBundleItemsPayoutSuccess());
      return discountLineItem;
    } catch (e) {
      const error = new Error('Failed to update payout details for bundle items.');
      dispatch(updateBundleItemsPayoutError(error));
      log.error(error, 'update-bundle-items-payout-failed', {
        bundleId,
        disputedItems,
        disputesInfo,
        payoutOptions,
        error: e,
      });
      throw e;
    }
  };

export const setTradeInCredit =
  (params: {
    bundle: BundleInfo;
    disputesInfo: DisputesInfo;
    tradeInCreditPayoutOptions: TradeInPayoutOption[];
    listings: Listing[];
  }): AppThunk<Promise<any>> =>
  async (dispatch: ThunkDispatch<any, any, any>) => {
    const {
      bundle,
      listings: sharetribeListings,
      disputesInfo,
      tradeInCreditPayoutOptions,
    } = params;

    const disputedBundleItemIds = Object.keys(disputesInfo);
    const disputedItems = bundle.bundleItems
      .filter((bundleItem) => disputedBundleItemIds.includes(bundleItem.id))
      .reduce<DisputedItemForTradeInCredit[]>((acc, filteredBundleItem) => {
        const bundleItemId = filteredBundleItem.id;
        const sharetribeListing = sharetribeListings.find(
          (l) => l.id.uuid === filteredBundleItem.listing.sharetribeListingId
        );
        if (sharetribeListing) {
          return acc.concat({ bundleItemId, listing: sharetribeListing });
        }
        return acc;
      }, []);

    dispatch(setTradeInCreditRequest());

    try {
      const response = await dispatch(
        updateBundleItemsPayout({
          bundleId: bundle.id,
          disputedItems,
          disputesInfo,
          payoutOptions: tradeInCreditPayoutOptions,
        })
      );
      dispatch(setTradeInCreditSuccess());
      return response;
    } catch (e) {
      log.error(e, 'set-trade-in-credit-failed', { bundleId: bundle.id, disputesInfo });
      dispatch(setTradeInCreditError(e));
      // Caught in BundlePanel
      throw e;
    }
  };

// Email code 2.4
export const fileClaim =
  (params: {
    bundle: BundleInfo;
    disputesInfoByBundleItem: DisputesInfo;
    listings: Listing[];
  }): AppThunk<Promise<any>> =>
  async (dispatch: ThunkDispatch<any, any, any>, getState: () => any) => {
    const { bundle, disputesInfoByBundleItem, listings } = params;

    dispatch(fileClaimRequest());

    const { currentUser } = getState().user;
    const { treetId, shopConfig: shopConfigV2 } = getState().initial;
    const { images: shopImages, poweredByTreetType } = getShopConfig(treetId, shopConfigV2);
    const { email } = currentUser.attributes;
    const isReturn = bundle.isReturnable;

    // get all images
    const images = Object.entries(disputesInfoByBundleItem).reduce(
      (acc, [, data]) => [...acc, ...(data.images || [])],
      [] as any[]
    );

    try {
      if (!isReturn) {
        const dynamicTemplateData = {
          customer: currentUser,
          shopLogo: shopImages.treetShopLogo?.url || shopImages.shopLogo?.url,
          shouldShowPoweredByTreet: poweredByTreetType === POWERED_BY_TREET_TYPES.ICON,
          ...getDisputeDynamicTemplateData(disputesInfoByBundleItem, bundle, listings),
        };

        queueBuyerDisputeEmail({
          email,
          treetId,
          dynamicTemplateData,
          images,
        });
      }

      dispatch(fileClaimSuccess());
    } catch (err) {
      log.error(err, 'file-claim-failed', { err });
      dispatch(fileClaimError(err));
      // Will be caught by BundlePanel
      throw err;
    }
  };

export const fetchBundleListings =
  (listingIds: string[]) => async (dispatch: ThunkDispatch<any, any, any>) => {
    dispatch(fetchBundleListingsRequest());
    let resultIds;
    try {
      const bundleListingsResponse = await fetchListingsApiRequest({ listingIds });
      dispatch(addMarketplaceEntities(bundleListingsResponse));
      resultIds = denormalisedResponseEntities(bundleListingsResponse).map((listing) => listing.id);
    } catch (e) {
      log.error(e, 'fetching-bundle-listings-for-purchases-failed', { listingIds });
      dispatch(fetchBundleListingsError(e));
    }
    dispatch(fetchBundleListingsSuccess());
    return resultIds;
  };

export const updateBundleShipping =
  (params: { bundleId: string; status: string }) =>
  async (dispatch: ThunkDispatch<any, any, any>) => {
    const { bundleId, status } = params;
    dispatch(updateBundleShippingRequest());
    try {
      await updateShippingStatus({ bundleId, status });
      dispatch(updateBundleShippingSuccess());
      return;
    } catch (e) {
      dispatch(updateBundleShippingError(e));
      log.error(new Error('Failed to update bundle shipping'), 'update-bundle-shipping-failed', {
        params,
        e,
      });

      // Caught in BundlePanel
      throw e;
    }
  };

const setStock = (listingId: string) => async (dispatch: ThunkDispatch<any, any, any>) => {
  dispatch(setStockRequest());

  const oldTotal = null;
  const newTotal = 1;
  try {
    const response = await setInventory({ listingId, oldTotal, newTotal, forceUpdate: true });
    dispatch(addMarketplaceEntities(response));
    dispatch(setStockSuccess());
    return response;
  } catch (e) {
    return dispatch(setStockError(storableError(e)));
  }
};

export const createListingDraftFromExistingListing =
  (params: any) =>
  async (
    dispatch: ThunkDispatch<any, any, any>,
    getState: () => any,
    sdk: any
  ): Promise<ListingWithImages | null> => {
    dispatch(createListingDraftRequest());

    const queryParams = {
      expand: true,
      include: ['author', 'images'],
      'fields.image': ['variants.default'],
    };

    try {
      const listingDraftResponse = await sdk.ownListings.createDraft(params, queryParams);
      dispatch(addMarketplaceEntities(listingDraftResponse));
      const listingDraft = denormalisedResponseEntities(listingDraftResponse)[0];
      dispatch(createListingDraftSuccess(listingDraft));
      if (listingDraft.id?.uuid) {
        dispatch(setStock(listingDraft.id.uuid));
      }
      return listingDraft;
    } catch (e) {
      log.error(e as Error, 'create-listing-draft-from-trade-in-failed', params);
      dispatch(createListingDraftError(storableError(e)));
      return null;
    }
  };

export const updateListing = (params: any) => async (dispatch: ThunkDispatch<any, any, any>) => {
  try {
    const updatedListingResponse = await updateListingApiRequest(params);
    dispatch(addMarketplaceEntities(updatedListingResponse));
    return denormalisedResponseEntities(updatedListingResponse)[0];
  } catch (e) {
    log.error(e as Error, 'manage-purchases-page-update-listing-failed', params);
    return null;
  }
};

export const fetchOwnListing =
  (listingId: string) =>
  async (dispatch: ThunkDispatch<any, any, any>, getState: () => any, sdk: any) => {
    try {
      const response = await sdk.ownListings.show({
        id: listingId,
      });
      const listing = denormalisedResponseEntities(response)[0];
      return listing;
    } catch (e) {
      log.error(e as Error, 'fetch-created-resale-listing-failed', { listingId });
      return null;
    }
  };

export const loadData =
  () => async (dispatch: ThunkDispatch<any, any, any>, getState: () => any) => {
    const { currentUser } = getState().user;
    if (!currentUser) {
      await fetchCurrentUser();
    }
  };
