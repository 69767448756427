/* eslint-disable import/prefer-default-export */
// TODO (sonia-y | TREET-1021): Refactor route names to an enum
export enum PageName {
  AdminPage = 'AdminPage',
  CheckoutPage = 'CheckoutPage',
  ListingPage = 'ListingPage',
  EditListingPage = 'EditListingPage',
  LoginPage = 'LoginPage',
  SignupPage = 'SignupPage',
}
