import { getConditionsFilterConfig } from '../filters/condition';
import { getSizeFilterConfig } from '../filters/size';
import { getDefaultShopConfig } from './default';
import * as custom from '../filters/marketplace-custom-config';
import { getBrandDirectFilterConfig } from '../filters/brandDirect';
import { BuilderSections } from '../../util/builder';

const spellSizes = ['XXS', 'XS', 'S', 'M', 'L', 'XL', 'XXL', '3XL', 'OS'];

const spellBaseConfig = {
  ...getDefaultShopConfig(),
  filters: [
    ...custom.filters,
    getConditionsFilterConfig(),
    getBrandDirectFilterConfig(),
    getSizeFilterConfig({
      sizes: spellSizes,
    }),
  ],
  builderConfig: {
    sections: {
      [BuilderSections.LandingPageContent]: '7bf5addc71044c368c93c0d0945b569b',
      [BuilderSections.ListingCardDetails]: '559825b2dbcd46efab9616fba3bec35a',
    },
  },
  // Consider moving to contentful if used in >3 configs.
  hideConfettiAtCheckout: true,
};

const spellAdditionalPayoutSettings = {
  additionalShopifyGiftCardRequirements: {
    giftCardExpiresAfterDays: 1095, // 3 years
  },
  creditCodeHelperText: 'Gift card expires 3 years after date of issue.',
};

// AU site only config
export const spellShopConfig = {
  ...spellBaseConfig,
  additionalPayoutSettings: spellAdditionalPayoutSettings,
};

// US site only config
export const spellusaShopConfig = spellBaseConfig;
