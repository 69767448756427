import { getDefaultShopConfig } from './default';
import * as custom from '../filters/marketplace-custom-config';
import { getBrandDirectFilterConfig } from '../filters/brandDirect';
import { getConditionsFilterConfig } from '../filters/condition';
import { getSizeFilterConfig } from '../filters/size';
import { getGenderFilterConfig } from '../filters/gender';

const shopeitherorSizes: string[] = [
  '6',
  '6.5',
  '7',
  '7.5',
  '8',
  '8.5',
  '9',
  '9.5',
  '10',
  '10.5',
  '11',
  'BABY',
  'CHILD',
  'YOUTH',
  'S/M',
  'M/L',
  'One Size',
];

export const shopeitherorShopConfig = {
  ...getDefaultShopConfig(),
  filters: [
    ...custom.filters,
    getGenderFilterConfig(),
    getConditionsFilterConfig(),
    getBrandDirectFilterConfig(),
    getSizeFilterConfig({ sizes: shopeitherorSizes }),
  ],
};
