import { getDefaultBrandDirectOnlyShopConfig, getDefaultShopConfig } from './default';
import * as custom from '../filters/marketplace-custom-config';
import { getConditionsFilterConfig } from '../filters/condition';
import { getSizeFilterConfig } from '../filters/size';
import { getGenderFilterConfig } from '../filters/gender';

export const tentreeCaShopConfig = {
  ...getDefaultShopConfig(),
  ...getDefaultBrandDirectOnlyShopConfig('tentree'),
  filters: [
    ...custom.filters,
    getGenderFilterConfig(),
    getConditionsFilterConfig(),
    getSizeFilterConfig({
      sizes: ['XS', 'S', 'M', 'L', 'XL', 'XXL', 'ONE'],
    }),
  ],
};
