/* eslint-disable import/prefer-default-export */
import { getDefaultShopConfig } from './default';
import * as custom from '../filters/marketplace-custom-config';
import { getConditionsFilterConfig } from '../filters/condition';
import { getSizeFilterConfig } from '../filters/size';

export const nooworksShopConfig = {
  ...getDefaultShopConfig(),
  filters: [
    ...custom.filters,
    getConditionsFilterConfig(),
    getSizeFilterConfig({
      sizes: [
        'xxsmall',
        'xsmall',
        'small',
        'medium',
        'large',
        'xlarge',
        'xxlarge',
        'xxxlarge',
        '2X',
        '3X',
        '4X',
        '5X',
      ],
    }),
  ],
};
