import React from 'react';
import { useIconColor } from '../../../hooks/useIconColor';

interface IconListFilledProps {
  className?: string;
  color?: string;
}

const IconListFilled: React.FC<IconListFilledProps> = (props) => {
  const { className, color } = props;

  const { iconColor } = useIconColor();
  const fillColor = color || iconColor;

  return (
    <svg
      className={className}
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17 22H18V21V18H21H22V17V15V14H21H18L18 11V10H17H15H14V11V14H11H10V15V17V18H11H14V21V22H15H17ZM5 16C5 9.92487 9.92487 5 16 5C22.0751 5 27 9.92487 27 16C27 22.0751 22.0751 27 16 27C9.92487 27 5 22.0751 5 16Z"
        fill={fillColor}
        stroke={fillColor}
        strokeWidth="2"
      />
    </svg>
  );
};

export default IconListFilled;
