import { getConditionsFilterConfig } from '../filters/condition';
import { getSizeFilterConfig } from '../filters/size';
import { getDefaultShopConfig } from './default';
import * as custom from '../filters/marketplace-custom-config';
import { getBrandDirectFilterConfig } from '../filters/brandDirect';

const plaeSizes = [
  'YS',
  'YM',
  'YL',
  'S',
  'M',
  'L',
  'XL',
  'One Size',
  '5 - tiny-tots',
  '5.5 - tiny-tots',
  '6 - tots',
  '6.5 - tots',
  '7 - tots',
  '7.5 - tots',
  '8 - kids',
  '8.5 - kids',
  '9 - kids',
  '9.5 - kids',
  '10 - kids',
  '10.5 - kids',
  '11 - kids',
  '11.5 - kids',
  '12 - kids',
  '12.5 - kids',
  '13 - kids',
  '13.5 - kids',
  '1 - youth',
  '1.5 - youth',
  '2 - youth',
  '2.5 - youth',
  '3 - youth',
  '3.5 - youth',
  '4 - youth',
  '4.5 - youth',
  '4 Mens = 5.5 Womens',
  '4.5 Mens = 6 Womens',
  '5 Mens = 6.5 Womens',
  '5.5 Mens = 7 Womens',
  '6 Mens = 7.5 Womens',
  '6.5 Mens = 8 Womens',
  '7 Mens = 8.5 Womens',
  '7.5 Mens = 9 Womens',
  '8 Mens = 9.5 Womens',
  '8.5 Mens = 10 Womens',
  '9 Mens = 10.5 Womens',
  '9.5 Mens = 11 Womens',
  '10 Mens = 11.5 Womens',
  '10.5 Mens = 12 Womens',
  '11 Mens = 12.5 Womens',
  '11.5 Mens = 13 Womens',
  '12 Mens = 13.5 Womens',
  '13 Mens = 14.5 Womens',
  '14 Mens = 15.5 Womens',
  '15 Mens = 16.5 Womens',
];

export const plaeShopConfig = {
  ...getDefaultShopConfig(),
  filters: [
    ...custom.filters,
    getConditionsFilterConfig(),
    getSizeFilterConfig({ sizes: plaeSizes }),
    getBrandDirectFilterConfig(),
  ],
};
