import { defaultClothesConditionsOptions, getConditionsFilterConfig } from '../filters/condition';
import { getDefaultShopConfig } from './default';
import * as custom from '../filters/marketplace-custom-config';
import { getSizeFilterConfigFromOptions } from '../filters/size';

const statethelabelSizeOptions = [
  { key: 'XS', label: 'XS' },
  { key: 'S', label: 'S' },
  { key: 'M', label: 'M' },
  { key: 'S/M', label: 'S/M' },
  { key: 'L', label: 'L' },
  { key: 'XL', label: 'XL' },
  { key: 'L/XL', label: 'L/XL' },
  { key: '2XL', label: '2XL' },
  { key: '3XL', label: '3XL' },
];

export const statethelabelShopConfig = {
  ...getDefaultShopConfig(),
  filters: [
    ...custom.filters,
    getConditionsFilterConfig({ options: defaultClothesConditionsOptions }),
    getSizeFilterConfigFromOptions({ options: statethelabelSizeOptions }),
  ],
};
