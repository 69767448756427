import { Box, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import React, { FC, useContext, useEffect } from 'react';
import { OnChange } from 'react-final-form-listeners';
import { useDispatch, useSelector } from 'react-redux';
import uniq from 'lodash/uniq';
import { AddressForm, FieldCheckbox, InlineTextButton } from '../../components';
import {
  CheckoutPageState,
  getReturnInsuranceQuote,
} from '../../containers/CheckoutPage/CheckoutPage.duck';
import AppContext from '../../context/AppContext';
import { useShopConfig } from '../../hooks/shopConfig';
import { useReturnInsurance } from '../../hooks/useReturnInsurance';
import { useUserCountryConfig } from '../../hooks/useCountryConfig';
import { CurrentUser } from '../../types/sharetribe/currentUser';
import { getSeelAddressFromSharetribeAddress } from '../../util/address';
import { ADDRESS_FORM_TYPE_SHIPPING, SHIPPING_ADDRESS_FIELD_ID } from '../../util/constants';
import { getReturnInsuranceQuoteParams } from '../../util/seelHelpers';
import css from './StripePaymentForm.module.css';
import { CountryCodeAndName } from '../../util/countryCodes';

export const SHIPPING_ADDRESS_PREFIX = 'shippingAddress';
const SAVE_ADDRESS_FIELD_NAME = 'shouldSaveAddress';

interface ShippingAddressFieldsProps {
  form: any;
  allowedShipToCountryCodes: CountryCodeAndName[];
  handleShippingCountryChange: (value: string) => void;
}

const ShippingAddressFields: FC<ShippingAddressFieldsProps> = (
  props: ShippingAddressFieldsProps
) => {
  const { form, allowedShipToCountryCodes, handleShippingCountryChange } = props;

  const { sizeVariantOptionName } = useShopConfig();
  const { countryCode: userCountryCode } = useUserCountryConfig();
  const { shouldOfferReturnInsurance } = useReturnInsurance();
  const { canonicalRootUrl, treetId } = useContext(AppContext);
  const dispatch = useDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const currentUser = useSelector<any>((state) => state.user.currentUser) as
    | CurrentUser
    | undefined;
  const { listings, shipToCountry } = useSelector<any>(
    (state) => state.CheckoutPage
  ) as CheckoutPageState;

  const isMultisellerCheckout = uniq(listings.map((listing) => listing.author.id.uuid)).length > 1;
  const shouldEnableSeel = shouldOfferReturnInsurance && !isMultisellerCheckout;

  useEffect(() => {
    if (!shouldEnableSeel) return;

    const fields = form.getRegisteredFields();
    const shippingAddressFields = fields
      .filter((key: string) => key.includes(SHIPPING_ADDRESS_PREFIX))
      .reduce(
        (acc: { [key: string]: string }, field: string) => ({
          ...acc,
          [field.replace(`${SHIPPING_ADDRESS_PREFIX}.`, '')]: form.getFieldState(field)?.value,
        }),
        {}
      );

    let shippingAddress = getSeelAddressFromSharetribeAddress(shippingAddressFields);
    // Default to user's detected country if they haven't yet inputted an address
    if (!shippingAddress.country) {
      shippingAddress = { ...shippingAddress, country: userCountryCode };
    }

    const params = getReturnInsuranceQuoteParams(
      listings,
      canonicalRootUrl,
      treetId,
      sizeVariantOptionName,
      shippingAddress,
      currentUser
    );
    dispatch(getReturnInsuranceQuote(params));
  }, [shipToCountry, listings]);

  const handleClear = () => {
    const fields = form.getRegisteredFields();
    fields.forEach((key: string) => {
      if (key.includes(SHIPPING_ADDRESS_PREFIX)) {
        form.change(key, undefined);
        form.resetFieldState(key);
      }
    });
  };

  return (
    <Box mb={{ xs: 0, md: 3 }}>
      {isMobile && (
        <Box display="flex" justifyContent="flex-start" mt={2}>
          <InlineTextButton
            className={css.clearAddressLabel}
            id="clearAddress"
            type="button"
            onClick={handleClear}
          >
            <Typography variant="body1" className={css.link}>
              Clear
            </Typography>
          </InlineTextButton>
        </Box>
      )}
      <AddressForm
        form={form}
        addressType={ADDRESS_FORM_TYPE_SHIPPING}
        fieldId={SHIPPING_ADDRESS_FIELD_ID}
        prefix={SHIPPING_ADDRESS_PREFIX}
        allowedCountryCodes={allowedShipToCountryCodes}
      />
      <OnChange name={`${SHIPPING_ADDRESS_PREFIX}.country`}>
        {(value) => handleShippingCountryChange(value)}
      </OnChange>
      <div className={css.saveForLaterUse}>
        <Box display="flex" flexDirection="row" justifyContent="space-between">
          <FieldCheckbox
            className={css.saveForLaterUseCheckbox}
            textClassName={css.saveForLaterUseLabel}
            id={SAVE_ADDRESS_FIELD_NAME}
            name={SAVE_ADDRESS_FIELD_NAME}
            label="Save address"
            useSuccessColor
          />
          {!isMobile && (
            <InlineTextButton
              className={css.clearAddressLabel}
              id="clearAddress"
              type="button"
              onClick={handleClear}
            >
              <Typography variant="body1" className={css.link}>
                Clear
              </Typography>
            </InlineTextButton>
          )}
        </Box>
      </div>
    </Box>
  );
};

export default ShippingAddressFields;
