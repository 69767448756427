import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import {
  Footer,
  FormattedMessage,
  LayoutSingleColumn,
  LayoutWrapperFooter,
  LayoutWrapperMain,
  LayoutWrapperTopbar,
  Page,
} from '../../components';
import { TopbarContainer } from '..';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import { getSubdomain } from '../../util/envHelpers';
import { injectIntl, intlShape } from '../../util/reactIntl';
import { doesRouteHaveViews } from '../../util/routes';
import css from './NotFoundPage.module.css';

export class NotFoundPageComponent extends Component {
  constructor(props) {
    super(props);
    // The StaticRouter component used in server side rendering
    // provides the context object. We attach a `notfound` flag to
    // the context to tell the server to change the response status
    // code into a 404.
    this.props.staticContext.notfound = true;
  }

  render() {
    const { intl, scrollingDisabled } = this.props;
    const subdomain = getSubdomain();
    const title = intl.formatMessage({ id: 'NotFoundPage.title' });
    const shouldRenderShopNavs = doesRouteHaveViews(subdomain);

    return (
      <Page title={title} scrollingDisabled={scrollingDisabled}>
        <LayoutSingleColumn>
          <LayoutWrapperTopbar>{shouldRenderShopNavs && <TopbarContainer />}</LayoutWrapperTopbar>
          <LayoutWrapperMain>
            <div className={css.root}>
              <div className={css.content}>
                <div className={css.number}>404</div>
                <h1 className={css.heading}>
                  <FormattedMessage id="NotFoundPage.heading" />
                </h1>
                <p className={css.description}>
                  <FormattedMessage id="NotFoundPage.description" />
                </p>
              </div>
            </div>
          </LayoutWrapperMain>
          <LayoutWrapperFooter>{shouldRenderShopNavs && <Footer />}</LayoutWrapperFooter>
        </LayoutSingleColumn>
      </Page>
    );
  }
}

NotFoundPageComponent.defaultProps = {
  staticContext: {},
};

const { bool, object } = PropTypes;

NotFoundPageComponent.propTypes = {
  scrollingDisabled: bool.isRequired,

  // context object from StaticRouter, injected by the withRouter wrapper
  staticContext: object,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = (state) => ({ scrollingDisabled: isScrollingDisabled(state) });

const NotFoundPage = compose(connect(mapStateToProps), injectIntl)(NotFoundPageComponent);

export default NotFoundPage;
