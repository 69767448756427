import React, { FC } from 'react';
import { Box, withStyles } from '@material-ui/core';
import { useFormState } from 'react-final-form';
import { useIntl } from 'react-intl';
import { TextField } from 'mui-rff';
import ISOHeader from './ISOHeader';
import TypographyWrapper, { TypographyWeight } from '../TypographyWrapper/TypographyWrapper';
import * as validators from '../../util/validators';
import { useIsMobile } from '../../hooks/useIsMobile';
import { defaultTreetStyles } from '../../shopConfig/config';

const TEXT_INPUT_COLOR = defaultTreetStyles.gray80;

const inputStyles = {
  root: {
    '& label': {
      color: TEXT_INPUT_COLOR,
      fontWeight: 'bold',
    },
    '& label.Mui-focused': {
      color: TEXT_INPUT_COLOR,
    },
    '& .MuiSelect-icon': {
      color: TEXT_INPUT_COLOR,
      top: 'unset',
    },
    '& .MuiInputLabel-outlined': {
      transform: 'translate(14px, 15px) scale(1)',
    },

    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      transform: 'translate(14px, -6px) scale(0.75)',
    },
    // This is needed so that the autocomplete backfill fills the whole input
    '& input': {
      padding: '0px 14px',
      height: '100%',
    },
    '& .MuiOutlinedInput-root': {
      color: TEXT_INPUT_COLOR,
      fontWeight: 'bold',
      width: '100%',
      height: '48px',

      '& fieldset': {
        borderWidth: '1px',
        borderColor: TEXT_INPUT_COLOR,
        color: TEXT_INPUT_COLOR,
      },
      '&:hover fieldset': {
        borderColor: TEXT_INPUT_COLOR,
      },
      '&.Mui-focused fieldset': {
        borderColor: TEXT_INPUT_COLOR,
      },
    },
  },
};

const EmailTextField = withStyles(inputStyles)(TextField);

const ISOEmailPane: FC = () => {
  const intl = useIntl();
  const isMobile = useIsMobile();
  const { values } = useFormState();

  // TODO: type the values
  const { shopifyProduct } = values;
  const title = shopifyProduct?.title;
  const emailRequiredMessage = intl.formatMessage({ id: 'SignupForm.emailRequired' });
  const emailRequired = validators.required(emailRequiredMessage);
  const emailInvalidMessage = intl.formatMessage({ id: 'SignupForm.emailInvalid' });
  const emailValid = validators.emailFormatValid(emailInvalidMessage);
  const emailValidators = validators.composeValidators(emailRequired, emailValid);

  return (
    <Box display="flex" flexDirection="column" height="100%">
      <ISOHeader headerCopy={isMobile ? 'Enter Your Email' : 'Enter Your Email To Get Notified'} />
      <Box py={{ xs: 10, md: 5 }} px={2} display="flex" flexDirection="column" flexGrow={1}>
        <TypographyWrapper variant="body1" typographyOverrides={{ align: 'center' }}>
          Enter your email to get notified when
        </TypographyWrapper>
        <Box display="flex" justifyContent="center" pb={3}>
          <TypographyWrapper
            variant="body1"
            weight={TypographyWeight.Bold}
            typographyOverrides={{ display: 'inline' }}
          >
            {title}&nbsp;
          </TypographyWrapper>
          <TypographyWrapper variant="body1" typographyOverrides={{ display: 'inline' }}>
            lists!
          </TypographyWrapper>
        </Box>
        <Box alignItems="center" display="flex" flexDirection="column" pt={2} pb={10}>
          <Box width="70%" pb={2}>
            <EmailTextField
              id="email-iso"
              name="email"
              label="email"
              variant="outlined"
              required
              fieldProps={{ validate: emailValidators }}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ISOEmailPane;
