import {
  FilterId,
  GenericFilterOption,
  SearchFilterType,
  SearchNavBarSections,
} from '../../types/filters/filters';
import { FilterQueryParam } from '../../types/search/search';

interface GetColorFilterConfigInputs {
  options?: GenericFilterOption[];
}

// (3/08/2022): Currently this filter only works for Frenzy searches because there is no
// color data stored in Sharetribe.
export const getColorFilterConfig = (params?: GetColorFilterConfigInputs) => {
  const { options = [] } = params || {};
  return {
    id: FilterId.Color,
    label: 'Color',
    type: SearchFilterType.Checkbox,
    group: 'primary',
    queryParamNames: [FilterQueryParam.color],
    searchNavBarSection: SearchNavBarSections.Filter,
    config: {
      options,
    },
  };
};
