/* eslint-disable import/prefer-default-export */
import { getDefaultShopConfig, getDefaultTradeInShopConfig } from './default';
import * as custom from '../filters/marketplace-custom-config';
import { getBrandDirectFilterConfig } from '../filters/brandDirect';
import { Conditions, getConditionsFilterConfig } from '../filters/condition';
import { getSizeFilterConfigFromOptions } from '../filters/size';
import { getTagsFilterConfig } from '../filters/styles';
import { FilterId } from '../../types/filters/filters';
import { BuilderSections } from '../../util/builder';
import { ListingItemType } from '../../types/sharetribe/listing';

const ryleeandcruSizeOptions = [
  { key: 'NB', label: 'NB' },
  { key: '0-3M', label: '0-3M' },
  { key: '3-6M', label: '3-6M' },
  { key: '6-12M', label: '6-12M' },
  { key: '12-18M', label: '12-18M' },
  { key: '12-24M', label: '12-24M' },
  { key: '18-24M', label: '18-24M' },
  { key: '2-3Y', label: '2-3Y' },
  { key: '4-5Y', label: '4-5Y' },
  { key: '6-7Y', label: '6-7Y' },
  { key: '8-9Y', label: '8-9Y' },
  { key: '10-12Y', label: '10-12Y' },
  { key: '12-14Y', label: '12-14Y' },
  { key: 'XS', label: 'XS' },
  { key: 'S', label: 'S' },
  { key: 'M', label: 'M' },
  { key: 'L', label: 'L' },
  { key: 'XL', label: 'XL' },
  { key: 'ONESIZE', label: 'One Size' },
];

const ryleeandcruTags = [
  { key: 'ryleeandcru', label: 'Rylee + Cru', tags: ['RC'] },
  { key: 'quincymae', label: 'Quincy Mae', tags: ['QM'] },
  { key: 'playxplay', label: 'Play x Play', tags: ['PP'] },
];

const ryleeandcruConditionOptions = [
  {
    key: Conditions.NewWithTags,
    label: 'New With Tags',
    description:
      'Your item has never been worn and the original hanging tags are still on. For this option, you’ll need to take pictures of the retail tags.',
  },
  {
    key: Conditions.NewWithoutTags,
    label: 'New Without Tags',
    description:
      'Your item has never been worn (other than perhaps trying it on), but the tags have been removed. There are no quirks or issues whatsoever.',
  },
  {
    key: Conditions.Excellent,
    label: 'Excellent Condition',
    description:
      'Your item has only been lightly used and has been extremely well maintained. There may be some small signs of wear, but nothing major.',
  },
  {
    key: Conditions.Good,
    label: 'Good Condition',
    description:
      'You’ve worn this item and it’s well maintained. There may be some quirks like a small stain, a button missing, or a loose thread, but it’s overall in good shape. You will need to take pictures of any quirks the item has.',
    includedTypes: [ListingItemType.Marketplace],
  },
];

export const ryleeandcruShopConfig = {
  ...getDefaultShopConfig(),
  ...getDefaultTradeInShopConfig(),
  filters: [
    ...custom.filters,
    getTagsFilterConfig({
      id: FilterId.Style,
      options: ryleeandcruTags,
      shouldUseTreetOptions: true,
    }),
    getBrandDirectFilterConfig(),
    getSizeFilterConfigFromOptions({ options: ryleeandcruSizeOptions }),
    getConditionsFilterConfig({ options: ryleeandcruConditionOptions }),
  ],
  builderConfig: {
    sections: {
      [BuilderSections.LandingPageContent]: '907a6847e6564dd28615045070ec0403',
    },
  },
  conditionOptionsOverride: ryleeandcruConditionOptions,
};
