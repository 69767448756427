import { getDefaultShopConfig } from './default';
import * as custom from '../filters/marketplace-custom-config';
import { getBrandDirectFilterConfig } from '../filters/brandDirect';
import { getConditionsFilterConfig } from '../filters/condition';
import { getSizeFilterConfig } from '../filters/size';
import { BuilderSections } from '../../util/builder';

const anianSizes = [
  'Women’s XXS',
  'Women’s XS',
  'Women’s S',
  'Women’s M',
  'Women’s L',
  'Women’s XL',
  'Women’s XXL',
  'Men’s XXS',
  'Men’s XS',
  'Men’s S',
  'Men’s M',
  'Men’s L',
  'Men’s XL',
  'Men’s XXL',
  'Unisex XXS',
  'Unisex XS',
  'Unisex S',
  'Unisex M',
  'Unisex L',
  'Unisex XL',
  'Unisex XXL',
];

export const anianShopConfig = {
  ...getDefaultShopConfig(),
  filters: [
    ...custom.filters,
    // Gender filter has replaced traditional Categories filter,
    // so that is why there is a gender filter on Search but no
    // corresponding gender filter config here.
    getConditionsFilterConfig(),
    getBrandDirectFilterConfig(),
    getSizeFilterConfig({ sizes: anianSizes }),
  ],
  builderConfig: {
    sections: {
      [BuilderSections.LandingPageContent]: '75b18effaac24582941926beda7efd5c',
    },
  },
};
