import { getConditionsFilterConfig } from '../filters/condition';
import { getBrandDirectFilterConfig } from '../filters/brandDirect';
import { getSizeFilterConfig } from '../filters/size';
import { getDefaultShopConfig } from './default';
import * as custom from '../filters/marketplace-custom-config';

export const goldieandaceSizes = [
  '0-3M',
  '3-6M',
  '6-9M',
  '6-12M',
  '9-12M',
  '12-18M',
  '6M',
  '12M',
  '18M',
  '1Y',
  '2Y',
  '3Y',
  '4Y',
  '5Y',
  '6Y',
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  'OS',
];

export const goldieandaceShopConfig = {
  ...getDefaultShopConfig(),
  filters: [
    ...custom.filters,
    getBrandDirectFilterConfig(),
    getConditionsFilterConfig(),
    getSizeFilterConfig({ sizes: goldieandaceSizes }),
  ],
};
