import { Grid } from '@material-ui/core';
import React, { useContext } from 'react';
import PropTypes, { bool } from 'prop-types';
import classNames from 'classnames';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { propTypes } from '../../util/types';
import {
  MAX_LARGE_SCREEN_WIDTH,
  MAX_MEDIUM_SCREEN_WIDTH,
  MAX_SMALL_SCREEN_WIDTH,
} from '../../util/window';
import { useShopConfig } from '../../hooks/shopConfig';
import AppContext from '../../context/AppContext';
import { formatPayoutPercentage } from '../../util/currency';
import { shouldShowListItemCard } from '../../util/search';
import { ListAnItemCard, ListingCard, PaginationLinks } from '..';
import TypographyWrapper, { TypographyWeight } from '../TypographyWrapper/TypographyWrapper';
import { trackClickListingCard } from '../../util/heap';
import css from './SearchResultsPanel.module.css';
import { useEnabledCustomerExperiences } from '../../hooks/useEnabledCustomerExperiences';
import { useYotpo } from '../../hooks/useYotpo';
import { useActivePromos } from '../../hooks/useActivePromos';
import { parse } from '../../util/urlHelpers';
import { RequestStatus } from '../../types/requestStatus';

const MainContentIfUsingLoyaltyPoints = (props) => {
  const { formattedCreditPayout } = props;
  return (
    <>
      <TypographyWrapper
        variant="body1"
        weight={TypographyWeight.Bold}
        typographyOverrides={{ display: 'inline', fontSize: 'inherit' }}
      >
        {`Get ${formattedCreditPayout}% brand credit `}
      </TypographyWrapper>
      in loyalty points when your item sells.
    </>
  );
};

const MainContentIfUsingBrandCredit = (props) => {
  const { formattedCreditPayout, formattedPromoCreditPayoutPercentage } = props;
  const activeCreditPercentage = formattedPromoCreditPayoutPercentage ?? formattedCreditPayout;
  const inactiveCreditStrikethrough = formattedPromoCreditPayoutPercentage ? (
    <>
      {' '}
      <TypographyWrapper
        variant="body1"
        component="span"
        typographyOverrides={{
          style: {
            display: 'inline',
            fontSize: 'inherit',
            textDecoration: 'line-through',
          },
        }}
      >
        {formattedCreditPayout}%
      </TypographyWrapper>{' '}
    </>
  ) : null;

  return (
    <>
      Get {inactiveCreditStrikethrough}
      <TypographyWrapper
        variant="body1"
        component="span"
        weight={TypographyWeight.Bold}
        typographyOverrides={{ style: { display: 'inline', fontSize: 'inherit' } }}
      >
        {`${activeCreditPercentage}% `}
      </TypographyWrapper>
      brand credit for every item you sell.
    </>
  );
};

const ListingCardGridItem = ({ children }) => (
  <Grid
    // Padding is set on the listing card so we can customize how large
    // the gutter width is
    style={{ marginBottom: 10, padding: 0 }}
    item
    xs={6}
    sm={4}
    md={3}
    lg={3}
  >
    {children}
  </Grid>
);

const SearchResultsPanel = (props) => {
  const { className, rootClassName, listings, pagination, search, areListingsLoaded } = props;

  const { treetId } = useContext(AppContext);
  const { creditPayoutPercentage, css: shopCss } = useShopConfig();
  const { allowSell } = useEnabledCustomerExperiences();
  const formattedCreditPayout = formatPayoutPercentage(creditPayoutPercentage);
  const { doesBrandUseLoyaltyPoints } = useYotpo();
  const { promoCreditPayoutPercentage } = useActivePromos();

  const { searchListingsStatus, soldListingsStatus } = useSelector((state) => state.LandingPageV2);

  const formattedPromoCreditPayoutPercentage = promoCreditPayoutPercentage
    ? formatPayoutPercentage(promoCreditPayoutPercentage)
    : undefined;

  const classes = classNames(rootClassName || css.root, className);
  const paginationLinks =
    pagination && pagination.totalPages > 1 ? (
      <PaginationLinks
        className={css.pagination}
        pageName="LandingPage"
        pageSearchParams={search}
        pagination={pagination}
      />
    ) : null;

  const creditMainContent = doesBrandUseLoyaltyPoints ? (
    <MainContentIfUsingLoyaltyPoints formattedCreditPayout={formattedCreditPayout} />
  ) : (
    <MainContentIfUsingBrandCredit
      formattedCreditPayout={formattedCreditPayout}
      formattedPromoCreditPayoutPercentage={formattedPromoCreditPayoutPercentage}
    />
  );

  // Panel width relative to the viewport
  const panelMediumWidth = 50;
  const panelLargeWidth = 62.5;
  const cardRenderSizes = [
    `(max-width: ${MAX_SMALL_SCREEN_WIDTH}px) 100vw`,
    `(max-width: ${MAX_MEDIUM_SCREEN_WIDTH}px) ${panelMediumWidth}vw`,
    `(max-width: ${MAX_LARGE_SCREEN_WIDTH}px) ${panelLargeWidth / 2}vw`,
    `${panelLargeWidth / 3}vw`,
  ].join(', ');

  const location = useLocation();
  const searchParams = parse(location.search);

  const trackListingCardHeapEvent = (params) => {
    trackClickListingCard({
      ...params,
      originOfClick: 'SearchResults',
      searchKeywords: searchParams.keywords,
    });
  };

  const isFirstPage = pagination?.page === 1;
  const isSinglePage = pagination?.totalPages === 1;
  const showListItemCard = shouldShowListItemCard(
    isFirstPage,
    isSinglePage,
    allowSell,
    search,
    listings.length
  );
  const listingCardStyle = shopCss?.listingCardStyle || {};
  const areListingsFetched =
    searchListingsStatus !== RequestStatus.Pending && soldListingsStatus !== RequestStatus.Pending;

  return (
    <div className={classes}>
      <div className={css.listingCards}>
        <Grid container direction="row" spacing={1}>
          {areListingsFetched && (
            <>
              {showListItemCard && (
                <ListingCardGridItem key="list-an-item">
                  <ListAnItemCard
                    style={{ padding: '0 4px', ...listingCardStyle }}
                    buttonText={{ default: 'List An Item', condensed: 'Sell' }}
                    captionHeaderText="List An Item"
                    captionSubtitleText={
                      creditPayoutPercentage
                        ? `Get ${
                            formattedPromoCreditPayoutPercentage || formattedCreditPayout
                          }% brand credit`
                        : 'Earn Cash'
                    }
                    mainContent={
                      creditPayoutPercentage
                        ? creditMainContent
                        : 'Earn cash for every item you sell.'
                    }
                  />
                </ListingCardGridItem>
              )}
              {listings.map((l, index) => (
                <ListingCardGridItem key={l.id.uuid}>
                  <ListingCard
                    key={l.id.uuid}
                    style={{ padding: '0 4px', ...listingCardStyle }}
                    index={index}
                    listing={l}
                    referrerLocation="Search Page"
                    renderSizes={cardRenderSizes}
                    subdomain={treetId}
                    soldListingText="JUST SOLD"
                    trackClickListingCardHeapEvent={trackListingCardHeapEvent}
                  />
                </ListingCardGridItem>
              ))}
            </>
          )}
        </Grid>
        {props.children}
      </div>
      {/* To prevent content shift, make sure the search results are ready to render before
      displaying anything below it. */}
      {areListingsLoaded && paginationLinks}
    </div>
  );
};

SearchResultsPanel.defaultProps = {
  children: null,
  className: null,
  listings: [],
  pagination: null,
  rootClassName: null,
  search: null,
  areListingsLoaded: false,
};

const { array, node, object, string } = PropTypes;

SearchResultsPanel.propTypes = {
  children: node,
  className: string,
  listings: array,
  pagination: propTypes.pagination,
  rootClassName: string,
  search: object,
  areListingsLoaded: bool,
};

export default SearchResultsPanel;
