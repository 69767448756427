/* eslint-disable import/prefer-default-export */
import {
  FilterId,
  GenericFilterOption,
  SearchFilterType,
  SearchNavBarSections,
} from '../../types/filters/filters';
import { FilterQueryParam } from '../../types/search/search';

interface GetDecadeFilterConfigInputs {
  options?: GenericFilterOption[];
}

export const getDecadeFilterConfig = (params?: GetDecadeFilterConfigInputs) => {
  const { options = [] } = params || {};
  return {
    id: FilterId.Decade,
    label: 'Decade',
    type: SearchFilterType.Checkbox,
    group: 'primary',
    queryParamNames: [FilterQueryParam.decade],
    searchNavBarSection: SearchNavBarSections.Filter,
    config: { options },
  };
};
