import { Box, Typography } from '@material-ui/core';
import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import { Button, DialogWithCloseButton, Divider, InlineTextButton } from '..';
import SizeNotificationsForm, {
  SizeNotificationsFormValues,
} from '../../forms/SizeNotificationsForm/SizeNotificationsForm';
import { useShopConfig } from '../../hooks/shopConfig';
import { useIsMobile } from '../../hooks/useIsMobile';
import css from './ManageNotificationsModal.module.css';
import { resetActiveModal } from '../../ducks/modal.duck';

const HeaderBar: FC = () => (
  <Box display="flex" flexDirection="row" alignItems="center" justifyContent="center" height="60px">
    <Typography variant="h2">Manage Notifications</Typography>
  </Box>
);

const Footer: FC = () => {
  const dispatch = useDispatch();
  const isMobile = useIsMobile();
  const { css: brandCss } = useShopConfig();

  return (
    <Box
      bgcolor={brandCss?.backgroundColor || 'white'}
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      p={2}
    >
      <InlineTextButton>
        <Box mx={{ xs: 1, sm: 2 }} onClick={() => dispatch(resetActiveModal())}>
          Cancel
        </Box>
      </InlineTextButton>
      <Button className={css.footerButton} type="submit">
        {isMobile ? 'Save' : 'Save Changes'}
      </Button>
    </Box>
  );
};

const FooterActions: FC = () => (
  <Box className={css.footer}>
    <Divider className={css.divider} />
    <Footer />
  </Box>
);

interface ManageNotificationsModalProps {
  isOpen: boolean;
  subscribeSizes: string[];
  onSubmit: (values: SizeNotificationsFormValues) => void;
}

const ManageNotificationsModal: FC<ManageNotificationsModalProps> = (props) => {
  const { isOpen, subscribeSizes, onSubmit } = props;

  const dispatch = useDispatch();
  const { css: brandCss } = useShopConfig();

  return (
    <DialogWithCloseButton
      open={isOpen}
      allowFullScreen={false}
      headerClassName={css.dialogHeader}
      closeButtonClassName={css.dialogCloseButton}
      fullWidth
      maxWidth="sm"
      onClose={() => dispatch(resetActiveModal())}
    >
      <Box bgcolor={brandCss?.backgroundColor || 'white'} className={css.content}>
        <Box bgcolor="inherit">
          <HeaderBar />
          <Divider className={css.divider} />
        </Box>
        <SizeNotificationsForm
          initialValues={{ sizes: subscribeSizes }}
          onSubmit={onSubmit}
          actions={<FooterActions />}
        />
      </Box>
    </DialogWithCloseButton>
  );
};

export default ManageNotificationsModal;
