/* eslint-disable import/prefer-default-export */
import { getDefaultShopConfig } from './default';
import * as custom from '../filters/marketplace-custom-config';
import { getBrandDirectFilterConfig } from '../filters/brandDirect';
import { getConditionsFilterConfig } from '../filters/condition';
import { getSizeFilterConfig } from '../filters/size';
import { getGenderFilterConfig } from '../filters/gender';

const grayersSizes = [
  'XS',
  'S',
  'M',
  'L',
  'XL',
  'XXL',
  'One Size',
  '30W/30',
  '31W/30',
  '32W/30',
  '33W/30',
  '34W/30',
  '35W/30',
  '36W/30',
  '38W/30',
  '40W/30',
  '30W/32',
  '31W/32',
  '32W/32',
  '33W/32',
  '34W/32',
  '35W/32',
  '36W/32',
  '38W/32',
  '40W/32',
  '32W/34',
  '33W/34',
  '34W/34',
  '36W/34',
  '38W/34',
  '2',
  '4',
  '6',
  '8',
  '10',
  '12',
];

export const grayersShopConfig = {
  ...getDefaultShopConfig(),
  filters: [
    ...custom.filters,
    getGenderFilterConfig(),
    getConditionsFilterConfig(),
    getBrandDirectFilterConfig(),
    getSizeFilterConfig({ sizes: grayersSizes }),
  ],
};
