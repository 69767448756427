/* eslint-disable import/prefer-default-export */

import { defaultTreetStyles } from '../../shopConfig/config';
import { IShopCss } from '../../types/contentful/types.generated';

// Taken from https://stackoverflow.com/questions/5560248/programmatically-lighten-or-darken-a-hex-color-or-rgb-and-blend-colors
export const getPercentAlteredHexColor = (color: string, percentage: number) => {
  let R = parseInt(color.substring(1, 3), 16);
  let G = parseInt(color.substring(3, 5), 16);
  let B = parseInt(color.substring(5, 7), 16);

  R = parseInt(`${(R * (100 + percentage)) / 100}`, 10);
  G = parseInt(`${(G * (100 + percentage)) / 100}`, 10);
  B = parseInt(`${(B * (100 + percentage)) / 100}`, 10);

  R = R < 255 ? R : 255;
  G = G < 255 ? G : 255;
  B = B < 255 ? B : 255;

  const RR = R.toString(16).length === 1 ? `0${R.toString(16)}` : R.toString(16);
  const GG = G.toString(16).length === 1 ? `0${G.toString(16)}` : G.toString(16);
  const BB = B.toString(16).length === 1 ? `0${B.toString(16)}` : B.toString(16);

  return `#${RR}${GG}${BB}`;
};

// Used for elements that should be unobtrusively distinguished from the background
// i.e. NavBar, Divider, Banner
export const getDarkerBrandCssBackgroundColor = (brandCss: IShopCss): string =>
  (brandCss?.backgroundColor && getPercentAlteredHexColor(brandCss.backgroundColor, -2)) ||
  defaultTreetStyles.gray10;

export const isValidHex = (hex?: string) => {
  if (!hex) return false;
  // Regular expression to check if the hex code is valid for both standard and shorthand notation & optional transparency for standard color codes
  const regex = /^#([0-9A-F]{3}([0-9A-F]{3})?([0-9A-F]{2})?)$/i;
  return regex.test(hex);
};

export const expandShorthandHex = (hex?: string) => {
  if (!hex || !isValidHex(hex)) return null;
  // If the hex code is in shorthand notation (e.g., #F00), expand it (e.g., #FF0000)
  if (hex.length === 4) {
    return `#${hex[1]}${hex[1]}${hex[2]}${hex[2]}${hex[3]}${hex[3]}`;
  }

  return hex;
};

export const hexToRgb = (hex?: string) => {
  const expandedHex = expandShorthandHex(hex);

  if (expandedHex) {
    const intVal = parseInt(expandedHex.slice(1), 16);
    /* eslint-disable no-bitwise */
    const r = (intVal >> 16) & 255;
    const g = (intVal >> 8) & 255;
    const b = intVal & 255;
    /* eslint-enable no-bitwise */
    return [r, g, b];
  }
  return null;
};

export const areHexColorsSimilar = ({
  firstHexColor,
  secondHexColor,
  euclideanDistanceThreshold,
}: {
  firstHexColor: string;
  secondHexColor: string;
  euclideanDistanceThreshold: number;
}) => {
  const firstColor = hexToRgb(firstHexColor);
  const secondColor = hexToRgb(secondHexColor);

  if (!firstColor || !secondColor) return false;

  // Calculate the Euclidean distance between the two RGB colors
  const distance = Math.sqrt(
    (secondColor[0] - firstColor[0]) ** 2 +
      (secondColor[1] - firstColor[1]) ** 2 +
      (secondColor[2] - firstColor[2]) ** 2
  );

  return distance <= euclideanDistanceThreshold;
};

export const areColorsValidAndDistinct = ({
  firstHexColor,
  secondHexColor,
  euclideanDistanceThreshold,
}: {
  firstHexColor?: string;
  secondHexColor?: string;
  euclideanDistanceThreshold: number;
}) => {
  if (
    !firstHexColor ||
    !secondHexColor ||
    !isValidHex(firstHexColor) ||
    !isValidHex(secondHexColor)
  )
    return false;

  return !areHexColorsSimilar({
    firstHexColor,
    secondHexColor,
    euclideanDistanceThreshold,
  });
};
