import arrayMutators from 'final-form-arrays';
import React, { FC, ReactNode } from 'react';
import { Form as FinalForm } from 'react-final-form';
import { Form } from '../../components';
import SizeNotifications, {
  SizeNotificationsFormValue,
} from '../../components/SizeNotifications/SizeNotifications';
import css from './SizeNotificationsForm.module.css';

export interface SizeNotificationsFormValues {
  [SizeNotificationsFormValue.Sizes]: string[];
  [SizeNotificationsFormValue.ShouldNotify]: boolean;
}

interface SizeNotificationsFormProps {
  actions: ReactNode;
  initialValues: {
    sizes: string[];
    shouldNotify?: boolean;
  };
  showToggle?: boolean;
  className?: string;
  onSubmit: (values: SizeNotificationsFormValues) => void;
  isLoading?: boolean;
  notifyMeBodyContent?: ReactNode;
  shouldAlwaysShowSizes?: boolean;
  formId?: string;
}

const SizeNotificationsForm: FC<SizeNotificationsFormProps> = (props) => {
  const {
    actions,
    initialValues: passedInitialValues,
    showToggle = false,
    className,
    onSubmit,
    isLoading,
    notifyMeBodyContent,
    shouldAlwaysShowSizes,
    formId = 'SizeNotifications',
  } = props;

  if (isLoading) return null;

  // If the notify toggle isn't being shown, we should default the
  // SizeNotificationsFormValue.ShouldNotify field to true; however, always override if
  // SizeNotificationsFormValue.ShouldNotify is passed explicitly in the initialValues prop
  const initialValues = {
    [SizeNotificationsFormValue.ShouldNotify]: !showToggle,
    ...passedInitialValues,
  };

  return (
    <FinalForm
      onSubmit={onSubmit}
      initialValues={initialValues}
      mutators={{ ...arrayMutators }}
      keepDirtyOnReinitialize
      render={(formRenderProps) => {
        const { handleSubmit } = formRenderProps;

        return (
          <Form id={formId} onSubmit={handleSubmit} className={css.formWrapper}>
            <SizeNotifications
              formId="SizeNotifications"
              className={className}
              showToggle={showToggle}
              notifyMeBodyContent={notifyMeBodyContent}
              shouldAlwaysShowSizes={shouldAlwaysShowSizes}
              actions={actions}
            />
          </Form>
        );
      }}
    />
  );
};

export default SizeNotificationsForm;
