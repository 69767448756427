import { useMediaQuery, useTheme } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import React, { FC, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { CancelModal, DisputeOrVerifyTradeInModal, PayoutMethodModal, VerifyModal } from '..';
import { useBrandCountryConfig } from '../../hooks/useCountryConfig';
import { useCurrentUserPermissions } from '../../hooks/useUserPermissions';
import { BundleType, Participant } from '../../types/apollo/generated/types.generated';
import { CancelFormData } from '../../types/forms/cancelFormData';
import { DisputeFormData } from '../../types/forms/disputeFormData';
import { PayoutOptionFormData } from '../../types/forms/payoutOptionFormData';
import { BundleInfo } from '../../types/models/bundle';
import { PayoutOptions } from '../../util/constants';
import { handle } from '../../util/helpers';
import MarkAsActionModal from '../MarkAsActionModal/MarkAsActionModal';
import ManageBundlesContainerDesktop from './ManageBundlesContainerDesktop';
import ManageBundlesContainerMobile from './ManageBundlesContainerMobile';
import { getPayoutValues, StatusFilterValues } from './manageBundlesContainerUtils';
import { error as logError } from '../../util/log';
import { ModalType, resetActiveModal } from '../../ducks/modal.duck';
import { useActiveModal } from '../../hooks/useActiveModal';

interface ManageBundlesContainerProps {
  isLoading: boolean;
  error?: string;
  bundles: BundleInfo[];
  onCancel?: (cancelData: CancelFormData, bundle: BundleInfo) => Promise<any>;
  onVerify?: (bundle: BundleInfo) => Promise<any>;
  onDispute?: (bundle: BundleInfo, DisputeFormData: DisputeFormData) => Promise<any>;
  onGetPaid?: (bundle: BundleInfo, payoutOptionFormData: PayoutOptionFormData) => Promise<any>;
  onFilterChange?: (value: StatusFilterValues) => void;
  onMarkAsFulfilled?: (bundle: BundleInfo) => Promise<any>;
  onMarkAsDelivered?: (bundle: BundleInfo) => Promise<any>;
  onEditShippingAddressSubmit?: (values: any, addressId: string, onSuccess: () => void) => void;
  participant: Participant;
  fetchMore?: (() => void) | false;
}

const ManageBundlesContainer: FC<ManageBundlesContainerProps> = (
  props: ManageBundlesContainerProps
) => {
  const {
    bundles,
    isLoading,
    error: bundleError,
    onCancel,
    onVerify,
    onDispute,
    onGetPaid,
    onFilterChange,
    onMarkAsFulfilled,
    onMarkAsDelivered,
    onEditShippingAddressSubmit,
    participant,
    fetchMore,
  } = props;

  const dispatch = useDispatch();
  const [activeBundle, setActiveBundle] = useState<BundleInfo | undefined>(undefined);

  const { isModalOpen: isCancelBundleModalOpen, openModal: openCancelBundleModal } = useActiveModal(
    ModalType.CancelBundle
  );
  const { isModalOpen: isVerifyBundleModalOpen, openModal: openVerifyBundleModal } = useActiveModal(
    ModalType.VerifyBundle
  );
  const {
    isModalOpen: isDisputeOrVerifyDradeInBundleModalOpen,
    openModal: openDisputeOrVerifyTradeInBundleModal,
  } = useActiveModal(ModalType.DisputeOrVerifyTradeInBundle);
  const { isModalOpen: isGetPaidForBundleModalOpen, openModal: openGetPaidForBundleModal } =
    useActiveModal(ModalType.GetPaidForBundle);
  const {
    isModalOpen: isMarkBundleAsFulfilledModalOpen,
    openModal: openMarkBundleAsFulfilledModal,
  } = useActiveModal(ModalType.MarkBundleAsFulfilled);
  const {
    isModalOpen: isMarkBundleAsDeliveredModalOpen,
    openModal: openMarkBundleAsDeliveredModal,
  } = useActiveModal(ModalType.MarkBundleAsDelivered);

  const closeModalAndResetActiveBundle = () => {
    setActiveBundle(undefined);
    dispatch(resetActiveModal());
  };

  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const intl = useIntl();
  const { isBrand } = useCurrentUserPermissions();
  const brandCountryConfig = useBrandCountryConfig();

  const [isVerifySubmitInProgress, setIsVerifySubmitInProgress] = useState(false);
  const rootState = useSelector<any>((state) => state) as any;

  const onCancelSubmit = async (values: CancelFormData) => {
    if (!onCancel || !isCancelBundleModalOpen || !activeBundle) {
      return;
    }

    const [response, error] = await handle(onCancel(values, activeBundle));

    if (response) {
      enqueueSnackbar('Order successfully canceled.', {
        variant: 'success',
        transitionDuration: { enter: 800, exit: 500 },
      });
    }
    if (error) {
      const userDisplayErrorMessage = 'Something went wrong. Please contact support@treet.co.';
      error.message = `User encountered error: ${error.message}`;
      logError(error, 'cancel-bundle-submit-error', {
        error,
        userDisplayErrorMessage,
        values,
        activeBundle,
      });

      console.error('cancel-bundle-submit-error', error);
      enqueueSnackbar(userDisplayErrorMessage, {
        variant: 'error',
        transitionDuration: { enter: 800, exit: 500 },
      });
    }
    closeModalAndResetActiveBundle();
  };

  const onVerifySubmit = async (bundle: BundleInfo | undefined) => {
    if (!onVerify || !bundle) return;

    setIsVerifySubmitInProgress(true);
    const [response, error] = await handle(onVerify(bundle));
    setIsVerifySubmitInProgress(false);

    if (response) {
      enqueueSnackbar('Thanks for verifying!', {
        variant: 'success',
        transitionDuration: { enter: 800, exit: 500 },
      });
    }
    if (error) {
      const userDisplayErrorMessage = 'Something went wrong. Please contact support@treet.co.';
      error.message = `User encountered error: ${error.message}`;
      logError(error, 'verify-bundle-submit-error', {
        error,
        userDisplayErrorMessage,
        bundle,
      });

      console.error('verify-bundle-submit-error', error);
      enqueueSnackbar(userDisplayErrorMessage, {
        variant: 'error',
        transitionDuration: { enter: 800, exit: 500 },
      });
    }
    closeModalAndResetActiveBundle();
  };

  const onDisputeSubmit = async (values: DisputeFormData) => {
    if (!onDispute || !isDisputeOrVerifyDradeInBundleModalOpen || !activeBundle) {
      return;
    }

    const [response, error] = await handle(onDispute(activeBundle, values));
    const isTradeInBundle = activeBundle.type === BundleType.TradeIn;
    const isReturn = activeBundle?.isReturnable;

    if (response) {
      const message = isTradeInBundle
        ? 'Item verification successfully submitted.'
        : isReturn
        ? 'Return inquiry submitted. Please check your email for the next steps.'
        : 'Inquiry submitted. We will get back to you as soon as we can.';
      enqueueSnackbar(message, {
        variant: 'success',
        transitionDuration: { enter: 800, exit: 500 },
      });
    }
    if (error) {
      const userDisplayErrorMessage = 'Something went wrong. Please contact support@treet.co.';
      error.message = `User encountered error: ${error.message}`;
      logError(error, 'dispute-bundle-submit-error', {
        error,
        userDisplayErrorMessage,
        values,
        activeBundle,
        isTradeInBundle,
      });

      console.error('dispute-bundle-submit-error', error);
      enqueueSnackbar(userDisplayErrorMessage, {
        variant: 'error',
        transitionDuration: { enter: 800, exit: 500 },
      });
    }
    closeModalAndResetActiveBundle();
  };

  const onGetPaidSubmit = async (values: PayoutOptionFormData) => {
    if (!onGetPaid || !isGetPaidForBundleModalOpen || !activeBundle) {
      return;
    }

    const [response, error] = await handle(onGetPaid(activeBundle, values));

    if (response) {
      if (values.payoutOption === PayoutOptions.Cash) {
        enqueueSnackbar('Thank you! You should receive the funds within 7 days.', {
          variant: 'success',
          transitionDuration: { enter: 800, exit: 500 },
        });
      } else if (values.payoutOption === PayoutOptions.Credit) {
        enqueueSnackbar('Thank you! You should receive an email shortly.', {
          variant: 'success',
          transitionDuration: { enter: 800, exit: 500 },
        });
      }
    }

    if (error) {
      enqueueSnackbar('Something went wrong. Please contact support@treet.co.', {
        variant: 'error',
        transitionDuration: { enter: 800, exit: 500 },
      });
    }
    closeModalAndResetActiveBundle();
  };

  const onMarkAsFulfilledSubmit = async () => {
    if (!onMarkAsFulfilled || !isMarkBundleAsFulfilledModalOpen || !activeBundle) {
      return;
    }

    const [response, error] = await handle(onMarkAsFulfilled(activeBundle));

    if (response) {
      enqueueSnackbar('Bundle marked as fulfilled.', {
        variant: 'success',
        transitionDuration: { enter: 800, exit: 500 },
      });
    }
    if (error) {
      enqueueSnackbar('Something went wrong. Please contact support@treet.co.', {
        variant: 'error',
        transitionDuration: { enter: 800, exit: 500 },
      });
    }
    closeModalAndResetActiveBundle();
  };

  const onMarkAsDeliveredSubmit = async () => {
    if (!onMarkAsDelivered || !isMarkBundleAsDeliveredModalOpen || !activeBundle) {
      return;
    }

    const [response, error] = await handle(onMarkAsDelivered(activeBundle));

    if (response) {
      enqueueSnackbar('Bundle marked as delivered.', {
        variant: 'success',
        transitionDuration: { enter: 800, exit: 500 },
      });
    }
    if (error) {
      enqueueSnackbar('Something went wrong. Please contact support@treet.co.', {
        variant: 'error',
        transitionDuration: { enter: 800, exit: 500 },
      });
    }
    closeModalAndResetActiveBundle();
  };

  const needsVerifiedBundleItems = true;
  const payoutValues = getPayoutValues(
    activeBundle,
    intl,
    brandCountryConfig,
    rootState,
    needsVerifiedBundleItems
  );

  const onCancelClick = async (bundle: BundleInfo) => {
    setActiveBundle({ ...bundle });
    openCancelBundleModal();
  };

  const onVerifyClick = async (bundle: BundleInfo) => {
    setActiveBundle({ ...bundle });
    openVerifyBundleModal();
  };

  const onDisputeClick = async (bundle: BundleInfo) => {
    setActiveBundle({ ...bundle });
    openDisputeOrVerifyTradeInBundleModal();
  };

  const onGetPaidClick = async (bundle: BundleInfo) => {
    setActiveBundle({ ...bundle });
    openGetPaidForBundleModal();
  };

  const onMarkAsFulfilledClick = async (bundle: BundleInfo) => {
    setActiveBundle({ ...bundle });
    openMarkBundleAsFulfilledModal();
  };

  const onMarkAsDeliveredClick = async (bundle: BundleInfo) => {
    setActiveBundle({ ...bundle });
    openMarkBundleAsDeliveredModal();
  };

  return (
    <>
      {isMobile && (
        <ManageBundlesContainerMobile
          bundles={bundles}
          isLoading={isLoading}
          participant={participant}
          onCancelClick={onCancelClick}
          onVerifyClick={onVerifyClick}
          onDisputeClick={onDisputeClick}
          onGetPaidClick={onGetPaidClick}
          onMarkAsFulfilledClick={onMarkAsFulfilledClick}
          onMarkAsDeliveredClick={onMarkAsDeliveredClick}
          onEditShippingAddressSubmit={onEditShippingAddressSubmit}
          error={bundleError}
          fetchMore={fetchMore}
        />
      )}
      {!isMobile && (
        <ManageBundlesContainerDesktop
          bundles={bundles}
          isLoading={isLoading}
          participant={participant}
          onCancelClick={onCancelClick}
          onVerifyClick={onVerifyClick}
          onDisputeClick={onDisputeClick}
          onGetPaidClick={onGetPaidClick}
          onMarkAsFulfilledClick={onMarkAsFulfilledClick}
          onMarkAsDeliveredClick={onMarkAsDeliveredClick}
          onEditShippingAddressSubmit={onEditShippingAddressSubmit}
          onFilterChange={onFilterChange}
          error={bundleError}
          fetchMore={fetchMore}
        />
      )}
      <CancelModal
        open={isCancelBundleModalOpen}
        onClose={closeModalAndResetActiveBundle}
        onSubmit={(values) => onCancelSubmit(values)}
      />
      <VerifyModal
        open={isVerifyBundleModalOpen}
        onClose={closeModalAndResetActiveBundle}
        onSubmit={() => onVerifySubmit(activeBundle)}
        inProgress={isVerifySubmitInProgress}
        isReturnable={activeBundle?.isReturnable}
      />
      <DisputeOrVerifyTradeInModal
        open={isDisputeOrVerifyDradeInBundleModalOpen}
        onClose={closeModalAndResetActiveBundle}
        onSubmit={onDisputeSubmit}
        onVerify={() => onVerifySubmit(activeBundle)}
        bundle={activeBundle}
      />
      <PayoutMethodModal
        open={isGetPaidForBundleModalOpen}
        onClose={closeModalAndResetActiveBundle}
        payoutValues={payoutValues}
        onSubmit={(values: PayoutOptionFormData) => onGetPaidSubmit(values)}
      />
      <MarkAsActionModal
        open={isMarkBundleAsFulfilledModalOpen}
        onClose={closeModalAndResetActiveBundle}
        onSubmit={onMarkAsFulfilledSubmit}
        // For brands, use more standard e-commerce fulfillment language; otherwise use colloquial
        // language.
        actionLabel={isBrand ? 'Fulfilled' : 'Shipped'}
        actionDescription={`By marking this order as ${
          isBrand ? 'fulfilled' : 'shipped'
        }, you are confirming that you have shipped this item.`}
      />
      <MarkAsActionModal
        open={isMarkBundleAsDeliveredModalOpen}
        onClose={closeModalAndResetActiveBundle}
        onSubmit={onMarkAsDeliveredSubmit}
        actionLabel="Delivered"
        actionDescription="By marking this order as delivered, you are confirming that you have received this item."
      />
    </>
  );
};

export default ManageBundlesContainer;
