import { Typography } from '@material-ui/core';
import React, { FC, useContext } from 'react';
import { useSelector } from 'react-redux';
import { NamedLink } from '../../components';
import { ListingPageParamTab } from './EditListingPage.utils';
import { EditListingPageState } from './EditListingPage.duck';

import css from './EditListingPage.module.css';
import { ListingItemType } from '../../types/sharetribe/listing';
import { getUrlHash } from '../../util/urlHelpers';
import { ISOContext } from '../../components/ISOModal/ISOModal';
import { useListingFlowConfig } from '../../hooks/useListingFlowConfig';

interface CannotFindItemLinkProps {
  listingItemType?: ListingItemType;
  children?: React.ReactNode;
}

const CannotFindItemLink: FC<CannotFindItemLinkProps> = (props) => {
  const { listingItemType, children } = props;
  const isISO = useContext(ISOContext);
  const { params } = useSelector<any>((state) => state.EditListingPage) as EditListingPageState;
  const search = listingItemType ? new URLSearchParams({ listingItemType }).toString() : null;
  const hash = getUrlHash()?.substring(1);

  // TODO: TREET-5498 determine whether this ISO context is still necessary
  if (isISO) {
    return null;
  }

  const { cannotFindMyItemText } = useListingFlowConfig();

  return (
    <NamedLink
      name="EditListingPage"
      params={{ ...params, tab: ListingPageParamTab.CannotFind }}
      className={css.link}
      {...{ to: { search, hash } }}
    >
      {children || (
        <Typography variant="body2">{cannotFindMyItemText || 'Can’t find your item?'}</Typography>
      )}
    </NamedLink>
  );
};

export default CannotFindItemLink;
