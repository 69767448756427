/* eslint-disable import/prefer-default-export */
import { getDefaultShopConfig } from './default';
import { getBrandDirectFilterConfig } from '../filters/brandDirect';
import { getConditionsFilterConfig } from '../filters/condition';
import * as custom from '../filters/marketplace-custom-config';
import { getSizeFilterConfig } from '../filters/size';
import { BuilderSections } from '../../util/builder';

const lornajaneSizeOptions: string[] = ['XXS', 'XS', 'S', 'M', 'L', 'XL', 'XXL', 'One Size'];

export const lornajaneShopConfig = {
  ...getDefaultShopConfig(),
  filters: [
    ...custom.filters,
    getBrandDirectFilterConfig(),
    getSizeFilterConfig({ sizes: lornajaneSizeOptions }),
    getConditionsFilterConfig(),
  ],
  builderConfig: {
    sections: {
      [BuilderSections.LandingPageContent]: '02060178a5db4d80b0f9cc995b3340fb',
    },
  },
};
