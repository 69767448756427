import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { fetchCurrentUser } from '../../ducks/user.duck';
import { RequestStatus } from '../../types/requestStatus';
import { fetchSoldListings } from '../../util/api';
import { getShopConfig } from '../../shopConfig/configHelper';
import { denormalisedResponseEntities } from '../../util/data';
import { storableError } from '../../util/errors';
import { handle } from '../../util/helpers';
import * as log from '../../util/log';
import { types as sdkTypes } from '../../util/sdkLoader';
import { ListingItemType } from '../../types/sharetribe/listing';

const { UUID } = sdkTypes;

const RESULT_PAGE_SIZE = 100;

// ================ Action types ================ //

export const SET_INITIAL_STATE = 'app/ProfilePage/SET_INITIAL_STATE';

export const SHOW_USER_REQUEST = 'app/ProfilePage/SHOW_USER_REQUEST';
export const SHOW_USER_SUCCESS = 'app/ProfilePage/SHOW_USER_SUCCESS';
export const SHOW_USER_ERROR = 'app/ProfilePage/SHOW_USER_ERROR';

export const QUERY_LISTINGS_REQUEST = 'app/ProfilePage/QUERY_LISTINGS_REQUEST';
export const QUERY_LISTINGS_SUCCESS = 'app/ProfilePage/QUERY_LISTINGS_SUCCESS';
export const QUERY_LISTINGS_ERROR = 'app/ProfilePage/QUERY_LISTINGS_ERROR';

// ================ Reducer ================ //

const initialState = {
  userId: null,
  userShowError: null,
  queryUserListingsStatus: RequestStatus.Ready,
  queryListingsError: null,
  listingIds: [],
  openListingsPagination: null,
  soldListingsPagination: null,
};

export default function profilePageReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case SET_INITIAL_STATE:
      return { ...initialState };

    case SHOW_USER_REQUEST:
      return { ...state, userShowError: null, userId: payload.userId };
    case SHOW_USER_SUCCESS:
      return state;
    case SHOW_USER_ERROR:
      return { ...state, userShowError: payload };

    case QUERY_LISTINGS_REQUEST:
      return {
        ...state,

        queryUserListingsStatus: RequestStatus.Pending,
        // Empty listings only when user id changes
        listingIds: payload.userId.uuid !== state.userId.uuid ? [] : state.listingIds,
        queryListingsError: null,
      };
    case QUERY_LISTINGS_SUCCESS:
      return {
        ...state,
        queryUserListingsStatus: RequestStatus.Success,
        listingIds: [...state.listingIds, ...payload.listingIds],
        openListingsPagination: { ...payload.openListingsPagination },
        soldListingsPagination: { ...payload.soldListingsPagination },
      };
    case QUERY_LISTINGS_ERROR:
      return {
        ...state,
        queryUserListingsStatus: RequestStatus.Error,
        queryListingsError: payload,
      };

    default:
      return state;
  }
}

// ================ Action creators ================ //

export const setInitialState = () => ({
  type: SET_INITIAL_STATE,
});

export const showUserRequest = (userId) => ({
  type: SHOW_USER_REQUEST,
  payload: { userId },
});

export const showUserSuccess = () => ({
  type: SHOW_USER_SUCCESS,
});

export const showUserError = (e) => ({
  type: SHOW_USER_ERROR,
  error: true,
  payload: e,
});

export const queryListingsRequest = (userId) => ({
  type: QUERY_LISTINGS_REQUEST,
  payload: { userId },
});

export const queryListingsSuccess = (payload) => ({
  type: QUERY_LISTINGS_SUCCESS,
  payload,
});

export const queryListingsError = (e) => ({
  type: QUERY_LISTINGS_ERROR,
  error: true,
  payload: e,
});

// ================ Thunks ================ //

const getSoldListings = async (page, authorId) => {
  const [soldListingsResponse, soldListingsError] = await handle(
    fetchSoldListings({
      page,
      perPage: RESULT_PAGE_SIZE,
      authorId,
      params: { 'fields.user': ['profile.displayName', 'profile.abbreviatedName', 'profile.bio'] },
    })
  );

  if (soldListingsError) {
    log.error(soldListingsError, 'profile-page-fetch-sold-listings-failed', { authorId });
  }

  return soldListingsResponse;
};

export const queryUserListings = (params) => async (dispatch, getState, sdk) => {
  const state = getState();
  const { treetId, shopConfig: shopConfigV2 } = state.initial;
  const { openListingsPagination, soldListingsPagination } = state.ProfilePage;

  const { shopName } = getShopConfig(treetId, shopConfigV2);
  const { userId } = params;
  const authorId = userId.uuid;

  dispatch(queryListingsRequest(userId));

  let resultIds;
  try {
    // Fetch open listings if there are any left to fetch
    const prevOpenListingsPage = openListingsPagination?.page || 0;
    const currOpenListingsPage = prevOpenListingsPage + 1;
    const hasMoreOpenListings =
      !openListingsPagination || currOpenListingsPage <= openListingsPagination.totalPages;
    let openListingIds = [];
    let updatedOpenListingsPagination = openListingsPagination;
    if (hasMoreOpenListings) {
      const openListingsResponse = await sdk.listings.query({
        page: currOpenListingsPage,
        per_page: RESULT_PAGE_SIZE,
        author_id: authorId,
        pub_shopName: shopName,
        pub_listingItemType: ListingItemType.Marketplace,
        include: ['author', 'images'],
        'fields.image': [
          'variants.landscape-crop',
          'variants.landscape-crop2x',
          'variants.default',
        ],
      });
      dispatch(addMarketplaceEntities(openListingsResponse));
      openListingIds = denormalisedResponseEntities(openListingsResponse).map(
        (listing) => listing.id
      );
      updatedOpenListingsPagination = openListingsResponse.data.meta;
    }

    // We need to know the sold listings' pagination data immediately so that we can display
    // the total count of listings, so if this is our first time fetching and we've already fetched
    // a full page of open listings (meaning we won't fetch any sold listings this time around),
    // we query the sold listings endpoint here so that we can store the returned pagination data.
    const didFetchFullPageOfOpenListings = openListingIds.length === RESULT_PAGE_SIZE;
    let updatedSoldListingsPagination = soldListingsPagination;
    if (!soldListingsPagination && didFetchFullPageOfOpenListings) {
      const soldListingsResponse = await getSoldListings(1, authorId);

      if (soldListingsResponse) {
        // Reset the page number since we still want to fetch starting from page 1
        // once we've fetched all the open listings
        updatedSoldListingsPagination = { ...soldListingsResponse.data.meta, page: null };
      }
    }

    // Fetch sold listings if we're done fetching open listings
    let soldListingIds = [];
    const prevSoldListingsPage = soldListingsPagination?.page || 0;
    const currSoldListingsPage = prevSoldListingsPage + 1;
    const hasMoreSoldListings =
      !soldListingsPagination || currSoldListingsPage <= soldListingsPagination.totalPages;
    if (!didFetchFullPageOfOpenListings && hasMoreSoldListings) {
      const soldListingsResponse = await getSoldListings(currSoldListingsPage, authorId);

      if (soldListingsResponse) {
        dispatch(addMarketplaceEntities(soldListingsResponse));
        soldListingIds = denormalisedResponseEntities(soldListingsResponse).map(
          (listing) => listing.id
        );
        updatedSoldListingsPagination = soldListingsResponse.data.meta;
      }
    }

    resultIds = [...openListingIds, ...soldListingIds];
    const queryListingsPayload = {
      listingIds: resultIds,
      openListingsPagination: updatedOpenListingsPagination,
      soldListingsPagination: updatedSoldListingsPagination,
    };
    dispatch(queryListingsSuccess(queryListingsPayload));
  } catch (e) {
    dispatch(queryListingsError(storableError(e)));
  }
  return resultIds;
};

export const showUser = (userId) => (dispatch, getState, sdk) => {
  dispatch(showUserRequest(userId));
  return sdk.users
    .show({
      id: userId,
      include: ['profileImage'],
      'fields.image': ['variants.square-small', 'variants.square-small2x'],
    })
    .then((response) => {
      dispatch(addMarketplaceEntities(response));
      dispatch(showUserSuccess());
      return response;
    })
    .catch((e) => dispatch(showUserError(storableError(e))));
};

export const loadData = (params) => (dispatch) => {
  const userId = new UUID(params.id);

  // Clear state so that previously loaded data is not visible
  // in case this page load fails.
  dispatch(setInitialState());

  return Promise.all([dispatch(fetchCurrentUser()), dispatch(showUser(userId))]);
};
