import { isNil } from 'lodash';
import { FulfillmentMethod } from '../types/shopConfig/shopConfigV2';
import { PayoutOptions } from './constants';
import { PayoutType } from '../types/apollo/generated/types.generated';

export function getLabel(options, key) {
  const config = options.find((c) => c.key === key);
  return config ? config.label : key;
}

// Get short label if its defined, but default to regular label if not
export function getShortLabel(options, key) {
  const config = options.find((c) => c.key === key);
  const shortLabel = config?.shortLabel;
  return shortLabel || getLabel(options, key);
}

export const buildDomainForAuthCookie = () => {
  // Example values for origin are 'http://www.boyish.treet.co'
  // or 'http://boyish.lvh.me:3000'.
  const url = new URL(window.location.origin.replace('www.', ''));

  let shouldRemoveSubdomain;
  if (
    url.hostname.includes('treet.co') ||
    url.hostname.includes('treet-test.co') ||
    url.hostname.includes('treet-staging.co') ||
    url.hostname.includes('lvh.me') // for local dev purposes
  ) {
    shouldRemoveSubdomain = true;
  } else {
    // Otherwise this is a custom url without a subdomain
    // e.g. marketplace.goodfair.com
    shouldRemoveSubdomain = false;
  }

  let cookieDomain;
  if (shouldRemoveSubdomain) {
    const domain = url.hostname
      .split('.')
      .slice(1) // strip out subdomain
      .join('.'); // e.g. 'treet.co'

    // Preprend a '.' to the cookie domain so this cookie can be passed to
    // all treet subdomains. This is necessary because the google auth callback
    // url will not contain the correct subdomain, meaning that the default
    // cookie passing params will not work.
    cookieDomain = `.${domain}`;
  } else {
    cookieDomain = url.hostname;
  }

  return cookieDomain;
};

// taken from https://dev.to/sobiodarlington/better-error-handling-with-async-await-2e5m
/**
 * @description ### Returns Go / Lua like responses(data, err)
 * when used with await
 *
 * - Example response [ data, undefined ]
 * - Example response [ undefined, Error ]
 *
 *
 * When used with Promise.all([req1, req2, req3])
 * - Example response [ [data1, data2, data3], undefined ]
 * - Example response [ undefined, Error ]
 *
 *
 * When used with Promise.race([req1, req2, req3])
 * - Example response [ data, undefined ]
 * - Example response [ undefined, Error ]
 *
 * @param {Promise} promise
 * @returns {Promise} [ data, undefined ]
 * @returns {Promise} [ undefined, Error ]
 */
export const handle = (promise) =>
  promise.then((data) => [data, undefined]).catch((error) => Promise.resolve([undefined, error]));

export const getFulfillmentMethod = (listings, defaultBrandFulfillmentMethod) => {
  let fulfillmentMethod;
  listings.forEach((listing) => {
    const authorFulfillmentMethod =
      listing.author?.attributes.profile.publicData?.fulfillmentMethod;
    if (!isNil(authorFulfillmentMethod)) {
      fulfillmentMethod = authorFulfillmentMethod;
    }
  });

  // Prioritize the listing authors' fulfillmentMethod value, if present, over the defaults.
  const isValidFulfillmentMethod = Object.values(FulfillmentMethod).includes(fulfillmentMethod);
  if (isValidFulfillmentMethod) return fulfillmentMethod;

  const areAllListingsBrandDirect = listings.every(
    (listing) => !!listing.attributes.publicData?.isBrandDirect
  );
  return areAllListingsBrandDirect ? defaultBrandFulfillmentMethod : FulfillmentMethod.Treet;
};

export const getPayoutTypeFromFormPayoutOption = (formPayoutOption) => {
  switch (formPayoutOption) {
    case PayoutOptions.Cash:
      return PayoutType.Cash;

    case PayoutOptions.Credit:
      return PayoutType.Credit;

    default:
      return undefined;
  }
};

export default { getLabel };
