import { defaultClothesConditionsOptions, getConditionsFilterConfig } from '../filters/condition';
import { getDefaultShopConfig } from './default';
import * as custom from '../filters/marketplace-custom-config';
import { getSizeFilterConfigFromOptions } from '../filters/size';

const saylorSizeOptions = [
  { key: 'XS', label: 'XS' },
  { key: 'S', label: 'S' },
  { key: 'M', label: 'M' },
  { key: 'L', label: 'L' },
  { key: 'XL', label: 'XL' },
  { key: 'O/S', label: 'O/S' },
];

export const saylorShopConfig = {
  ...getDefaultShopConfig(),
  filters: [
    ...custom.filters,
    getConditionsFilterConfig({ options: defaultClothesConditionsOptions }),
    getSizeFilterConfigFromOptions({ options: saylorSizeOptions }),
  ],
};
