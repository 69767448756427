import { PageName } from '../types/routes';

const ZENDESK_HIDDEN_PAGES = [PageName.CheckoutPage, PageName.ListingPage];
const ZENDESK_HIDDEN_PAGES_MOBILE = [PageName.EditListingPage];

// Show the chat bot
const show = () => {
  if (typeof window !== 'undefined' && window.zE) {
    window.zE('messenger', 'show');
  }
};

// Hide the chat bot
const hide = () => {
  if (typeof window !== 'undefined' && window.zE) {
    window.zE('messenger', 'hide');
  }
};

// Open the chat bot if Zendesk widget is displayed on the page
export const open = () => {
  if (typeof window !== 'undefined' && window.zE) {
    window.zE('messenger', 'open');
  }
};

// Hide/Display the Zendesk Widget depending on the page
export const showOrHide = (pageName, isModalActive, isMobile) => {
  const shouldHideForAll = ZENDESK_HIDDEN_PAGES.includes(pageName);
  const shouldHideForMobile = ZENDESK_HIDDEN_PAGES_MOBILE.includes(pageName) || isModalActive;
  if (shouldHideForAll || (isMobile && shouldHideForMobile)) {
    hide();
  } else {
    show();
  }
};
