import { MOCK_TRADE_IN_CONFIG } from '../mocks/tradeInData';
import { useShopConfig } from './shopConfig';

export const useTradeInConfig = () => {
  const { tradeInConfig } = useShopConfig(false);
  if (!tradeInConfig) {
    // Used for demos
    return MOCK_TRADE_IN_CONFIG;
  }
  return tradeInConfig || {};
};
