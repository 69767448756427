import Box from '@material-ui/core/Box';
import classNames from 'classnames';
import React, { FC, ReactNode } from 'react';
import { IconBuy, TypographyWrapper } from '..';
import { useShopConfigV2 } from '../../hooks/shopConfig';
import CustomIcon from '../CustomIcon/CustomIcon';

import css from './Empty.module.css';

interface EmptyProps {
  text?: string;
  button?: ReactNode;
  className?: string;
}

const Empty: FC<EmptyProps> = (props: EmptyProps) => {
  const { button, className, text } = props;
  const { images } = useShopConfigV2();
  const customBuyIconInfo = images?.howItWorksCustomImages?.faqImagesCollection?.items?.find(
    (icon) => icon.title === 'BUY'
  );
  const customBuyIconComponent = customBuyIconInfo ? (
    <CustomIcon
      url={customBuyIconInfo.url}
      altText={customBuyIconInfo.title}
      width="100px"
      height="100px"
    />
  ) : null;

  const emptyClassNames = classNames(css.empty, className);

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      p="20px"
      className={emptyClassNames}
    >
      {customBuyIconComponent || <IconBuy />}
      {text && (
        <div className={css.emptyText}>
          <TypographyWrapper variant="body1">{text}</TypographyWrapper>
        </div>
      )}
      {button && <div className={css.emptyButton}>{button}</div>}
    </Box>
  );
};

export default Empty;
