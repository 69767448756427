import { Box } from '@material-ui/core';
import React from 'react';
import { Switches, SwitchProps } from 'mui-rff';
import IconCheckCircleFilled from '../Icons/IconCheckCircleFilled/IconCheckCircleFilled';
import IconCloseV2 from '../Icons/IconCloseV2/IconCloseV2';

// Light design wrapper around the mui-rff `Switches` form field.
// Adds a checkmark for the enabled state and an 'x' for the disabled state.
export const FieldSwitch = (props: SwitchProps) => (
  <Switches
    checkedIcon={<IconCheckCircleFilled height="17" width="17" color="#FFFFFF" />}
    icon={
      <Box
        height="15px"
        width="15px"
        borderRadius="50%"
        bgcolor="#FFFFFF"
        display="flex"
        alignItems="center"
        padding="2px"
      >
        <IconCloseV2 />
      </Box>
    }
    color="default"
    {...props}
  />
);
