import React, { FC } from 'react';
import { useIconColor } from '../../../hooks/useIconColor';

interface IconSmokingProps {
  className?: string;
  color?: string;
}

const IconSmoking: FC<IconSmokingProps> = (props: IconSmokingProps) => {
  const { className, color } = props;

  const { iconColor } = useIconColor();
  const fillColor = color || iconColor;

  return (
    <svg
      className={className}
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 4C9.37258 4 4 9.37258 4 16C4 22.6274 9.37258 28 16 28C22.6274 28 28 22.6274 28 16C28 9.37258 22.6274 4 16 4ZM6 16C6 21.5228 10.4772 26 16 26C21.5228 26 26 21.5228 26 16C26 10.4772 21.5228 6 16 6C10.4772 6 6 10.4772 6 16Z"
        fill={fillColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15 18H7.22302C7.56651 19.5137 8.29155 20.8826 9.29169 22H15V18ZM16 22V18H17V22H16ZM18 22H20V18H18V22Z"
        fill={fillColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 7.5H16.2878C18.9404 7.5 21.3442 9.06216 22.4215 11.4861C22.8029 12.3443 23 13.2731 23 14.2122V19.5H21V14.2122C21 13.5529 20.8616 12.9009 20.5939 12.2984C19.8375 10.5967 18.15 9.5 16.2878 9.5H16V7.5Z"
        fill={fillColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 10.5H16.425C17.7672 10.5 18.9963 11.2518 19.6076 12.4467C19.8655 12.9507 20 13.5088 20 14.075V17H18V14.075C18 13.8256 17.9408 13.5797 17.8271 13.3576C17.5578 12.8312 17.0163 12.5 16.425 12.5H16V10.5Z"
        fill={fillColor}
      />
    </svg>
  );
};

export default IconSmoking;
