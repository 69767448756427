import React from 'react';
import { useIconColor } from '../../../hooks/useIconColor';

interface IconVerticalThreeDotsProps {
  className?: string;
  color?: string;
}
const IconVerticalThreeDots: React.FC<IconVerticalThreeDotsProps> = (props) => {
  const { className, color } = props;

  const { iconColor } = useIconColor();
  const fillColor = color || iconColor;

  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
    >
      <path
        d="M18 10C18 11.1046 17.1046 12 16 12C14.8954 12 14 11.1046 14 10C14 8.89543 14.8954 8 16 8C17.1046 8 18 8.89543 18 10Z"
        fill={fillColor}
      />
      <path
        d="M18 16C18 17.1046 17.1046 18 16 18C14.8954 18 14 17.1046 14 16C14 14.8954 14.8954 14 16 14C17.1046 14 18 14.8954 18 16Z"
        fill={fillColor}
      />
      <path
        d="M16 24C17.1046 24 18 23.1046 18 22C18 20.8954 17.1046 20 16 20C14.8954 20 14 20.8954 14 22C14 23.1046 14.8954 24 16 24Z"
        fill={fillColor}
      />
    </svg>
  );
};

export default IconVerticalThreeDots;
