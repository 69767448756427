import React, { FC, MouseEvent, ReactNode } from 'react';
import classNames from 'classnames';
import TypographyWrapper from '../TypographyWrapper/TypographyWrapper';
import css from './Overlay.module.css';

interface OverlayProps {
  className?: string;
  rootClassName?: string;
  messageClassName?: string;
  message?: string;
  errorMessage?: string;
  shouldAllowClick?: boolean;
  children?: ReactNode;
  fontSize?: string;
}

const Overlay: FC<OverlayProps> = (props: OverlayProps) => {
  const {
    className,
    rootClassName,
    messageClassName,
    message,
    errorMessage,
    shouldAllowClick = false,
    children,
    fontSize,
  } = props;

  const handleClick = (e: MouseEvent) => {
    if (!shouldAllowClick) {
      e.preventDefault();
      e.stopPropagation();
    }
  };

  const classes = classNames(rootClassName || css.root, className);
  const contentClasses = classNames(css.overlayContent, {
    [css.isClickable]: shouldAllowClick,
  });
  const messageClasses = classNames(messageClassName || css.message);

  return (
    <div className={classes} onClick={handleClick}>
      <div className={css.overlay} />
      <div className={contentClasses}>
        {!!errorMessage && <div className={css.errorMessage}>{errorMessage}</div>}
        {!!message && (
          <h3 className={messageClasses}>
            <TypographyWrapper
              variant="body1"
              typographyOverrides={fontSize ? { style: { fontSize } } : undefined}
            >
              <>{message}</>
            </TypographyWrapper>
          </h3>
        )}
        {children}
      </div>
    </div>
  );
};

export default Overlay;
