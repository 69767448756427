import pickBy from 'lodash/pickBy';
import url from 'url';

import { buildNewSearchString, getUrlHash, parse, stringify } from './urlHelpers';

export const MAX_SMALL_SCREEN_WIDTH = 768;
export const MAX_MEDIUM_SCREEN_WIDTH = 1023;
export const MAX_LARGE_SCREEN_WIDTH = 1920;

export const redirectToURLWithModalState = (
  history: any,
  location: any,
  modalStateParam: string
) => {
  const { pathname, search, state } = location;
  const searchString = `?${stringify({ [modalStateParam]: 'open', ...parse(search) })}`;
  const newSearchQuery = buildNewSearchString(location, searchString);

  const urlHash = getUrlHash();

  history.replace(
    url.format({
      pathname,
      search: newSearchQuery,
      hash: urlHash,
    }),
    state
  );
};

export const redirectToURLWithoutModalState = (
  history: any,
  location: any,
  modalStateParam: string
) => {
  const { pathname, search, state } = location;
  const queryParams = pickBy(parse(search), (_, value: string) => value !== modalStateParam);
  const stringified = stringify(queryParams as any);
  const searchString = stringified ? `?${stringified}` : '';

  const urlHash = getUrlHash();

  const newSearchQuery = buildNewSearchString(location, searchString);
  history.replace(
    url.format({
      pathname,
      search: newSearchQuery,
      hash: urlHash,
    }),
    state
  );
};
