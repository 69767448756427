import React, { FC } from 'react';
import { useIconColor } from '../../../hooks/useIconColor';
import { CustomIconType, useCustomIcon } from '../../../hooks/useCustomIcon';
import CustomIcon from '../../CustomIcon/CustomIcon';

interface IconTreeFilledProps {
  className?: string;
  color?: string;
}

const IconTreeFilled: FC<IconTreeFilledProps> = (props: IconTreeFilledProps) => {
  const { className, color } = props;

  const customTreeFilledIcon = useCustomIcon(CustomIconType.TreeFilled);
  const { iconColor } = useIconColor();

  if (customTreeFilledIcon) {
    return <CustomIcon url={customTreeFilledIcon.icon.url} altText="tree" className={className} />;
  }

  const fillColor = color || iconColor;

  return (
    <svg
      className={className}
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23.0084 13.0373L16.5 2L9.99163 13.0373H12.1349L7.70558 20.5037H10.0583L6 27.3209H14.9543V31H18.1523V27.3209H27L22.9417 20.5037H25.2944L20.8651 13.0373H23.0084Z"
        fill={fillColor}
      />
    </svg>
  );
};

export default IconTreeFilled;
