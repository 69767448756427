/* eslint-disable import/prefer-default-export */
import { getSizeFilterConfig } from '../filters/size';
import { getDefaultShopConfig } from './default';
import * as custom from '../filters/marketplace-custom-config';
import { getConditionsFilterConfig } from '../filters/condition';
import { getBrandDirectFilterConfig } from '../filters/brandDirect';

const aceandjigSizeOptions = ['xxs', 'xs', 's', 'm', 'l', 'xl', '1x', '2x', '3x', '4x', 'one size'];

export const aceandjigShopConfig = {
  ...getDefaultShopConfig(),
  filters: [
    ...custom.filters,
    getConditionsFilterConfig(),
    getBrandDirectFilterConfig(),
    getSizeFilterConfig({ sizes: aceandjigSizeOptions }),
  ],
};
