import { getConditionsFilterConfig } from '../filters/condition';
import { getSizeFilterConfig } from '../filters/size';
import { getDefaultShopConfig } from './default';
import * as custom from '../filters/marketplace-custom-config';
import { getBrandDirectFilterConfig } from '../filters/brandDirect';
import { BuilderSections } from '../../util/builder';
import { getGenderFilterConfig } from '../filters/gender';

const hudsonjeansSizes = [
  '23',
  '24',
  '25',
  '26',
  '27',
  '28',
  '29',
  '30',
  '31',
  '32',
  '33',
  '34',
  '36',
  '38',
  '40',
  '42',
  'XS',
  'S',
  'M',
  'L',
  'XL',
];

export const hudsonjeansShopConfig = {
  ...getDefaultShopConfig(),
  filters: [
    ...custom.filters,
    getGenderFilterConfig(),
    getConditionsFilterConfig(),
    getBrandDirectFilterConfig(),
    getSizeFilterConfig({
      sizes: hudsonjeansSizes,
    }),
  ],
  builderConfig: {
    sections: {
      [BuilderSections.LandingPageContent]: 'feba9328a5894aa695330351b1ec4e27',
    },
  },
};
