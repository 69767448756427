import { setInitialValues as CheckoutPageInitialValues } from './CheckoutPage/CheckoutPage.duck';
import { loadData as ContactDetailsPageLoader } from './ContactDetailsPage/ContactDetailsPage.duck';
import { loadData as EditListingPageLoader } from './EditListingPage/EditListingPage.duck';
import { loadData as EmailVerificationPageLoader } from './EmailVerificationPage/EmailVerificationPage.duck';
import { loadData as FavoritesPageLoader } from './FavoritesPage/FavoritesPage.duck';
import { loadData as ListingPageLoader } from './ListingPage/ListingPage.duck';
import { loadData as ManageListingsPageLoader } from './ManageListingsPage/ManageListingsPage.duck';
import { loadData as ManagePurchasesPageLoader } from './ManagePurchasesPage/ManagePurchasesPage.duck';
import { loadData as ManageSalesPageLoader } from './ManageSalesPage/ManageSalesPage.duck';
import {
  setInitialValues as OrderSuccessPageInitialValues,
  loadData as OrderSuccessPageLoader,
} from './OrderSuccessPage/OrderSuccessPage.duck';
import { loadData as PaymentMethodsPageLoader } from './PaymentMethodsPage/PaymentMethodsPage.duck';
import { loadData as ProfilePageLoader } from './ProfilePage/ProfilePage.duck';
import { loadData as StripePayoutPageLoader } from './StripePayoutPage/StripePayoutPage.duck';
import { loadData as TreetShopLandingPageLoader } from './TreetShopLandingPage/TreetShopLandingPage.duck';
import { loadData as ManageTradeInsPageLoader } from './ManageTradeInsPage/ManageTradeInsPage.duck';
import { loadData as ManageClosetPageLoader } from './ManageClosetPage/ManageClosetPage.duck';

/**
 * Export calls from ducks modules of different containers
 */
const getPageDataLoadingAPI = () => ({
  CheckoutPage: {
    setInitialValues: CheckoutPageInitialValues,
  },
  ContactDetailsPage: {
    loadData: ContactDetailsPageLoader,
  },
  EditListingPage: {
    loadData: EditListingPageLoader,
  },
  EmailVerificationPage: {
    loadData: EmailVerificationPageLoader,
  },
  FavoritesPage: {
    loadData: FavoritesPageLoader,
  },
  ListingPage: {
    loadData: ListingPageLoader,
  },
  ManageListingsPage: {
    loadData: ManageListingsPageLoader,
  },
  ManageClosetPage: {
    loadData: ManageClosetPageLoader,
  },
  ManagePurchasesPage: {
    loadData: ManagePurchasesPageLoader,
  },
  ManageSalesPage: {
    loadData: ManageSalesPageLoader,
  },
  PaymentMethodsPage: {
    loadData: PaymentMethodsPageLoader,
  },
  ProfilePage: {
    loadData: ProfilePageLoader,
  },
  OrderSuccessPage: {
    loadData: OrderSuccessPageLoader,
    setInitialValues: OrderSuccessPageInitialValues,
  },
  StripePayoutPage: {
    loadData: StripePayoutPageLoader,
  },
  TreetShopLandingPage: {
    loadData: TreetShopLandingPageLoader,
  },
  ManageTradeInsPage: {
    loadData: ManageTradeInsPageLoader,
  },
});

export default getPageDataLoadingAPI;
