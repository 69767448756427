import { Conditions, getConditionsFilterConfig } from '../filters/condition';
import { getSizeFilterConfig } from '../filters/size';
import { getDefaultShopConfig, getDefaultTradeInShopConfig } from './default';
import * as custom from '../filters/marketplace-custom-config';

const moskomotoSizes = [
  'XS',
  'S',
  'M',
  'L',
  'XL',
  'XXL',
  'S-M',
  'L-XL',
  '30',
  '32',
  '32T',
  '34',
  '34T',
  '36',
  '36T',
  '38',
  '40',
];

const moskomotoConditionsOptions = [
  {
    key: Conditions.NewWithoutTags,
    label: 'New/Like New',
    description:
      'Your items have never or rarely been used, and include all the original items that were initially included.',
  },
  {
    key: Conditions.Good,
    label: 'Good',
    description:
      'Your item is used but well-maintained and fully functional. There may be some signs of wear like minor scratches or stains, but it’s generally in good condition.',
  },
  {
    key: Conditions.MinorDefect,
    label: 'Well-loved',
    description:
      'Your item has been used on many journeys but still may have another life to live.',
  },
];

export const moskomotoShopConfig = {
  ...getDefaultShopConfig(),
  ...getDefaultTradeInShopConfig(),
  filters: [
    ...custom.filters,
    getConditionsFilterConfig({
      options: moskomotoConditionsOptions,
    }),
    getSizeFilterConfig({
      sizes: moskomotoSizes,
    }),
  ],
};
