import { isNil } from 'lodash';
import { FilterQueryParam } from '../types/search/search';
import { DEFAULT_SOLD_LISTINGS_PAGE_SIZE } from './ducks/soldListings';

export const SEARCH_FILTERS_MODAL_BREAKPOINT = 768; // Search is in modal on mobile layout

/**
 * SelectMultipleFilter needs to parse values from format
 * "has_all:a,b,c,d" or "a,b,c,d"
 */
export const parseSelectFilterOptions = (uriComponentValue) => {
  if (!uriComponentValue) return null;

  const startsWithHasAll = uriComponentValue.indexOf('has_all:') === 0;
  const startsWithHasAny = uriComponentValue.indexOf('has_any:') === 0;

  if (startsWithHasAll) {
    return uriComponentValue.substring(8).split(',');
  }
  if (startsWithHasAny) {
    return uriComponentValue.substring(8).split(',');
  }
  return uriComponentValue.split(',');
};

/**
 * Check if any of the filters (defined by filterIds) have currently active query parameter in URL.
 */
export const isAnyFilterActive = (filterIds, urlQueryParams, filterConfigs) => {
  const getQueryParamKeysOfGivenFilters = (keys, config) => {
    const isFilterIncluded = filterIds.includes(config.id);
    const addedQueryParamNamesMaybe = isFilterIncluded ? config.queryParamNames : [];
    return [...keys, ...addedQueryParamNamesMaybe];
  };
  const queryParamKeysOfGivenFilters = filterConfigs.reduce(getQueryParamKeysOfGivenFilters, []);

  const paramEntries = Object.entries(urlQueryParams);
  const activeKey = paramEntries.find((entry) => {
    const [key, value] = entry;
    return queryParamKeysOfGivenFilters.includes(key) && value != null;
  });
  return !!activeKey;
};

/**
 * Check if the filter is currently active.
 */
export const findOptionsForSelectFilter = (filterId, filters) => {
  const filter = filters.find((f) => f.id === filterId);
  return filter && filter.config && filter.config.options ? filter.config.options : [];
};

/**
 * Clean search params.
 */
export const cleanSearchFromConflictingParams = (searchParams, sortConfig, filterConfig) => {
  // Single out filters that should disable SortBy when an active
  // keyword search sorts the listings according to relevance.
  // In those cases, sort parameter should be removed.
  const sortingFiltersActive = isAnyFilterActive(
    sortConfig.conflictingFilters,
    searchParams,
    filterConfig
  );
  return sortingFiltersActive
    ? { ...searchParams, [sortConfig.queryParamName]: null }
    : searchParams;
};

/**
 * Return function to get initial search values.
 * @param {object} currentQueryParams Query parameters that are visible in the URL
 * @param {object} urlQueryParams Query parameters that are in state (user might have not yet clicked "Apply")
 */
export const getSearchInitialValuesFn =
  (currentQueryParams, urlQueryParams) => (queryParamNames) => {
    // Get initial value for a given parameter from state if its there.
    const getInitialValue = (paramName) => {
      const currentQueryParam = currentQueryParams[paramName];
      const hasQueryParamInState = typeof currentQueryParam !== 'undefined';
      return hasQueryParamInState ? currentQueryParam : urlQueryParams[paramName];
    };

    // Return all the initial values related to given queryParamNames
    // InitialValues for "amenities" filter could be
    // { amenities: "has_any:towel,jacuzzi" }
    const isArray = Array.isArray(queryParamNames);
    return isArray
      ? queryParamNames.reduce(
          (acc, paramName) => ({ ...acc, [paramName]: getInitialValue(paramName) }),
          {}
        )
      : {};
  };

export const getShouldFetchSoldListings = (searchParams) => {
  if (!searchParams) return true;

  let shouldFetchSoldListings = true;
  Object.keys(FilterQueryParam).forEach((param) => {
    // Don't show sold listings if there are enabled sorts, filters, or search queries
    if (!isNil(searchParams[param])) shouldFetchSoldListings = false;
  });

  return shouldFetchSoldListings;
};

export const shouldShowListItemCard = (
  isFirstPage,
  isSinglePage,
  allowListMarketplaceItem,
  searchParams,
  numListings
) => {
  const shouldFetchSoldListings = getShouldFetchSoldListings(searchParams);
  const doesAddingAnotherListingCardFillPage =
    (numListings + 1) % DEFAULT_SOLD_LISTINGS_PAGE_SIZE === 0;

  return (
    allowListMarketplaceItem &&
    shouldFetchSoldListings &&
    (isSinglePage || (isFirstPage && doesAddingAnotherListingCardFillPage))
  );
};
