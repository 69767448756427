import { getDefaultShopConfig } from './default';
import * as custom from '../filters/marketplace-custom-config';
import { getBrandDirectFilterConfig } from '../filters/brandDirect';
import { getConditionsFilterConfig } from '../filters/condition';
import { getSizeFilterConfig } from '../filters/size';
import { BuilderSections } from '../../util/builder';

const superxSizes = ['XS', 'S', 'M', 'L', 'XL', 'XXL', '2XL', '3XL'];

export const superxShopConfig = {
  ...getDefaultShopConfig(),
  filters: [
    ...custom.filters,
    getConditionsFilterConfig(),
    getBrandDirectFilterConfig(),
    getSizeFilterConfig({ sizes: superxSizes }),
  ],
  builderConfig: {
    sections: {
      [BuilderSections.LandingPageContent]: 'f81f9f80caea4e3f8d30be45f45afb04',
    },
  },
};
