/* eslint-disable import/prefer-default-export */
import { useMemo } from 'react';
import { useShopConfig } from './shopConfig';

export const useIconColor = () => {
  const { styles, css: shopCss } = useShopConfig();

  return useMemo(
    () => ({
      iconColor: shopCss?.icon?.color || shopCss?.subtitle1?.color || styles.matterColor,
      iconHoverColor:
        shopCss?.icon?.hoverColor ||
        shopCss?.icon?.color ||
        shopCss?.subtitle1?.color ||
        styles.matterColorDark,
    }),
    [shopCss, styles]
  );
};
