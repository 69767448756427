import classNames from 'classnames';
import React, { FC, ReactNode } from 'react';
import { Field, useField, useFormState } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import { Box } from '@material-ui/core';
import { IconNotify, ToggleBanner, TypographyWrapper } from '..';
import { findOptionsForSelectFilter } from '../../util/search';
import { FilterId } from '../../types/filters/filters';
import { useShopConfig } from '../../hooks/shopConfig';
import { TypographyWeight } from '../TypographyWrapper/TypographyWrapper';
import * as validators from '../../util/validators';
import css from './SizeNotifications.module.css';

export enum SizeNotificationsFormValue {
  ShouldNotify = 'shouldNotify',
  Sizes = 'sizes',
}

interface SizeProps {
  label: string;
  name: string;
  value: string;
  formId: string;
  shouldAlwaysShowSizes: boolean;
}

const Size: FC<SizeProps> = (props) => {
  const { label, name, value, formId, shouldAlwaysShowSizes } = props;
  const id = `${formId}-size-${label}`;

  const sizesField = useField(name);
  const shouldNotifyField = useField(SizeNotificationsFormValue.ShouldNotify);

  const isSelected = sizesField.input.value?.includes(value);
  const shouldDisable = !shouldAlwaysShowSizes && !shouldNotifyField.input.value;

  return (
    <>
      <Field
        name={name}
        component="input"
        type="checkbox"
        id={id}
        value={value}
        disabled={shouldDisable}
        className={css.hidden}
        validate={validators.requiredFieldArrayCheckbox('Required')}
      />
      <label htmlFor={id} className={css.label}>
        <Box
          className={classNames(css.sizeBox, {
            [css.sizeBoxSelected]: isSelected,
            [css.sizeBoxDisabled]: shouldDisable,
          })}
        >
          <TypographyWrapper
            variant="body1"
            typographyOverrides={isSelected ? { style: { color: 'white' } } : undefined}
          >
            {label}
          </TypographyWrapper>
        </Box>
      </label>
    </>
  );
};

const SizesRenderer: FC<any> = (props) => {
  const { options, fields, className, formId, shouldAlwaysShowSizes } = props;

  return (
    <fieldset className={classNames(css.sizes, className)}>
      {options.map((option: { key: string; label: string }) => (
        <Size
          key={option.key}
          label={option.label}
          value={option.key}
          name={fields.name}
          formId={formId}
          shouldAlwaysShowSizes={shouldAlwaysShowSizes}
        />
      ))}
    </fieldset>
  );
};

interface SizesProps {
  name: string;
  options: { key: string; label: string }[];
  className?: string;
  formId: string;
  shouldAlwaysShowSizes?: boolean;
}

const Sizes: FC<SizesProps> = (props) => <FieldArray component={SizesRenderer} {...props} />;

const NotifyToggle: FC<{ formId: string; notifyMeBodyContent: ReactNode }> = (props) => {
  const { formId, notifyMeBodyContent } = props;

  const body = notifyMeBodyContent || (
    <>
      <TypographyWrapper variant="body1" weight={TypographyWeight.Bold}>
        Notify me
      </TypographyWrapper>
      <TypographyWrapper variant="body1" typographyOverrides={{ style: { fontSize: '12px' } }}>
        Receive updates on new and discounted items
      </TypographyWrapper>
    </>
  );

  return (
    <ToggleBanner formId={formId} name={SizeNotificationsFormValue.ShouldNotify}>
      {body}
    </ToggleBanner>
  );
};

interface SizeNotificationsProps {
  formId: string;
  actions?: ReactNode;
  className?: string;
  showToggle?: boolean;
  notifyMeBodyContent?: ReactNode;
  shouldAlwaysShowSizes?: boolean;
}

const SizeNotifications: FC<SizeNotificationsProps> = (props: SizeNotificationsProps) => {
  const {
    className,
    actions = null,
    formId,
    shouldAlwaysShowSizes,
    notifyMeBodyContent,
    showToggle,
  } = props;

  const { filters } = useShopConfig();
  const { values } = useFormState();

  const sizeOptions: { key: string; label: string }[] = findOptionsForSelectFilter(
    FilterId.Size,
    filters
  );

  const hideSizes =
    !shouldAlwaysShowSizes &&
    (sizeOptions.length === 0 ||
      (!values[SizeNotificationsFormValue.ShouldNotify] &&
        values[SizeNotificationsFormValue.Sizes].length === 0));

  return (
    <>
      <Box className={classNames(css.content, className)}>
        <Sizes
          name={SizeNotificationsFormValue.Sizes}
          options={sizeOptions}
          className={classNames({ [css.hidden]: hideSizes })}
          formId={formId}
          shouldAlwaysShowSizes={shouldAlwaysShowSizes}
        />
        <Box
          display="flex"
          justifyContent="center"
          className={classNames({ [css.hidden]: !hideSizes })}
        >
          <IconNotify
            className={classNames(css.iconNotify, {
              [css.iconNotifyDisabled]: !values[SizeNotificationsFormValue.ShouldNotify],
            })}
          />
        </Box>
        {showToggle && (
          <Box py={2}>
            <NotifyToggle formId={formId} notifyMeBodyContent={notifyMeBodyContent} />
          </Box>
        )}
      </Box>
      {actions}
    </>
  );
};

export default SizeNotifications;
