import { getBrandDirectFilterConfig } from '../filters/brandDirect';
import { getConditionsFilterConfig } from '../filters/condition';
import { getSizeFilterConfig } from '../filters/size';
import { getDefaultShopConfig } from './default';
import * as custom from '../filters/marketplace-custom-config';
import { BuilderSections } from '../../util/builder';

const unifSizes = [
  'XS',
  'XS/S',
  'S',
  'M',
  'L',
  'M/L',
  'XL',
  'XXL',
  '5',
  '6',
  '7',
  '8',
  '9',
  '10',
  '11',
  '12',
  '13',
  '23',
  '24',
  '25',
  '26',
  '27',
  '28',
  '29',
  '30',
  '31',
  '32',
  '34',
  '36',
  '38',
  'One Size',
];

export const unifShopConfig = {
  ...getDefaultShopConfig(),
  filters: [
    ...custom.filters,
    getConditionsFilterConfig(),
    getSizeFilterConfig({
      sizes: unifSizes,
    }),
    getBrandDirectFilterConfig(),
  ],
  builderConfig: {
    sections: {
      [BuilderSections.LandingPageContent]: 'b1002ee72ff4471eb3f5826db42df921',
    },
  },
};
