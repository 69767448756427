import { getConditionsFilterConfig } from '../filters/condition';
import { getSizeFilterConfig } from '../filters/size';
import { getDefaultShopConfig } from './default';
import * as custom from '../filters/marketplace-custom-config';
import { getTagsFilterConfig } from '../filters/styles';
import { FilterId } from '../../types/filters/filters';

export const cutsCuts = [
  {
    key: 'split',
    label: 'Split',
    tags: ['cut::split'],
  },
  {
    key: 'classic',
    label: 'Classic',
    tags: ['cut::curve'],
  },
  {
    key: 'elongated',
    label: 'Elongated',
    tags: ['cut::elongated'],
  },
];

export const cutsCollars = [
  {
    key: 'hooded',
    label: 'Hooded',
    tags: ['collar::hooded'],
  },
  {
    key: 'crew',
    label: 'Crew',
    tags: ['collar:crew'],
  },
  {
    key: 'hoodie',
    label: 'Hoodie',
    tags: ['collar::hoodie'],
  },
  {
    key: 'polo',
    label: 'Polo',
    tags: ['collar::polo'],
  },
  {
    key: 'vneck',
    label: 'V-Neck',
    tags: ['collar::v-neck'],
  },
  {
    key: 'henley',
    label: 'Henley',
    tags: ['collar::henley'],
  },
];

export const cutsShopConfig = {
  ...getDefaultShopConfig(),
  filters: [
    ...custom.filters,
    getTagsFilterConfig({
      id: FilterId.Style,
      options: [...cutsCuts, ...cutsCollars],
      shouldUseTreetOptions: true,
    }),
    getConditionsFilterConfig(),
    getSizeFilterConfig({
      sizes: ['S', 'M', 'L', 'XL', 'XXL'],
    }),
  ],
};
