import { getDefaultShopConfig } from './default';
import * as custom from '../filters/marketplace-custom-config';
import { getBrandDirectFilterConfig } from '../filters/brandDirect';
import { getConditionsFilterConfig } from '../filters/condition';
import { getSizeFilterConfig } from '../filters/size';

const nuiorganicsSizes = [
  'XS',
  'S',
  'M',
  'L',
  'XL',
  'XXL',
  '0-3m',
  '3-6m',
  '6-12m',
  '12-18m',
  '18-24m',
  '2y',
  '3y',
  '4y',
  '5y',
  '6y',
  '8y',
  '10y',
  '12y',
  'One Size',
];

export const nuiorganicsShopConfig = {
  ...getDefaultShopConfig(),
  filters: [
    ...custom.filters,
    getConditionsFilterConfig(),
    getBrandDirectFilterConfig(),
    getSizeFilterConfig({ sizes: nuiorganicsSizes }),
  ],
};
