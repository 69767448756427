import { Box, Typography } from '@material-ui/core';
import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Divider, InlineTextButton } from '..';
import { ModalState, ModalType, resetActiveModal } from '../../ducks/modal.duck';
import { SubscribeModalState, subscribeSizes } from '../../ducks/subscribeModal.duck';
import { SizeNotificationsForm } from '../../forms';
import { SizeNotificationsFormValues } from '../../forms/SizeNotificationsForm/SizeNotificationsForm';
import { useShopConfig } from '../../hooks/shopConfig';
import { useIsMobile } from '../../hooks/useIsMobile';
import { Cadence, EmailSubscribeSource } from '../../types/apollo/generated/types.generated';
import { RequestStatus } from '../../types/requestStatus';
import { RELEASES_AND_DISCOUNTS_GROUP_ID } from '../../util/sendgrid';
import { ButtonVariant } from '../Button/Button';
import css from './SubscribeModal.module.css';

const ONE_SECOND = 1000; // in ms

const HeaderBar: FC = () => {
  const isMobile = useIsMobile();
  const text = isMobile ? 'Choose Your Sizes' : 'What sizes should we update you on?';

  return (
    <Box
      display="flex"
      flexDirection="row"
      alignItems="center"
      justifyContent="center"
      height="60px"
    >
      <Typography variant="h2">{text}</Typography>
    </Box>
  );
};

const ActionsBar: FC = () => {
  const isMobile = useIsMobile();
  const dispatch = useDispatch();

  const { subscribeSizesStatus } = useSelector<any>(
    (state) => state.subscribeModal
  ) as SubscribeModalState;
  const { activeModal } = useSelector<any>((state) => state.modal) as ModalState;

  const handleAddLater = () => {
    if (activeModal === ModalType.Subscribe) {
      dispatch(resetActiveModal());
    }
  };

  const isSubmitting = subscribeSizesStatus === RequestStatus.Pending;
  const isSuccess = subscribeSizesStatus === RequestStatus.Success;
  const laterText = isMobile ? 'Add later' : 'I’ll add my sizes later';

  return (
    <>
      <Divider className={css.divider} />
      <Box display="flex" alignItems="center" justifyContent="space-between" p={2}>
        <Box mx={1}>
          <InlineTextButton onClick={handleAddLater}>{laterText}</InlineTextButton>
        </Box>
        <Box>
          <Button
            type="submit"
            variant={ButtonVariant.Primary}
            inProgress={isSubmitting}
            disabled={isSubmitting}
            ready={isSuccess}
          >
            Submit
          </Button>
        </Box>
      </Box>
    </>
  );
};

const SubscribeSizes: FC = () => {
  const dispatch = useDispatch();
  const { css: brandCss } = useShopConfig();

  const { email } = useSelector<any>((state) => state.subscribeModal) as SubscribeModalState;
  const { activeModal } = useSelector<any>((state) => state.modal) as ModalState;

  const handleModalSubmit = async (values: SizeNotificationsFormValues) => {
    const { sizes } = values;

    if (!email) return;

    await dispatch(
      subscribeSizes({
        email,
        sizes,
        groupId: RELEASES_AND_DISCOUNTS_GROUP_ID,
        subscribeSource: EmailSubscribeSource.LandingPageModal,
        cadence: Cadence.Weekly,
      })
    );
    setTimeout(() => {
      if (activeModal === ModalType.Subscribe) {
        dispatch(resetActiveModal());
      }
    }, ONE_SECOND);
  };

  return (
    <Box className={css.subscribeSizesBackground}>
      <Box bgcolor={brandCss?.backgroundColor || 'white'} className={css.subscribeSizesContent}>
        <Box bgcolor="inherit">
          <HeaderBar />
          <Divider className={css.divider} />
        </Box>
        <SizeNotificationsForm
          actions={<ActionsBar />}
          initialValues={{ sizes: [] }}
          onSubmit={handleModalSubmit}
          className={css.sizeNotificationsWrapper}
        />
      </Box>
    </Box>
  );
};

export default SubscribeSizes;
