import { getDefaultShopConfig } from './default';
import * as custom from '../filters/marketplace-custom-config';
import { getBrandDirectFilterConfig } from '../filters/brandDirect';
import { Conditions, getConditionsFilterConfig } from '../filters/condition';

const theandibrandConditionsOptions = [
  {
    key: Conditions.NewWithoutTags,
    label: 'New',
    description:
      'Your item has never been worn (other than perhaps trying it on). There are no quirks or issues whatsoever.',
  },
  {
    key: Conditions.Excellent,
    label: 'Excellent',
    description:
      'Your item has only been lightly used and has been extremely well maintained. There may be some small signs of wear, but nothing major.',
  },
  {
    key: Conditions.Good,
    label: 'Good',
    description:
      'You’ve worn this item and it’s well maintained. There may be some quirks like a small stain, but it’s overall in good shape. You will need to take pictures of any quirks the item has.',
  },
];

export const theandibrandShopConfig = {
  ...getDefaultShopConfig(),
  filters: [
    ...custom.filters,
    getConditionsFilterConfig({
      options: theandibrandConditionsOptions,
    }),
    getBrandDirectFilterConfig(),
  ],
};
