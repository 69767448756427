import React, { FC } from 'react';
import { useIconColor } from '../../../hooks/useIconColor';

interface IconPurchaseProtectionProps {
  className?: string;
  color?: string;
}

const IconPurchaseProtection: FC<IconPurchaseProtectionProps> = (
  props: IconPurchaseProtectionProps
) => {
  const { className, color } = props;

  const { iconColor } = useIconColor();
  const fillColor = color || iconColor;

  return (
    <svg
      className={className}
      width="32"
      height="33"
      viewBox="0 0 32 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28 10.0838C27.3875 10.3514 26.7111 10.4999 26 10.4999C23.2386 10.4999 21 8.2613 21 5.49988C21 4.62716 21.2236 3.80666 21.6166 3.09254C17.323 2.21338 12.874 2.35283 8.62964 3.51089L6.94709 3.96997C5.20706 4.44474 4 6.02527 4 7.82891V13.0974C4 17.8951 6.15293 22.4396 9.86548 25.4785L13.4664 28.426C14.9401 29.6323 17.0599 29.6323 18.5336 28.426L22.1345 25.4785C25.8471 22.4396 28 17.8951 28 13.0974V10.0838ZM16 15.4999H25.7924C25.2204 18.7839 23.488 21.786 20.8677 23.9308L17.2668 26.8784C16.8984 27.1799 16.4492 27.3307 16 27.3307V15.4999ZM16 4.52344V15.4999H6.20756C6.07044 14.7126 6 13.9091 6 13.0974V7.82891C6 6.92709 6.60353 6.13682 7.47355 5.89944L9.15609 5.44036C11.3964 4.82908 13.6982 4.52344 16 4.52344Z"
        fill={fillColor}
      />
      <rect x="25" y="2.5" width="2" height="6" rx="1" fill={fillColor} />
      <rect
        x="29"
        y="4.5"
        width="2"
        height="6"
        rx="1"
        transform="rotate(90 29 4.5)"
        fill={fillColor}
      />
    </svg>
  );
};

export default IconPurchaseProtection;
