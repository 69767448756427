import { Box } from '@material-ui/core';
import React from 'react';
import { bool, func, node, object, string } from 'prop-types';
import classNames from 'classnames';
import { Field } from 'react-final-form';
import TypographyWrapper from '../TypographyWrapper/TypographyWrapper';
import { useShopConfig } from '../../hooks/shopConfig';
import { defaultTreetStyles } from '../../shopConfig/config';

import css from './FieldCheckbox.module.css';

const IconCheckbox = (props) => {
  const { className, boxClassName } = props;

  const { css: brandCss } = useShopConfig();

  return (
    <svg className={className} width="14" height="14" xmlns="http://www.w3.org/2000/svg">
      <rect className={boxClassName || css.box} width="14" height="14" />

      <g fill="none" fillRule="evenodd">
        <path
          d="M5.636621 10.7824771L3.3573694 8.6447948c-.4764924-.4739011-.4764924-1.2418639 0-1.7181952.4777142-.473901 1.251098-.473901 1.7288122 0l1.260291 1.1254782 2.8256927-4.5462307c.3934117-.5431636 1.1545778-.6695372 1.7055985-.278265.5473554.3912721.6731983 1.150729.2797866 1.6951077l-3.6650524 5.709111c-.2199195.306213-.5803433.5067097-.9920816.5067097-.3225487 0-.6328797-.1263736-.8637952-.3560334z"
          fill={brandCss?.backgroundColor || '#FFF'}
        />
      </g>
    </svg>
  );
};

IconCheckbox.defaultProps = { className: null, boxClassName: null };

IconCheckbox.propTypes = { className: string, boxClassName: string };

const FieldCheckboxComponent = (props) => {
  const {
    rootClassName,
    className,
    wrapperClassName,
    svgClassName,
    textClassName,
    id,
    label,
    useSuccessColor,
    typographyOverrides,
    description,
    subLabel,
    ...rest
  } = props;

  const classes = classNames(rootClassName || css.root, className);
  const checkboxProps = {
    id,
    className: css.input,
    component: 'input',
    type: 'checkbox',
    ...rest,
  };

  const successColorVariantMaybe = useSuccessColor
    ? {
        boxClassName: css.boxSuccess,
      }
    : {};

  return (
    <span className={classes}>
      <Field {...checkboxProps} />
      <label htmlFor={id} className={css.label}>
        <Box display="flex" alignItems="center" my={subLabel ? 0 : 1}>
          <span className={wrapperClassName || css.checkboxWrapper}>
            <IconCheckbox className={svgClassName} {...successColorVariantMaybe} />
          </span>
          <Box>
            <span className={classNames(css.text, textClassName || css.textRoot)}>
              <Box display="flex" flexDirection="row" alignItems="center">
                <TypographyWrapper
                  variant="body1"
                  typographyOverrides={{
                    style: {
                      lineHeight: 1,
                    },
                    ...(typographyOverrides && { typographyOverrides }),
                  }}
                >
                  {label}
                </TypographyWrapper>
                <TypographyWrapper
                  variant="body1"
                  typographyOverrides={{
                    style: {
                      color: defaultTreetStyles.gray60,
                      margin: '0 0 0 7px',
                      lineHeight: 1,
                    },
                    ...(typographyOverrides && { typographyOverrides }),
                  }}
                >
                  {description}
                </TypographyWrapper>
              </Box>
            </span>
          </Box>
        </Box>
      </label>
      {subLabel && (
        <Box ml="27px">
          <TypographyWrapper
            typographyOverrides={{
              style: {
                color: defaultTreetStyles.gray60,
                margin: 0,
                padding: 0,
                fontSize: '12px',
              },
            }}
            variant="body2"
          >
            {subLabel}
          </TypographyWrapper>
        </Box>
      )}
    </span>
  );
};

FieldCheckboxComponent.defaultProps = {
  className: null,
  rootClassName: null,
  svgClassName: null,
  textClassName: null,
  label: null,
  subLabel: null,
  value: undefined,
  validate: undefined,
  useSuccessColor: null,
  description: undefined,
  disabled: undefined,
  typographyOverrides: undefined,
};

FieldCheckboxComponent.propTypes = {
  className: string,
  rootClassName: string,
  svgClassName: string,
  textClassName: string,
  useSuccessColor: bool,
  description: string,
  disabled: bool,
  typographyOverrides: object,
  subLabel: node,

  // Id is needed to connect the label with input.
  id: string.isRequired,
  label: node,

  // Name groups several checkboxes to an array of selected values
  name: string.isRequired,

  // Checkbox needs a value that is passed forward when user checks the checkbox
  value: string,

  // Field props
  validate: func,
  ...Field.propTypes,
};

export default FieldCheckboxComponent;
