import { apolloClient } from '../apollo';
import {
  CreateFavoritedItemDocument,
  FavoritedItemsDocument,
  RemoveFavoritedItemDocument,
} from '../types/apollo/generated/types.generated';
import { handle } from './helpers';
import * as log from './log';

export const getFavoritedListingsFromPG = async (
  currentUser: { id: { uuid: string } },
  treetId: string
): Promise<string[]> => {
  if (!currentUser || !treetId) return [];

  let formattedFavoritedItems = [];
  const favoritedItemsPromise = apolloClient.query({
    query: FavoritedItemsDocument,
    variables: {
      treetId,
    },
    fetchPolicy: 'network-only',
  });

  const [pgFavoritedItems, pgFavoritedItemsError] = await handle(favoritedItemsPromise);

  if (pgFavoritedItemsError) {
    log.error(
      new Error(`Error attempting to query PG favorites: ${pgFavoritedItemsError}`),
      'query-pg-favorite-items-failed',
      { sharetribeUserId: currentUser.id.uuid }
    );
  } else {
    formattedFavoritedItems = pgFavoritedItems?.data.favoritedItems.map(
      (favoritedItem: { listing: { sharetribeListingId: 'String' } }) =>
        favoritedItem.listing.sharetribeListingId
    );
  }
  return formattedFavoritedItems;
};

export const setFavoriteListingInPG = (favoritedItemParams: any) =>
  apolloClient.mutate({
    mutation: CreateFavoritedItemDocument,
    variables: {
      input: favoritedItemParams,
    },
  });

export const removeFavoriteListingInPG = (favoritedItemParams: any) =>
  apolloClient.mutate({
    mutation: RemoveFavoritedItemDocument,
    variables: {
      input: favoritedItemParams,
    },
  });
