import { getConditionsFilterConfig } from '../filters/condition';
import { getBrandDirectFilterConfig } from '../filters/brandDirect';
import { getDefaultShopConfig } from './default';
import { getSizeFilterConfigFromOptions } from '../filters/size';
import * as custom from '../filters/marketplace-custom-config';

const exoticathleticaSizeOptions = [
  { key: 'XS (AUS 4)', label: 'XS (AUS 4)' },
  { key: 'S  (AUS 6-8)', label: 'S (AUS 6-8)' },
  { key: 'M  (AUS 10-12)', label: 'M (AUS 10-12)' },
  { key: 'L  (AUS 14-16)', label: 'L (AUS 14-16)' },
  { key: 'XL (AUS 18-20)', label: 'XL (AUS 18-20)' },
  { key: 'XXL (AUS 20-26)', label: 'XXL (AUS 20-26)' },
  { key: '2  (Kids)', label: '2 (Kids)' },
  { key: '4  (Kids)', label: '4 (Kids)' },
  { key: '6  (Kids)', label: '6 (Kids)' },
  { key: '8  (Kids)', label: '8 (Kids)' },
  { key: '10 (Kids)', label: '10 (Kids)' },
  { key: '12 (Kids)', label: '12 (Kids)' },
];

export const exoticathleticaShopConfig = {
  ...getDefaultShopConfig(),
  filters: [
    ...custom.filters,
    getConditionsFilterConfig(),
    getBrandDirectFilterConfig(),
    getSizeFilterConfigFromOptions({
      options: exoticathleticaSizeOptions,
    }),
  ],
};
