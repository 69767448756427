import classNames from 'classnames';
import React, { FC, useState } from 'react';
import {
  ConditionalWrapper,
  IconSpinner,
  IconSubtractFilled,
  ListingImage,
  NamedLink,
  Overlay,
} from '..';
import { ListingWithImages } from '../../types/sharetribe/listing';
import { createSlug } from '../../util/urlHelpers';
import css from './ListingCardPreview.module.css';

interface ListingProps {
  listing: ListingWithImages;
  allowListingRedirect?: boolean;
  isEditing?: boolean;
  onRemoveClick?: () => void;
  onClick?: () => void;
  listingImageClassName?: string;
}

export const ListingCardSmall: FC<ListingProps> = (props: ListingProps) => {
  const {
    listing,
    allowListingRedirect = true,
    isEditing = false,
    onRemoveClick = () => {},
    onClick = () => {},
    listingImageClassName,
  } = props;

  const [isRemovingListing, setIsRemovingListing] = useState<boolean>(false);

  const handleRemoveClick = async () => {
    if (!onRemoveClick) return;
    setIsRemovingListing(true);
    await new Promise(() => onRemoveClick());
    setIsRemovingListing(false);
  };

  return (
    <div className={css.listing}>
      <ConditionalWrapper
        condition={allowListingRedirect}
        wrapper={(children) => (
          <NamedLink
            name="ListingPage"
            onClick={onClick}
            params={{ id: listing.id.uuid, slug: createSlug(listing.attributes.title) }}
          >
            {children}
          </NamedLink>
        )}
      >
        <ListingImage
          currentListing={listing}
          title={listing.attributes.title}
          className={classNames(css.listingImage, listingImageClassName)}
        />
      </ConditionalWrapper>
      {isRemovingListing && (
        <Overlay>
          <IconSpinner />
        </Overlay>
      )}
      {isEditing && (
        <button className={css.removeListingButton} type="button" onClick={handleRemoveClick}>
          <IconSubtractFilled className={css.removeIcon} />
        </button>
      )}
    </div>
  );
};

export default ListingCardSmall;
