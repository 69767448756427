import React, { FC } from 'react';
import { DialogActions, DialogContent, DialogContentText } from '@material-ui/core';
import { Button } from '..';
import DialogWithCloseButton from '../DialogWithCloseButton/DialogWithCloseButton';
import { ButtonVariant } from '../Button/Button';
import TypographyWrapper from '../TypographyWrapper/TypographyWrapper';

interface MarkAsActionModalProps {
  open: boolean;
  onClose: () => void;
  onSubmit: () => void;
  inProgress?: boolean;
  actionLabel: string;
  actionDescription: string;
}

const MarkAsActionModal: FC<MarkAsActionModalProps> = (props) => {
  const { open, onClose, onSubmit, inProgress, actionLabel, actionDescription } = props;

  return (
    <DialogWithCloseButton
      scroll="paper"
      open={open}
      onClose={onClose}
      titleText={`Mark Order As ${actionLabel}`}
    >
      <DialogContent dividers>
        <DialogContentText>
          <TypographyWrapper variant="body1">
            <h4>{actionDescription}</h4>
            <h4>Please note, this action cannot be undone.</h4>
          </TypographyWrapper>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          disabled={inProgress}
          inProgress={inProgress}
          onClick={onSubmit}
          variant={ButtonVariant.Primary}
        >
          {`Mark as ${actionLabel}`}
        </Button>
      </DialogActions>
    </DialogWithCloseButton>
  );
};

export default MarkAsActionModal;
