import {
  Filter,
  FilterId,
  GenericFilterOption,
  SearchFilterType,
  SearchNavBarSections,
} from '../../types/filters/filters';

export enum Conditions {
  NewWithTags = 'new_with_tags',
  NewWithoutTags = 'new_without_tags',
  Excellent = 'excellent',
  Good = 'good',
  MinorDefect = 'minor_defect',
  Recyclable = 'recyclable',
}

export const defaultConditionOptionsOrder = [
  Conditions.NewWithTags,
  Conditions.NewWithoutTags,
  Conditions.Excellent,
  Conditions.Good,
  Conditions.MinorDefect,
];

export const defaultClothesConditionsOptions = [
  {
    key: Conditions.NewWithTags,
    label: 'New With Tags',
    shortLabel: 'New with Tags',
    description:
      'Your item has never been worn and the original hanging tags are still on. For this option, you’ll need to take pictures of the retail tags.',
  },
  {
    key: Conditions.NewWithoutTags,
    label: 'New Without Tags',
    shortLabel: 'New w/o Tags',
    description:
      'Your item has never been worn (other than perhaps trying it on), but the tags have been removed. There are no quirks or issues whatsoever.',
  },
  {
    key: Conditions.Excellent,
    label: 'Excellent Condition',
    shortLabel: 'Excellent',
    description:
      'Your item has only been lightly used and has been extremely well maintained. There may be some small signs of wear, but nothing major.',
  },
  {
    key: Conditions.Good,
    label: 'Good Condition',
    shortLabel: 'Good',
    description:
      'You’ve worn this item and it’s well maintained. There may be some quirks like a small stain, a button missing, or a loose thread, but it’s overall in good shape. You will need to take pictures of any quirks the item has.',
  },
];

export const defaultShoesConditionsOptions = [
  {
    // We are keeping the same as clothes, because we have logic that says if its NWT, then X & Y
    key: Conditions.NewWithTags,
    label: 'New In Box',
    shortLabel: 'New in Box',
    description:
      'Your shoes have never been worn and they’re still in the original box. For this option, you’ll need to take pictures of your shoes in the box.',
  },
  {
    key: Conditions.NewWithoutTags,
    label: 'New Without Box',
    shortLabel: 'New w/o Box',
    description:
      'Your shoes have never been worn (other than perhaps trying it on), but you no longer have the box. There are no quirks or issues whatsoever.',
  },
  {
    key: Conditions.Excellent,
    label: 'Excellent Condition',
    shortLabel: 'Excellent',
    description:
      'Your shoes have only been lightly used and have been extremely well maintained. There may be some small signs of wear, but nothing major.',
  },
  {
    key: Conditions.Good,
    label: 'Good Condition',
    shortLabel: 'Good',
    description:
      'You’ve worn the shoes and they’re well maintained. There may be some quirks like a small stain or a scuff mark, but they’re overall in good shape. You will need to take pictures of any quirks your shoes have.',
  },
];

export const defaultBagConditionsOptions = [
  {
    key: Conditions.NewWithTags,
    label: 'New With Tags',
    shortLabel: 'New with Tags',
    description:
      'Your item has never been used and the original hanging tags are still on. For this option, you’ll need to take pictures of the retail tags.',
  },
  {
    key: Conditions.NewWithoutTags,
    label: 'New Without Tags',
    shortLabel: 'New w/o Tags',
    description:
      'Your item has never been used, but the tags have been removed. There are no quirks or issues whatsoever.',
  },
  {
    key: Conditions.Excellent,
    label: 'Excellent Condition',
    shortLabel: 'Excellent',
    description:
      'Your item has only been lightly used and has been extremely well maintained. There may be some small signs of wear, but nothing major.',
  },
  {
    key: Conditions.Good,
    label: 'Good Condition',
    shortLabel: 'Good',
    description:
      'You’ve used this item and it’s well maintained. There may be some quirks like a small stain, or a loose thread, but it’s overall in good shape. You will need to take pictures of any quirks the item has.',
  },
];

interface GetConditionFilterConfigParams {
  options?: GenericFilterOption[];
}

export const getConditionsFilterConfig = (params?: GetConditionFilterConfigParams): Filter => {
  const { options = defaultClothesConditionsOptions } = params || {};
  return {
    id: FilterId.Condition,
    label: 'Condition',
    type: SearchFilterType.Checkbox,
    group: 'primary',
    queryParamNames: ['pub_condition'],
    searchNavBarSection: SearchNavBarSections.Filter,
    config: {
      options,
    },
  };
};
