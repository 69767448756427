import React, { FC } from 'react';
import {
  Button,
  CalendarCell,
  CalendarGrid,
  DateInput,
  DateRange,
  DateSegment,
  Dialog,
  Group,
  Heading,
  Label,
  Popover,
  RangeCalendar,
  DateRangePicker as ReactAriaDateRangePicker,
} from 'react-aria-components';
import { Box } from '@material-ui/core';
import TypographyWrapper from '../TypographyWrapper/TypographyWrapper';
import { IconArrowDown, IconArrowLeft, IconArrowRight } from '..';
import './DateRangePicker.css';

const BUTTON_STYLES = {
  cursor: 'pointer',
  border: 'none',
};

interface DateRangePickerProps {
  value: DateRange | undefined;
  onChange: (newValue: DateRange) => void;
  label?: string;
}

const DateRangePicker: FC<DateRangePickerProps> = (props) => {
  const { label, value, onChange } = props;

  return (
    <ReactAriaDateRangePicker value={value} onChange={onChange}>
      {label && (
        <Label>
          <TypographyWrapper variant="body2">{label}</TypographyWrapper>
        </Label>
      )}
      <Group>
        <DateInput slot="start">{(segment) => <DateSegment segment={segment} />}</DateInput>
        <Box px={1}>
          <span aria-hidden="true">–</span>
        </Box>
        <DateInput slot="end">{(segment) => <DateSegment segment={segment} />}</DateInput>
        <Button style={BUTTON_STYLES}>
          <IconArrowDown />
        </Button>
      </Group>
      <Popover>
        <Dialog>
          <RangeCalendar>
            <Box display="flex" justifyContent="space-between" width="100%">
              <Button slot="previous" style={BUTTON_STYLES}>
                <IconArrowLeft />
              </Button>
              <Heading />
              <Button slot="next" style={BUTTON_STYLES}>
                <IconArrowRight />
              </Button>
            </Box>
            <CalendarGrid>{(date) => <CalendarCell date={date} />}</CalendarGrid>
          </RangeCalendar>
        </Dialog>
      </Popover>
    </ReactAriaDateRangePicker>
  );
};

export default DateRangePicker;
