import { defaultClothesConditionsOptions, getConditionsFilterConfig } from '../filters/condition';
import { getDefaultShopConfig } from './default';
import * as custom from '../filters/marketplace-custom-config';
import { getSizeFilterConfigFromOptions } from '../filters/size';

const rooleeSizeOptions = [
  { key: 'Kids - 0-3mo', label: '0-3mo' },
  { key: 'Kids - 3-6mo', label: '3-6mo' },
  { key: 'Kids - 6-12mo', label: '6-12mo' },
  { key: 'Kids - 12-18mo', label: '12-18mo' },
  { key: 'Kids - 18-24mo', label: '18-24mo' },
  { key: 'Kids - 2T', label: '2T' },
  { key: 'Kids - 3-4T', label: '3-4T' },
  { key: 'Kids - 5-6T', label: '5-6T' },
  { key: 'Kids - 7-8', label: '7-8' },
  { key: 'Kids - 9-10', label: '9-10' },
  { key: 'Kids - 11-12', label: '11-12' },
  { key: 'Kids - 13-14', label: '13-14' },
  { key: 'Shoes - 5', label: '5' },
  { key: 'Shoes - 6', label: '6' },
  { key: 'Shoes - 6.5', label: '6.5' },
  { key: 'Shoes - 7', label: '7' },
  { key: 'Shoes - 7.5', label: '7.5' },
  { key: 'Shoes - 8', label: '8' },
  { key: 'Shoes - 8.5', label: '8.5' },
  { key: 'Shoes - 9', label: '9' },
  { key: 'Shoes - 10', label: '10' },
  { key: 'Shoes - 11', label: '11' },
  { key: 'XXS', label: 'XXS' },
  { key: 'XS', label: 'XS' },
  { key: 'S', label: 'S' },
  { key: 'M', label: 'M' },
  { key: 'L', label: 'L' },
  { key: 'XL', label: 'XL' },
  { key: 'XXL', label: 'XXL' },
  { key: 'XXXL', label: 'XXXL' },
];

export const rooleeShopConfig = {
  ...getDefaultShopConfig(),
  filters: [
    ...custom.filters,
    getConditionsFilterConfig({ options: defaultClothesConditionsOptions }),
    getSizeFilterConfigFromOptions({ options: rooleeSizeOptions }),
  ],
};
