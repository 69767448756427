import React from 'react';
import classNames from 'classnames';
import { useIconColor } from '../../../hooks/useIconColor';

interface IconCloseV2Props {
  rootClassName?: string;
  className?: string;
}

const IconCloseV2: React.FC<IconCloseV2Props> = (props) => {
  const { className, rootClassName } = props;
  const classes = classNames(rootClassName, className);

  const { iconColor } = useIconColor();

  return (
    <svg
      className={classes}
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="5.42029"
        y="4.625"
        width="11.25"
        height="1.125"
        transform="rotate(45 5.42029 4.625)"
        fill={iconColor}
      />
      <rect
        x="13.3752"
        y="5.41992"
        width="11.25"
        height="1.125"
        transform="rotate(135 13.3752 5.41992)"
        fill={iconColor}
      />
    </svg>
  );
};

export default IconCloseV2;
