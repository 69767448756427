import React, { FC } from 'react';
import { useIconColor } from '../../../hooks/useIconColor';

interface IconInfoProps {
  className?: string;
  color?: string;
  height?: string;
  width?: string;
}

const IconInfo: FC<IconInfoProps> = (props: IconInfoProps) => {
  const { className, color, height, width } = props;

  const { iconColor } = useIconColor();
  const fillColor = color || iconColor;

  return (
    <svg
      className={className}
      width={width || '16'}
      height={height || '16'}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1619_19)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8 14C11.3137 14 14 11.3137 14 8C14 4.68629 11.3137 2 8 2C4.68629 2 2 4.68629 2 8C2 11.3137 4.68629 14 8 14ZM8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16Z"
          fill={fillColor}
        />
        <rect x="7" y="7" width="2" height="5" fill={fillColor} />
        <rect x="7" y="4" width="2" height="2" fill={fillColor} />
      </g>
      <defs>
        <clipPath id="clip0_1619_19">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default IconInfo;
