import { defaultShoesConditionsOptions, getConditionsFilterConfig } from '../filters/condition';
import { getDefaultShopConfig } from './default';
import * as custom from '../filters/marketplace-custom-config';
import { getSizeFilterConfig } from '../filters/size';

const bryrSizes = ['4/34', '5/35', '6/36', '7/37', '8/38', '9/39', '10/40', '11/41', '12', '13'];

export const bryrShopConfig = {
  ...getDefaultShopConfig(),
  filters: [
    ...custom.filters,
    getConditionsFilterConfig({ options: defaultShoesConditionsOptions }),
    getSizeFilterConfig({ sizes: bryrSizes }),
  ],
};
