/* eslint-disable import/prefer-default-export */
import { defaultClothesConditionsOptions, getConditionsFilterConfig } from '../filters/condition';
import { getDefaultShopConfig, getDefaultTradeInShopConfig } from './default';
import * as custom from '../filters/marketplace-custom-config';
import { getSizeFilterConfig } from '../filters/size';

const tenable6Sizes = [
  'S',
  'M',
  'L',
  'XL',
  'XXL',
  'ONE SIZE',
  '32',
  '33',
  '34',
  '35',
  '36',
  '37',
  '38',
  '39',
  '40',
  '41',
  '42',
  '44',
  '46',
  '48',
  '50',
  '52',
  '54',
  '56',
  '58',
];

export const tenable6ShopConfig = {
  ...getDefaultShopConfig(),
  ...getDefaultTradeInShopConfig(),
  filters: [
    ...custom.filters,
    getConditionsFilterConfig({ options: defaultClothesConditionsOptions }),
    getSizeFilterConfig({ sizes: tenable6Sizes }),
  ],
  additionalPayoutSettings: {
    // Tenable6's trade-in customers are considered "consignors" that are not paid out upon receipt
    // and approval of the trade-in item. Instead, they will be paid out with commission once the
    // trade-in item is purchased by a new buyer.
    tradeInCreditPayoutBundleAmount: 0,
  },
  addTradeInItemCardCopy: 'Earn cash for accepted pre-loved items',
};
