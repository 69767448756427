import React from 'react';
import PropTypes from 'prop-types';
import { DialogActions, DialogContent, DialogContentText } from '@material-ui/core';
import { Button, TypographyWrapper } from '..';
import DialogWithCloseButton from '../DialogWithCloseButton/DialogWithCloseButton';
import { ButtonVariant } from '../Button/Button';

const ConfirmDeleteListingModal = (props) => {
  const { open, onClose, onSubmit, inProgress } = props;

  return (
    <DialogWithCloseButton
      allowFullScreen={false}
      scroll="paper"
      open={open}
      onClose={onClose}
      titleText="Delete Listing"
    >
      <DialogContent dividers>
        <DialogContentText>
          <h4>
            <TypographyWrapper variant="body1">
              Are you sure you want to delete this listing? Doing so will delete your listing
              permanently.
            </TypographyWrapper>
          </h4>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          disabled={inProgress}
          inProgress={inProgress}
          onClick={onSubmit}
          variant={ButtonVariant.Danger}
        >
          Delete
        </Button>
      </DialogActions>
    </DialogWithCloseButton>
  );
};

ConfirmDeleteListingModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  inProgress: PropTypes.bool,
};

export default ConfirmDeleteListingModal;
