import React, { FC } from 'react';
import ExternalLink from '../ExternalLink/ExternalLink';
import TypographyWrapper from '../TypographyWrapper/TypographyWrapper';
import css from './MarkdownText.module.css';

interface MarkdownTextProps {
  markdownText: string;
  typographyOverrides?: any;
}

const parseMarkdownLinks = (text: string): any[] => {
  const markdownLinkRegex = /\[([^\]]+)\]\(([^)]+)\)/g;
  let lastIndex = 0;
  const parts: React.ReactNode[] = [];

  text.replace(markdownLinkRegex, (match, linkText, url, offset) => {
    // Add preceding text as plain text
    parts.push(text.substring(lastIndex, offset));

    // Add link component
    parts.push(
      <ExternalLink key={offset} href={url} className={css.link}>
        <TypographyWrapper
          component="span"
          variant="body1"
          typographyOverrides={{ display: 'inline' }}
        >
          {linkText}
        </TypographyWrapper>
      </ExternalLink>
    );

    lastIndex = offset + match.length;
    return match;
  });

  // Add remaining text after last match
  parts.push(text.substring(lastIndex));

  return parts;
};

const MarkdownText: FC<MarkdownTextProps> = ({ markdownText, typographyOverrides }) => {
  const parsedText = parseMarkdownLinks(markdownText);

  return (
    <TypographyWrapper variant="body1" typographyOverrides={typographyOverrides}>
      {parsedText}
    </TypographyWrapper>
  );
};

export default MarkdownText;
