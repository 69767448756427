import { Box, Grid, Typography } from '@material-ui/core';
import { isEmpty } from 'lodash';
import React, { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { TopbarContainer } from '..';
import {
  Button,
  Error,
  Footer,
  IconArrowRight,
  IconSpinner,
  LayoutSingleColumn,
  LayoutWrapperFooter,
  LayoutWrapperMain,
  LayoutWrapperTopbar,
  ListingCardPreview,
  ManageListingCard,
  NamedLink,
  Page,
  UserNavbar,
} from '../../components';
import TypographyWrapper, {
  TypographyFormat,
} from '../../components/TypographyWrapper/TypographyWrapper';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import { useIsMobile } from '../../hooks/useIsMobile';
import { defaultTreetStyles } from '../../shopConfig/config';
import { RequestStatus } from '../../types/requestStatus';
import { CurrentUser } from '../../types/sharetribe/currentUser';
import { ListingWithImages } from '../../types/sharetribe/listing';
import { LISTING_STATE_DRAFT } from '../../util/types';
import EmptyCloset from './Empty';
import SellOrTradeInInfoCards from './SellOrTradeInInfoCards';
import { getListingsFromMarketplaceData, ManageClosetPageState } from './ManageClosetPage.duck';
import css from './ManageClosetPage.module.css';
import { getDarkerBrandCssBackgroundColor } from '../../util/colors/colors';
import { useShopConfig } from '../../hooks/shopConfig';
import { CoreBundle, useAllBundlesLazyQuery } from '../../types/apollo/generated/types.generated';

const SectionBoxWrapper: FC<any> = (props: any) => {
  const { children, boxOverrides } = props;
  const isMobile = useIsMobile();

  return (
    <Box mx={isMobile ? 0 : 4} {...boxOverrides}>
      {children}
    </Box>
  );
};

const ListingsGridItem: FC = (props) => {
  const { children } = props;
  return (
    <Grid item xs={6} sm={6} md={6} lg={3}>
      {children}
    </Grid>
  );
};

interface ListingsRowProps {
  listings: ListingWithImages[];
  bundles?: CoreBundle[];
}

const ListingCardRow: FC<ListingsRowProps> = (props) => {
  const { listings, bundles } = props;
  const currentUser = useSelector<any>((state) => state.user.currentUser) as
    | CurrentUser
    | undefined;
  if (!listings) return null;

  const getMostRecentBundle = (listingId: string) =>
    bundles?.find((bundle) =>
      bundle.bundleItems.some((bundleItem) => bundleItem.listing.sharetribeListingId === listingId)
    );

  return (
    <Grid container spacing={3}>
      {listings.map((l: ListingWithImages) => (
        <ListingsGridItem key={l.id.uuid}>
          <ManageListingCard
            key={l.id.uuid}
            listing={l}
            isMenuOpen={false}
            onToggleMenu={() => {}}
            currentUser={currentUser}
            bundle={getMostRecentBundle(l.id.uuid)}
            hideMenu
          />
        </ListingsGridItem>
      ))}
    </Grid>
  );
};

interface ContinueTradeInProps {
  listings: ListingWithImages[];
}

const ContinueTradeIn: FC<ContinueTradeInProps> = (props) => {
  const { listings } = props;
  const isMobile = useIsMobile();
  const { css: brandCss } = useShopConfig();
  const darkerBrandCssBackgroundColor = getDarkerBrandCssBackgroundColor(brandCss);

  const listingsToShow = listings?.slice(0, 20);

  return (
    <NamedLink name="ManageTradeInsPage">
      <SectionBoxWrapper
        boxOverrides={{
          className: css.banner,
          px: 3,
          py: 1,
          display: 'flex',
          flexDirection: 'column',
          style: {
            backgroundColor: darkerBrandCssBackgroundColor,
            border: `1px solid ${defaultTreetStyles.gray20}`,
          },
        }}
      >
        <Box display="flex" flexDirection="row" justifyContent="center">
          <Box
            display="flex"
            flexDirection="row"
            className={css.listingImages}
            style={{ position: 'relative' }}
          >
            {listingsToShow.length > 1 && <div className={css.listingPreviewGradient} />}
            {listingsToShow.map((listing, index) => {
              const isLast = index === listingsToShow?.length - 1;
              return (
                <Box {...(!isLast && { mr: 1 })}>
                  <ListingCardPreview
                    listingImageClassName={css.listingPreview}
                    listing={listing}
                    allowListingRedirect={false}
                  />
                </Box>
              );
            })}
          </Box>
          <Box
            className={css.submitButton}
            display="flex"
            flexDirection="row"
            alignItems="center"
            ml={2}
          >
            <Typography variant={isMobile ? 'body1' : 'subtitle1'} style={{ fontWeight: 'bold' }}>
              Submit Trade-In
            </Typography>
            <IconArrowRight />
          </Box>
        </Box>
      </SectionBoxWrapper>
    </NamedLink>
  );
};

interface SeeListingsProps {
  listings: ListingWithImages[];
  isDraft?: boolean;
  bundles?: CoreBundle[];
}

const SeeListings: FC<SeeListingsProps> = (props) => {
  const { listings, isDraft, bundles } = props;
  const isMobile = useIsMobile();

  const maxNumToShow = isMobile ? 2 : 4;
  const listingsToShow = listings?.slice(0, maxNumToShow);
  const title = isDraft ? 'Drafts' : 'Listings For Sale';
  const hasMore = listings?.length > maxNumToShow;

  return (
    <SectionBoxWrapper boxOverrides={{ mt: 3 }}>
      <NamedLink
        name="ManageListingsPage"
        {...(isDraft && { to: { search: `state=${LISTING_STATE_DRAFT}` } })}
      >
        <Box mb={1} display="flex" flexDirection="row" alignItems="center">
          <TypographyWrapper variant="h1" format={TypographyFormat.Underlined}>
            {title}
          </TypographyWrapper>
        </Box>
      </NamedLink>
      <ListingCardRow listings={listingsToShow} bundles={bundles} />
      {hasMore && (
        <Box display="flex" justifyContent="center" alignItems="center" my={1}>
          <NamedLink
            name="ManageListingsPage"
            style={{ textDecoration: 'none' }}
            {...(isDraft && { to: { search: `state=${LISTING_STATE_DRAFT}` } })}
          >
            <Button>All {title}</Button>
          </NamedLink>
        </Box>
      )}
    </SectionBoxWrapper>
  );
};

export const ManageClosetPage: FC = () => {
  const scrollingDisabled = useSelector<any>((state) => isScrollingDisabled(state));
  const {
    listingResultIds,
    draftListingResultIds,
    tradeInListingResultIds,
    fetchListingsError,
    fetchDraftListingsError,
    fetchTradeInListingsError,
    fetchListingsStatus,
    fetchDraftListingsStatus,
    fetchTradeInListingsStatus,
  } = useSelector<any>((state) => state.ManageClosetPage) as ManageClosetPageState;
  const currentUser = useSelector<any>((state) => state.user.currentUser) as
    | CurrentUser
    | undefined;

  const rootState = useSelector<any>((state) => state) as any;
  const listings = getListingsFromMarketplaceData(rootState, listingResultIds);
  const draftListings = getListingsFromMarketplaceData(rootState, draftListingResultIds);
  const tradeInListings = getListingsFromMarketplaceData(rootState, tradeInListingResultIds);

  const [queryAllBundles, { loading: areBundlesLoading, data: bundlesResponse }] =
    useAllBundlesLazyQuery({
      fetchPolicy: 'network-only',
    });

  useEffect(() => {
    if (currentUser && !isEmpty(listingResultIds)) {
      queryAllBundles({
        variables: {
          userId: currentUser.id.uuid,
          sharetribeListingIds: listings.map((listing) => listing.id.uuid),
        },
      });
    }
  }, [currentUser, listingResultIds]);

  const hasError = fetchListingsError || fetchDraftListingsError || fetchTradeInListingsError;
  const isLoading =
    fetchListingsStatus === RequestStatus.Pending ||
    fetchDraftListingsStatus === RequestStatus.Pending ||
    fetchTradeInListingsStatus === RequestStatus.Pending ||
    areBundlesLoading;
  const isEmptyState = isEmpty(tradeInListings) && isEmpty(draftListings) && isEmpty(listings);

  return (
    <Page title="Manage Closet" scrollingDisabled={scrollingDisabled}>
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer currentPage="ManageClosetPage" />
          <UserNavbar selectedPageName="ManageClosetPage" />
        </LayoutWrapperTopbar>
        <LayoutWrapperMain>
          <div className={css.listingPanel}>
            <Box display="flex" flexDirection="column" className={css.title} pb={4}>
              {hasError && (
                <Box my={3}>
                  <Error>Error querying listings. Please try again.</Error>
                </Box>
              )}
              {isLoading && (
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="center"
                  flex={1}
                >
                  <IconSpinner />
                </Box>
              )}
              {!isLoading && !hasError && (
                <>
                  {!isEmpty(tradeInListings) && <ContinueTradeIn listings={tradeInListings} />}
                  <SellOrTradeInInfoCards hasActiveTradeIn={!isEmpty(tradeInListings)} />
                  {!isEmpty(draftListings) && (
                    <SeeListings
                      listings={draftListings}
                      bundles={bundlesResponse?.bundles}
                      isDraft
                    />
                  )}
                  {!isEmpty(listings) && (
                    <SeeListings listings={listings} bundles={bundlesResponse?.bundles} />
                  )}
                  {isEmptyState && <EmptyCloset />}
                </>
              )}
            </Box>
          </div>
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </Page>
  );
};

export default ManageClosetPage;
