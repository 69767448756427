import React from 'react';
import { useIconColor } from '../../../hooks/useIconColor';

interface IconCheckCircleProps {
  className?: string;
  color?: string;
}

const IconCheckCircle: React.FC<IconCheckCircleProps> = (props) => {
  const { className, color } = props;

  const { iconColor } = useIconColor();
  const fillColor = color || iconColor;

  return (
    <svg
      className={className}
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="7" cy="7" r="6" stroke={fillColor} strokeWidth="1" fill="none" />
    </svg>
  );
};

export default IconCheckCircle;
