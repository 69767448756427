import { getDefaultShopConfig } from './default';
import * as custom from '../filters/marketplace-custom-config';
import { getBrandDirectFilterConfig } from '../filters/brandDirect';
import {
  Conditions,
  defaultClothesConditionsOptions,
  getConditionsFilterConfig,
} from '../filters/condition';
import { getSizeFilterConfig } from '../filters/size';
import { getColorFilterConfig } from '../filters/color';

const quinceandcoSizes = [
  'One Size',
  'Small',
  'Medium',
  'Large',
  'US 6 (4mm)',
  'US 7 (4.4mm)',
  'US 8 (4.8mm)',
  'US 9 (5.6mm)',
  'US 10.5 (7mm)',
  'US 11 (8mm)',
  'US 13 (8.8mm)',
  '3/8"',
  '1/2"',
  '9/16"',
  '13/16"',
  '1"',
];

const quinceandcoConditions = [
  ...defaultClothesConditionsOptions.slice(0, 2),
  {
    key: Conditions.Excellent,
    label: 'Excellent Condition',
    description:
      'Your item has only been slightly unraveled and has been extremely well maintained.',
  },
  {
    key: Conditions.Good,
    label: 'Good Condition',
    description:
      'Only for creations made with Quince and Co. yarns. There may be some quirks like a dropped stitch, a button missing, or a loose thread, but it’s overall in good shape. You will need to take pictures of any quirks the item has.',
  },
];

export const quinceandcoShopConfig = {
  ...getDefaultShopConfig(),
  filters: [
    ...custom.filters,
    getConditionsFilterConfig({ options: quinceandcoConditions }),
    getBrandDirectFilterConfig(),
    getColorFilterConfig(),
    getSizeFilterConfig({ sizes: quinceandcoSizes }),
  ],
};
