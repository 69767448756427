/* eslint-disable import/prefer-default-export */
import { defaultClothesConditionsOptions, getConditionsFilterConfig } from '../filters/condition';
import { getDefaultShopConfig } from './default';
import * as custom from '../filters/marketplace-custom-config';
import { getBrandDirectFilterConfig } from '../filters/brandDirect';
import { getSizeFilterConfig } from '../filters/size';

const thelullabyclubSizes = [
  'XS (6-8)',
  'S/M (8-10)',
  'S/M (10-12)',
  'M/L (12-14)',
  'L/XL (16-18)',
  'XXL (20-22)',
  '3XL (24-26)',
  'O/S',
  '8',
  '10',
  '12',
  '14',
  '16',
  '18',
  '20',
];

export const thelullabyclubShopConfig = {
  ...getDefaultShopConfig(),
  filters: [
    ...custom.filters,
    getConditionsFilterConfig({ options: defaultClothesConditionsOptions }),
    getBrandDirectFilterConfig(),
    getSizeFilterConfig({ sizes: thelullabyclubSizes }),
  ],
};
