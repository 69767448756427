import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';
import { withRouter } from 'react-router-dom';
import classNames from 'classnames';
import { compose } from 'redux';
import { Box } from '@material-ui/core';
import { connect } from 'react-redux';
import config from '../../shopConfig/config';
import AppContext from '../../context/AppContext';
import { getEnabledCustomerExperiences, getShopConfig } from '../../shopConfig/configHelper';
import { injectIntl, intlShape } from '../../util/reactIntl';
import routeConfiguration from '../../routeConfiguration';
import { withViewport } from '../../util/contextHelpers';
import { canonicalRoutePath, getRouteName } from '../../util/routes';
import { metaTagProps } from '../../util/seo';
import * as zendesk from '../../util/zendesk';
import { MAX_SMALL_SCREEN_WIDTH } from '../../util/window';
import NamedRedirect from '../NamedRedirect/NamedRedirect';
import { getUserCountryConfig } from '../../hooks/useCountryConfig';
import { COUNTRY_WARNING_MODAL_TRIGGERED_STORAGE_KEY } from '../CountryWarningModal/CountryWarningModal';
import { getStoredData } from '../../util/sessionHelpers/sessionHelpersUtils';
import { setFeatureFlagHeapProperties, setIsLoggedInEventProperty } from '../../util/heap';
import { ModalParams, parse } from '../../util/urlHelpers';
import { ModalType, setActiveModal } from '../../ducks/modal.duck';
import Modals from './Modals';
import css from './Page.module.css';

const VALID_TRADE_IN_PAGES = [
  'AboutBasePage',
  'AboutPage',
  'AboutTreetPage',
  'AccountSettingsPage',
  'AdminPage',
  'AuthenticationPage',
  'ContactDetailsPage',
  'CookiePolicyPage',
  'EditListingPage',
  'EmailVerificationPage',
  'GenerateShippingLabelPage',
  'HomePage',
  'LoginPage',
  'ManagePurchasePage',
  'ManagePurchasesPage',
  'ManageSalePage',
  'ManageSalesPage',
  'ManageTradeInsPage',
  'NewListingPage',
  'NotFoundPage',
  'PasswordChangePage',
  'PasswordRecoveryPage',
  'PasswordResetPage',
  'PrivacyPolicyPage',
  'SignupPage',
  'TermsOfServicePage',
];

const TWO_SECONDS = 2000; // in ms

const preventDefault = (e) => {
  e.preventDefault();
};

const twitterPageURL = (siteTwitterHandle) => {
  if (siteTwitterHandle && siteTwitterHandle.charAt(0) === '@') {
    return `https://twitter.com/${siteTwitterHandle.substring(1)}`;
  }

  if (siteTwitterHandle) {
    return `https://twitter.com/${siteTwitterHandle}`;
  }
  return null;
};

const formatLocale = (locale) => {
  if (!locale) {
    return locale;
  }
  const [lang, country] = locale.split('_');
  return `${lang.toLowerCase()}-${country.toUpperCase()}`;
};

class PageComponent extends Component {
  static contextType = AppContext;

  constructor(props) {
    super(props);
    // Keeping scrollPosition out of state reduces rendering cycles (and no bad states rendered)
    this.scrollPosition = 0;
    this.contentDiv = null;
    this.scrollingDisabledChanged = this.scrollingDisabledChanged.bind(this);
  }

  componentDidMount() {
    const { intl, openCountryWarningModal, isAuthenticated, currentUser } = this.props;
    const { treetId, shopConfig: shopConfigV2 } = this.context;
    const { enabledCustomerExperiences, internationalConfig } = getShopConfig(
      treetId,
      shopConfigV2
    );
    const { allowBuy, allowList } = getEnabledCustomerExperiences(enabledCustomerExperiences);
    const allowedOriginToDestinationCountries =
      internationalConfig?.allowedOriginToDestinationCountries || {};
    const { canUserBuyInCountry, canShipFromUserCountry: canUserListInCountry } =
      getUserCountryConfig(intl.locale, allowedOriginToDestinationCountries);

    // Set Heap properties that depend on user, per page.
    setFeatureFlagHeapProperties(treetId, currentUser);
    setIsLoggedInEventProperty(isAuthenticated);

    const shouldShowCountryWarningModal =
      (allowBuy && !canUserBuyInCountry) || (allowList && !canUserListInCountry);

    // By default a dropped file is loaded in the browser window as a
    // file URL. We want to prevent this since it might loose a lot of
    // data the user has typed but not yet saved. Preventing requires
    // handling both dragover and drop events.
    document.addEventListener('dragover', preventDefault);
    document.addEventListener('drop', preventDefault);

    if (!shouldShowCountryWarningModal) return;

    const { displayCountryWarningTreetIds = [] } =
      getStoredData(COUNTRY_WARNING_MODAL_TRIGGERED_STORAGE_KEY) || {};
    const hasAlreadyTriggeredCountryWarningModal = displayCountryWarningTreetIds.includes(treetId);
    if (!hasAlreadyTriggeredCountryWarningModal) {
      setTimeout(openCountryWarningModal, TWO_SECONDS);
    }
  }

  componentWillUnmount() {
    document.removeEventListener('dragover', preventDefault);
    document.removeEventListener('drop', preventDefault);
  }

  scrollingDisabledChanged(currentScrollingDisabled) {
    if (currentScrollingDisabled && currentScrollingDisabled !== this.scrollingDisabled) {
      // Update current scroll position, if scrolling is disabled (e.g. modal is open)
      this.scrollPosition = window.pageYOffset || document.documentElement.scrollTop;
      this.scrollingDisabled = currentScrollingDisabled;
    } else if (currentScrollingDisabled !== this.scrollingDisabled) {
      this.scrollingDisabled = currentScrollingDisabled;
    }
  }

  render() {
    const {
      className,
      rootClassName,
      children,
      location,
      intl,
      scrollingDisabled,
      referrer,
      author,
      contentType,
      description,
      facebookImages,
      published,
      schema,
      tags,
      title,
      twitterHandle,
      twitterImages,
      updated,
      viewport,
      activeModal,
      pageCanonicalUrl,
      shouldNotIndex,
    } = this.props;

    const classes = classNames(rootClassName || css.root, className, {
      [css.scrollingDisabled]: scrollingDisabled,
    });

    this.scrollingDisabledChanged(scrollingDisabled);
    const referrerMeta = referrer ? <meta name="referrer" content={referrer} /> : null;

    const shouldReturnPathOnly = referrer && referrer !== 'unsafe-url';
    const { treetId, canonicalRootUrl, primaryLocale, shopConfig: shopConfigV2 } = this.context;

    const shopPrimaryLocale = formatLocale(primaryLocale);

    const canonicalPath = canonicalRoutePath(
      routeConfiguration(treetId),
      location,
      shouldReturnPathOnly
    );
    const routeName = getRouteName(location.pathname, routeConfiguration(treetId));
    const isMobileLayout = viewport.width <= MAX_SMALL_SCREEN_WIDTH;
    const {
      [ModalParams.MobileMenu]: mobileMenuParam,
      [ModalParams.MobileShoppingBag]: mobileShoppingBagParam,
    } = parse(location.search);
    const hasActiveModal =
      !!activeModal || mobileMenuParam === 'open' || mobileShoppingBagParam === 'open';
    zendesk.showOrHide(routeName, hasActiveModal, isMobileLayout);

    const {
      treetShopName,
      shopName,
      images,
      css: brandCss,
      enabledCustomerExperiences,
      forClientReviewOnly,
      blockFromSearchIndexing,
      copy: brandCopy,
    } = getShopConfig(treetId, shopConfigV2);
    const { isTradeInOnly } = getEnabledCustomerExperiences(enabledCustomerExperiences);

    // TODO (TREET-3478): remove redirect
    if (location.pathname === '/notifications-settings') {
      return <NamedRedirect name="NotificationSettingsPage" />;
    }

    if (isTradeInOnly && !VALID_TRADE_IN_PAGES.includes(routeName)) {
      return <NamedRedirect name="AboutBasePage" />;
    }

    const canonicalUrl = pageCanonicalUrl || `${canonicalRootUrl}${canonicalPath}`;
    const schemaTitle = intl.formatMessage(
      { id: 'Page.schemaTitle' },
      { siteTitle: brandCopy?.seoSiteTitle || treetShopName }
    );

    const schemaDescription =
      brandCopy?.seoSiteDescription ||
      brandCopy?.heroSubtitle ||
      intl.formatMessage({ id: 'Page.schemaDescription' });

    const heroImageDefaultUrl = images?.heroImage?.url;
    const heroImageMobileUrl = images?.heroImageMobile?.url;
    const heroImageUrl = (isMobileLayout && heroImageMobileUrl) || heroImageDefaultUrl;

    const metaTitle = title || schemaTitle;

    // This default meta description is applied to all pages. Incorporating the page
    // title helps avoid issues with duplicate meta descriptions. In the future, we should
    // create unique meta descriptions for each page to enhance SEO.
    const defaultMetaDescription = `${metaTitle} - ${schemaDescription}`;
    const metaDescription = description || defaultMetaDescription;
    const facebookImgs = facebookImages || [
      {
        name: 'facebook',
        url: heroImageUrl,
        width: 1200,
        height: 630,
      },
    ];
    const twitterImgs = twitterImages || [
      {
        name: 'twitter',
        url: heroImageUrl,
        width: 600,
        height: 314,
      },
    ];

    const metaToHead = metaTagProps({
      author,
      contentType,
      description: metaDescription,
      facebookImages: facebookImgs,
      twitterImages: twitterImgs,
      published,
      tags,
      title: metaTitle,
      twitterHandle,
      updated,
      url: canonicalUrl,
      locale: shopPrimaryLocale,
      image: [heroImageUrl],
      noindex: shouldNotIndex || blockFromSearchIndexing || forClientReviewOnly,
    });

    // eslint-disable-next-line react/no-array-index-key
    const metaTags = metaToHead.map((metaProps, i) => <meta key={i} {...metaProps} />);

    const facebookPage = config.siteFacebookPage;
    const twitterPage = twitterPageURL(config.siteTwitterHandle);
    const instagramPage = config.siteInstagramPage;
    const linkedInPage = config.siteLinkedInPage;
    const sameOrganizationAs = [facebookPage, twitterPage, instagramPage, linkedInPage].filter(
      (v) => v != null
    );

    // Schema for search engines (helps them to understand what this page is about)
    // http://schema.org
    // We are using JSON-LD format
    const structuredData = [
      {
        '@context': 'http://schema.org',
        '@type': 'Organization',
        '@id': `${canonicalRootUrl}#organization`,
        url: canonicalRootUrl,
        name: treetShopName,
        brand: shopName,
        logo: images?.treetShopLogo?.url || images?.shopLogo?.url,
        description: schemaDescription,
        contactPoint: {
          '@type': 'ContactPoint',
          contactType: 'Customer Service',
          email: 'support@treet.co',
        },
        memberOf: {
          '@type': 'Organization',
          '@id': 'https://www.treet.co/#organization',
          url: 'https://www.treet.co/',
          name: 'Treet',
          logo: 'https://static.wixstatic.com/media/c5f5d6_15b200306a08413197e548f2abbf907c~mv2.png/v1/fill/w_228,h_60,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/logoOnLight.png',
          description: 'Your home for brand-certified resale.',
          sameAs: sameOrganizationAs,
          contactPoint: {
            '@type': 'ContactPoint',
            contactType: 'Customer Service',
            email: 'support@treet.co',
          },
        },
      },
      {
        '@context': 'https://schema.org/',
        '@type': 'WebSite',
        name: treetShopName,
        url: canonicalRootUrl,
        potentialAction: {
          '@type': 'SearchAction',
          target: `${canonicalRootUrl}/?keywords={search_term_string}&mode=raw-query`,
          'query-input': 'required name=search_term_string',
        },
      },
      schema,
    ]
      .filter((value) => !!value)
      .map((value) => {
        const stringifiedValue = JSON.stringify(value).replace(/</g, '\\u003c');
        return (
          <script key={stringifiedValue} type="application/ld+json">
            {stringifiedValue}
          </script>
        );
      });

    const scrollPositionStyles = scrollingDisabled
      ? { marginTop: `${-1 * this.scrollPosition}px` }
      : {};
    const isTreetShop = treetId === 'treet';

    // If scrolling is not disabled, but content element has still scrollPosition set
    // in style attribute, we scrollTo scrollPosition.
    const hasMarginTopStyle = this.contentDiv && this.contentDiv.style.marginTop;
    if (!scrollingDisabled && hasMarginTopStyle) {
      window.requestAnimationFrame(() => {
        window.scrollTo(0, this.scrollPosition);
      });
    }

    const buildHreflang = () => {
      const isPathListing = location.pathname.toString().includes('/l/');
      const isPathUser = location.pathname.toString().includes('/u/');
      if (isPathListing || isPathUser) {
        // Listings & Users do not have "alternate" versions in other locales.
        return [];
      }
      return [
        <link
          rel="alternate"
          key={shopPrimaryLocale}
          href={canonicalUrl}
          hrefLang={shopPrimaryLocale}
        />,
      ];
    };

    const hreflang = buildHreflang();

    return (
      <div className={classes}>
        <Helmet
          htmlAttributes={{
            lang: shopPrimaryLocale,
          }}
        >
          <title>{title}</title>
          {hreflang}
          {referrerMeta}
          <link rel="canonical" href={canonicalUrl} />
          <meta httpEquiv="Content-Type" content="text/html; charset=UTF-8" />
          <meta httpEquiv="Content-Language" content={shopPrimaryLocale} />
          {metaTags}
          {structuredData}
        </Helmet>
        <Box
          className={css.content}
          bgcolor={brandCss?.backgroundColor}
          style={scrollPositionStyles}
          ref={(c) => {
            this.contentDiv = c;
          }}
        >
          {children}
          {!isTreetShop && <Modals />}
        </Box>
      </div>
    );
  }
}

const { any, array, arrayOf, bool, func, number, object, oneOfType, shape, string } = PropTypes;

PageComponent.defaultProps = {
  className: null,
  rootClassName: null,
  children: null,
  author: null,
  contentType: 'website',
  description: null,
  facebookImages: null,
  twitterImages: null,
  published: null,
  referrer: null,
  schema: null,
  tags: null,
  twitterHandle: null,
  updated: null,
  currentUser: null,
  pageCanonicalUrl: null,
  shouldNotIndex: false,
};

PageComponent.propTypes = {
  className: string,
  rootClassName: string,
  children: any,
  scrollingDisabled: bool,

  // Handle referrer policy
  referrer: string,

  // SEO related props
  author: string,
  contentType: string, // og:type
  description: string, // page description
  facebookImages: arrayOf(
    shape({
      width: number.isRequired,
      height: number.isRequired,
      url: string.isRequired,
    })
  ),
  twitterImages: arrayOf(
    shape({
      width: number.isRequired,
      height: number.isRequired,
      url: string.isRequired,
    })
  ),
  published: string, // article:published_time
  schema: oneOfType([object, array]), // http://schema.org
  tags: string, // article:tag
  title: string.isRequired, // page title
  twitterHandle: string, // twitter handle
  updated: string, // article:modified_time

  // from withRouter
  history: shape({
    listen: func.isRequired,
  }).isRequired,
  location: object.isRequired,

  // from withViewport
  viewport: shape({
    width: number.isRequired,
    height: number.isRequired,
  }).isRequired,

  // from state
  activeModal: string,
  isAuthenticated: bool.isRequired,
  currentUser: any,

  // from dispatch
  openCountryWarningModal: func.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
  pageCanonicalUrl: string,
  shouldNotIndex: bool,
};

const mapStateToProps = (state) => {
  const { activeModal } = state.modal;
  const { isAuthenticated } = state.Auth;
  const { currentUser } = state.user;

  return { activeModal, isAuthenticated, currentUser };
};

const mapDispatchToProps = (dispatch) => ({
  openCountryWarningModal: () => dispatch(setActiveModal(ModalType.CountryWarning)),
});

const Page = compose(
  withRouter,
  withViewport,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(PageComponent);
Page.displayName = 'Page';

export default Page;
