import { Conditions } from '../../shopConfig/filters/condition';
import { Uuid } from '../sharetribe/uuid';

export type ImageCategoryConfig = {
  label: string;
  conditionsToRequire?: Conditions[];
};

export enum SellerImageCategory {
  // Clothing defaults
  Front = 'FRONT',
  Back = 'BACK',
  Tag = 'TAG',
  RetailTag = 'RETAIL_TAG',
  Additional = 'ADDITIONAL',

  // Shoes
  Top = 'TOP',
  Side = 'SIDE',
  Sole = 'SOLE',

  // Bags
  Interior = 'INTERIOR',

  // Swimwear, intimates, diapers
  Inside = 'INSIDE',

  // rough & tumble
  OrderCard = 'ORDER_CARD',

  // jujube
  WashingInstructions = 'WASHING_INSTRUCTIONS',

  // anián
  FlatLay = 'FLAT_LAY',
}

//  From Contentful.
export type SellerImageCategoryContentfulConfig = {
  [key in SellerImageCategory]?: ImageCategoryConfig;
};

type SellerImageCategoryForConditionConfig = ImageCategoryConfig & {
  required: boolean;
};

export type SellerImageCategoryForCondition = {
  [key in SellerImageCategory]?: SellerImageCategoryForConditionConfig;
};

export type ImageFileData = {
  id: string;
  imageId?: Uuid;
  file?: File;
};
