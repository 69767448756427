import React, { FC } from 'react';

interface IconSellProps {
  className?: string;
}

const IconSell: FC<IconSellProps> = (props: IconSellProps) => {
  const { className } = props;

  return (
    <svg className={className} viewBox="0 0 65 65" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g opacity="0.15" clipPath="url(#clip0_940_3009)">
        <rect width="65" height="65" fill="white" />
        <path d="M5.03594 24.5039L-3.64844 71.36H55.8336L47.1492 24.5039H5.03594Z" fill="#D1D1D1" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-3.64844 66.9655H55.8336L47.1492 20.1094H5.03594L-3.64844 66.9655ZM-1.26432 64.9822H53.4495L45.5003 22.0927H6.68486L-1.26432 64.9822Z"
          fill="#292929"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.0566 17.5027C15.0566 11.3484 20.0395 6.35938 26.1861 6.35938C32.3328 6.35938 37.3156 11.3484 37.3156 17.5027V28.1507H35.3371V17.5027C35.3371 12.4425 31.24 8.34041 26.1861 8.34041C21.1322 8.34041 17.0352 12.4425 17.0352 17.5027V28.1507H15.0566V17.5027Z"
          fill="#292929"
        />
        <circle cx="15.9452" cy="28.9472" r="2.47842" fill="#292929" />
        <circle cx="36.3339" cy="28.9472" r="2.47842" fill="#292929" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M26.1867 55.2719C31.3003 55.2719 35.4457 51.1265 35.4457 46.0129C35.4457 40.8993 31.3003 36.7539 26.1867 36.7539C21.0731 36.7539 16.9277 40.8993 16.9277 46.0129C16.9277 51.1265 21.0731 55.2719 26.1867 55.2719ZM26.1867 41.0941L30.1879 45.0952L28.5511 46.732L27.3441 45.525V50.6878H25.0294V45.525L23.8224 46.732L22.1856 45.0952L26.1867 41.0941Z"
          fill="#292929"
        />
      </g>
      <defs>
        <clipPath id="clip0_940_3009">
          <rect width="65" height="65" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default IconSell;
