import { Box, Divider } from '@material-ui/core';
import classNames from 'classnames';
import React, { FC, useContext, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import {
  FormattedMessage,
  IconUser,
  IconUserFilled,
  InlineTextButton,
  Menu,
  MenuContent,
  MenuItem,
  MenuLabel,
  NamedLink,
} from '..';
import { AboutPageTab } from '../../containers/AboutPage/aboutPageUtils';
import { useShopConfig } from '../../hooks/shopConfig';
import { useEnabledCustomerExperiences } from '../../hooks/useEnabledCustomerExperiences';
import { useFeatureFlags } from '../../hooks/useFeatureFlags';
import { useLogout } from '../../hooks/useLogout';
import { usePageTitles } from '../../hooks/usePageTitles';
import { useCurrentUserPermissions } from '../../hooks/useUserPermissions';
import { PAGE_TO_PATH } from '../../routeConfiguration';
import { Feature } from '../../util/featureFlags';
import TypographyWrapper from '../TypographyWrapper/TypographyWrapper';
import TopbarTransparencyContext from '../../context/TopbarTransparencyContext';
import { EmailSubscribeSource } from '../../types/apollo/generated/types.generated';
import { setSavedSearchSource } from '../../ducks/user.duck';
import { useAllowedFindItemMethods } from '../../hooks/useAllowedFindItemMethods';
import { ModalType, setActiveModal } from '../../ducks/modal.duck';
import { getBasePath } from '../../util/routes';
import css from './TopbarDesktop.module.css';

interface ProfileMenuProps {
  isAuthenticatedOnClientSide: boolean;
}

const currentPathClass = (path: string, currentPath?: string) =>
  currentPath === path ? css.currentPage : null;

const YourPurchases: FC = () => {
  const { purchasesTitle } = usePageTitles();
  const location = useLocation();
  const { pathname } = location;

  return (
    <NamedLink
      className={classNames(
        css.menuLink,
        currentPathClass(PAGE_TO_PATH.ManagePurchasesPage, pathname)
      )}
      name="ManagePurchasesPage"
    >
      <span className={css.menuItemBorder} />
      <TypographyWrapper variant="body1">{purchasesTitle}</TypographyWrapper>
    </NamedLink>
  );
};

const YourSales: FC = () => {
  const { salesTitle } = usePageTitles();
  const location = useLocation();
  const { pathname } = location;

  return (
    <NamedLink
      className={classNames(css.menuLink, currentPathClass(PAGE_TO_PATH.ManageSalesPage, pathname))}
      name="ManageSalesPage"
    >
      <span className={css.menuItemBorder} />
      <TypographyWrapper variant="body1">{salesTitle}</TypographyWrapper>
    </NamedLink>
  );
};

const YourListings: FC = () => {
  const { listingsTitle } = usePageTitles();
  const location = useLocation();
  const { pathname } = location;

  return (
    <NamedLink
      className={classNames(
        css.menuLink,
        currentPathClass(PAGE_TO_PATH.ManageListingsPage, pathname)
      )}
      name="ManageListingsPage"
    >
      <span className={css.menuItemBorder} />
      <TypographyWrapper variant="body1">{listingsTitle}</TypographyWrapper>
    </NamedLink>
  );
};

const Closet: FC = () => {
  const { closetTitle } = usePageTitles();
  const location = useLocation();
  const { pathname } = location;

  return (
    <NamedLink
      className={classNames(
        css.menuLink,
        currentPathClass(PAGE_TO_PATH.ManageClosetPage, pathname)
      )}
      name="ManageClosetPage"
    >
      <span className={css.menuItemBorder} />
      <TypographyWrapper variant="body1">{closetTitle}</TypographyWrapper>
    </NamedLink>
  );
};

const YourTradeIns: FC = () => {
  const { tradeInsTitle } = usePageTitles();
  const location = useLocation();
  const { pathname } = location;

  return (
    <NamedLink
      className={classNames(
        css.menuLink,
        currentPathClass(PAGE_TO_PATH.ManageTradeInsPage, pathname)
      )}
      name="ManageTradeInsPage"
    >
      <span className={css.menuItemBorder} />
      <TypographyWrapper variant="body1">{tradeInsTitle}</TypographyWrapper>
    </NamedLink>
  );
};

const ProfileSettings: FC = () => {
  const location = useLocation();
  const { pathname } = location;

  return (
    <NamedLink
      className={classNames(
        css.menuLink,
        currentPathClass(PAGE_TO_PATH.ProfileSettingsPage, pathname)
      )}
      name="ProfileSettingsPage"
    >
      <span className={css.menuItemBorder} />
      <TypographyWrapper variant="body1">
        <FormattedMessage id="TopbarDesktop.profileSettingsLink" />
      </TypographyWrapper>
    </NamedLink>
  );
};

const AccountSettings: FC = () => {
  const location = useLocation();
  const { pathname } = location;
  const basePath = getBasePath(pathname);

  return (
    <NamedLink
      className={classNames(
        css.menuLink,
        currentPathClass(PAGE_TO_PATH.AccountSettingsPage, basePath)
      )}
      name="AccountSettingsPage"
    >
      <span className={css.menuItemBorder} />
      <TypographyWrapper variant="body1">
        <FormattedMessage id="TopbarDesktop.accountSettingsLink" />
      </TypographyWrapper>
    </NamedLink>
  );
};

const NotificationSettings: FC = () => {
  const location = useLocation();
  const { pathname } = location;

  return (
    <NamedLink
      className={classNames(
        css.menuLink,
        currentPathClass(PAGE_TO_PATH.NotificationSettingsPage, pathname)
      )}
      name="NotificationSettingsPage"
    >
      <span className={css.menuItemBorder} />
      <TypographyWrapper variant="body1">Notification Settings</TypographyWrapper>
    </NamedLink>
  );
};

const InSearchOf: FC = () => {
  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch(setActiveModal(ModalType.ISO));
    dispatch(setSavedSearchSource({ source: EmailSubscribeSource.ProfileDropdownIso }));
  };

  return (
    <Box className={css.menuLink}>
      <InlineTextButton style={{ textDecoration: 'none' }} onClick={handleClick}>
        <span className={css.menuItemBorder} />
        <TypographyWrapper component="span" variant="body1">
          Submit An Item Request
        </TypographyWrapper>
      </InlineTextButton>
    </Box>
  );
};

const AboutShop: FC = () => {
  const { treetShopName } = useShopConfig();
  const location = useLocation();
  const { pathname } = location;
  const pagePath = `${PAGE_TO_PATH.AboutBasePage}/${AboutPageTab.Info}`;

  return (
    <NamedLink
      className={classNames(css.menuLink, currentPathClass(pagePath, pathname))}
      params={{ tab: AboutPageTab.Info }}
      name="AboutPage"
    >
      <span className={css.menuItemBorder} />
      <TypographyWrapper variant="body1">About {treetShopName}</TypographyWrapper>
    </NamedLink>
  );
};

const AboutTreet: FC = () => {
  const { isTradeInOnly } = useEnabledCustomerExperiences();
  const location = useLocation();
  const { pathname } = location;

  const pageName = !isTradeInOnly ? 'AboutPage' : 'AboutTreetPage';
  const pagePath = !isTradeInOnly
    ? `${PAGE_TO_PATH.AboutBasePage}/${AboutPageTab.AboutTreet}`
    : PAGE_TO_PATH.AboutTreetPage;
  const pageParams = !isTradeInOnly ? { params: { tab: AboutPageTab.AboutTreet } } : {};

  return (
    <NamedLink
      className={classNames(css.menuLink, currentPathClass(pagePath, pathname))}
      name={pageName}
      {...pageParams}
    >
      <span className={css.menuItemBorder} />
      <TypographyWrapper variant="body1">About Treet</TypographyWrapper>
    </NamedLink>
  );
};

const Logout: FC = () => {
  const { handleLogout } = useLogout();

  return (
    <Box mb={2} className={css.logoutButtonWrapper}>
      <InlineTextButton rootClassName={css.logoutButton} onClick={handleLogout}>
        <span className={css.menuItemBorder} />
        <TypographyWrapper component="span" variant="body1">
          <FormattedMessage id="TopbarDesktop.logout" />
        </TypographyWrapper>
      </InlineTextButton>
    </Box>
  );
};

const ProfileMenu: FC<ProfileMenuProps> = (props: ProfileMenuProps) => {
  const { isAuthenticatedOnClientSide } = props;
  const isTransparentTopbar = useContext(TopbarTransparencyContext);
  const isISOEnabled = useFeatureFlags(Feature.InSearchOf);
  const isNotificationSettingsPageEnabled = useFeatureFlags(Feature.FollowingPage);
  const { allowBuy, allowMarketplace } = useEnabledCustomerExperiences();
  const { canOwnTradeIns, canOwnListingsForSale, isTradeInAdmin, canViewPage } =
    useCurrentUserPermissions();

  const [isProfileMenuOpen, setIsProfileMenuOpen] = useState<boolean>(false);

  const { shouldAllowSearch: hasProductCatalog } = useAllowedFindItemMethods();

  if (!isAuthenticatedOnClientSide) return null;

  const hasManageSectionLinks =
    allowBuy || isTradeInAdmin || canOwnTradeIns || canOwnListingsForSale;

  const profileMenuId = 'profile-menu';

  return (
    <Menu
      isOpen={isProfileMenuOpen}
      onToggleActive={(isActive: boolean) => setIsProfileMenuOpen(isActive)}
      className={css.sectionLink}
    >
      <MenuLabel
        ariaLabel={`${isProfileMenuOpen ? 'Close' : 'Open'} Profile Menu`}
        contentId={profileMenuId}
        className={classNames(css.profileMenuLabel, {
          [css.transparentTopbarForeground]: isTransparentTopbar,
        })}
        isOpenClassName={classNames(css.profileMenuIsOpen, {
          [css.transparentTopbarForeground]: isTransparentTopbar,
        })}
      >
        {isProfileMenuOpen ? <IconUserFilled /> : <IconUser />}
      </MenuLabel>
      <MenuContent
        id={profileMenuId}
        className={css.profileMenuContent}
        style={{ right: 0, left: 'unset' }}
      >
        {canViewPage.ManagePurchasesPage && (
          <MenuItem key="ManagePurchasesPage">
            <YourPurchases />
          </MenuItem>
        )}
        {canViewPage.ManageSalesPage && (
          <MenuItem key="ManageSalesPage">
            <YourSales />
          </MenuItem>
        )}
        {canViewPage.ManageClosetPage && (
          <MenuItem key="ManageClosetPage">
            <Closet />
          </MenuItem>
        )}
        {canViewPage.ManageListingsPage && (
          <MenuItem key="ManageListingsPage">
            <YourListings />
          </MenuItem>
        )}
        {canViewPage.ManageTradeInsPage && (
          <MenuItem key="ManageTradeInsPage">
            <YourTradeIns />
          </MenuItem>
        )}
        {hasManageSectionLinks && (
          <MenuItem key="dividerSettings">
            <Box my={2}>
              <Divider />
            </Box>
          </MenuItem>
        )}
        {canViewPage.ProfileSettingsPage && (
          <MenuItem key="ProfileSettingsPage">
            <ProfileSettings />
          </MenuItem>
        )}
        <MenuItem key="AccountSettingsPage">
          <AccountSettings />
        </MenuItem>
        {isNotificationSettingsPageEnabled && canViewPage.NotificationSettingsPage && (
          <MenuItem key="NotificationSettingsPage">
            <NotificationSettings />
          </MenuItem>
        )}
        <MenuItem key="dividerInfo">
          <Box my={2}>
            <Divider />
          </Box>
        </MenuItem>
        {isISOEnabled && allowMarketplace && hasProductCatalog && (
          <MenuItem key="InSearchOfModal">
            <InSearchOf />
          </MenuItem>
        )}
        {isISOEnabled && allowMarketplace && hasProductCatalog && (
          <MenuItem key="dividerInSearchOf">
            <Box my={2}>
              <Divider />
            </Box>
          </MenuItem>
        )}
        <MenuItem key="AboutPage">
          <AboutShop />
        </MenuItem>
        <MenuItem key="AboutTreetPage">
          <AboutTreet />
        </MenuItem>
        <MenuItem key="dividerLogout">
          <Box my={2}>
            <Divider />
          </Box>
        </MenuItem>
        <MenuItem key="logout">
          <Logout />
        </MenuItem>
      </MenuContent>
    </Menu>
  );
};

export default ProfileMenu;
