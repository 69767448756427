import React from 'react';
import { useIconColor } from '../../../hooks/useIconColor';

interface IconCheckOrderSuccessProps {
  className?: string;
  color?: string;
}

const IconCheckOrderSuccess: React.FC<IconCheckOrderSuccessProps> = (props) => {
  const { className, color } = props;

  const { iconColor } = useIconColor();
  const fillColor = color || iconColor;

  return (
    <svg
      style={{ fill: 'none' }}
      className={className}
      width="64"
      height="64"
      viewBox="0 0 64 64"
      fill={fillColor}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M64 32C64 49.6731 49.6731 64 32 64C14.3269 64 0 49.6731 0 32C0 14.3269 14.3269 0 32 0C49.6731 0 64 14.3269 64 32Z"
        fill="#F8F8F8"
      />
      <circle cx="32" cy="33.8824" r="11.7647" fill="#D1D1D1" />
      <circle cx="32" cy="32.0001" r="11.2941" stroke="#292929" strokeWidth="0.941176" />
      <path
        d="M28.4706 32.8244L30.4699 34.8235L35.5294 29.7646"
        stroke="#292929"
        strokeWidth="0.941176"
      />
    </svg>
  );
};

export default IconCheckOrderSuccess;
