/* eslint-disable import/prefer-default-export */
import { getBrandDirectFilterConfig } from '../filters/brandDirect';
import { getConditionsFilterConfig } from '../filters/condition';
import { getSizeFilterConfig } from '../filters/size';
import { getDefaultShopConfig } from './default';
import * as custom from '../filters/marketplace-custom-config';

const nettlestudiosSizes = [
  'XL',
  'S',
  'M',
  'L',
  'XS',
  'XXL',
  'XXXL',
  'XXXXL',
  'XS/S',
  'M/L',
  'OS-',
  'OS',
  'OS+',
];
export const nettlestudiosShopConfig = {
  ...getDefaultShopConfig(),
  filters: [
    ...custom.filters,
    getConditionsFilterConfig(),
    getSizeFilterConfig({
      sizes: nettlestudiosSizes,
    }),
    getBrandDirectFilterConfig(),
  ],
};
