import { defaultClothesConditionsOptions, getConditionsFilterConfig } from '../filters/condition';
import { getDefaultShopConfig } from './default';
import * as custom from '../filters/marketplace-custom-config';
import { getSizeFilterConfigFromOptions } from '../filters/size';

const whimsyandrowSizeOptions = [
  { key: 'XXS', label: 'XXS' },
  { key: 'XS', label: 'XS' },
  { key: 'S', label: 'S' },
  { key: 'M', label: 'M' },
  { key: 'L', label: 'L' },
  { key: 'XL', label: 'XL' },
  { key: '0', label: '0' },
  { key: '2', label: '2' },
  { key: '4', label: '4' },
  { key: '6', label: '6' },
  { key: '8', label: '8' },
  { key: '10', label: '10' },
  { key: '12', label: '12' },
  { key: '24', label: '24' },
  { key: '25', label: '25' },
  { key: '26', label: '26' },
  { key: '27', label: '27' },
  { key: '28', label: '28' },
  { key: '29', label: '29' },
  { key: '30', label: '30' },
  { key: '31', label: '31' },
  { key: '32', label: '32' },
  { key: 'One Size', label: 'One Size' },
];

export const whimsyandrowShopConfig = {
  ...getDefaultShopConfig(),
  filters: [
    ...custom.filters,
    getConditionsFilterConfig({ options: defaultClothesConditionsOptions }),
    getSizeFilterConfigFromOptions({ options: whimsyandrowSizeOptions }),
  ],
};
