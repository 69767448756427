/* eslint-disable import/prefer-default-export */
export const builderTypographyInputs = [
  {
    name: 'variant',
    type: 'string',
    enum: [
      'h1',
      'h2',
      'h3',
      'h4',
      'h5',
      'h6',
      'subtitle1',
      'subtitle2',
      'body1',
      'body2',
      'caption',
      'button',
      'overline',
    ], // matches types of TypographyVariant. There isn't good way to transform string literal type to array type
  },
  {
    name: 'children',
    type: 'text',
  },
  {
    name: 'weight',
    type: 'text',
  },
  {
    name: 'format',
    type: 'text',
  },
  {
    name: 'typographyOverrides',
    type: 'object',
    defaultValue: {},
    subFields: [
      {
        name: 'display',
        type: 'text',
        enum: ['initial', 'block', 'inline'],
      },
      {
        name: 'style',
        type: 'object',
        defaultValue: {},
        subFields: [
          {
            name: 'color',
            type: 'text',
          },
          {
            name: 'textTransform',
            type: 'text',
          },
        ],
      },
    ],
  },
];
