import { getBrandDirectFilterConfig } from '../filters/brandDirect';
import {
  Conditions,
  defaultClothesConditionsOptions,
  getConditionsFilterConfig,
} from '../filters/condition';
import { getSizeFilterConfig } from '../filters/size';
import { getDefaultShopConfig } from './default';
import * as custom from '../filters/marketplace-custom-config';

export const teekiboutiqueSizes = [
  'Extra Small',
  'Extra Small / Small',
  'Small',
  'Medium',
  'Medium / Large',
  'Large',
];

const teekiboutiqueConditions = [
  ...defaultClothesConditionsOptions.slice(0, 2),
  // Minor Defect requested to be at index 2, between NWOT and Excellent.
  {
    key: Conditions.MinorDefect,
    label: 'Minor Defect',
    description:
      'This item is NWT or NWOT condition but may have a factory defect such as printing flaw, stain, stretched fabric, faded color or uneven stitching',
  },
  ...defaultClothesConditionsOptions.slice(2),
];

export const teekiboutiqueShopConfig = {
  ...getDefaultShopConfig(),
  filters: [
    ...custom.filters,
    getConditionsFilterConfig({ options: teekiboutiqueConditions }),
    getBrandDirectFilterConfig(),
    getSizeFilterConfig({ sizes: teekiboutiqueSizes }),
  ],
};
