/* eslint-disable import/no-cycle */
import { isEmpty } from 'lodash';
import { denormalisedResponseEntities, ensureOwnListing } from '../util/data';
import {
  deleteSuppression as deleteSuppressionApiRequest,
  queueAbandonedBagEmail,
  queueUpdateFavoriteListings,
  retrieveStripeAccount,
  subscribeEmail as subscribeEmailApiRequest,
} from '../util/api';
import { storableError } from '../util/errors';
import { transitionsToRequested } from '../util/transaction';
import { LISTING_STATE_DRAFT } from '../util/types';
import * as log from '../util/log';
import * as analytics from '../analytics/ga4analytics';
import * as intercom from '../util/intercom';
import * as heap from '../util/heap';
import { authInfo } from './Auth.duck';
import { stripeAccountCreateSuccess } from './stripeConnectAccount.duck';
import { fetchShoppingBagListings, removeShoppingBagListings } from './shoppingBag.duck';
import { RequestStatus } from '../types/requestStatus';
import { handle } from '../util/helpers';
import {
  Cadence,
  namedOperations,
  UpsertGeneralSavedSearchDocument,
  UpsertIsoSavedSearchDocument,
} from '../types/apollo/generated/types.generated';
import { apolloClient } from '../apollo';
import SENDGRID_CONTACT_LISTS from '../util/sendgrid';
import * as refiner from '../util/refiner';
import { formatToSharetribeStripeAccount } from '../util/stripe';
import {
  getFavoritedListingsFromPG,
  removeFavoriteListingInPG,
  setFavoriteListingInPG,
} from '../util/favoritedListingHelpers';
import {
  formatPGCartItems,
  getCartListingsFromPG,
  removeCartListingsInPG,
  setCartListingInPG,
} from '../util/cartListingHelpers';

// ================ Action types ================ //

export const CURRENT_USER_SHOW_REQUEST = 'app/user/CURRENT_USER_SHOW_REQUEST';
export const CURRENT_USER_SHOW_SUCCESS = 'app/user/CURRENT_USER_SHOW_SUCCESS';
export const CURRENT_USER_SHOW_ERROR = 'app/user/CURRENT_USER_SHOW_ERROR';

export const CLEAR_CURRENT_USER = 'app/user/CLEAR_CURRENT_USER';

export const FETCH_CURRENT_USER_HAS_LISTINGS_REQUEST =
  'app/user/FETCH_CURRENT_USER_HAS_LISTINGS_REQUEST';
export const FETCH_CURRENT_USER_HAS_LISTINGS_SUCCESS =
  'app/user/FETCH_CURRENT_USER_HAS_LISTINGS_SUCCESS';
export const FETCH_CURRENT_USER_HAS_LISTINGS_ERROR =
  'app/user/FETCH_CURRENT_USER_HAS_LISTINGS_ERROR';

export const FETCH_CURRENT_USER_NOTIFICATIONS_REQUEST =
  'app/user/FETCH_CURRENT_USER_NOTIFICATIONS_REQUEST';
export const FETCH_CURRENT_USER_NOTIFICATIONS_SUCCESS =
  'app/user/FETCH_CURRENT_USER_NOTIFICATIONS_SUCCESS';
export const FETCH_CURRENT_USER_NOTIFICATIONS_ERROR =
  'app/user/FETCH_CURRENT_USER_NOTIFICATIONS_ERROR';

export const FETCH_CURRENT_USER_HAS_ORDERS_REQUEST =
  'app/user/FETCH_CURRENT_USER_HAS_ORDERS_REQUEST';
export const FETCH_CURRENT_USER_HAS_ORDERS_SUCCESS =
  'app/user/FETCH_CURRENT_USER_HAS_ORDERS_SUCCESS';
export const FETCH_CURRENT_USER_HAS_ORDERS_ERROR = 'app/user/FETCH_CURRENT_USER_HAS_ORDERS_ERROR';

// Favoriting / unfavoriting a listing
export const FAVORITE_LISTING_ERROR = 'app/user/FAVORITE_LISTING_ERROR';
export const FAVORITED_LISTING_IDS = 'app/user/FAVORITED_LISTING_IDS';

// Adding a listing to shopping bag
export const ADD_TO_SHOPPING_BAG_ERROR = 'app/user/ADD_TO_SHOPPING_BAG_ERROR';
export const CART_LISTING_IDS = 'app/user/CART_LISTING_IDS';

// Remove listing(s) from shopping bag
export const REMOVE_FROM_SHOPPING_BAG_ERROR = 'app/user/REMOVE_FROM_SHOPPING_BAG_ERROR';

// Saving address
export const SAVE_ADDRESS_REQUEST = 'app/user/SAVE_ADDRESS_REQUEST';
export const SAVE_ADDRESS_SUCCESS = 'app/user/SAVE_ADDRESS_SUCCESS';
export const SAVE_ADDRESS_ERROR = 'app/user/SAVE_ADDRESS_ERROR';

export const SEND_VERIFICATION_EMAIL_REQUEST = 'app/user/SEND_VERIFICATION_EMAIL_REQUEST';
export const SEND_VERIFICATION_EMAIL_SUCCESS = 'app/user/SEND_VERIFICATION_EMAIL_SUCCESS';
export const SEND_VERIFICATION_EMAIL_ERROR = 'app/user/SEND_VERIFICATION_EMAIL_ERROR';

// General subscribe to our newsletter
export const SUBSCRIBE_REQUEST = 'app/user/SUBSCRIBE_REQUEST';
export const SUBSCRIBE_SUCCESS = 'app/user/SUBSCRIBE_SUCCESS';
export const SUBSCRIBE_ERROR = 'app/user/SUBSCRIBE_ERROR';

// Delete suppression from suppression group in Sendgrid
export const DELETE_SUPPRESSION_REQUEST = 'app/user/DELETE_SUPPRESSION_REQUEST';
export const DELETE_SUPPRESSION_SUCCESS = 'app/user/DELETE_SUPPRESSION_SUCCESS';
export const DELETE_SUPPRESSION_ERROR = 'app/user/DELETE_SUPPRESSION_ERROR';

// Subscribe when a specific item gets listed
export const SUBSCRIBE_ISO_ITEM_REQUEST = 'app/user/SUBSCRIBE_ISO_ITEM_REQUEST';
export const SUBSCRIBE_ISO_ITEM_SUCCESS = 'app/user/SUBSCRIBE_ISO_ITEM_SUCCESS';
export const SUBSCRIBE_ISO_ITEM_ERROR = 'app/user/SUBSCRIBE_ISO_ITEM_ERROR';

// Subscribe to a saved search (size)
export const UPDATE_GENERAL_SAVED_SEARCH_SUBSCRIPTION_REQUEST =
  'app/user/UPDATE_GENERAL_SAVED_SEARCH_SUBSCRIPTION_REQUEST';
export const UPDATE_GENERAL_SAVED_SEARCH_SUBSCRIPTION_SUCCESS =
  'app/user/UPDATE_GENERAL_SAVED_SEARCH_SUBSCRIPTION_SUCCESS';
export const UPDATE_GENERAL_SAVED_SEARCH_SUBSCRIPTION_ERROR =
  'app/user/UPDATE_GENERAL_SAVED_SEARCH_SUBSCRIPTION_ERROR';
export const SET_SAVED_SEARCH_SOURCE = 'app/user/SET_SAVED_SEARCH_SOURCE';

// Subscribe to a saved search (ISO)
export const UPDATE_ISO_SAVED_SEARCH_SUBSCRIPTION_REQUEST =
  'app/user/UPDATE_ISO_SAVED_SEARCH_SUBSCRIPTION_REQUEST';
export const UPDATE_ISO_SAVED_SEARCH_SUBSCRIPTION_SUCCESS =
  'app/user/UPDATE_ISO_SAVED_SEARCH_SUBSCRIPTION_SUCCESS';
export const UPDATE_ISO_SAVED_SEARCH_SUBSCRIPTION_ERROR =
  'app/user/UPDATE_ISO_SAVED_SEARCH_SUBSCRIPTION_ERROR';

// ================ Reducer ================ //

const mergeCurrentUser = (oldCurrentUser, newCurrentUser) => {
  const { id: oId, type: oType, attributes: oAttr, ...oldRelationships } = oldCurrentUser || {};
  const { id, type, attributes, ...relationships } = newCurrentUser || {};

  // Passing null will remove currentUser entity.
  // Only relationships are merged.
  // TODO figure out if sparse fields handling needs a better handling.
  return newCurrentUser === null
    ? null
    : oldCurrentUser === null
    ? newCurrentUser
    : { id, type, attributes, ...oldRelationships, ...relationships };
};

const initialState = {
  currentUser: null,
  currentUserShowStatus: RequestStatus.Ready,
  currentUserShowError: null,
  currentUserHasListings: false,
  currentUserHasListingsError: null,
  currentUserNotificationCount: 0,
  currentUserNotificationCountError: null,
  currentUserHasOrders: null, // This is not fetched unless unverified emails exist
  currentUserHasOrdersError: null,
  favoriteListingError: null,
  favoritedListingIds: [],
  cartListingIds: [],
  addToShoppingBagError: null,
  removeFromShoppingBagError: null,
  saveAddressInProgress: null,
  saveAddressError: null,
  sendVerificationEmailInProgress: false,
  sendVerificationEmailError: null,
  subscribeInProgress: false,
  subscribedEmail: null,
  subscribeError: null,
  deleteSuppressionStatus: RequestStatus.Ready,
  deleteSuppressionError: null,
  updateGeneralSavedSearchProgress: false,
  updateGeneralSavedSearchError: null,
  updateISOSavedSearchProgress: false,
  updateISOSavedSearchError: null,
  savedSearchSource: null,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;

  switch (type) {
    case CURRENT_USER_SHOW_REQUEST:
      return { ...state, currentUserShowStatus: RequestStatus.Pending, currentUserShowError: null };
    case CURRENT_USER_SHOW_SUCCESS:
      return {
        ...state,
        currentUserShowStatus: RequestStatus.Success,
        currentUser: mergeCurrentUser(state.currentUser, payload),
      };
    case CURRENT_USER_SHOW_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return {
        ...state,
        currentUserShowStatus: RequestStatus.Error,
        currentUserShowError: payload,
      };

    case CLEAR_CURRENT_USER:
      return {
        ...state,
        currentUser: null,
        currentUserShowError: null,
        currentUserHasListings: false,
        currentUserHasListingsError: null,
        currentUserNotificationCount: 0,
        currentUserNotificationCountError: null,
      };

    case FETCH_CURRENT_USER_HAS_LISTINGS_REQUEST:
      return { ...state, currentUserHasListingsError: null };
    case FETCH_CURRENT_USER_HAS_LISTINGS_SUCCESS:
      return { ...state, currentUserHasListings: payload.hasListings };
    case FETCH_CURRENT_USER_HAS_LISTINGS_ERROR:
      console.error(payload); // eslint-disable-line
      return { ...state, currentUserHasListingsError: payload };

    case FETCH_CURRENT_USER_NOTIFICATIONS_REQUEST:
      return { ...state, currentUserNotificationCountError: null };
    case FETCH_CURRENT_USER_NOTIFICATIONS_SUCCESS:
      return { ...state, currentUserNotificationCount: payload.transactions.length };
    case FETCH_CURRENT_USER_NOTIFICATIONS_ERROR:
      console.error(payload); // eslint-disable-line
      return { ...state, currentUserNotificationCountError: payload };

    case FETCH_CURRENT_USER_HAS_ORDERS_REQUEST:
      return { ...state, currentUserHasOrdersError: null };
    case FETCH_CURRENT_USER_HAS_ORDERS_SUCCESS:
      return { ...state, currentUserHasOrders: payload.hasOrders };
    case FETCH_CURRENT_USER_HAS_ORDERS_ERROR:
      console.error(payload); // eslint-disable-line
      return { ...state, currentUserHasOrdersError: payload };

    case FAVORITE_LISTING_ERROR:
      return {
        ...state,
        favoriteListingError: payload,
      };

    case FAVORITED_LISTING_IDS:
      return {
        ...state,
        favoritedListingIds: payload,
      };

    case ADD_TO_SHOPPING_BAG_ERROR:
      return {
        ...state,
        addToShoppingBagError: payload,
      };
    case CART_LISTING_IDS:
      return {
        ...state,
        cartListingIds: payload,
      };

    case REMOVE_FROM_SHOPPING_BAG_ERROR:
      return {
        ...state,
        removeFromShoppingBagError: payload,
      };

    case SAVE_ADDRESS_REQUEST:
      return {
        ...state,
        saveAddressInProgress: true,
        saveAddressError: null,
      };
    case SAVE_ADDRESS_SUCCESS:
      return {
        ...state,
        saveAddressInProgress: false,
      };
    case SAVE_ADDRESS_ERROR:
      return {
        ...state,
        saveAddressInProgress: false,
        saveAddressError: payload,
      };

    case SEND_VERIFICATION_EMAIL_REQUEST:
      return {
        ...state,
        sendVerificationEmailInProgress: true,
        sendVerificationEmailError: null,
      };
    case SEND_VERIFICATION_EMAIL_SUCCESS:
      return {
        ...state,
        sendVerificationEmailInProgress: false,
      };
    case SEND_VERIFICATION_EMAIL_ERROR:
      return {
        ...state,
        sendVerificationEmailInProgress: false,
        sendVerificationEmailError: payload,
      };

    case SUBSCRIBE_REQUEST:
      return {
        ...state,
        subscribeInProgress: true,
        subscribeError: null,
      };
    case SUBSCRIBE_SUCCESS: {
      const { email } = payload;
      return { ...state, subscribeInProgress: false, subscribedEmail: email };
    }
    case SUBSCRIBE_ERROR:
      return { ...state, subscribeInProgress: false, subscribeError: payload };

    case DELETE_SUPPRESSION_REQUEST:
      return {
        ...state,
        deleteSuppressionStatus: RequestStatus.Pending,
        deleteSuppressionError: null,
      };
    case DELETE_SUPPRESSION_SUCCESS:
      return { ...state, deleteSuppressionStatus: RequestStatus.Success };
    case DELETE_SUPPRESSION_ERROR:
      return {
        ...state,
        deleteSuppressionStatus: RequestStatus.Error,
        deleteSuppressionError: payload,
      };
    case UPDATE_GENERAL_SAVED_SEARCH_SUBSCRIPTION_REQUEST:
      return {
        ...state,
        updateGeneralSavedSearchProgress: true,
        updateGeneralSavedSearchError: null,
      };
    case UPDATE_GENERAL_SAVED_SEARCH_SUBSCRIPTION_SUCCESS: {
      return { ...state, updateGeneralSavedSearchProgress: false };
    }
    case UPDATE_GENERAL_SAVED_SEARCH_SUBSCRIPTION_ERROR:
      return {
        ...state,
        updateGeneralSavedSearchProgress: false,
        updateGeneralSavedSearchError: payload,
      };
    case UPDATE_ISO_SAVED_SEARCH_SUBSCRIPTION_REQUEST:
      return {
        ...state,
        updateISOSavedSearchProgress: true,
        updateISOSavedSearchError: null,
      };
    case UPDATE_ISO_SAVED_SEARCH_SUBSCRIPTION_SUCCESS: {
      return { ...state, updateISOSavedSearchProgress: false };
    }
    case UPDATE_ISO_SAVED_SEARCH_SUBSCRIPTION_ERROR:
      return {
        ...state,
        updateISOSavedSearchProgress: false,
        updateISOSavedSearchError: payload,
      };
    case SET_SAVED_SEARCH_SOURCE: {
      const { source } = payload;
      return { ...state, savedSearchSource: source };
    }
    default:
      return state;
  }
}

// ================ Selectors ================ //

export const hasCurrentUserErrors = (state) => {
  const { user } = state;
  return (
    user.currentUserShowError ||
    user.currentUserHasListingsError ||
    user.currentUserNotificationCountError ||
    user.currentUserHasOrdersError
  );
};

export const verificationSendingInProgress = (state) => state.user.sendVerificationEmailInProgress;

// ================ Action creators ================ //

export const currentUserShowRequest = () => ({ type: CURRENT_USER_SHOW_REQUEST });

export const currentUserShowSuccess = (user) => ({
  type: CURRENT_USER_SHOW_SUCCESS,
  payload: user,
});

export const currentUserShowError = (e) => ({
  type: CURRENT_USER_SHOW_ERROR,
  payload: e,
  error: true,
});

export const clearCurrentUser = () => ({ type: CLEAR_CURRENT_USER });

const fetchCurrentUserHasListingsRequest = () => ({
  type: FETCH_CURRENT_USER_HAS_LISTINGS_REQUEST,
});

export const fetchCurrentUserHasListingsSuccess = (hasListings) => ({
  type: FETCH_CURRENT_USER_HAS_LISTINGS_SUCCESS,
  payload: { hasListings },
});

const fetchCurrentUserHasListingsError = (e) => ({
  type: FETCH_CURRENT_USER_HAS_LISTINGS_ERROR,
  error: true,
  payload: e,
});

const fetchCurrentUserNotificationsRequest = () => ({
  type: FETCH_CURRENT_USER_NOTIFICATIONS_REQUEST,
});

export const fetchCurrentUserNotificationsSuccess = (transactions) => ({
  type: FETCH_CURRENT_USER_NOTIFICATIONS_SUCCESS,
  payload: { transactions },
});

const fetchCurrentUserNotificationsError = (e) => ({
  type: FETCH_CURRENT_USER_NOTIFICATIONS_ERROR,
  error: true,
  payload: e,
});

const fetchCurrentUserHasOrdersRequest = () => ({
  type: FETCH_CURRENT_USER_HAS_ORDERS_REQUEST,
});

export const fetchCurrentUserHasOrdersSuccess = (hasOrders) => ({
  type: FETCH_CURRENT_USER_HAS_ORDERS_SUCCESS,
  payload: { hasOrders },
});

const fetchCurrentUserHasOrdersError = (e) => ({
  type: FETCH_CURRENT_USER_HAS_ORDERS_ERROR,
  error: true,
  payload: e,
});

export const favoritedListingIdsUpdated = (listingIds) => ({
  type: FAVORITED_LISTING_IDS,
  payload: listingIds,
});

const addToShoppingBagError = (e) => ({
  type: ADD_TO_SHOPPING_BAG_ERROR,
  error: true,
  payload: e,
});

export const cartListingIdsUpdated = (listingIds) => ({
  type: CART_LISTING_IDS,
  payload: listingIds,
});

const removeFromShoppingBagError = (e) => ({
  type: REMOVE_FROM_SHOPPING_BAG_ERROR,
  error: true,
  payload: e,
});

const saveAddressRequest = () => ({
  type: SAVE_ADDRESS_REQUEST,
});

const saveAddressSuccess = () => ({
  type: SAVE_ADDRESS_SUCCESS,
});

const saveAddressError = (e) => ({
  type: SAVE_ADDRESS_ERROR,
  error: true,
  payload: e,
});

export const sendVerificationEmailRequest = () => ({
  type: SEND_VERIFICATION_EMAIL_REQUEST,
});

export const sendVerificationEmailSuccess = () => ({
  type: SEND_VERIFICATION_EMAIL_SUCCESS,
});

export const sendVerificationEmailError = (e) => ({
  type: SEND_VERIFICATION_EMAIL_ERROR,
  error: true,
  payload: e,
});

export const subscribeRequest = () => ({
  type: SUBSCRIBE_REQUEST,
});

export const subscribeSuccess = (payload) => ({
  type: SUBSCRIBE_SUCCESS,
  payload,
});

export const subscribeError = (e) => ({
  type: SUBSCRIBE_ERROR,
  error: true,
  payload: e,
});

export const deleteSuppressionRequest = () => ({ type: DELETE_SUPPRESSION_REQUEST });

export const deleteSuppressionSuccess = () => ({ type: DELETE_SUPPRESSION_SUCCESS });

export const deleteSuppressionError = (e) => ({
  type: DELETE_SUPPRESSION_ERROR,
  error: true,
  payload: e,
});

export const updateGeneralSavedSearchSubscriptionRequest = () => ({
  type: UPDATE_GENERAL_SAVED_SEARCH_SUBSCRIPTION_REQUEST,
});

export const updateGeneralSavedSearchSubscriptionSuccess = () => ({
  type: UPDATE_GENERAL_SAVED_SEARCH_SUBSCRIPTION_SUCCESS,
});

export const updateGeneralSavedSearchSubscriptionError = (e) => ({
  type: UPDATE_GENERAL_SAVED_SEARCH_SUBSCRIPTION_ERROR,
  error: true,
  payload: e,
});

export const updateISOSavedSearchSubscriptionRequest = () => ({
  type: UPDATE_ISO_SAVED_SEARCH_SUBSCRIPTION_REQUEST,
});

export const updateISOSavedSearchSubscriptionSuccess = () => ({
  type: UPDATE_ISO_SAVED_SEARCH_SUBSCRIPTION_SUCCESS,
});

export const updateISOSavedSearchSubscriptionError = (e) => ({
  type: UPDATE_ISO_SAVED_SEARCH_SUBSCRIPTION_ERROR,
  error: true,
  payload: e,
});

export const setSavedSearchSource = (payload) => ({
  type: SET_SAVED_SEARCH_SOURCE,
  payload,
});

// ================ Thunks ================ //

export const fetchCurrentUserHasListings = () => (dispatch, getState, sdk) => {
  dispatch(fetchCurrentUserHasListingsRequest());
  const { currentUser } = getState().user;

  if (!currentUser) {
    dispatch(fetchCurrentUserHasListingsSuccess(false));
    return Promise.resolve(null);
  }

  const params = {
    // Since we are only interested in if the user has
    // listings, we only need at most one result.
    page: 1,
    per_page: 1,
  };

  return sdk.ownListings
    .query(params)
    .then((response) => {
      const hasListings = response.data.data && response.data.data.length > 0;

      const hasPublishedListings =
        hasListings &&
        ensureOwnListing(response.data.data[0]).attributes.state !== LISTING_STATE_DRAFT;
      dispatch(fetchCurrentUserHasListingsSuccess(!!hasPublishedListings));
    })
    .catch((e) => dispatch(fetchCurrentUserHasListingsError(storableError(e))));
};

export const fetchCurrentUserHasOrders = () => (dispatch, getState, sdk) => {
  dispatch(fetchCurrentUserHasOrdersRequest());

  if (!getState().user.currentUser) {
    dispatch(fetchCurrentUserHasOrdersSuccess(false));
    return Promise.resolve(null);
  }

  const params = {
    only: 'order',
    page: 1,
    per_page: 1,
  };

  return sdk.transactions
    .query(params)
    .then((response) => {
      const hasOrders = response.data.data && response.data.data.length > 0;
      dispatch(fetchCurrentUserHasOrdersSuccess(!!hasOrders));
    })
    .catch((e) => dispatch(fetchCurrentUserHasOrdersError(storableError(e))));
};

// Notificaiton page size is max (100 items on page)
const NOTIFICATION_PAGE_SIZE = 100;

export const fetchCurrentUserNotifications = () => (dispatch, getState, sdk) => {
  dispatch(fetchCurrentUserNotificationsRequest());

  const apiQueryParams = {
    only: 'sale',
    last_transitions: transitionsToRequested,
    page: 1,
    per_page: NOTIFICATION_PAGE_SIZE,
  };

  sdk.transactions
    .query(apiQueryParams)
    .then((response) => {
      const transactions = response.data.data;
      dispatch(fetchCurrentUserNotificationsSuccess(transactions));
    })
    .catch((e) => dispatch(fetchCurrentUserNotificationsError(storableError(e))));
};

/* !!! IMPORTANT READ ME !!!
 *
 * All SDK calls in fetchCurrentUser need to be passed a unique treetId for each shop,
 * so that we don't run into potential browser caching issues across shops.
 * See https://github.com/TreetCo/treet/pull/1088 for more details.
 *
 */
/**
 * Fetches the current user.
 *
 * @param {any|null} [params=null] - The parameters for the request.
 * @returns {Promise} A thunk function that dispatches actions.
 */
export const fetchCurrentUser =
  (params = null) =>
  (dispatch, getState, sdk) => {
    dispatch(currentUserShowRequest());

    const state = getState();
    const { isAuthenticated } = state.Auth;
    const { treetId } = state.initial || '';

    if (!isAuthenticated) {
      // Make sure current user is null
      dispatch(currentUserShowSuccess(null));
      return Promise.resolve({});
    }

    const parameters = params || {
      include: ['profileImage', 'stripeAccount'],
      'fields.image': ['variants.square-small', 'variants.square-small2x'],
      // Pass in a unique treetId for each shop, so that we don't run into potential browser
      // caching issues across shops. See https://github.com/TreetCo/treet/pull/1088
      // for more details.
      subdomain: treetId,
    };

    return sdk.currentUser
      .show(parameters)
      .then(async (response) => {
        const entities = denormalisedResponseEntities(response);
        if (entities.length !== 1) {
          throw new Error('Expected a resource in the sdk.currentUser.show response');
        }
        const currentUser = entities[0];

        // Save stripeAccount to store.stripeConnectAccount.stripeAccount if it exists.
        // For US accounts, the Stripe account is created through Sharetribe and can be accessed
        // via the stripeAccount relationship on the current user. However, for non-US accounts,
        // the Stripe account is created directly through Stripe and must be retrieved via the
        // stored stripeAccountId on the user.
        if (currentUser.stripeAccount) {
          dispatch(stripeAccountCreateSuccess(currentUser.stripeAccount));
        } else if (currentUser.attributes.profile.protectedData?.stripeAccountId) {
          const stripeAccount = await retrieveStripeAccount({
            accountId: currentUser.attributes.profile.protectedData.stripeAccountId,
          });
          dispatch(stripeAccountCreateSuccess(formatToSharetribeStripeAccount(stripeAccount)));
        }

        // set user id for sentry
        log.setUserId(currentUser.id.uuid);
        // set user id for google analytics
        analytics.setUserId(currentUser.id.uuid);
        // set up user for intercom
        intercom.setUser(currentUser);
        // set up heap analytics
        heap.setUserId(currentUser);

        // set up refiner
        refiner.setUser(currentUser);

        return currentUser;
      })
      .then(async (currentUser) => {
        const pgFavoritedListingIds = await getFavoritedListingsFromPG(currentUser, treetId);
        dispatch(favoritedListingIdsUpdated(pgFavoritedListingIds));

        const pgCartListingIds = await getCartListingsFromPG(currentUser, treetId);
        dispatch(cartListingIdsUpdated(pgCartListingIds));

        dispatch(currentUserShowSuccess(currentUser));
        return currentUser;
      })
      .then(async (currentUser) => {
        // Make sure auth info is up to date
        dispatch(authInfo());

        if (state.shoppingBag.fetchShoppingBagListingsStatus === RequestStatus.Ready) {
          // Wait for this to finish before returning in case we're fetching the current user
          // after logging in to add a listing to the shopping bag. If we don't await here,
          // this fetch could finish after we've added the new listing to the shopping bag,
          // which would overwrite the shopping bag state.
          await dispatch(fetchShoppingBagListings());
        }
        return currentUser;
      })
      .catch((e) => {
        // Make sure auth info is up to date
        dispatch(authInfo());
        console.error(JSON.stringify(e));
        log.error(e, 'fetch-current-user-failed', { e });
        dispatch(currentUserShowError(storableError(e)));
      });
  };

export const favoriteListing = (params) => async (dispatch, getState) => {
  const state = getState();
  const { treetId } = getState().initial;
  const { currentUser } = state.user;

  const { listingId } = params;

  const favoriteListingIds = state.user.favoritedListingIds;
  const isUnfavoriting = favoriteListingIds.includes(listingId);

  const favoritedItemParams = {
    sharetribeListingId: listingId,
  };

  const favoritedItemPromise = isUnfavoriting
    ? removeFavoriteListingInPG(favoritedItemParams)
    : setFavoriteListingInPG(favoritedItemParams);

  const [, pgFavoriteError] = await handle(favoritedItemPromise);

  if (!isEmpty(pgFavoriteError?.clientErrors)) {
    log.error(pgFavoriteError, 'update-pg-favorite-items-failed', {
      ...favoritedItemParams,
      sharetribeUserId: currentUser.id.uuid,
    });
  }

  const updatedFavoriteListingIds = isUnfavoriting
    ? favoriteListingIds.filter((id) => id !== listingId)
    : [...favoriteListingIds, listingId];

  dispatch(favoritedListingIdsUpdated(updatedFavoriteListingIds));

  if (!isUnfavoriting) {
    queueUpdateFavoriteListings({
      userId: currentUser.id.uuid,
      favoritedListingIds: updatedFavoriteListingIds,
      treetId,
      listingId,
    });
  }
};

export const addListingToShoppingBag = (params) => async (dispatch, getState) => {
  const state = getState();

  const { treetId } = state.initial;
  const { currentUser } = state.user;

  const cartListingIds = state.user.cartListingIds || [];

  const { listingId, shouldQueueAbandonedBagEmail = false } = params;
  // Don't add the listing if it has already been added so we don't show duplicate listings
  // in the bag
  if (cartListingIds.includes(listingId)) return;

  const cartItemParams = {
    sharetribeListingId: listingId,
  };

  const [pgCartItems, pgCartItemError] = await handle(setCartListingInPG(cartItemParams));

  const pgCartListingIds = formatPGCartItems(pgCartItems?.data.createCartItem);
  dispatch(cartListingIdsUpdated(pgCartListingIds));

  if (pgCartItemError) {
    if (!isEmpty(pgCartItemError?.clientErrors)) {
      log.error(pgCartItemError, 'update-pg-add-to-cart-failed', {
        ...cartItemParams,
        sharetribeUserId: currentUser.id.uuid,
      });
    }
    dispatch(addToShoppingBagError(storableError(pgCartItemError)));
  }

  await dispatch(fetchShoppingBagListings());
  const updatedCartListingIds = [...cartListingIds, listingId];

  if (shouldQueueAbandonedBagEmail && currentUser?.id.uuid) {
    queueAbandonedBagEmail({
      shoppingBagListingIds: updatedCartListingIds,
      treetId,
      userId: currentUser.id.uuid,
      timestamp: Date.now(),
    });
  }
};

export const removeListingsFromShoppingBag = (params) => async (dispatch, getState) => {
  const state = getState();

  const { treetId } = state.initial;
  const { currentUser } = state.user;

  const cartListingIds = state.user.cartListingIds || [];

  const { listingIds, shouldQueueAbandonedBagEmail = false } = params;
  const updatedCartListingIds = cartListingIds.filter((id) => !listingIds.includes(id));
  const cartListingIdsToRemove = cartListingIds.filter((id) => listingIds.includes(id));

  if (cartListingIdsToRemove.length) {
    const cartItemsPromise = removeCartListingsInPG({
      sharetribeListingIds: cartListingIdsToRemove,
    });
    const [pgCartItems, pgCartItemError] = await handle(cartItemsPromise);

    const pgCartListingIds = formatPGCartItems(pgCartItems?.data.removeCartItem);
    dispatch(cartListingIdsUpdated(pgCartListingIds));
    dispatch(removeShoppingBagListings(listingIds));

    if (pgCartItemError) {
      if (!isEmpty(pgCartItemError?.clientErrors)) {
        log.error(pgCartItemError, 'update-pg-remove-from-cart-failed', {
          sharetribeListingIds: listingIds,
          sharetribeUserId: currentUser.id.uuid,
        });
      }
      dispatch(removeFromShoppingBagError(storableError(pgCartItemError)));
    }

    if (shouldQueueAbandonedBagEmail && currentUser?.id.uuid) {
      queueAbandonedBagEmail({
        shoppingBagListingIds: updatedCartListingIds,
        treetId,
        userId: currentUser.id.uuid,
        timestamp: Date.now(),
      });
    }
  }
};

export const saveAddress = (params) => (dispatch, getState, sdk) => {
  dispatch(saveAddressRequest());

  const { address } = params;

  return sdk.currentUser
    .updateProfile({ privateData: { addresses: [address] } }, { expand: true })
    .then((response) => {
      const entities = denormalisedResponseEntities(response);
      if (entities.length !== 1) {
        throw new Error('Expected a resource in the sdk.currentUser.show response');
      }
      const currentUser = entities[0];
      dispatch(currentUserShowSuccess(currentUser));
      dispatch(saveAddressSuccess());
    })
    .catch((e) => dispatch(saveAddressError(storableError(e))));
};

export const sendVerificationEmail = () => (dispatch, getState, sdk) => {
  if (verificationSendingInProgress(getState())) {
    return Promise.reject(new Error('Verification email sending already in progress'));
  }
  dispatch(sendVerificationEmailRequest());
  return sdk.currentUser
    .sendVerificationEmail()
    .then(() => dispatch(sendVerificationEmailSuccess()))
    .catch((e) => dispatch(sendVerificationEmailError(storableError(e))));
};

export const subscribeEmail = (params) => async (dispatch, getState) => {
  const { email, sizes, subscribeSource } = params;
  const { treetId } = getState().initial;
  dispatch(subscribeRequest());

  const contact = {
    email,
    custom_fields: {
      // treetId custom field id
      w1_T: treetId,
      // sizes custom field id
      ...(sizes ? { w2_T: sizes?.join(', ') } : {}),
    },
  };

  const [addSendgridContactResponse, addSendgridContactError] = await handle(
    subscribeEmailApiRequest({
      contact,
      listId: SENDGRID_CONTACT_LISTS.updatesSubscribeList.listId,
    })
  );

  if (addSendgridContactError) {
    log.error(addSendgridContactError, 'subscribe-email-add-sg-contact-failed', { contact });
    dispatch(subscribeError(addSendgridContactError));
    return null;
  }

  heap.trackSubscribe(
    treetId,
    email,
    SENDGRID_CONTACT_LISTS.updatesSubscribeList.listName,
    subscribeSource,
    sizes
  );
  dispatch(subscribeSuccess({ email }));
  return addSendgridContactResponse;
};

export const deleteSuppression = (params) => async (dispatch) => {
  dispatch(deleteSuppressionRequest());

  const [deleteSgSuppressionResponse, deleteSgSuppressionError] = await handle(
    deleteSuppressionApiRequest(params)
  );

  if (deleteSgSuppressionError) {
    log.error(deleteSgSuppressionError, 'sendgrid-delete-suppression-failed', params);
    dispatch(deleteSuppressionError(deleteSgSuppressionError));
    return null;
  }

  dispatch(deleteSuppressionSuccess());
  return deleteSgSuppressionResponse;
};

export const updateGeneralSavedSearchEmailSubscription = (params) => async (dispatch, getState) => {
  const { cadence, email, sizes, groupId, subscribeSource } = params;

  const { shopId } = getState().initial;
  const { savedSearchSource } = getState().user;

  dispatch(updateGeneralSavedSearchSubscriptionRequest());

  const search = isEmpty(sizes) ? {} : { sizes: sizes.join(',') };

  const upsertGeneralSavedSearchParams = {
    email,
    search,
    shopId,
    subscribeSource: subscribeSource || savedSearchSource,
    cadence,
  };

  const upsertGeneralSavedSearchPromise = apolloClient.mutate({
    mutation: UpsertGeneralSavedSearchDocument,
    variables: {
      input: upsertGeneralSavedSearchParams,
    },
    refetchQueries: [namedOperations.Query.SavedSearchByEmail],
  });

  let response, error;
  if (cadence === Cadence.Never) {
    [response, error] = await handle(upsertGeneralSavedSearchPromise);
  } else {
    [response, error] = await handle(
      Promise.all([
        dispatch(
          subscribeEmail({
            email,
            sizes,
            subscribeSource: subscribeSource || savedSearchSource,
          })
        ),
        upsertGeneralSavedSearchPromise,
        dispatch(deleteSuppression({ email, groupId })),
      ])
    );
  }

  if (response) {
    dispatch(setSavedSearchSource({ source: null }));
    dispatch(updateGeneralSavedSearchSubscriptionSuccess());
    return response;
  }

  log.error(error, 'update-general-saved-search-email-subscription-failed', { params });
  dispatch(updateGeneralSavedSearchSubscriptionError(error));
  throw error;
};

export const updateISOSavedSearchEmailSubscription = (params) => async (dispatch, getState) => {
  const { cadence, email, sizes, shopifyProductId, groupId } = params;

  const { shopId } = getState().initial;
  const { savedSearchSource } = getState().user;

  dispatch(updateISOSavedSearchSubscriptionRequest());

  const search = {
    ...(!isEmpty(sizes) && { sizes: sizes.join(',') }),
    ...(shopifyProductId && { shopifyProductId }),
  };

  const upsertISOSavedSearchParams = {
    email,
    search,
    shopId,
    subscribeSource: savedSearchSource,
    cadence,
  };

  const upsertISOSavedSearchPromise = apolloClient.mutate({
    mutation: UpsertIsoSavedSearchDocument,
    variables: {
      input: upsertISOSavedSearchParams,
    },
    refetchQueries: [namedOperations.Query.IsoSavedSearchesByEmailDocument],
  });

  const [response, error] = await handle(upsertISOSavedSearchPromise);
  if (cadence !== Cadence.Never) {
    await dispatch(deleteSuppression({ email, groupId }));
  }

  if (response) {
    dispatch(updateISOSavedSearchSubscriptionSuccess());
    return response;
  }

  log.error(error, 'update-iso-saved-search-email-subscription-failed', { params });
  dispatch(updateISOSavedSearchSubscriptionError(error));
  throw error;
};
