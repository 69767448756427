import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { favoritedListingIdsUpdated } from '../../ducks/user.duck';
import { storableError } from '../../util/errors';
import * as log from '../../util/log';
import { fetchListings as fetchListingsApiRequest } from '../../util/api';
import { getFavoritedListingsFromPG } from '../../util/favoritedListingHelpers';

// ================ Action types ================ //

const FETCH_FAVORITE_LISTINGS_REQUEST = 'app/FavoritesPage/FETCH_FAVORITE_LISTINGS_REQUEST';
const FETCH_FAVORITE_LISTINGS_SUCCESS = 'app/FavoritesPage/FETCH_FAVORITE_LISTINGS_SUCCESS';
const FETCH_FAVORITE_LISTINGS_ERROR = 'app/FavoritesPage/FETCH_FAVORITE_LISTINGS_ERROR';

// ================ Reducer ================ //

const initialState = {
  fetchFavoriteListingsInProgress: false,
  fetchFavoriteListingsError: null,
  listingIds: [],
};

const favoritesPageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case FETCH_FAVORITE_LISTINGS_REQUEST:
      return {
        ...state,
        fetchFavoriteListingsInProgress: true,
        fetchFavoriteListingsError: null,
      };
    case FETCH_FAVORITE_LISTINGS_SUCCESS:
      return {
        ...state,
        listingIds: payload.resultIds,
        fetchFavoriteListingsInProgress: false,
      };
    case FETCH_FAVORITE_LISTINGS_ERROR:
      return {
        ...state,
        fetchFavoriteListingsInProgress: false,
        fetchFavoriteListingsError: payload,
      };

    default:
      return state;
  }
};

export default favoritesPageReducer;

// ================ Action creators ================ //

export const fetchFavoriteListingsRequest = () => ({
  type: FETCH_FAVORITE_LISTINGS_REQUEST,
});

export const fetchFavoriteListingsSuccess = (payload) => ({
  type: FETCH_FAVORITE_LISTINGS_SUCCESS,
  payload,
});

export const fetchFavoriteListingsError = (error) => ({
  type: FETCH_FAVORITE_LISTINGS_ERROR,
  error: true,
  payload: error,
});

// ================ Thunks ================ //

export const fetchFavoriteListings = () => async (dispatch, getState) => {
  const state = getState();
  const { treetId } = getState().initial;
  const { currentUser } = state.user;

  dispatch(fetchFavoriteListingsRequest());
  const pgFavoritedListingIds = await getFavoritedListingsFromPG(currentUser, treetId);
  await dispatch(favoritedListingIdsUpdated(pgFavoritedListingIds));

  const listingIds = getState().user.favoritedListingIds;

  let resultIds;
  try {
    const favoriteListingsResponse = await fetchListingsApiRequest({ listingIds });
    dispatch(addMarketplaceEntities(favoriteListingsResponse));
    resultIds = listingIds.map((listingId) => ({ uuid: listingId }));
    dispatch(fetchFavoriteListingsSuccess({ resultIds }));
  } catch (e) {
    dispatch(fetchFavoriteListingsError(storableError(e)));
    log.error(e, 'fetching-favorite-listings-failed', {});
  }
  return resultIds;
};

export const loadData = () => async (dispatch, getState) => {
  if (getState().user.currentUser?.id.uuid) {
    await dispatch(fetchFavoriteListings());
  }
};
