import { Box } from '@material-ui/core';
import React, { FC, ReactNode } from 'react';
import { Field } from 'react-final-form';
import { FieldSwitch } from '../FieldSwitch/FieldSwitch';
import css from './ToggleBanner.module.css';

interface ToggleBannerProps {
  formId: string;
  name: string;
  children: ReactNode;
}

/**
 * Must be used inside a React Final Form form.
 */
const ToggleBanner: FC<ToggleBannerProps> = (props: ToggleBannerProps) => {
  const { formId, name, children } = props;
  const id = `${formId}-${name}`;

  return (
    <>
      <Field name={id} component="input" type="checkbox" id={id} className={css.hidden} />
      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
      <label htmlFor={name} className={css.label}>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          // More padding on left to account for extra padding from the MUI Switch component
          pl={{ xs: 3, sm: 5 }}
          pr={{ xs: 2, sm: 4 }}
          py={2}
          className={css.banner}
        >
          <Box display="flex" flexDirection="column">
            {children}
          </Box>
          <FieldSwitch name={name} data={{ label: '', value: true }} color="default" />
        </Box>
      </label>
    </>
  );
};

export default ToggleBanner;
