/* eslint-disable import/prefer-default-export */
import { useHistory } from 'react-router-dom';
import { logout } from '../ducks/Auth.duck';
import config from '../shopConfig/config';
import { buildDomainForAuthCookie } from '../util/helpers';
import { pathByRouteName } from '../util/routes';
import { useAppDispatch } from './appDispatch';
import { useRouteConfiguration } from './useRouteConfiguration';

export const useLogout = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const routes = useRouteConfiguration();

  const handleLogout = () => {
    const cookieDomain = buildDomainForAuthCookie();
    dispatch(logout({ cookieDomain })).then(() => {
      const path = pathByRouteName('LandingPage', routes);

      // In production we ensure that data is really lost, but in development mode
      // we use stored values for debugging
      if (config.dev) {
        history.push(path);
      } else if (typeof window !== 'undefined') {
        window.location = path as any;
      }
    });
  };

  return { handleLogout };
};
