/* eslint-disable import/prefer-default-export */
import { getDefaultShopConfig } from './default';
import { getBrandDirectFilterConfig } from '../filters/brandDirect';
import { getConditionsFilterConfig } from '../filters/condition';
import * as custom from '../filters/marketplace-custom-config';
import { getSizeFilterConfig } from '../filters/size';

const joahloveSizeOptions = [
  '3M',
  '6M',
  '12M',
  '18M',
  '24M',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '10',
  '12',
  '14',
  'Adult X-Small',
  'Adult Small',
  'Adult Medium',
  'Adult Large',
  'Adult X-Large',
  'Adult XX-Large',
];

export const joahloveShopConfig = {
  ...getDefaultShopConfig(),
  filters: [
    ...custom.filters,
    getBrandDirectFilterConfig(),
    getSizeFilterConfig({ sizes: joahloveSizeOptions }),
    getConditionsFilterConfig(),
  ],
};
