import { getBrandDirectFilterConfig } from '../filters/brandDirect';
import { getConditionsFilterConfig } from '../filters/condition';
import { getSizeFilterConfig } from '../filters/size';
import { getDefaultShopConfig } from './default';
import * as custom from '../filters/marketplace-custom-config';

export const kitsbowSizes = [
  '28',
  '29',
  '30',
  '31',
  '32',
  '33',
  '34',
  '36',
  '38',
  '40',
  '42',
  'XXXXL',
  'W-XXXXL',
  'XXXL',
  'W-XXXL',
  'XXL',
  'W-XXL',
  'XL',
  'W-XL',
  'L/XL',
  'L',
  'W-L',
  'M',
  'W-M',
  'S',
  'W-S',
  'XS',
  'W-XS',
  'XXS',
  'W-XXS',
];

export const kitsbowShopConfig = {
  ...getDefaultShopConfig(),
  filters: [
    ...custom.filters,
    getConditionsFilterConfig(),
    getBrandDirectFilterConfig(),
    getSizeFilterConfig({ sizes: kitsbowSizes }),
  ],
};
