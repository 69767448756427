export const isDev = process.env.REACT_APP_ENV === 'development';
export const isTest = process.env.REACT_APP_ENV === 'test';
export const isStaging = process.env.REACT_APP_ENV === 'staging';
export const isProd = process.env.REACT_APP_ENV === 'production';

export const getSubdomain = () => {
  if (typeof window !== 'undefined') {
    return window.location?.host?.replace('www.', '').split('.')[0];
  }
  return null;
};

export const isUsingDevApiServer = (): boolean => {
  const port = process.env.REACT_APP_DEV_API_SERVER_PORT;
  return process.env.NODE_ENV === 'development' && !!port;
};

export const apiBaseUrl = (origin?: string): string => {
  // In development, the dev API server is running in a different port
  if (isUsingDevApiServer()) {
    // Since this is dev, the subdomain is the Treet ID
    const treetId = getSubdomain();
    const port = process.env.REACT_APP_DEV_API_SERVER_PORT;
    return `http://${treetId}.localhost:${port}`;
  }

  // Otherwise, use the same domain and port as the frontend
  return origin || `${window?.location.origin || ''}`;
};
