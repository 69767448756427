/* eslint-disable import/prefer-default-export */
import { DisputesInfo } from '../../types/forms/disputeFormData';
import { BundleInfo } from '../../types/models/bundle';
import { getBundleDisplayNumber } from '../../util/bundles';
import { CLAIM_TYPES } from '../../util/constants';
import { Listing } from '../../types/sharetribe/listing';

export enum TransactionEmailTemplateId {
  BuyerDisputedTemplateId = 'd-a7d4c6a911c24d24ae1ec831e3686ccd',
}
export const DO_NOT_REPLY_EMAIL = 'do-not-reply@treet.co';

export const getDisputeDynamicTemplateData = (
  disputesInfo: DisputesInfo,
  bundle: BundleInfo,
  listings: Listing[]
) => {
  const listingDisputeInfo = Object.entries(disputesInfo).map(([bundleItemId, disputeInfo]) => {
    const bundleItem = bundle.bundleItems.find((b) => b.id === bundleItemId);
    const listing = listings.find((l) => l?.id.uuid === bundleItem?.listing.sharetribeListingId);
    const { claimType, description } = disputeInfo;
    const inquiryLabel = CLAIM_TYPES.find(({ key }) => claimType === key)?.label;

    return {
      title: listing?.attributes.title,
      id: listing?.id.uuid,
      inquiryLabel,
      description,
    };
  });
  return {
    listingDisputeInfo,
    bundle: {
      displayId: getBundleDisplayNumber(bundle.id),
    },
  };
};
