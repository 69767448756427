import { getConditionsFilterConfig } from '../filters/condition';
import { getDefaultShopConfig } from './default';
import * as custom from '../filters/marketplace-custom-config';
import { getSizeFilterConfig } from '../filters/size';

const henkaaSizes = ['XS/S', 'M/L', '1X/2X', '3X/4X', 'Plus', 'One Size'];

export const henkaaShopConfig = {
  ...getDefaultShopConfig(),
  filters: [
    ...custom.filters,
    getConditionsFilterConfig(),
    getSizeFilterConfig({ sizes: henkaaSizes }),
  ],
};
