import { defaultClothesConditionsOptions, getConditionsFilterConfig } from '../filters/condition';
import { getDefaultShopConfig } from './default';
import * as custom from '../filters/marketplace-custom-config';
import { getSizeFilterConfigFromOptions } from '../filters/size';

const lykkewullfSizeOptions = [
  { key: 'XS', label: 'XS' },
  { key: 'S', label: 'S' },
  { key: 'M', label: 'M' },
  { key: 'L', label: 'L' },
  { key: 'XL', label: 'XL' },
  { key: '0', label: '0' },
  { key: '2', label: '2' },
  { key: '4', label: '4' },
  { key: '6', label: '6' },
  { key: '8', label: '8' },
  { key: '10', label: '10' },
  { key: 'One Size', label: 'One Size' },
];

export const lykkewullfShopConfig = {
  ...getDefaultShopConfig(),
  filters: [
    ...custom.filters,
    getConditionsFilterConfig({ options: defaultClothesConditionsOptions }),
    getSizeFilterConfigFromOptions({ options: lykkewullfSizeOptions }),
  ],
};
