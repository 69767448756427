/* eslint-disable import/prefer-default-export */
import { Conditions, getConditionsFilterConfig } from '../filters/condition';
import { getBrandDirectFilterConfig } from '../filters/brandDirect';
import { getSizeFilterConfig } from '../filters/size';
import { getDefaultShopConfig } from './default';
import * as custom from '../filters/marketplace-custom-config';
import { getGenderFilterConfig } from '../filters/gender';

const conditionOptions = [
  {
    key: Conditions.NewWithTags,
    label: 'New In Box',
    description:
      'Your shoes have never been worn and they’re still in the original box. For this option, you’ll need to take pictures of your shoes in the box.',
  },
  {
    key: Conditions.NewWithoutTags,
    label: 'New Without Box',
    description:
      'Your shoes have never been worn (other than perhaps trying them on). You no longer have the box, but may have the dustbags they came in. There are no quirks or issues whatsoever.',
  },
  {
    key: Conditions.Excellent,
    label: 'Excellent Condition',
    description:
      'Your shoes have only been lightly used and have been extremely well maintained. There may be some signs of wear, but nothing major.',
  },
  {
    key: Conditions.Good,
    label: 'Good Condition',
    description:
      'You’ve worn the shoes and they’re well maintained. There may be some quirks like a small stain or a scuff mark, but they’re overall in good shape. You will need to take pictures of any quirks your shoes have.',
  },
];

export const ranchroadShopConfig = {
  ...getDefaultShopConfig(),
  filters: [
    ...custom.filters,
    getGenderFilterConfig(),
    getConditionsFilterConfig({ options: conditionOptions }),
    getSizeFilterConfig({
      sizes: [
        '5.5',
        '6',
        '6.5',
        '7',
        '7.5',
        '8',
        '8.5',
        '9',
        '9.5',
        '10',
        '10.5',
        '11',
        '11.5',
        '12',
        '12.5',
        '13',
        '13.5',
        '14',
        '15',
      ],
    }),
    getBrandDirectFilterConfig(),
  ],
};
