import React, { FC } from 'react';

const IconBuy: FC = () => (
  <svg
    width="100"
    height="100"
    viewBox="0 0 544 544"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="272" cy="272" r="272" fill="#F8F8F8" />
    <path d="M187.04 209L152 398H392L356.96 209H187.04Z" fill="#D1D1D1" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M152 380H392L356.96 191H187.04L152 380ZM161.619 372H382.38L350.307 199H193.693L161.619 372Z"
      fill="#454545"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M227 181C227 156.147 247.147 136 272 136C296.853 136 317 156.147 317 181V224H309V181C309 160.565 292.435 144 272 144C251.565 144 235 160.565 235 181V224H227V181Z"
      fill="#454545"
    />
    <circle cx="231" cy="227" r="10" fill="#454545" />
    <circle cx="313" cy="227" r="10" fill="#454545" />
  </svg>
);

export default IconBuy;
