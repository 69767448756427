import { getDefaultShopConfig } from './default';
import * as custom from '../filters/marketplace-custom-config';
import { getBrandDirectFilterConfig } from '../filters/brandDirect';
import { getConditionsFilterConfig } from '../filters/condition';
import { getSizeFilterConfig } from '../filters/size';

export const lafemmeaperoShopConfig = {
  ...getDefaultShopConfig(),
  filters: [
    ...custom.filters,
    getConditionsFilterConfig(),
    getBrandDirectFilterConfig(),
    getSizeFilterConfig({
      sizes: [
        'XS / US 2 / 34 FR',
        'S / US 4 / 36 FR',
        'M / US 6 / 38 FR',
        'L / US 8 / 40 FR',
        'XL / US 10 / 42 FR',
        'XXL / US 12 / 44 FR',
        'XXXL / US 14 / 46 FR',
      ],
    }),
  ],
};
