import { Box, MenuItem, Select } from '@material-ui/core';
import SearchBar from 'material-ui-search-bar';
import { stringify } from 'query-string';
import React, { FC, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import {
  Button,
  DynamicValueWrapper,
  Empty,
  Footer,
  IconCloseV2,
  IconSearch,
  IconSpinner,
  LayoutSideNavigation,
  LayoutWrapperFooter,
  LayoutWrapperMain,
  LayoutWrapperSideNav,
  LayoutWrapperTopbar,
  TypographyWrapper,
  UserNavbar,
} from '..';
import { TopbarContainer } from '../../containers';
import {
  ManagePurchasesPageState,
  setSearchValue,
} from '../../containers/ManagePurchasesPage/ManagePurchasesPage.duck';
import { useAppDispatch } from '../../hooks/appDispatch';
import { useEnabledCustomerExperiences } from '../../hooks/useEnabledCustomerExperiences';
import { useCurrentUserPermissions } from '../../hooks/useUserPermissions';
import {
  BundleStatus,
  BundleType,
  Participant,
} from '../../types/apollo/generated/types.generated';
import { BundleInfo } from '../../types/models/bundle';
import { getBundleStatusLabel } from '../../util/bundles';
import { getFormattedDate } from '../../util/dates';
import { parse } from '../../util/urlHelpers';
import IndividualBundleContainer from './IndividualBundleContainer';
import css from './ManageBundlesContainer.module.css';
import { BUNDLE_STATUS_FILTER_ALL, StatusFilterValues } from './manageBundlesContainerUtils';
import ThreeDotsIconButton from './ThreeDotsIconButton';
import BundleTypeMarker from '../BundleTypeMarker/BundleTypeMarker';

interface ManageBundlesContainerDesktopProps {
  isLoading: boolean;
  bundles: BundleInfo[];
  participant: Participant;
  onCancelClick?: (bundle: BundleInfo) => void;
  onVerifyClick?: (bundle: BundleInfo) => void;
  onDisputeClick?: (bundle: BundleInfo) => void;
  onGetPaidClick?: (bundle: BundleInfo) => void;
  onFilterChange?: (value: StatusFilterValues) => void;
  onMarkAsFulfilledClick?: (bundle: BundleInfo) => void;
  onMarkAsDeliveredClick?: (bundle: BundleInfo) => void;
  onEditShippingAddressSubmit?: (values: any, addressId: string, onSuccess: () => void) => void;
  error?: string;
  fetchMore?: (() => void) | false;
}

const ManageBundlesContainerDesktop: FC<ManageBundlesContainerDesktopProps> = (
  props: ManageBundlesContainerDesktopProps
) => {
  const {
    bundles,
    isLoading,
    participant,
    onCancelClick,
    onVerifyClick,
    onDisputeClick,
    onGetPaidClick,
    onFilterChange,
    onMarkAsFulfilledClick,
    onMarkAsDeliveredClick,
    onEditShippingAddressSubmit,
    error,
    fetchMore,
  } = props;

  const { isBrand, isTradeInAdmin } = useCurrentUserPermissions();
  const { allowTradeInAndMarketplace, isListTradeInOnly, isTradeInOnly, hasBuyHistory } =
    useEnabledCustomerExperiences();
  const location = useLocation();
  const queryParams = parse(location.search);
  const dispatch = useAppDispatch();
  const { searchValue } = useSelector<any>(
    (state) => state.ManagePurchasesPage
  ) as ManagePurchasesPageState;
  const { filter } = queryParams;

  const [trackingSearchQuery, setTrackingSearchQuery] = useState<string>('');

  const isSales = participant === Participant.Seller;
  // Specific Page for individual sales/purchases
  const currentIdPage = isSales ? 'ManageSalePage' : 'ManagePurchasePage';
  // Root index page for sales/purchases
  const currentRootPage = isSales ? 'ManageSalesPage' : 'ManagePurchasesPage';
  const { id: selectedBundleId } = useParams() as { id?: string };
  // Default to first bundle if we're just on the index page (/sales or /purchases)
  const activeBundle =
    (selectedBundleId && bundles.find((o) => o.id === selectedBundleId)) || bundles[0];
  const otherBundlesInSameOrder = selectedBundleId
    ? bundles.filter(
        (b) => b?.order?.id && b.order.id === activeBundle?.order?.id && b.id !== activeBundle.id
      )
    : undefined;

  const getTab = (bundle: BundleInfo) => {
    const isTradeInBundle = bundle.type === BundleType.TradeIn;
    return (
      <div className={css.tab}>
        <Box display="flex" flexDirection="column">
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Box display="flex">
              <TypographyWrapper variant="h2">
                <DynamicValueWrapper>{getFormattedDate(bundle.createdAt)}</DynamicValueWrapper>
              </TypographyWrapper>
            </Box>
            {allowTradeInAndMarketplace && (
              <Box display="flex" paddingLeft={1}>
                <BundleTypeMarker title={isTradeInBundle ? 'Trade-In' : 'Sale'} />
              </Box>
            )}
          </Box>
          <h4>
            <TypographyWrapper variant="body1">
              {getBundleStatusLabel(participant, bundle)}
            </TypographyWrapper>
          </h4>
        </Box>
      </div>
    );
  };

  const getTabs = () =>
    bundles.map((bundle) => ({
      text: getTab(bundle),
      selected: bundle.id === activeBundle?.id,
      linkProps: {
        name: currentIdPage,
        params: { id: bundle.id },
        to: { search: stringify({ filter }) },
      },
    }));

  const handleCancelSearch = () => {
    dispatch(setSearchValue(''));
  };

  const statusFilterEl = onFilterChange && (
    <Select
      labelId="bundle-status"
      id="bundle-status"
      value={filter || BUNDLE_STATUS_FILTER_ALL}
      onChange={(e) => {
        const val = e.target.value as StatusFilterValues;
        onFilterChange(val);
      }}
      label="Filter By"
      style={{ width: '100%' }}
    >
      <MenuItem value={BUNDLE_STATUS_FILTER_ALL}>All</MenuItem>
      <MenuItem value={BundleStatus.Open}>Not yet shipped</MenuItem>
      <MenuItem value={BundleStatus.Shipped}>Shipped</MenuItem>
      <MenuItem value={BundleStatus.Delivered}>Delivered</MenuItem>
      <MenuItem value={BundleStatus.Completed}>Completed</MenuItem>
    </Select>
  );

  const transactionType = () => {
    if (isTradeInOnly) {
      if (hasBuyHistory && !isSales) return 'purchases';
      return 'trade-ins';
    }
    if (isSales) {
      if (isListTradeInOnly) return 'trade-ins';
      if (allowTradeInAndMarketplace) return 'sales or trade-ins';
      return 'sales';
    }
    return 'purchases';
  };

  return (
    <>
      <LayoutSideNavigation>
        <LayoutWrapperTopbar>
          <TopbarContainer currentPage={currentRootPage} />
          <UserNavbar selectedPageName={currentRootPage} />
        </LayoutWrapperTopbar>
        <LayoutWrapperSideNav
          tabs={getTabs()}
          className={css.sideNav}
          getMore={
            fetchMore && (
              <Box mb={2}>
                <Button onClick={fetchMore} disabled={isLoading}>
                  Get More Orders
                </Button>
              </Box>
            )
          }
        >
          {isBrand && (
            <Box mr={1} mb={3}>
              {/* TODO: implement search bar for sales page */}
              {!isSales && (
                <SearchBar
                  value={searchValue}
                  onChange={(newSearchValue) => setTrackingSearchQuery(newSearchValue)}
                  onRequestSearch={() => {
                    dispatch(setSearchValue(trackingSearchQuery));
                  }}
                  cancelOnEscape
                  onCancelSearch={handleCancelSearch}
                  searchIcon={<IconSearch />}
                  closeIcon={<IconCloseV2 />}
                  classes={{
                    root: css.search,
                  }}
                  style={{
                    borderRadius: '0',
                    boxShadow: 'none',
                    height: '30px',
                  }}
                  placeholder={isTradeInAdmin ? 'Search by order or customer' : 'Search by order #'}
                />
              )}
              {statusFilterEl}
            </Box>
          )}
        </LayoutWrapperSideNav>
        <LayoutWrapperMain className={css.body}>
          {error && <h4 className={css.error}>{error}</h4>}
          {isLoading && (
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              flex={1}
            >
              <IconSpinner />
            </Box>
          )}
          {!isLoading && !error && bundles.length === 0 && (
            <Empty text={`No ${transactionType()} yet.`} />
          )}
          {!isLoading && activeBundle && (
            <>
              <div className={css.content}>
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                  alignItems="center"
                  pl={2}
                  pb={1}
                >
                  <h2>
                    <TypographyWrapper variant="h1">
                      <DynamicValueWrapper>
                        {getFormattedDate(activeBundle.createdAt)}
                      </DynamicValueWrapper>
                    </TypographyWrapper>
                  </h2>
                  <ThreeDotsIconButton
                    participant={participant}
                    bundle={activeBundle}
                    onCancelClick={onCancelClick}
                  />
                </Box>

                <IndividualBundleContainer
                  isLoading={isLoading}
                  bundle={activeBundle}
                  otherBundlesInSameOrder={otherBundlesInSameOrder}
                  participant={participant}
                  onVerifyClick={onVerifyClick}
                  onDisputeClick={onDisputeClick}
                  onGetPaidClick={onGetPaidClick}
                  onMarkAsFulfilledClick={onMarkAsFulfilledClick}
                  onMarkAsDeliveredClick={onMarkAsDeliveredClick}
                  onEditShippingAddressSubmit={onEditShippingAddressSubmit}
                />
              </div>
            </>
          )}
          {/* TODO (sonia-y | TREET-616): Fill in error order state */}
          {/* Return some state that says the order is not found */}
          {!activeBundle && <div />}
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSideNavigation>
    </>
  );
};

export default ManageBundlesContainerDesktop;
