import React, { FC } from 'react';
import { useIconColor } from '../../../hooks/useIconColor';

interface IconDashboardProps {
  className?: string;
  color?: string;
}

const IconDashboard: FC<IconDashboardProps> = (props) => {
  const { className, color } = props;

  const { iconColor } = useIconColor();
  const fillColor = color || iconColor;

  return (
    <svg
      className={className}
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask id="path-1-inside-1_19_83" fill="white">
        <path d="M4 18H15V28H4V18Z" />
      </mask>
      <path
        d="M4 18V16H2V18H4ZM15 18H17V16H15V18ZM15 28V30H17V28H15ZM4 28H2V30H4V28ZM4 20H15V16H4V20ZM13 18V28H17V18H13ZM15 26H4V30H15V26ZM6 28V18H2V28H6Z"
        fill={fillColor}
        mask="url(#path-1-inside-1_19_83)"
      />
      <rect x="18" y="19" width="9" height="8" stroke={fillColor} fill="none" strokeWidth="2" />
      <rect x="5" y="5" width="22" height="10" stroke={fillColor} fill="none" strokeWidth="2" />
    </svg>
  );
};

export default IconDashboard;
