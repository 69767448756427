/* eslint-disable import/prefer-default-export */
import { getSmallUploadcarePreviewAsPng } from '../../util/uploadcare';
import { UploadcareFile } from '../../types/uploadcare/file';

export const arePixelsTransparent = (cornerPixels: Uint8ClampedArray[]): boolean =>
  // check if the alpha value is 0, means it's transparent
  cornerPixels.some((rgba) => rgba[3] === 0);

export const shouldRenderBackgroundBlurFn = (
  imageUrl: string | null | undefined,
  images: UploadcareFile[]
): boolean => {
  const urlPattern = /https:\/\/ucarecdn\.com\/[a-f0-9]{8}-(?:[a-f0-9]{4}-){3}[a-f0-9]{12}\//;
  const strippedImageUrl = urlPattern.exec(imageUrl || '')?.[0];
  const correspondingUploadcareImage = (images || []).find(
    (uploadcareImage: UploadcareFile) => uploadcareImage.originalUrl === strippedImageUrl
  );

  if (!correspondingUploadcareImage) {
    // Do not render background blur for stock images
    return false;
  }

  // Do not render background blur for images that have background removed
  return !correspondingUploadcareImage?.isBackgroundRemoved;
};

export const isBackgroundTransparent = (imageUrl: string): Promise<boolean> =>
  new Promise((resolve) => {
    const img = new Image();
    img.crossOrigin = 'Anonymous'; // This is important for CORS
    img.onload = () => {
      const canvas = document.createElement('canvas');
      const context = canvas.getContext('2d', { willReadFrequently: true });
      if (!context) {
        resolve(false);
        return;
      }
      canvas.width = img.width;
      canvas.height = img.height;
      context.drawImage(img, 0, 0, img.width, img.height);

      try {
        const cornerPixels = [
          context.getImageData(0, 0, 1, 1).data, // Top-left
          context.getImageData(img.width - 1, 0, 1, 1).data, // Top-right
          context.getImageData(0, img.height - 1, 1, 1).data, // Bottom-left
          context.getImageData(img.width - 1, img.height - 1, 1, 1).data, // Bottom-right
        ];
        const isTransparent = arePixelsTransparent(cornerPixels);
        resolve(isTransparent);
      } catch (error) {
        console.error('Error accessing image data:', error);
        resolve(false);
      }
    };

    img.onerror = () => {
      console.error(`Error in determining background transparency for ${imageUrl}.`);
      resolve(false);
    };

    img.src = getSmallUploadcarePreviewAsPng(imageUrl) || imageUrl;
  });
