/* eslint-disable import/prefer-default-export */
import { datadogLogs } from '@datadog/browser-logs';

// Datadog Browser Logging
export const setup = () => {
  datadogLogs.init({
    clientToken: process.env.REACT_APP_DATADOG_LOGS_CLIENT_TOKEN as string,
    site: 'datadoghq.com',
    service: 'treet-web-app',
    env: process.env.REACT_APP_ENV,
    forwardErrorsToLogs: true,
    sampleRate: 100,
    version: process.env.HEROKU_RELEASE_VERSION as string,
  });
};
