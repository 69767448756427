/* eslint-disable import/prefer-default-export */
import { getDefaultShopConfig, getDefaultTradeInShopConfig } from './default';
import * as custom from '../filters/marketplace-custom-config';
import { getBrandDirectFilterConfig } from '../filters/brandDirect';
import { Conditions, getConditionsFilterConfig } from '../filters/condition';
import { getSizeFilterConfig } from '../filters/size';
import { FilterId } from '../../types/filters/filters';
import { getTagsFilterConfig } from '../filters/styles';
import { BuilderSections } from '../../util/builder';

const littleandlivelySizes = [
  '0-3m',
  '0-6m',
  '3-6m',
  '6-9m',
  '6-12m',
  '9-12m',
  '12-18m',
  '18-24m',
  '7/8Y',
  '9/10Y',
  '11/12Y',
  '13/14Y',
  '1-2T',
  '2T',
  '3T',
  '3-4T',
  '4T',
  '5T',
  '5-6T',
  '6T',
  'X-Small',
  'Small',
  'Medium',
  'Large',
  'X-Large',
  '2X',
  '3X',
  'OS',
];

const littleandlivelyTags = [
  { key: 'baby', label: 'Baby & Kids Clothing', tags: ['little-lively'] },
  { key: 'adult', label: 'Adult Clothing', tags: ['dwelling-apparel'] },
  { key: 'pajamas', label: 'Pajamas', tags: ['pretty-laundry'] },
];

const littleandlivelyConditionsOptions = [
  {
    key: Conditions.NewWithTags,
    label: 'New & Never Worn',
    description: 'Your item is brand new and has never been worn.',
  },
  {
    key: Conditions.Excellent,
    label: 'Excellent Condition',
    description:
      'Your item has only been lightly used and has been extremely well maintained. There may be some small signs of wear, but nothing major.',
  },
  {
    key: Conditions.Good,
    label: 'Good Condition',
    description:
      'You’ve worn this item and it’s well maintained. There may be some quirks like a small stain, a button missing, or a loose thread, but it’s overall in good shape. You will need to take pictures of any quirks the item has.',
  },
];

export const littleandlivelyShopConfig = {
  ...getDefaultTradeInShopConfig(),
  ...getDefaultShopConfig(),
  filters: [
    ...custom.filters,
    getConditionsFilterConfig({ options: littleandlivelyConditionsOptions }),
    getBrandDirectFilterConfig(),
    getSizeFilterConfig({ sizes: littleandlivelySizes }),
    getTagsFilterConfig({
      id: FilterId.Style,
      options: littleandlivelyTags,
      shouldUseTreetOptions: true,
    }),
  ],
  builderConfig: {
    sections: {
      [BuilderSections.LandingPageContent]: 'db04c2f7712b454d8fc9e711eca4490c',
    },
  },
};
