import { Box } from '@material-ui/core';
import React, { CSSProperties, FC, ReactElement, useRef } from 'react';
import { Button, NamedLink, TypographyWrapper } from '..';
import {
  DRAFT_ID,
  DRAFT_SLUG,
  ListingPageParamTab,
} from '../../containers/EditListingPage/EditListingPage.utils';
import { useShopConfigV2 } from '../../hooks/shopConfig';
import { useElementSize } from '../../hooks/useElementSize';
import { useIsMobile } from '../../hooks/useIsMobile';
import { ListingPageParamType } from '../../util/urlHelpers';
import { LIST_ITEM_CARD_DEFAULT_OVERFLOW_WIDTH } from '../../util/constants';
import { TypographyWeight } from '../TypographyWrapper/TypographyWrapper';
import css from './ListAnItemCard.module.css';

interface ListAnItemCardProps {
  buttonText: { default: string; condensed: string };
  captionHeaderText: string;
  captionSubtitleText: string;
  mainContent: ReactElement | string;
  urlQueryParams?: { [key: string]: string };
  maxWidthForOverflow?: number | undefined;
  style?: CSSProperties;
}

const ListAnItemCard: FC<ListAnItemCardProps> = (props) => {
  const {
    mainContent,
    buttonText,
    captionHeaderText,
    captionSubtitleText,
    urlQueryParams,
    maxWidthForOverflow = LIST_ITEM_CARD_DEFAULT_OVERFLOW_WIDTH,
    style,
  } = props;
  const { imageRatio, css: brandCss } = useShopConfigV2();

  const componentRef = useRef(null);
  const { width } = useElementSize(componentRef);
  const isMobile = useIsMobile();
  const isOverflowWidth = isMobile || (!!width && width <= maxWidthForOverflow);
  const fontSize = isOverflowWidth ? '13px' : undefined;

  const formattedButtonText = isOverflowWidth ? buttonText?.condensed : buttonText?.default;
  const search = urlQueryParams ? new URLSearchParams(urlQueryParams).toString() : null;
  const overrideStyles = { textWrap: 'balance' };

  return (
    <div ref={componentRef}>
      <Box style={style}>
        <NamedLink
          // Directs to NewListingPage. Define 'params' manually so that url query params in 'to' are
          // not overwritten.
          name="EditListingPage"
          params={{
            slug: DRAFT_SLUG,
            id: DRAFT_ID,
            type: ListingPageParamType.New,
            tab: ListingPageParamTab.Search,
          }}
          style={{ textDecoration: 'none' }}
          {...(search && { to: { search } })}
        >
          <Box
            className={css.aspectWrapper}
            style={{ paddingBottom: `calc(${imageRatio * 100}% - 2px)` }} // subtract 2px to account for added height from border
            bgcolor={brandCss?.backgroundColor}
          >
            <Box display="flex" flexDirection="column" p={{ xs: 1, sm: 2 }} className={css.content}>
              <TypographyWrapper
                variant="body1"
                component="span"
                typographyOverrides={{ align: 'center', style: { fontSize, ...overrideStyles } }}
              >
                {mainContent}
              </TypographyWrapper>
              <Box mt={1}>
                <Button className={css.button}>{formattedButtonText}</Button>
              </Box>
            </Box>
          </Box>

          <Box display="flex" pt={1} flexDirection="column" justifyItems="center">
            <Box className={css.textRow}>
              <TypographyWrapper variant="body1" weight={TypographyWeight.Bold}>
                {captionHeaderText}
              </TypographyWrapper>
            </Box>
            <Box className={css.textRow}>
              <TypographyWrapper variant="body2">{captionSubtitleText}</TypographyWrapper>
            </Box>
          </Box>
        </NamedLink>
      </Box>
    </div>
  );
};

export default ListAnItemCard;
