import React from 'react';
import { useIconColor } from '../../../hooks/useIconColor';

interface IconCheckCircleFilledProps {
  className?: string;
  color?: string;
}

const IconCheckCircleFilled: React.FC<IconCheckCircleFilledProps> = (props) => {
  const { className, color } = props;

  const { iconColor } = useIconColor();
  const fillColor = color || iconColor;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
    >
      <circle cx="7" cy="7" r="7" fill={fillColor} />
      <path d="M4 7.18182L5.875 9L10 5" stroke="white" strokeWidth="2" />
    </svg>
  );
};

export default IconCheckCircleFilled;
