import { getDefaultShopConfig } from './default';
import * as custom from '../filters/marketplace-custom-config';
import { getConditionsFilterConfig } from '../filters/condition';
import { getSizeFilterConfig } from '../filters/size';

const ingridandisabelSizes = [
  'XS',
  'S',
  'M',
  'L',
  'XL',
  'XXL',
  'XXXL',
  'OS',
  '23',
  '24',
  '25',
  '26',
  '27',
  '28',
  '29',
  '30',
  '31',
  '32',
  '33',
  '34',
  '1',
  '2',
  '3',
  '4',
  '5',
  '2T',
  '3T',
  '4T',
  '0-6',
  '6-12',
  '12-18',
  '18-24',
  '0-3M',
  '3-6M',
  '6-9M',
  '9-12M',
];

const categoryPercentages = {
  dresses: 0.05,
  tops: 0.03,
  bottoms: 0.01,
};

const tagPercentages = {
  BestSellers: 0.06,
  'Jackets+Sweaters': 0.05,
  jacket: 0.05,
  jumpsuit: 0.03,
  newarrivals: 0.03,
  'new-arrivals': 0.03,
  Fallnewarrivals: 0.03,
  Workwear: 0.02,
  'Maternity Photoshoot Dress': 0.02,
  BabyShowerDresses: 0.015,
  'Boho Dresses': 0.015,
  'floral dresses': 0.015,
  'bodycon dresses': 0.015,
  Dresses: 0.015,
  'Mama+Babe': 0.015,
  Nursing: 0.01,
  Trimester_First: 0.01,
  Trimester_Postpartum: 0.01,
  Trimester_Second: 0.01,
  Trimester_Third: 0.01,
  denim: 0.01,
  'Premium Denim': 0.01,
  Leggings: 0.005,
  legging: 0.005,
  Style_Jeans: 0.005,
  Style_Legging: 0.005,
  'maternity jean': 0.005,
  activewear: 0.005,
  'iai_nursing+pospartum': 0.005,
  Style_Tanks: -0.02,
  'Style_T-Shirt': -0.02,
  'bellyband/belt': -0.02,
  workout: -0.02,
  'workout pants': -0.02,
  Intimates: -0.03,
  nursing_bras: -0.03,
  Bra: -0.03,
};

export const ingridandisabelShopConfig = {
  ...getDefaultShopConfig(),
  filters: [
    ...custom.filters,
    getConditionsFilterConfig(),
    getSizeFilterConfig({ sizes: ingridandisabelSizes }),
  ],
  resaleValueConfig: {
    categoryPercentages,
    tagPercentages,
  },
};
