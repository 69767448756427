import {
  Conditions,
  defaultClothesConditionsOptions,
  getConditionsFilterConfig,
} from '../filters/condition';
import { getBrandDirectFilterConfig } from '../filters/brandDirect';
import { getSizeFilterConfig } from '../filters/size';
import { getDefaultShopConfig } from './default';
import * as custom from '../filters/marketplace-custom-config';

export const southernTatersSizes = [
  'S',
  'M',
  'L',
  'XL',
  '3XL',
  'NB',
  '3m',
  '6m',
  '9m',
  '12m',
  '18m',
  '24m',
  '2t',
  '3t',
  '4t',
  '5t',
  '6t',
  '7t',
  '8t',
  '10',
];

export const southernTatersConditionOptions = [
  ...defaultClothesConditionsOptions.filter((option) => option.key !== Conditions.Good),
  {
    key: Conditions.Good,
    label: 'Play Condition',
    description:
      'This item has been well loved but still has life left in it! It may have small spots or stains, or tiny flaws that don’t effect the wear. You will need to take pictures of any quirks the item has.',
  },
];

export const southernTatersShopConfig = {
  ...getDefaultShopConfig(),
  filters: [
    ...custom.filters,
    getBrandDirectFilterConfig(),
    getConditionsFilterConfig({ options: southernTatersConditionOptions }),
    getSizeFilterConfig({ sizes: southernTatersSizes }),
  ],
};
