import { Box } from '@material-ui/core';
import React, { FC, ReactElement } from 'react';
import { useStyleType } from '../../hooks/useStyleType';

interface MultiButtonWrapperProps {
  children: ReactElement[];
}

const MultiButtonWrapper: FC<MultiButtonWrapperProps> = (props) => {
  const { children } = props;
  const { isCondensedStyle } = useStyleType();

  return (
    <Box display="flex" flexDirection={{ xs: 'column', sm: 'row' }} justifyContent="center">
      {children.map((button: ReactElement, index: number) => (
        <Box
          minWidth={isCondensedStyle ? '100px' : '150px'}
          ml={{ sm: index === 0 ? 0 : 2 }}
          // Need !important override for Safari
          mt={{ xs: index === 0 ? 0 : 1, sm: '0 !important' }}
          key={button.key}
        >
          {button}
        </Box>
      ))}
    </Box>
  );
};

export default MultiButtonWrapper;
