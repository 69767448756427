import { Box, BoxProps } from '@material-ui/core';
import React, { FC } from 'react';
import { useShopConfig } from '../../hooks/shopConfig';
import { useIsMobile } from '../../hooks/useIsMobile';
import { getDarkerBrandCssBackgroundColor } from '../../util/colors/colors';
import css from './Navbar.module.css';

interface NavbarProps {
  wrapperBoxOverrides?: Partial<BoxProps>;
  id?: string;
  type?: string;
}

export const enum NavbarType {
  Primary = 'PRIMARY',
  Secondary = 'SECONDARY',
}

const Navbar: FC<NavbarProps> = (props) => {
  const { children, wrapperBoxOverrides, id, type = NavbarType.Primary } = props;
  const isPrimaryNav = type === NavbarType.Primary;

  const { css: brandCss } = useShopConfig();
  const isMobile = useIsMobile();

  const backgroundColor = isPrimaryNav ? getDarkerBrandCssBackgroundColor(brandCss) : '#ffffff';
  const desktopHeight = isPrimaryNav ? 2 : 1.5;

  return (
    <Box py={isMobile ? 1 : desktopHeight} bgcolor={backgroundColor} id={id}>
      <Box
        px={isMobile ? 0.25 : 4}
        className={css.root}
        display="flex"
        alignItems="center"
        {...wrapperBoxOverrides}
      >
        {children}
      </Box>
    </Box>
  );
};
export default Navbar;
