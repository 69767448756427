import React, { FC } from 'react';

interface Icon25OffProps {
  className?: string;
}

const Icon25Off: FC<Icon25OffProps> = (props: Icon25OffProps) => {
  const { className } = props;

  return (
    <svg
      className={className}
      width="64"
      height="64"
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="32" cy="32" r="31.5" fill="#962A2A" stroke="#D1D1D1" />
      <path
        d="M18.4372 29V27.3361L22.2643 23.3426C22.4307 23.1595 22.5804 22.9765 22.7135 22.7935C22.9631 22.4773 23.1794 22.0613 23.1794 21.6786C23.1794 21.013 22.7634 20.597 22.0147 20.597C21.166 20.597 20.6835 21.0796 20.6835 21.9282L18.354 21.7618C18.354 19.8483 19.7683 18.4339 22.0147 18.4339C24.3442 18.4339 25.6754 19.7651 25.6754 21.5954C25.6754 22.3941 25.2594 23.1429 24.8434 23.692C24.5938 24.0082 24.3276 24.3077 24.0114 24.5905L22.0147 26.6705H25.6754V29H18.4372Z"
        fill="#F8F8F8"
      />
      <path
        d="M26.9194 26.0881L29.3322 25.9217C29.3821 26.1047 29.4819 26.2711 29.5984 26.4209C29.7981 26.6705 30.1641 26.9201 30.7465 26.9201C31.5952 26.9201 32.2441 26.2711 32.2441 25.4225C32.2441 24.5739 31.5952 23.925 30.7465 23.925C30.1974 23.925 29.848 24.1413 29.6317 24.3409C29.5152 24.4574 29.4154 24.6072 29.3322 24.7569H27.169L27.8346 18.5171H33.9081V20.8466H29.848L29.665 22.261C29.7814 22.1778 29.9146 22.1112 30.0643 22.0613C30.3139 21.9448 30.6633 21.845 31.0793 21.845C33.0761 21.845 34.6568 23.4258 34.6568 25.4225C34.6568 27.4858 33.0761 29.0832 30.7465 29.0832C28.5667 29.0832 27.1857 27.6689 26.9194 26.0881Z"
        fill="#F8F8F8"
      />
      <path
        d="M42.3988 26.7537C42.3988 27.2695 42.7981 27.6689 43.314 27.6689C43.8298 27.6689 44.2291 27.2695 44.2291 26.7537C44.2291 26.2379 43.8298 25.8385 43.314 25.8385C42.7981 25.8385 42.3988 26.2379 42.3988 26.7537ZM40.9844 26.7537C40.9844 25.4225 41.9828 24.4241 43.314 24.4241C44.6451 24.4241 45.6435 25.4225 45.6435 26.7537C45.6435 28.0848 44.6451 29.0832 43.314 29.0832C41.9828 29.0832 40.9844 28.0848 40.9844 26.7537ZM38.9045 20.7634C38.9045 20.2476 38.5051 19.8483 37.9893 19.8483C37.4735 19.8483 37.0741 20.2476 37.0741 20.7634C37.0741 21.2793 37.4735 21.6786 37.9893 21.6786C38.5051 21.6786 38.9045 21.2793 38.9045 20.7634ZM35.6598 20.7634C35.6598 19.4323 36.6581 18.4339 37.9893 18.4339C39.3205 18.4339 40.3188 19.4323 40.3188 20.7634C40.3188 22.0946 39.3205 23.093 37.9893 23.093C36.6581 23.093 35.6598 22.0946 35.6598 20.7634ZM36.4917 29L42.898 18.5171H44.8115L38.4053 29H36.4917Z"
        fill="#F8F8F8"
      />
      <path
        d="M23.3579 42.7537C24.8721 42.7537 26.1034 41.4891 26.1034 39.7586C26.1034 38.028 24.8721 36.7634 23.3579 36.7634C21.8437 36.7634 20.6123 38.028 20.6123 39.7586C20.6123 41.4891 21.8437 42.7537 23.3579 42.7537ZM23.3579 45.0832C20.5125 45.0832 18.1996 42.7537 18.1996 39.7586C18.1996 36.7634 20.5125 34.4339 23.3579 34.4339C26.2032 34.4339 28.5161 36.7634 28.5161 39.7586C28.5161 42.7537 26.2032 45.0832 23.3579 45.0832Z"
        fill="#F8F8F8"
      />
      <path
        d="M30.0085 45V34.5171H37.2467V36.8466H32.4212V39.0098H36.3315V41.3393H32.4212V45H30.0085Z"
        fill="#F8F8F8"
      />
      <path
        d="M38.6533 45V34.5171H45.8915V36.8466H41.066V39.0098H44.9763V41.3393H41.066V45H38.6533Z"
        fill="#F8F8F8"
      />
    </svg>
  );
};

export default Icon25Off;
