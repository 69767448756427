import { getDefaultShopConfig, getDefaultTradeInShopConfig } from './default';
import * as custom from '../filters/marketplace-custom-config';
import { getBrandDirectFilterConfig } from '../filters/brandDirect';
import { Conditions, getConditionsFilterConfig } from '../filters/condition';
import { getColorFilterConfig } from '../filters/color';

export const thenutrConditionOptions = [
  {
    key: Conditions.NewWithTags,
    label: 'New With Tags',
    shortLabel: 'New with Tags',
    description:
      'Your item has never been used and the tags are still on. For this option, you’ll need to take pictures of the retail tags.',
  },
  {
    key: Conditions.NewWithoutTags,
    label: 'New Without Tags',
    shortLabel: 'New w/o Tags',
    description:
      'Your item has never been used (other than perhaps unboxing), but the tags have been removed. There are no quirks or issues whatsoever.',
  },
  {
    key: Conditions.Excellent,
    label: 'Excellent Condition',
    description:
      'Your item has only been lightly used and has been extremely well maintained. There may be some small signs of wear, but nothing major.',
  },
  {
    key: Conditions.Good,
    label: 'Good Condition',
    description:
      'You’ve used this item and it’s well maintained. There may be some quirks, but it’s overall in good shape. You will need to take pictures of any quirks the item has.',
  },
];

export const thenutrShopConfig = {
  ...getDefaultShopConfig(),
  ...getDefaultTradeInShopConfig(),
  filters: [
    ...custom.filters,
    getBrandDirectFilterConfig(),
    getColorFilterConfig(),
    getConditionsFilterConfig({ options: thenutrConditionOptions }),
  ],
};
