/* global google */
import React, { useEffect } from 'react';
import { arrayOf, bool, object, oneOf, string } from 'prop-types';
import { useField } from 'react-final-form';
import { useIntl } from 'react-intl';
import isEmpty from 'lodash/isEmpty';
import config from '../../shopConfig/config';
import getCountryCodes from '../../translations/countryCodes';
import { ADDRESS_FORM_TYPE_BILLING, ADDRESS_FORM_TYPES } from '../../util/constants';
import * as validators from '../../util/validators';
import { FieldPhoneNumberInput, FieldSelect, FieldTextInput } from '..';
import {
  getAddressAutocomplete,
  getSharetribeAddressFromGoogle,
  getStateValueMaxLength,
} from '../../util/address';
import css from './AddressForm.module.css';
import { useUserCountryConfig } from '../../hooks/useCountryConfig';

const RECIPIENT_FIRST_NAME_FIELD_NAME = 'firstName';
const RECIPIENT_LAST_NAME_FIELD_NAME = 'lastName';

// TODO (SY): Need to disable country selection for shipping address (but not for payment address);
const AddressForm = (props) => {
  // postcal is required if isBillingForm is true
  const { className, disabled, form, fieldId, card, addressType, prefix, allowedCountryCodes } =
    props;

  const intl = useIntl();
  const { countryCode: userCountryCode } = useUserCountryConfig();

  const firstAddressFieldId = `${fieldId}.addressLine1`;
  const countryFieldName = prefix ? `${prefix}.country` : 'country';
  const countryValue = useField(countryFieldName)?.input?.value;

  useEffect(() => {
    if (google) {
      const autocomplete = getAddressAutocomplete(google, firstAddressFieldId);
      // Only suggest countries that are valid
      if (!isEmpty(allowedCountryCodes)) {
        autocomplete.setComponentRestrictions({
          country: allowedCountryCodes.map((country) => country.code),
        });
      }

      const handleGooglePlaceSelection = () => {
        const addressFormValues = getSharetribeAddressFromGoogle(autocomplete);
        const updateShippingAddress = form.mutators?.updateShippingAddress;
        if (updateShippingAddress) updateShippingAddress(addressFormValues);
      };
      const placeChangedListener = autocomplete.addListener(
        'place_changed',
        handleGooglePlaceSelection
      );

      return () => {
        google.maps.event.removeListener(placeChangedListener);
      };
    }
    return () => {};
  }, [google]);

  const isBillingForm = addressType === ADDRESS_FORM_TYPE_BILLING;

  const addressLine1Label = intl.formatMessage({
    id: 'AddressForm.addressLine1Label',
  });
  const addressLine1Placeholder = intl.formatMessage({
    id: 'AddressForm.addressLine1Placeholder',
  });
  const addressLine1Required = validators.required(
    intl.formatMessage({
      id: 'AddressForm.addressLine1Required',
    })
  );

  const addressLine2Label = intl.formatMessage({ id: 'AddressForm.addressLine2Label' });

  const addressLine2Placeholder = intl.formatMessage({
    id: 'AddressForm.addressLine2Placeholder',
  });

  const postalCodeLabel = intl.formatMessage({ id: 'AddressForm.postalCodeLabel' });
  const postalCodePlaceholder = intl.formatMessage({
    id: 'AddressForm.postalCodePlaceholder',
  });
  const postalCodeRequired = validators.required(
    intl.formatMessage({
      id: 'AddressForm.postalCodeRequired',
    })
  );

  const cityLabel = intl.formatMessage({ id: 'AddressForm.cityLabel' });
  const cityPlaceholder = intl.formatMessage({ id: 'AddressForm.cityPlaceholder' });
  const cityRequired = validators.required(
    intl.formatMessage({
      id: 'AddressForm.cityRequired',
    })
  );

  const stateRequired = validators.required(
    intl.formatMessage({
      id: 'AddressForm.stateRequired',
    })
  );

  const stateLabel = intl.formatMessage({ id: 'AddressForm.stateLabel' });
  const statePlaceholder = intl.formatMessage({ id: 'AddressForm.statePlaceholder' });
  const stateValueMaxLength = getStateValueMaxLength(countryValue || userCountryCode);

  const countryLabel = intl.formatMessage({ id: 'AddressForm.countryLabel' });
  const countryPlaceholder = intl.formatMessage({ id: 'AddressForm.countryPlaceholder' });
  const countryRequired = validators.required(
    intl.formatMessage({
      id: 'AddressForm.countryRequired',
    })
  );

  const phonePlaceholder = intl.formatMessage({ id: 'AddressForm.phonePlaceholder' });

  const handleOnChange = (event) => {
    const { value } = event.target;
    form.change(prefix ? `${prefix}.postal` : 'postal', value);
    card.update({ value: { postalCode: value } });
  };

  // Use the language set in config.locale to get the correct translations of the country names
  const countryCodes = getCountryCodes(config.locale);

  const firstNameFieldName = prefix
    ? `${prefix}.${RECIPIENT_FIRST_NAME_FIELD_NAME}`
    : RECIPIENT_FIRST_NAME_FIELD_NAME;
  const lastNameFieldName = prefix
    ? `${prefix}.${RECIPIENT_LAST_NAME_FIELD_NAME}`
    : RECIPIENT_LAST_NAME_FIELD_NAME;

  const firstNameValidators = validators.composeValidators(
    validators.required('First name is required'),
    validators.minLength('First name must be at least 2 characters', 2)
  );
  const lastNameValidators = validators.composeValidators(
    validators.required('Last name is required'),
    validators.minLength('Last name must be at least 2 characters', 2)
  );

  return (
    <div className={className || css.root}>
      <div className={css.formRow}>
        <FieldTextInput
          id={`${fieldId}.${RECIPIENT_FIRST_NAME_FIELD_NAME}`}
          name={firstNameFieldName}
          className={css.field}
          type="text"
          label="First name"
          placeholder="Jane"
          validate={firstNameValidators}
          onUnmount={() => form.change(firstNameFieldName, undefined)}
        />
        <FieldTextInput
          id={`${fieldId}.${RECIPIENT_LAST_NAME_FIELD_NAME}`}
          name={lastNameFieldName}
          className={css.field}
          type="text"
          label="Last name"
          placeholder="Doe"
          validate={lastNameValidators}
          onUnmount={() => form.change(lastNameFieldName, undefined)}
        />
      </div>
      <div className={css.formRow}>
        <FieldTextInput
          id={firstAddressFieldId}
          name={prefix ? `${prefix}.addressLine1` : 'addressLine1'}
          disabled={disabled}
          className={css.field}
          type="text"
          autoComplete="billing address-line1"
          label={addressLine1Label}
          placeholder={addressLine1Placeholder}
          validate={addressLine1Required}
          onUnmount={() => form.change('addressLine1', undefined)}
        />

        <FieldTextInput
          id={`${fieldId}.addressLine2`}
          name={prefix ? `${prefix}.addressLine2` : 'addressLine2'}
          disabled={disabled}
          className={css.field}
          type="text"
          autoComplete="billing address-line2"
          label={addressLine2Label}
          placeholder={addressLine2Placeholder}
          onUnmount={() => form.change('addressLine2', undefined)}
        />
      </div>
      <div className={css.formRow}>
        <FieldTextInput
          id={`${fieldId}.city`}
          name={prefix ? `${prefix}.city` : 'city'}
          disabled={disabled}
          className={css.field}
          type="text"
          autoComplete="billing address-level2"
          label={cityLabel}
          placeholder={cityPlaceholder}
          validate={cityRequired}
          onUnmount={() => form.change('city', undefined)}
        />
        <FieldTextInput
          id={`${fieldId}.state`}
          name={prefix ? `${prefix}.state` : 'state'}
          disabled={disabled}
          className={css.narrowField}
          type="text"
          autoComplete="billing address-level1"
          label={stateLabel}
          placeholder={statePlaceholder}
          validate={stateRequired}
          onUnmount={() => form.change('state', undefined)}
          maxLength={stateValueMaxLength}
          onChange={(event) => {
            const value = event?.target?.value || '';
            form.change(prefix ? `${prefix}.state` : 'state', value.toUpperCase());
          }}
        />
        <FieldTextInput
          id={`${fieldId}.postalCode`}
          name={prefix ? `${prefix}.postal` : 'postal'}
          disabled={disabled}
          className={css.narrowField}
          type="text"
          autoComplete="billing postal-code"
          label={postalCodeLabel}
          placeholder={postalCodePlaceholder}
          validate={postalCodeRequired}
          onUnmount={() => form.change('postal', undefined)}
          // conditionally include the custom onChange if it's for payment forms
          {...(isBillingForm && { onChange: (event) => handleOnChange(event) })}
        />
      </div>
      <div className={css.formRow}>
        <FieldSelect
          id={`${fieldId}.country`}
          name={countryFieldName}
          className={css.field}
          label={countryLabel}
          validate={
            allowedCountryCodes
              ? validators.composeValidators(
                  countryRequired,
                  validators.countryCodesOnly(
                    allowedCountryCodes,
                    'This country is not currently supported.'
                  )
                )
              : countryRequired
          }
        >
          {(!allowedCountryCodes || allowedCountryCodes.length > 1) && (
            <option disabled value="">
              {countryPlaceholder}
            </option>
          )}
          {(allowedCountryCodes || countryCodes).map((country) => (
            <option key={country.code} value={country.code}>
              {country.name}
            </option>
          ))}
        </FieldSelect>
        <FieldPhoneNumberInput
          id={`${fieldId}.phone`}
          name={prefix ? `${prefix}.phone` : 'phone'}
          disabled={disabled}
          className={css.field}
          type="text"
          autoComplete="billing tel-national"
          label="Phone number"
          placeholder={phonePlaceholder}
          onUnmount={() => form.change('phone', undefined)}
          country={countryValue}
          validate={validators.phoneNumberFormatValid(
            'A valid phone number is required',
            countryValue || userCountryCode
          )}
        />
      </div>
    </div>
  );
};

AddressForm.defaultProps = {
  country: null,
  disabled: false,
  fieldId: null,
  addressType: ADDRESS_FORM_TYPE_BILLING,
  allowedCountryCodes: null,
  prefix: null,
};

AddressForm.propTypes = {
  country: string,
  disabled: bool,
  form: object.isRequired,
  fieldId: string,
  prefix: string,
  addressType: oneOf(ADDRESS_FORM_TYPES),
  allowedCountryCodes: arrayOf(object),
};

export default AddressForm;
