import { Dispatch } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { fetchCurrentUser } from '../../ducks/user.duck';
import { RequestStatus } from '../../types/requestStatus';
import { Listing, ListingItemType, ListingWithAuthor } from '../../types/sharetribe/listing';
import { Uuid } from '../../types/sharetribe/uuid';
import { getShopConfig } from '../../shopConfig/configHelper';
import { ITEM_AVAILABILITY_AVAILABLE } from '../../util/constants';
import { denormalisedResponseEntities } from '../../util/data';
import { storableError } from '../../util/errors';
import * as log from '../../util/log';

// ================ Action types ================ //

const SET_INITIAL_VALUES = 'app/OrderSuccessPage/SET_INITIAL_VALUES';

export const FETCH_ORDER_LISTINGS_REQUEST = 'app/OrderSuccessPage/FETCH_ORDER_LISTINGS_REQUEST';
export const FETCH_ORDER_LISTINGS_SUCCESS = 'app/OrderSuccessPage/FETCH_ORDER_LISTINGS_SUCCESS';
export const FETCH_ORDER_LISTINGS_ERROR = 'app/OrderSuccessPage/FETCH_ORDER_LISTINGS_ERROR';

export const FETCH_SIMILAR_LISTINGS_REQUEST = 'app/OrderSuccessPage/FETCH_SIMILAR_LISTINGS_REQUEST';
export const FETCH_SIMILAR_LISTINGS_SUCCESS = 'app/OrderSuccessPage/FETCH_SIMILAR_LISTINGS_SUCCESS';
export const FETCH_SIMILAR_LISTINGS_ERROR = 'app/OrderSuccessPage/FETCH_SIMILAR_LISTINGS_ERROR';

interface SetInitialValues {
  type: typeof SET_INITIAL_VALUES;
  listings: ListingWithAuthor[];
  orderId: string;
}

interface FetchOrderListingsRequest {
  type: typeof FETCH_ORDER_LISTINGS_REQUEST;
}

interface FetchOrderListingsSuccess {
  type: typeof FETCH_ORDER_LISTINGS_SUCCESS;
}

interface FetchOrderListingsError {
  type: typeof FETCH_ORDER_LISTINGS_ERROR;
}

interface FetchSimilarListingsRequest {
  type: typeof FETCH_SIMILAR_LISTINGS_REQUEST;
}

interface FetchSimilarListingsSuccess {
  type: typeof FETCH_SIMILAR_LISTINGS_SUCCESS;
  similarListingRefs: { id: Uuid; type: string }[];
}

interface FetchSimilarListingsError {
  type: typeof FETCH_SIMILAR_LISTINGS_ERROR;
}

type OrderSuccessPageActionType =
  | SetInitialValues
  | FetchOrderListingsRequest
  | FetchOrderListingsSuccess
  | FetchOrderListingsError
  | FetchSimilarListingsRequest
  | FetchSimilarListingsSuccess
  | FetchSimilarListingsError;

// ================ Reducer ================ //

export interface OrderSuccessPageState {
  fetchOrderListingsStatus: RequestStatus;
  fetchSimilarListingsStatus: RequestStatus;
  listings: ListingWithAuthor[];
  orderId: string | null;
  similarListingRefs: { id: Uuid; type: string }[];
}

const initialState: OrderSuccessPageState = {
  fetchOrderListingsStatus: RequestStatus.Ready,
  fetchSimilarListingsStatus: RequestStatus.Ready,
  listings: [],
  orderId: null,
  similarListingRefs: [],
};

export default function orderSuccessPageReducer(
  state: OrderSuccessPageState = initialState,
  action: OrderSuccessPageActionType
): OrderSuccessPageState {
  switch (action.type) {
    case SET_INITIAL_VALUES: {
      return {
        ...state,
        listings: action.listings,
        orderId: action.orderId,
      };
    }
    case FETCH_ORDER_LISTINGS_REQUEST: {
      return { ...state, fetchOrderListingsStatus: RequestStatus.Pending };
    }
    case FETCH_ORDER_LISTINGS_SUCCESS: {
      return { ...state, fetchOrderListingsStatus: RequestStatus.Success };
    }
    case FETCH_ORDER_LISTINGS_ERROR: {
      return { ...state, fetchOrderListingsStatus: RequestStatus.Error };
    }
    case FETCH_SIMILAR_LISTINGS_REQUEST: {
      return {
        ...state,
        similarListingRefs: [],
        fetchSimilarListingsStatus: RequestStatus.Pending,
      };
    }
    case FETCH_SIMILAR_LISTINGS_SUCCESS: {
      return {
        ...state,
        similarListingRefs: action.similarListingRefs,
        fetchSimilarListingsStatus: RequestStatus.Success,
      };
    }
    case FETCH_SIMILAR_LISTINGS_ERROR: {
      return { ...state, fetchSimilarListingsStatus: RequestStatus.Error };
    }
    default: {
      return state;
    }
  }
}

// ================ Action creators ================ //

export const setInitialValues = (listings: Listing[], orderId: string) => ({
  type: SET_INITIAL_VALUES,
  listings,
  orderId,
});

const fetchOrderListingsRequest = () => ({ type: FETCH_ORDER_LISTINGS_REQUEST });

const fetchOrderListingsSuccess = () => ({ type: FETCH_ORDER_LISTINGS_SUCCESS });

const fetchOrderListingsError = () => ({ type: FETCH_ORDER_LISTINGS_ERROR });

const fetchSimilarListingsRequest = () => ({ type: FETCH_SIMILAR_LISTINGS_REQUEST });

const fetchSimilarListingsSuccess = (similarListingRefs: { id: Uuid; type: string }[]) => ({
  type: FETCH_SIMILAR_LISTINGS_SUCCESS,
  similarListingRefs,
});

const fetchSimilarListingsError = () => ({ type: FETCH_SIMILAR_LISTINGS_ERROR });

// ================ Thunks ================ //

export const fetchOrderListings =
  (listingIds: string[]) => async (dispatch: Dispatch, getState: () => any, sdk: any) => {
    const { orderId } = getState().OrderSuccessPage;
    dispatch(fetchOrderListingsRequest());

    try {
      const listingResponse = await Promise.all(
        listingIds.map((listingId) =>
          sdk.listings.show({
            id: listingId,
            include: ['author'],
          })
        )
      );

      const listings = listingResponse.map((listingWithAuthor) => {
        const listing = denormalisedResponseEntities(listingWithAuthor)[0];
        return listing;
      }) as ListingWithAuthor[];

      dispatch(setInitialValues(listings, orderId));
      dispatch(fetchOrderListingsSuccess());
    } catch (e) {
      const error = storableError(e);
      log.error(e, 'fetch-order-listings-failed', error);
      dispatch(fetchOrderListingsError());
    }
  };

export const fetchSimilarListings =
  () => async (dispatch: Dispatch, getState: () => any, sdk: any) => {
    const { treetId, shopConfig: shopConfigV2 } = getState().initial;
    const { listings } = getState().OrderSuccessPage;
    const { shopName, sizeVariantOptionName } = getShopConfig(treetId, shopConfigV2);

    dispatch(fetchSimilarListingsRequest());

    try {
      const listingsSizes = listings.map(
        (listing: Listing) => listing.attributes.publicData?.[sizeVariantOptionName]
      );
      const listingsCategories = listings.map(
        (listing: Listing) => listing.attributes.publicData?.category
      );
      const listingsResponse = await sdk.listings.query({
        include: ['author', 'images'],
        'fields.image': [
          'variants.landscape-crop',
          'variants.landscape-crop2x',
          'variants.default',
        ],
        pub_shopName: shopName,
        pub_availability: ITEM_AVAILABILITY_AVAILABLE,
        // Filter on similar sizes unless the listings don't have sizes, in which case
        // we filter on similar categories.
        ...(!!listingsSizes.length && { pub_size: listingsSizes }),
        ...(!listingsSizes.length && { pub_category: listingsCategories }),
        sort: 'pub_random',
        pub_listingItemType: ListingItemType.Marketplace,
      });
      const similarListings = denormalisedResponseEntities(listingsResponse);

      // Pick only the id and type properties from the response listings
      const listingRefs = similarListings.map((listing) => ({
        id: listing.id,
        type: listing.type,
      }));

      dispatch(addMarketplaceEntities(listingsResponse));
      dispatch(fetchSimilarListingsSuccess(listingRefs));
    } catch (e) {
      const error = storableError(e);
      log.error(e, 'fetch-similar-listings-failed', error);
      dispatch(fetchSimilarListingsError());
    }
  };

export const loadData =
  (params: { id: string }) => (dispatch: ThunkDispatch<any, any, any>, getState: () => any) => {
    const state = getState().OrderSuccessPage;
    const { listings, orderId } = state;

    // orderId might not be set if the page was refreshed
    if (!orderId) {
      dispatch(fetchCurrentUser());
      dispatch(setInitialValues(listings, params.id));
    }
    return Promise.all([]);
  };
