import { DisputeReason } from '../types/apollo/generated/types.generated';

// TODO (TREET-4551): Use a dummy site like (treeton.treet) to hold the mock data. That way we can edit them in Contentful.
export const MOCK_TRADE_IN_CONFIG = {
  tradeInShipToAddress: {
    zip: '94105',
    city: 'Carson',
    name: 'Treet',
    state: 'CA',
    country: 'US',
    street1: '535 Mission St.',
  },
  tradeInCreditPayoutOptionsCollection: {
    items: [
      {
        key: DisputeReason.DamagedButAcceptable,
        label: 'Damaged but acceptable - 25%',
        payoutFixedAmount: null,
        payoutPercentage: null,
        payoutPercentageOfOriginalPrice: 0.25,
      },
      {
        key: DisputeReason.InauthenticOrExcessiveDamage,
        label: 'Reject - 0%',
        payoutFixedAmount: null,
        payoutPercentage: 0,
        payoutPercentageOfOriginalPrice: null,
      },
    ],
  },
  enableSelectableQuirks: true,
  tradeInItemDetailsFormSections: [
    'VARIANT_OPTIONS',
    'CONDITION',
    'TRADE_IN_PRICE',
    'ACKNOWLEDGEMENT_CHECKBOX',
  ],
};
