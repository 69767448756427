import { getConditionsFilterConfig } from '../filters/condition';
import { getDefaultShopConfig } from './default';
import * as custom from '../filters/marketplace-custom-config';
import { getSizeFilterConfig } from '../filters/size';

const loveandgrowclothingSizes = [
  '6M',
  '12M',
  '18M',
  '24M',
  '2T',
  '3T',
  '4T',
  '5Y',
  '6Y',
  '7Y',
  '8Y',
  '10Y',
  '12Y',
  'XS',
  'S',
  'M',
  'L',
  'XL',
  'XXL',
];

export const loveandgrowclothingShopConfig = {
  ...getDefaultShopConfig(),
  filters: [
    ...custom.filters,
    getConditionsFilterConfig(),
    getSizeFilterConfig({ sizes: loveandgrowclothingSizes }),
  ],
};
