import { Conditions, getConditionsFilterConfig } from '../filters/condition';
import { getDefaultShopConfig } from './default';
import * as custom from '../filters/marketplace-custom-config';
import { getSizeFilterConfig } from '../filters/size';

export const pistollakeConditionOptions = [
  {
    key: Conditions.NewWithTags,
    label: 'Brand New',
    description: 'Your item has never been worn.',
  },
  {
    key: Conditions.Excellent,
    label: 'Excellent Condition',
    description:
      'Your item has only been lightly used and has been extremely well maintained. There may be some small signs of wear, but nothing major.',
  },
  {
    key: Conditions.Good,
    label: 'Good Condition',
    description:
      'You’ve worn this item and it’s well maintained. There may be some quirks like a small stain, a button missing, or a loose thread, but it’s overall in good shape. You will need to take pictures of any quirks the item has.',
  },
];

export const pistollakeShopConfig = {
  ...getDefaultShopConfig(),
  filters: [
    ...custom.filters,
    getConditionsFilterConfig({ options: pistollakeConditionOptions }),
    getSizeFilterConfig({
      sizes: ['XS', 'S', 'M', 'L', 'XL', 'XXL', '3XL'],
    }),
  ],
};
