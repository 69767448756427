import React, { FC } from 'react';
import { Box } from '@material-ui/core';
import css from './ListingFeedbackModal.module.css';
import TypographyWrapper, { TypographyWeight } from '../TypographyWrapper/TypographyWrapper';
import { useShopConfig } from '../../hooks/shopConfig';
import { useIsMobile } from '../../hooks/useIsMobile';

const ListingFeedbackHeader: FC<{ headerCopy?: string }> = (props) => {
  const { headerCopy } = props;
  const { css: brandCss } = useShopConfig();
  const bgcolor = brandCss?.backgroundColor || 'white';
  const isMobile = useIsMobile();

  return (
    <Box
      display="flex"
      flexDirection="row"
      alignItems="center"
      justifyContent="center"
      py={2}
      className={css.header}
      bgcolor={bgcolor}
    >
      <TypographyWrapper
        variant={isMobile ? 'subtitle1' : 'h2'}
        weight={TypographyWeight.Bold}
        typographyOverrides={{ align: 'center', style: { marginRight: '8px' } }}
      >
        {headerCopy || 'Request Listing Update'}
      </TypographyWrapper>
    </Box>
  );
};

export default ListingFeedbackHeader;
