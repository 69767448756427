import { getConditionsFilterConfig } from '../filters/condition';
import { getDefaultShopConfig } from './default';
import * as custom from '../filters/marketplace-custom-config';
import { getSizeFilterConfig } from '../filters/size';

const miakodaSizes = ['xs', 's', 'm', 'l', 'xl', 'xxl', '3xl', '4xl', '1', '2', '3', '4'];

export const miakodaShopConfig = {
  ...getDefaultShopConfig(),
  filters: [
    ...custom.filters,
    getConditionsFilterConfig(),
    getSizeFilterConfig({ sizes: miakodaSizes }),
  ],
};
