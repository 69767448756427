import { getDefaultShopConfig } from './default';
import * as custom from '../filters/marketplace-custom-config';
import { getConditionsFilterConfig } from '../filters/condition';
import { getSizeFilterConfig } from '../filters/size';
import { User } from '../../types/sharetribe/user';

export const treetonSizes = ['XS', 'S', 'M', 'L', 'XL'];

// Testing for Tecovas
const handleTreetonCustomCreditDescription = (data: {
  bundle: {
    shipFromAddress: { state: string };
  };
  recipient: User;
}) => {
  const { state } = data.bundle?.shipFromAddress || {};
  const { email } = data.recipient?.attributes || {};

  return `Email: ${email}, State: ${state || 'Unknown'}`;
};

export const treetonShopConfig = {
  ...getDefaultShopConfig(),
  filters: [
    ...custom.filters,
    getConditionsFilterConfig(),
    getSizeFilterConfig({ sizes: treetonSizes }),
  ],
  additionalPayoutSettings: {
    buildCustomCreditDescription: handleTreetonCustomCreditDescription,
  },
};
