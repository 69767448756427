// This file is based off of ST sdk's memory store implementation
// https://github.com/sharetribe/flex-sdk-js/blob/master/src/memory_store.js.

import { SharetribeAuthTokenData } from './tokenStoreUtils';

const createStore = () => {
  let memo: SharetribeAuthTokenData | null;

  const getToken = () => memo;
  const setToken = (tokenData: SharetribeAuthTokenData) => {
    memo = tokenData;
  };
  const removeToken = () => {
    memo = null;
  };

  return {
    getToken,
    setToken,
    removeToken,
  };
};

export default createStore;
