import React, { FC } from 'react';
import classNames from 'classnames';
import { BrandDirectIconType } from '../../../types/shopConfig/shopConfigV2';
import {
  Icon25Off,
  Icon30Off,
  Icon50Off,
  Icon70Off,
  IconBrandDirect,
  IconDL1961Direct,
  IconDoppleDirect,
  IconElizabethSuzannDirect,
  IconGoldieAndAceDirect,
} from '../..';
import { useShopConfigV2 } from '../../../hooks/shopConfig';
import { useShopCss } from '../../../hooks/useShopCss';
import { useActivePromos } from '../../../hooks/useActivePromos';
import CustomIcon from '../../CustomIcon/CustomIcon';
import css from './IconBrandDirectExplainer.module.css';

interface IconBrandDirectExplainerProps {
  className?: string;
}

const IconBrandDirectExplainer: FC<IconBrandDirectExplainerProps> = (props) => {
  const { className } = props;

  const { brandDirectIconType } = useShopConfigV2();
  const { brandDirectIcon } = useShopCss();
  const { activePromoBrandDirectIconUrl } = useActivePromos();

  const brandDirectIconClasses = classNames(css.brandDirectIcon, className);
  const customBrandDirectIconClasses = classNames(css.customBrandDirectIcon, className);

  // If the brand has a promo active that includes
  // a custom brand direct icon, use that here.
  if (activePromoBrandDirectIconUrl) {
    return (
      <CustomIcon
        url={activePromoBrandDirectIconUrl}
        altText="brand-direct-promo"
        className={customBrandDirectIconClasses}
        imgStyleOverrides={{ height: 'auto' }}
      />
    );
  }

  if (brandDirectIcon) {
    return (
      <CustomIcon
        url={brandDirectIcon.url}
        altText="brand-direct"
        className={customBrandDirectIconClasses}
        imgStyleOverrides={{ height: 'auto' }}
      />
    );
  }

  switch (brandDirectIconType) {
    case BrandDirectIconType.Default:
      return <IconBrandDirect className={brandDirectIconClasses} />;
    case BrandDirectIconType.Dopple:
      return <IconDoppleDirect className={brandDirectIconClasses} />;
    case BrandDirectIconType.GoldieAndAce:
      return <IconGoldieAndAceDirect className={brandDirectIconClasses} />;
    case BrandDirectIconType.ElizabethSuzann:
      return <IconElizabethSuzannDirect className={brandDirectIconClasses} />;
    case BrandDirectIconType.DL1961:
      return <IconDL1961Direct className={brandDirectIconClasses} />;
    case BrandDirectIconType.Sale25Off:
      return <Icon25Off className={brandDirectIconClasses} />;
    case BrandDirectIconType.Sale30Off:
      return <Icon30Off className={brandDirectIconClasses} />;
    case BrandDirectIconType.Sale50Off:
      return <Icon50Off className={brandDirectIconClasses} />;
    case BrandDirectIconType.Sale70Off:
      return <Icon70Off className={brandDirectIconClasses} />;
    case BrandDirectIconType.None:
      return null;
    default:
      return <IconBrandDirect className={brandDirectIconClasses} />;
  }
};

export default IconBrandDirectExplainer;
