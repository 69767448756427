import { getDefaultShopConfig } from './default';
import * as custom from '../filters/marketplace-custom-config';
import { getBrandDirectFilterConfig } from '../filters/brandDirect';
import { getConditionsFilterConfig } from '../filters/condition';
import { getSizeFilterConfigFromOptions } from '../filters/size';

const vettaSizeOptions = [
  { key: 'XS', label: 'XS' },
  { key: 'S', label: 'S' },
  { key: 'M', label: 'M' },
  { key: 'L', label: 'L' },
  { key: 'XL', label: 'XL' },
  { key: '00', label: '00' },
  { key: '0', label: '0' },
  { key: '2', label: '2' },
  { key: '4', label: '4' },
  { key: '6', label: '6' },
  { key: '8', label: '8' },
  { key: '10', label: '10' },
  { key: '12', label: '12' },
  { key: '14', label: '14' },
  { key: '16', label: '16' },
  { key: '18', label: '18' },
];

export const vettaShopConfig = {
  ...getDefaultShopConfig(),
  filters: [
    ...custom.filters,
    getBrandDirectFilterConfig(),
    getSizeFilterConfigFromOptions({ options: vettaSizeOptions }),
    getConditionsFilterConfig(),
  ],
};
