import React, { FC } from 'react';
import { useIconColor } from '../../../hooks/useIconColor';

interface IconSpeechBubbleProps {
  className?: string;
  color?: string;
}

const IconSpeechBubble: FC<IconSpeechBubbleProps> = (props: IconSpeechBubbleProps) => {
  const { className, color } = props;

  const { iconColor } = useIconColor();
  const fillColor = color || iconColor;

  return (
    <svg
      className={className}
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 11C6 9.89543 6.89543 9 8 9H24C25.1046 9 26 9.89543 26 11V21C26 22.1046 25.1046 23 24 23C22.3431 23 21 24.3431 21 26V26.6309L16.8058 24.3431C15.19 23.4618 13.3789 23 11.5384 23H8C6.89543 23 6 22.1046 6 21V11ZM8 7C5.79086 7 4 8.79086 4 11V21C4 23.2091 5.79086 25 8 25H11.5384C13.0443 25 14.5261 25.3778 15.8481 26.0989L21.5212 29.1934C22.1875 29.5568 23 29.0745 23 28.3155V26C23 25.4477 23.4477 25 24 25C26.2091 25 28 23.2091 28 21V11C28 8.79086 26.2091 7 24 7H8ZM11 18C12.1046 18 13 17.1046 13 16C13 14.8954 12.1046 14 11 14C9.89543 14 9 14.8954 9 16C9 17.1046 9.89543 18 11 18ZM18 16C18 17.1046 17.1046 18 16 18C14.8954 18 14 17.1046 14 16C14 14.8954 14.8954 14 16 14C17.1046 14 18 14.8954 18 16ZM21 18C22.1046 18 23 17.1046 23 16C23 14.8954 22.1046 14 21 14C19.8954 14 19 14.8954 19 16C19 17.1046 19.8954 18 21 18Z"
        fill={fillColor}
      />
    </svg>
  );
};

export default IconSpeechBubble;
