import React from 'react';
import classNames from 'classnames';
import { useIconColor } from '../../../hooks/useIconColor';
import { CustomIconType, useCustomIcon } from '../../../hooks/useCustomIcon';
import CustomIcon from '../../CustomIcon/CustomIcon';

interface IconSearchProps {
  rootClassName?: string;
  className?: string;
  color?: string;
}

const IconSearch: React.FC<IconSearchProps> = (props) => {
  const { className, rootClassName, color } = props;
  const classes = classNames(rootClassName, className);

  const customSearchIcon = useCustomIcon(CustomIconType.Search);
  const { iconColor } = useIconColor();

  if (customSearchIcon) {
    return <CustomIcon url={customSearchIcon.icon.url} altText="search" className={classes} />;
  }

  const fillColor = color || iconColor;

  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.8055 19.3913C23.248 16.2528 23.0269 11.7132 20.1421 8.82844C17.018 5.70424 11.9526 5.70424 8.82844 8.82844C5.70424 11.9526 5.70424 17.018 8.82844 20.1421C11.7132 23.0269 16.2528 23.248 19.3913 20.8055L24.3848 25.799L25.799 24.3848L20.8055 19.3913ZM18.7279 10.2427C21.0711 12.5858 21.0711 16.3848 18.7279 18.7279C16.3848 21.0711 12.5858 21.0711 10.2426 18.7279C7.8995 16.3848 7.8995 12.5858 10.2426 10.2427C12.5858 7.8995 16.3848 7.8995 18.7279 10.2427Z"
        fill={fillColor}
      />
    </svg>
  );
};

export default IconSearch;
