import {
  Conditions,
  defaultClothesConditionsOptions,
  getConditionsFilterConfig,
} from '../filters/condition';
import { getDefaultShopConfig } from './default';
import * as custom from '../filters/marketplace-custom-config';
import { getSizeFilterConfig } from '../filters/size';

export const sozyShopConfig = {
  ...getDefaultShopConfig(),
  filters: [
    ...custom.filters,
    getConditionsFilterConfig({
      options: [
        ...defaultClothesConditionsOptions,
        {
          key: Conditions.MinorDefect,
          label: 'Minor Defect',
          description:
            'The item has a minor defect such as an uneven hem, unraveling seam, missing button, or loose strap that could be fixed by a willing buyer. Please price accordingly.',
        },
      ],
    }),
    getSizeFilterConfig({
      sizes: ['XS', 'S', 'M', 'L', 'XL', 'XXL', 'One Size', 'O/S', 'XS/S', 'S/M', 'M/L'],
    }),
  ],
};
