import React, { FC } from 'react';
import { useIconColor } from '../../../hooks/useIconColor';
import { CustomIconType, useCustomIcon } from '../../../hooks/useCustomIcon';
import CustomIcon from '../../CustomIcon/CustomIcon';

interface IconFilterProps {
  className?: string;
  color?: string;
}

const IconFilter: FC<IconFilterProps> = (props: IconFilterProps) => {
  const { className, color } = props;

  const customFilterIcon = useCustomIcon(CustomIconType.Filter);
  const { iconColor } = useIconColor();

  if (customFilterIcon) {
    return <CustomIcon url={customFilterIcon.icon.url} altText="filter" className={className} />;
  }

  const fillColor = color || iconColor;

  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24 12L15.8293 12C15.4175 10.8348 14.3062 10 13 10C11.6938 10 10.5825 10.8348 10.1707 12H8V14H10.1707C10.5825 15.1652 11.6938 16 13 16C14.3062 16 15.4175 15.1652 15.8293 14L24 14V12ZM21.8293 18H24V20H21.8293C21.4175 21.1652 20.3062 22 19 22C17.6938 22 16.5825 21.1652 16.1707 20H8V18H16.1707C16.5825 16.8348 17.6938 16 19 16C20.3062 16 21.4175 16.8348 21.8293 18Z"
        fill={fillColor}
      />
    </svg>
  );
};

export default IconFilter;
