/* eslint-disable import/prefer-default-export */
import { getSizeFilterConfig } from '../filters/size';
import { Conditions, getConditionsFilterConfig } from '../filters/condition';
import { getDefaultShopConfig, getDefaultTradeInShopConfig } from './default';
import * as custom from '../filters/marketplace-custom-config';
import { BuilderSections } from '../../util/builder';
import { AboutTreetPages } from '../../types/shopConfig/shopConfigV2';
import { Feature, isFeatureEnabled } from '../../util/featureFlags';

const charlottestoneSizes = ['4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15'];

const charlottestoneAdditionalPayoutSettings = {
  additionalShopifyDiscountCodeRequirements: {
    // TODO extract into a fragment
    mutation: { minimumRequirement: { subtotal: { greaterThanOrEqualToSubtotal: '198.00' } } },
  },
  creditCodeHelperText:
    'Code can only be used on charlotte-stone.com for purchases over $100. Limit one code per checkout.',
};

const charlottestoneConditionOptions = [
  {
    key: Conditions.NewWithoutTags,
    label: 'Unworn',
    description:
      'Your shoes have never been worn (other than perhaps trying it on). There are no quirks or issues whatsoever.',
  },
  {
    key: Conditions.Excellent,
    label: 'Excellent Condition',
    description:
      'Your shoes have only been lightly used and have been extremely well maintained. There may be some small signs of wear, but nothing major.',
  },
  {
    key: Conditions.Good,
    label: 'Good Condition',
    description:
      'You’ve worn the shoes and they’re well maintained. There may be some quirks like a small stain or a scuff mark, but they’re overall in good shape.',
  },
];

let builderConfig: { sections: { [key: string]: string } } = {
  sections: {
    [BuilderSections.AboutPageHeroContent]: '324a7714bcb14161adda40b7ad14f2c4',
    [BuilderSections.AboutPageNavbarContent]: '52d78fb1b8fd4fa5b41e979c3c5692b6',
    [BuilderSections.AboutPageInfoTabContent]: '40cdc37dbab648dbad8fc4d45da0e143',
    [BuilderSections.ListingPageTitleAndVariantDetailsContent]: 'c6c61c63ecdc408c9f39cd3a4cfac98b',
  },
};

if (isFeatureEnabled(Feature.CSBuy, 'charlotte-stone')) {
  builderConfig = {
    sections: {
      [BuilderSections.LandingPageContent]: 'd4da8ec53198415b8638411883a464ac',
      [BuilderSections.AboutPageHeroContent]: 'f3575e707f4d49008bc0f5fbdcb8a3e2',
      [BuilderSections.AboutPageNavbarContent]: '52d78fb1b8fd4fa5b41e979c3c5692b6',
      [BuilderSections.AboutPageInfoTabContent]: '52f740f516204a32ae20fb55b5876fbc',
      [BuilderSections.ListingPageTitleAndVariantDetailsContent]:
        'c6c61c63ecdc408c9f39cd3a4cfac98b',
      [BuilderSections.ListingCardDetails]: '71dd933e6fae4af390c4d8c7d3533785',
    },
  };
}

export const charlottestoneShopConfig = {
  ...getDefaultShopConfig(),
  ...getDefaultTradeInShopConfig(),
  filters: [
    ...custom.filters,
    getConditionsFilterConfig({ options: charlottestoneConditionOptions }),
    getSizeFilterConfig({ sizes: charlottestoneSizes }),
  ],
  hideHeaderLogoAtPathnames: ['/about'],
  additionalFooterSections: [
    { title: 'About Charlotte Stone', link: 'https://charlotte-stone.com/pages/about' },
    { title: 'About CS Rewards', link: 'https://charlotte-stone.com/pages/rewards' },
  ],
  enabledAboutTreetPages: [AboutTreetPages.AboutTreet],
  builderConfig,
  additionalPayoutSettings: charlottestoneAdditionalPayoutSettings,
};
