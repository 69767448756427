import { getFilterOptions } from '../../shopConfig/configHelper';
import { FilterId } from '../../types/filters/filters';
import { Product } from '../../types/shopify/product';
import { hasIntersection } from '../../util/utilityFunctions';

export enum ShopifyProductsSearchFormField {
  Category = 'category',
  Categories = 'categories',
  Search = 'search',
  Sku = 'sku',
  IsSearchActive = 'isSearchActive',
}

// Keep in sync with canProductBeShownForNewListings in server/utils/shopify.ts
export const canProductBeShown = (product: Product, shopConfig: any) => {
  // TODO(SY): This is how we're currently checking if a product is able to show or not. This
  // needs to be changed. Need to also display something to tell the user why it can't be shown.
  const { blockedTags = [], blockedProductTypes = [], allowedTags } = shopConfig;
  const sanitizedProductType = product.productType.toLowerCase();
  const sanitizedTags = product?.tags?.map((tag: string) => tag.toLowerCase());
  const sanitizedBlockedTags = blockedTags?.map((tag: string) => tag.toLowerCase());
  const sanitizedBlockedProductTypes = blockedProductTypes.map((productType: string) =>
    productType.toLowerCase()
  );
  const sanitizedAllowedTags = allowedTags?.map((tag: string) => tag.toLowerCase());

  if (hasIntersection(sanitizedTags, sanitizedBlockedTags)) {
    return false;
  }

  if (sanitizedBlockedProductTypes.includes(sanitizedProductType)) {
    return false;
  }

  if (sanitizedAllowedTags?.length > 0 && !hasIntersection(sanitizedTags, sanitizedAllowedTags)) {
    // For some brands we only want to allow a specified subset of products (i.e. doen)
    return false;
  }

  return true;
};

export const FIND_ITEM_METHOD = 'findItemMethod';

export const getProductVariantQuery = (
  optionFields: any[],
  optionValues: any,
  shopifyProductId?: string,
  sizeVariantOptionName?: string
) => {
  // Gets id from the global id like gid://shopify/Product/3935994576968
  const productId = shopifyProductId?.split('/')?.slice(-1)?.[0];
  let query = `product_id:"${productId}"`;

  const isSizeOptionField = (optionField: string) =>
    optionField.toLowerCase() === sizeVariantOptionName?.toLowerCase();

  // The product variant title is a concatenation of all of the selected options for the variant.
  // We query for titles that contain the options, except the size variant since
  // we use custom sizes that are often not an exact match to the Shopify sizing.
  // Returned variants are filtered down to a likely match in `findMatchingProductVariant`.
  const additionalQuery = optionFields
    ?.map((optionField) => {
      if (!isSizeOptionField(optionField)) {
        return `AND title:*${optionValues[optionField]}*`;
      }
      return null;
    })
    ?.join(' ');

  query += ` ${additionalQuery}`;
  return query;
};

export const shouldFillVariantOption = (variantOption: any, shopConfig: any) => {
  const { sizeVariantOptionName } = shopConfig;
  const variantName = variantOption.name.toLowerCase();
  if (variantName !== sizeVariantOptionName) return true;

  // If this is the size variant option, make sure the value matches a treet size filter option.
  // Otherwise do not auto-fill in the variant option.
  const staticSizeConfig = getFilterOptions(shopConfig, FilterId.Size);
  const sizes = staticSizeConfig ? staticSizeConfig.map((sizeConfig: any) => sizeConfig.key) : [];
  if (sizes.includes(variantOption.value)) return true;
  return false;
};
