/* eslint-disable import/prefer-default-export */
import { getDefaultShopConfig } from './default';
import * as custom from '../filters/marketplace-custom-config';
import { getBrandDirectFilterConfig } from '../filters/brandDirect';
import { getConditionsFilterConfig } from '../filters/condition';
import { getSizeFilterConfig } from '../filters/size';
import { BuilderSections } from '../../util/builder';

const jordancraigSizes = ['XXS', 'XS', 'S', 'M', 'L', 'XL', 'XXL'];

export const jordancraigShopConfig = {
  ...getDefaultShopConfig(),
  filters: [
    ...custom.filters,
    getConditionsFilterConfig(),
    getBrandDirectFilterConfig(),
    getSizeFilterConfig({ sizes: jordancraigSizes }),
  ],
  builderConfig: {
    sections: {
      [BuilderSections.LandingPageContent]: '5f31cf7316ed41889eb7a3860fbd149b',
      [BuilderSections.ListingCardDetails]: '2d05cb4b07e645c0a2b7fddad42794bb',
    },
  },
};
