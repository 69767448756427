/* eslint-disable import/prefer-default-export */
import { useDispatch, useSelector } from 'react-redux';
import { ModalState, ModalType, resetActiveModal, setActiveModal } from '../ducks/modal.duck';

/**
 * @param modalType Should be a type in the ModalType enum unless you need to render
 * a list of components that open the same modal type. In this case, use a unique key
 * prefaced with a ModalType, e.g. `${ModalType.CancelItemRequest}-${savedSearch.id}`.
 */
export const useActiveModal = (modalType: ModalType | string) => {
  const dispatch = useDispatch();
  const { activeModal } = useSelector<any>((state) => state.modal) as ModalState;
  const isModalOpen = activeModal === modalType;
  const openModal = () => dispatch(setActiveModal(modalType));
  const closeModal = () => dispatch(resetActiveModal());
  return { isModalOpen, openModal, closeModal };
};
