import { Conditions, getConditionsFilterConfig } from '../filters/condition';
import { getDefaultShopConfig } from './default';
import * as custom from '../filters/marketplace-custom-config';

export const unhideConditionOptions = [
  {
    key: Conditions.NewWithTags,
    label: 'New With Tags',
    description:
      'Your item has never been used and it contains original packaging. For this option, please take photos of the product in its original packaging.',
  },
  {
    key: Conditions.NewWithoutTags,
    label: 'New Without Tags',
    description:
      'Your item has never been used and the original hanging tags are still on. For this option, you’ll need to take pictures of the retail tags.',
  },
  {
    key: Conditions.Excellent,
    label: 'Excellent Condition',
    description:
      'Your item has only been slightly used and has been extremely well maintained. There may be some small signs of wear, but nothing major.',
  },
  {
    key: Conditions.Good,
    label: 'Good Condition',
    description:
      'You’ve used this item and it’s well maintained. There may be some quirks like matted material, but it’s overall in good shape. Please take pictures of any quirks the item has.',
  },
];

export const unhideShopConfig = {
  ...getDefaultShopConfig(),
  filters: [...custom.filters, getConditionsFilterConfig({ options: unhideConditionOptions })],
};
