import { isEmpty } from 'lodash';
import config from '../shopConfig/config';
import { CountryCode } from '../types/apollo/generated/types.generated';
import { CONDITIONS, ITEM_AVAILABILITY_AVAILABLE } from './constants';
import { getConditionLabel } from './listings/listing';
import { getAllowedShipToCountryCodes } from './shipping';

const ensureOpenGraphLocale = (locale) => {
  switch (locale) {
    case 'en':
      return 'en_US';
    default:
      return locale;
  }
};

/**
 * These will be used with Helmet <meta {...openGraphMetaProps} />
 */
export const openGraphMetaProps = (data) => {
  const {
    canonicalRootURL,
    contentType,
    description,
    facebookAppId,
    facebookImages,
    locale,
    published,
    siteTitle,
    tags,
    title,
    updated,
    url,
  } = data;

  if (!(title && description && contentType && url && facebookImages && canonicalRootURL)) {
    /* eslint-disable no-console */
    if (console && console.warn) {
      console.warn(
        `Can't create Openg Graph meta tags:
        title, description, contentType, url, facebookImages, and canonicalRootURL are needed.`
      );
    }
    /* eslint-enable no-console */
    return [];
  }

  const openGraphMeta = [
    { property: 'og:description', content: description },
    { property: 'og:title', content: title },
    { property: 'og:type', content: contentType },
    { property: 'og:url', content: url },
    { property: 'og:locale', content: ensureOpenGraphLocale(locale) },
  ];

  if (facebookImages && facebookImages.length > 0) {
    facebookImages.forEach((i) => {
      openGraphMeta.push({
        property: 'og:image',
        content: i.url,
      });

      if (i.width && i.height) {
        openGraphMeta.push({ property: 'og:image:width', content: i.width });
        openGraphMeta.push({ property: 'og:image:height', content: i.height });
      }
    });
  }

  if (siteTitle) {
    openGraphMeta.push({ property: 'og:site_name', content: siteTitle });
  }

  if (facebookAppId) {
    openGraphMeta.push({ property: 'fb:app_id', content: facebookAppId });
  }

  if (published) {
    openGraphMeta.push({ property: 'article:published_time', content: published });
  }

  if (updated) {
    openGraphMeta.push({ property: 'article:modified_time', content: updated });
  }

  if (tags) {
    openGraphMeta.push({ property: 'article:tag', content: tags });
  }

  return openGraphMeta;
};

/**
 * These will be used with Helmet <meta {...twitterMetaProps} />
 */
export const twitterMetaProps = (data) => {
  const {
    canonicalRootURL,
    description,
    siteTwitterHandle,
    title,
    twitterHandle,
    twitterImages,
    url,
  } = data;

  if (!(title && description && siteTwitterHandle && url)) {
    /* eslint-disable no-console */
    if (console && console.warn) {
      console.warn(
        `Can't create twitter card meta tags:
        title, description, siteTwitterHandle, and url are needed.`
      );
    }
    /* eslint-enable no-console */
    return [];
  }

  const twitterMeta = [
    { name: 'twitter:card', content: 'summary_large_image' },
    { name: 'twitter:title', content: title },
    { name: 'twitter:description', content: description },
    { name: 'twitter:site', content: siteTwitterHandle },
    { name: 'twitter:url', content: url },
  ];

  if (canonicalRootURL && twitterImages && twitterImages.length > 0) {
    twitterImages.forEach((i) => {
      twitterMeta.push({
        name: 'twitter:image',
        content: i.url,
      });
    });
  }

  if (twitterHandle) {
    // TODO: If we want to connect providers twitter account on ListingPage
    // we needs to get this info among listing data (API support needed)
    twitterMeta.push({ name: 'twitter:creator', content: twitterHandle });
  }

  if (canonicalRootURL) {
    twitterMeta.push({ name: 'twitter:domain', content: canonicalRootURL });
  }

  return twitterMeta;
};

/**
 * These will be used with Helmet <meta {...metaTagProps} />
 * Creates data for Open Graph and Twitter meta tags.
 */
export const metaTagProps = (tagData) => {
  const { canonicalRootURL, facebookAppId, siteTitle, siteTwitterHandle } = config;

  const author = tagData.author || siteTitle;
  const defaultMeta = [
    { name: 'description', content: tagData.description },
    { name: 'author', content: author },
    tagData.noindex ? { name: 'robots', content: 'noindex' } : {},
  ];

  const openGraphMeta = openGraphMetaProps({
    ...tagData,
    canonicalRootURL,
    facebookAppId,
    siteTitle,
  });

  const twitterMeta = twitterMetaProps({
    ...tagData,
    canonicalRootURL,
    siteTwitterHandle,
  });

  return [...defaultMeta, ...openGraphMeta, ...twitterMeta];
};

export const getStructuredMarkupAvailability = (listing) =>
  listing?.attributes?.publicData?.availability === ITEM_AVAILABILITY_AVAILABLE
    ? 'https://schema.org/InStock'
    : 'https://schema.org/OutOfStock';

export const getStructuredMarkupItemCondition = (listing) =>
  listing?.attributes?.publicData?.condition ===
  (CONDITIONS.NEW_WITHOUT_TAGS || CONDITIONS.NEW_WITH_TAGS)
    ? 'https://schema.org/NewCondition'
    : 'https://schema.org/UsedCondition';

export const createListingDescriptionForMarkup = (
  listing,
  showProductDescription,
  filters,
  hideIsBrandUser = false
) => {
  const { isBrandDirect, conditionInfo } = listing.attributes.publicData;
  const quirks = conditionInfo?.quirks;

  const descriptionContent = [
    { name: 'Seller Notes: ', content: conditionInfo?.notes },
    { name: 'Condition: ', content: getConditionLabel(conditionInfo?.condition, filters) },
    quirks && { name: 'Quirks: ', content: quirks },
    showProductDescription && {
      name: 'Product Details: ',
      content: listing.attributes.publicData?.shopifyProduct?.description,
    },
    isBrandDirect &&
      !hideIsBrandUser && {
        name: '',
        content: `This item is sold directly from ${listing.attributes.publicData.shopName}, and might be samples, production units, returned items, or more`,
      },
    { name: '', content: listing.attributes.description },
  ];

  return descriptionContent
    .map((obj) => obj?.content && `${obj?.name}${obj?.content}`)
    .filter((item) => item)
    .join('; ');
};

// Meta description should be shorter than the markup description.
export const createListingDescriptionForMeta = (
  shopName,
  treetShopName,
  listing,
  filters,
  author,
  sizeVariantOptionName,
  variantOptions
) => {
  const { title, price } = listing?.attributes || {};
  const { category, conditionInfo, originalPrice, isBrandDirect, shopifyProduct } =
    listing?.attributes?.publicData || {};
  const { displayName, publicData: userPublicData } = author?.attributes?.profile || {};
  const { hideIsBrandUser } = userPublicData || {};

  const itemCategory = shopifyProduct
    ? shopifyProduct?.productType
    : category === 'UNKNOWN'
    ? ''
    : category;

  const variantOptionText = variantOptions
    ?.map((variantOption) => {
      const optionValue = listing?.attributes?.publicData?.[variantOption];
      if (variantOption === sizeVariantOptionName && !isEmpty(optionValue)) {
        return `${sizeVariantOptionName} ${optionValue}`;
      }
      return optionValue;
    })
    ?.join(', ');

  // Some condition labels have the text "condition" in them, some do not.
  const condition = (
    getConditionLabel(conditionInfo?.condition, filters) || 'secondhand'
  ).toLowerCase();
  const conditionText = condition.includes('condition') ? condition : `${condition} condition`;

  let discountText = '';
  if (originalPrice) {
    const discount = Math.round(((originalPrice - price.amount / 100) / originalPrice) * 100);
    if (discount >= 5) {
      discountText = `at ${discount}% off MSRP`;
    }
  }

  const soldBy =
    isBrandDirect && !hideIsBrandUser
      ? `This item is sold directly from ${shopName}.`
      : `- Sold by ${displayName}`;

  const descriptionContent = [
    `Shop secondhand ${shopName}`,
    itemCategory,
    variantOptionText,
    `at ${treetShopName}.`,
    title,
    `in ${conditionText}`,
    `${discountText}.`,
    conditionInfo?.notes,
    soldBy,
  ];

  return descriptionContent.filter((content) => !isEmpty(content)).join(' ');
};

export const getShippingDetailsForMarkup = (
  shipFromCountry,
  allowedShippingDestinationCountries,
  isBrandDirect,
  currency
) => {
  const shipToCountries = getAllowedShipToCountryCodes(
    isBrandDirect,
    shipFromCountry === CountryCode.Us,
    allowedShippingDestinationCountries
  )?.map((value) => value?.code);

  return {
    '@type': 'OfferShippingDetails',
    shippingRate: {
      '@type': 'MonetaryAmount',
      currency,
      minValue: 8.0,
      maxValue: 15.0,
    },
    shippingDestination: {
      '@type': 'DefinedRegion',
      addressCountry: shipToCountries,
    },
    shippingOrigin: {
      '@type': 'DefinedRegion',
      addressCountry: shipFromCountry,
    },
    deliveryTime: {
      '@type': 'ShippingDeliveryTime',
      handlingTime: {
        '@type': 'QuantitativeValue',
        minValue: 0,
        maxValue: 6,
        unitCode: 'DAY',
      },
      transitTime: {
        '@type': 'QuantitativeValue',
        minValue: 1,
        maxValue: 5,
        unitCode: 'DAY',
      },
    },
  };
};
