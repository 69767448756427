/* eslint-disable import/prefer-default-export */

import { useIntl } from 'react-intl';
import { formatMoney } from '../util/currency';
import { useShopConfig } from './shopConfig';
import { Currency } from '../types/apollo/generated/types.generated';
import { CreditPayoutOption } from '../shopConfig/shops/default';
import { Feature } from '../util/featureFlags';
import { useFeatureFlags } from './useFeatureFlags';

/**
 * Returns whether the given feature is enabled for the user and/or treetId.
 */
export function useYotpo(): {
  doesBrandUseLoyaltyPoints: boolean;
  linkToBrandLoyaltyPointsPage: string;
  getPointsFromPrice: (price: number | undefined) => string;
  getPriceFromPoints: (points: string, currency: Currency | undefined) => string;
} {
  const isPreviewYotpoEnabled = useFeatureFlags(Feature.Yotpo);

  const { creditPayoutOption, yotpo = {} } = useShopConfig();
  const intl = useIntl();
  const doesBrandUseLoyaltyPoints =
    creditPayoutOption === CreditPayoutOption.YotpoLoyaltyPoints || isPreviewYotpoEnabled;

  const { linkToBrandLoyaltyPointsPage = '' } = yotpo;

  const getPointsFromPrice = (price = 0) => {
    const { yotpoPointsPerDollar = 1 } = yotpo;
    return new Intl.NumberFormat().format(Math.round((price / 100) * yotpoPointsPerDollar));
  };

  const getPriceFromPoints = (points = '0', currency: Currency | undefined) => {
    const pointsCastAsNumber = Number(points.replace(/[^\d.-]+/g, ''));
    const { yotpoPointsPerDollar = 1 } = yotpo;
    return pointsCastAsNumber
      ? formatMoney(intl, {
          currency,
          amount: ((pointsCastAsNumber / yotpoPointsPerDollar) * 100).toFixed(2),
        })
      : '';
  };
  return {
    doesBrandUseLoyaltyPoints,
    linkToBrandLoyaltyPointsPage,
    getPointsFromPrice,
    getPriceFromPoints,
  };
}
