import React, { FC } from 'react';
import { Divider } from '..';
import { ListingWithAuthorAndImages } from '../../types/sharetribe/listing';
import ShoppingBagBundle from './ShoppingBagBundle';

import css from './ShoppingBagBundles.module.css';

interface ShoppingBagBundlesProps {
  isEditing: boolean;
  listings: ListingWithAuthorAndImages[];
}

const ShoppingBagBundles: FC<ShoppingBagBundlesProps> = (props: ShoppingBagBundlesProps) => {
  const { isEditing, listings } = props;

  const authorIdToBundle: { [id: string]: ListingWithAuthorAndImages[] } = listings.reduce(
    (acc: { [id: string]: ListingWithAuthorAndImages[] }, listing: ListingWithAuthorAndImages) => {
      const authorId = listing.author.id.uuid;
      if (authorId in acc) {
        acc[authorId].push(listing);
      } else {
        acc[authorId] = [listing];
      }
      return acc;
    },
    {}
  );

  return (
    <>
      {Object.entries(authorIdToBundle).map(([authorId, bundle], index: number) => (
        <div key={authorId}>
          {index !== 0 && <Divider className={css.divider} />}
          <ShoppingBagBundle listings={bundle} isEditing={isEditing} />
        </div>
      ))}
    </>
  );
};

export default ShoppingBagBundles;
