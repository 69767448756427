import { getDefaultBrandDirectOnlyShopConfig, getDefaultShopConfig } from './default';
import * as custom from '../filters/marketplace-custom-config';
import { getSizeFilterConfig } from '../filters/size';

export const moderncitizenShopConfig = {
  ...getDefaultShopConfig(),
  ...getDefaultBrandDirectOnlyShopConfig('Hemster'),
  filters: [
    ...custom.filters,
    getSizeFilterConfig({
      sizes: ['XS', 'S', 'M', 'L', 'XL', '1X', '2X', '3X', 'OS'],
    }),
  ],
};
