import { getDefaultShopConfig } from './default';
import * as custom from '../filters/marketplace-custom-config';
import { getConditionsFilterConfig } from '../filters/condition';
import { getBrandDirectFilterConfig } from '../filters/brandDirect';
import { getSizeFilterConfig } from '../filters/size';

const cinnamonbabyauSizes: string[] = [
  'NB',
  '0-3M',
  '3-6M',
  '6-12M',
  '12-18M',
  '18-24M',
  '1Y',
  '2Y',
  '3Y',
  '4Y',
  '5Y',
  '6/7Y',
  '8/9Y',
  'S',
  'M',
  'L',
  'XL',
  'One Size',
];

export const cinnamonbabyauShopConfig = {
  ...getDefaultShopConfig(),
  filters: [
    ...custom.filters,
    getConditionsFilterConfig(),
    getBrandDirectFilterConfig(),
    getSizeFilterConfig({ sizes: cinnamonbabyauSizes }),
  ],
};
