import React, { FC } from 'react';
import { useIconColor } from '../../../hooks/useIconColor';
import { CustomIconType, useCustomIcon } from '../../../hooks/useCustomIcon';
import CustomIcon from '../../CustomIcon/CustomIcon';

interface IconBuyerProtectionFilledProps {
  className?: string;
  color?: string;
}

const IconBuyerProtectionFilled: FC<IconBuyerProtectionFilledProps> = (
  props: IconBuyerProtectionFilledProps
) => {
  const { className, color } = props;

  const customBuyerProtectionFilledIcon = useCustomIcon(CustomIconType.BuyerProtectionFilled);
  const { iconColor } = useIconColor();

  if (customBuyerProtectionFilledIcon) {
    return (
      <CustomIcon
        url={customBuyerProtectionFilledIcon.icon.url}
        altText="buyer-protection"
        className={className}
      />
    );
  }

  const fillColor = color || iconColor;

  return (
    <svg
      className={className}
      width="33"
      height="33"
      viewBox="0 0 33 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.58257 4.90201C13.7947 6.08385 19.2053 6.08385 24.4174 4.90201L25.8236 4.58315C26.8586 4.34847 27.8438 5.13524 27.8438 6.1965V16.6551C27.8438 18.9515 26.767 21.115 24.935 22.4997L18.8512 27.098C17.4601 28.1494 15.5399 28.1494 14.1488 27.098L8.06495 22.4997C6.23298 21.115 5.15625 18.9515 5.15625 16.6551V6.1965C5.15625 5.13524 6.14138 4.34847 7.17637 4.58315L8.58257 4.90201ZM14.7813 20.7691L22.403 12.7663L20.9095 11.3438L14.7813 17.7785L12.0905 14.9532L10.597 16.3756L14.7813 20.7691Z"
        fill={fillColor}
      />
    </svg>
  );
};

export default IconBuyerProtectionFilled;
