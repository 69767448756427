import { Typography } from '@material-ui/core';
import classNames from 'classnames';
import React, { FC, ReactNode } from 'react';
import css from './ModalParagraph.module.css';

interface ModalParagraphProps {
  children: ReactNode;
  className?: string;
}

const ModalParagraph: FC<ModalParagraphProps> = (props: ModalParagraphProps) => {
  const { children, className } = props;

  return (
    <p className={classNames(css.root, className)}>
      <Typography variant="body1">{children}</Typography>
    </p>
  );
};

export default ModalParagraph;
