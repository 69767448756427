/* eslint-disable no-nested-ternary */
/* eslint-disable import/prefer-default-export */
import { useEnabledCustomerExperiences } from './useEnabledCustomerExperiences';
import { useCurrentUserPermissions } from './useUserPermissions';

export const usePageTitles = () => {
  const { isListTradeInOnly, allowTradeInAndMarketplace } = useEnabledCustomerExperiences();
  const { isTradeInAdmin } = useCurrentUserPermissions();
  const salesTitle =
    allowTradeInAndMarketplace && !isTradeInAdmin
      ? 'Your Sales & Trade-Ins'
      : isListTradeInOnly && !isTradeInAdmin
      ? 'Your Sent Items'
      : 'Your Sales';
  const purchasesTitle = isTradeInAdmin ? 'Customer Trade-Ins' : 'Your Purchases';
  const tradeInsTitle = 'Your Closet';
  const listingsTitle = 'Your Listings';
  const closetTitle = 'Your Closet';

  return {
    salesTitle,
    purchasesTitle,
    tradeInsTitle,
    listingsTitle,
    closetTitle,
  };
};
