import { getBrandDirectFilterConfig } from '../filters/brandDirect';
import { getConditionsFilterConfig } from '../filters/condition';
import { getSizeFilterConfig } from '../filters/size';
import { getDefaultShopConfig } from './default';
import * as custom from '../filters/marketplace-custom-config';

const urbancredSizes = ['XS', 'S', 'M', 'L', 'X-Large', 'XX-Large'];

export const urbancredShopConfig = {
  ...getDefaultShopConfig(),
  filters: [
    ...custom.filters,
    getConditionsFilterConfig(),
    getSizeFilterConfig({
      sizes: urbancredSizes,
    }),
    getBrandDirectFilterConfig(),
  ],
};
