import { PageName } from '../types/routes';
import { Feature, isFeatureEnabled } from './featureFlags';

const APP_ID = 'l95ocan6';

const INTERCOM_REMOVED_PAGES = [PageName.CheckoutPage, PageName.ListingPage];
const INTERCOM_REMOVED_PAGES_MOBILE = [PageName.EditListingPage];
const DEFAULT_INTERCOM_DISPLAY_SETTINGS = { horizontal_padding: 20, vertical_padding: 20 };
const PAGE_TO_INTERCOM_CUSTOM_SETTINGS = {
  [PageName.EditListingPage]: {
    vertical_padding: 100, // Default (20px) + footer height (80px)
  },
};

// based off of
// https://developers.intercom.com/installing-intercom/docs/basic-javascript
export const setup = (treetId) => {
  const IS_INTERCOM_ENABLED = !isFeatureEnabled(Feature.Zendesk, treetId, null);

  if (!IS_INTERCOM_ENABLED) {
    return;
  }

  window.intercomSettings = {
    app_id: APP_ID,
  };
  (function () {
    const w = window;
    const ic = w.Intercom;
    if (typeof ic === 'function') {
      ic('reattach_activator');
      ic('update', w.intercomSettings);
    } else {
      const d = document;
      const i = function () {
        // eslint-disable-next-line prefer-rest-params
        i.c(arguments);
      };
      i.q = [];
      i.c = function (args) {
        i.q.push(args);
      };
      w.Intercom = i;
      const l = function () {
        const s = d.createElement('script');
        s.type = 'text/javascript';
        s.async = true;
        s.src = `https://widget.intercom.io/widget/${APP_ID}`;
        const x = d.getElementsByTagName('script')[0];
        x.parentNode.insertBefore(s, x);
      };
      if (document.readyState === 'complete') {
        l();
      } else if (w.attachEvent) {
        w.attachEvent('onload', l);
      } else {
        w.addEventListener('load', l, false);
      }
    }
  })();
};

export const setUser = (currentUser) => {
  if (!currentUser || typeof window === 'undefined' || !window.Intercom) {
    return;
  }
  const {
    attributes: {
      profile: { firstName, lastName },
      email,
    },
    id: { uuid },
  } = currentUser;
  window.Intercom('update', {
    name: `${firstName} ${lastName}`,
    email,
    user_id: uuid,
  });
};

// Expands the chat bot
export const show = () => {
  if (typeof window !== 'undefined' && window.Intercom) {
    window.Intercom('show');
  }
};

// Removes the intercom chat bot
const remove = () => {
  if (typeof window !== 'undefined' && window.Intercom) {
    window.Intercom('update', {
      hide_default_launcher: true,
    });
  }
};

// Show the chat bot
const display = (pageName) => {
  if (typeof window !== 'undefined' && window.Intercom) {
    window.Intercom('update', {
      ...DEFAULT_INTERCOM_DISPLAY_SETTINGS,
      hide_default_launcher: false,
      ...(PAGE_TO_INTERCOM_CUSTOM_SETTINGS[pageName] || {}),
    });
  }
};

// Remove/Display the intercom depending on the page
export const displayOrRemove = (pageName, isMobile) => {
  const shouldRemoveForAll = INTERCOM_REMOVED_PAGES.includes(pageName);
  const shouldRemoveForMobile = INTERCOM_REMOVED_PAGES_MOBILE.includes(pageName);
  if (shouldRemoveForAll || (isMobile && shouldRemoveForMobile)) {
    remove();
  } else {
    display(pageName);
  }
};
