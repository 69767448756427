import React, { FC } from 'react';
import { Box } from '@material-ui/core';
import css from './ISOModal.module.css';
import TypographyWrapper, { TypographyWeight } from '../TypographyWrapper/TypographyWrapper';
import { useShopConfig } from '../../hooks/shopConfig';

const ISOHeader: FC<{ headerCopy: string }> = (props) => {
  const { headerCopy } = props;
  const { css: brandCss } = useShopConfig();
  const bgcolor = brandCss?.backgroundColor || 'white';

  return (
    <Box py={2} className={css.header} bgcolor={bgcolor}>
      <TypographyWrapper
        variant="h2"
        weight={TypographyWeight.Bold}
        typographyOverrides={{ align: 'center' }}
      >
        {headerCopy}
      </TypographyWrapper>
    </Box>
  );
};

export default ISOHeader;
