// Followed these steps: https://www.builder.io/c/docs/integrate-section-building
import { builder, BuilderComponent } from '@builder.io/react';
import React, { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { BuilderResponse } from '../../types/builder/builder';
import { BuilderSections } from '../../util/builder';
import { getUrlSearchParams } from '../../util/urlHelpers';

builder.init(process.env.REACT_APP_BUILDER_IO_API_KEY as string);

interface BuilderSectionProps {
  sectionType: BuilderSections;
  sectionId: string;
  // See https://www.builder.io/c/docs/custom-actions#passing-data-down-with-code-builder-component-code
  sectionData?: Record<string, any>; // Data props
  sectionContext?: Record<string, any>; // Function props
  fetchDataFromBuilder?: (builderResponse: BuilderResponse['data']) => any;
}

const BuilderSection: FC<BuilderSectionProps> = (props: BuilderSectionProps) => {
  const { sectionType, sectionId, sectionData, sectionContext, fetchDataFromBuilder } = props;

  const { builderContent } = useSelector<any>((state) => state.initial) as any;

  const [builderContentJson, setBuilderContentJson] = useState();

  const content = builderContent?.[sectionId] || builderContentJson;

  useEffect(() => {
    // Most Builder content should already be available in the initial Redux store via
    // PATH_TO_BUILDER_SECTIONS in src/routeConfiguration.js (for SSR). This fallback here is
    // used to fetch any content that we don't need to SSR (ex: the footer since it's
    // typically below the fold), or any content that we missed including in
    // PATH_TO_BUILDER_SECTIONS.
    if (!content) {
      // Preview = true will render unpublished content.
      const searchParams = getUrlSearchParams();
      const isPreview = searchParams.get('preview');

      const handleBuilderResponse = (builderResponse: any) => {
        setBuilderContentJson(builderResponse);
        if (fetchDataFromBuilder) {
          fetchDataFromBuilder(builderResponse?.data);
        }
      };

      builder
        .get(sectionType, {
          ...(isPreview && { options: { includeUnpublished: true } }),
          query: { id: sectionId },
        })
        .promise()
        .then(handleBuilderResponse);
    } else if (fetchDataFromBuilder) {
      fetchDataFromBuilder(content?.data);
    }
  }, [content]);

  return content ? (
    <BuilderComponent
      model={sectionType}
      content={content}
      data={sectionData}
      context={sectionContext}
    />
  ) : null;
};

export default BuilderSection;
