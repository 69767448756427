import { getBrandDirectFilterConfig } from '../filters/brandDirect';
import { getConditionsFilterConfig } from '../filters/condition';
import { getSizeFilterConfig } from '../filters/size';
import { getDefaultShopConfig } from './default';
import * as custom from '../filters/marketplace-custom-config';

const karinaDressesSizes = ['XXS', 'XS', 'S/M', 'M/L', 'XL', 'XXL', '3XL', '4XL'];

export const karinadressesShopConfig = {
  ...getDefaultShopConfig(),
  filters: [
    ...custom.filters,
    getConditionsFilterConfig(),
    getSizeFilterConfig({
      sizes: karinaDressesSizes,
    }),
    getBrandDirectFilterConfig(),
  ],
};
