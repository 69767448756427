import { withStyles } from '@material-ui/core';
import { TextField } from 'mui-rff';

const inputStyles = {
  root: {
    '& label': {
      color: 'white',
      fontWeight: 'bold',
    },
    '& label.Mui-focused': {
      color: 'white',
    },
    '& .MuiSelect-icon': {
      color: 'white',
      top: 'unset',
    },
    '& .MuiInputLabel-outlined': {
      transform: 'translate(14px, 15px) scale(1)',
    },

    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      transform: 'translate(14px, -6px) scale(0.75)',
    },
    // This is needed so that the autocomplete backfill fills the whole input
    '& input': {
      padding: '0px 14px',
      height: '100%',
    },
    '& .MuiOutlinedInput-root': {
      color: 'white',
      fontWeight: 'bold',
      width: '100%',
      height: '48px',

      '& fieldset': {
        borderWidth: '1px',
        borderColor: 'white',
        color: 'white',
      },
      '&:hover fieldset': {
        borderColor: 'white',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'white',
      },
    },
  },
};

const EmailTextField = withStyles(inputStyles)(TextField);

export default EmailTextField;
