import { getDefaultShopConfig } from './default';
import * as custom from '../filters/marketplace-custom-config';
import { getBrandDirectFilterConfig } from '../filters/brandDirect';
import { getConditionsFilterConfig } from '../filters/condition';
import { getSizeFilterConfig } from '../filters/size';

const skhoopSizeOptions = [
  'OS',
  'XS',
  'S',
  'Small',
  'M',
  'Medium',
  'L',
  'Large',
  'XL',
  'XXL',
  'XXXL',
  'XXXXL',
  '34',
  '37',
  '40',
  '43',
];

export const skhoopShopConfig = {
  ...getDefaultShopConfig(),
  filters: [
    ...custom.filters,
    getBrandDirectFilterConfig(),
    getSizeFilterConfig({ sizes: skhoopSizeOptions }),
    getConditionsFilterConfig(),
  ],
};
