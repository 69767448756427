import { getSizeFilterConfig } from '../filters/size';
import { getBrandDirectFilterConfig } from '../filters/brandDirect';
import { getDefaultShopConfig } from './default';
import * as custom from '../filters/marketplace-custom-config';
import { getConditionsFilterConfig } from '../filters/condition';

const oschaslingsUsSizeOptions = [
  'Size 2',
  'Size 3',
  'Size 4',
  'Size 5',
  'Size 6',
  'Size 7',
  'Size 8',
  'Size 9',
  '0.5m Fabric',
  '1m Fabric',
  '1.5m Fabric',
  'Left Shoulder',
  'Right Shoulder',
  'Long Left Shoulder',
  'Long Right Shoulder',
  'XL Left Shoulder',
  'XL Right Shoulder',
  'Baby',
  'Toddler',
  'Preschool',
  'XL Baby',
  'XL Toddler',
  'XL Preschool',
  'Itty Bitty Pieces',
  'Small Pieces',
  'Large Pieces',
  'Shawl',
  'Cowl',
  'Scarf',
];

export const oschaslingsUsShopConfig = {
  ...getDefaultShopConfig(),
  filters: [
    ...custom.filters,
    getConditionsFilterConfig(),
    getBrandDirectFilterConfig(),
    getSizeFilterConfig({ sizes: oschaslingsUsSizeOptions }),
  ],
};
