import React, { FC } from 'react';

interface IconTrashWithBorderProps {
  className?: string;
}

const IconTrashWithBorder: FC<IconTrashWithBorderProps> = (props: IconTrashWithBorderProps) => {
  const { className } = props;

  return (
    <svg
      className={className}
      width="76"
      height="76"
      viewBox="0 0 76 76"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="36" cy="40" r="36" fill="#FFDCDC" />
      <path
        d="M34.5516 27.3103C33.3328 27.3103 32.3447 28.2984 32.3447 29.5172H26.8275C25.6087 29.5172 24.6206 30.5053 24.6206 31.7241V32.8275C24.6206 33.437 25.1146 33.931 25.7241 33.931H45.5861C46.1955 33.931 46.6896 33.437 46.6896 32.8275V31.7241C46.6896 30.5053 45.7015 29.5172 44.4827 29.5172H38.9654C38.9654 28.2984 37.9774 27.3103 36.7585 27.3103H34.5516Z"
        fill="#BB4E4E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M45.5861 36.1379L25.7241 36.1379V52.6896C25.7241 54.5179 27.2061 56 29.0344 56H42.2758C44.104 56 45.5861 54.5179 45.5861 52.6896V36.1379ZM30.1378 41.6551C30.1378 41.0457 30.6319 40.5517 31.2413 40.5517C31.8507 40.5517 32.3447 41.0457 32.3447 41.6551V50.4827C32.3447 51.0921 31.8507 51.5862 31.2413 51.5862C30.6319 51.5862 30.1378 51.0921 30.1378 50.4827V41.6551ZM35.6551 40.5517C35.0457 40.5517 34.5516 41.0457 34.5516 41.6551V50.4827C34.5516 51.0921 35.0457 51.5862 35.6551 51.5862C36.2645 51.5862 36.7585 51.0921 36.7585 50.4827V41.6551C36.7585 41.0457 36.2645 40.5517 35.6551 40.5517ZM40.0689 40.5517C39.4595 40.5517 38.9654 41.0457 38.9654 41.6551V50.4827C38.9654 51.0921 39.4595 51.5862 40.0689 51.5862C40.6783 51.5862 41.1723 51.0921 41.1723 50.4827V41.6551C41.1723 41.0457 40.6783 40.5517 40.0689 40.5517Z"
        fill="#BB4E4E"
      />
      <circle cx="62" cy="14" r="12" fill="#BB4E4E" stroke="white" strokeWidth="4" />
      <path d="M58 14.4L60.6 17L66.6 11" stroke="white" strokeWidth="2" />
    </svg>
  );
};

export default IconTrashWithBorder;
