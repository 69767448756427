/* eslint-disable import/prefer-default-export */
import { startCase, toLower, truncate } from 'lodash';

const isVowel = (char: string) => /^[aeiou]$/i.test(char);

/**
 * @param noun Word to be pluralized
 * @param count Number used to maybe pluralize the noun
 * @param includeCount Whether or not to include the count in the result
 * @returns Pluralized string, possibly with count
 */
export const pluralize = (noun: string, count: number, includeCount?: boolean): string => {
  const nounLastChar = noun.slice(-1);
  const nounSecondToLastChar = noun.charAt(noun.length - 2);

  let pluralNoun;
  if (count === 1) {
    pluralNoun = noun;
  } else if (!isVowel(nounSecondToLastChar) && nounLastChar === 'y') {
    const nounUpToLastChar = noun.slice(0, -1);
    pluralNoun = `${nounUpToLastChar}ies`;
  } else if (nounLastChar === 's' || nounLastChar === 'x' || nounLastChar === 'z') {
    pluralNoun = `${noun}es`;
  } else {
    pluralNoun = `${noun}s`;
  }

  return includeCount ? `${count} ${pluralNoun}` : pluralNoun;
};

/**
 * @param items Items in a list, as an array of strings
 * @returns A readable list of the items as as string.
 *
 * Example: listItems(["plums", "pears", "passionfruit"])
 * returns "plums, pears and passionfruit"
 */
export const listItems = (items: string[]): string => {
  if (items.length === 1) {
    return items[0];
  }

  const lastTwo = [items.slice(-2).join(' and ')];
  return [...items.slice(0, -2), ...lastTwo].join(', ');
};

/**
 * @param noun Word to be pluralized.
 * @param items Items in a list.
 * @returns A readable sentence.
 *
 *
 * Example:
 *
 * listItemsSentence('size', ["S", "M", "L"])
 * returns
 * "sizes S, M and L"
 */
export const listItemsSentence = (noun: string, items: string[]): string => {
  const maybePluralNoun = pluralize(noun, items.length);
  const itemSentence = listItems(items);

  return `${maybePluralNoun} ${itemSentence}`;
};

/**
 * @param text
 * @returns titlecased text as a string, removing any special chars
 *
 * @example titlecase(CONSTANT_STYLE_AS_STRING) returns "Constant Style As String"
 */
export const titlecase = (text: string): string => startCase(toLower(text));

/**
 * @param text
 * @returns text with spaces sanitized to normal spaces
 */
export const sanitizeSpaces = (text: string) => text.replace(/[^\S\r\n]+/g, ' ');

export const truncated = (s: string, length: number) =>
  truncate(s, {
    length,

    // Allow truncated text end only in specific characters. This will
    // make the truncated text shorter than the length if the original
    // text has to be shortened and the substring ends in a separator.
    //
    // This ensures that the final text doesn't get cut in the middle
    // of a word.
    separator: /\s|,|\.|:|;/,
    omission: '…',
  });
