import { defaultClothesConditionsOptions, getConditionsFilterConfig } from '../filters/condition';
import { getDefaultShopConfig } from './default';
import * as custom from '../filters/marketplace-custom-config';
import { getSizeFilterConfigFromOptions } from '../filters/size';

const bohemianmamaSizeOptions = [
  { key: 'XS', label: 'XS' },
  { key: 'S', label: 'S' },
  { key: 'M', label: 'M' },
  { key: 'L', label: 'L' },
  { key: 'XL', label: 'XL' },
  { key: '0-3M', label: '0-3M' },
  { key: '3-6M', label: '3-6M' },
  { key: '6-12M', label: '6-12M' },
  { key: '12-18M', label: '12-18M' },
  { key: '18-24M', label: '18-24M' },
  { key: '2-3Y', label: '2-3Y' },
  { key: '4-5Y', label: '4-5Y' },
  { key: '6-7Y', label: '6-7Y' },
  { key: '8-9Y', label: '8-9Y' },
  { key: 'O/S', label: 'O/S' },
];

export const bohemianmamaShopConfig = {
  ...getDefaultShopConfig(),
  filters: [
    ...custom.filters,
    getConditionsFilterConfig({ options: defaultClothesConditionsOptions }),
    getSizeFilterConfigFromOptions({ options: bohemianmamaSizeOptions }),
  ],
};
