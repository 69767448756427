import { getDefaultShopConfig } from './default';
import * as custom from '../filters/marketplace-custom-config';
import { getBrandDirectFilterConfig } from '../filters/brandDirect';
import { getConditionsFilterConfig } from '../filters/condition';
import { getSizeFilterConfig } from '../filters/size';

const mymummadeitSizes: string[] = [
  'XS',
  'S',
  'M',
  'L',
  'XL',
  '2XL',
  '3XL',
  '4XL',
  'One Size',
  '4 US/AU',
  '5 US/AU',
  '6 US/AU',
  '7 US/AU',
  '8 US/AU',
  '9 US/AU',
  '10 US/AU',
  '11 US/AU',
];

export const mymummadeitShopConfig = {
  ...getDefaultShopConfig(),
  filters: [
    ...custom.filters,
    getConditionsFilterConfig(),
    getBrandDirectFilterConfig(),
    getSizeFilterConfig({ sizes: mymummadeitSizes }),
  ],
};
