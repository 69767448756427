/* eslint-disable import/prefer-default-export */
import { getConditionsFilterConfig } from '../filters/condition';
import { getBrandDirectFilterConfig } from '../filters/brandDirect';
import { getSizeFilterConfig } from '../filters/size';
import { getDefaultShopConfig } from './default';
import * as custom from '../filters/marketplace-custom-config';

const texasstandardSizes: string[] = [
  'One Size',
  'XS',
  'S',
  'M',
  'L',
  'XL',
  'XXL',
  '3XL',
  '30 x 30',
  '30 x 32',
  '30 x 34',
  '32 x 30',
  '32 x 32',
  '32 x 34',
  '34 x 30',
  '34 x 32',
  '34 x 34',
  '36 x 30',
  '36 x 32',
  '36 x 34',
  '38 x 30',
  '38 x 32',
  '38 x 34',
  '40 x 30',
  '40 x 32',
  '40 x 34',
  '42 x 30',
  '42 x 32',
  '42 x 34',
];

export const texasstandardShopConfig = {
  ...getDefaultShopConfig(),
  filters: [
    ...custom.filters,
    getBrandDirectFilterConfig(),
    getConditionsFilterConfig(),
    getSizeFilterConfig({ sizes: texasstandardSizes }),
  ],
};
