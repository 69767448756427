import { Dispatch } from 'redux';
import { RequestStatus } from '../types/requestStatus';
import {
  redeemVoucher as redeemVoucherApiRequest,
  validateVoucher as validateVoucherApiRequest,
} from '../util/api';
import * as log from '../util/log';

// ================ Action types ================ //

const VALIDATE_VOUCHER_REQUEST = 'app/voucherify/VALIDATE_VOUCHER_REQUEST';
const VALIDATE_VOUCHER_SUCCESS = 'app/voucherify/VALIDATE_VOUCHER_SUCCESS';
const VALIDATE_VOUCHER_ERROR = 'app/voucherify/VALIDATE_VOUCHER_ERROR';

const REDEEM_VOUCHER_REQUEST = 'app/voucherify/REDEEM_VOUCHER_REQUEST';
const REDEEM_VOUCHER_SUCCESS = 'app/voucherify/REDEEM_VOUCHER_SUCCESS';
const REDEEM_VOUCHER_ERROR = 'app/voucherify/REDEEM_VOUCHER_ERROR';

interface ValidateVoucherRequest {
  type: typeof VALIDATE_VOUCHER_REQUEST;
}

interface ValidateVoucherSuccess {
  type: typeof VALIDATE_VOUCHER_SUCCESS;
}

interface ValidateVoucherError {
  type: typeof VALIDATE_VOUCHER_ERROR;
}

interface RedeemVoucherRequest {
  type: typeof REDEEM_VOUCHER_REQUEST;
}

interface RedeemVoucherSuccess {
  type: typeof REDEEM_VOUCHER_SUCCESS;
}

interface RedeemVoucherError {
  type: typeof REDEEM_VOUCHER_ERROR;
}

type VoucherifyActionType =
  | ValidateVoucherRequest
  | ValidateVoucherSuccess
  | ValidateVoucherError
  | RedeemVoucherRequest
  | RedeemVoucherSuccess
  | RedeemVoucherError;

// ================ Reducer ================ //

export interface VoucherifyState {
  validateVoucherStatus: RequestStatus;
  redeemVoucherStatus: RequestStatus;
}

const initialState: VoucherifyState = {
  validateVoucherStatus: RequestStatus.Ready,
  redeemVoucherStatus: RequestStatus.Ready,
};

export default function reducer(
  state: VoucherifyState = initialState,
  action: VoucherifyActionType
): VoucherifyState {
  switch (action.type) {
    case VALIDATE_VOUCHER_REQUEST: {
      return { ...state, validateVoucherStatus: RequestStatus.Pending };
    }
    case VALIDATE_VOUCHER_SUCCESS: {
      return { ...state, validateVoucherStatus: RequestStatus.Success };
    }
    case VALIDATE_VOUCHER_ERROR: {
      return { ...state, validateVoucherStatus: RequestStatus.Error };
    }
    case REDEEM_VOUCHER_REQUEST: {
      return { ...state, redeemVoucherStatus: RequestStatus.Pending };
    }
    case REDEEM_VOUCHER_SUCCESS: {
      return { ...state, redeemVoucherStatus: RequestStatus.Success };
    }
    case REDEEM_VOUCHER_ERROR: {
      return { ...state, redeemVoucherStatus: RequestStatus.Error };
    }
    default:
      return state;
  }
}

// ================ Action creators ================ //

const validateVoucherRequest = (): ValidateVoucherRequest => ({
  type: VALIDATE_VOUCHER_REQUEST,
});

const validateVoucherSuccess = (): ValidateVoucherSuccess => ({
  type: VALIDATE_VOUCHER_SUCCESS,
});

const validateVoucherError = (): ValidateVoucherError => ({
  type: VALIDATE_VOUCHER_ERROR,
});

const redeemVoucherRequest = (): RedeemVoucherRequest => ({
  type: REDEEM_VOUCHER_REQUEST,
});

const redeemVoucherSuccess = (): RedeemVoucherSuccess => ({
  type: REDEEM_VOUCHER_SUCCESS,
});

const redeemVoucherError = (): RedeemVoucherError => ({
  type: REDEEM_VOUCHER_ERROR,
});

// ================ Thunks ================ //

export const validateVoucher =
  (discountCode: string) => async (dispatch: Dispatch, getState: () => any) => {
    dispatch(validateVoucherRequest());
    const { treetId } = getState().initial;

    try {
      const response = await validateVoucherApiRequest({ discountCode, treetId });
      dispatch(validateVoucherSuccess());
      return response;
    } catch (e) {
      dispatch(validateVoucherError());
      throw e;
    }
  };

export const redeemVoucher =
  (discountCode: string) => async (dispatch: Dispatch, getState: () => any) => {
    dispatch(redeemVoucherRequest());
    const { treetId } = getState().initial;

    let response;
    try {
      response = await redeemVoucherApiRequest({ discountCode, treetId });
      dispatch(redeemVoucherSuccess());
    } catch (e) {
      log.error(e, 'redeem-voucher-failed', { discountCode, treetId });
      dispatch(redeemVoucherError());
      // Swallow error since we don't handle it on the frontend
    }
    return response;
  };
