/* eslint-disable import/prefer-default-export */
import { getDefaultShopConfig } from './default';
import * as custom from '../filters/marketplace-custom-config';
import { getBrandDirectFilterConfig } from '../filters/brandDirect';
import { Conditions, getConditionsFilterConfig } from '../filters/condition';
import { getSizeFilterConfig } from '../filters/size';

const rachelantonoffSizeOptions = [
  'XS',
  'S',
  'M',
  'L',
  'XL',
  'XXL',
  '1X',
  '2X',
  '3X',
  '0',
  '2',
  '4',
  '6',
  '8',
  '10',
  '12',
  '14',
  '15',
  '16',
  '18',
  '20',
  '22',
  '24',
  '26',
];

const rachelantonoffConditionOptions = [
  {
    key: Conditions.NewWithoutTags,
    label: 'New & Unworn',
    description: 'Pristine, no visible signs of wear. There are no quirks or issues whatsoever.',
  },
  {
    key: Conditions.MinorDefect,
    label: 'Minor Defect',
    description:
      'This item is in great condition but may have a factory defect such as a stitching error, discoloration, or fabric imperfection.',
  },
  {
    key: Conditions.Excellent,
    label: 'Excellent Condition',
    description:
      'This item has only been lightly used and has been extremely well maintained. There may be some small signs of wear, but nothing major.',
  },
  {
    key: Conditions.Good,
    label: 'Good Condition',
    description:
      'This item has been worn and well maintained. There may be some quirks like a small stain, a button missing, or a loose thread, but it’s overall in good shape. ',
  },
];

export const rachelantonoffShopConfig = {
  ...getDefaultShopConfig(),
  filters: [
    ...custom.filters,
    getConditionsFilterConfig({ options: rachelantonoffConditionOptions }),
    getBrandDirectFilterConfig(),
    getSizeFilterConfig({ sizes: rachelantonoffSizeOptions }),
  ],
};
