import { getConditionsFilterConfig } from '../filters/condition';
import { getBrandDirectFilterConfig } from '../filters/brandDirect';
import { getSizeFilterConfig } from '../filters/size';
import { getDefaultShopConfig } from './default';
import * as custom from '../filters/marketplace-custom-config';
import { BuilderSections } from '../../util/builder';

const joanieSizes = [
  'US 4',
  'US 5',
  'US 6',
  'US 7',
  'US 8',
  'US 9',
  'US 10',
  'US 11',
  'US 12',
  'US 14',
  'US 16',
  'US 18',
  'US 20',
  'US 22',
  'EXTRA SMALL (US 4-6)',
  'S (US 8-10)',
  'M (US 12-14)',
  'L (US 16-18)',
  'XL (US 20-22)',
];

export const joanieShopConfig = {
  ...getDefaultShopConfig(),
  filters: [
    ...custom.filters,
    getBrandDirectFilterConfig(),
    getConditionsFilterConfig(),
    getSizeFilterConfig({ sizes: joanieSizes }),
  ],
  builderConfig: {
    sections: {
      [BuilderSections.TopbarContent]: '414f0c3392974f039cb6d02d43966aba',
      [BuilderSections.LandingPageContent]: '077b23d2a4d940209e080141b99232b1',
    },
  },
};
