import { ThunkDispatch } from 'redux-thunk';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { fetchStripeAccount } from '../../ducks/stripeConnectAccount.duck';
import { fetchCurrentUser } from '../../ducks/user.duck';
import { RequestStatus } from '../../types/requestStatus';
import { Uuid } from '../../types/sharetribe/uuid';
import { PayoutOptions } from '../../util/constants';
import {
  denormalisedEntities,
  denormalisedResponseEntities,
  updatedEntities,
} from '../../util/data';
import { getAllListings } from '../../util/ducks/getAllListings';
import { storableError } from '../../util/errors';
import * as log from '../../util/log';
import { fetchListings as fetchListingsApiRequest } from '../../util/api';

export const EXTENDED_SHIPPING_WINDOW = 5;

export const MAX_BUNDLES_PER_QUERY = 200;

const merge = (state: any, sdkResponse: any) => {
  const apiResponse = sdkResponse.data;
  return {
    ...state,
    ownEntities: updatedEntities({ ...state.ownEntities }, apiResponse),
  };
};

export const getOwnListingsById = (state: any, listingIds: Uuid[]) => {
  const { ownEntities } = state.ManageSalesPage;
  const resources = listingIds.map((id) => ({
    id,
    type: 'ownListing',
  }));
  const throwIfNotFound = false;
  return denormalisedEntities(ownEntities, resources, throwIfNotFound);
};

// ================ Action types ================ //

export const UPDATE_LISTINGS_PAYOUT_OPTION_REQUEST =
  'app/ManageSalesPage/UPDATE_LISTINGS_PAYOUT_OPTION_REQUEST';
export const UPDATE_LISTINGS_PAYOUT_OPTION_SUCCESS =
  'app/ManageSalesPage/UPDATE_LISTINGS_PAYOUT_OPTION_SUCCESS';
export const UPDATE_LISTINGS_PAYOUT_OPTION_ERROR =
  'app/ManageSalesPage/UPDATE_LISTINGS_PAYOUT_OPTION_ERROR';

export const FETCH_OWN_LISTINGS_REQUEST = 'app/ManageSalesPage/FETCH_OWN_LISTINGS_REQUEST';
export const FETCH_OWN_LISTINGS_SUCCESS = 'app/ManageSalesPage/FETCH_OWN_LISTINGS_SUCCESS';
export const FETCH_OWN_LISTINGS_ERROR = 'app/ManageSalesPage/FETCH_OWN_LISTINGS_ERROR';

export const FETCH_BUNDLE_LISTINGS_REQUEST = 'app/ManageSalesPage/FETCH_BUNDLE_LISTINGS_REQUEST';
export const FETCH_BUNDLE_LISTINGS_SUCCESS = 'app/ManageSalesPage/FETCH_BUNDLE_LISTINGS_SUCCESS';
export const FETCH_BUNDLE_LISTINGS_ERROR = 'app/ManageSalesPage/FETCH_BUNDLE_LISTINGS_ERROR';

export const ADD_OWN_ENTITIES = 'app/ManageSalesPage/ADD_OWN_ENTITIES';

interface UpdateListingsPayoutOptionRequest {
  type: typeof UPDATE_LISTINGS_PAYOUT_OPTION_REQUEST;
}

interface UpdateListingsPayoutOptionSuccess {
  type: typeof UPDATE_LISTINGS_PAYOUT_OPTION_SUCCESS;
}

interface UpdateListingsPayoutOptionError {
  type: typeof UPDATE_LISTINGS_PAYOUT_OPTION_ERROR;
  error: any;
}

interface FetchOwnListingsRequest {
  type: typeof FETCH_OWN_LISTINGS_REQUEST;
}

interface FetchOwnListingsSuccess {
  type: typeof FETCH_OWN_LISTINGS_SUCCESS;
}

interface FetchOwnListingsError {
  type: typeof FETCH_OWN_LISTINGS_ERROR;
  error: any;
}

interface FetchBundleListingsRequest {
  type: typeof FETCH_BUNDLE_LISTINGS_REQUEST;
}

interface FetchBundleListingsSuccess {
  type: typeof FETCH_BUNDLE_LISTINGS_SUCCESS;
}

interface FetchBundleListingsError {
  type: typeof FETCH_BUNDLE_LISTINGS_ERROR;
  error: any;
}

interface AddOwnEntities {
  type: typeof ADD_OWN_ENTITIES;
  payload: any;
}

type ManageSalesPageActionType =
  | UpdateListingsPayoutOptionRequest
  | UpdateListingsPayoutOptionSuccess
  | UpdateListingsPayoutOptionError
  | FetchOwnListingsRequest
  | FetchOwnListingsSuccess
  | FetchOwnListingsError
  | FetchBundleListingsRequest
  | FetchBundleListingsSuccess
  | FetchBundleListingsError
  | AddOwnEntities;

// ================ Reducer ================ //

export interface ManageSalesPageState {
  updateListingsPayoutOptionStatus: RequestStatus;
  updateListingsPayoutOptionError: any | null;
  ownEntities: any;
  fetchOwnListingsStatus: RequestStatus;
  fetchOwnListingsError: any | null;
  fetchBundleListingsStatus: RequestStatus;
  fetchBundleListingsError: any | null;
}

const initialState = {
  updateListingsPayoutOptionStatus: RequestStatus.Ready,
  updateListingsPayoutOptionError: null,
  ownEntities: [],
  fetchOwnListingsStatus: RequestStatus.Ready,
  fetchOwnListingsError: null,
  fetchBundleListingsStatus: RequestStatus.Ready,
  fetchBundleListingsError: null,
};

export default function manageSalesPageReducer(
  state: ManageSalesPageState = initialState,
  action: ManageSalesPageActionType
): ManageSalesPageState {
  switch (action.type) {
    case UPDATE_LISTINGS_PAYOUT_OPTION_REQUEST: {
      return {
        ...state,
        updateListingsPayoutOptionStatus: RequestStatus.Pending,
        updateListingsPayoutOptionError: null,
      };
    }
    case UPDATE_LISTINGS_PAYOUT_OPTION_SUCCESS: {
      return {
        ...state,
        updateListingsPayoutOptionStatus: RequestStatus.Success,
      };
    }
    case UPDATE_LISTINGS_PAYOUT_OPTION_ERROR: {
      return {
        ...state,
        updateListingsPayoutOptionStatus: RequestStatus.Error,
        updateListingsPayoutOptionError: action.error,
      };
    }
    case FETCH_OWN_LISTINGS_REQUEST: {
      return {
        ...state,
        fetchOwnListingsStatus: RequestStatus.Pending,
        fetchOwnListingsError: null,
      };
    }
    case FETCH_OWN_LISTINGS_SUCCESS: {
      return {
        ...state,
        fetchOwnListingsStatus: RequestStatus.Success,
      };
    }
    case FETCH_OWN_LISTINGS_ERROR: {
      return {
        ...state,
        fetchOwnListingsStatus: RequestStatus.Error,
        fetchOwnListingsError: action.error,
      };
    }
    case FETCH_BUNDLE_LISTINGS_REQUEST: {
      return {
        ...state,
        fetchBundleListingsStatus: RequestStatus.Pending,
        fetchBundleListingsError: null,
      };
    }
    case FETCH_BUNDLE_LISTINGS_SUCCESS: {
      return {
        ...state,
        fetchBundleListingsStatus: RequestStatus.Success,
      };
    }
    case FETCH_BUNDLE_LISTINGS_ERROR: {
      return {
        ...state,
        fetchBundleListingsStatus: RequestStatus.Error,
        fetchBundleListingsError: action.error,
      };
    }
    case ADD_OWN_ENTITIES:
      return merge(state, action.payload);

    default:
      return state;
  }
}

// ================ Action creators ================ //

// This works the same way as addMarketplaceEntities,
// but we don't want to mix own listings with searched listings
// (own listings data contains different info - e.g. exact location etc.)
export const addOwnEntities = (sdkResponse: any) => ({
  type: ADD_OWN_ENTITIES,
  payload: sdkResponse,
});

const updateListingsPayoutOptionRequest = () => ({ type: UPDATE_LISTINGS_PAYOUT_OPTION_REQUEST });
const updateListingsPayoutOptionSuccess = () => ({
  type: UPDATE_LISTINGS_PAYOUT_OPTION_SUCCESS,
});
const updateListingsPayoutOptionError = (error: any) => ({
  type: UPDATE_LISTINGS_PAYOUT_OPTION_ERROR,
  error,
});

const fetchOwnListingsRequest = () => ({ type: FETCH_OWN_LISTINGS_REQUEST });
const fetchOwnListingsSuccess = () => ({ type: FETCH_OWN_LISTINGS_SUCCESS });
const fetchOwnListingsError = (error: any) => ({ type: FETCH_OWN_LISTINGS_ERROR, error });

const fetchBundleListingsRequest = () => ({ type: FETCH_BUNDLE_LISTINGS_REQUEST });
const fetchBundleListingsSuccess = () => ({
  type: FETCH_BUNDLE_LISTINGS_SUCCESS,
});
const fetchBundleListingsError = (error: any) => ({
  type: FETCH_BUNDLE_LISTINGS_ERROR,
  error,
});

/* ================ Thunks ================ */

// Currently the actual payout is handled by the periodically polling that checks for transactions that need to be paid out
// We're not saving the data to the transaction because we can only edit tranaction data when it's transitioning
export const updateListingsPayoutOption =
  (data: { listingIds: string[]; payoutOption: PayoutOptions }) =>
  async (dispatch: ThunkDispatch<any, any, any>, getState: () => any, sdk: any) => {
    const { listingIds, payoutOption } = data;
    dispatch(updateListingsPayoutOptionRequest());
    try {
      const listingsPromises = listingIds.map((listingId: string) =>
        // TODO: Listing-Migration After migrating listings to PG, ST update can be removed
        sdk.ownListings.update(
          {
            id: listingId,
            privateData: {
              payoutOption,
            },
          },
          {
            expand: true,
          }
        )
      );
      const response = await Promise.all(listingsPromises);
      response.forEach((listingResponse: any) => {
        dispatch(addOwnEntities(listingResponse));
        dispatch(addMarketplaceEntities(listingResponse));
        dispatch(updateListingsPayoutOptionSuccess());
      });
      return response;
    } catch (e) {
      log.error(e, 'update-listings-payout-option-on-bundle-failed', { data, e });
      dispatch(updateListingsPayoutOptionError(storableError(e)));
      // This will be caught in BundlePannel
      throw e;
    }
  };

// This will fetch ALL the listings, however most people will have < 100 though and can fit in 1 page.
export const queryOwnListings =
  () => async (dispatch: ThunkDispatch<any, any, any>, getState: () => any, sdk: any) => {
    dispatch(fetchOwnListingsRequest());

    const params = {
      per_page: 100,
      include: ['images', 'privateData'],
      'fields.image': ['variants.landscape-crop', 'variants.landscape-crop2x', 'variants.default'],
      'limit.images': 1,
    };

    try {
      const response = await getAllListings(sdk, params);
      dispatch(addOwnEntities(response));
      dispatch(fetchOwnListingsSuccess());
    } catch (e) {
      dispatch(fetchOwnListingsError(storableError(e)));
      throw e;
    }
  };

export const fetchBundleListings =
  (listingIds: string[]) => async (dispatch: ThunkDispatch<any, any, any>) => {
    dispatch(fetchBundleListingsRequest());
    let resultIds;
    try {
      const bundleListingsResponse = await fetchListingsApiRequest({ listingIds });
      dispatch(addMarketplaceEntities(bundleListingsResponse));
      resultIds = denormalisedResponseEntities(bundleListingsResponse).map((listing) => listing.id);
    } catch (e) {
      log.error(e, 'fetching-bundle-listings-for-sales-failed', { listingIds });
      dispatch(fetchBundleListingsError(e));
    }
    dispatch(fetchBundleListingsSuccess());
    return resultIds;
  };

export const loadData =
  () => async (dispatch: ThunkDispatch<any, any, any>, getState: () => any) => {
    let { currentUser } = getState().user;
    const { fetchOwnListingsStatus } = getState().ManageSalesPage;
    if (!currentUser) {
      await dispatch(fetchCurrentUser());
      // refetch from state after current user is fetched
      currentUser = getState().user.currentUser;
    }
    if (
      currentUser?.stripeAccount ||
      currentUser?.attributes.profile.protectedData?.stripeAccountId
    ) {
      dispatch(fetchStripeAccount());
    }
    // need to query own listings in order to get payout option since its on privateData
    if (fetchOwnListingsStatus !== RequestStatus.Success) dispatch(queryOwnListings());
  };
