import { getDefaultShopConfig } from './default';
import * as custom from '../filters/marketplace-custom-config';
import { getBrandDirectFilterConfig } from '../filters/brandDirect';
import { getConditionsFilterConfig } from '../filters/condition';
import { getSizeFilterConfig } from '../filters/size';

const lovedbabySizes = [
  'XS',
  'S',
  'M',
  'L',
  'XL',
  '0-3m',
  '0-6m',
  '0-12m',
  '6-9m',
  '6-12m',
  '9-12m',
  '12-18m',
  '12-24m',
  '18-24m',
  '2T',
  '3T',
  '4T',
  'Preemie-NB',
  '5',
  '6',
  '7',
  '8',
  '9',
  '10',
];

export const lovedbabyShopConfig = {
  ...getDefaultShopConfig(),
  filters: [
    ...custom.filters,
    getConditionsFilterConfig(),
    getBrandDirectFilterConfig(),
    getSizeFilterConfig({ sizes: lovedbabySizes }),
  ],
};
