import { ensureTransaction } from './data';

/**
 * Transitions
 *
 * These strings must sync with values defined in Flex API,
 * since transaction objects given by API contain info about last transitions.
 * All the actions in API side happen in transitions,
 * so we need to understand what those strings mean.
 */

// When a customer makes a booking to a listing, a transaction is
// created with the initial request-payment transition.
// At this transition a PaymentIntent is created by Marketplace API.
// After this transition, the actual payment must be made on client-side directly to Stripe.
export const TRANSITION_REQUEST_PAYMENT = 'transition/request-payment';

// A customer can also initiate a transaction with an enquiry, and
// then transition that with a request.
export const TRANSITION_ENQUIRE = 'transition/enquire';
export const TRANSITION_REQUEST_PAYMENT_AFTER_ENQUIRY = 'transition/request-payment-after-enquiry';

// TODO(SY): Check if we need to do this
// Stripe SDK might need to ask 3D security from customer, in a separate front-end step.
// Therefore we need to make another transition to Marketplace API,
// to tell that the payment is confirmed.
export const TRANSITION_CONFIRM_PAYMENT = 'transition/confirm-payment';

// If the payment is not confirmed in the time limit set in transaction process (by default 15min)
// the transaction will expire automatically.
export const TRANSITION_EXPIRE_PAYMENT = 'transition/expire-payment';

// When the provider accepts or declines a transaction from the
// SalePage, it is transitioned with the accept or decline transition.
export const TRANSITION_ACCEPT = 'transition/accept';
export const TRANSITION_DECLINE = 'transition/decline';

// The backend automatically expire the transaction.
export const TRANSITION_EXPIRE = 'transition/expire';

// Admin can also cancel the transition.
// export const TRANSITION_CANCEL = 'transition/cancel';

// The backend will mark the transaction completed.
export const TRANSITION_COMPLETE = 'transition/complete';

// Reviews are given through transaction transitions. Review 1 can be
// by provider or customer, and review 2 will be the other party of
// the transaction.
export const TRANSITION_REVIEW_1_BY_PROVIDER = 'transition/review-1-by-provider';
export const TRANSITION_REVIEW_2_BY_PROVIDER = 'transition/review-2-by-provider';
export const TRANSITION_REVIEW_1_BY_CUSTOMER = 'transition/review-1-by-customer';
export const TRANSITION_REVIEW_2_BY_CUSTOMER = 'transition/review-2-by-customer';
export const TRANSITION_EXPIRE_CUSTOMER_REVIEW_PERIOD = 'transition/expire-customer-review-period';
export const TRANSITION_EXPIRE_PROVIDER_REVIEW_PERIOD = 'transition/expire-provider-review-period';
export const TRANSITION_EXPIRE_REVIEW_PERIOD = 'transition/expire-review-period';

// Transitions added for Treet

export const TRANSITION_INITIATE = 'transition/initiate';
export const TRANSITION_PURCHASE = 'transition/purchase';
export const TRANSITION_CANCEL = 'transition/cancel';
export const TRANSITION_REFUND_CANCELLATION = 'transition/refund-cancellation';
export const TRANSITION_EXPIRE_PURCHASE = 'transition/expire-purchase';
export const TRANSITION_SHIP = 'transition/ship';
export const TRANSITION_EXTEND_SHIPPING = 'transition/extend-shipping';
export const TRANSITION_CANCEL_FROM_EXTENSION = 'transition/cancel-from-extension';
export const TRANSITION_CANCEL_FROM_EXTENSION_BY_OPERATOR =
  'transition/cancel-from-extension-by-operator';
export const TRANSITION_CANCEL_BY_OPERATOR = 'transition/cancel-by-operator';
export const TRANSITION_DELIVER_FROM_EXTENSION = 'transition/deliver-from-extension';
export const TRANSITION_DELIVER_FROM_PURCHASED = 'transition/deliver-from-purchase';
export const TRANSITION_SHIP_FROM_EXTENSION = 'transition/ship-from-extension';
export const TRANSITION_DELIVER = 'transition/deliver';
export const TRANSITION_VERIFY = 'transition/verify';
export const TRANSITION_AUTOMATIC_VERIFY = 'transition/automatic-verify';
export const TRANSITION_VERIFY_BY_OPERATOR = 'transition/verify-by-operator';
export const TRANSITION_PAY_OUT = 'transition/pay-out';
export const TRANSITION_DISPUTE = 'transition/dispute';
export const TRANSITION_REJECT_DISPUTE = 'transition/reject-dispute';
export const TRANSITION_ACCEPT_DISPUTE = 'transition/accept-dispute';
export const TRANSITION_INITIATE_RETURN = 'transition/initiate-return';
export const TRANSITION_RETURN_SHIP = 'transition/return-ship';
export const TRANSITION_EXPIRE_DISPUTE = 'transition/expire-dispute';
export const TRANSITION_REFUND_RETURN = 'transition/refund-return';
export const TRANSITION_RETURN_DELIVER = 'transition/return-deliver';

/**
 * Actors
 *
 * There are 4 different actors that might initiate transitions:
 */

// Roles of actors that perform transaction transitions
export const TX_TRANSITION_ACTOR_CUSTOMER = 'customer';
export const TX_TRANSITION_ACTOR_PROVIDER = 'provider';
export const TX_TRANSITION_ACTOR_SYSTEM = 'system';
export const TX_TRANSITION_ACTOR_OPERATOR = 'operator';
export const TX_TRANSITION_ACTOR_AUTOMATIC = 'automatic';

export const TX_TRANSITION_ACTORS = [
  TX_TRANSITION_ACTOR_CUSTOMER,
  TX_TRANSITION_ACTOR_PROVIDER,
  TX_TRANSITION_ACTOR_SYSTEM,
  TX_TRANSITION_ACTOR_OPERATOR,
];

/**
 * States
 *
 * These constants are only for making it clear how transitions work together.
 * You should not use these constants outside of this file.
 *
 * Edit: ^ Unsure why we are not supposed to use these constants outside of this file.
 *
 * Note: these states are not in sync with states used transaction process definitions
 *       in Marketplace API. Only last transitions are passed along transaction object.
 */
const STATE_INITIAL = 'initial';
// TODO(SY): remove all unused states
const STATE_ENQUIRY = 'enquiry';
const STATE_PENDING_PAYMENT = 'pending-payment';
const STATE_PAYMENT_EXPIRED = 'payment-expired';
const STATE_PREAUTHORIZED = 'preauthorized';
const STATE_DECLINED = 'declined';
const STATE_ACCEPTED = 'accepted';
// const STATE_CANCELED = 'canceled';

const STATE_REVIEWED = 'reviewed';

const STATE_REVIEWED_BY_CUSTOMER = 'reviewed-by-customer';
const STATE_REVIEWED_BY_PROVIDER = 'reviewed-by-provider';

// Treet States
export const STATE_INITIATED = 'initiated';
export const STATE_PURCHASED = 'purchased';
export const STATE_SHIPPED = 'shipped';
export const STATE_SHIPPING_EXTENDED = 'shipping-extended';
export const STATE_CANCELED = 'canceled';
export const STATE_CANCELLATION_REFUNDED = 'cancellation-refunded';
export const STATE_DELIVERED = 'delivered';
export const STATE_DISPUTED = 'disputed';
export const STATE_VERIFIED = 'verified';
export const STATE_PAID_OUT = 'paid-out';
export const STATE_DISPUTE_ACCEPTED = 'dispute-accepted';
export const STATE_RETURN_INITIATED = 'return-initiated';
export const STATE_RETURN_SHIPPED = 'return-shipped';
export const STATE_RETURN_REFUNDED = 'return-refunded';
export const STATE_RETURN_DELIVERED = 'return-delivered';
/**
 * Description of transaction process
 *
 * You should keep this in sync with transaction process defined in Marketplace API
 *
 * Note: we don't use yet any state machine library,
 *       but this description format is following Xstate (FSM library)
 *       https://xstate.js.org/docs/
 */
const stateDescription = {
  // id is defined only to support Xstate format.
  // However if you have multiple transaction processes defined,
  // it is best to keep them in sync with transaction process aliases.
  id: 'treet/release-1',

  // This 'initial' state is a starting point for new transaction
  initial: STATE_INITIAL,

  // States
  states: {
    [STATE_INITIAL]: {
      on: {
        [TRANSITION_INITIATE]: STATE_INITIATED,
      },
    },
    [STATE_INITIATED]: {
      on: {
        [TRANSITION_PURCHASE]: STATE_PURCHASED,
      },
    },
    [STATE_PURCHASED]: {
      on: {
        [TRANSITION_SHIP]: STATE_SHIPPED,
        [TRANSITION_CANCEL]: STATE_CANCELED,
        [TRANSITION_CANCEL_BY_OPERATOR]: STATE_CANCELED,
        [TRANSITION_EXPIRE_PURCHASE]: STATE_CANCELED,
        [TRANSITION_EXTEND_SHIPPING]: STATE_SHIPPING_EXTENDED,
        [TRANSITION_DELIVER_FROM_PURCHASED]: STATE_DELIVERED,
      },
    },
    [STATE_SHIPPING_EXTENDED]: {
      on: {
        [TRANSITION_SHIP_FROM_EXTENSION]: STATE_SHIPPED,
        [TRANSITION_DELIVER_FROM_EXTENSION]: STATE_DELIVERED,
        [TRANSITION_CANCEL_FROM_EXTENSION]: STATE_CANCELED,
        [TRANSITION_CANCEL_FROM_EXTENSION_BY_OPERATOR]: STATE_CANCELED,
      },
    },
    [STATE_CANCELED]: {
      on: {
        [TRANSITION_REFUND_CANCELLATION]: STATE_CANCELLATION_REFUNDED,
      },
    },
    [STATE_SHIPPED]: {
      on: {
        [TRANSITION_DELIVER]: STATE_DELIVERED,
      },
    },
    [STATE_DELIVERED]: {
      on: {
        [TRANSITION_VERIFY]: STATE_VERIFIED,
        [TRANSITION_VERIFY_BY_OPERATOR]: STATE_VERIFIED,
        [TRANSITION_AUTOMATIC_VERIFY]: STATE_VERIFIED,
        [TRANSITION_DISPUTE]: STATE_DISPUTED,
      },
    },
    [STATE_DISPUTED]: {
      on: {
        [TRANSITION_ACCEPT_DISPUTE]: STATE_DISPUTE_ACCEPTED,
        [TRANSITION_REJECT_DISPUTE]: STATE_VERIFIED,
      },
    },
    [STATE_DISPUTE_ACCEPTED]: {
      on: {
        [TRANSITION_INITIATE_RETURN]: STATE_RETURN_INITIATED,
      },
    },
    [STATE_RETURN_INITIATED]: {
      on: {
        [TRANSITION_EXPIRE_DISPUTE]: STATE_VERIFIED,
        [TRANSITION_RETURN_SHIP]: STATE_RETURN_SHIPPED,
      },
    },
    [STATE_RETURN_SHIPPED]: {
      on: {
        [TRANSITION_REFUND_RETURN]: STATE_RETURN_REFUNDED,
      },
    },
    [STATE_RETURN_REFUNDED]: {
      on: {
        [TRANSITION_RETURN_DELIVER]: STATE_RETURN_DELIVERED,
      },
    },
    [STATE_VERIFIED]: {
      on: {
        [TRANSITION_PAY_OUT]: STATE_PAID_OUT,
      },
    },
    [STATE_PAID_OUT]: {
      type: 'final',
    },
    [STATE_RETURN_DELIVERED]: {
      type: 'final',
    },
    [STATE_CANCELLATION_REFUNDED]: {
      type: 'final',
    },
  },
};

const transitionToActor = {
  // Customer transitions
  [TRANSITION_INITIATE]: TX_TRANSITION_ACTOR_CUSTOMER,
  [TRANSITION_PURCHASE]: TX_TRANSITION_ACTOR_CUSTOMER,
  [TRANSITION_VERIFY]: TX_TRANSITION_ACTOR_CUSTOMER,
  [TRANSITION_DISPUTE]: TX_TRANSITION_ACTOR_CUSTOMER,

  // Operator transitions
  [TRANSITION_SHIP]: TX_TRANSITION_ACTOR_OPERATOR,
  [TRANSITION_DELIVER]: TX_TRANSITION_ACTOR_OPERATOR,
  [TRANSITION_DELIVER_FROM_PURCHASED]: TX_TRANSITION_ACTOR_OPERATOR,
  [TRANSITION_EXTEND_SHIPPING]: TX_TRANSITION_ACTOR_OPERATOR,
  [TRANSITION_SHIP_FROM_EXTENSION]: TX_TRANSITION_ACTOR_OPERATOR,
  [TRANSITION_DELIVER_FROM_EXTENSION]: TX_TRANSITION_ACTOR_OPERATOR,
  [TRANSITION_VERIFY_BY_OPERATOR]: TX_TRANSITION_ACTOR_OPERATOR,

  [TRANSITION_CANCEL_BY_OPERATOR]: TX_TRANSITION_ACTOR_OPERATOR,
  [TRANSITION_CANCEL_FROM_EXTENSION_BY_OPERATOR]: TX_TRANSITION_ACTOR_OPERATOR,
  [TRANSITION_REFUND_CANCELLATION]: TX_TRANSITION_ACTOR_OPERATOR,

  [TRANSITION_ACCEPT_DISPUTE]: TX_TRANSITION_ACTOR_OPERATOR,
  [TRANSITION_REJECT_DISPUTE]: TX_TRANSITION_ACTOR_OPERATOR,
  [TRANSITION_INITIATE_RETURN]: TX_TRANSITION_ACTOR_OPERATOR,
  [TRANSITION_RETURN_SHIP]: TX_TRANSITION_ACTOR_OPERATOR,
  [TRANSITION_RETURN_DELIVER]: TX_TRANSITION_ACTOR_OPERATOR,
  [TRANSITION_REFUND_RETURN]: TX_TRANSITION_ACTOR_OPERATOR,
  [TRANSITION_PAY_OUT]: TX_TRANSITION_ACTOR_OPERATOR,

  // Provider transitions
  [TRANSITION_CANCEL]: TX_TRANSITION_ACTOR_PROVIDER,
  [TRANSITION_CANCEL_FROM_EXTENSION]: TX_TRANSITION_ACTOR_PROVIDER,

  // Automatic transitions
  [TRANSITION_AUTOMATIC_VERIFY]: TX_TRANSITION_ACTOR_AUTOMATIC,
  [TRANSITION_EXPIRE_PURCHASE]: TX_TRANSITION_ACTOR_AUTOMATIC,
  [TRANSITION_EXPIRE_DISPUTE]: TX_TRANSITION_ACTOR_AUTOMATIC,
};

// Note: currently we assume that state description doesn't contain nested states.
const statesFromStateDescription = (description) => description.states || {};

// Get all the transitions from states object in an array
const getTransitions = (states) => {
  const stateNames = Object.keys(states);

  const transitionsReducer = (transitionArray, name) => {
    const stateTransitions = states[name] && states[name].on;
    const transitionKeys = stateTransitions ? Object.keys(stateTransitions) : [];
    return [
      ...transitionArray,
      ...transitionKeys.map((key) => ({ key, value: stateTransitions[key] })),
    ];
  };

  return stateNames.reduce(transitionsReducer, []);
};

// This is a list of all the transitions that this app should be able to handle.
export const TRANSITIONS = getTransitions(statesFromStateDescription(stateDescription)).map(
  (t) => t.key
);

// This function returns a function that has given stateDesc in scope chain.
const getTransitionsToStateFn = (stateDesc) => (state) =>
  getTransitions(statesFromStateDescription(stateDesc))
    .filter((t) => t.value === state)
    .map((t) => t.key);

// Get all the transitions that lead to specified state.
const getTransitionsToState = getTransitionsToStateFn(stateDescription);

// This is needed to fetch transactions that need response from provider.
// I.e. transactions which provider needs to accept or decline
export const transitionsToRequested = getTransitionsToState(STATE_PREAUTHORIZED);
export const transitionsToCanceled = getTransitionsToState(STATE_CANCELED);
export const transitionsToDelivered = getTransitionsToState(STATE_DELIVERED);

/**
 * Helper functions to figure out if transaction is in a specific state.
 * State is based on lastTransition given by transaction object and state description.
 */

export const txLastTransition = (tx) => ensureTransaction(tx).attributes.lastTransition;

export const TRANSITION_TO_STATES = getTransitions(statesFromStateDescription(stateDescription));

/**
 *
 * @param {Transaction} tx Transaction object
 * @returns {string} Deduced state from the transaction object
 */
export const getStateFromTransaction = (tx) =>
  TRANSITION_TO_STATES.find((item) => item.key === txLastTransition(tx)).value;

export const txIsEnquired = (tx) =>
  getTransitionsToState(STATE_ENQUIRY).includes(txLastTransition(tx));

export const txIsPaymentPending = (tx) =>
  getTransitionsToState(STATE_PENDING_PAYMENT).includes(txLastTransition(tx));

export const txIsPaymentExpired = (tx) =>
  getTransitionsToState(STATE_PAYMENT_EXPIRED).includes(txLastTransition(tx));

// Note: state name used in Marketplace API docs (and here) is actually preauthorized
// However, word "requested" is used in many places so that we decided to keep it.
export const txIsRequested = (tx) =>
  getTransitionsToState(STATE_PREAUTHORIZED).includes(txLastTransition(tx));

export const txIsAccepted = (tx) =>
  getTransitionsToState(STATE_ACCEPTED).includes(txLastTransition(tx));

export const txIsDeclined = (tx) =>
  getTransitionsToState(STATE_DECLINED).includes(txLastTransition(tx));

export const txIsInitiated = (tx) =>
  getTransitionsToState(STATE_INITIATED).includes(txLastTransition(tx));

export const txIsPurchased = (tx) =>
  getTransitionsToState(STATE_PURCHASED).includes(txLastTransition(tx));

export const txIsCanceled = (tx) =>
  getTransitionsToState(STATE_CANCELED).includes(txLastTransition(tx));

export const txIsVerified = (tx) =>
  getTransitionsToState(STATE_VERIFIED).includes(txLastTransition(tx));

export const txIsDelivered = (tx) =>
  getTransitionsToState(STATE_DELIVERED).includes(txLastTransition(tx));

const firstReviewTransitions = [
  ...getTransitionsToState(STATE_REVIEWED_BY_CUSTOMER),
  ...getTransitionsToState(STATE_REVIEWED_BY_PROVIDER),
];
export const txIsInFirstReview = (tx) => firstReviewTransitions.includes(txLastTransition(tx));

export const txIsInFirstReviewBy = (tx, isCustomer) =>
  isCustomer
    ? getTransitionsToState(STATE_REVIEWED_BY_CUSTOMER).includes(txLastTransition(tx))
    : getTransitionsToState(STATE_REVIEWED_BY_PROVIDER).includes(txLastTransition(tx));

export const txIsReviewed = (tx) =>
  getTransitionsToState(STATE_REVIEWED).includes(txLastTransition(tx));

/**
 * Helper functions to figure out if transaction has passed a given state.
 * This is based on transitions history given by transaction object.
 */

const txTransitions = (tx) => ensureTransaction(tx).attributes.transitions || [];
const hasPassedTransition = (transitionName, tx) =>
  !!txTransitions(tx).find((t) => t.transition === transitionName);

const hasPassedStateFn = (state) => (tx) =>
  getTransitionsToState(state).filter((t) => hasPassedTransition(t, tx)).length > 0;

/**
 * @deprecated
 */
export const txHasBeenAccepted = hasPassedStateFn(STATE_ACCEPTED);
/**
 * @deprecated use `itemHasBeenDelivered` from `src/util/bundleItem.ts`
 */
export const txHasBeenDelivered = hasPassedStateFn(STATE_DELIVERED);
/**
 * @deprecated
 */
export const txHasBeenPurchased = hasPassedStateFn(STATE_PURCHASED);
/**
 * @deprecated use `itemHasBeenVerified` from `src/util/bundleItem.ts`
 */
export const txHasBeenVerified = hasPassedStateFn(STATE_VERIFIED);
/**
 * @deprecated
 */
export const txHasBeenShipped = hasPassedStateFn(STATE_SHIPPED);
/**
 * @deprecated use `itemHasBeenCanceled` from `src/util/bundleItem.ts`
 */
export const txHasBeenCanceled = hasPassedStateFn(STATE_CANCELED);
/**
 * @deprecated use `itemHasBeenDisputed` from `src/util/bundleItem.ts`
 */
export const txHasBeenDisputed = hasPassedStateFn(STATE_DISPUTED);
/**
 * @deprecated use `itemHasBeenDisputeResolved` from `src/util/bundleItem.ts`
 */
export const txHasBeenReturnShipped = hasPassedStateFn(STATE_RETURN_SHIPPED);
/**
 * @deprecated use `itemHasBeenPaidOut` from `src/util/bundleItem.ts`
 */
export const txHasBeenPaidOut = hasPassedStateFn(STATE_PAID_OUT);
/**
 * @deprecated use `itemHasBeenDisputedAndNotVerified` from `src/util/bundleItem.ts`
 */
export const txHasBeenDisputedAndNotVerified = (tx) =>
  txHasBeenDisputed(tx) && !txHasBeenVerified(tx);
/**
 * Other transaction related utility functions
 */

export const transitionIsReviewed = (transition) =>
  getTransitionsToState(STATE_REVIEWED).includes(transition);

export const transitionIsFirstReviewedBy = (transition, isCustomer) =>
  isCustomer
    ? getTransitionsToState(STATE_REVIEWED_BY_CUSTOMER).includes(transition)
    : getTransitionsToState(STATE_REVIEWED_BY_PROVIDER).includes(transition);

export const getReview1Transition = (isCustomer) =>
  isCustomer ? TRANSITION_REVIEW_1_BY_CUSTOMER : TRANSITION_REVIEW_1_BY_PROVIDER;

export const getReview2Transition = (isCustomer) =>
  isCustomer ? TRANSITION_REVIEW_2_BY_CUSTOMER : TRANSITION_REVIEW_2_BY_PROVIDER;

// Check if a transition is the kind that should be rendered
// when showing transition history (e.g. ActivityFeed)
// The first transition and most of the expiration transitions made by system are not relevant
export const isRelevantPastTransition = (transition) =>
  [
    TRANSITION_ACCEPT,
    TRANSITION_CANCEL,
    TRANSITION_COMPLETE,
    TRANSITION_CONFIRM_PAYMENT,
    TRANSITION_DECLINE,
    TRANSITION_EXPIRE,
    TRANSITION_REVIEW_1_BY_CUSTOMER,
    TRANSITION_REVIEW_1_BY_PROVIDER,
    TRANSITION_REVIEW_2_BY_CUSTOMER,
    TRANSITION_REVIEW_2_BY_PROVIDER,
  ].includes(transition);

export const isCustomerReview = (transition) =>
  [TRANSITION_REVIEW_1_BY_CUSTOMER, TRANSITION_REVIEW_2_BY_CUSTOMER].includes(transition);

export const isProviderReview = (transition) =>
  [TRANSITION_REVIEW_1_BY_PROVIDER, TRANSITION_REVIEW_2_BY_PROVIDER].includes(transition);

export const getUserTxRole = (currentUserId, transaction) => {
  const tx = ensureTransaction(transaction);
  const { customer } = tx;
  if (currentUserId && currentUserId.uuid && tx.id && customer.id) {
    // user can be either customer or provider
    return currentUserId.uuid === customer.id.uuid
      ? TX_TRANSITION_ACTOR_CUSTOMER
      : TX_TRANSITION_ACTOR_PROVIDER;
  }

  throw new Error(`Parameters for "userIsCustomer" function were wrong.
      currentUserId: ${currentUserId}, transaction: ${transaction}`);
};

export const txRoleIsProvider = (userRole) => userRole === TX_TRANSITION_ACTOR_PROVIDER;
export const txRoleIsCustomer = (userRole) => userRole === TX_TRANSITION_ACTOR_CUSTOMER;

// Check if the given transition is privileged.
//
// Privileged transitions need to be handled from a secure context,
// i.e. the backend. This helper is used to check if the transition
// should go through the local API endpoints, or if using JS SDK is
// enough.
export const isPrivileged = (transition) =>
  [TRANSITION_REQUEST_PAYMENT, TRANSITION_REQUEST_PAYMENT_AFTER_ENQUIRY].includes(transition);

// Depending on where the transaction is now and where we want to end up, we use a different transition
/**
 *
 * @param {Object} transaction
 * @param {string} desiredEndState
 * @param {string} actor
 * @returns the next transition we could take
 */
export const getNextTransition = (transaction, desiredEndState, actor = null) => {
  const currentState = getStateFromTransaction(transaction);
  // Get all possible next transitions
  const nextTransitions = statesFromStateDescription(stateDescription)[currentState].on;
  // Find the transition that will take us to our desired end state
  return Object.entries(nextTransitions).find(([transition, endState]) =>
    actor
      ? endState === desiredEndState && actor === transitionToActor[transition]
      : endState === desiredEndState
  )?.[0];
};

// TODO (SY): This will be different for both sides of the paty
/**
 * This is a mapping from transaction state to the tags displayed on the Order page
 */
export const STATE_TO_STATUS_TAGS = {
  [STATE_PURCHASED]: 'Not yet shipped',
  [STATE_SHIPPING_EXTENDED]: 'Not yet shipped',
  [STATE_CANCELED]: 'Canceled',
  [STATE_CANCELLATION_REFUNDED]: 'Canceled',
  [STATE_SHIPPED]: 'Shipped',
  [STATE_DELIVERED]: 'Delivered - Verification needed',
  [STATE_DISPUTED]: 'Delivered - Claim Filed',
  [STATE_VERIFIED]: 'Delivered - Verified',
  [STATE_PAID_OUT]: 'Delivered',
  [STATE_DISPUTE_ACCEPTED]: 'Return Initiated',
  [STATE_RETURN_INITIATED]: 'Return Initiated',
  [STATE_RETURN_SHIPPED]: 'Returned',
  [STATE_RETURN_REFUNDED]: 'Returned',
};

// Main difference here is that for brand direct, it shouldnt say verification needed
export const BRAND_DIRECT_STATE_TO_STATUS_TAGS = {
  [STATE_PURCHASED]: 'Not yet shipped',
  [STATE_SHIPPING_EXTENDED]: 'Not yet shipped',
  [STATE_PURCHASED]: 'Not yet shipped',
  [STATE_CANCELED]: 'Canceled',
  [STATE_CANCELLATION_REFUNDED]: 'Canceled',
  [STATE_SHIPPED]: 'Shipped',
  [STATE_DELIVERED]: 'Delivered',
  [STATE_DISPUTED]: 'Delivered - Claim Filed',
  [STATE_VERIFIED]: 'Delivered - Verified',
  [STATE_PAID_OUT]: 'Delivered',
  [STATE_DISPUTE_ACCEPTED]: 'Return Initiated',
  [STATE_RETURN_INITIATED]: 'Return Initiated',
  [STATE_RETURN_SHIPPED]: 'Returned',
  [STATE_RETURN_REFUNDED]: 'Returned',
};
