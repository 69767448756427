/* eslint-disable import/prefer-default-export */
import { getDefaultShopConfig, getDefaultTradeInShopConfig } from './default';
import * as custom from '../filters/marketplace-custom-config';
import { getBrandDirectFilterConfig } from '../filters/brandDirect';
import { Conditions, getConditionsFilterConfig } from '../filters/condition';
import { getSizeFilterConfig } from '../filters/size';
import { GenericFilterOption } from '../../types/filters/filters';
import { ListingItemType } from '../../types/sharetribe/listing';

const elizabethsuzannSizes = [
  '000',
  '000-Short',
  '000-Regular',
  '000-Tall',
  '00-Short',
  '00-Regular',
  '00-Tall',
  '0-Short',
  '0-Regular',
  '0-Tall',
  '2-Short',
  '2-Regular',
  '2-Tall',
  '4-Short',
  '4-Regular',
  '4-Tall',
  '6-Short',
  '6-Regular',
  '6-Tall',
  '8-Short',
  '8-Regular',
  '8-Tall',
  '10',
  '10-Short',
  '10-Regular',
  '10-Tall',
  '12-Short',
  '12-Regular',
  '12-Tall',
  '14-Short',
  '14-Regular',
  '14-Tall',
  '16-Short',
  '16-Regular',
  '16-Tall',
  '20-Short',
  '20-Tall',
  '22-Short',
  '22-Regular',
  '22-Tall',
  '26-Regular',
  '28-Short',
  'OS Minus',
  'OS',
  'OS Plus',
  'OS Plus 2',
  'OS Plus 3',
  '1X',
  '1X/2X',
  '2X',
  '3X',
  '3X/4X',
  '4X',
  '5X',
  '5X/6X',
  '4XL/5XL',
  '4XL',
  '4XL-Short',
  '4XL-Regular',
  '4XL-Tall',
  '3XL',
  '3XL-Short',
  '3XL-Regular',
  '3XL-Tall',
  '2XL/3XL',
  '2XL',
  '2XL-Short',
  '2XL-Regular',
  '2XL-Tall',
  'XL',
  'XL-Short',
  'XL-Regular',
  'XL-Tall',
  'L/XL',
  'L',
  'L-Short',
  'L-Regular',
  'L-Tall',
  'M/L',
  'M',
  'M-Short',
  'M-Regular',
  'M-Tall',
  'S/M',
  'S',
  'S-Short',
  'S-Regular',
  'S-Tall',
  'XS',
  'XS-Short',
  'XS-Regular',
  'XS-Tall',
  'XXS/XS',
  'XXS',
  'XXS-Short',
  'XXS-Regular',
  'XXS-Tall',
];

const elizabethsuzannConditionOptions = [
  {
    key: Conditions.NewWithoutTags,
    label: 'Brand New',
    description: 'Your item has never been used. There are no quirks or issues whatsoever.',
  },
  {
    key: Conditions.Excellent,
    label: 'Excellent Condition',
    description:
      'Your item has only been lightly used and has been extremely well maintained. There may be some small signs of wear, but nothing major.',
  },
  {
    key: Conditions.Good,
    label: 'Good Condition',
    description:
      'You’ve used this item and it’s well maintained. There may be some quirks like a small stain, or a loose thread, but it’s overall in good shape.',
  },
  {
    key: Conditions.Recyclable,
    label: 'Damaged/Recyclable',
    description: 'Your item has significant damage or is in recycle-only condition.',
    includedTypes: [ListingItemType.TradeIn],
  },
];

// Do not show trade-in only conditions in marketplace search filter
const elizabethsuzannConditionFilterOptions = elizabethsuzannConditionOptions
  .filter((option) => !option.includedTypes?.includes(ListingItemType.TradeIn))
  .map((option) => ({
    key: option.key,
    label: option.label,
    description: option.description,
  })) as GenericFilterOption[];

export const elizabethsuzannShopConfig = {
  ...getDefaultShopConfig(),
  ...getDefaultTradeInShopConfig(),
  filters: [
    ...custom.filters,
    getConditionsFilterConfig({ options: elizabethsuzannConditionFilterOptions }),
    getBrandDirectFilterConfig(),
    getSizeFilterConfig({
      sizes: elizabethsuzannSizes,
    }),
  ],
  conditionOptionsOverride: elizabethsuzannConditionOptions,
};
