/* eslint-disable camelcase */
import { v4 as uuidv4 } from 'uuid';
import { camelCase, capitalize, compact, isBoolean, isEmpty, isNil, min } from 'lodash';
import {
  CountryCode,
  Currency,
  EmailSubscribeSource,
  LineItem,
  ListingItemType as ListingItemTypeType,
  Cadence as SavedSearchCadence,
} from '../types/apollo/generated/types.generated';
import { CurrentUser } from '../types/sharetribe/currentUser';
import { Listing, OwnListingWithImages, SharetribeImage } from '../types/sharetribe/listing';
import { isDev } from './envHelpers';
import { UploadcareFile } from '../types/uploadcare/file';
import { SharetribeAddress } from '../types/sharetribe/address';
import {
  CustomerExperience,
  PricingRestriction,
  ProgressBarSection,
  ShopConfigV2,
} from '../types/shopConfig/shopConfigV2';
import { Feature, isFeatureEnabled } from './featureFlags';
import { getBrandCountryConfigFromShopConfig } from '../hooks/useCountryConfig';
import { CountryCodeAndName } from './countryCodes';
import { IShopConfig } from '../types/contentful/types.generated';
import { Pane } from '../components/ISOModal/ISOModal';
import { Pane as ListingFeedbackModalPane } from '../components/ListingFeedbackModal/ListingFeedbackModal';
import { getActivePromoConfigs } from '../shopConfig/configHelper';
import { ListingFeedbackCategory } from '../components/ListingFeedbackModal/ListingFeedback.utils';
import { trackHeapEvent } from './api';
import { getSessionId } from './heapSession';

enum Event {
  InitializeCheckout = 'Initialize Checkout',
  Purchase = 'Purchase',
  Order = 'Order',
  SearchFilter = 'Apply Search Filter',
  Subscribe = 'Subscribe',
  SizeSubscribe = 'Size Subscribe',
  SubscribeISO = 'ISO Subscribe',
  SizeSubscribeISO = 'ISO Size Subscribe',
  ShowLandingPageSubscribeModal = 'Show Landing Page Subscribe Modal',
  ShowCountryWarningModal = 'Show Country Warning Modal',
  ShowISOModal = 'Show ISO Modal',
  ShowListingFeedbackModal = 'Show Listing Feedback Modal',
  SignUp = 'Sign Up',
  PublishNewListing = 'Publish New Listing',
  SaveExisitingListing = 'Save Existing Listing',
  SaveDraftListing = 'Save Draft Listing',
  UploadBackgroundRemovedImage = 'Upload Background Removed Image',
  RemoveBackground = 'Remove Background',
  DeleteImage = 'Delete Image',
  CompleteListingSection = 'Complete Listing Section',
  ChooseStockPhotos = 'Choose Stock Photo Feature Shown',
  ClickListingCard = 'Click Listing Card',
  VisitListingPage = 'Visit Listing Page',
  SelectConsolidatedListing = 'Select Consolidated Listing',
  SubmitListingFeedback = 'Submit Listing Feedback',
  ListingFeedbackProgress = 'Listing Feedback Progress',
  ZoomPDPImage = 'Zoom PDP Image',
  ClickFiltersBarFilter = 'Click Filters Bar Filter',
  SubmitFiltersBarPopupFilter = 'Submit Filters Bar Popup Filter',
  ClearFiltersBarPopupFilter = 'Clear Filters Bar Popup Filter',
  ViewSearchResults = 'View Search Results',
  ShowMobileFiltersModal = 'Show Mobile Filters Modal',
  ClearFilterMobileFiltersModal = 'Clear Filter Mobile Filters Modal',
  ClearAllFiltersMobileFiltersModal = 'Clear All Filters Mobile Filters Modal',
  SubmitFiltersModal = 'Submit Filters Modal',
  ClickISOBanner = 'Click ISO Banner',
  ClickLoyaltyPointsSection = 'Click Loyalty Points Section',
}

enum HeapUserProperty {
  // email property name must be downcased
  Email = 'email',
  AccountCreationShopName = 'Account Creation Shop Name',
  SignUpDate = 'Sign Up Date',
  SignUpSource = 'Sign Up Source',
  IsAdmin = 'Is Admin Account',
  IsBrand = 'Is Brand Account',
}

enum HeapProperty {
  ShopName = 'Shop Name',
  SignUpSource = 'Sign Up Source',
  IsBrandDirect = 'Is Brand Direct',
  Email = 'Email',
  ListingId = 'Listing ID',
  TreetId = 'Treet ID',
  Name = 'Name',
  Category = 'Category',
  RecommendedPrice = 'Recommended Price',
  Quantity = 'Quantity',
  CurrencyLabel = 'Currency',
  GrossPrice = 'Gross Price',
  OrderId = 'Order ID',
  ItemCount = 'Item Count',
  Subtotal = 'Subtotal',
  Total = 'Total',
  Shipping = 'Shipping',
  Discount = 'Discount',
  DiscountCode = 'Discount Code',
  FilterType = 'Filter Type',
  FilterValue = 'Filter Value',
  EmailContactList = 'Contact List',
  SubscribeSource = 'Subscribe Source',
  GroupId = 'Group ID',
  SubscribeSize = 'Subscribed Size',
  ShopifyProductVariantId = 'Shopify Product Variant ID',
  ShopifyProductId = 'Shopify Product ID',
  Condition = 'Condition',
  OriginalRetailPrice = 'Original Retail Price',
  ListedPrice = 'Listed Price',
  ImageCount = 'Image Count',
  ImageSrc = 'Image Src',
  NumRequiredImages = 'Required Image Count',
  DidUpdateImages = 'Did Update Images',
  ListingItemType = 'Listing Item Type',
  OriginatedAsGuestListing = 'Originated As Guest Listing',
  IsBackgroundRemoved = 'Is Background Removed From Listing Images',
  ShipFromCountries = 'Ship From Countries',
  ShipToCountry = 'Ship To Country',
  ShipToState = 'Ship To State',
  ShipToZip = 'Ship To Zip',
  ShipToCity = 'Ship To City',
  IsReturnInsurancePurchased = 'Is Return Insurance Purchased',
  ReturnInsuranceQuote = 'Return Insurance Quote',
  PromosActive = 'Promos Active',
  ListingAge = 'Listing Age',
  LoggedIn = 'Logged In',
  EnabledFeatureFlags = 'Enabled Feature Flags',
  TimeSinceListingStart = 'Time Since Listing Start',
  TotalTimeSpent = 'Total Time Spent',
  FindItemMethod = 'Find Item Method',
  TimeSpentOnFindItem = 'Time Spent on Find Item',
  TimeSpentOnItemDetails = 'Time Spent on Item Details',
  TimeSpentOnCondition = 'Time Spent on Condition',
  TimeSpentOnPhotos = 'Time Spent on Photos',
  TimeSpentOnPrice = 'Time Spent on Price',
  TimeSpentOnAcknowledgement = 'Time Spent on Acknowledgement',
  ListingFlowId = 'Listing Flow ID',
  AlreadyCompletedSections = 'Already Completed Sections',
  MostRecentlyCompletedSection = 'Most Recently Completed Section',
  SectionName = 'Section Name',
  IsCrossShop = 'Is Cross Shop',
  DidUseQRCode = 'Did Use QR Code',
  SavedStockPhotosCount = 'Saved Stock Photos Count',
  VisibleStockPhotosCount = 'Visible Stock Photos Count',
  RemovedStockPhotosCount = 'Removed Stock Photos Count',
  AvailableStockPhotosCount = 'Available Stock Photos Count',
  Cadence = 'Cadence',
  ModalPane = 'Modal Pane',
  SearchedQuery = 'Searched Query',
  NumSellers = 'Unique Sellers Count',
  OriginatingListingId = 'Originating Listing ID',
  IndexOfSelectedListing = 'Index of Selected Listing',
  NumConsolidatedListings = 'Number of Consolidated Listings',
  AutomaticPriceDropEnabled = 'Automatic Price Drop Enabled',
  AutomaticPriceDropPercentage = 'Automatic Price Drop Percentage',
  AutomaticPriceDropMinPrice = 'Automatic Price Drop Minimum Price',
  AutomaticPriceDropAnchorPrice = 'Automatic Price Drop Anchor Price',
  AutomaticPriceDropMinPricePercentOfSellingPrice = 'Automatic Price Drop Min Price Percent of Selling Price',
  ListingHadAutoPriceDropEnabled = 'Listing Had Auto Price Drop Enabled',
  ListingSalePricePercentAutoDropped = 'Listing Sale Price Percent Auto Dropped',
  SearchKeywords = 'Search Keywords',
  NumListings = 'Number of Listings',
  PageNumber = 'Page Number',
  PaymentMethod = 'Payment Method',
  Page = 'Page',
  NumLoyaltyPoints = 'Number of Loyalty Points',
}

// Properties specific to brand.
enum ShopHeapProperty {
  EnabledCustomerExperiences = 'Enabled Customer Experiences',
  HasPriceRestrictions = 'Has Price Restrictions',
  AllowedFindItemMethods = 'Allowed Find Item Methods',
  HasListingRequestEnabledForNonTreetUsers = 'Has Listing Request Enabled For Non Treet Users',
  DefaultShopCreditPayoutPercentage = 'Default Shop Credit Payout Percentage',
  DefaultShopCashPayoutPercentage = 'Default Shop Cash Payout Percentage',
  CreditPayoutOption = 'Credit Payout Option',
  AllowedOriginCountries = 'Allowed Origin Countries',
  AllowedDestinationCountries = 'Allowed Destination Countries',
  ShopCurrency = 'Shop Currency',
  ShopCountry = 'Shop Country',
  MaxStockImagesPerListing = 'Max Stock Images Per Listing',
  ActiveCreditBoostPromo = 'Active Credit Boost Promo',
  ActiveDiscountCodePromo = 'Active Discount Code Promo',
  ActiveSlashBdListingsPromo = 'Active Slash BD Listings Promo',
  PromoShopCreditPayoutPercentage = 'Promo Shop Credit Payout Percentage',
  PromoSlashBdPricesPercentOff = 'Promo Slash BD Prices Percent Off',
  HasActivePromo = 'Has Active Promo',
  ActivePromoTypes = 'Active Promo Types',
}

enum ListingCardHeapProperty {
  FeaturedImageType = 'Featured Image Type',
  HoveredImageType = 'Hovered Image Type',
  IndexOnPage = 'Index On Page',
  PageNumber = 'Page Number',
  Page = 'Page',
  OriginOfClick = 'Origin Of Click',
  IsMobile = 'Is Mobile',
  IsPurchased = 'Is Purchased',
}

enum ListingFeedbackHeapProperty {
  FeedbackCategory = 'Feedback Category',
  HasFeedbackNote = 'Has Feedback Note',
  IsCompleteSubmission = 'Is Complete Submission',
}

enum FiltersHeapProperty {
  Filter = 'Filter',
  Filters = 'Filters',
  ShopName = 'Shop Name',
  NumberOfResults = 'Number Of Results',
}

interface HeapProperties {
  [x: string]: string | number | undefined;
}

// Will clear all heap event properties.
// Should be used to clear out all previously set properties to
// ensure accuracy.
const clearHeapEventProperties = (): void => {
  if (typeof window !== 'undefined') {
    if (isDev && !window.heap) {
      // eslint-disable-next-line no-console
      console.log('Heap event properties cleared in development.');
    }

    if (window.heap) {
      window.heap.clearEventProperties();
    }
  }
};

const setHeapEventProperties = (properties: { [x: string]: any }): void => {
  if (typeof window !== 'undefined') {
    const formattedProperties: HeapProperties = {};
    Object.keys(properties).forEach((propertyKey) => {
      const value = properties[propertyKey];
      if (Array.isArray(value)) {
        formattedProperties[propertyKey] = value.sort().join(',');
        return;
      }

      if (isBoolean(value)) {
        formattedProperties[propertyKey] = `${value}`;
        return;
      }

      formattedProperties[propertyKey] = value;
    });

    if (isDev && !window.heap) {
      // eslint-disable-next-line no-console
      console.log('Heap event properties not set in development:', formattedProperties);
    }

    if (window.heap) {
      window.heap.addEventProperties(formattedProperties);
    }
  }
};

export const setFeatureFlagHeapProperties = (
  treetId: string,
  currentUser?: CurrentUser | null
): void => {
  const enabledFeatures: string[] = [];
  Object.values(Feature).forEach((value: Feature) => {
    if (isFeatureEnabled(value, treetId, currentUser)) {
      enabledFeatures.push(value);
    }
  });

  setHeapEventProperties({ [HeapProperty.EnabledFeatureFlags]: enabledFeatures });
};

const setShippingHeapEventProperties = (
  allowedShippingDestinationCountries: CountryCodeAndName[] | undefined,
  allowedShippingOriginCountries: CountryCodeAndName[] | undefined
): void => {
  if (!allowedShippingDestinationCountries || !allowedShippingOriginCountries) return;

  const allowedShipTo = allowedShippingDestinationCountries?.map((value) => value.code);
  const allowedShipFrom = allowedShippingOriginCountries?.map((value) => value.code);

  let properties = {};
  Object.values(CountryCode).forEach((value: CountryCode) => {
    properties = {
      ...properties,
      [`Ships To ${value}`]: allowedShipTo.includes(value),
      [`Ships From ${value}`]: allowedShipFrom.includes(value),
    };
  });

  setHeapEventProperties(properties);
};

const setEnabledCustomerExperienceProperties = (
  enabledCustomerExperiences: IShopConfig['enabledCustomerExperiences'] | undefined
) => {
  let properties = {};
  if (!enabledCustomerExperiences) return;

  Object.values(CustomerExperience).forEach((value: CustomerExperience) => {
    properties = {
      ...properties,
      [`${capitalize(value.replace(/_/g, ' '))} Experience Enabled`]:
        enabledCustomerExperiences?.includes(value),
    };
  });

  setHeapEventProperties(properties);
};

const setActivePromoProperties = (promoConfigs: any) => {
  if (!promoConfigs) return;

  const {
    activeDiscountCodePromo,
    activePayoutCreditBoostPromo,
    activeSlashBdPricesPromo,
    activePromos,
  } = getActivePromoConfigs(promoConfigs);

  if (!activePromos) return;

  const activePromoTypes = compact(activePromos.map((promos) => promos?.promoType))
    .sort()
    .join(',');

  const properties = {
    [ShopHeapProperty.HasActivePromo]: true,
    [ShopHeapProperty.ActivePromoTypes]: activePromoTypes,
    ...(activeDiscountCodePromo && {
      [ShopHeapProperty.ActiveDiscountCodePromo]: activeDiscountCodePromo.uniquePromoId,
    }),
    ...(activePayoutCreditBoostPromo && {
      [ShopHeapProperty.ActiveCreditBoostPromo]: activePayoutCreditBoostPromo.uniquePromoId,
      [ShopHeapProperty.PromoShopCreditPayoutPercentage]:
        activePayoutCreditBoostPromo.promoTypeConfig.promoPayoutPercentage,
    }),
    ...(activeSlashBdPricesPromo && {
      [ShopHeapProperty.ActiveSlashBdListingsPromo]: activeSlashBdPricesPromo.uniquePromoId,
      [ShopHeapProperty.PromoSlashBdPricesPercentOff]:
        activeSlashBdPricesPromo.promoTypeConfig.defaultPercentOff,
    }),
  };

  setHeapEventProperties(properties);
};

export const setInitialHeapEventProperties = (shopConfigV2: ShopConfigV2 | undefined): void => {
  clearHeapEventProperties();

  if (!shopConfigV2) return;

  const {
    shopId,
    shopName,
    enabledCustomerExperiences,
    cashPayoutPercentage,
    creditPayoutPercentage,
    creditPayoutOption,
    listingFlowConfig,
    marketingSettingsConfig,
    maxStockImages,
    promoConfigsCollection,
  } = shopConfigV2;
  const { pricingRestrictions, allowedFindItemMethods } = listingFlowConfig;
  const hasPriceRestrictions = pricingRestrictions.some(
    (restriction: PricingRestriction) =>
      !!restriction?.minimumPercentage || !!restriction?.minimumPrice
  );

  const {
    allowedShippingDestinationCountries,
    allowedShippingOriginCountries,
    currencyConfig,
    countryCode,
  } = getBrandCountryConfigFromShopConfig(shopConfigV2);

  setShippingHeapEventProperties(
    allowedShippingDestinationCountries,
    allowedShippingOriginCountries
  );

  setEnabledCustomerExperienceProperties(enabledCustomerExperiences);

  setActivePromoProperties(promoConfigsCollection);

  setHeapEventProperties({
    [HeapProperty.TreetId]: shopId,
    [HeapProperty.ShopName]: shopName,
    // Sort to ensure consistency
    [ShopHeapProperty.EnabledCustomerExperiences]: enabledCustomerExperiences,
    [ShopHeapProperty.AllowedFindItemMethods]: allowedFindItemMethods,
    [ShopHeapProperty.AllowedOriginCountries]: allowedShippingOriginCountries?.map(
      (value) => value.code
    ),
    [ShopHeapProperty.AllowedDestinationCountries]: allowedShippingDestinationCountries?.map(
      (value) => value.code
    ),
    [ShopHeapProperty.ShopCurrency]: currencyConfig?.currency,
    [ShopHeapProperty.ShopCountry]: countryCode,
    [ShopHeapProperty.HasPriceRestrictions]: hasPriceRestrictions,
    [ShopHeapProperty.HasListingRequestEnabledForNonTreetUsers]:
      marketingSettingsConfig?.enableNonTreetUsersForListingsRequestEmail,
    [ShopHeapProperty.CreditPayoutOption]: creditPayoutOption,
    [ShopHeapProperty.DefaultShopCreditPayoutPercentage]: creditPayoutPercentage,
    [ShopHeapProperty.DefaultShopCashPayoutPercentage]: cashPayoutPercentage,
    [ShopHeapProperty.MaxStockImagesPerListing]: maxStockImages,
  });
};

export const setIsLoggedInEventProperty = (value: boolean) => {
  setHeapEventProperties({ [HeapProperty.LoggedIn]: value });
};

interface UserProperties {
  // All other fields need to be title cased but email is a special case needs
  // to be lowercase: https://developers.heap.io/reference/adduserproperties fo
  // more info.
  [HeapUserProperty.Email]: string;
  [HeapUserProperty.AccountCreationShopName]?: string;
  [HeapUserProperty.SignUpDate]?: string;
  [HeapUserProperty.SignUpSource]?: string;
  [HeapUserProperty.IsAdmin]?: string;
  [HeapUserProperty.IsBrand]?: string;
}

export const setUserId = (user: CurrentUser): void => {
  const userId = user.id.uuid;
  if (typeof window !== 'undefined') {
    if (window.heap) {
      window.heap.identify(userId);
      window.heap.addUserProperties({
        [HeapUserProperty.Email]: user.attributes.email,
        [HeapUserProperty.AccountCreationShopName]:
          user.attributes.profile.protectedData?.accountCreatedFromShopInfo?.shopName,
        [HeapUserProperty.IsAdmin]: `${!!user.attributes.profile.publicData?.isAdmin}`,
        [HeapUserProperty.IsBrand]: `${!!user.attributes.profile.publicData?.isBrand}`,
      } as UserProperties);

      setIsLoggedInEventProperty(true);
    }
  }
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const track = (eventName: Event, properties: HeapProperties = {}) => {
  if (typeof window !== 'undefined') {
    if (window.heap) {
      window.heap.track(eventName, properties);
    }

    if (isDev && !window.heap) {
      // eslint-disable-next-line no-console
      console.log('Heap event not tracked in development:', eventName, properties);
    }
  }
};

// TODO (sonia-y | TREET-1216): Delete and deprecate the non title cased properties
// Based off suggested fields from heap: https://developers.heap.io/docs/tracking-purchases-in-heap
interface TrackPurchaseProperties extends HeapProperties {
  // Old non title cased fields
  // Technically email can be found using the user id, but eventually when we
  // have guest checkout we want to make sure to have this info
  email?: string;
  listing_id: string;
  name: string;
  shop_name: string;
  is_brand_direct: string;
  category?: string;
  quantity: number;
  currency: string;
  gross_price: number;

  // Title Case going forward
  [HeapProperty.Email]?: string;
  [HeapProperty.ListingId]: string;
  [HeapProperty.Name]: string;
  [HeapProperty.ShopName]: string;
  [HeapProperty.IsBrandDirect]: string;
  [HeapProperty.Category]?: string;
  [HeapProperty.RecommendedPrice]: number;
  [HeapProperty.Quantity]: number;
  [HeapProperty.CurrencyLabel]: string;
  [HeapProperty.GrossPrice]: number;
  [HeapProperty.IsBackgroundRemoved]: string;
  [HeapProperty.NumRequiredImages]: number;
}

const areBackgroundImagesRemoved = (images?: UploadcareFile[]) => {
  if (!images) {
    return false;
  }
  return images.some((image: UploadcareFile) => image.isBackgroundRemoved);
};

const checkIfIsCrossShop = (currentUser?: CurrentUser, shopName?: string) => {
  const userShopName =
    currentUser?.attributes?.profile?.protectedData?.accountCreatedFromShopInfo?.shopName;

  return shopName !== userShopName;
};

interface TrackInitializeCheckoutProperties extends HeapProperties {
  // Old non title cased fields
  email?: string;
  shop_name?: string;
  item_count: number;

  // All fields should be title cased going forward
  [HeapProperty.Email]?: string;
  [HeapProperty.ShopName]?: string;
  [HeapProperty.ItemCount]: number;
  [HeapProperty.IsCrossShop]: string;
  [HeapProperty.NumSellers]: number;
}

interface TrackInitializeCheckoutParams {
  email?: string;
  shopName?: string;
  itemCount: number;
  currentUser?: CurrentUser;
  numSellers: number;
}

export const trackInitializeCheckout = (params: TrackInitializeCheckoutParams): void => {
  const properties: TrackInitializeCheckoutProperties = {
    email: params.email,
    shop_name: params.shopName,
    item_count: params.itemCount,

    [HeapProperty.Email]: params.email,
    [HeapProperty.ShopName]: params.shopName,
    [HeapProperty.ItemCount]: params.itemCount,
    [HeapProperty.IsCrossShop]: `${checkIfIsCrossShop(params.currentUser, params.shopName)}`,
    [HeapProperty.NumSellers]: params.numSellers,
  };

  track(Event.InitializeCheckout, properties);
};

// Currently, the user should always exist because every purchase is tied to a user; however,
// in the future if we have guest checkout, this might not be the case.
export const trackPurchase = (
  shopConfig: ShopConfigV2,
  listing: Listing,
  purchaseTime: number,
  user?: CurrentUser,
  shipFromCountries?: CountryCode[],
  shipToAddress?: SharetribeAddress,
  listingLineItem?: LineItem
): void => {
  const isAnyImageBackgroundRemoved = areBackgroundImagesRemoved(
    listing.attributes.publicData?.images
  );
  const shopName = listing.attributes.publicData?.shopName;

  const visibleStockPhotosCount = min([
    listing.attributes.publicData?.stockPhotoUrls?.length,
    shopConfig.maxStockImages,
  ]);

  const { automaticPriceDropInfo } = listing.attributes.publicData || {};

  const buildAutomaticPriceDropInfo = () => {
    const { enabled, anchorPrice } = automaticPriceDropInfo || {};
    if (enabled) {
      const salePrice = Number(listing.attributes.price.amount) / 100;
      // e.g. If the listing was auto price dropped once before selling,
      // this value will be -10 (default price drop % is 10).
      const percentDiffAnchorPriceSalePrice = Math.round(
        ((salePrice - Number(anchorPrice)) / Number(anchorPrice)) * 100
      ).toFixed(2);

      return {
        [HeapProperty.ListingHadAutoPriceDropEnabled]: enabled,
        [HeapProperty.ListingSalePricePercentAutoDropped]: percentDiffAnchorPriceSalePrice,
      };
    }

    return {};
  };

  const properties: TrackPurchaseProperties = {
    email: user?.attributes.email,
    listing_id: listing.id.uuid,
    name: listing.attributes.title,
    shop_name: shopName,
    is_brand_direct: `${!!listing.attributes.publicData?.isBrandDirect}`,
    category: listing.attributes.publicData?.category,
    gross_price: listing.attributes.price.amount,
    quantity: 1,
    currency: listing.attributes.price.currency,

    [HeapProperty.Email]: user?.attributes.email,
    [HeapProperty.ListingId]: listing.id.uuid,
    [HeapProperty.Name]: listing.attributes.title,
    [HeapProperty.ShopName]: shopName,
    [HeapProperty.IsBrandDirect]: `${!!listing.attributes.publicData?.isBrandDirect}`,
    [HeapProperty.Category]: listing.attributes.publicData?.category,
    [HeapProperty.GrossPrice]: listing.attributes.price.amount,
    [HeapProperty.Quantity]: 1,
    [HeapProperty.CurrencyLabel]: listing.attributes.price.currency,
    [HeapProperty.IsBackgroundRemoved]: `${isAnyImageBackgroundRemoved}`,
    [HeapProperty.ShipFromCountries]: shipFromCountries && `${shipFromCountries?.join(',')}`,
    [HeapProperty.ShipToCountry]: shipToAddress?.country,
    [HeapProperty.ShipToState]: shipToAddress?.state,
    [HeapProperty.ShipToZip]: shipToAddress?.postal,
    [HeapProperty.ShipToCity]: shipToAddress?.city,
    [HeapProperty.Condition]: listing.attributes.publicData?.condition,
    [HeapProperty.RecommendedPrice]: listing.attributes.publicData?.recommendedPrice,
    [HeapProperty.OriginatedAsGuestListing]: `${!!listing.attributes.publicData
      ?.originatedAsGuestListing}`,
    // Do not include if undefined (i.e. brand direct listings don't req uploaded images.)
    ...(listing.attributes.publicData?.images && {
      [HeapProperty.ImageCount]: listing.attributes.publicData?.images?.length,
    }),
    ...(listing.attributes.publicData?.numRequiredImages && {
      [HeapProperty.NumRequiredImages]: listing.attributes.publicData?.numRequiredImages,
    }),
    ...(!isEmpty(listing.attributes.publicData?.promosActive) && {
      [HeapProperty.PromosActive]: listing.attributes.publicData?.promosActive.join(','),
    }),
    [HeapProperty.ListingAge]:
      purchaseTime -
      (listing.attributes.publicData?.approvedAt || listing.attributes.createdAt.valueOf()),
    [HeapProperty.IsCrossShop]: `${checkIfIsCrossShop(user, shopName)}`,
    ...(!isNil(listing.attributes.publicData?.stockPhotoUrls) && {
      [HeapProperty.VisibleStockPhotosCount]: visibleStockPhotosCount,
    }),
    ...buildAutomaticPriceDropInfo(),
  };

  track(Event.Purchase, properties);

  // Tracks the event on both server and client side
  trackHeapEvent({
    eventName: Event.Purchase,
    properties,
    idempotencyKey: listingLineItem?.id,
    currentUserId: user?.id.uuid,
    sessionId: getSessionId(),
  });
};

interface TrackOrderParams {
  userId?: string;
  email?: string;
  shopName?: string;
  orderId: string;
  itemCount: number;
  subtotal: number;
  total: number;
  shipping: number;
  discount: number;
  insurance: number;
  currency: Currency;
  isReturnInsurancePurchased: boolean;
  returnInsuranceQuote?: number;
  discountCode?: string;
  shipToAddress?: SharetribeAddress;
  currentUser: CurrentUser;
  numSellers: number;
  paymentMethod: string;
  page: string;
}

interface TrackOrderProperties extends HeapProperties {
  // Old non title cased fields
  email?: string;
  shop_name?: string;
  order_id: string;
  item_count: number;
  // The subtotal price of the order before shipping and taxes
  subtotal: number;
  // The total price of the order including shipping and taxes
  total: number;
  // The amount charged for shipping
  shipping: number;
  // The total amount of discounts applied to the order
  discount: number;
  // The amount charged for return insurance
  insurance: number;
  discount_code?: string;
  currency: Currency;

  // All fields should be title cased going forward
  [HeapProperty.Email]?: string;
  [HeapProperty.ShopName]?: string;
  [HeapProperty.OrderId]: string;
  [HeapProperty.ItemCount]: number;
  [HeapProperty.Subtotal]: number;
  [HeapProperty.Total]: number;
  [HeapProperty.Shipping]: number;
  [HeapProperty.Discount]: number;
  [HeapProperty.DiscountCode]?: string;
  [HeapProperty.CurrencyLabel]: Currency;
  [HeapProperty.IsReturnInsurancePurchased]: string;
  [HeapProperty.ReturnInsuranceQuote]?: number;
  [HeapProperty.PaymentMethod]: string;
  [HeapProperty.Page]: string;
}

export const trackOrder = (params: TrackOrderParams): void => {
  const properties: TrackOrderProperties = {
    email: params.email,
    shop_name: params.shopName,
    order_id: params.orderId,
    item_count: params.itemCount,
    subtotal: params.subtotal,
    total: params.total,
    shipping: params.shipping,
    discount: params.discount,
    insurance: params.insurance,
    discount_code: params.discountCode,
    currency: params.currency,
    numSellers: params.numSellers,
    paymentMethod: params.paymentMethod,
    page: params.page,

    [HeapProperty.Email]: params.email,
    [HeapProperty.ShopName]: params.shopName,
    [HeapProperty.OrderId]: params.orderId,
    [HeapProperty.ItemCount]: params.itemCount,
    [HeapProperty.Subtotal]: params.subtotal,
    [HeapProperty.Total]: params.total,
    [HeapProperty.Shipping]: params.shipping,
    [HeapProperty.Discount]: params.discount,
    [HeapProperty.DiscountCode]: params.discountCode,
    [HeapProperty.CurrencyLabel]: params.currency,
    [HeapProperty.ShipToCountry]: params.shipToAddress?.country,
    [HeapProperty.ShipToState]: params.shipToAddress?.state,
    [HeapProperty.ShipToZip]: params.shipToAddress?.postal,
    [HeapProperty.ShipToCity]: params.shipToAddress?.city,
    [HeapProperty.IsReturnInsurancePurchased]: `${params.isReturnInsurancePurchased}`,
    [HeapProperty.ReturnInsuranceQuote]: params.returnInsuranceQuote,
    [HeapProperty.IsCrossShop]: `${checkIfIsCrossShop(params.currentUser, params.shopName)}`,
    [HeapProperty.NumSellers]: params.numSellers,
    [HeapProperty.PaymentMethod]: params.paymentMethod,
    [HeapProperty.Page]: params.page,
  };

  track(Event.Order, properties);

  // Tracks the event on both server and client side
  trackHeapEvent({
    eventName: Event.Order,
    properties,
    idempotencyKey: params.orderId,
    currentUserId: params.userId,
    sessionId: getSessionId(),
  });
};

interface TrackFilterProperties extends HeapProperties {
  // Old non title case fields.
  shop_name: string;
  filter_type: string;
  filter_value: string;

  // Everything should be title cased going forward
  [HeapProperty.ShopName]: string;
  [HeapProperty.FilterType]: string;
  [HeapProperty.FilterValue]: string;
}

export const trackFilter = (shopName: string, filterType: string, filterValue: string): void => {
  const properties: TrackFilterProperties = {
    shop_name: shopName,
    filter_type: filterType,
    filter_value: filterValue,

    [HeapProperty.ShopName]: shopName,
    [HeapProperty.FilterType]: filterType,
    [HeapProperty.FilterValue]: filterValue,
  };

  track(Event.SearchFilter, properties);
};

interface TrackSubscribeProperties extends HeapProperties {
  [HeapProperty.ShopName]: string;
  [HeapProperty.Email]: string;
  [HeapProperty.EmailContactList]: string;
  [HeapProperty.SubscribeSource]: EmailSubscribeSource;
  [HeapProperty.GroupId]: string;
}

interface TrackSizeSubscribeProperties {
  'Subscribed Size': string;
}

export const trackSubscribe = (
  shopName: string,
  email: string,
  list: string,
  subscribeSource: EmailSubscribeSource,
  sizes?: string[]
): void => {
  const uniqueSubscribeId = `unique-subscribe-id-${uuidv4()}`;
  const properties: TrackSubscribeProperties = {
    [HeapProperty.ShopName]: shopName,
    [HeapProperty.Email]: email,
    [HeapProperty.EmailContactList]: list,
    [HeapProperty.SubscribeSource]: subscribeSource,
    [HeapProperty.GroupId]: uniqueSubscribeId,
  };

  if (sizes) {
    // Send one event per size subscribed.
    sizes.forEach((size) => {
      const sizeProperties: TrackSubscribeProperties & TrackSizeSubscribeProperties = {
        ...properties,
        [HeapProperty.SubscribeSize]: size,
      };
      track(Event.SizeSubscribe, sizeProperties);
    });
  }

  track(Event.Subscribe, properties);
};

interface TrackSubscribeISOProperties extends HeapProperties {
  [HeapProperty.ShopName]: string;
  [HeapProperty.Email]: string;
  [HeapProperty.SubscribeSource]: EmailSubscribeSource;
  [HeapProperty.Cadence]: SavedSearchCadence;
  [HeapProperty.GroupId]: string;
  [HeapProperty.ModalPane]: Pane | undefined;
  [HeapProperty.FindItemMethod]: string | undefined;
  [HeapProperty.ShopifyProductId]: string | undefined;
  [HeapProperty.SearchedQuery]: string | undefined;
}

export const trackSubscribeISO = (params: {
  shopName: string;
  email: string;
  subscribeSource: EmailSubscribeSource;
  cadence: SavedSearchCadence;
  modalPane?: Pane;
  findItemMethod?: string;
  searchedQuery?: string;
  shopifyProductId?: string;
}): void => {
  const {
    shopName,
    email,
    subscribeSource,
    cadence,
    modalPane,
    findItemMethod,
    searchedQuery,
    shopifyProductId,
  } = params;

  const uniqueSubscribeISOId = `unique-subscribe-iso-id-${uuidv4()}`;
  const properties: TrackSubscribeISOProperties = {
    [HeapProperty.ShopName]: shopName,
    [HeapProperty.Email]: email,
    [HeapProperty.SubscribeSource]: subscribeSource,
    [HeapProperty.Cadence]: cadence,
    [HeapProperty.ModalPane]: modalPane,
    [HeapProperty.FindItemMethod]: findItemMethod,
    [HeapProperty.GroupId]: uniqueSubscribeISOId,
    [HeapProperty.ShopifyProductId]: shopifyProductId,
    [HeapProperty.SearchedQuery]: searchedQuery,
  };

  track(Event.SubscribeISO, properties);
};

export const trackSubscribeISOSizes = (params: {
  shopName: string;
  email: string;
  subscribeSource: EmailSubscribeSource;
  cadence: SavedSearchCadence;
  modalPane?: Pane;
  findItemMethod?: string;
  searchedQuery?: string;
  shopifyProductId?: string;
  sizes?: string[];
}): void => {
  const {
    shopName,
    email,
    subscribeSource,
    cadence,
    modalPane,
    findItemMethod,
    searchedQuery,
    shopifyProductId,
    sizes,
  } = params;

  const uniqueSubscribeISOId = `unique-subscribe-iso-sizes-id-${uuidv4()}`;
  const properties: TrackSubscribeISOProperties = {
    [HeapProperty.ShopName]: shopName,
    [HeapProperty.Email]: email,
    [HeapProperty.SubscribeSource]: subscribeSource,
    [HeapProperty.Cadence]: cadence,
    [HeapProperty.ModalPane]: modalPane,
    [HeapProperty.FindItemMethod]: findItemMethod,
    [HeapProperty.GroupId]: uniqueSubscribeISOId,
    [HeapProperty.ShopifyProductId]: shopifyProductId,
    [HeapProperty.SearchedQuery]: searchedQuery,
  };

  if (sizes) {
    // Send one event per size subscribed.
    sizes.forEach((size) => {
      const sizeProperties: TrackSubscribeISOProperties & TrackSizeSubscribeProperties = {
        ...properties,
        [HeapProperty.SubscribeSize]: size,
      };
      track(Event.SizeSubscribeISO, sizeProperties);
    });
  }
};

export const trackShowLandingPageSubscribeModal = (shopName: string): void => {
  const properties = { [HeapProperty.ShopName]: shopName };
  track(Event.ShowLandingPageSubscribeModal, properties);
};

export const trackShowCountryWarningModal = (shopName: string): void => {
  const properties = { [HeapProperty.ShopName]: shopName };
  track(Event.ShowCountryWarningModal, properties);
};

export const trackShowISOModal = (shopName: string): void => {
  const properties = { [HeapProperty.ShopName]: shopName };
  track(Event.ShowISOModal, properties);
};

export const trackShowListingFeedbackModal = (treetId: string): void => {
  const properties = { [HeapProperty.TreetId]: treetId };
  track(Event.ShowListingFeedbackModal, properties);
};

export const trackSignUp = (user: CurrentUser, signUpSource: string): void => {
  const signUpTime = new Date(Date.now());
  if (typeof window !== 'undefined') {
    if (window.heap) {
      window.heap.identify(user.id.uuid);
      window.heap.addUserProperties({
        [HeapUserProperty.SignUpDate]: signUpTime.toISOString(),
        [HeapUserProperty.SignUpSource]: signUpSource,
      } as Omit<UserProperties, 'email'>);
    }
  }
  const properties = {
    [HeapProperty.SignUpSource]: signUpSource,
  };
  track(Event.SignUp, properties);
};

const isUpdated = <T>(originalListingValue: T, updatedListingValue: T) =>
  originalListingValue !== updatedListingValue;

const includeIfUpdated = <T>(
  propertyName: string,
  originalListingValue: T,
  updatedListingValue: T
) => {
  if (isUpdated(originalListingValue, updatedListingValue)) {
    return {
      [`Original ${propertyName}`]: originalListingValue,
      [`Updated ${propertyName}`]: updatedListingValue,
    };
  }

  return {};
};

const didUpdateImages = (
  originalImages: SharetribeImage[] | UploadcareFile[] | undefined,
  updatedImages: SharetribeImage[] | UploadcareFile[] | undefined
) => {
  const currentImageIds = originalImages?.map((image) => {
    if ('uuid' in image) {
      return image.uuid;
    }
    return image.id?.uuid;
  });
  const newImageIds = updatedImages?.map((image) => {
    if ('uuid' in image) {
      return image.uuid;
    }
    return image.id?.uuid;
  });

  const hasImages = isEmpty(newImageIds) && isEmpty(currentImageIds);
  const result = hasImages
    ? `${currentImageIds?.sort().toString() !== newImageIds?.sort().toString()}`
    : 'false';

  return {
    [HeapProperty.DidUpdateImages]: result,
  };
};

export const trackSaveExistingListing = (
  listingBeforeUpdate: OwnListingWithImages,
  updatedListing: OwnListingWithImages,
  treetId: string
): void => {
  const {
    condition,
    originalPrice,
    shopName,
    shopifyProductVariant,
    images: uploadCareImages,
    listingItemType,
    automaticPriceDropInfo,
  } = listingBeforeUpdate.attributes.publicData;
  const {
    condition: updatedCondition,
    originalPrice: updatedOriginalPrice,
    shopifyProductVariant: updatedShopifyProductVariant,
    images: updatedUploadCareImages,
    listingItemType: updatedListingItemType,
    automaticPriceDropInfo: updatedAutomaticPriceDropInfo,
  } = updatedListing.attributes.publicData;

  const originalListedPrice = (listingBeforeUpdate.attributes.price.amount / 100).toFixed(2);
  const updatedListedPrice = (updatedListing.attributes.price.amount / 100).toFixed(2);
  const didPriceUpdate =
    isUpdated(originalPrice, updatedOriginalPrice) ||
    isUpdated(originalListedPrice, updatedListedPrice);

  const originalImages = uploadCareImages || listingBeforeUpdate.images || [];
  const updatedImages = updatedUploadCareImages || updatedListing.images || [];

  const autoPriceDropUpdates = {
    ...includeIfUpdated(
      HeapProperty.AutomaticPriceDropEnabled,
      automaticPriceDropInfo?.enabled,
      updatedAutomaticPriceDropInfo?.enabled
    ),
    ...includeIfUpdated(
      HeapProperty.AutomaticPriceDropMinPrice,
      automaticPriceDropInfo?.minPrice,
      updatedAutomaticPriceDropInfo?.minPrice
    ),
    ...includeIfUpdated(
      HeapProperty.AutomaticPriceDropPercentage,
      automaticPriceDropInfo?.percentageDrop,
      updatedAutomaticPriceDropInfo?.percentageDrop
    ),
    ...includeIfUpdated(
      HeapProperty.AutomaticPriceDropAnchorPrice,
      automaticPriceDropInfo?.anchorPrice,
      updatedAutomaticPriceDropInfo?.anchorPrice
    ),
  };

  const properties = {
    [HeapProperty.TreetId]: treetId,
    [HeapProperty.ListingId]: updatedListing.id.uuid,
    [HeapProperty.ShopName]: shopName,
    ...includeIfUpdated(HeapProperty.Condition, condition, updatedCondition),
    ...includeIfUpdated(HeapProperty.OriginalRetailPrice, originalPrice, updatedOriginalPrice),
    ...includeIfUpdated(HeapProperty.ListedPrice, originalListedPrice, updatedListedPrice),
    [HeapProperty.CurrencyLabel]: didPriceUpdate
      ? updatedListing.attributes.price.currency
      : undefined,
    ...includeIfUpdated(
      HeapProperty.ShopifyProductVariantId,
      shopifyProductVariant?.id,
      updatedShopifyProductVariant?.id
    ),
    ...includeIfUpdated(HeapProperty.ImageCount, originalImages?.length, updatedImages?.length),
    ...didUpdateImages(originalImages, updatedImages),
    ...includeIfUpdated(HeapProperty.ListingItemType, listingItemType, updatedListingItemType),
    ...autoPriceDropUpdates,
  };
  return track(Event.SaveExisitingListing, properties);
};

const generatePropertiesForListingEvents = (
  listing: OwnListingWithImages | any,
  treetId: string
) => {
  const {
    isBrandDirect,
    recommendedPrice,
    condition,
    originalPrice,
    shopName,
    images: uploadCareImages,
    numRequiredImages,
    shopifyProductVariant,
    listingItemType,
    originatedAsGuestListing,
    listingFlowId,
    listingFinishTime,
    listingStartTime,
    listingFlowSectionToTimestamp,
    didUseQRCode,
    stockPhotoUrls,
    automaticPriceDropInfo,
  } = listing.attributes.publicData;
  const listedPrice = listing.attributes.price?.amount
    ? (listing.attributes.price.amount / 100).toFixed(2)
    : 0;
  const isAnyImageBackgroundRemoved = areBackgroundImagesRemoved(uploadCareImages);

  let previousFinishTime = listingStartTime;

  const adjustedSectionCompletionTimestamps: { [sectionName: string]: number } = Object.entries(
    listingFlowSectionToTimestamp as { [sectionName: string]: number }
  )
    .sort(([, finishTimeA], [, finishTimeB]) => finishTimeA - finishTimeB)
    .reduce((acc, [sectionName, sectionFinishTime]) => {
      const timeForSectionToComplete = sectionFinishTime - previousFinishTime;
      previousFinishTime = sectionFinishTime;
      return { ...acc, [sectionName]: timeForSectionToComplete };
    }, {});

  const timeSpentOnEachSection = {
    [HeapProperty.TimeSpentOnFindItem]:
      adjustedSectionCompletionTimestamps[camelCase(ProgressBarSection.FindItem)],
    [HeapProperty.TimeSpentOnItemDetails]:
      adjustedSectionCompletionTimestamps[camelCase(ProgressBarSection.ItemDetails)],
    [HeapProperty.TimeSpentOnCondition]:
      adjustedSectionCompletionTimestamps[camelCase(ProgressBarSection.Condition)],
    [HeapProperty.TimeSpentOnPhotos]:
      adjustedSectionCompletionTimestamps[camelCase(ProgressBarSection.Photos)],
    [HeapProperty.TimeSpentOnPrice]:
      adjustedSectionCompletionTimestamps[camelCase(ProgressBarSection.Price)],
    [HeapProperty.TimeSpentOnAcknowledgement]:
      adjustedSectionCompletionTimestamps[camelCase(ProgressBarSection.Acknowledgement)],
  };

  const buildAutomaticPriceDropProperties = () => {
    if (listingItemType !== ListingItemTypeType.Marketplace || isEmpty(automaticPriceDropInfo)) {
      return {};
    }

    const { enabled, minPrice, anchorPrice, percentageDrop } = automaticPriceDropInfo || {};
    return {
      [HeapProperty.AutomaticPriceDropEnabled]: enabled,
      ...(enabled && {
        [HeapProperty.AutomaticPriceDropPercentage]: percentageDrop,
        [HeapProperty.AutomaticPriceDropMinPrice]: minPrice,
        [HeapProperty.AutomaticPriceDropAnchorPrice]: anchorPrice,
        [HeapProperty.AutomaticPriceDropMinPricePercentOfSellingPrice]: Math.round(
          (Number(minPrice) / Number(anchorPrice)) * 100
        ).toFixed(2), // If they used the default, this value will be 80.
      }),
    };
  };

  return {
    [HeapProperty.TreetId]: treetId,
    [HeapProperty.ListingId]: listing.id.uuid,
    [HeapProperty.ShopName]: shopName,
    [HeapProperty.IsBrandDirect]: isBrandDirect || false,
    [HeapProperty.RecommendedPrice]: recommendedPrice,
    [HeapProperty.Condition]: condition,
    [HeapProperty.OriginalRetailPrice]: originalPrice,
    [HeapProperty.ListedPrice]: listedPrice,
    [HeapProperty.CurrencyLabel]: listing.attributes.price?.currency,
    [HeapProperty.ShopifyProductVariantId]: shopifyProductVariant?.id,
    [HeapProperty.ImageCount]: uploadCareImages?.length || listing.images?.length,
    [HeapProperty.NumRequiredImages]: numRequiredImages,
    [HeapProperty.ListingItemType]: listingItemType,
    [HeapProperty.OriginatedAsGuestListing]: `${originatedAsGuestListing}`,
    [HeapProperty.IsBackgroundRemoved]: `${isAnyImageBackgroundRemoved}`,
    ...(listingFinishTime &&
      listingStartTime && {
        [HeapProperty.TotalTimeSpent]: listingFinishTime - listingStartTime,
      }),
    ...timeSpentOnEachSection,
    [HeapProperty.ListingFlowId]: listingFlowId,
    [HeapProperty.DidUseQRCode]: didUseQRCode,
    ...(!isNil(stockPhotoUrls) && {
      [HeapProperty.SavedStockPhotosCount]: stockPhotoUrls?.length,
    }),
    ...buildAutomaticPriceDropProperties(),
  };
};

export const trackPublishNewListing = (listing: OwnListingWithImages, treetId: string): void => {
  const properties = generatePropertiesForListingEvents(listing, treetId);

  return track(Event.PublishNewListing, properties);
};

export const trackSaveDraftListing = (
  listing: OwnListingWithImages | any,
  treetId: string
): void => {
  const properties = generatePropertiesForListingEvents(listing, treetId);

  return track(Event.SaveDraftListing, properties);
};

export const trackCompleteListingSection = (eventProperties: HeapProperties): void => {
  const properties = {
    [HeapProperty.AlreadyCompletedSections]: eventProperties.alreadyCompletedSections,
    [HeapProperty.MostRecentlyCompletedSection]: eventProperties.mostRecentlyCompletedSection,
    [HeapProperty.SectionName]: eventProperties.sectionName,
    [HeapProperty.TotalTimeSpent]: eventProperties.totalTimeSpent,
    [HeapProperty.ListingItemType]: eventProperties.itemType,
    [HeapProperty.ListingFlowId]: eventProperties.listingFlowId,
    [HeapProperty.FindItemMethod]: eventProperties.findItemMethod,
    [HeapProperty.DidUseQRCode]: eventProperties.didUseQRCode,
  };

  return track(Event.CompleteListingSection, properties);
};

export const trackBackgroundRemovedImageUpload = (
  listingId: string | undefined,
  treetId: string,
  shopName: string
) => {
  const properties = {
    [HeapProperty.TreetId]: treetId,
    [HeapProperty.ListingId]: listingId,
    [HeapProperty.ShopName]: shopName,
    [HeapProperty.OriginatedAsGuestListing]: `${!listingId}`,
  };
  return track(Event.UploadBackgroundRemovedImage, properties);
};

export const trackDeleteImage = (
  listingId: string | undefined,
  treetId: string,
  isBackgroundRemoved: boolean,
  shopName: string
) => {
  const properties = {
    [HeapProperty.TreetId]: treetId,
    [HeapProperty.IsBackgroundRemoved]: `${isBackgroundRemoved}`,
    [HeapProperty.ListingId]: listingId,
    [HeapProperty.ShopName]: shopName,
    [HeapProperty.OriginatedAsGuestListing]: `${!listingId}`,
  };
  return track(Event.DeleteImage, properties);
};

export const trackRemovedBackground = (
  listingId: string | undefined,
  treetId: string,
  shopName: string
) => {
  const properties = {
    [HeapProperty.TreetId]: treetId,
    [HeapProperty.ListingId]: listingId,
    [HeapProperty.ShopName]: shopName,
    [HeapProperty.OriginatedAsGuestListing]: `${!listingId}`,
  };
  return track(Event.RemoveBackground, properties);
};

export const trackChooseStockPhotos = (
  availableStockPhotosCount: number,
  removedStockPhotosCount: number,
  savedStockPhotosCount: number
): void => {
  const properties = {
    [HeapProperty.AvailableStockPhotosCount]: availableStockPhotosCount,
    [HeapProperty.RemovedStockPhotosCount]: removedStockPhotosCount,
    [HeapProperty.SavedStockPhotosCount]: savedStockPhotosCount,
  };

  return track(Event.ChooseStockPhotos, properties);
};

export interface TrackListingCardParams {
  listingId: string;
  treetId: string;
  indexOnPage?: number;
  pageNumber?: number;
  page?: string;
  originOfClick: string;
  isMobile: boolean;
  isPurchased: boolean;
  featuredImageType?: string;
  hoveredImageType?: string;
  searchKeywords?: string;
}

export const trackClickListingCard = ({
  listingId,
  treetId,
  indexOnPage,
  pageNumber,
  page,
  originOfClick,
  isMobile,
  isPurchased,
  featuredImageType,
  hoveredImageType,
  searchKeywords,
}: TrackListingCardParams) => {
  const properties = {
    [HeapProperty.TreetId]: treetId,
    [HeapProperty.ListingId]: listingId,
    [ListingCardHeapProperty.FeaturedImageType]: featuredImageType,
    [ListingCardHeapProperty.HoveredImageType]: hoveredImageType,
    [ListingCardHeapProperty.IndexOnPage]: indexOnPage,
    [ListingCardHeapProperty.PageNumber]: pageNumber,
    [ListingCardHeapProperty.Page]: page,
    [ListingCardHeapProperty.OriginOfClick]: originOfClick,
    [ListingCardHeapProperty.IsMobile]: `${isMobile}`,
    [ListingCardHeapProperty.IsPurchased]: `${isPurchased}`,
    [HeapProperty.SearchKeywords]: searchKeywords,
  };
  return track(Event.ClickListingCard, properties);
};

export const trackSelectConsolidatedListing = (
  originatingListingId: string,
  selectedListingId: string,
  indexOfSelectedListing: number,
  treetId: string,
  shopName: string
) => {
  const properties = {
    [HeapProperty.OriginatingListingId]: originatingListingId,
    [HeapProperty.ListingId]: selectedListingId,
    [HeapProperty.IndexOfSelectedListing]: indexOfSelectedListing,
    [HeapProperty.TreetId]: treetId,
    [HeapProperty.ShopName]: shopName,
  };
  return track(Event.SelectConsolidatedListing, properties);
};

export const trackVisitListingPage = (
  listing: Listing,
  numConsolidatedListings: number,
  treetId: string,
  shopName: string
) => {
  const properties = {
    [HeapProperty.ListingId]: listing.id.uuid,
    [HeapProperty.TreetId]: treetId,
    [HeapProperty.ShopName]: shopName,
    [HeapProperty.NumConsolidatedListings]: numConsolidatedListings,
    [HeapProperty.IsBrandDirect]: `${!!listing.attributes.publicData?.isBrandDirect}`,
  };
  return track(Event.VisitListingPage, properties);
};

interface TrackListingFeedbackProgressProperties extends HeapProperties {
  [HeapProperty.TreetId]: string;
  [HeapProperty.ModalPane]: ListingFeedbackModalPane;
  [HeapProperty.Email]?: string;
  [ListingFeedbackHeapProperty.FeedbackCategory]: ListingFeedbackCategory;
  [ListingFeedbackHeapProperty.HasFeedbackNote]: string;
  [ListingFeedbackHeapProperty.IsCompleteSubmission]: string;
}

export const trackListingFeedbackProgress = (params: {
  treetId: string;
  modalPane: ListingFeedbackModalPane;
  hasFeedbackNote: boolean;
  isCompleteSubmission: boolean;
  category: ListingFeedbackCategory;
  email?: string;
}): void => {
  const { treetId, email, modalPane, category, hasFeedbackNote, isCompleteSubmission } = params;

  const properties: TrackListingFeedbackProgressProperties = {
    [HeapProperty.TreetId]: treetId,
    [HeapProperty.ModalPane]: modalPane,
    [ListingFeedbackHeapProperty.HasFeedbackNote]: `${hasFeedbackNote}`,
    [ListingFeedbackHeapProperty.IsCompleteSubmission]: `${isCompleteSubmission}`,
    ...(email && { [HeapProperty.Email]: email }),
    ...(category && { [ListingFeedbackHeapProperty.FeedbackCategory]: category }),
  };

  track(Event.ListingFeedbackProgress, properties);
};

export const trackZoomPDPImage = (listingId: string, imageSrc: string, treetId: string) => {
  const properties = {
    [HeapProperty.ListingId]: listingId,
    [HeapProperty.ImageSrc]: imageSrc,
    [HeapProperty.TreetId]: treetId,
  };
  return track(Event.ZoomPDPImage, properties);
};

export const trackFiltersBarFilterClick = (filter: string, shopName: string) => {
  const properties = {
    [HeapProperty.ShopName]: shopName,
    [FiltersHeapProperty.Filter]: filter,
  };
  return track(Event.ClickFiltersBarFilter, properties);
};

export const trackFiltersBarPopupFilterSubmit = (filter: string, shopName: string) => {
  const properties = {
    [FiltersHeapProperty.Filter]: filter,
    [HeapProperty.ShopName]: shopName,
  };
  return track(Event.SubmitFiltersBarPopupFilter, properties);
};

export const trackFiltersBarPopupClear = (filter: string, shopName: string) => {
  const properties = {
    [FiltersHeapProperty.Filter]: filter,
    [HeapProperty.ShopName]: shopName,
  };
  return track(Event.ClearFiltersBarPopupFilter, properties);
};

export const trackViewSearchResults = (
  numListings: number,
  searchKeywords: string,
  treetId: string
) => {
  const properties = {
    [HeapProperty.NumListings]: numListings,
    [HeapProperty.SearchKeywords]: searchKeywords,
    [HeapProperty.TreetId]: treetId,
  };
  return track(Event.ViewSearchResults, properties);
};

export const trackShowMobileFiltersModal = (shopName: string) => {
  const properties = {
    [HeapProperty.ShopName]: shopName,
  };
  return track(Event.ShowMobileFiltersModal, properties);
};

export const trackMobileFiltersModalFilterClear = (filter: string, shopName: string) => {
  const properties = {
    [FiltersHeapProperty.Filter]: filter,
    [HeapProperty.ShopName]: shopName,
  };
  return track(Event.ClearFilterMobileFiltersModal, properties);
};

export const trackMobileFiltersModalFilterClearAll = (shopName: string) => {
  const properties = {
    [HeapProperty.ShopName]: shopName,
  };
  return track(Event.ClearAllFiltersMobileFiltersModal, properties);
};

export const trackMobileFiltersModalSubmit = (
  filters: string,
  shopName: string,
  numOfResults?: number
) => {
  const properties = {
    [FiltersHeapProperty.Filters]: filters,
    [HeapProperty.ShopName]: shopName,
    [FiltersHeapProperty.NumberOfResults]: numOfResults,
  };
  return track(Event.SubmitFiltersModal, properties);
};

export const trackClickISOBanner = (treetId: string, shopName: string, pageNumber: number) => {
  const properties = {
    [HeapProperty.TreetId]: treetId,
    [HeapProperty.ShopName]: shopName,
    [HeapProperty.PageNumber]: pageNumber,
  };
  return track(Event.ClickISOBanner, properties);
};

export const trackClickLoyaltyPointsSection = (
  treetId: string,
  shopName: string,
  numLoyaltyPoints: number
) => {
  const properties = {
    [HeapProperty.TreetId]: treetId,
    [HeapProperty.ShopName]: shopName,
    [HeapProperty.NumLoyaltyPoints]: numLoyaltyPoints,
  };

  return track(Event.ClickLoyaltyPointsSection, properties);
};
