import { Conditions, getConditionsFilterConfig } from '../filters/condition';
import { getDefaultShopConfig } from './default';
import * as custom from '../filters/marketplace-custom-config';
import { getSizeFilterConfigFromOptions } from '../filters/size';

const wornhubConditionsOptions = [
  {
    key: Conditions.NewWithTags,
    label: 'New With Tags',
    description:
      'Your item has never been worn and the original hanging tags are still on. For this option, you’ll need to take pictures of the retail tags.',
  },
  {
    key: Conditions.NewWithoutTags,
    label: 'New Without Tags',
    description:
      'Your item has never been worn (other than perhaps trying it on), but the tags have been removed. There are no quirks or issues whatsoever.',
  },
  {
    key: Conditions.Excellent,
    label: 'Excellent',
    description:
      'Your item may have been worn a handful of times, but it’s in excellent condition and clean without any stains or notable wear.',
  },
  {
    key: Conditions.Good,
    label: 'Good',
    description:
      'Your item has been worn, but it’s in good condition and clean without any stains. Please be sure to clearly note any signs of wear including stretching, fading, or holes.',
  },
];

const wornhubSizeOptions = [
  { key: 'XXS', label: 'XXS' },
  { key: 'XS', label: 'XS' },
  { key: 'S', label: 'S' },
  { key: 'M', label: 'M' },
  { key: 'L', label: 'L' },
  { key: 'XL', label: 'XL' },
  { key: '2X', label: '2X' },
  { key: '3X', label: '3X' },
  { key: '4X', label: '4X' },
  { key: '5X', label: '5X' },
  { key: 'OSFA', label: 'OSFA' },
];

export const wornhubShopConfig = {
  ...getDefaultShopConfig(),
  filters: [
    ...custom.filters,
    getConditionsFilterConfig({ options: wornhubConditionsOptions }),
    getSizeFilterConfigFromOptions({ options: wornhubSizeOptions }),
  ],
};
