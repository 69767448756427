import { getDefaultShopConfig } from './default';
import * as custom from '../filters/marketplace-custom-config';
import { Conditions, getConditionsFilterConfig } from '../filters/condition';

export const betterlifebagsConditionOptions = [
  {
    key: Conditions.Excellent,
    label: 'Excellent Condition',
    description:
      'Your item has only been lightly used and has been extremely well maintained. There may be some small signs of wear, but nothing major.',
  },
  {
    key: Conditions.Good,
    label: 'Good Condition',
    description:
      'You’ve worn this item and it’s well maintained. There may be some quirks like a small stain, a button missing, or a loose thread, but it’s overall in good shape. You will need to take pictures of any quirks the item has.',
  },
];

export const betterlifebagsShopConfig = {
  ...getDefaultShopConfig(),
  filters: [
    ...custom.filters,
    getConditionsFilterConfig({ options: betterlifebagsConditionOptions }),
  ],
};
