/* eslint-disable import/prefer-default-export */
import { getDefaultShopConfig } from './default';
import { getBrandDirectFilterConfig } from '../filters/brandDirect';
import { getConditionsFilterConfig } from '../filters/condition';
import * as custom from '../filters/marketplace-custom-config';
import { getSizeFilterConfig } from '../filters/size';

const whyandwhaleSizeOptions = [
  'Regular - 20"',
  'Little - 13"',
  '0-3m',
  '3-6m',
  '6-12m',
  '12-18m',
  '18-24m',
  '6',
  '8',
  '10',
  '2T',
  '3T',
  '4T',
];

export const whyandwhaleShopConfig = {
  ...getDefaultShopConfig(),
  filters: [
    ...custom.filters,
    getBrandDirectFilterConfig(),
    getSizeFilterConfig({ sizes: whyandwhaleSizeOptions }),
    getConditionsFilterConfig(),
  ],
};
