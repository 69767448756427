import { getConditionsFilterConfig } from '../filters/condition';
import { getBrandDirectFilterConfig } from '../filters/brandDirect';
import { getSizeFilterConfig } from '../filters/size';
import { getDefaultShopConfig } from './default';
import * as custom from '../filters/marketplace-custom-config';

const encircledUsSizes = ['XS', 'S', 'M', 'L', 'XL', 'XXL', '2X', '3X', '4X', 'One Size'];

export const encircledUsShopConfig = {
  ...getDefaultShopConfig(),
  filters: [
    ...custom.filters,
    getBrandDirectFilterConfig(),
    getConditionsFilterConfig(),
    getSizeFilterConfig({ sizes: encircledUsSizes }),
  ],
  additionalPayoutSettings: {
    creditCodeHelperText:
      'Credit is converted to and issued in CAD, but may be redeemed at checkout in any currency.',
  },
};
