import { Dialog, IconButton, useMediaQuery, useTheme, withStyles } from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import { bool, string } from 'prop-types';
import React from 'react';
import { TypographyWrapper } from '..';
import { useShopConfig } from '../../hooks/shopConfig';

// based on https://material-ui.com/components/dialogs/
const styles = () => ({
  root: {
    margin: 0,
    padding: '16px',
  },
  closeButton: {
    position: 'absolute',
    right: '0',
    top: '0',
    color: '#4a4a4a',
    padding: '16px',
  },
});

// Wrapper class for the transition that removes any animation
const NoTransition = React.forwardRef(({ children }, ref) =>
  React.cloneElement(children, { ref, tabIndex: -1 })
);

// make this shared
const DialogWithCloseButton = withStyles(styles)((props) => {
  const {
    allowFullScreen,
    children,
    titleText,
    onClose,
    open,
    classes,
    headerClassName,
    closeButtonClassName,
    ...rest
  } = props;

  const theme = useTheme();
  const modalFullScreen = allowFullScreen && useMediaQuery(theme.breakpoints.down('sm'));
  const { css: brandCss } = useShopConfig();

  return open ? (
    <Dialog
      fullScreen={modalFullScreen}
      open={open}
      onClose={onClose}
      aria-labelledby="responsive-dialog-title"
      BackdropProps={{ style: { backdropFilter: 'blur(1px)' } }}
      PaperProps={{ style: { backgroundColor: brandCss?.backgroundColor || 'white' } }}
      TransitionComponent={NoTransition}
      {...rest}
    >
      <MuiDialogTitle disableTypography className={headerClassName || classes.root}>
        {titleText && (
          // Right margin is to avoid overlapping the X button
          <h2 style={{ margin: 0, marginRight: '48px' }}>
            <TypographyWrapper variant="h2">{titleText}</TypographyWrapper>
          </h2>
        )}

        {onClose ? (
          <IconButton
            aria-label="close"
            className={closeButtonClassName || classes.closeButton}
            onClick={onClose}
            style={{ zIndex: 2 }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
      {children}
    </Dialog>
  ) : null;
});

DialogWithCloseButton.defaultProps = {
  allowFullScreen: true,
  headerClassName: null,
  closeButtonClassName: null,
};

DialogWithCloseButton.propTypes = {
  allowFullScreen: bool,
  headerClassName: string,
  closeButtonClassName: string,
};

export default DialogWithCloseButton;
