import {
  FilterId,
  GenericFilterOption,
  SearchFilterType,
  SearchNavBarSections,
} from '../../types/filters/filters';

interface GetBrandDirectFilterConfigParams {
  options?: GenericFilterOption[];
}

const defaultBrandDirectOptions = [
  {
    key: 'true',
    label: 'Brand Direct',
  },
  {
    key: 'false',
    label: 'Peer-to-Peer',
  },
];

export const getBrandDirectFilterConfig = (params?: GetBrandDirectFilterConfigParams) => {
  const { options = defaultBrandDirectOptions } = params || {};

  return {
    id: FilterId.IsBrandDirect,
    label: 'Seller',
    type: SearchFilterType.Checkbox,
    group: 'primary',
    queryParamNames: ['pub_isBrandDirect'],
    searchNavBarSection: SearchNavBarSections.Filter,
    config: {
      options,
    },
  };
};
