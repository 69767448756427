import { isEmpty, isNil } from 'lodash';
import { BundleType } from '@prisma/client';
import { BundleInfo } from '../types/models/bundle';
import {
  BundleStatus,
  CoreBundle,
  LineItemCode,
  Participant,
} from '../types/apollo/generated/types.generated';
import { ListingWithImages } from '../types/sharetribe/listing';
import { types as sdkTypes } from './sdkLoader';
import { getMarketplaceEntities } from '../ducks/marketplaceData.duck';
import {
  itemHasBeenCanceled,
  itemHasBeenDisputeResolved,
  itemHasBeenPaidOut,
  itemHasBeenVerified,
} from './bundleItem';

const { UUID } = sdkTypes;

export const getBundleStatusLabel = (participant: Participant, bundle: BundleInfo) => {
  const { status, type, bundleItems, isReturnable } = bundle;
  if (status === BundleStatus.Open) {
    return 'Not Yet Shipped';
  }

  if (status === BundleStatus.Shipped) {
    return 'Shipped';
  }

  if (status === BundleStatus.Delivered) {
    if (participant === Participant.Seller) {
      return 'Awaiting Verification';
    }

    return 'Delivered';
  }

  if (status === BundleStatus.Disputed) {
    if (isReturnable) {
      return 'Returned';
    }
    return 'Disputed';
  }

  if (bundleItems.length === 0) {
    return '';
  }

  if (bundleItems.every(itemHasBeenCanceled)) {
    return 'Canceled';
  }

  if (bundleItems.every(itemHasBeenDisputeResolved)) {
    return 'Returned';
  }

  if (participant === Participant.Seller) {
    if (bundleItems.some(itemHasBeenPaidOut)) {
      return 'Paid';
    }

    if (bundleItems.some(itemHasBeenVerified)) {
      return 'Not Yet Paid';
    }

    return 'Delivered';
  }

  if (participant === Participant.Buyer) {
    if (status === BundleStatus.Completed) {
      return 'Completed';
    }

    if (status === BundleStatus.Resolved) {
      // Bundle is trade-in type
      if (type === BundleType.TRADE_IN) {
        return 'Processing Credit';
      }

      // Bundle has partially been returned
      if (bundleItems.some(itemHasBeenDisputeResolved)) {
        return 'Return In Transit';
      }

      // Bundle is all verified.
      if (bundleItems.every(itemHasBeenVerified)) {
        return 'Verified';
      }
    }

    return 'Delivered';
  }

  return 'Unknown';
};

/**
 * Returns a truncated bundle ID used for displaying to the user.
 */
export const getBundleDisplayNumber = (bundleId: string) =>
  // Only show the first few digits
  bundleId.split('-')[0];

export const getBundleSharetribeListingIds = (bundle: CoreBundle): string[] => {
  const sharetribeListingIds = bundle.lineItems
    .filter((lineItem) => lineItem.code === LineItemCode.Listing)
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    .map((lineItem) => lineItem.listingLineItem!.sharetribeListingId);
  return sharetribeListingIds;
};

export const getBundleListings = (
  bundle: BundleInfo | undefined,
  rootState: any
): ListingWithImages[] => {
  let listings: ListingWithImages[] = [];
  if (!isEmpty(bundle?.listingIds)) {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const listingRefs =
      bundle?.listingIds!.map((listingId) => ({
        id: new UUID(listingId),
        type: 'listing',
      })) || [];
    listings = getMarketplaceEntities(rootState, listingRefs) as ListingWithImages[];
  }
  return listings;
};

export const isBundleTradeInPayoutFlatAmount = (
  bundle: BundleInfo,
  listings: ListingWithImages[]
): boolean => {
  if (bundle.type !== BundleType.TRADE_IN) {
    return false;
  }

  return (
    listings.length > 0 &&
    listings.every(
      (listing) =>
        !isNil(listing.attributes?.publicData?.payoutInfo?.tradeInCreditPayoutBundleAmount)
    )
  );
};
