import { getDefaultShopConfig } from './default';
import * as custom from '../filters/marketplace-custom-config';
import { getConditionsFilterConfig } from '../filters/condition';
import { getBrandDirectFilterConfig } from '../filters/brandDirect';
import { getSizeFilterConfig } from '../filters/size';

const kickeepantsSizes = [
  'XXS',
  'XS',
  'S',
  'M',
  'L',
  'XL',
  '2XL',
  '3XL',
  'OS',
  '0-3 M',
  '3-6 M',
  '6-12 M',
  '12-18 M',
  '18-24 M',
  '2T',
  '3T',
  '4T',
  '5 Y',
  '6 Y',
  '8 Y',
  '10 Y',
  '12 Y',
  '14 Y',
];

export const kickeepantsShopConfig = {
  ...getDefaultShopConfig(),
  filters: [
    ...custom.filters,
    getConditionsFilterConfig(),
    getBrandDirectFilterConfig(),
    getSizeFilterConfig({ sizes: kickeepantsSizes }),
  ],
};
