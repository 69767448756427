import { Accordion, AccordionProps } from '@material-ui/core';
import React, { FC, ReactElement, useEffect } from 'react';
import { useShopConfig } from '../../hooks/shopConfig';
import { defaultTreetStyles } from '../../shopConfig/config';
import { getPercentAlteredHexColor } from '../../util/colors/colors';

export interface StyledAccordionProps extends AccordionProps {
  children: ReactElement | ReactElement[];
  name?: string;
  isSelected?: boolean;
}

const StyledAccordion: FC<StyledAccordionProps> = (props) => {
  const { children, isSelected, name, onChange, ...rest } = props;
  const { css: brandCss } = useShopConfig();
  const formattedName = name
    ?.toLowerCase()
    .replace(/[^a-zA-Z0-9 -]/g, '')
    .replace(/\s/g, '-');

  const anchorTag = typeof window !== 'undefined' ? window.location?.hash : undefined;
  const shouldScrollOnLoad = anchorTag === `#${formattedName}`;
  const shouldExpandOnLoad = shouldScrollOnLoad || anchorTag === `#all`;

  useEffect(() => {
    if (formattedName && shouldScrollOnLoad) {
      const element = document.getElementById(formattedName);
      element?.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  }, []);

  const onSectionChange = (event: any, expanded: boolean) => {
    // Appends the section name (if present) to the URL for easy link sharing.
    if (expanded && formattedName && typeof window !== 'undefined') {
      window.history.replaceState(null, '', `#${formattedName}`);
    }

    // Calls original onChange event if one is passed to component.
    if (onChange) {
      onChange(event, expanded);
    }
  };

  const darkerBrandCssBackgroundColor =
    brandCss?.backgroundColor && getPercentAlteredHexColor(brandCss.backgroundColor, -5);
  const backgroundColor = isSelected
    ? darkerBrandCssBackgroundColor || defaultTreetStyles.gray20
    : brandCss?.backgroundColor;

  return (
    <Accordion
      style={{ backgroundColor }}
      {...rest}
      onChange={onSectionChange}
      {...(shouldExpandOnLoad && { defaultExpanded: true })}
      {...(formattedName && { id: formattedName })}
    >
      {children}
    </Accordion>
  );
};

export default StyledAccordion;
