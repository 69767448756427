import { Listing } from '../../types/sharetribe/listing';
import { ITEM_AVAILABILITY_AVAILABLE } from '../../util/constants';

export const MAX_NUM_CONSOLIDATED_LISTINGS_TO_SHOW = 4;

const compareVariant = (currentListing: Listing, compareListing: Listing, variant: string) =>
  currentListing.attributes.publicData[variant] === compareListing.attributes.publicData[variant];

const getNumberOfVariantsMatched = (
  currentListing: Listing,
  compareListing: Listing,
  variantsToCompare: string[]
) =>
  variantsToCompare
    .map((variant) => compareVariant(currentListing, compareListing, variant))
    .filter(Boolean).length;

export const sortListings =
  (currentListing: Listing, variantOptions: string[]) => (a: Listing, b: Listing) => {
    // Same as current listing
    if (a.id.uuid === currentListing.id.uuid) {
      return -1;
    }

    if (b.id.uuid === currentListing.id.uuid) {
      return 1;
    }
    // Compare variants
    const variantsToCompare = [...variantOptions, 'condition'];

    const totalVariants = variantsToCompare.length;
    const numAVariantsMatched = getNumberOfVariantsMatched(currentListing, a, variantsToCompare);
    const numBVariantsMatched = getNumberOfVariantsMatched(currentListing, b, variantsToCompare);

    const allAVariantsMatched = numAVariantsMatched === totalVariants;
    const allBVariantsMatched = numBVariantsMatched === totalVariants;

    // Exact variant and condition match
    if (allAVariantsMatched) {
      return -1;
    }

    if (allBVariantsMatched) {
      return 1;
    }

    // Rank by size if exists
    if (currentListing.attributes.publicData.size) {
      const currentSize = currentListing.attributes.publicData.size;
      const aSize = a.attributes.publicData.size;
      const bSize = b.attributes.publicData.size;

      if (aSize === currentSize) {
        return -1;
      }
      if (bSize === currentSize) {
        return 1;
      }
    }
    // Rank by how many other matching attributes they have with the listing
    return numAVariantsMatched - numBVariantsMatched;
  };

export const getNumConsolidatedListingsShown = (currConsolidatedListings: Listing[]) => {
  if (!currConsolidatedListings || currConsolidatedListings.length <= 1) {
    return 0;
  }
  return Math.min(currConsolidatedListings?.length, MAX_NUM_CONSOLIDATED_LISTINGS_TO_SHOW) - 1;
};

// This function determines whether consolidated listings are loaded
export const getAreConsolidatedListingsLoaded = (
  consolidatedListings: Listing[],
  currentListing: Listing
) => {
  const isAvailable =
    currentListing.attributes.publicData?.availability === ITEM_AVAILABILITY_AVAILABLE;

  if (consolidatedListings?.length > 1) {
    // If this is an available listing, we only re-fetch if these are outdated consolidated listings
    if (isAvailable) {
      return consolidatedListings
        .slice(0, MAX_NUM_CONSOLIDATED_LISTINGS_TO_SHOW)
        .some((consolidatedListing) => consolidatedListing?.id?.uuid === currentListing?.id?.uuid);
    }
    // If this is a sold listing, it won't be in the list, but we can assume the data is loaded because we force a re-fetch
    return true;
  }
  return false;
};
