import React, { FC, useState } from 'react';
import isEqual from 'lodash/isEqual';
import { ResponsiveImage } from '..';
import { useFeaturedListingImage } from '../../hooks/images';
import { ListingWithImages, OwnListingWithImages } from '../../types/sharetribe/listing';

interface ListingImageProps {
  className?: string;
  currentListing: ListingWithImages | OwnListingWithImages;
  renderSizes?: string[] | string;
  rootClassName?: string;
  title: string;
  variants?: string[];
}

const ListingImage: FC<ListingImageProps> = (props: ListingImageProps) => {
  const { className, currentListing, renderSizes, rootClassName, title, variants } = props;
  const featuredListingImage = useFeaturedListingImage(currentListing);
  const backupImage = useFeaturedListingImage(currentListing, true);

  const [imageProps, setImageProps] = useState(featuredListingImage);
  return (
    <>
      <ResponsiveImage
        className={className}
        rootClassName={rootClassName}
        variants={variants || ['default']}
        alt={title}
        sizes={renderSizes}
        onError={() => {
          if (!isEqual(backupImage, imageProps)) {
            setImageProps(backupImage);
          }
        }}
        {...imageProps}
      />
    </>
  );
};

export default ListingImage;
