// TODO (anniew|TREET-2191) Share Uploadcare code in server and src

export enum ImageSource {
  Uploadcare = 'UPLOADCARE',
}

export const isUploadcareImage = (src: string) => src.includes('ucarecdn.com/');

export const getUploadcareImagePreviewUrl = (cdnUrl?: string): string | null => {
  if (!cdnUrl) return null;
  if (!isUploadcareImage(cdnUrl)) return cdnUrl;

  let previewUrl = cdnUrl;
  if (!cdnUrl.includes('-/resize/500x/')) {
    previewUrl = previewUrl.concat('-/resize/500x/');
  }

  if (!cdnUrl.includes('-/format/auto/')) {
    previewUrl = previewUrl.concat('-/format/auto/');
  }

  return previewUrl;
};

export const getLargeUploadcareImagePreviewUrl = (cdnUrl?: string): string | null => {
  if (!cdnUrl) return null;
  if (!isUploadcareImage(cdnUrl)) return cdnUrl;

  let previewUrl = cdnUrl;
  if (!cdnUrl.includes('-/resize/2000x/')) {
    previewUrl = previewUrl.concat('-/resize/2000x/');
  }

  if (!cdnUrl.includes('-/quality/lightest/')) {
    previewUrl = previewUrl.concat('-/quality/lightest/');
  }

  if (!cdnUrl.includes('-/progressive/yes/')) {
    previewUrl = previewUrl.concat('-/progressive/yes/');
  }

  if (!cdnUrl.includes('-/format/auto/')) {
    previewUrl = previewUrl.concat('-/format/auto/');
  }

  return previewUrl;
};

// Return Uploadcare image in the original file format (png or jpg).
// Non-optimized. Only for use in sending URLs to partners,
// should not be used for rendering.
export const getUploadcarePreserveFormatUrl = (cdnUrl?: string): string | null => {
  if (!cdnUrl) return null;
  if (!isUploadcareImage(cdnUrl)) return cdnUrl;

  let previewUrl = cdnUrl;
  if (!cdnUrl.includes('-/format/preserve/')) {
    previewUrl = previewUrl.concat('-/format/preserve/');
  }

  return previewUrl;
};

export const getSmallUploadcarePreviewAsPng = (cdnUrl?: string): string | null => {
  if (!cdnUrl) return null;
  if (!isUploadcareImage(cdnUrl)) return cdnUrl;

  let previewUrl = cdnUrl;
  if (!cdnUrl.includes('-/resize/100x/')) {
    previewUrl = previewUrl.concat('-/resize/100x/');
  }

  if (!cdnUrl.includes('-/format/png/')) {
    previewUrl = previewUrl.concat('-/format/png/');
  }

  return previewUrl;
};
