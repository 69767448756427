import React, { FC } from 'react';
import { useIconColor } from '../../../hooks/useIconColor';

interface IconPawProps {
  className?: string;
  color?: string;
}

const IconPaw: FC<IconPawProps> = (props: IconPawProps) => {
  const { className, color } = props;

  const { iconColor } = useIconColor();
  const fillColor = color || iconColor;

  return (
    <svg
      className={className}
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.3549 17.6542C18.0886 14.9347 13.9116 14.9347 11.6452 17.6542L10.9993 18.4294C10.2379 19.3431 10.2529 20.6746 11.0347 21.5709L11.7398 22.3792C12.6117 23.3788 14.177 23.3411 14.9998 22.3008C15.5104 21.6553 16.4897 21.6553 17.0003 22.3008C17.8231 23.3411 19.3884 23.3788 20.2603 22.3792L20.9654 21.5709C21.7473 20.6746 21.7623 19.3431 21.0009 18.4294L20.3549 17.6542ZM10.1088 16.3739C13.1747 12.6948 18.8254 12.6948 21.8913 16.3739L22.5373 17.149C23.9277 18.8175 23.9003 21.2488 22.4726 22.8856L21.7675 23.6939C20.257 25.4256 17.6756 25.5555 16.0001 24.1312C14.3245 25.5555 11.7431 25.4256 10.2326 23.6939L9.5275 22.8856C8.09984 21.2488 8.07243 18.8175 9.46283 17.149L10.1088 16.3739Z"
        fill={fillColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.7996 10.448C18.6118 10.0054 18.5275 9.33718 18.7092 8.57437C18.891 7.81157 19.2676 7.25323 19.6348 6.94284C20.0027 6.63186 20.237 6.65023 20.3091 6.6674C20.3811 6.68457 20.5986 6.77385 20.7867 7.21733C20.9745 7.65995 21.0588 8.32814 20.877 9.09094C20.6953 9.85374 20.3186 10.4121 19.9515 10.7225C19.5836 11.0335 19.3492 11.0151 19.2772 10.9979C19.2051 10.9807 18.9877 10.8915 18.7996 10.448ZM18.8136 12.9434C17.1405 12.5448 16.2227 10.3811 16.7637 8.11078C17.3047 5.84045 19.0996 4.32319 20.7727 4.72188C22.4458 5.12056 23.3636 7.28422 22.8226 9.55454C22.2816 11.8249 20.4867 13.3421 18.8136 12.9434Z"
        fill={fillColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.0775 13.5819C24.0504 13.3139 24.1208 12.9684 24.3559 12.6412C24.5911 12.314 24.896 12.1371 25.1587 12.0774C25.4164 12.0188 25.5956 12.0744 25.7088 12.1558C25.8221 12.2372 25.932 12.3893 25.9586 12.6522C25.9858 12.9202 25.9153 13.2657 25.6802 13.5929C25.4451 13.9201 25.1401 14.097 24.8774 14.1567C24.6197 14.2153 24.4405 14.1597 24.3273 14.0783C24.2141 13.9969 24.1042 13.8448 24.0775 13.5819ZM23.1601 15.7024C21.8975 14.795 21.7057 12.9019 22.7318 11.4741C23.7579 10.0462 25.6133 9.6243 26.876 10.5317C28.1386 11.4391 28.3304 13.3322 27.3043 14.76C26.2782 16.1879 24.4228 16.6098 23.1601 15.7024Z"
        fill={fillColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.3627 10.448C13.5504 10.0054 13.6348 9.33718 13.453 8.57437C13.2712 7.81157 12.8946 7.25323 12.5274 6.94284C12.1595 6.63186 11.9252 6.65023 11.8531 6.6674C11.7811 6.68457 11.5636 6.77385 11.3755 7.21733C11.1878 7.65995 11.1034 8.32814 11.2852 9.09094C11.467 9.85374 11.8436 10.4121 12.2108 10.7225C12.5786 11.0335 12.813 11.0151 12.8851 10.9979C12.9571 10.9807 13.1746 10.8915 13.3627 10.448ZM13.3486 12.9434C15.0218 12.5448 15.9395 10.3811 15.3985 8.11078C14.8575 5.84045 13.0627 4.32319 11.3895 4.72188C9.71643 5.12056 8.79867 7.28422 9.33966 9.55454C9.88065 11.8249 11.6755 13.3421 13.3486 12.9434Z"
        fill={fillColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.08472 13.5819C8.11187 13.3139 8.04142 12.9684 7.80629 12.6412C7.57117 12.314 7.2662 12.1371 7.00355 12.0774C6.7458 12.0188 6.56662 12.0744 6.45339 12.1558C6.34016 12.2372 6.23026 12.3893 6.20362 12.6522C6.17647 12.9202 6.24692 13.2657 6.48204 13.5929C6.71717 13.9201 7.02213 14.097 7.28478 14.1567C7.54253 14.2153 7.72171 14.1597 7.83495 14.0783C7.94818 13.9969 8.05808 13.8448 8.08472 13.5819ZM9.00209 15.7024C10.2647 14.795 10.4565 12.9019 9.43042 11.4741C8.40431 10.0462 6.54891 9.6243 5.28625 10.5317C4.02359 11.4391 3.83182 13.3322 4.85792 14.76C5.88402 16.1879 7.73943 16.6098 9.00209 15.7024Z"
        fill={fillColor}
      />
    </svg>
  );
};

export default IconPaw;
