/* eslint-disable import/prefer-default-export */
import {
  FilterId,
  GenericFilterOption,
  SearchFilterType,
  SearchNavBarSections,
} from '../../types/filters/filters';
import { FilterQueryParam } from '../../types/search/search';

interface GetVintageFilterConfigInputs {
  options?: GenericFilterOption[];
}

export const getVintageFilterConfig = (params?: GetVintageFilterConfigInputs) => {
  const { options = [] } = params || {};
  return {
    id: FilterId.Vintage,
    label: 'Vintage',
    type: SearchFilterType.Checkbox,
    group: 'primary',
    queryParamNames: [FilterQueryParam.vintage],
    searchNavBarSection: SearchNavBarSections.Filter,
    config: { options },
  };
};
