import { getConditionsFilterConfig } from '../filters/condition';
import { getBrandDirectFilterConfig } from '../filters/brandDirect';
import { getSizeFilterConfig } from '../filters/size';
import { getDefaultShopConfig } from './default';
import * as custom from '../filters/marketplace-custom-config';

export const iksplorSizes = [
  'small',
  'medium',
  'large',
  '0-3 mo',
  '3-6 mo',
  '6-12 mo',
  '12-24 mo',
  '2T',
  '3T',
  '4T',
  '5T',
  '6-7 YR',
  '8-9 YR',
  '10 YR',
  '12 YR',
];

export const iksplorShopConfig = {
  ...getDefaultShopConfig(),
  filters: [
    ...custom.filters,
    getBrandDirectFilterConfig(),
    getConditionsFilterConfig(),
    getSizeFilterConfig({ sizes: iksplorSizes }),
  ],
};
