import React from 'react';
import { useIconColor } from '../../../hooks/useIconColor';

interface IconTreetLogoProps {
  className?: string;
  color?: string;
}

const IconTreetLogo: React.FC<IconTreetLogoProps> = (props) => {
  const { className, color } = props;

  const { iconColor } = useIconColor();
  const fillColor = color || iconColor;

  return (
    <svg
      className={className}
      width="1232"
      height="321"
      viewBox="0 0 1232 321"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M171.961 228.203C171.961 283.863 207.583 320.041 258.233 320.041C266.582 320.041 273.818 318.928 279.94 317.258C283.28 316.702 286.063 315.588 288.846 314.475V258.816L284.393 260.486C281.61 261.042 278.271 261.599 272.148 261.599C249.884 261.599 235.97 247.684 235.97 225.42V119.668H291.629V61.2253H235.97V0H180.31V36.1786C180.31 56.7725 170.292 66.7912 149.698 66.7912H133V119.668H171.961V228.203Z"
        fill={fillColor}
      />
      <path
        d="M344.353 317.258H408.361V180.893C408.361 147.497 430.068 125.233 461.238 125.233C467.917 125.233 473.483 126.347 477.379 126.903L483.501 128.016V61.2253L477.936 60.1121C474.039 59.5555 470.143 58.4423 464.021 58.4423C444.54 58.4423 429.512 65.678 418.937 74.0269C412.814 78.4797 407.248 83.489 402.795 89.0549L391.664 61.2253H344.353V317.258Z"
        fill={fillColor}
      />
      <path
        d="M686.767 239.335C683.984 243.231 680.088 246.571 675.635 249.354C667.286 254.363 656.711 258.816 639.456 258.816C606.061 258.816 587.136 239.335 581.014 214.288H759.124L760.237 205.94C760.794 200.93 761.907 195.364 761.907 189.242C761.907 111.319 706.247 58.4423 639.456 58.4423C570.439 58.4423 511.44 116.885 511.44 189.242C511.44 261.599 570.439 320.041 639.456 320.041C697.342 320.041 740.2 277.74 753.558 239.335H686.767ZM578.231 169.761C584.354 141.375 606.061 119.668 639.456 119.668C672.852 119.668 694.559 141.375 700.681 169.761H578.231Z"
        fill={fillColor}
      />
      <path
        d="M970.499 239.335C967.716 243.231 963.82 246.571 959.367 249.354C951.018 254.363 940.443 258.816 923.188 258.816C889.793 258.816 870.869 239.335 864.746 214.288H1042.86L1043.97 205.94C1044.53 200.93 1045.64 195.364 1045.64 189.242C1045.64 111.319 989.979 58.4423 923.188 58.4423C854.171 58.4423 795.172 116.885 795.172 189.242C795.172 261.599 854.171 320.041 923.188 320.041C981.074 320.041 1023.93 277.74 1037.29 239.335H970.499ZM861.963 169.761C868.086 141.375 889.793 119.668 923.188 119.668C956.584 119.668 978.291 141.375 984.414 169.761H861.963Z"
        fill={fillColor}
      />
      <path
        d="M1112.3 228.203C1112.3 283.863 1147.92 320.041 1198.57 320.041C1206.92 320.041 1214.16 318.928 1220.28 317.258C1223.62 316.702 1226.4 315.588 1229.18 314.475V258.816L1224.73 260.486C1221.95 261.042 1218.61 261.599 1212.49 261.599C1190.22 261.599 1176.31 247.684 1176.31 225.42V119.668H1231.97V61.2253H1176.31V0H1120.65V36.1786C1120.65 56.7725 1110.63 66.7912 1090.04 66.7912H1073.34V119.668H1112.3V228.203Z"
        fill={fillColor}
      />
      <path
        d="M129.168 158.784C73.6653 158.784 28.6718 203.778 28.6718 259.28L32.537 259.28C88.0394 259.28 133.033 214.286 133.033 158.784L129.168 158.784Z"
        fill={fillColor}
      />
      <path
        d="M93.6635 107.514C59.4769 89.9713 17.5422 103.464 -4.23126e-05 137.651L3.43888 139.416C37.6255 156.958 79.5602 143.465 97.1024 109.278L93.6635 107.514Z"
        fill={fillColor}
      />
      <path
        d="M96.5372 65.3181C97.1266 34.2737 72.4379 8.62938 41.3934 8.03998L41.3341 11.1628C40.7447 42.2073 65.4334 67.8516 96.4779 68.441L96.5372 65.3181Z"
        fill={fillColor}
      />
    </svg>
  );
};

export default IconTreetLogo;
