import { getDefaultShopConfig } from './default';
import * as custom from '../filters/marketplace-custom-config';
import { getBrandDirectFilterConfig } from '../filters/brandDirect';
import { getSizeFilterConfig } from '../filters/size';
import { getConditionsFilterConfig } from '../filters/condition';

const minimiocheSizes = [
  '1/2',
  '3/4',
  '5/6',
  '7/8',
  '9/10',
  '11/12',
  '0-3',
  '3-6',
  '6-12',
  '12-18',
  '18-24',
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  'XS',
  'S',
  'M',
  'L',
  '1X',
  '2X',
  '3X',
  'one size',
];

export const minimiocheShopConfig = {
  ...getDefaultShopConfig(),
  filters: [
    ...custom.filters,
    getBrandDirectFilterConfig(),
    getConditionsFilterConfig(),
    getSizeFilterConfig({ sizes: minimiocheSizes }),
  ],
};
