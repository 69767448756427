import { ApolloError } from '@apollo/client';
import { useContext } from 'react';
import AppContext from '../context/AppContext';
import {
  SavedSearchByEmailResponse,
  SavedSearchType,
  useSavedSearchByEmail,
} from '../types/apollo/generated/types.generated';
import * as log from '../util/log';

export const useFetchUserSizeSubscribeInfo = (
  email: string,
  skip = false
): {
  savedSearchData: SavedSearchByEmailResponse | undefined;
  savedSearchLoading: boolean;
  savedSearchError: ApolloError | undefined;
} => {
  const { treetId } = useContext(AppContext);
  const {
    data: savedSearchData,
    loading: savedSearchLoading,
    error: savedSearchError,
  } = useSavedSearchByEmail({
    variables: {
      email,
      treetId,
      type: SavedSearchType.General,
    },
    skip,
    onError: (error) => {
      log.error(error, 'fetch-saved-search-query-failed', { email, treetId });
    },
  });

  return {
    savedSearchData,
    savedSearchLoading,
    savedSearchError,
  };
};

export default useFetchUserSizeSubscribeInfo;
