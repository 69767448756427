import { BuilderSections } from '../../util/builder';
import { getDefaultShopConfig } from './default';
import * as custom from '../filters/marketplace-custom-config';
import { getBrandDirectFilterConfig } from '../filters/brandDirect';
import { getConditionsFilterConfig } from '../filters/condition';
import { getSizeFilterConfig } from '../filters/size';

export const simonsShopConfig = {
  ...getDefaultShopConfig(),
  filters: [
    ...custom.filters,
    getConditionsFilterConfig(),
    getBrandDirectFilterConfig(),
    getSizeFilterConfig({
      sizes: ['X-SMALL', 'SMALL', 'MEDIUM', 'LARGE', '0', '2', '4', '6', '8', '10'],
    }),
  ],
  // TODO: Move to contentful if we do decide to proceed with builder io
  builderConfig: {
    sections: {
      [BuilderSections.LandingPageContent]: 'e0ed0a40ea214c9daab1eeb775301a20',
    },
  },
};
