/**
 * This is a wrapper component for different Layouts. Main content should be added to this wrapper.
 */
import { Box } from '@material-ui/core';
import classNames from 'classnames';
import React, { FC, ReactNode } from 'react';
import { useShopConfig } from '../../hooks/shopConfig';

import css from './LayoutWrapperMain.module.css';

interface LayoutWrapperMainProps {
  children: ReactNode;
  className?: string;
  rootClassName?: string;
  bgcolor?: string;
  id?: string;
}

const LayoutWrapperMain: FC<LayoutWrapperMainProps> = (props: LayoutWrapperMainProps) => {
  const { className, rootClassName, children, bgcolor, id } = props;
  const { css: brandCss } = useShopConfig();
  const classes = classNames(rootClassName || css.root, className);

  return (
    <Box className={classes} bgcolor={bgcolor || brandCss?.backgroundColor} role="main" id={id}>
      {children}
    </Box>
  );
};

export default LayoutWrapperMain;
