import { getBrandDirectFilterConfig } from '../filters/brandDirect';
import { getSizeFilterConfig } from '../filters/size';
import { Conditions, getConditionsFilterConfig } from '../filters/condition';
import { getDefaultShopConfig, getDefaultTradeInShopConfig } from './default';
import * as custom from '../filters/marketplace-custom-config';

export const burjushoesSizes = [
  '5',
  '5.5',
  '6',
  '6.5',
  '7',
  '7.5',
  '8',
  '8.5',
  '9',
  '9.5',
  '10',
  '10.5',
  '11',
  '12',
  '13',
  '14',
  '15',
];

export const burjushoesConditionOptions = [
  {
    // We are keeping the same as clothes, because we have logic that says if its NWT, then X & Y
    key: Conditions.NewWithTags,
    label: 'New With Shoe Bag',
    description:
      'Your shoes have never been worn and they’re still in the original bag. For this option, you’ll need to take pictures of your shoes in the bag.',
  },
  {
    key: Conditions.NewWithoutTags,
    label: 'New Without Shoe Bag',
    description:
      'Your shoes have never been worn (other than perhaps trying it on), but you no longer have the bag. There are no quirks or issues whatsoever.',
  },
  {
    key: Conditions.Excellent,
    label: 'Excellent Condition',
    description:
      'Your shoes have only been lightly used and have been extremely well maintained. There may be some small signs of wear, but nothing major.',
  },
  {
    key: Conditions.Good,
    label: 'Good Condition',
    description:
      'You’ve worn the shoes and they’re well maintained. There may be some quirks like a small stain or a scuff mark, but they’re overall in good shape. You will need to take pictures of any quirks your shoes have.',
  },
];

export const burjushoesShopConfig = {
  ...getDefaultShopConfig(),
  ...getDefaultTradeInShopConfig(),
  filters: [
    ...custom.filters,
    getConditionsFilterConfig({ options: burjushoesConditionOptions }),
    getBrandDirectFilterConfig(),
    getSizeFilterConfig({ sizes: burjushoesSizes }),
  ],
};
