// Get attribute: stripeAccountData
export const getStripeAccountData = (stripeAccount) => {
  if (stripeAccount) {
    return stripeAccount.attributes.stripeAccountData || null;
  }
  return null;
};

// Get last 4 digits of bank account returned in Stripe account
export const getBankAccountLast4Digits = (stripeAccountData) =>
  stripeAccountData && stripeAccountData.external_accounts.data.length > 0
    ? stripeAccountData.external_accounts.data[0].last4
    : null;

// Check if there's requirements on selected type: 'past_due', 'currently_due' etc.
export const hasRequirements = (stripeAccountData, requirementType) =>
  stripeAccountData != null &&
  stripeAccountData.requirements &&
  Array.isArray(stripeAccountData.requirements[requirementType]) &&
  stripeAccountData.requirements[requirementType].length > 0;

const hasFutureRequirements = (stripeAccountData, requirementType) =>
  stripeAccountData != null &&
  stripeAccountData.future_requirements &&
  Array.isArray(stripeAccountData.future_requirements[requirementType]) &&
  stripeAccountData.future_requirements[requirementType].length > 0;

// Checks to see if there are any requirements that are still missing and causing the
// account to be in an unverified state
export const hasRequirementsMissing = (stripeAccount) => {
  const stripeAccountData = getStripeAccountData(stripeAccount);
  return (
    stripeAccountData &&
    (hasRequirements(stripeAccountData, 'past_due') ||
      hasRequirements(stripeAccountData, 'currently_due'))
  );
};

// Checks to see if there are any impending requirements that are missing
export const hasFutureRequirementsMissing = (stripeAccount) => {
  const stripeAccountData = getStripeAccountData(stripeAccount);
  return (
    stripeAccountData &&
    (hasFutureRequirements(stripeAccountData, 'past_due') ||
      hasFutureRequirements(stripeAccountData, 'currently_due') ||
      hasRequirements(stripeAccountData, 'eventually_due'))
  );
};

export const formatToSharetribeStripeAccount = (stripeAccount) => ({
  id: stripeAccount.id,
  type: 'stripeAccount',
  attributes: {
    stripeAccountId: stripeAccount.id,
    stripeAccountData: stripeAccount,
  },
});
