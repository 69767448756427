import { useContext } from 'react';
import { buildShopConfig } from '../shopConfig/configV2';
import AppContext from '../context/AppContext';
import { ShopConfigV2 } from '../types/shopConfig/shopConfigV2';
import { getShopConfigHelper } from '../shopConfig/configHelper';
import { isDev } from '../util/envHelpers';

/**
 * This hook gives us access to the config (in V1 format).
 */
export function useShopConfig(shouldSanitize = true) {
  // shopConfigV2 should always exist except for treet.shop case
  const { treetId, shopConfig: shopConfigV2 } = useContext(AppContext);

  const shopConfig = getShopConfigHelper(treetId, shopConfigV2);

  // Attach shopconfig to window.app for easy inspection on dev
  if (isDev && typeof window !== 'undefined') {
    (window as any).app = {
      ...(window as any).app,
      shopConfig,
    };
  }

  if (!shouldSanitize) {
    // Return full shopConfig fields if specified
    return shopConfig;
  }

  // Otherwise remove some shopConfig fields that should not be accessible directly through this `useShopConfig` hook
  const sanitizedShopConfig = {
    ...shopConfig,
    tradeInConfig: undefined, // This makes sure we will call useTradeInConfig to get trade in config
  };

  return sanitizedShopConfig;
}

/**
 * This hook gives us access to the config (in V2 format).
 */
export const useShopConfigV2 = (): ShopConfigV2 => {
  // shopConfig should always exist except for treet.shop case
  const { shopConfig: contentfulShopConfig } = useContext(AppContext);

  return buildShopConfig(contentfulShopConfig);
};
