import browserCookieStore from './browserCookieStore';
import memoryStore from './memoryStore';

/* eslint-disable import/prefer-default-export */
const hasBrowserCookies = () => typeof document === 'object' && typeof document.cookie === 'string';

interface createCustomTokenStoreParams {
  clientId: string;
  secure: boolean;
}

export const createCustomTokenStore = (params: createCustomTokenStoreParams) => {
  if (hasBrowserCookies()) {
    return browserCookieStore(params);
  }

  // If we can't use browser cookie store, default to in-memory store.
  return memoryStore();
};
