import { Dispatch } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { getOpenListings } from '../util/ducks/getOpenListings';
import { Listing, ListingItemType } from '../types/sharetribe/listing';
import { addMarketplaceEntities } from './marketplaceData.duck';
import { RequestStatus } from '../types/requestStatus';
import { getShopConfig } from '../shopConfig/configHelper';
import * as log from '../util/log';
import { denormalisedResponseEntities } from '../util/data';
import { apolloClient } from '../apollo';
import { ShopByTreetIdDocument } from '../types/apollo/generated/types.generated';

type ListingsCountAndShopInfo = { count: number; shopName: string; shopUrl: string };
type TreetIdToListingsCountAndShopInfo = { [treetId: string]: ListingsCountAndShopInfo };

// ================ Action types ================ //

const FETCH_SHOPPING_BAG_LISTINGS_REQUEST = 'app/shoppingBag/FETCH_SHOPPING_BAG_LISTINGS_REQUEST';
const FETCH_SHOPPING_BAG_LISTINGS_SUCCESS = 'app/shoppingBag/FETCH_SHOPPING_BAG_LISTINGS_SUCCESS';
const FETCH_SHOPPING_BAG_LISTINGS_ERROR = 'app/shoppingBag/FETCH_SHOPPING_BAG_LISTINGS_ERROR';

const FETCH_OTHER_SHOPS_LISTINGS_COUNT_AND_SHOP_INFO_SUCCESS =
  'app/shoppingBag/FETCH_OTHER_SHOPS_LISTINGS_COUNT_AND_SHOP_INFO_SUCCESS';

const FETCH_BUNDLE_AUTHOR_LISTINGS_REQUEST = 'app/shoppingBag/FETCH_BUNDLE_AUTHOR_LISTINGS_REQUEST';
const FETCH_BUNDLE_AUTHOR_LISTINGS_SUCCESS = 'app/shoppingBag/FETCH_BUNDLE_AUTHOR_LISTINGS_SUCCESS';
const FETCH_BUNDLE_AUTHOR_LISTINGS_ERROR = 'app/shoppingBag/FETCH_BUNDLE_AUTHOR_LISTINGS_ERROR';

const ADD_SHOPPING_BAG_LISTING = 'app/shoppingBag/ADD_SHOPPING_BAG_LISTING';
const REMOVE_SHOPPING_BAG_LISTINGS = 'app/shoppingBag/REMOVE_SHOPPING_BAG_LISTINGS';

interface FetchShoppingBagListingsRequest {
  type: typeof FETCH_SHOPPING_BAG_LISTINGS_REQUEST;
}

interface FetchShoppingBagListingsSuccess {
  type: typeof FETCH_SHOPPING_BAG_LISTINGS_SUCCESS;
  listingIds: string[];
}

interface FetchOtherShopsListingsCountAndShopInfoSuccess {
  type: typeof FETCH_OTHER_SHOPS_LISTINGS_COUNT_AND_SHOP_INFO_SUCCESS;
  otherShopsListingsCountAndShopInfo: TreetIdToListingsCountAndShopInfo;
}

interface FetchShoppingBagListingsError {
  type: typeof FETCH_SHOPPING_BAG_LISTINGS_ERROR;
}

interface FetchBundleAuthorListingsRequest {
  type: typeof FETCH_BUNDLE_AUTHOR_LISTINGS_REQUEST;
}

interface FetchBundleAuthorListingsSuccess {
  type: typeof FETCH_BUNDLE_AUTHOR_LISTINGS_SUCCESS;
  authorId: string;
  listingIds: string[];
}

interface FetchBundleAuthorListingsError {
  type: typeof FETCH_BUNDLE_AUTHOR_LISTINGS_ERROR;
}

interface AddShoppingBagListing {
  type: typeof ADD_SHOPPING_BAG_LISTING;
  listingId: string;
}

interface RemoveShoppingBagListings {
  type: typeof REMOVE_SHOPPING_BAG_LISTINGS;
  listingIds: string[];
}

type ShoppingBagActionType =
  | FetchShoppingBagListingsRequest
  | FetchShoppingBagListingsSuccess
  | FetchOtherShopsListingsCountAndShopInfoSuccess
  | FetchShoppingBagListingsError
  | FetchBundleAuthorListingsRequest
  | FetchBundleAuthorListingsSuccess
  | FetchBundleAuthorListingsError
  | AddShoppingBagListing
  | RemoveShoppingBagListings;

// ================ Reducer ================ //

export interface ShoppingBagState {
  fetchShoppingBagListingsStatus: RequestStatus;
  fetchBundleAuthorListingsStatus: RequestStatus;
  // Includes only listing ids for open listings on the current shop
  shoppingBagResultIds: string[];
  otherShopsListingsCountAndShopInfo: TreetIdToListingsCountAndShopInfo;
  bundleAuthorToListingIds: { [authorId: string]: string[] };
}

const initialState: ShoppingBagState = {
  fetchShoppingBagListingsStatus: RequestStatus.Ready,
  fetchBundleAuthorListingsStatus: RequestStatus.Ready,
  shoppingBagResultIds: [],
  otherShopsListingsCountAndShopInfo: {},
  bundleAuthorToListingIds: {},
};

export default function reducer(
  state: ShoppingBagState = initialState,
  action: ShoppingBagActionType
): ShoppingBagState {
  switch (action.type) {
    case FETCH_SHOPPING_BAG_LISTINGS_REQUEST: {
      return {
        ...state,
        fetchShoppingBagListingsStatus: RequestStatus.Pending,
      };
    }
    case FETCH_SHOPPING_BAG_LISTINGS_SUCCESS: {
      return {
        ...state,
        fetchShoppingBagListingsStatus: RequestStatus.Success,
        shoppingBagResultIds: action.listingIds,
      };
    }

    case FETCH_OTHER_SHOPS_LISTINGS_COUNT_AND_SHOP_INFO_SUCCESS: {
      return {
        ...state,
        otherShopsListingsCountAndShopInfo: action.otherShopsListingsCountAndShopInfo,
      };
    }
    case FETCH_SHOPPING_BAG_LISTINGS_ERROR: {
      return {
        ...state,
        fetchShoppingBagListingsStatus: RequestStatus.Error,
      };
    }
    case FETCH_BUNDLE_AUTHOR_LISTINGS_REQUEST: {
      return {
        ...state,
        fetchBundleAuthorListingsStatus: RequestStatus.Pending,
      };
    }
    case FETCH_BUNDLE_AUTHOR_LISTINGS_SUCCESS: {
      return {
        ...state,
        fetchBundleAuthorListingsStatus: RequestStatus.Success,
        bundleAuthorToListingIds: {
          ...state.bundleAuthorToListingIds,
          [action.authorId]: action.listingIds,
        },
      };
    }
    case FETCH_BUNDLE_AUTHOR_LISTINGS_ERROR: {
      return {
        ...state,
        fetchBundleAuthorListingsStatus: RequestStatus.Error,
      };
    }
    case ADD_SHOPPING_BAG_LISTING: {
      return {
        ...state,
        shoppingBagResultIds: [...state.shoppingBagResultIds, action.listingId],
      };
    }
    case REMOVE_SHOPPING_BAG_LISTINGS: {
      return {
        ...state,
        shoppingBagResultIds: state.shoppingBagResultIds.filter(
          (id) => !action.listingIds.includes(id)
        ),
      };
    }
    default:
      return state;
  }
}

// ================ Action creators ================ //

export const fetchShoppingBagListingsRequest = (): FetchShoppingBagListingsRequest => ({
  type: FETCH_SHOPPING_BAG_LISTINGS_REQUEST,
});

export const fetchShoppingBagListingsSuccess = (
  listingIds: string[]
): FetchShoppingBagListingsSuccess => ({
  type: FETCH_SHOPPING_BAG_LISTINGS_SUCCESS,
  listingIds,
});

export const fetchOtherShopsListingsCountAndShopInfoSuccess = (
  otherShopsListingsCountAndShopInfo: TreetIdToListingsCountAndShopInfo
): FetchOtherShopsListingsCountAndShopInfoSuccess => ({
  type: FETCH_OTHER_SHOPS_LISTINGS_COUNT_AND_SHOP_INFO_SUCCESS,
  otherShopsListingsCountAndShopInfo,
});

export const fetchShoppingBagListingsError = (): FetchShoppingBagListingsError => ({
  type: FETCH_SHOPPING_BAG_LISTINGS_ERROR,
});

export const fetchBundleAuthorListingsRequest = (): FetchBundleAuthorListingsRequest => ({
  type: FETCH_BUNDLE_AUTHOR_LISTINGS_REQUEST,
});

export const fetchBundleAuthorListingsSuccess = (
  authorId: string,
  listingIds: string[]
): FetchBundleAuthorListingsSuccess => ({
  type: FETCH_BUNDLE_AUTHOR_LISTINGS_SUCCESS,
  authorId,
  listingIds,
});

export const fetchBundleAuthorListingsError = (): FetchBundleAuthorListingsError => ({
  type: FETCH_BUNDLE_AUTHOR_LISTINGS_ERROR,
});

export const addShoppingBagListing = (listingId: string): AddShoppingBagListing => ({
  type: ADD_SHOPPING_BAG_LISTING,
  listingId,
});

export const removeShoppingBagListings = (listingIds: string[]): RemoveShoppingBagListings => ({
  type: REMOVE_SHOPPING_BAG_LISTINGS,
  listingIds,
});

// ================ Thunks ================ //

const getOtherShop = async (treetId: string) => {
  const queryShopByTreetIdResponse = await apolloClient.query({
    query: ShopByTreetIdDocument,
    variables: { treetId },
  });

  const shop = queryShopByTreetIdResponse.data?.shop;
  if (!shop) throw new Error(`Cannot find shop for treetId ${treetId}`);

  return shop;
};

export const fetchShoppingBagListings =
  () =>
  async (dispatch: ThunkDispatch<any, any, any>, getState: () => any, sdk: any): Promise<void> => {
    const state = getState();
    dispatch(fetchShoppingBagListingsRequest());

    try {
      const { treetId, shopConfig: shopConfigV2 } = getState().initial;
      const { shopName } = getShopConfig(treetId, shopConfigV2);

      const cartListingIds = state.user.cartListingIds || [];

      const openShoppingBagListingsResponse = await getOpenListings(sdk, {
        shopName: undefined,
        listingIds: cartListingIds,
        subdomain: treetId,
      });
      const openShoppingBagListings = denormalisedResponseEntities(openShoppingBagListingsResponse);

      const otherShopsListingsCountAndShopInfo = await openShoppingBagListings.reduce(
        async (acc, listing) => {
          const { domain: listingTreetId, shopName: listingShopName } =
            listing.attributes.publicData;
          if (listingTreetId === treetId) return acc;

          const { canonicalRootUrl } = await getOtherShop(listingTreetId);
          if (acc[listingTreetId]) {
            acc[listingTreetId].count += 1;
          } else {
            acc[listingTreetId] = {
              count: 1,
              shopName: listingShopName,
              shopUrl: canonicalRootUrl,
            };
          }
          return acc;
        },
        {} as { [key: string]: ListingsCountAndShopInfo }
      );

      dispatch(fetchOtherShopsListingsCountAndShopInfoSuccess(otherShopsListingsCountAndShopInfo));

      const openShoppingBagListingIdsForShop = openShoppingBagListings
        .filter((listing) => listing.attributes.publicData.shopName === shopName)
        .map((listing) => listing.id.uuid);
      dispatch(addMarketplaceEntities(openShoppingBagListingsResponse));
      dispatch(fetchShoppingBagListingsSuccess(openShoppingBagListingIdsForShop));
    } catch (e) {
      dispatch(fetchShoppingBagListingsError());
      log.error(e, 'fetch-shopping-bag-failed', {});
    }
  };

export const fetchBundleAuthorListings =
  (authorId: string) =>
  async (dispatch: Dispatch, getState: () => any, sdk: any): Promise<void> => {
    const state = getState();
    const { treetId, shopConfig: shopConfigV2 } = state.initial;
    const { shopName } = getShopConfig(treetId, shopConfigV2);

    dispatch(fetchBundleAuthorListingsRequest());

    try {
      const response = await sdk.listings.query({
        author_id: authorId,
        pub_shopName: shopName,
        pub_listingItemType: ListingItemType.Marketplace,
      });
      const listingIds = denormalisedResponseEntities(response).map(
        (listing: Listing) => listing.id.uuid
      );
      dispatch(fetchBundleAuthorListingsSuccess(authorId, listingIds));
    } catch (e) {
      dispatch(fetchBundleAuthorListingsError());
      log.error(e, 'fetch-bundle-author-listings-failed', { authorId });
    }
  };
