import { getDefaultShopConfig } from './default';
import * as custom from '../filters/marketplace-custom-config';
import { getConditionsFilterConfig } from '../filters/condition';
import { getBrandDirectFilterConfig } from '../filters/brandDirect';
import { getSizeFilterConfig } from '../filters/size';
import { BuilderSections } from '../../util/builder';

const huxbabySizes = [
  '0-3M',
  '3-6M',
  '6-12M',
  '12-18M',
  '18-24M',
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  'XS',
  'S',
  'M',
  'L',
  'XL',
  'One Size',
];

export const huxbabyShopConfig = {
  ...getDefaultShopConfig(),
  filters: [
    ...custom.filters,
    getConditionsFilterConfig(),
    getBrandDirectFilterConfig(),
    getSizeFilterConfig({ sizes: huxbabySizes }),
  ],
  builderConfig: {
    sections: {
      [BuilderSections.LandingPageContent]: '2573701e673a4f238eb76568ce1481ad',
    },
  },
};
