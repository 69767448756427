import { createTheme, Theme } from '@material-ui/core';
import {
  IShopConfig,
  IShopCss,
  IShopFont,
  ITreetShopConfig,
} from './types/contentful/types.generated';
import { getShopConfig } from './shopConfig/configHelper';
import { defaultTreetStyles } from './shopConfig/config';

const getFontConfig = (theme: Theme, config?: IShopFont) => {
  if (!config) return {};

  return {
    fontSize: config.fontSize,
    color: config.color,
    letterSpacing: config.letterSpacing || 'normal',
    lineHeight: config.lineHeight || 'normal',
    textTransform: config.textTransform,
    fontWeight: config.fontWeight,
    // TODO (sonia-y | TREET-1281): Remove the !important once we no longer use the fonts configured from config.js
    fontFamily: `${
      config.fontFamily || ['sofiapro', '"Helvetica"', 'Arial', 'sans-serif'].join(',')
    } !important`,
    [theme.breakpoints.down('sm')]: {
      fontSize: config.fontSizeSmall || config.fontSize,
    },
    ...config.style,
  };
};

const getTheme = (styles: any, cssConfig?: IShopCss) => {
  const theme = createTheme({
    // Matched with marketplace media queries
    breakpoints: {
      values: {
        xs: 0,
        sm: 550,
        md: 768,
        lg: 1024,
        xl: 1921,
      },
    },
    typography: {
      button: {
        textTransform: 'none' as any,
      },
    },
    palette: {
      primary: {
        main: defaultTreetStyles.teal100,
      },
      secondary: {
        main: defaultTreetStyles.teal10,
      },
    },
    overrides: {
      MuiCard: {
        root: {
          color: '#4a4a4a',
          borderRadius: styles.borderRadiusHalf,
        },
      },
      MuiOutlinedInput: {
        root: {
          borderRadius: styles.borderRadiusHalf,
        },
      },
      MuiChip: {
        root: {
          borderRadius: styles.borderRadiusHalf,
        },
      },
      MuiSwitch: {
        root: {
          alignItems: 'center',
          width: '65px',
        },
        switchBase: {
          top: 'unset',
          left: '15px',
          padding: 'unset',
        },
        track: {
          height: '20px',
          borderRadius: '20px',
          opacity: '0.1',
        },
        thumb: {
          height: '14px',
          width: '14px',
        },
      },
    },
    props: {
      MuiTypography: {
        variantMapping: {
          subtitle1: 'p', // Change subtitle1 to use <p> instead of <h6>
          h6: 'h1',
          h1: 'h2',
          h2: 'h3',
          h5: 'h4',
        },
      },
    },
  });

  // In Treet V2, we will store all CSS fields in Contentful
  if (cssConfig) {
    const { h1, h2, h5, h6, body1, body2, subtitle1 } = cssConfig;
    theme.typography.h1 = getFontConfig(theme, h1);
    theme.typography.h2 = getFontConfig(theme, h2);
    theme.typography.h5 = getFontConfig(theme, h5);
    theme.typography.h6 = getFontConfig(theme, h6);
    theme.typography.body1 = getFontConfig(theme, body1);
    theme.typography.body2 = getFontConfig(theme, body2);
    theme.typography.subtitle1 = getFontConfig(theme, subtitle1);
  }

  return theme;
};

const getMuiThemeForShop = (treetId: string, contentfulShopConfig?: IShopConfig) => {
  const { styles } = getShopConfig(treetId, contentfulShopConfig);
  return getTheme(styles, contentfulShopConfig?.css);
};

export const getMuiThemeForTreetShop = (cssConfig?: IShopCss) =>
  getTheme({ marketplaceColor: defaultTreetStyles.teal80 }, cssConfig);

export const getMuiTheme = (
  treetId: string,
  shopConfig?: IShopConfig,
  treetShopConfig?: ITreetShopConfig
) =>
  treetId === 'treet'
    ? getMuiThemeForTreetShop(treetShopConfig?.styles)
    : getMuiThemeForShop(treetId, shopConfig);
