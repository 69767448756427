import { getDefaultShopConfig } from './default';
import * as custom from '../filters/marketplace-custom-config';
import { getBrandDirectFilterConfig } from '../filters/brandDirect';
import { Conditions, getConditionsFilterConfig } from '../filters/condition';
import { getSizeFilterConfig } from '../filters/size';

const wayreSizes = [
  'XXS',
  'XS',
  'S',
  'M',
  'L',
  'XL',
  'XXL',
  'XXXL',
  '00',
  '0',
  '2',
  '4',
  '6',
  '8',
  '10',
  '12',
  '14',
  '16',
  '18',
  '20',
  '22',
  '24',
];

export const wayreConditionOptions = [
  {
    key: Conditions.NewWithTags,
    // Wayre does not have tags. See TREET-2936.
    label: 'New Without Tags',
    description:
      'Your item has never been worn or washed. There are no quirks or issues whatsoever.',
  },
  {
    key: Conditions.NewWithoutTags,
    label: 'Like New',
    description:
      'Your item has never been worn, but okay if it’s been washed. There are no quirks or issues whatsoever.',
  },
  {
    key: Conditions.Excellent,
    label: 'Excellent Condition',
    description:
      'Your item has only been lightly used and has been extremely well maintained. There may be some small signs of wear, but nothing major.',
  },
  {
    key: Conditions.Good,
    label: 'Good Condition',
    description:
      'You’ve used this item and it’s well maintained. There may be some quirks like a small stain, or a loose thread, but it’s overall in good shape. You will need to take pictures of any quirks the item has.',
  },
];

export const wayreShopConfig = {
  ...getDefaultShopConfig(),
  filters: [
    ...custom.filters,
    getConditionsFilterConfig({ options: wayreConditionOptions }),
    getBrandDirectFilterConfig(),
    getSizeFilterConfig({ sizes: wayreSizes }),
  ],
};
