import { getDefaultShopConfig } from './default';
import * as custom from '../filters/marketplace-custom-config';
import { getBrandDirectFilterConfig } from '../filters/brandDirect';
import { getConditionsFilterConfig } from '../filters/condition';
import { getSizeFilterConfigFromOptions } from '../filters/size';

const viscataSizeOptions = [
  { key: '5 US (35EU)', label: '5 US (35EU)' },
  { key: '5.5-6 US (36EU)', label: '5.5-6 US (36EU)' },
  { key: '6.5 US (37EU)', label: '6.5 US (37EU)' },
  { key: '7 US (38EU)', label: '7 US (38EU)' },
  { key: '8 US (39EU)', label: '8 US (39EU)' },
  { key: '9 US (40EU)', label: '9 US (40EU)' },
  { key: '10 US (41EU)', label: '10 US (41EU)' },
  { key: '11 US (42EU)', label: '11 US (42EU)' },
];

export const viscataShopConfig = {
  ...getDefaultShopConfig(),
  filters: [
    ...custom.filters,
    getBrandDirectFilterConfig(),
    getSizeFilterConfigFromOptions({ options: viscataSizeOptions }),
    getConditionsFilterConfig(),
  ],
};
