import { defaultClothesConditionsOptions, getConditionsFilterConfig } from '../filters/condition';
import { getDefaultShopConfig } from './default';
import * as custom from '../filters/marketplace-custom-config';

export const relovedShopConfig = {
  ...getDefaultShopConfig(),
  filters: [
    ...custom.filters,
    getConditionsFilterConfig({ options: defaultClothesConditionsOptions }),
  ],
};
