import { getDefaultShopConfig } from './default';
import * as custom from '../filters/marketplace-custom-config';
import { getBrandDirectFilterConfig } from '../filters/brandDirect';
import { getSizeFilterConfigFromOptions } from '../filters/size';
import { getConditionsFilterConfig } from '../filters/condition';

const ziabirdSizes = [
  { key: 'One Size', label: 'One Size' },
  { key: 'XS', label: 'XS' },
  { key: 'S', label: 'S' },
  { key: 'M', label: 'M' },
  { key: 'L', label: 'L' },
  { key: 'XL', label: 'XL' },
  { key: 'XS/S', label: 'XS/S' },
  { key: 'S/M', label: 'S/M' },
  { key: 'M/L', label: 'M/L' },
  { key: 'L/XL', label: 'L/XL' },
  { key: '0', label: '0' },
  { key: '1', label: '1' },
  { key: '2', label: '2' },
  { key: '3', label: '3' },
  { key: '4', label: '4' },
  { key: '5', label: '5' },
  { key: '6', label: '6' },
  { key: '36', label: '36' },
  { key: '38', label: '38' },
  { key: '40', label: '40' },
  { key: '42', label: '42' },
  { key: '44', label: '44' },
  { key: '16"', label: 'Jewelry - 16"' },
  { key: '18"', label: 'Jewelry - 18"' },
  { key: '20"', label: 'Jewelry - 20"' },
  { key: '22"', label: 'Jewelry - 22"' },
  { key: '24"', label: 'Jewelry - 24"' },
  { key: '30"', label: 'Jewelry - 30"' },
  { key: '32"', label: 'Jewelry - 32"' },
  { key: '36"', label: 'Jewelry - 36"' },
];

export const ziabirdShopConfig = {
  ...getDefaultShopConfig(),
  filters: [
    ...custom.filters,
    getBrandDirectFilterConfig(),
    getConditionsFilterConfig(),
    getSizeFilterConfigFromOptions({ options: ziabirdSizes }),
  ],
};
