import { getBrandDirectFilterConfig } from '../filters/brandDirect';
import { getConditionsFilterConfig } from '../filters/condition';
import { getSizeFilterConfig } from '../filters/size';
import { getDefaultShopConfig } from './default';
import * as custom from '../filters/marketplace-custom-config';

const freedomravewearSizes = [
  'XS',
  'S',
  'M',
  'L',
  'XL',
  'XXL',
  '1X',
  '2X',
  'Regular',
  'Plus',
  'OS',
  '5',
  '6',
  '7',
  '8',
  '9',
  '10',
  '11',
  '12',
];

export const freedomravewearShopConfig = {
  ...getDefaultShopConfig(),
  filters: [
    ...custom.filters,
    getConditionsFilterConfig(),
    getSizeFilterConfig({
      sizes: freedomravewearSizes,
    }),
    getBrandDirectFilterConfig(),
  ],
};
