import { getDefaultShopConfig } from './default';
import * as custom from '../filters/marketplace-custom-config';
import { getBrandDirectFilterConfig } from '../filters/brandDirect';
import { getConditionsFilterConfig } from '../filters/condition';
import { getSizeFilterConfig } from '../filters/size';

const costumeboxSizes = [
  'Small',
  'Medium',
  'Large',
  'Extra Large',
  'Standard',
  'One Size',
  'XS',
  'S/M',
  'M/L',
  'L/XL',
];

export const costumeboxShopConfig = {
  ...getDefaultShopConfig(),
  filters: [
    ...custom.filters,
    getConditionsFilterConfig(),
    getBrandDirectFilterConfig(),
    getSizeFilterConfig({ sizes: costumeboxSizes }),
  ],
};
