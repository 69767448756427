import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './PrivacyPolicy.module.css';

const PrivacyPolicy = (props) => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);

  // prettier-ignore
  return (
    <div className={classes}>
      <p className={css.lastUpdated}>Last updated: December 31, 2023</p>
      <p>
        Thank you for choosing to be part of our community at Treet Co. (<b>“Company”</b>, <b>“we”</b>, <b>“us”</b>,
        <b>“our”</b>). We are committed to protecting your personal information and your right to privacy.
        If you have any questions or concerns about this privacy notice, or our practices with
        regards to your personal information, please contact us at support@treet.co.
      </p>
      <p>
        When you visit our marketplace websites that link to this Privacy Policy
        (the <b>“Website”</b>), and more generally, use any of our services (the <b>“Services”</b>,
        which include the Website), we appreciate that you are trusting us with your personal
        information. We take your privacy very seriously. In this privacy notice, we seek
        to explain to you in the clearest way possible what information we
        collect, how we use it and what rights you have in relation to it. We hope you take some
        time to read through it carefully, as it is important. If there are any terms in this
        privacy notice that you do not agree with, please discontinue use of our Services
        immediately.
      </p>
      <p>
        This privacy notice applies to all information collected through our Services (which, as
        described above, includes our Website), as well as, any related services, sales, marketing
        or events.
      </p>
      <p>
        <b>
          Please read this privacy notice carefully as it will help you understand what we do with the
          information that we collect.
        </b>
      </p>
      <h2>TABLE OF CONTENTS</h2>
      <a href="#infocollect"><p>1. WHAT INFORMATION DO WE COLLECT?</p></a>
      <a href="#infouse"><p>2. HOW DO WE USE YOUR INFORMATION?</p></a>
      <a href="#infoshare"><p>3. WILL YOUR INFORMATION BE SHARED WITH ANYONE?</p></a>
      <a href="#whoshare"><p>4. WHO WILL YOUR INFORMATION BE SHARED WITH?</p></a>
      <a href="#cookies"><p>5. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</p></a>
      <a href="#googlemaps"><p>6. DO WE USE GOOGLE MAPS PLATFORM APIS?</p></a>
      <a href="#inforetain"><p>7. HOW LONG DO WE KEEP YOUR INFORMATION?</p></a>
      <a href="#infosafe"><p>8. HOW DO WE KEEP YOUR INFORMATION SAFE?</p></a>
      <a href="#infominors"><p>9. DO WE COLLECT INFORMATION FROM MINORS?</p></a>
      <a href="#privacyrights"><p>10. WHAT ARE YOUR PRIVACY RIGHTS?</p></a>
      <a href="#DNT"><p>11. CONTROLS FOR DO-NOT-TRACK FEATURES</p></a>
      <a href="#caresidents"><p>12. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</p></a>
      <a href="#gdpr"><p>13. DO INDIVIDUALS IN THE EU, EEA, UK, AND SWITZERLAND HAVE SPECIFIC PRIVACY RIGHTS?</p></a>
      <a href="#policyupdates"><p>14. DO WE MAKE UPDATES TO THIS NOTICE?</p></a>
      <a href="#contact"><p>15. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</p></a>
      <h2 id="infocollect">1. WHAT INFORMATION DO WE COLLECT?</h2>
      <h3>Personal information you disclose to us</h3>
      <p>
        <b>In Short</b>: We collect personal information that you provide to us.
      </p>
      <p>
        We collect personal information that you voluntarily provide to us when you register on the
        Website, express an interest in obtaining information about us or our products and Services,
        when you participate in activities on the Website (such as by posting messages in our online
        forums or entering competitions, contests or giveaways) or otherwise when you contact us.
      </p>
      <p>
        The personal information that we collect depends on the context of your interactions with us
        and the Website, the choices you make and the products and features you use. The personal
        information we collect may include the following:
      </p>
      <p>
        <b>Personal Information Provided by You.</b> We collect names; email addresses; mailing addresses;
        usernames; passwords; contact preferences; billing addresses; debit/credit card numbers;
        contact or authentication data; phone numbers; and other similar information.
      </p>
      <p>
        <b>Payment Data.</b> We may collect data necessary to process your payment if you make purchases,
        such as your payment instrument number (such as a credit card number), and the security code
        associated with your payment instrument. All payment data is stored by Stripe. You may find
        their privacy notice link(s) here: <a href="https://stripe.com/privacy">https://stripe.com/privacy</a>.
      </p>
      <p>
        All personal information that you provide to us must be true, complete and accurate, and you
        must notify us of any changes to such personal information.
      </p>
      <h3>Information automatically collected</h3>
      <p>
        <b>In Short</b>: Some information — such as your Internet Protocol (IP) address and/or
        browser and device characteristics — is collected automatically when you visit our Website.
      </p>
      <p>
        We automatically collect certain information when you visit, use or navigate the Website.
        This information does not reveal your specific identity (like your name or contact
        information) but may include device and usage information, such as your IP address, browser
        and device characteristics, operating system, language preferences, referring URLs, device
        name, country, location, information about how and when you use our Website and other
        technical information. This information is primarily needed to maintain the security and
        operation of our Website, and for our internal analytics and reporting purposes.
      </p>
      <p>
        Like many businesses, we also collect information through cookies and similar technologies.
      </p>
      <p>The information we collect includes:</p>
      <ul>
        <li>
          <p>
            Log and Usage Data. Log and usage data is service-related, diagnostic, usage and
            performance information our servers automatically collect when you access or use our
            Website and which we record in log files. Depending on how you interact with us, this
            log data may include your IP address, device information, browser type and settings and
            information about your activity in the Website (such as the date/time stamps associated
            with your usage, pages and files viewed, searches and other actions you take such as
            which features you use), device event information (such as system activity, error
            reports (sometimes called ‘crash dumps’) and hardware settings).
          </p>
        </li>
        <li>
          <p>
            Device Data. We collect device data such as information about your computer, phone,
            tablet or other device you use to access the Website. Depending on the device used, this
            device data may include information such as your IP address (or proxy server), device
            and application identification numbers, location, browser type, hardware model Internet
            service provider and/or mobile carrier, operating system and system configuration
            information.
          </p>
        </li>
        <li>
          <p>
            Location Data. We collect location data such as information about your device’s
            location, which can be either precise or imprecise. How much information we collect
            depends on the type and settings of the device you use to access the Website. For
            example, we may use GPS and other technologies to collect geolocation data that tells us
            your current location (based on your IP address). You can opt out of allowing us to
            collect this information either by refusing access to the information or by disabling
            your Location setting on your device. Note however, if you choose to opt out, you may
            not be able to use certain aspects of the Services.
          </p>
        </li>
      </ul>
      <h2 id="infouse">2. HOW DO WE USE YOUR INFORMATION?</h2>
      <p>
        <b>In Short</b>: We process your information for purposes based on legitimate business
        interests, the fulfillment of our contract with you, compliance with our legal obligations,
        and/or your consent.
      </p>
      <p>
        We use personal information collected via our Website for a variety of business purposes
        described below. We process your personal information for these purposes in reliance on our
        legitimate business interests, in order to enter into or perform a contract with you, with
        your consent, and/or for compliance with our legal obligations. We indicate the specific
        processing grounds we rely on next to each purpose listed below.
      </p>
      <p>We use the information we collect or receive:</p>
      <ul>
        <li>
          <p>
            <b>To facilitate account creation and logon process.</b> If you choose to link your
            account with us to a third-party account (such as your Google or Facebook account), we
            use the information you allowed us to collect from those third parties to facilitate
            account creation and logon process for the performance of the contract.
          </p>
        </li>
        <li>
          <p>
            <b>To post testimonials.</b> We post testimonials on our Website that may contain
            personal information. Prior to posting a testimonial, we will obtain your consent to use
            your name and the content of the testimonial. If you wish to update, or delete your
            testimonial, please contact us at support@treet.co and be sure to include your name,
            testimonial location, and contact information.
          </p>
        </li>
        <li>
          <p>
            <b>Request feedback.</b> We may use your information to request feedback and to contact
            you about your use of our Website.
          </p>
        </li>
        <li>
          <p>
            <b>To enable user-to-user communications.</b> We may use your information in order to
            enable user-to-user communications with each user’s consent.
          </p>
        </li>
        <li>
          <p>
            <b>To manage user accounts.</b> We may use your information for the purposes of managing
            our account and keeping it in working order.
          </p>
        </li>
        <li>
          <p>
            <b>To send administrative information to you.</b> We may use your personal information
            to send you product, service and new feature information and/or information about
            changes to our terms, conditions, and policies.
          </p>
        </li>
        <li>
          <p>
            <b>To protect our Services.</b> We may use your information as part of our efforts to
            keep our Website safe and secure (for example, for fraud monitoring and prevention).
          </p>
        </li>
        <li>
          <p>
            <b>
              To enforce our terms, conditions and policies for business purposes, to comply with
              legal and regulatory requirements or in connection with our contract.
            </b>
          </p>
        </li>
        <li>
          <p>
            <b>To respond to legal requests and prevent harm.</b> If we receive a subpoena or other
            legal request, we may need to inspect the data we hold to determine how to respond.
          </p>
        </li>
        <li>
          <p>
            <b>Fulfill and manage your orders.</b> We may use your information to fulfill and manage
            your orders, payments, returns, and exchanges made through the Website.
          </p>
        </li>

        <li>
          <p>
            <b>Administer prize draws and competitions.</b> We may use your information to
            administer prize draws and competitions when you elect to participate in our
            competitions.
          </p>
        </li>

        <li>
          <p>
            <b>To deliver and facilitate delivery of services to the user.</b> We may use your
            information to provide you with the requested service.
          </p>
        </li>

        <li>
          <p>
            <b>To respond to user inquiries/offer support to users.</b> We may use your information
            to respond to your inquiries and solve any potential issues you might have with the use
            of our Services.
          </p>
        </li>
        <li>
          <p>
            <b>To send you marketing and promotional communications.</b> We and/or our third-party
            marketing partners may use the personal information you send to us for our marketing
            purposes, if this is in accordance with your marketing preferences. For example, when
            expressing an interest in obtaining information about us or our Website, subscribing to
            marketing or otherwise contacting us, we will collect personal information from you. You
            can opt-out of our marketing emails at any time (see the “WHAT ARE YOUR PRIVACY RIGHTS”
            below).
          </p>
        </li>
        <li>
          <p>
            <b>Deliver targeted advertising to you.</b> We may use your information to develop and
            display personalized content and advertising (and work with third parties who do so)
            tailored to your interests and/or location and to measure its effectiveness.
          </p>
        </li>
        <li><p>Marketing & Communications directly from the Brand hosting their site on Treet.</p></li>
      </ul>
      <h2 id="infoshare">3. WILL YOUR INFORMATION BE SHARED WITH ANYONE?</h2>
      <p>
        <b>In Short</b>: We only share information with your consent, to comply with laws, to
        provide you with services, to protect your rights, or to fulfill business obligations.
      </p>
      <p>We may process or share your data that we hold based on the following legal basis:</p>
      <p>
        We may share your personal information in the following situations:
      </p>
      <ul>
        <li>
          <p>
            <b>Our Brand Customers.</b> We may share your information with our brand
            customers that you purchase products from.
          </p>
        </li>
        <li>
          <p>
            <b>Business Transfers.</b> We may share or transfer your information in connection with,
            or during negotiations of, any merger, sale of company assets, financing, or acquisition
            of all or a portion of our business to another company.
          </p>
        </li>
        <li>
          <p>
            <b>Vendors, Consultants and Other Third-Party Service Providers.</b> We may share your
            data with third-party vendors, service providers, contractors or agents who perform
            services for us or on our behalf and require access to such information to do that work.
            Examples include: payment processing, data analysis, email delivery, hosting services,
            customer service and marketing efforts. We may allow selected third parties to use
            tracking technology on the Website, which will enable them to collect data on our behalf
            about how you interact with our Website over time. This information may be used to,
            among other things, analyze and track data, determine the popularity of certain content,
            pages or features, and better understand online activity. Unless described in this
            notice, we do not share, sell, rent or trade any of your information with third parties
            for their promotional purposes.
          </p>
        </li>
        <li>
          <p>
            <b>Affiliates.</b> We may share your information with our affiliates, in which case we
            will require those affiliates to honor this privacy notice. Affiliates include our
            parent company and any subsidiaries, joint venture partners or other companies that we
            control or that are under common control with us.
          </p>
        </li>
        <li>
          <p>
            <b>Business Partners.</b> We may share your information with our business partners to
            offer you certain products, services or promotions.
          </p>
        </li>
        <li>
          <p>
            <b>Other Users.</b> When you share personal information (for example, by posting
            comments, contributions or other content to the Website) or otherwise interact with
            public areas of the Website, such personal information may be viewed by all users and
            may be publicly made available outside the Website in perpetuity. Similarly, other users
            will be able to view descriptions of your activity, communicate with you within our
            Website, and view your profile.
          </p>
        </li>
      </ul>
      <h2 id="whoshare">4. WHO WILL YOUR INFORMATION BE SHARED WITH?</h2>
      <p>
        <b>In Short</b>: We only share information with the following third parties.
      </p>
      <p>
        We only share and disclose your information with the following third parties. We have
        categorized each party so that you may be easily understand the purpose of our data
        collection and processing practices. If we have processed your data based on your consent
        and you wish to revoke your consent, please contact us using the contact details provided in
        the section below titled “HOW CAN YOU CONTACT US ABOUT THIS NOTICE?”.
      </p>
      <ul>
        <li>
          <p>
            <b>Advertising, Direct Marketing, and Lead Generation</b>
          </p>
        </li>
        <p>Google AdSense</p>
        <li>
          <p>
            <b>Allow Users to Connect to Their Third-Party Accounts</b>
          </p>
        </li>
        <p>Facebook account, Stripe account and Instagram account</p>
        <li>
          <p>
            <b>Cloud Computing Services</b>
          </p>
        </li>
        <p>Amazon Web Services (AWS)</p>
        <li>
          <p>
            <b>Communicate and Chat with Users</b>
          </p>
        </li>
        <p>HubSpot Email and Sendgrid and Zendesk Chat and Intercom</p>
        <li>
          <p>
            <b>Content Optimization</b>
          </p>
        </li>
        <p>Google Fonts and Google Site Search</p>
        <li>
          <p>
            <b>Data Backup and Security</b>
          </p>
        </li>
        <p>Amazon Glacier</p>
        <li>
          <p>
            <b>Functionality and Infrastructure Optimization</b>
          </p>
        </li>
        <p>Google Cloud Storage and Amazon Web Services</p>
        <li>
          <p>
            <b>Invoice and Billing</b>
          </p>
        </li>
        <p>Stripe</p>
        <li>
          <p>
            <b>Retargeting Platforms</b>
          </p>
        </li>
        <p>
          Facebook Custom Audience, Facebook Remarketing, Google Ads Remarketing and Google
          Analytics Remarketing
        </p>
        <li>
          <p>
            <b>Social Media Sharing and Advertising</b>
          </p>
        </li>
        <p>Facebook advertising and Instagram advertising</p>
        <li>
          <p>
            <b>User Account Registration and Authentication</b>
          </p>
        </li>
        <p>Firebase Authentication and Stripe OAuth</p>
        <li>
          <p>
            <b>Web and Mobile Analytics</b>
          </p>
        </li>
        <p>
          Facebook Analytics, Facebook Ads conversion tracking, Google Analytics, Google Ads and
          HubSpot Analytics
        </p>
        <li>
          <p>
            <b>Website Hosting</b>
          </p>
        </li>
        <p>Sharetribe</p>
      </ul>
      <h2 id="cookies">5. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</h2>
      <p>
        <b>In Short</b>: We may use cookies and other tracking technologies to collect and store
        your information.
      </p>
      <p>
        We may use cookies and similar tracking technologies (like web beacons and pixels) to access
        or store information. Specific information about how we use such technologies and how you
        can refuse certain cookies is set out in our Cookie Notice.
      </p>
      <h2 id="googlemaps">6. DO WE USE GOOGLE MAPS PLATFORM APIS?</h2>
      <p>
        <b>In Short</b>: Yes, we use Google Maps Platform APIs for the purpose of providing better
        service.
      </p>
      <p>
        This Website uses Google Maps Platform APIs which are subject to Google’s Terms of Service.
        You may find the Google Maps Platform Terms of Service <a href="https://cloud.google.com/maps-platform/terms/">here</a>. To find out more about Google’s
        Privacy Policy, please refer to this <a href="https://policies.google.com/privacy">link</a>. We obtain and store on your device (‘cache’) your
        location. You may revoke your consent anytime by contacting us at the contact details
        provided at the end of this document.
      </p>
      <h2 id="inforetain">7. HOW LONG DO WE KEEP YOUR INFORMATION?</h2>
      <p>
        <b>In Short</b>: We keep your information for as long as necessary to fulfill the purposes
        outlined in this privacy notice unless otherwise required by law.
      </p>
      <p>
        We will only keep your personal information for as long as it is necessary for the purposes
        set out in this privacy notice, unless a longer retention period is required or permitted by
        law (such as tax, accounting or other legal requirements). No purpose in this notice will
        require us keeping your personal information for longer than twelve (12) months past the
        termination of the user’s account.
      </p>
      <p>
        When we have no ongoing legitimate business need to process your personal information, we
        will either delete or anonymize such information, or, if this is not possible (for example,
        because your personal information has been stored in backup archives), then we will securely
        store your personal information and isolate it from any further processing until deletion is
        possible.
      </p>
      <h2 id="infosafe">8. HOW DO WE KEEP YOUR INFORMATION SAFE?</h2>
      <p>
        <b>In Short</b>: We aim to protect your personal information through a system of
        organizational and technical security measures.
      </p>
      <p>
        We have implemented appropriate technical and organizational security measures designed to
        protect the security of any personal information we process. However, despite our safeguards
        and efforts to secure your information, no electronic transmission over the Internet or
        information storage technology can be guaranteed to be 100% secure, so we cannot promise or
        guarantee that hackers, cybercriminals, or other unauthorized third parties will not be able
        to defeat our security, and improperly collect, access, steal, or modify your information.
        Although we will do our best to protect your personal information, transmission of personal
        information to and from our Website is at your own risk. You should only access the Website
        within a secure environment.
      </p>
      <h2 id="infominors">9. DO WE COLLECT INFORMATION FROM MINORS?</h2>
      <p>
        <b>In Short</b>: We do not knowingly collect data from or market to children under 18 years
        of age.
      </p>
      <p>
        We do not knowingly solicit data from or market to children under 18 years of age. By using
        the Website, you represent that you are at least 18 or that you are the parent or guardian
        of such a minor and consent to such minor dependent’s use of the Website. If we learn that
        personal information from users less than 18 years of age has been collected, we will
        deactivate the account and take reasonable measures to promptly delete such data from our
        records. If you become aware of any data we may have collected from children under age 18,
        please contact us at support@treet.co.
      </p>
      <h2 id="privacyrights">10. WHAT ARE YOUR PRIVACY RIGHTS?</h2>
      <p>
        <b>In Short</b>: You may review, change, or terminate your account at any time.
      </p>
      <h3><u>Account Information</u></h3>
      <p>
        If you would at any time like to review or change the information in your account or
        terminate your account, you can:
      </p>
      <ul>
        <li>
          <p>Contact us using the contact information provided.</p>
        </li>
      </ul>
      <p>
        Upon your request to terminate your account, we will deactivate or delete your account and
        information from our active databases. However, we may retain some information in our files
        to prevent fraud, troubleshoot problems, assist with any investigations, enforce our Terms
        of Use and/or comply with applicable legal requirements.
      </p>
      <p>
        <b>
          <u>Cookies and similar technologies:</u>
        </b>{' '}
        Most Web browsers are set to accept cookies by default. If you prefer, you can usually
        choose to set your browser to remove cookies and to reject cookies. If you choose to remove
        cookies or reject cookies, this could affect certain features or services of our Website. To
        opt-out of interest-based advertising by advertisers on our Website visit
        <a href="http://www.aboutads.info/choices/">http://www.aboutads.info/choices/</a>.
      </p>
      <p>
        <b>
          <u>Opting out of email marketing:</u>
        </b>{' '}
        You can unsubscribe from our marketing email list at any time by clicking on the unsubscribe
        link in the emails that we send or by contacting us using the details provided below. You
        will then be removed from the marketing email list — however, we may still communicate with
        you, for example to send you service-related emails that are necessary for the
        administration and use of your account, to respond to service requests, or for other
        non-marketing purposes. To otherwise opt-out, you may:
      </p>
      <ul>
        <li>
          <p>Contact us using the contact information provided.</p>
        </li>
      </ul>
      <h2 id="DNT">11. CONTROLS FOR DO-NOT-TRACK FEATURES</h2>
      <p>
        Most web browsers and some mobile operating systems and mobile applications include a
        Do-Not-Track (“DNT”) feature or setting you can activate to signal your privacy preference
        not to have data about your online browsing activities monitored and collected. At this
        stage no uniform technology standard for recognizing and implementing DNT signals has been
        finalized. As such, we do not currently respond to DNT browser signals or any other
        mechanism that automatically communicates your choice not to be tracked online. If a
        standard for online tracking is adopted that we must follow in the future, we will inform
        you about that practice in a revised version of this privacy notice.
      </p>
      <h2 id="caresidents">12. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</h2>
      <p>
        <b>In Short</b>: Yes, if you are a resident of California, you are granted specific rights
        regarding access to your personal information.
      </p>
      <p>
        California Civil Code Section 1798.83, also known as the “Shine The Light” law, permits our
        users who are California residents to request and obtain from us, once a year and free of
        charge, information about categories of personal information (if any) we disclosed to third
        parties for direct marketing purposes and the names and addresses of all third parties with
        which we shared personal information in the immediately preceding calendar year. If you are
        a California resident and would like to make such a request, please submit your request in
        writing to us using the contact information provided below.
      </p>
      <p>
        If you are under 18 years of age, reside in California, and have a registered account with
        the Website, you have the right to request removal of unwanted data that you publicly post
        on the Website. To request removal of such data, please contact us using the contact
        information provided below, and include the email address associated with your account and a
        statement that you reside in California. We will make sure the data is not publicly
        displayed on the Website, but please be aware that the data may not be completely or
        comprehensively removed from all our systems (e.g. backups, etc.).
      </p>
      CCPA Privacy Notice
      <p>The California Code of Regulations defines a “resident” as:</p>
      <p>
        (1) every individual who is in the State of California for other than a temporary or
        transitory purpose and
      </p>
      <p>
        (2) every individual who is domiciled in the State of California who is outside the State of
        California for a temporary or transitory purpose
      </p>
      <p>All other individuals are defined as “non-residents.”</p>
      <p>
        If this definition of “resident” applies to you, we must adhere to certain rights and
        obligations regarding your personal information.
      </p>
      <p>What categories of personal information do we collect?</p>
      <p>
        We have collected the following categories of personal information in the past twelve (12)
        months:
      </p>
      <table style={{ width: '100%' }} border="1">
        <tbody>
          <tr style={{ height: '21px' }}>
            <td style={{ height: '21px' }}>
              <p>
                &nbsp;<strong>Category</strong>
              </p>
            </td>
            <td style={{ height: '21px' }}>
              <p>
                &nbsp;<strong>Examples</strong>
              </p>
            </td>
            <td style={{ height: '21px' }}>
              <p>
                &nbsp;<strong>Collected</strong>
              </p>
            </td>
          </tr>
          <tr style={{ height: '21px' }}>
            <td style={{ height: '21px' }}>
              <p>&nbsp;A. Identifiers</p>
            </td>
            <td style={{ height: '21px' }}>
              <p>
                &nbsp;Contact details, such as real name, alias, postal address, telephone or mobile
                contact number, unique personal identifier, online identifier, Internet Protocol
                address, email address and account name
              </p>
            </td>
            <td style={{ height: '21px' }}>
              <p>&nbsp;NO</p>
            </td>
          </tr>
          <tr style={{ height: '21px' }}>
            <td style={{ height: '21px' }}>
              <p>
                &nbsp;B. Personal information categories listed in the California Customer Records
                statute
              </p>
            </td>
            <td style={{ height: '21px' }}>
              <p>
                &nbsp;Name, contact information, education, employment, employment history and
                financial information
              </p>
            </td>
            <td style={{ height: '21px' }}>
              YES<p>&nbsp;</p>
            </td>
          </tr>
          <tr style={{ height: '21px' }}>
            <td style={{ height: '21px' }}>
              <p>
                &nbsp;C. Protected classification characteristics under California or federal law
              </p>
            </td>
            <td style={{ height: '21px' }}>
              <p>&nbsp;Gender and date of birth</p>
            </td>
            <td style={{ height: '21px' }}>
              NO<p>&nbsp;</p>
            </td>
          </tr>
          <tr style={{ height: '21px' }}>
            <td style={{ height: '21px' }}>
              <p>&nbsp;D. Commercial information</p>
            </td>
            <td style={{ height: '21px' }}>
              <p>
                &nbsp;Transaction information, purchase history, financial details and payment
                information
              </p>
            </td>
            <td style={{ height: '21px' }}>
              NO<p>&nbsp;</p>
            </td>
          </tr>
          <tr style={{ height: '21px' }}>
            <td style={{ height: '21px' }}>
              <p>&nbsp;E. Biometric information</p>
            </td>
            <td style={{ height: '21px' }}>
              <p>&nbsp;Fingerprints and voiceprints</p>
            </td>
            <td style={{ height: '21px' }}>
              NO<p>&nbsp;</p>
            </td>
          </tr>
          <tr style={{ height: '21px' }}>
            <td style={{ height: '21px' }}>
              <p>&nbsp;F. Internet or other similar network activity</p>
            </td>
            <td style={{ height: '21px' }}>
              <p>
                &nbsp;Browsing history, search history, online behavior, interest data, and
                interactions with our and other websites, applications, systems and advertisements
              </p>
            </td>
            <td style={{ height: '21px' }}>
              NO<p>&nbsp;</p>
            </td>
          </tr>
          <tr style={{ height: '21px' }}>
            <td style={{ height: '21px' }}>
              <p>&nbsp;G. Geolocation data</p>
            </td>
            <td style={{ height: '21px' }}>
              <p>&nbsp;Device location</p>
            </td>
            <td style={{ height: '21px' }}>
              NO<p>&nbsp;</p>
            </td>
          </tr>
          <tr style={{ height: '21px' }}>
            <td style={{ height: '21px' }}>
              <p>&nbsp;H. Audio, electronic, visual, thermal, olfactory, or similar information</p>
            </td>
            <td style={{ height: '21px' }}>
              <p>
                &nbsp;Images and audio, video or call recordings created in connection with our
                business activities
              </p>
            </td>
            <td style={{ height: '21px' }}>
              NO<p>&nbsp;</p>
            </td>
          </tr>
          <tr style={{ height: '21px' }}>
            <td style={{ height: '21px' }}>
              <p>&nbsp;I. Professional or employment-related information</p>
            </td>
            <td style={{ height: '21px' }}>
              <p>
                &nbsp;Business contact details in order to provide you our services at a business
                level, job title as well as work history and professional qualifications if you
                apply for a job with us
              </p>
            </td>
            <td style={{ height: '21px' }}>
              NO<p>&nbsp;</p>
            </td>
          </tr>
          <tr style={{ height: '21px' }}>
            <td style={{ height: '21px' }}>
              <p>&nbsp;J. Education Information</p>
            </td>
            <td style={{ height: '21px' }}>
              <p>&nbsp;Student records and directory information</p>
            </td>
            <td style={{ height: '21px' }}>
              <p>&nbsp;</p>
            </td>
          </tr>
          <tr style={{ height: '21px' }}>
            <td style={{ height: '21px' }}>
              <p>&nbsp;K. Inferences drawn from other personal information</p>
            </td>
            <td style={{ height: '21px' }}>
              <p>
                &nbsp;Inferences drawn from any of the collected personal information listed above
                to create a profile or summary about, for example, an individual&rsquo;s preferences
                and characteristics
              </p>
            </td>
            <td style={{ height: '21px' }}>
              YES<p>&nbsp;</p>
            </td>
          </tr>
        </tbody>
      </table>
      <p>
        We may also collect other personal information outside of these categories instances where
        you interact with us in-person, online, or by phone or mail in the context of:
      </p>
      <ul>
        <li>
          <p>Receiving help through our customer support channels;</p>
        </li>
        <li>
          <p>Participation in customer surveys or contests; and</p>
        </li>
        <li>
          <p>Facilitation in the delivery of our Services and to respond to your inquiries.</p>
        </li>
      </ul>
      <p>
        <b>How do we use and share your personal information?</b>
      </p>
      <p>Treet Co. collects and shares your personal information through:</p>
      <ul>
        <li>
          <p>Targeting cookies/Marketing cookies</p>
        </li>
        <li>
          <p>Social media cookies</p>
        </li>
        <li>
          <p>Beacons/Pixels/Tags</p>
        </li>
      </ul>
      <p>
        More information about our data collection and sharing practices can be found in this
        privacy notice.
      </p>
      <p>
        You may contact us by email at support@treet.co, or by referring to the contact details at
        the bottom of this document.
      </p>
      <p>
        If you are using an authorized agent to exercise your right to opt-out we may deny a request
        if the authorized agent does not submit proof that they have been validly authorized to act
        on your behalf.
      </p>
      <p>
        <b>Will your information be shared with anyone else?</b>
      </p>
      <p>
        We may disclose your personal information with our service providers pursuant to a written
        contract between us and each service provider. Each service provider is a for-profit entity
        that processes the information on our behalf.
      </p>
      <p>The current list of our service providers can be found in below.</p>
      <p>
        We may use your personal information for our own business purposes, such as for undertaking
        internal research for technological development and demonstration. This is not considered to
        be “selling” of your personal data.
      </p>
      <p>
        Treet Co. has disclosed the following categories of personal information to third parties
        for a business or commercial purpose in the preceding twelve (12) months:
      </p>
      <ul>
        <li>
          <p>
            Category B. Personal information, as defined in the California Customer Records law,
            such as your name, contact information, education, employment, employment history and
            financial information.
          </p>
        </li>
      </ul>
      <p>
        The categories of third parties to whom we disclosed personal information for a business or
        commercial purpose can be found under “WHO WILL YOUR INFORMATION BE SHARED WITH?”.
      </p>
      <p>
        <b>Your rights with respect to your personal data</b>
      </p>
      <p>
        <u>Right to request deletion of the data - Request to delete</u>
      </p>
      <p>
        You can ask for the deletion of your personal information. If you ask us to delete your
        personal information, we will respect your request and delete your personal information,
        subject to certain exceptions provided by law, such as (but not limited to) the exercise by
        another consumer of his or her right to free speech, our compliance requirements resulting
        from a legal obligation or any processing that may be required to protect against illegal
        activities.
      </p>
      <p>
        <u>Right to be informed - Request to know</u>
      </p>
      <p>Depending on the circumstances, you have a right to know:</p>
      <p>whether we collect and use your personal information;</p>
      <p>the categories of personal information that we collect;</p>
      <p>the purposes for which the collected personal information is used;</p>
      <p>whether we sell your personal information to third parties;</p>
      <p>
        the categories of personal information that we sold or disclosed for a business purpose;
      </p>
      <p>
        the categories of third parties to whom the personal information was sold or disclosed for a
        business purpose; and
      </p>
      <p>the business or commercial purpose for collecting or selling personal information.</p>
      <p>
        In accordance with applicable law, we are not obligated to provide or delete consumer
        information that is de-identified in response to a consumer request or to re-identify
        individual data to verify a consumer request.
      </p>
      <p>
        <u>Right to Non-Discrimination for the Exercise of a Consumer’s Privacy Rights</u>
      </p>
      <p>We will not discriminate against you if you exercise your privacy rights.</p>
      <p>
        <u>Verification process</u>
      </p>
      <p>
        Upon receiving your request, we will need to verify your identity to determine you are the
        same person about whom we have the information in our system. These verification efforts
        require us to ask you to provide information so that we can match it with information you
        have previously provided us. For instance, depending on the type of request you submit, we
        may ask you to provide certain information so that we can match the information you provide
        with the information we already have on file, or we may contact you through a communication
        method (e.g. phone or email) that you have previously provided to us. We may also use other
        verification methods as the circumstances dictate.
      </p>
      <p>
        We will only use personal information provided in your request to verify your identity or
        authority to make the request. To the extent possible, we will avoid requesting additional
        information from you for the purposes of verification. If, however, we cannot verify your
        identity from the information already maintained by us, we may request that you provide
        additional information for the purposes of verifying your identity, and for security or
        fraud-prevention purposes. We will delete such additionally provided information as soon as
        we finish verifying you.
      </p>
      <p>
        <u>Other privacy rights</u>
      </p>
      <ul>
        <li>
          <p>you may object to the processing of your personal data</p>
        </li>
        <li>
          <p>
            you may request correction of your personal data if it is incorrect or no longer
            relevant, or ask to restrict the processing of the data
          </p>
        </li>
        <li>
          <p>
            you can designate an authorized agent to make a request under the CCPA on your behalf.
            We may deny a request from an authorized agent that does not submit proof that they have
            been validly authorized to act on your behalf in accordance with the CCPA.
          </p>
        </li>
        <li>
          <p>
            you may request to opt-out from future selling of your personal information to third
            parties. Upon receiving a request to opt-out, we will act upon the request as soon as
            feasibly possible, but no later than 15 days from the date of the request submission.
            You may also initiate any opt outs by broadcasting an Opt-Out Preference Signal, such
            as the Global Privacy Control (GPC). We honor Opt-Out Preference Signals, including GPC.
            If you choose to use an Opt-Out Preference Signal, you will need to turn it on for each
            supported browser or browser extension you use.
          </p>
        </li>
      </ul>
      <p>
        To exercise these rights, you can contact us by email at support@treet.co, or by
        referring to the contact details at the bottom of this document. If you have a complaint
        about how we handle your data, we would like to hear from you.
      </p>
      <h2 id="gdpr">13. DO INDIVIDUALS IN THE EU, EEA, UK, AND SWITZERLAND HAVE SPECIFIC
        PRIVACY RIGHTS?
      </h2>
      <p>
        <b>In Short</b>: Yes, if you are located in the European Union, European Economic Area,
        United Kingdom, or Switzerland, you are granted specific rights regarding your
        Personal Data. This section provides additional information regarding Treet’s processing
        of personal data of people located in the European Union (“EU”), European Economic
        Area (“EEA”), Switzerland, and the United Kingdom (“UK”) in accordance with the
        EU Data Protection Regulation, UK Data Protection Regulation, and the Swiss Federal Data
        Protection Act. For purposes of this section, “Personal Data” has the definition set
        forth in the foregoing data protection laws.
      </p>
      <p>
        <b>
          <u>Legal Basis for Processing</u>
        </b>
      </p>
      <p>
        Our legal basis for processing Personal Data depends on the Personal Data concerned
        and the context in which we process it. We process Personal Data from you where we
        need it to perform a contract with you, where the processing is in our legitimate
        interests (including the purposes described in this privacy notice), where the processing
        is necessary for us to meet our applicable legal obligations, or if we otherwise
        have your consent.
      </p>
      <p>
        <b>
          <u>Special Category Data</u>
        </b>
      </p>
      <p>
        Treet does not intend to collect any Special Category Data, which is any data that reveals
        your racial or ethnic origin, political opinions, religious, moral or philosophical
        beliefs, trade union membership, political views, the processing of genetic data, biometric
        data for the purpose of identifying a person, and data concerning health or a person’s
        sex life and/or sexual orientation.  Please refrain from sending us any Special Category
        Data.
      </p>
      <p>
        <b>
          <u>Automated Decision Making</u>
        </b>
      </p>
      <p>
        Treet does not make any automated decisions on your behalf or about you without first
        obtaining your express, opt-in consent. In the event we secure your consent to do so,
        you have the right to object to the processing of Personal Data via automated decision
        making at any time by contacting us at support@treet.co.
      </p>
      <p>
        <b>
          <u>Transfers to Third Parties and Countries</u>
        </b>
      </p>
      <p>
        Personal Data that we collect or receive may be transferred to and/or processed by third
        parties that are located outside of the EU, EEA, Switzerland or the UK, some of which
        applicable authorities may not consider to have an adequate level of protection
        for Personal Data. Treet will only transfer Personal Data to third parties located outside
        of the EU, EEA, Switzerland, and the UK when it has ensured appropriate safeguards for
        such Personal Data through use of the standard contractual clauses or other lawful
        and approved methods.
      </p>
      <p>
        <b>Your Privacy Rights</b>
      </p>
      <p>
        You have the right to request access to your Personal Data, to have your Personal
        Data corrected, restricted or deleted, to withdraw any consent that you have given to
        the processing of your Personal Data (without affecting the lawfulness of the processing
        prior to your withdrawal of consent) and to object to our processing of your Personal
        Data.  You also have the right of data portability in certain circumstances, which means
        that you can request that we provide you with a transferable copy of personal data that
        you have provided to us.  Please note that your rights may be subject to various
        exceptions. If you wish to exercise any of these rights, or if you have any concerns
        about our processing of your Personal Data, please contact us at support@treet.co.
      </p>
      <p>
        You also have the right to lodge a complaint with a supervisory authority or other
        regulatory agency if you believe we have violated any of the rights afforded to you
        under applicable data protection laws. We encourage you to reach out to us
        first so we have an opportunity to address your concerns directly before you do so.
      </p>
      <h2 id="policyupdates">14. DO WE MAKE UPDATES TO THIS NOTICE?</h2>
      <p>
        <b>In Short</b>: Yes, we will update this notice as necessary to stay compliant with
        relevant laws.
      </p>
      <p>
        We may update this privacy notice from time to time. The updated version will be indicated
        by an updated “Revised” date and the updated version will be effective as soon as it is
        accessible. If we make material changes to this privacy notice, we may notify you either by
        prominently posting a notice of such changes or by directly sending you a notification. We
        encourage you to review this privacy notice frequently to be informed of how we are
        protecting your information.
      </p>
      <h2 id="contact">15. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</h2>
      <p>
        If you have questions or comments about this notice, you may email us at support@treet.co.
      </p>
    </div>
  );
};

PrivacyPolicy.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

PrivacyPolicy.propTypes = {
  rootClassName: string,
  className: string,
};

export default PrivacyPolicy;
