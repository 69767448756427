import { Box, Divider } from '@material-ui/core';
import arrayMutators from 'final-form-arrays';
import isEmpty from 'lodash/isEmpty';
import React, { FC } from 'react';
import { Form as FinalForm } from 'react-final-form';
import { CoreAddress, CountryCode } from '../../types/apollo/generated/types.generated';
import { constructAddressMutatorFn } from '../../util/address';
import { ADDRESS_FORM_TYPE_SHIPPING } from '../../util/constants';
import { getAllowedShipToCountryCodes } from '../../util/shipping';
import { useBrandCountryConfig } from '../../hooks/useCountryConfig';
import DialogWithCloseButton from '../DialogWithCloseButton/DialogWithCloseButton';
import { AddressForm, Button, Error, Form } from '..';
import css from './BundlePanel.module.css';

export const EDIT_SHIPPING_ADDRESS = 'editShippingAddress';

interface EditShippingAddressFormProps {
  isBrandDirectBundle: boolean;
  shippingAddress: CoreAddress;
  shipFromCountry: CountryCode;
  onSave: (values: any, addressId: string) => void;
}

const EditShippingAddressForm: FC<EditShippingAddressFormProps> = (props) => {
  const { shippingAddress, shipFromCountry, onSave, isBrandDirectBundle } = props;

  const { allowedShippingDestinationCountries } = useBrandCountryConfig();

  const fullNameParts = shippingAddress.fullName.split(' ');
  const lastName = fullNameParts.pop();
  const firstName = fullNameParts.join(' ');

  const initialValues = {
    [EDIT_SHIPPING_ADDRESS]: {
      ...shippingAddress,
      firstName,
      lastName,
      postal: shippingAddress.zip,
    },
  };

  return (
    <>
      <FinalForm
        onSubmit={(values: any) => onSave(values, shippingAddress.id)}
        initialValues={initialValues}
        keepDirtyOnReinitialize
        mutators={{
          ...arrayMutators,
          updateShippingAddress: constructAddressMutatorFn(EDIT_SHIPPING_ADDRESS),
        }}
        render={(formRenderProps) => {
          const { errors, form, handleSubmit, submitting, submitError } = formRenderProps;

          return (
            <>
              <Form id="EditShippingAddressForm" onSubmit={handleSubmit}>
                <Divider className={css.divider} />
                <Box p={2}>
                  <AddressForm
                    form={form}
                    addressType={ADDRESS_FORM_TYPE_SHIPPING}
                    fieldId="BundlePanelEditShippingAddress"
                    prefix={EDIT_SHIPPING_ADDRESS}
                    allowedCountryCodes={getAllowedShipToCountryCodes(
                      isBrandDirectBundle,
                      shipFromCountry === CountryCode.Us,
                      allowedShippingDestinationCountries
                    )}
                  />
                  {!!submitError && (
                    <Box pt={2} display="flex" justifyContent="center">
                      <Error>{submitError}</Error>
                    </Box>
                  )}
                  <Box display="flex" justifyContent="center" pt={6}>
                    <Button
                      type="submit"
                      className={css.saveButton}
                      inProgress={submitting}
                      disabled={!isEmpty(errors)}
                    >
                      Save
                    </Button>
                  </Box>
                </Box>
              </Form>
            </>
          );
        }}
      />
    </>
  );
};

interface EditShippingAddressModalProps {
  isBrandDirectBundle: boolean;
  isOpen: boolean;
  shippingAddress: CoreAddress;
  shipFromCountry: CountryCode;
  onClose: () => void;
  onSave: (values: any, addressId: string) => void;
}

const EditShippingAddressModal: FC<EditShippingAddressModalProps> = (props) => {
  const { isOpen, shippingAddress, shipFromCountry, onClose, onSave, isBrandDirectBundle } = props;

  return (
    <DialogWithCloseButton
      allowFullScreen={false}
      scroll="paper"
      open={isOpen}
      onClose={onClose}
      fullWidth
      titleText="Edit Shipping Address"
    >
      <EditShippingAddressForm
        shippingAddress={shippingAddress}
        shipFromCountry={shipFromCountry}
        onSave={onSave}
        isBrandDirectBundle={isBrandDirectBundle}
      />
    </DialogWithCloseButton>
  );
};

export default EditShippingAddressModal;
