import { getDefaultShopConfig } from './default';
import * as custom from '../filters/marketplace-custom-config';
import { getBrandDirectFilterConfig } from '../filters/brandDirect';
import { defaultBagConditionsOptions, getConditionsFilterConfig } from '../filters/condition';
import { getColorFilterConfig } from '../filters/color';

export const paktShopConfig = {
  ...getDefaultShopConfig(),
  filters: [
    ...custom.filters,
    getBrandDirectFilterConfig(),
    getColorFilterConfig(),
    getConditionsFilterConfig({ options: defaultBagConditionsOptions }),
  ],
};
