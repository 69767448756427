/* eslint-disable import/prefer-default-export */
import { getConditionsFilterConfig } from '../filters/condition';
import { getBrandDirectFilterConfig } from '../filters/brandDirect';
import { getSizeFilterConfig } from '../filters/size';
import { getDefaultShopConfig } from './default';
import * as custom from '../filters/marketplace-custom-config';

const teddyblakeSizes = [
  '35',
  '36',
  '37',
  '38',
  '39',
  '40',
  '41',
  'Micro',
  'Mignon',
  'Small',
  'Medium',
];

export const teddyblakeShopConfig = {
  ...getDefaultShopConfig(),
  filters: [
    ...custom.filters,
    getBrandDirectFilterConfig(),
    getConditionsFilterConfig(),
    getSizeFilterConfig({ sizes: teddyblakeSizes }),
  ],
};
