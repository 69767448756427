import pick from 'lodash/pick';
import moment from 'moment';
import config from '../../shopConfig/config';
import { types as sdkTypes } from '../../util/sdkLoader';
import { storableError } from '../../util/errors';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { denormalisedResponseEntities } from '../../util/data';
import {
  LISTING_PAGE_DRAFT_VARIANT,
  LISTING_PAGE_PENDING_APPROVAL_VARIANT,
} from '../../util/urlHelpers';
import { fetchCurrentUser } from '../../ducks/user.duck';
import { handle } from '../../util/helpers';
import { recommendedListings as recommendedListingsApiRequest } from '../../util/api';
import { RequestStatus } from '../../types/requestStatus';
import { ListingItemType, RecommendedListingsType } from '../../types/sharetribe/listing';
import { LISTING_STATE_CLOSED } from '../../util/types';
import { getShopConfig } from '../../shopConfig/configHelper';
import * as log from '../../util/log';
import { orderIdsByList } from '../../util/frenzyHelpers';
import { sortListings } from './ListingPage.utils';
import { ITEM_AVAILABILITY_AVAILABLE } from '../../util/constants';

const { UUID } = sdkTypes;

export const NUM_RECOMMENDED_LISTINGS = 4;

// ================ Action types ================ //

export const SET_INITIAL_VALUES = 'app/ListingPage/SET_INITIAL_VALUES';

export const SET_HAS_FIRST_MEDIA_SRC_LOADED = 'api/ListingPage/SET_HAS_FIRST_MEDIA_SRC_LOADED';

export const SHOW_LISTING_REQUEST = 'app/ListingPage/SHOW_LISTING_REQUEST';
export const SHOW_LISTING_ERROR = 'app/ListingPage/SHOW_LISTING_ERROR';

export const FETCH_TIME_SLOTS_REQUEST = 'app/ListingPage/FETCH_TIME_SLOTS_REQUEST';
export const FETCH_TIME_SLOTS_SUCCESS = 'app/ListingPage/FETCH_TIME_SLOTS_SUCCESS';
export const FETCH_TIME_SLOTS_ERROR = 'app/ListingPage/FETCH_TIME_SLOTS_ERROR';

export const FETCH_LINE_ITEMS_REQUEST = 'app/ListingPage/FETCH_LINE_ITEMS_REQUEST';
export const FETCH_LINE_ITEMS_SUCCESS = 'app/ListingPage/FETCH_LINE_ITEMS_SUCCESS';
export const FETCH_LINE_ITEMS_ERROR = 'app/ListingPage/FETCH_LINE_ITEMS_ERROR';

export const SEND_ENQUIRY_REQUEST = 'app/ListingPage/SEND_ENQUIRY_REQUEST';
export const SEND_ENQUIRY_SUCCESS = 'app/ListingPage/SEND_ENQUIRY_SUCCESS';
export const SEND_ENQUIRY_ERROR = 'app/ListingPage/SEND_ENQUIRY_ERROR';

export const FETCH_RECOMMENDED_LISTINGS_REQUEST =
  'app/ListingPage/FETCH_RECOMMENDED_LISTINGS_REQUEST';
export const FETCH_RECOMMENDED_LISTINGS_SUCCESS =
  'app/ListingPage/FETCH_RECOMMENDED_LISTINGS_SUCCESS';
export const FETCH_RECOMMENDED_LISTINGS_ERROR = 'app/ListingPage/FETCH_RECOMMENDED_LISTINGS_ERROR';

export const FETCH_AUTHOR_LISTINGS_REQUEST = 'app/ListingPage/FETCH_AUTHOR_LISTINGS_REQUEST';
export const FETCH_AUTHOR_LISTINGS_SUCCESS = 'app/ListingPage/FETCH_AUTHOR_LISTINGS_SUCCESS';
export const FETCH_AUTHOR_LISTINGS_ERROR = 'app/ListingPage/FETCH_AUTHOR_LISTINGS_ERROR';

export const FETCH_CONSOLIDATED_LISTINGS_REQUEST =
  'app/ListingPage/FETCH_CONSOLIDATED_LISTINGS_REQUEST';
export const FETCH_CONSOLIDATED_LISTINGS_SUCCESS =
  'app/ListingPage/FETCH_CONSOLIDATED_LISTINGS_SUCCESS';
export const FETCH_CONSOLIDATED_LISTINGS_ERROR =
  'app/ListingPage/FETCH_CONSOLIDATED_LISTINGS_ERROR';

// ================ Reducer ================ //

const initialState = {
  id: null,
  hasFirstImageLoaded: false,
  showListingError: null,
  timeSlots: null,
  fetchTimeSlotsError: null,
  lineItems: null,
  fetchLineItemsInProgress: false,
  fetchLineItemsError: null,
  sendEnquiryInProgress: false,
  sendEnquiryError: null,
  enquiryModalOpenForListingId: null,
  recommendedListings: null,
  fetchRecommendedListingsStatus: null,
  authorListingIds: null,
  fetchAuthorListingsStatus: null,
  consolidatedListings: null,
  fetchConsolidatedListingsStatus: null,
};

const listingPageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case SET_INITIAL_VALUES:
      return { ...initialState, ...payload };

    case SET_HAS_FIRST_MEDIA_SRC_LOADED:
      return { ...state, hasFirstImageLoaded: true };

    case SHOW_LISTING_REQUEST:
      return { ...state, id: payload.id, showListingError: null };
    case SHOW_LISTING_ERROR:
      return { ...state, showListingError: payload };

    case FETCH_TIME_SLOTS_REQUEST:
      return { ...state, fetchTimeSlotsError: null };
    case FETCH_TIME_SLOTS_SUCCESS:
      return { ...state, timeSlots: payload };
    case FETCH_TIME_SLOTS_ERROR:
      return { ...state, fetchTimeSlotsError: payload };

    case FETCH_LINE_ITEMS_REQUEST:
      return { ...state, fetchLineItemsInProgress: true, fetchLineItemsError: null };
    case FETCH_LINE_ITEMS_SUCCESS:
      return { ...state, fetchLineItemsInProgress: false, lineItems: payload };
    case FETCH_LINE_ITEMS_ERROR:
      return { ...state, fetchLineItemsInProgress: false, fetchLineItemsError: payload };

    case SEND_ENQUIRY_REQUEST:
      return { ...state, sendEnquiryInProgress: true, sendEnquiryError: null };
    case SEND_ENQUIRY_SUCCESS:
      return { ...state, sendEnquiryInProgress: false };
    case SEND_ENQUIRY_ERROR:
      return { ...state, sendEnquiryInProgress: false, sendEnquiryError: payload };

    case FETCH_RECOMMENDED_LISTINGS_REQUEST:
      return {
        ...state,
        fetchRecommendedListingsStatus: RequestStatus.Pending,
        fetchRecommendedListingsError: null,
        recommendedListings: null,
      };
    case FETCH_RECOMMENDED_LISTINGS_SUCCESS:
      return {
        ...state,
        fetchRecommendedListingsStatus: RequestStatus.Success,
        recommendedListings: payload,
      };
    case FETCH_RECOMMENDED_LISTINGS_ERROR:
      return {
        ...state,
        fetchRecommendedListingsStatus: RequestStatus.Error,
      };

    case FETCH_AUTHOR_LISTINGS_REQUEST:
      return {
        ...state,
        fetchAuthorListingsStatus: RequestStatus.Pending,
        authorListingIds: null,
      };
    case FETCH_AUTHOR_LISTINGS_SUCCESS:
      return {
        ...state,
        fetchAuthorListingsStatus: RequestStatus.Success,
        authorListingIds: payload,
      };
    case FETCH_AUTHOR_LISTINGS_ERROR:
      return {
        ...state,
        fetchAuthorListingsStatus: RequestStatus.Error,
      };

    case FETCH_CONSOLIDATED_LISTINGS_REQUEST:
      return {
        ...state,
        fetchConsolidatedListingsStatus: RequestStatus.Pending,
        consolidatedListings: null,
      };
    case FETCH_CONSOLIDATED_LISTINGS_SUCCESS:
      return {
        ...state,
        fetchConsolidatedListingsStatus: RequestStatus.Success,
        consolidatedListings: payload,
      };
    case FETCH_CONSOLIDATED_LISTINGS_ERROR:
      return {
        ...state,
        fetchConsolidatedListingsStatus: RequestStatus.Error,
        consolidatedListings: [],
      };

    default:
      return state;
  }
};

export default listingPageReducer;

// ================ Action creators ================ //

export const setInitialValues = (initialValues) => ({
  type: SET_INITIAL_VALUES,
  payload: pick(initialValues, Object.keys(initialState)),
});

export const setHasFirstMediaSrcLoaded = () => ({ type: SET_HAS_FIRST_MEDIA_SRC_LOADED });

export const showListingRequest = (id) => ({
  type: SHOW_LISTING_REQUEST,
  payload: { id },
});

export const showListingError = (e) => ({
  type: SHOW_LISTING_ERROR,
  error: true,
  payload: e,
});

export const fetchTimeSlotsRequest = () => ({ type: FETCH_TIME_SLOTS_REQUEST });
export const fetchTimeSlotsSuccess = (timeSlots) => ({
  type: FETCH_TIME_SLOTS_SUCCESS,
  payload: timeSlots,
});
export const fetchTimeSlotsError = (error) => ({
  type: FETCH_TIME_SLOTS_ERROR,
  error: true,
  payload: error,
});

export const fetchLineItemsRequest = () => ({ type: FETCH_LINE_ITEMS_REQUEST });
export const fetchLineItemsSuccess = (lineItems) => ({
  type: FETCH_LINE_ITEMS_SUCCESS,
  payload: lineItems,
});
export const fetchLineItemsError = (error) => ({
  type: FETCH_LINE_ITEMS_ERROR,
  error: true,
  payload: error,
});

export const sendEnquiryRequest = () => ({ type: SEND_ENQUIRY_REQUEST });
export const sendEnquirySuccess = () => ({ type: SEND_ENQUIRY_SUCCESS });
export const sendEnquiryError = (e) => ({ type: SEND_ENQUIRY_ERROR, error: true, payload: e });

export const fetchRecommendedListingsRequest = () => ({ type: FETCH_RECOMMENDED_LISTINGS_REQUEST });
export const fetchRecommendedListingsSuccess = (listings) => ({
  type: FETCH_RECOMMENDED_LISTINGS_SUCCESS,
  payload: listings,
});
export const fetchRecommendedListingsError = (e) => ({
  type: FETCH_RECOMMENDED_LISTINGS_ERROR,
  error: true,
  payload: e,
});

export const fetchAuthorListingsRequest = () => ({ type: FETCH_AUTHOR_LISTINGS_REQUEST });
export const fetchAuthorListingsSuccess = (listingIds) => ({
  type: FETCH_AUTHOR_LISTINGS_SUCCESS,
  payload: listingIds,
});
export const fetchAuthorListingsError = (e) => ({
  type: FETCH_AUTHOR_LISTINGS_ERROR,
  error: true,
  payload: e,
});

export const fetchConsolidatedListingsRequest = () => ({
  type: FETCH_CONSOLIDATED_LISTINGS_REQUEST,
});
export const fetchConsolidatedListingsSuccess = (listings) => ({
  type: FETCH_CONSOLIDATED_LISTINGS_SUCCESS,
  payload: listings,
});
export const fetchConsolidatedListingsError = (e) => ({
  type: FETCH_CONSOLIDATED_LISTINGS_ERROR,
  error: true,
  payload: e,
});

// ================ Thunks ================ //

export const showListing =
  (listingId, isOwn = false) =>
  (dispatch, getState, sdk) => {
    const { authModalTrigger } = getState().Auth;

    dispatch(showListingRequest(listingId));

    if (!authModalTrigger) {
      // If the auth modal is open when this function gets called in loadData, that means
      // the customer is signing in, so the current user is already being fetched. We
      // shouldn't fetch it again because fetching the current user twice could lead the
      // shopping bag count to be overwritten if one of the fetches finishes after we've
      // already updated the shopping bag count.
      dispatch(fetchCurrentUser());
    }

    const params = {
      id: listingId,
      include: ['author', 'author.profileImage', 'images'],
      'fields.image': [
        // Listing page
        'variants.landscape-crop',
        'variants.landscape-crop2x',
        'variants.landscape-crop4x',
        'variants.landscape-crop6x',
        'variants.default',

        // Social media
        'variants.facebook',
        'variants.twitter',

        // Image carousel
        'variants.scaled-small',
        'variants.scaled-medium',
        'variants.scaled-large',
        'variants.scaled-xlarge',

        // Avatars
        'variants.square-small',
        'variants.square-small2x',
      ],
    };

    const show = isOwn ? sdk.ownListings.show(params) : sdk.listings.show(params);

    return show
      .then((data) => {
        dispatch(addMarketplaceEntities(data));
        return data;
      })
      .catch((e) => {
        dispatch(showListingError(storableError(e)));
      });
  };

const timeSlotsRequest = (params) => (dispatch, getState, sdk) =>
  sdk.timeslots.query(params).then((response) => denormalisedResponseEntities(response));

export const fetchTimeSlots = (listingId) => (dispatch) => {
  dispatch(fetchTimeSlotsRequest);

  // Time slots can be fetched for 90 days at a time,
  // for at most 180 days from now. If max number of bookable
  // day exceeds 90, a second request is made.

  const maxTimeSlots = 90;
  // booking range: today + bookable days -1
  const bookingRange = config.dayCountAvailableForBooking - 1;
  const timeSlotsRange = Math.min(bookingRange, maxTimeSlots);

  const start = moment.utc().startOf('day').toDate();
  const end = moment().utc().startOf('day').add(timeSlotsRange, 'days').toDate();
  const params = { listingId, start, end };

  return dispatch(timeSlotsRequest(params))
    .then((timeSlots) => {
      const secondRequest = bookingRange > maxTimeSlots;

      if (secondRequest) {
        const secondRange = Math.min(maxTimeSlots, bookingRange - maxTimeSlots);
        const secondParams = {
          listingId,
          start: end,
          end: moment(end).add(secondRange, 'days').toDate(),
        };

        return dispatch(timeSlotsRequest(secondParams)).then((secondBatch) => {
          const combined = timeSlots.concat(secondBatch);
          dispatch(fetchTimeSlotsSuccess(combined));
        });
      }
      return dispatch(fetchTimeSlotsSuccess(timeSlots));
    })
    .catch((e) => {
      dispatch(fetchTimeSlotsError(storableError(e)));
    });
};

export const fetchRecommendedListings = (listing) => async (dispatch, getState, sdk) => {
  dispatch(fetchRecommendedListingsRequest());
  const isClosed = listing.attributes.state === LISTING_STATE_CLOSED;
  const { treetId } = getState().initial;
  const userState = getState().user;
  const { currentUser } = userState;
  const [recommendedListingsResponse, recommendedListingsError] = await handle(
    recommendedListingsApiRequest({
      listingId: listing.id.uuid,
      numListings: NUM_RECOMMENDED_LISTINGS,
      type: isClosed ? RecommendedListingsType.Popular : RecommendedListingsType.Related,
      subdomain: treetId,
      userId: currentUser?.id.uuid,
    })
  );

  if (recommendedListingsError) {
    // Fail gracefully, already logged server side.
    return dispatch(fetchRecommendedListingsError(storableError(recommendedListingsError)));
  }

  if (!recommendedListingsResponse?.matching_products) {
    return dispatch(fetchRecommendedListingsError('No recommended listings returned for listing.'));
  }

  const recommendedListingIds = recommendedListingsResponse.matching_products;
  const response = await sdk.listings.query({
    include: ['author', 'images'],
    'fields.image': ['variants.default'],
    'limit.images': 1,
    ids: recommendedListingIds,
  });

  let recommendedListings = denormalisedResponseEntities(response);
  recommendedListings = orderIdsByList(recommendedListings, recommendedListingIds);

  recommendedListings = recommendedListings.filter((recommendedListing) => {
    const authorAvailable = recommendedListing.author;
    const userAndListingAuthorAvailable = !!(currentUser && authorAvailable);
    const isOwnListing =
      userAndListingAuthorAvailable && recommendedListing.author.id.uuid === currentUser.id.uuid;

    const shoppingBagListingIds = userState.cartListingIds || [];
    const isAlreadyBagged = shoppingBagListingIds.includes(recommendedListing.id.uuid);
    return !(isOwnListing || isAlreadyBagged);
  });

  dispatch(addMarketplaceEntities(response));
  return dispatch(fetchRecommendedListingsSuccess(recommendedListings));
};

export const fetchAuthorListings = (authorId) => async (dispatch, getState, sdk) => {
  dispatch(fetchAuthorListingsRequest());
  const { treetId, shopConfig: shopConfigV2 } = getState().initial;
  const { shopName } = getShopConfig(treetId, shopConfigV2);

  try {
    const response = await sdk.listings.query({
      author_id: authorId,
      pub_shopName: shopName,
      pub_listingItemType: ListingItemType.Marketplace,
    });
    const listingIds = denormalisedResponseEntities(response).map((listing) => listing.id.uuid);
    dispatch(fetchAuthorListingsSuccess(listingIds));
  } catch (e) {
    dispatch(fetchAuthorListingsError());
    log.error(e, 'fetch-author-listings-failed', { authorId });
  }
};

export const fetchConsolidatedListings =
  (currentListing, variantOptions) => async (dispatch, getState, sdk) => {
    dispatch(fetchConsolidatedListingsRequest());
    const { treetId, shopConfig: shopConfigV2 } = getState().initial;
    const { shopName } = getShopConfig(treetId, shopConfigV2);
    const shopifyProductId = currentListing?.attributes?.publicData?.shopifyProductId;

    try {
      if (!shopifyProductId) {
        /* 
        When the shopifyProductId is null, return an empty list so that consolidated listings
        are explicitly reset correctly on each new listing page load. 
      */
        dispatch(fetchConsolidatedListingsSuccess([]));
        return [];
      }
      const response = await sdk.listings.query({
        pub_shopName: shopName,
        pub_shopifyProductId: shopifyProductId,
        pub_listingItemType: ListingItemType.Marketplace,
        pub_availability: ITEM_AVAILABILITY_AVAILABLE,
      });
      const listings = denormalisedResponseEntities(response).sort(
        sortListings(currentListing, variantOptions)
      );
      dispatch(addMarketplaceEntities(response));
      dispatch(fetchConsolidatedListingsSuccess(listings));
      return listings;
    } catch (e) {
      log.error(e, 'fetch-consolidated-listings-failed', { shopifyProductId, treetId });
      dispatch(fetchConsolidatedListingsError());
      return [];
    }
  };

export const loadData = (params) => (dispatch) => {
  const listingId = new UUID(params.id);

  const ownListingVariants = [LISTING_PAGE_DRAFT_VARIANT, LISTING_PAGE_PENDING_APPROVAL_VARIANT];
  if (ownListingVariants.includes(params.variant)) {
    return Promise.all([dispatch(showListing(listingId, true))]);
  }

  return dispatch(showListing(listingId));
};
