/* eslint-disable no-nested-ternary */
/* eslint-disable import/prefer-default-export */
import { useEnabledCustomerExperiences } from './useEnabledCustomerExperiences';

export const useActionCopy = () => {
  const { allowTradeInAndMarketplace, isBuyAndTradeInOnly } = useEnabledCustomerExperiences();
  const listActionCopy = allowTradeInAndMarketplace
    ? 'Sell or Trade In'
    : isBuyAndTradeInOnly
    ? 'Trade In'
    : 'Sell Your Item';

  return {
    listActionCopy,
  };
};
