/* eslint-disable jsx-a11y/label-has-associated-control */
import { useLocation } from 'react-router-dom';
import { Box, Grid } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import debounce from 'lodash/debounce';
import isEmpty from 'lodash/isEmpty';
import React, { FC, useCallback, useContext, useEffect, useState } from 'react';
import { useForm, useFormState } from 'react-final-form';
import { OnChange } from 'react-final-form-listeners';
import InfiniteScroll from 'react-infinite-scroll-component';
import {
  Button,
  Empty,
  FieldSelect,
  FieldTextInput,
  IconSpinner,
  InlineTextButton,
  TypographyWrapper,
} from '..';
import {
  canProductBeShown,
  getProductVariantQuery,
  ShopifyProductsSearchFormField,
  shouldFillVariantOption,
} from './Shopify.utils';
import { useShopConfig, useShopConfigV2 } from '../../hooks/shopConfig';
import { ProductEdge, Product as ShopifyProduct } from '../../types/shopify/product';
import { FindItemMethod } from '../../util/listings/listing';
import {
  buildAdditionalProductQueryFromTagsAndProductTypes,
  filterDefaultShopifyOptions,
  getCategories,
} from '../../util/shopifyHelpers';
import Product from './Product';
import { useCurrentUserPermissions } from '../../hooks/useUserPermissions';
import { useEnabledCustomerExperiences } from '../../hooks/useEnabledCustomerExperiences';
import { useBrandCountryConfig } from '../../hooks/useCountryConfig';
import { parse } from '../../util/urlHelpers';
import CannotFindItemLink from '../../containers/EditListingPage/CannotFindItemLink';
import { useIsMobile } from '../../hooks/useIsMobile';
import { useAllowedFindItemMethods } from '../../hooks/useAllowedFindItemMethods';
import { TypographyWeight } from '../TypographyWrapper/TypographyWrapper';
import { fetchProductById, fetchProductsByTag, fetchShopifyVariantByQuery } from '../../util/api';
import { useLazyApi } from '../../hooks/useLazyApi';
import AppContext from '../../context/AppContext';
import css from './Shopify.module.css';

const ClearSearchLink: FC = () => {
  const form = useForm();

  const handleClick = () => {
    form.change(ShopifyProductsSearchFormField.Category, undefined);
    form.change(ShopifyProductsSearchFormField.Categories, undefined);
    form.change(ShopifyProductsSearchFormField.Search, undefined);
    form.change(ShopifyProductsSearchFormField.Sku, undefined);
    form.change(ShopifyProductsSearchFormField.IsSearchActive, false);
  };

  return (
    <InlineTextButton className={css.link} onClick={handleClick}>
      <TypographyWrapper component="span" weight={TypographyWeight.Medium} variant="body2">
        Clear all filters
      </TypographyWrapper>
    </InlineTextButton>
  );
};

interface CategorySelectorProps {
  onFilterChange: (category: string) => void;
}

const CategorySelector: FC<CategorySelectorProps> = (props) => {
  const { onFilterChange } = props;
  const { categoriesConfig } = useShopConfigV2();
  const categories = categoriesConfig?.categoriesCollection?.items || [];

  return (
    <>
      <FieldSelect
        id={ShopifyProductsSearchFormField.Category}
        name={ShopifyProductsSearchFormField.Category}
        className={css.searchCategory}
        selectClassName={css.searchCategory}
        label="Filter by Category"
        tabIndex={0}
      >
        <option value="">All Categories</option>
        {categories.map((c: any) => (
          <option key={c.key} value={c.key}>
            {c.label}
          </option>
        ))}
      </FieldSelect>
      <OnChange name={ShopifyProductsSearchFormField.Category}>
        {(value) => onFilterChange(value)}
      </OnChange>
    </>
  );
};

interface SearchProps {
  onSearchChange: (value?: string) => void;
}

const Search: FC<SearchProps> = (props) => {
  const { onSearchChange } = props;

  const isMobile = useIsMobile();
  const placeholderText = isMobile
    ? 'Search for an item'
    : 'Search for items using product name, color, or item description';

  return (
    <Box width="100%" pb={2}>
      <label htmlFor={ShopifyProductsSearchFormField.Search} className={css.srOnly}>
        Search
      </label>
      <FieldTextInput
        id={ShopifyProductsSearchFormField.Search}
        name={ShopifyProductsSearchFormField.Search}
        placeholder={placeholderText}
      />
      <OnChange name={ShopifyProductsSearchFormField.Search}>
        {(value) => onSearchChange(value)}
      </OnChange>
    </Box>
  );
};

const SearchBySku: FC<SearchProps> = (props) => {
  const { onSearchChange } = props;

  return (
    <Box width="100%" pb={2}>
      <FieldTextInput
        id={ShopifyProductsSearchFormField.Sku}
        name={ShopifyProductsSearchFormField.Sku}
        placeholder="Enter SKU"
      />
      <OnChange name={ShopifyProductsSearchFormField.Sku}>
        {(value) => onSearchChange(value)}
      </OnChange>
    </Box>
  );
};

const Loading: FC = () => (
  <Box display="flex" justifyContent="center" pb={4}>
    <IconSpinner />
  </Box>
);

interface ResultsProps {
  isLoading: boolean;
  hasNoResults: boolean;
  hasMoreProductsToFetch: boolean;
  products: ProductEdge[];
  handleFetchMore: () => void;
  handleProductChange: (shopifyProduct?: ShopifyProduct) => void;
}

interface EmptyStateProps {
  shouldAllowCannotFind: boolean;
}

const EmptyState: FC<EmptyStateProps> = (props) => {
  const { shouldAllowCannotFind } = props;
  return (
    <Box width="fit-content" margin="auto">
      <Empty
        text="No results found"
        button={
          shouldAllowCannotFind && (
            <CannotFindItemLink>
              <Button>Add Item Manually</Button>
            </CannotFindItemLink>
          )
        }
      />
    </Box>
  );
};

const Results: FC<ResultsProps> = (props) => {
  const {
    isLoading,
    hasNoResults,
    hasMoreProductsToFetch,
    products,
    handleFetchMore,
    handleProductChange,
  } = props;

  const { values } = useFormState();
  const { shopifyProduct } = values;
  const { shouldAllowCannotFind } = useAllowedFindItemMethods();

  const isSearchActive = values[ShopifyProductsSearchFormField.IsSearchActive];

  if (!isSearchActive) return null;

  return (
    <Box pt={4} width="100%">
      {isLoading && <Loading />}
      {!isLoading && hasNoResults && <EmptyState shouldAllowCannotFind={shouldAllowCannotFind} />}
      {!isLoading && isSearchActive && products?.length > 0 && (
        <InfiniteScroll
          dataLength={products.length}
          next={handleFetchMore}
          hasMore={hasMoreProductsToFetch}
          loader={<Loading />}
          scrollableTarget="scrollableDiv"
        >
          <Grid
            // This removes the horizontal scroll
            style={{ margin: 0, width: '100%' }}
            container
            spacing={3}
          >
            {products.map((product) => (
              <Product
                key={product.node.id}
                product={product.node}
                selectedProductId={shopifyProduct?.id}
                onChange={handleProductChange}
              />
            ))}
          </Grid>
        </InfiniteScroll>
      )}
    </Box>
  );
};

const ShopifyProductSearch: FC = () => {
  const [isSearching, setIsSearching] = useState<boolean>(false);

  const form = useForm();
  const { values } = useFormState();
  const { isBrand } = useCurrentUserPermissions();
  const { allowTradeIn } = useEnabledCustomerExperiences();
  const { countryCode } = useBrandCountryConfig();

  const { category: formCategory, search: formSearch, sku: formSku } = values;

  const shopConfig = useShopConfig();
  const { additionalProductQuery, blockedTags, blockedProductTypes, listingFlowConfig, shopId } =
    shopConfig;
  const { allowedFindItemMethods, showCategoriesOnSearchFlow } = listingFlowConfig;

  const { treetId } = useContext(AppContext);
  const { categoriesConfig } = useShopConfigV2();
  const categories = categoriesConfig?.categoriesCollection?.items || [];
  const fullProductQuery = buildAdditionalProductQueryFromTagsAndProductTypes(
    additionalProductQuery,
    blockedTags,
    blockedProductTypes
  );

  const isSearchActive = values[ShopifyProductsSearchFormField.IsSearchActive];

  const location = useLocation();
  const { listingItemType: listingItemTypeFromUrl } = parse(location.search);

  const fetchProductsByTagUpdateQuery = (previousResult?: any, fetchMoreResult?: any) => ({
    ...previousResult,
    products: {
      ...previousResult?.products,
      pageInfo: {
        ...fetchMoreResult?.products.pageInfo,
      },
      edges: [
        ...(previousResult?.products.edges || []),
        ...(fetchMoreResult?.products.edges || []),
      ],
    },
  });

  const {
    lazyQuery: lazyFetchProductsByTag,
    loading: areProductsByTagLoading,
    data: productsByTag,
  } = useLazyApi(fetchProductsByTag, fetchProductsByTagUpdateQuery);

  const { lazyQuery: lazyFetchProductById, data: productById } = useLazyApi(fetchProductById);

  const { lazyQuery: lazyFetchVariantByQuery, data: productVariantByQueryData } = useLazyApi(
    fetchShopifyVariantByQuery
  );

  useEffect(() => {
    const productVariant = productVariantByQueryData?.productVariants?.edges?.[0]?.node;
    if (productVariant) {
      form.change('shopifyProductVariant', productVariant);

      const itemVariantOptions = productVariant?.selectedOptions.reduce(
        (acc: { [option: string]: string }, option: any) => {
          if (shouldFillVariantOption(option, shopConfig)) {
            return { ...acc, [option.name.toLowerCase()]: option.value };
          }
          return acc;
        },
        {}
      );
      form.change('selectedVariantOptions', itemVariantOptions);
    }
  }, [productVariantByQueryData]);

  const getCategoryQuery = (category?: string) => {
    if (!category) return null;

    const categoryTags = categories?.find((c: any) => c.key === category)?.tags;
    const query = categoryTags?.reduce((result: string, tag: string, index: number) => {
      let newResult = result;
      const newValue =
        index === 0
          ? `product_type:"${tag}" OR tag:"${tag}"`
          : ` OR product_type:"${tag}" OR tag:"${tag}"`;

      newResult += newValue;
      return newResult;
    }, '');

    return `(${query})`;
  };

  const buildQuery = (filterChangeParams: { category?: string; search?: string; sku?: string }) => {
    const { search, category, sku } = filterChangeParams;
    const categoryQuery = getCategoryQuery(category);
    const searchQuery = search && `*${search}*`;
    let query = [categoryQuery, searchQuery, fullProductQuery].filter((q) => q).join(' AND ');
    if (isBrand && sku) {
      // Allow brands and partners (e.g. Retail Reworks) to search directly by SKU
      query = `(${query}) OR sku: *${sku}*`;
    }
    return query;
  };

  const handleFilterChange = (filterChangeParams: {
    category?: string;
    search?: string;
    sku?: string;
  }) => {
    const { search, category } = filterChangeParams;
    const query = buildQuery(filterChangeParams);
    const isSearchOrCategoryActive = !!search || !!category;
    const hasSearchQuery = !!search;
    form.change(ShopifyProductsSearchFormField.IsSearchActive, isSearchOrCategoryActive);

    lazyFetchProductsByTag({
      treetId,
      query,
      sortKey: hasSearchQuery ? 'RELEVANCE' : 'TITLE',
    });
  };

  useEffect(() => {
    if (formCategory || formSearch || formSku) {
      handleFilterChange({ category: formCategory, search: formSearch, sku: formSku });
    }
  }, []);

  useEffect(() => {
    if (!productById) return;
    const { product } = productById;
    form.change('shopifyProduct', product);
  }, [productById]);

  useEffect(() => {
    if (isSearching && !areProductsByTagLoading) {
      setIsSearching(false);
    }
  }, [areProductsByTagLoading]);

  // Only search if user has not typed for 0.8 seconds
  const debouncedSearch = useCallback(
    debounce((value) => {
      handleFilterChange(value);
    }, 800),
    []
  );

  /**
   * Shopify API can only do fuzzy tag search and not exact tag search, so we must
   * post-process the result to make sure we don't include any products that don't
   * have an exact tag match. This function does a second pass to make sure that the
   * product actually belongs to the category.
   * https://community.shopify.com/c/Shopify-APIs-SDKs/GraphQL-Admin-API-Search-Products-by-Tag/td-p/511216
   */
  const isProductInCategory = (product: ShopifyProduct, selectedCategory?: string) => {
    if (!selectedCategory) return true;
    const productCategories = getCategories(categories, product);
    return productCategories.includes(selectedCategory);
  };

  const handleFetchMore = () => {
    const cursor = productsByTag?.products?.edges?.slice(-1)[0]?.cursor;
    const query = buildQuery({ category: formCategory, search: formSearch, sku: formSku });
    const hasSearchQuery = !!formSearch;

    lazyFetchProductsByTag({
      treetId,
      query,
      cursor,
      sortKey: hasSearchQuery ? 'RELEVANCE' : 'TITLE',
    });
  };

  const handleChange = async (shopifyProduct?: ShopifyProduct) => {
    lazyFetchProductById({
      treetId,
      countryCode,
      productId: shopifyProduct?.id,
      shouldIncludeTradeInPriceMetafield: allowTradeIn,
    });

    const productVariantOptions = filterDefaultShopifyOptions(shopifyProduct?.options);
    const hasNoVariantOptions = isEmpty(productVariantOptions);

    if (hasNoVariantOptions && shopifyProduct?.id) {
      const query = getProductVariantQuery([], {}, shopifyProduct.id);
      lazyFetchVariantByQuery({
        queryString: query,
        countryCode,
        subdomain: shopId,
      });
    }

    if (isBrand && formSku) {
      lazyFetchVariantByQuery({
        queryString: `*${formSku}*`,
        countryCode,
        subdomain: shopId,
      });
    }

    if (!formCategory) {
      form.change(ShopifyProductsSearchFormField.Category, undefined);
      form.change(ShopifyProductsSearchFormField.Categories, undefined);
    }

    form.change('condition', undefined);
    form.change('emailOrPhoneForOrder', undefined);
    form.change('lineItemId', undefined);
    form.change('orderConfirmationNumber', undefined);
    form.change('shopifyProductVariant', undefined);
    form.change('tags', undefined);
    form.change('selectedVariantOptions', undefined);
    form.change('userGeneratedTitle', undefined);
    form.change('findItemMethod', FindItemMethod.Search);
    form.change('shopifyProduct', undefined);
  };

  const filteredProducts = productsByTag?.products?.edges?.filter(
    (product: ProductEdge) =>
      canProductBeShown(product.node, shopConfig) && isProductInCategory(product.node, formCategory)
  );
  const isLoading =
    isSearching || (isSearchActive && areProductsByTagLoading && isEmpty(filteredProducts));
  const hasNextPage = productsByTag?.products?.pageInfo?.hasNextPage;
  const hasNoResults = isSearchActive && !areProductsByTagLoading && isEmpty(filteredProducts);
  const hasCategoriesAndShouldShowCategories = !isEmpty(categories) && showCategoriesOnSearchFlow;
  const shouldAllowCannotFind = allowedFindItemMethods.includes(FindItemMethod.CannotFind);

  return (
    <Box className={css.searchWrapper}>
      {isBrand && (
        <Box pb={2} width="100%">
          <Typography variant="body1">Search By SKU:</Typography>
          <SearchBySku
            onSearchChange={(value?: string) => {
              setIsSearching(true);
              form.change(ShopifyProductsSearchFormField.IsSearchActive, true);
              debouncedSearch({
                search: value,
                sku: value,
                page: 0,
              });
            }}
          />
        </Box>
      )}
      <Grid container>
        {hasCategoriesAndShouldShowCategories && (
          <Grid item xs={12} sm={6} md={4}>
            <Box mr={{ sm: 2 }} pb={2} height="100%">
              <CategorySelector
                onFilterChange={(category: string) =>
                  handleFilterChange({ category, search: formSearch })
                }
              />
            </Box>
          </Grid>
        )}
        <Grid
          item
          xs={12}
          sm={hasCategoriesAndShouldShowCategories ? 6 : 12}
          md={hasCategoriesAndShouldShowCategories ? 8 : 12}
        >
          <Search
            onSearchChange={(value?: string) => {
              setIsSearching(true);
              form.change(ShopifyProductsSearchFormField.IsSearchActive, true);
              debouncedSearch({
                category: formCategory,
                search: value,
                page: 0,
              });
            }}
          />
        </Grid>
      </Grid>
      <Box display="flex" justifyContent="space-between" alignItems="center" width="100%">
        <Box display="flex">{isSearchActive ? <ClearSearchLink /> : <div />}</Box>
        {shouldAllowCannotFind && <CannotFindItemLink listingItemType={listingItemTypeFromUrl} />}
      </Box>
      <Results
        isLoading={isLoading}
        hasNoResults={hasNoResults}
        hasMoreProductsToFetch={hasNextPage}
        products={filteredProducts}
        handleFetchMore={handleFetchMore}
        handleProductChange={handleChange}
      />
    </Box>
  );
};

export default ShopifyProductSearch;
