import React, { FC } from 'react';
import { Box } from '@material-ui/core';
import {
  IconArrowRight,
  IconCheckCircle,
  IconCheckCircleFilledProgressBar,
  TypographyWrapper,
} from '../index';
import { useIsMobile } from '../../hooks/useIsMobile';
import { useShopCss } from '../../hooks/useShopCss';

import css from './ProgressBar.module.css';
import { defaultTreetStyles } from '../../shopConfig/config';

interface ProgressBarProps {
  displayedSections: { displayName: string; isComplete: boolean }[];
}

interface ProgressBarSectionProps {
  section: { displayName: string; isComplete: boolean };
}

const ProgressBarMobileSection: FC<ProgressBarSectionProps> = (props) => {
  const { section } = props;
  return (
    <>
      {section.isComplete ? (
        <IconCheckCircleFilledProgressBar className={css.sizeIconForMobile} />
      ) : (
        <IconCheckCircle className={css.sizeIconForMobile} />
      )}
      <TypographyWrapper
        variant="body2"
        typographyOverrides={{
          style: {
            lineHeight: 1,
            fontSize: 12,
            paddingRight: 1,
            paddingLeft: 1,
          },
        }}
      >
        {section.displayName.replace('Find Item', 'Item').replace('Item Details', 'Details')}
      </TypographyWrapper>
    </>
  );
};

const ProgressBarDesktopSection: FC<ProgressBarSectionProps> = (props) => {
  const { section } = props;
  return (
    <>
      {section.isComplete ? (
        <IconCheckCircleFilledProgressBar className={css.sizeIconForDesktop} />
      ) : (
        <IconCheckCircle className={css.sizeIconForDesktop} />
      )}
      <TypographyWrapper
        variant="body1"
        typographyOverrides={{
          style: {
            lineHeight: 1,
            paddingRight: 8,
          },
        }}
      >
        {section.displayName}
      </TypographyWrapper>
    </>
  );
};

const ProgressBar: FC<ProgressBarProps> = (props) => {
  const { displayedSections } = props;
  const isMobile = useIsMobile();
  const shopCss = useShopCss();
  const { backgroundColor } = shopCss;

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      bgcolor={backgroundColor}
      className={css.progressBar}
    >
      {displayedSections.map((section, index, allSections) => (
        <React.Fragment key={section.displayName}>
          {isMobile ? (
            <ProgressBarMobileSection section={section} />
          ) : (
            <ProgressBarDesktopSection section={section} />
          )}
          {index < allSections.length - 1 && <IconArrowRight color={defaultTreetStyles.gray40} />}
        </React.Fragment>
      ))}
    </Box>
  );
};

export default ProgressBar;
