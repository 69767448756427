import React from 'react';

interface IconTreetShopLogoProps {
  className?: string;
}

const IconTreetShopLogo: React.FC<IconTreetShopLogoProps> = (props) => {
  const { className } = props;

  return (
    <svg
      className={className}
      width="526"
      height="95"
      viewBox="0 0 526 95"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M37.6574 54.499C37.6574 66.686 45.4571 74.6075 56.5472 74.6075C58.3753 74.6075 59.9596 74.3638 61.3002 73.9982C62.0314 73.8763 62.6407 73.6326 63.2501 73.3888V61.2018L62.2751 61.5674C61.6658 61.6893 60.9346 61.8112 59.594 61.8112C54.7192 61.8112 51.6724 58.7644 51.6724 53.8896V30.7343H63.8594V17.9379H51.6724V4.53223H39.4854V12.4538C39.4854 16.963 37.2918 19.1566 32.7826 19.1566H29.1265V30.7343H37.6574V54.499Z"
        fill="#1E3D3F"
      />
      <path
        d="M75.4038 73.9982H89.4188V44.14C89.4188 36.8278 94.1718 31.953 100.996 31.953C102.459 31.953 103.678 32.1967 104.531 32.3186L105.871 32.5624V17.9379L104.653 17.6942C103.8 17.5723 102.946 17.3286 101.606 17.3286C97.3404 17.3286 94.0499 18.9129 91.7344 20.741C90.3938 21.7159 89.1751 22.8127 88.2001 24.0314L85.7627 17.9379H75.4038V73.9982Z"
        fill="#1E3D3F"
      />
      <path
        d="M150.378 56.9364C149.768 57.7895 148.915 58.5207 147.94 59.13C146.112 60.2269 143.797 61.2018 140.019 61.2018C132.707 61.2018 128.563 56.9364 127.222 51.4522H166.221L166.465 49.6242C166.586 48.5273 166.83 47.3086 166.83 45.9681C166.83 28.9062 154.643 17.3286 140.019 17.3286C124.907 17.3286 111.989 30.1249 111.989 45.9681C111.989 61.8112 124.907 74.6075 140.019 74.6075C152.693 74.6075 162.077 65.3454 165.002 56.9364H150.378ZM126.613 41.7026C127.954 35.4872 132.707 30.7343 140.019 30.7343C147.331 30.7343 152.084 35.4872 153.424 41.7026H126.613Z"
        fill="#1E3D3F"
      />
      <path
        d="M212.503 56.9364C211.894 57.7895 211.04 58.5207 210.065 59.13C208.237 60.2269 205.922 61.2018 202.144 61.2018C194.832 61.2018 190.688 56.9364 189.348 51.4522H228.346L228.59 49.6242C228.712 48.5273 228.955 47.3086 228.955 45.9681C228.955 28.9062 216.768 17.3286 202.144 17.3286C187.032 17.3286 174.114 30.1249 174.114 45.9681C174.114 61.8112 187.032 74.6075 202.144 74.6075C214.818 74.6075 224.202 65.3454 227.127 56.9364H212.503ZM188.738 41.7026C190.079 35.4872 194.832 30.7343 202.144 30.7343C209.456 30.7343 214.209 35.4872 215.55 41.7026H188.738Z"
        fill="#1E3D3F"
      />
      <path
        d="M243.551 54.499C243.551 66.686 251.351 74.6075 262.441 74.6075C264.269 74.6075 265.853 74.3638 267.194 73.9982C267.925 73.8763 268.535 73.6326 269.144 73.3888V61.2018L268.169 61.5674C267.56 61.6893 266.828 61.8112 265.488 61.8112C260.613 61.8112 257.566 58.7644 257.566 53.8896V30.7343H269.753V17.9379H257.566V4.53223H245.379V12.4538C245.379 16.963 243.186 19.1566 238.676 19.1566H235.02V30.7343H243.551V54.499Z"
        fill="#1E3D3F"
      />
      <path
        d="M28.2802 39.2988C16.1275 39.2988 6.27588 49.1505 6.27588 61.3031V61.3031L7.1222 61.3031C19.2748 61.3031 29.1265 51.4515 29.1265 39.2988V39.2988L28.2802 39.2988Z"
        fill="#437073"
      />
      <path
        d="M20.5081 28.0729C13.0227 24.2319 3.84084 27.1863 -0.000169064 34.6717V34.6717L0.752804 35.0581C8.23822 38.8991 17.4201 35.9447 21.2611 28.4593V28.4593L20.5081 28.0729Z"
        fill="#437073"
      />
      <path
        d="M21.135 18.8351C21.264 12.0377 15.8583 6.42273 9.06086 6.29367V6.29367L9.04788 6.97744C8.91883 13.7748 14.3246 19.3898 21.122 19.5189V19.5189L21.135 18.8351Z"
        fill="#437073"
      />
      <path
        d="M292.118 58.298C293.199 69.2364 300.532 76.3284 314.956 76.3284C327.457 76.3284 335.39 68.5152 335.39 59.5001C335.39 39.7869 307.143 44.3546 307.143 36.6617C307.143 33.6566 309.547 31.2526 313.754 31.2526C317.961 31.2526 320.966 34.2576 321.567 36.6617H334.789C333.467 27.0455 326.495 19.8334 313.153 19.8334C301.133 19.8334 293.32 27.6465 293.32 36.6617C293.32 55.7738 321.567 51.0859 321.567 58.8991C321.567 61.9041 318.562 64.9092 313.754 64.9092C308.946 64.9092 305.941 62.0243 305.34 58.298H292.118Z"
        fill="#437073"
      />
      <path
        d="M345.598 75.7274H359.421V45.0758C359.421 37.8637 364.109 33.0556 370.841 33.0556C377.572 33.0556 382.26 37.8637 382.26 45.0758V75.7274H396.083V43.2728C396.083 28.8485 387.068 19.8334 375.048 19.8334C369.639 19.8334 365.672 21.396 363.148 22.8384C361.705 23.6798 360.503 24.7616 359.421 25.8435V0H345.598V75.7274Z"
        fill="#437073"
      />
      <path
        d="M433.393 33.0556C441.206 33.0556 447.216 38.9455 447.216 48.0809C447.216 57.2162 441.206 63.1061 433.393 63.1061C425.58 63.1061 419.57 57.2162 419.57 48.0809C419.57 38.9455 425.58 33.0556 433.393 33.0556ZM433.393 19.8334C418.488 19.8334 405.746 32.4546 405.746 48.0809C405.746 63.7071 418.488 76.3284 433.393 76.3284C448.298 76.3284 461.039 63.7071 461.039 48.0809C461.039 32.4546 448.298 19.8334 433.393 19.8334Z"
        fill="#437073"
      />
      <path
        d="M471.2 94.9597H485.023V70.9193C486.105 72.0011 487.427 72.8425 488.87 73.6839C491.394 75.0061 494.76 76.3284 499.448 76.3284C513.271 76.3284 525.291 64.4284 525.291 48.0809C525.291 31.7334 513.271 19.8334 499.448 19.8334C494.64 19.8334 490.793 21.396 488.028 22.8384C486.466 23.6798 485.023 24.7616 483.821 25.8435L481.417 20.4344H471.2V94.9597ZM497.645 63.1061C489.831 63.1061 483.821 57.2162 483.821 48.0809C483.821 38.9455 489.831 33.0556 497.645 33.0556C505.458 33.0556 511.468 38.9455 511.468 48.0809C511.468 57.2162 505.458 63.1061 497.645 63.1061Z"
        fill="#437073"
      />
    </svg>
  );
};

export default IconTreetShopLogo;
