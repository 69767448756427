/* eslint-disable import/prefer-default-export */
import { CountryCode } from '../types/apollo/generated/types.generated';
import { Feature } from '../util/featureFlags';
import { useShopConfig } from './shopConfig';
import { useBrandCountryConfig } from './useCountryConfig';
import { useFeatureFlags } from './useFeatureFlags';

export const useReturnInsurance = () => {
  const isReturnInsuranceEnabled = useFeatureFlags(Feature.SeelReturnInsurance);
  const { shouldDisableSeel } = useShopConfig();
  const { allowedShippingDestinationCountries } = useBrandCountryConfig();

  const canShipToUS = allowedShippingDestinationCountries
    .map((codeAndName) => codeAndName.code)
    .includes(CountryCode.Us);

  return {
    shouldOfferReturnInsurance: isReturnInsuranceEnabled && canShipToUS && !shouldDisableSeel,
  };
};
