/* eslint-disable no-underscore-dangle */
import Decimal from 'decimal.js';
import { types as sdkTypes } from '../sdkLoader';

export const clearData = (storageKey: string) => {
  if (typeof window !== 'undefined' && window?.sessionStorage) {
    window.sessionStorage.removeItem(storageKey);
  }
};

export const storeData = (storageKey: string, data: any) => {
  if (typeof window !== 'undefined' && window?.sessionStorage && data) {
    const replacer = (k: any, v: any) => {
      if (v instanceof Date) {
        return { date: v, _serializedType: 'SerializableDate' };
      }
      if (v instanceof Decimal) {
        return { decimal: v, _serializedType: 'SerializableDecimal' };
      }
      return sdkTypes.replacer(k, v);
    };

    const storableData = JSON.stringify(data, replacer);
    window.sessionStorage.setItem(storageKey, storableData);
  }
};

export const getStoredData = (storageKey: string) => {
  if (typeof window !== 'undefined' && window?.sessionStorage) {
    const data = window.sessionStorage.getItem(storageKey);

    const reviver = (k: any, v: any) => {
      if (v && typeof v === 'object' && v._serializedType === 'SerializableDate') {
        // Dates are expected to be stored as:
        // { date: new Date(), _serializedType: 'SerializableDate' }
        return new Date(v.date);
      }

      if (v && typeof v === 'object' && v._serializedType === 'SerializableDecimal') {
        // Decimals are expected to be stored as:
        // { decimal: v, _serializedType: 'SerializableDecimal' }
        return new Decimal(v.decimal);
      }
      return sdkTypes.reviver(k, v);
    };

    return data ? JSON.parse(data, reviver) : {};
  }
  return {};
};
