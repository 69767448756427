import { getDefaultShopConfig } from './default';
import * as custom from '../filters/marketplace-custom-config';
import { getBrandDirectFilterConfig } from '../filters/brandDirect';
import { getConditionsFilterConfig } from '../filters/condition';
import { getSizeFilterConfigFromOptions } from '../filters/size';

const quincymaeSizeOptions = [
  { key: 'NB', label: 'NB' },
  { key: '0-3M', label: '0-3M' },
  { key: '3-6M', label: '3-6M' },
  { key: '6-12M', label: '6-12M' },
  { key: '12-18M', label: '12-18M' },
  { key: '12-24M', label: '12-24M' },
  { key: '18-24M', label: '18-24M' },
  { key: '2-3Y', label: '2-3Y' },
  { key: '4-5Y', label: '4-5Y' },
  { key: '6-7Y', label: '6-7Y' },
  { key: '8-9Y', label: '8-9Y' },
  { key: '10-12Y', label: '10-12Y' },
  { key: '12-14Y', label: '12-14Y' },
  { key: 'ONESIZE', label: 'One Size' },
];

export const quincymaeShopConfig = {
  ...getDefaultShopConfig(),
  filters: [
    ...custom.filters,
    getBrandDirectFilterConfig(),
    getSizeFilterConfigFromOptions({ options: quincymaeSizeOptions }),
    getConditionsFilterConfig(),
  ],
};
