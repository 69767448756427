import { getSizeFilterConfig } from '../filters/size';
import { getConditionsFilterConfig } from '../filters/condition';
import { getDefaultShopConfig } from './default';
import * as custom from '../filters/marketplace-custom-config';
import { getBrandDirectFilterConfig } from '../filters/brandDirect';

const thebeeandthefoxSizeOptions = [
  'Newborn',
  '6 months',
  '12 month',
  '18 month',
  'NB',
  '24M',
  '6M',
  '18-24M',
  '12M',
  '18 Months',
  '0-3M',
  '3-6M',
  '6-12M',
  '12-18M',
  '12',
  '3XL',
  '4XL',
  '18M',
  '10',
  '5x7',
  '8x10',
  '11x14',
  '16x20',
  '20x30',
  '8',
  'XS',
  '6',
  '4',
  '2XL',
  '2',
  'XXL',
  'M',
  'S',
  'L',
  'XL',
];

export const thebeeandthefoxShopConfig = {
  ...getDefaultShopConfig(),
  filters: [
    ...custom.filters,
    getBrandDirectFilterConfig(),
    getConditionsFilterConfig(),
    getSizeFilterConfig({ sizes: thebeeandthefoxSizeOptions }),
  ],
};
