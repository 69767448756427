import { getConditionsFilterConfig } from '../filters/condition';
import { getSizeFilterConfig } from '../filters/size';
import { getDefaultShopConfig } from './default';
import * as custom from '../filters/marketplace-custom-config';
import { getBrandDirectFilterConfig } from '../filters/brandDirect';

const crownforeverlaSizes = [
  'XS',
  'S',
  'M',
  'L',
  'XL',
  'XXL',
  '1Y',
  '1-2Y',
  '2Y',
  '2-3Y',
  '3Y',
  '3-4Y',
  '4Y',
  '4-5Y',
  '5Y',
  '5-6Y',
  '6Y',
  '6-7Y',
  '7Y',
  '7-8Y',
  '8Y',
  '8-9Y',
  '10Y',
  '10-11Y',
  '12Y',
  '3-6M',
  '6-12M',
  '12-18M',
  '18-24M',
  'One Size',
];

export const crownforeverlaShopConfig = {
  ...getDefaultShopConfig(),
  filters: [
    ...custom.filters,
    getConditionsFilterConfig(),
    getBrandDirectFilterConfig(),
    getSizeFilterConfig({ sizes: crownforeverlaSizes }),
  ],
};
