/* eslint-disable import/prefer-default-export */
import { FormatNumberOptions, useIntl } from 'react-intl';
import { flatten, uniq } from 'lodash';
import { useSelector } from 'react-redux';
import { useShopConfigV2 } from './shopConfig';
import { currencyConfiguration } from '../currency-config';
import {
  CountryCodeAndName,
  getCountryObjectFromCode,
  getCountryObjectFromLocale,
} from '../util/countryCodes';
import config from '../shopConfig/config';
import { ShopConfigV2 } from '../types/shopConfig/shopConfigV2';
import { CountryCode } from '../types/apollo/generated/types.generated';

interface WeightUnitConfig {
  subunit: 'g' | 'oz';
  unit: 'lb' | 'kg';
}

export const getLocalizedWeightUnitConfig = (countryCode: CountryCode): WeightUnitConfig => {
  const metricUnits: WeightUnitConfig = { subunit: 'g', unit: 'kg' };
  const imperialUnits: WeightUnitConfig = { subunit: 'oz', unit: 'lb' };
  const weightConfig = {
    [CountryCode.Us]: imperialUnits,
    [CountryCode.Ca]: metricUnits,
    [CountryCode.Au]: metricUnits,
    [CountryCode.Gb]: metricUnits,
    [CountryCode.Fr]: metricUnits,
    [CountryCode.It]: metricUnits,
    [CountryCode.De]: metricUnits,
    [CountryCode.Es]: metricUnits,
    [CountryCode.Nl]: metricUnits,
  };

  // Defaults to metric units since most countries use metric.
  return weightConfig[countryCode] || metricUnits;
};

export const getCurrencyConfig = (countryCode: CountryCode): FormatNumberOptions => {
  const countryDetails = config.stripe.supportedCountries.find((c) => c.code === countryCode)!;
  // Currency formatting options.
  // See: https://github.com/yahoo/react-intl/wiki/API#formatnumber
  return currencyConfiguration(countryDetails.currency);
};

export const getUserCountryConfig = (
  locale: string,
  allowedOriginToDestinationCountries: Record<CountryCode, CountryCode[]>
) => {
  const countryCode = getCountryObjectFromLocale(locale).code;
  const allowedOriginCountries = Object.keys(allowedOriginToDestinationCountries);
  const allowedDestinationCountries = flatten(Object.values(allowedOriginToDestinationCountries));

  const canShipFromUserCountry = allowedOriginCountries.includes(countryCode);
  const canShipToUserCountry = allowedDestinationCountries.includes(countryCode);
  const canShipWithinUserCountry =
    !!allowedOriginToDestinationCountries[countryCode]?.includes(countryCode);
  const canUserBuyInCountry = canShipToUserCountry || canShipWithinUserCountry;
  const canUserBuyBrandDirectOnlyInCountry = canShipToUserCountry && !canShipWithinUserCountry;

  return {
    countryCode,
    canShipFromUserCountry,
    canShipToUserCountry,
    canShipWithinUserCountry,
    canUserBuyInCountry,
    canUserBuyBrandDirectOnlyInCountry,
    userCurrencyConfig: getCurrencyConfig(countryCode),
  };
};

/**
 * Returns the country config of the user's current country.
 * Used for checking that the user is in a brand-supported country.
 */
export const useUserCountryConfig = () => {
  const { locale } = useIntl();
  const {
    internationalConfig: { allowedOriginToDestinationCountries },
  } = useShopConfigV2();

  return getUserCountryConfig(locale, allowedOriginToDestinationCountries);
};

export interface BrandCountryConfigType {
  countryCode: CountryCode;
  allowedShippingOriginCountries: CountryCodeAndName[];
  allowedShippingDestinationCountries: CountryCodeAndName[];
  weightUnitConfig: WeightUnitConfig;
  shopifyDefaultCurrencyConfig: FormatNumberOptions;
  currencyConfig: FormatNumberOptions;
  brandHasShopifyAndTreetCurrencyMismatch: boolean;
  currencyConversionRates: any;
}

export const getBrandCountryConfigFromShopConfig = (
  shopConfigV2: ShopConfigV2
): Omit<
  BrandCountryConfigType,
  | 'brandHasShopifyAndTreetCurrencyMismatch'
  | 'currencyConversionRates'
  | 'shopifyDefaultCurrencyConfig'
> => {
  const {
    internationalConfig: { allowedOriginToDestinationCountries },
  } = shopConfigV2;
  const countryCode = Object.keys(allowedOriginToDestinationCountries)[0] as CountryCode;

  const allowedShippingOriginCountries = Object.keys(allowedOriginToDestinationCountries).map(
    (code: string) => getCountryObjectFromCode(code as CountryCode)
  );

  const allowedShippingDestinationCountries: CountryCodeAndName[] = uniq(
    flatten(Object.values(allowedOriginToDestinationCountries))
  ).map((code: CountryCode) => getCountryObjectFromCode(code));

  const currencyConfig = getCurrencyConfig(countryCode); // Currency that the marketplace is in.

  return {
    countryCode,
    allowedShippingOriginCountries,
    allowedShippingDestinationCountries,
    weightUnitConfig: getLocalizedWeightUnitConfig(countryCode),
    currencyConfig,
  };
};

/**
 * Returns the country config of the brand-default country.
 */
export const useBrandCountryConfig = (): BrandCountryConfigType => {
  const shopConfigV2 = useShopConfigV2();
  const { defaultCurrency: shopifyDefaultCurrency, currencyConversionRates } = useSelector<any>(
    (state) => state.initial
  ) as any;

  const brandCountryConfigFromShopConfig = getBrandCountryConfigFromShopConfig(shopConfigV2);

  // Currency that all prices pulled from Shopify will be in.
  const shopifyDefaultCurrencyConfig = currencyConfiguration(shopifyDefaultCurrency);

  return {
    ...brandCountryConfigFromShopConfig,
    shopifyDefaultCurrencyConfig,
    currencyConversionRates,
    brandHasShopifyAndTreetCurrencyMismatch:
      brandCountryConfigFromShopConfig.currencyConfig.currency !==
      shopifyDefaultCurrencyConfig.currency,
  };
};
