import { getConditionsFilterConfig } from '../filters/condition';
import { getDefaultShopConfig, getDefaultTradeInShopConfig } from './default';
import * as custom from '../filters/marketplace-custom-config';
import { getBrandDirectFilterConfig } from '../filters/brandDirect';
import { BuilderSections } from '../../util/builder';

export const alpakagearConfig = {
  ...getDefaultShopConfig(),
  ...getDefaultTradeInShopConfig(),
  filters: [...custom.filters, getConditionsFilterConfig(), getBrandDirectFilterConfig()],
  builderConfig: {
    sections: {
      [BuilderSections.LandingPageContent]: '95905694971a42cea4c32de4330ea406',
    },
  },
};
