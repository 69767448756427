import { Box } from '@material-ui/core';
import classNames from 'classnames';
import React, { FC } from 'react';
import { useShopConfig } from '../../hooks/shopConfig';
import { getDarkerBrandCssBackgroundColor } from '../../util/colors/colors';
import css from './Divider.module.css';

interface DividerProps {
  className?: string;
  color?: string;
}

const Divider: FC<DividerProps> = (props: DividerProps) => {
  const { className, color } = props;
  const { css: brandCss } = useShopConfig();

  const darkerBrandCssBackgroundColor = getDarkerBrandCssBackgroundColor(brandCss);

  return (
    <Box
      className={classNames(css.root, className)}
      bgcolor={color || darkerBrandCssBackgroundColor}
    />
  );
};

export default Divider;
