import { BuilderSections } from '../../util/builder';
import { getDefaultShopConfig } from './default';
import * as custom from '../filters/marketplace-custom-config';
import { getBrandDirectFilterConfig } from '../filters/brandDirect';
import { getConditionsFilterConfig } from '../filters/condition';
import { getSizeFilterConfig } from '../filters/size';

export const cotopaxiShopConfig = {
  ...getDefaultShopConfig(),
  filters: [
    ...custom.filters,
    getConditionsFilterConfig(),
    getBrandDirectFilterConfig(),
    getSizeFilterConfig({
      sizes: ['S', 'M', 'L'],
    }),
  ],
  // TODO: Move to contentful if we do decide to proceed with builder io
  builderConfig: {
    sections: {
      [BuilderSections.LandingPageContent]: '82e9f2982dda4f5097f617399861113f',
    },
  },
};
