import { getDefaultShopConfig } from './default';
import * as custom from '../filters/marketplace-custom-config';
import { getConditionsFilterConfig } from '../filters/condition';
import { getSizeFilterConfig } from '../filters/size';

const poplinenSizes = ['XS', 'S', 'M', 'L', 'XL', '1X', '2X', '3X', '3M', '6M', '9M', '12M', '18M'];

export const poplinenShopConfig = {
  ...getDefaultShopConfig(),
  filters: [
    ...custom.filters,
    getConditionsFilterConfig(),
    getSizeFilterConfig({ sizes: poplinenSizes }),
  ],
};
