import { Conditions, getConditionsFilterConfig } from '../filters/condition';
import { getDefaultShopConfig } from './default';
import * as custom from '../filters/marketplace-custom-config';
import { getBrandDirectFilterConfig } from '../filters/brandDirect';
import { getSizeFilterConfig } from '../filters/size';

export const seaestasurfConditionOptions = [
  {
    key: Conditions.NewWithTags,
    label: 'New With Tags',
    description:
      'Your item has never been worn and the original hanging tags are still on. For this option, you’ll need to take pictures of the retail tags.',
  },
  {
    key: Conditions.NewWithoutTags,
    label: 'New Without Tags',
    description:
      'Your item has never been worn (other than perhaps trying it on), but the tags have been removed. There are no quirks or issues whatsoever.',
  },
  {
    key: Conditions.Excellent,
    label: 'Excellent Condition',
    description:
      'Your item has only been lightly used and has been extremely well maintained. There may be some small signs of wear, but nothing major.',
  },
  {
    key: Conditions.Good,
    label: 'Good Condition',
    description:
      'You’ve worn this item and it’s well maintained. There may be some quirks like a small stain, a button missing, or a loose thread, but it’s overall in good shape. You will need to take pictures of any quirks the item has. Please note that no swimsuit listings will be approved in this condition.',
  },
];

export const seaestasurfShopConfig = {
  ...getDefaultShopConfig(),
  filters: [
    ...custom.filters,
    getConditionsFilterConfig({ options: seaestasurfConditionOptions }),
    getBrandDirectFilterConfig(),
    getSizeFilterConfig({
      sizes: [
        'Small',
        'Medium',
        'Large',
        'Extra Large',
        '12',
        '14',
        '16',
        '22',
        '24',
        '26',
        '28',
        '2/3',
        '3/4',
        '4/5',
        '5/6',
        '6/7',
        '7/8',
        '8/9',
        '9/10',
        '18/24',
        '1T',
        '2T',
        '3T',
        '4T',
        '5T',
        '6T',
        '7Y',
        '8Y',
        '9Y',
      ],
    }),
  ],
};
