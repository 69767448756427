import { getConditionsFilterConfig } from '../filters/condition';
import { getSizeFilterConfig } from '../filters/size';
import { getDefaultShopConfig } from './default';
import * as custom from '../filters/marketplace-custom-config';
import { getTagsFilterConfig } from '../filters/styles';
import { FilterId } from '../../types/filters/filters';

export const birdygreyTags = [
  { key: 'Champagne', label: 'Champagne' },
  { key: 'Blushes', label: 'Blushes' },
  { key: 'Roses', label: 'Roses' },
  { key: 'Taupes', label: 'Taupes' },
  { key: 'Metallic', label: 'Metallic' },
  { key: 'Marigold', label: 'Marigold' },
  { key: 'Terracotta', label: 'Terracotta' },
  { key: 'Mauves', label: 'Mauves' },
  { key: 'Silvers', label: 'Silvers' },
  { key: 'Light Grays', label: 'Light Grays' },
  { key: 'Light Greens', label: 'Light Greens' },
  { key: 'Sea Glass', label: 'Sea Glass' },
  { key: 'Blues', label: 'Blues' },
  { key: 'Lilac', label: 'Lilac' },
  { key: 'Slate Blue', label: 'Slate Blue' },
  { key: 'Navys', label: 'Navys' },
  { key: 'Dark Greens', label: 'Dark Greens' },
  { key: 'Burgundys', label: 'Burgundys' },
  { key: 'Black', label: 'Black' },
];

export const birdygreyShopConfig = {
  ...getDefaultShopConfig(),
  filters: [
    ...custom.filters,
    getConditionsFilterConfig(),
    getTagsFilterConfig({ id: FilterId.Color, options: birdygreyTags }),
    getSizeFilterConfig({
      sizes: ['XS', 'S', 'M', 'L', 'XL', 'XXL', '2XL', '3XL', '2X', '3X'],
    }),
  ],
};
