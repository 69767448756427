/* eslint-disable import/prefer-default-export */
import { getDefaultShopConfig } from './default';
import * as custom from '../filters/marketplace-custom-config';
import { Conditions, getConditionsFilterConfig } from '../filters/condition';

const creocardsConditionOptions = [
  {
    key: Conditions.NewWithTags,
    label: 'Mint',
    description:
      'A Mint card is virtually perfect. All four corners should be perfectly sharp, no fading, and full original gloss. There should be no staining or surface damage of any kind, and the image should be centered on the card.',
  },
  {
    key: Conditions.NewWithoutTags,
    label: 'Good',
    description:
      'A Good card will show some rounding and surface wear on the card, including light scratching, scuffing, light staining, chipping of enamel, or small creases. Original gloss may be completely absent, and there may be considerable discoloration. Centering should be good but not perfect.',
  },
  {
    key: Conditions.Excellent,
    label: 'Played',
    description:
      'A Played card is one that has been used during gameplay, and will therefore have considerable wear, possibly affecting the framing of the card. The surface of the card can show advanced stages of wear, including scuffing, scratching, pitting, chipping, and staining, and may have one or more major creases.',
  },
  {
    key: Conditions.Good,
    label: 'Damaged',
    description:
      'A Damaged card has similar characteristics to a Played card, but the defects have progressed to a point at which the visual appeal of the card is almost completely gone. A Damaged card may be missing one or two small pieces, and can have major creases that almost break through the card.',
  },
];

export const creocardsShopConfig = {
  ...getDefaultShopConfig(),
  filters: [...custom.filters, getConditionsFilterConfig({ options: creocardsConditionOptions })],
};
