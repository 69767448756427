import { getBrandDirectFilterConfig } from '../filters/brandDirect';
import { Conditions, getConditionsFilterConfig } from '../filters/condition';
import { getSizeFilterConfig } from '../filters/size';
import { getDefaultShopConfig, getDefaultTradeInShopConfig } from './default';
import * as custom from '../filters/marketplace-custom-config';
import { ListingItemType } from '../../types/sharetribe/listing';
import { GenericFilterOption } from '../../types/filters/filters';

const coclicoConditionOptions = [
  {
    key: Conditions.NewWithTags,
    label: 'New In Box',
    description:
      'Your shoes have never been worn and they’re still in the original box. For this option, you’ll need to take pictures of your shoes in the box.',
  },
  {
    key: Conditions.NewWithoutTags,
    label: 'New Without Box',
    description:
      'Your shoes have never been worn (other than perhaps trying it on), but you no longer have the box. There are no quirks or issues whatsoever.',
    includedTypes: [ListingItemType.Marketplace],
  },
  {
    key: Conditions.Excellent,
    label: 'Excellent Condition',
    description:
      'Your shoes have only been lightly used and have been extremely well maintained. There may be some small signs of wear, but nothing major.',
  },
  {
    key: Conditions.Good,
    label: 'Good Condition',
    description:
      'You’ve worn the shoes and they’re well maintained. There may be some quirks like a small stain or a scuff mark, but they’re overall in good shape. You will need to take pictures of any quirks your shoes have.',
  },
  {
    key: Conditions.Recyclable,
    label: 'Recyclable',
    description: 'Your item has significant damage or is in recycle-only condition.',
    includedTypes: [ListingItemType.TradeIn],
  },
];

// Do not show trade-in only conditions in marketplace search filter
const coclicoConditionFilterOptions = coclicoConditionOptions
  .filter((option) => !option.includedTypes?.includes(ListingItemType.TradeIn))
  .map((option) => ({
    key: option.key,
    label: option.label,
    description: option.description,
  })) as GenericFilterOption[];

export const coclicoShopConfig = {
  ...getDefaultShopConfig(),
  ...getDefaultTradeInShopConfig(),
  filters: [
    ...custom.filters,
    getConditionsFilterConfig({ options: coclicoConditionFilterOptions }),
    getBrandDirectFilterConfig(),
    getSizeFilterConfig({
      sizes: [
        '35',
        '35.5',
        '36',
        '36.5',
        '37',
        '37.5',
        '38',
        '38.5',
        '39',
        '39.5',
        '40',
        '40.5',
        '41',
        '42',
      ],
    }),
  ],
  conditionOptionsOverride: coclicoConditionOptions,
};
