import { getDefaultShopConfig } from './default';
import * as custom from '../filters/marketplace-custom-config';
import { getBrandDirectFilterConfig } from '../filters/brandDirect';
import { getSizeFilterConfig } from '../filters/size';
import { getConditionsFilterConfig } from '../filters/condition';

const goldieteesSizes = [
  'O/S',
  'XS',
  'S',
  'M',
  'L',
  'XL',
  '0',
  '1',
  '2',
  '3',
  '4',
  '6',
  '8',
  '23',
  '24',
  '25',
  '26',
  '27',
  '28',
  '29',
  '30',
  '31',
  '32',
];

export const goldieteesShopConfig = {
  ...getDefaultShopConfig(),
  filters: [
    ...custom.filters,
    getBrandDirectFilterConfig(),
    getConditionsFilterConfig(),
    getSizeFilterConfig({ sizes: goldieteesSizes }),
  ],
};
