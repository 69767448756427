import { getDefaultShopConfig } from './default';
import * as custom from '../filters/marketplace-custom-config';
import { getBrandDirectFilterConfig } from '../filters/brandDirect';
import { getConditionsFilterConfig } from '../filters/condition';
import { getSizeFilterConfig } from '../filters/size';
import { BuilderSections } from '../../util/builder';

const forloveandlemonsSizes = ['O/S', 'XXS', 'XS', 'S', 'M', 'L', 'XL', '1x', '2x', 'SDD'];

// eslint-disable-next-line import/prefer-default-export
export const forloveandlemonsShopConfig = {
  ...getDefaultShopConfig(),
  filters: [
    ...custom.filters,
    getConditionsFilterConfig(),
    getBrandDirectFilterConfig(),
    getSizeFilterConfig({ sizes: forloveandlemonsSizes }),
  ],
  builderConfig: {
    sections: {
      [BuilderSections.LandingPageContent]: '967e003fb9c24c0aaaad1fb2c2e51d4a',
    },
  },
};
