import React, { FC, useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { DialogWithCloseButton } from '..';
import AppContext from '../../context/AppContext';
import { SubscribeModalState } from '../../ducks/subscribeModal.duck';
import * as heap from '../../util/heap';
import { getLocalStorage, setLocalStorage } from '../../util/localStorageHelpers';
import SubscribeEmail from './SubscribeEmail';
import SubscribeSizes from './SubscribeSizes';
import css from './SubscribeModal.module.css';

enum Pane {
  Email = 'EMAIL',
  Sizes = 'SIZES',
}

interface SubscribeModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const SubscribeModal: FC<SubscribeModalProps> = (props: SubscribeModalProps) => {
  const { isOpen, onClose } = props;

  const { treetId } = useContext(AppContext);
  const { storageKey } = useSelector<any>((state) => state.subscribeModal) as SubscribeModalState;

  const [activePane, setActivePane] = useState<Pane>(Pane.Email);

  useEffect(() => {
    if (isOpen) {
      const localStorageValue = getLocalStorage(storageKey) || '';
      setLocalStorage(storageKey, localStorageValue.concat(`,${treetId}`));
      heap.trackShowLandingPageSubscribeModal(treetId);
    }
  }, [isOpen]);

  const onEmailSubmit = () => setActivePane(Pane.Sizes);

  const closeButtonClassName =
    activePane === Pane.Email ? css.subscribeEmailCloseButton : undefined;

  return (
    <DialogWithCloseButton
      open={isOpen}
      allowFullScreen={false}
      fullWidth
      maxWidth="md"
      onClose={onClose}
      headerClassName={css.header}
      closeButtonClassName={closeButtonClassName}
    >
      {activePane === Pane.Email ? <SubscribeEmail onSubmit={onEmailSubmit} /> : <SubscribeSizes />}
    </DialogWithCloseButton>
  );
};

export default SubscribeModal;
