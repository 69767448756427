/* eslint-disable no-underscore-dangle */
import { CurrentUser } from '../types/sharetribe/currentUser';
import { UploadcareFile } from '../types/uploadcare/file';

enum FORM_IDS {
  BACKGROUND_REMOVAL = '2e577170-e475-11ed-b0d0-cfd7f2e0e20f',
  RETURN_INSURANCE_OPT_OUT = 'cc5ec0c0-200f-11ee-9e6c-9fbd28f51d82',
  CHOOSE_STOCK_PHOTOS = 'b4a602f0-3158-11ee-b2f6-ed42ed674d49',
  MULTI_SELLER_CHECKOUT = '28982070-6c6a-11ee-94e8-67ac367de0ed',
  LISTING_FEEDBACK_BUYER_SATISFACTION = 'd7e22220-e652-11ee-bc30-35c78c57c5e3',
}

export const showRefinerForm = (formId: FORM_IDS) => {
  if (typeof window !== 'undefined' && window._refiner) {
    /* eslint-disable no-underscore-dangle */
    window._refiner('showForm', formId);
  }
};

export const setUser = (user: CurrentUser) => {
  if (typeof window !== 'undefined' && window._refiner && user) {
    window._refiner('identifyUser', {
      id: user.id.uuid,
      email: user.attributes.email,
      name: `${user.attributes.profile.firstName} ${user.attributes.profile.lastName}`,
    });
  }
};

export const showBackgroundRemovalForm = (images?: UploadcareFile[]) => {
  if (images?.some((image: UploadcareFile) => image.isBackgroundRemoved)) {
    showRefinerForm(FORM_IDS.BACKGROUND_REMOVAL);
  }
};

export const showReturnInsuranceOptOutForm = () => {
  showRefinerForm(FORM_IDS.RETURN_INSURANCE_OPT_OUT);
};

export const showChooseStockPhotosForm = () => {
  showRefinerForm(FORM_IDS.CHOOSE_STOCK_PHOTOS);
};

export const showMultiSellerCheckoutForm = () => {
  showRefinerForm(FORM_IDS.MULTI_SELLER_CHECKOUT);
};

export const showListingFeedbackBuyerSatisfactionForm = (email?: string): void => {
  if (typeof window !== 'undefined' && window._refiner && email) {
    window._refiner('identifyUser', { email });
  }
  showRefinerForm(FORM_IDS.LISTING_FEEDBACK_BUYER_SATISFACTION);
};
