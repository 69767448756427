import { getDefaultShopConfig } from './default';
import { getConditionsFilterConfig } from '../filters/condition';
import * as custom from '../filters/marketplace-custom-config';
import { getSizeFilterConfig } from '../filters/size';
import { BuilderSections } from '../../util/builder';
import { getColorFilterConfig } from '../filters/color';

const sizes = [
  'XXXS',
  'XXS',
  'XS',
  'S',
  'M',
  'L',
  'XL',
  'XXL',
  'XXXL',
  '4XL',
  '5XL',
  '6XL',
  'OS',
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  '10',
  '11',
  '12',
];

export const girlfriendShopConfig = {
  ...getDefaultShopConfig(),
  filters: [
    ...custom.filters,
    getConditionsFilterConfig(),
    getSizeFilterConfig({ sizes }),
    getColorFilterConfig(),
  ],
  builderConfig: {
    sections: {
      [BuilderSections.LandingPageContent]: '2ba2bcb090d940a1bd3f3ac32900d171',
    },
  },
};
