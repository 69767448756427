import { getConditionsFilterConfig } from '../filters/condition';
import { getBrandDirectFilterConfig } from '../filters/brandDirect';
import { getSizeFilterConfig } from '../filters/size';
import { getDefaultShopConfig } from './default';
import * as custom from '../filters/marketplace-custom-config';

const ratboiSizes = ['XS', 'S', 'M', 'L', 'XL', '2X', '3X'];

export const ratboiShopConfig = {
  ...getDefaultShopConfig(),
  filters: [
    ...custom.filters,
    getBrandDirectFilterConfig(),
    getConditionsFilterConfig(),
    getSizeFilterConfig({ sizes: ratboiSizes }),
  ],
};
