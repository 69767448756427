import { defaultClothesConditionsOptions, getConditionsFilterConfig } from '../filters/condition';
import { getSizeFilterConfigFromOptions } from '../filters/size';
import { getDefaultShopConfig } from './default';
import * as custom from '../filters/marketplace-custom-config';

const maajiSizeOptions = [
  { key: 'XS', label: 'XS' },
  { key: 'SM', label: 'S' },
  { key: 'MD', label: 'M' },
  { key: 'LG', label: 'L' },
  { key: 'XL', label: 'XL' },
  { key: 'XXL', label: 'XXL' },
  { key: 'XXXL', label: 'XXXL' },
  { key: 'OS', label: 'OS' },
  { key: '02', label: '2' },
  { key: '04', label: '4' },
  { key: '06', label: '6' },
  { key: '08', label: '8' },
  { key: '10', label: '10' },
  { key: '12', label: '12' },
  { key: '14', label: '14' },
  { key: '16', label: '16' },
];

export const maajiShopConfig = {
  ...getDefaultShopConfig(),
  filters: [
    ...custom.filters,
    getConditionsFilterConfig({ options: defaultClothesConditionsOptions }),
    getSizeFilterConfigFromOptions({ options: maajiSizeOptions }),
  ],
};
