import { Dispatch } from 'redux';
import { getOpenListings } from '../util/ducks/getOpenListings';
import { StorableError } from '../types/error';
import { RequestStatus } from '../types/requestStatus';
import { getShopConfig } from '../shopConfig/configHelper';
import { storableError } from '../util/errors';
import * as log from '../util/log';
import { denormalisedResponseEntities } from '../util/data';

// ================ Action types ================ //

const FETCH_OPEN_LISTINGS_REQUEST = 'app/CheckoutPage/FETCH_OPEN_LISTINGS_REQUEST';
const FETCH_OPEN_LISTINGS_SUCCESS = 'app/CheckoutPage/FETCH_OPEN_LISTINGS_SUCCESS';
const FETCH_OPEN_LISTINGS_ERROR = 'app/CheckoutPage/FETCH_OPEN_LISTINGS_ERROR';

interface FetchOpenListingsRequest {
  type: typeof FETCH_OPEN_LISTINGS_REQUEST;
}

interface FetchOpenListingsSuccess {
  type: typeof FETCH_OPEN_LISTINGS_SUCCESS;
}

interface FetchOpenListingsError {
  type: typeof FETCH_OPEN_LISTINGS_ERROR;
  error: StorableError;
}

type ListingsActionType =
  | FetchOpenListingsRequest
  | FetchOpenListingsSuccess
  | FetchOpenListingsError;

// ================ Reducer ================ //

export interface ListingsState {
  fetchOpenListingsError: StorableError | null;
  fetchOpenListingsStatus: RequestStatus;
}

const initialState: ListingsState = {
  fetchOpenListingsError: null,
  fetchOpenListingsStatus: RequestStatus.Ready,
};

export default function reducer(
  state: ListingsState = initialState,
  action: ListingsActionType
): ListingsState {
  switch (action.type) {
    case FETCH_OPEN_LISTINGS_REQUEST: {
      return {
        ...state,
        fetchOpenListingsStatus: RequestStatus.Pending,
        fetchOpenListingsError: null,
      };
    }
    case FETCH_OPEN_LISTINGS_SUCCESS: {
      return {
        ...state,
        fetchOpenListingsStatus: RequestStatus.Success,
      };
    }
    case FETCH_OPEN_LISTINGS_ERROR: {
      return {
        ...state,
        fetchOpenListingsStatus: RequestStatus.Error,
        fetchOpenListingsError: action.error,
      };
    }
    default:
      return state;
  }
}

// ================ Action creators ================ //

const fetchOpenListingsRequest = () => ({ type: FETCH_OPEN_LISTINGS_REQUEST });

const fetchOpenListingsSuccess = () => ({ type: FETCH_OPEN_LISTINGS_SUCCESS });

const fetchOpenListingsError = (error: StorableError) => ({
  type: FETCH_OPEN_LISTINGS_ERROR,
  error,
});

// ================ Thunks ================ //

export const fetchOpenListings =
  (listingIds: string[]) => async (dispatch: Dispatch, getState: () => any, sdk: any) => {
    dispatch(fetchOpenListingsRequest());

    try {
      const { treetId, shopConfig: shopConfigV2 } = getState().initial;
      const { shopName } = getShopConfig(treetId, shopConfigV2);
      const listingsResponse = await getOpenListings(sdk, { shopName, listingIds });
      const listings = denormalisedResponseEntities(listingsResponse);
      dispatch(fetchOpenListingsSuccess());
      return listings;
    } catch (e) {
      dispatch(fetchOpenListingsError(storableError(e)));
      log.error(e, 'fetching-open-listings-failed', { listingIds });
      throw e;
    }
  };
