import React from 'react';
import SubscribeModal from '../SubscribeModal/SubscribeModal';
import { ModalType } from '../../ducks/modal.duck';
import { useAllowedFindItemMethods } from '../../hooks/useAllowedFindItemMethods';
import ISOModal from '../ISOModal/ISOModal';
import CountryWarningModal from '../CountryWarningModal/CountryWarningModal';
import { useUserCountryConfig } from '../../hooks/useCountryConfig';
import { useEnabledCustomerExperiences } from '../../hooks/useEnabledCustomerExperiences';
import { useActiveModal } from '../../hooks/useActiveModal';

const Modals = () => {
  const { allowBuy, allowList } = useEnabledCustomerExperiences();
  const { shouldAllowSearch: hasProductCatalog } = useAllowedFindItemMethods();

  const { isModalOpen: isSubscribeModalOpen, closeModal: closeSubscribeModal } = useActiveModal(
    ModalType.Subscribe
  );
  const { isModalOpen: isISOModalOpen, closeModal: closeISOModal } = useActiveModal(ModalType.ISO);
  const { isModalOpen: isCountryWarningModalOpen, closeModal: closeCountryWarningModal } =
    useActiveModal(ModalType.CountryWarning);

  const { canUserBuyInCountry, canShipFromUserCountry: canUserListInCountry } =
    useUserCountryConfig();

  const shouldShowCountryWarningModal =
    (allowBuy && !canUserBuyInCountry) || (allowList && !canUserListInCountry);

  return (
    <>
      {allowBuy && <SubscribeModal isOpen={isSubscribeModalOpen} onClose={closeSubscribeModal} />}
      {hasProductCatalog && (
        <ISOModal
          isOpen={isISOModalOpen}
          handleClose={closeISOModal}
          handleSuccess={closeISOModal}
        />
      )}
      {shouldShowCountryWarningModal && (
        <CountryWarningModal
          isOpen={isCountryWarningModalOpen}
          onClick={closeCountryWarningModal}
        />
      )}
    </>
  );
};

export default Modals;
