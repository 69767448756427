import React, { FC } from 'react';
import { useIconColor } from '../../../hooks/useIconColor';

interface IconQuestionProps {
  className?: string;
  color?: string;
}

const IconQuestion: FC<IconQuestionProps> = (props: IconQuestionProps) => {
  const { className, color } = props;

  const { iconColor } = useIconColor();
  const fillColor = color || iconColor;

  return (
    <svg
      className={className}
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 4C9.37258 4 4 9.37258 4 16C4 22.6274 9.37258 28 16 28C22.6274 28 28 22.6274 28 16C28 9.37258 22.6274 4 16 4ZM6 16C6 21.5228 10.4772 26 16 26C21.5228 26 26 21.5228 26 16C26 10.4772 21.5228 6 16 6C10.4772 6 6 10.4772 6 16Z"
        fill={fillColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.1823 12.9718C13.5197 11.804 14.5888 11 15.8045 11H16.9693C18.0908 11 19 11.9092 19 13.0307C19 13.6761 18.7214 14.29 18.2357 14.715L16.8466 15.9304C15.6731 16.9572 15 18.4407 15 20H17C17 19.0174 17.4242 18.0826 18.1636 17.4356L19.5527 16.2201C20.4724 15.4154 21 14.2528 21 13.0307C21 10.8046 19.1954 9 16.9693 9H15.8045C13.6981 9 11.8455 10.3931 11.2609 12.4168L11.0393 13.184L12.9607 13.7391L13.1823 12.9718ZM17 21H15V23H17V21Z"
        fill={fillColor}
      />
    </svg>
  );
};

export default IconQuestion;
