import { Box, Chip, Tooltip } from '@material-ui/core';
import React, { FC } from 'react';
import { useShopCss } from '../../hooks/useShopCss';
import { Listing } from '../../types/sharetribe/listing';
import IconLightningBolt from '../Icons/IconLightningBolt/IconLightningBolt';
import TypographyWrapper from '../TypographyWrapper/TypographyWrapper';

type CreditBoostChipType = {
  listing?: Listing;
};

const CreditBoostChip: FC<CreditBoostChipType> = (props) => {
  const { listing } = props;
  const shopCss = useShopCss();
  const listingHasSavedPromoCredit = listing?.attributes?.publicData?.payoutCreditBoostPromoId;

  if (!listing || !listingHasSavedPromoCredit) {
    return null;
  }

  const formattedCreditPercentage = `${
    Number(listing.attributes.publicData.payoutInfo.creditPayoutPercentage) * 100
  }%`;

  return (
    <Box marginY="3px">
      <Tooltip
        enterTouchDelay={0}
        title={
          <TypographyWrapper variant="body2" typographyOverrides={{ style: { color: 'inherit' } }}>
            🎉 Nice! This listing is eligible for a {formattedCreditPercentage} credit payout.
          </TypographyWrapper>
        }
      >
        <Chip
          style={{
            backgroundColor: shopCss.primaryButton.backgroundColor,
            borderRadius: '16px',
          }}
          label={
            <TypographyWrapper
              variant="body2"
              typographyOverrides={{ style: { color: shopCss.primaryButton.font.color } }}
            >
              CREDIT BOOST
            </TypographyWrapper>
          }
          size="small"
          icon={
            <IconLightningBolt
              color={shopCss.primaryButton.font.color}
              height="auto"
              width="auto"
            />
          }
          clickable
        />
      </Tooltip>
    </Box>
  );
};

export default CreditBoostChip;
