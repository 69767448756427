import { getBrandDirectFilterConfig } from '../filters/brandDirect';
import { defaultShoesConditionsOptions, getConditionsFilterConfig } from '../filters/condition';
import { getSizeFilterConfig } from '../filters/size';
import { getDefaultShopConfig } from './default';
import * as custom from '../filters/marketplace-custom-config';

export const fortressShopConfig = {
  ...getDefaultShopConfig(),
  filters: [
    ...custom.filters,
    getConditionsFilterConfig({ options: defaultShoesConditionsOptions }),
    getBrandDirectFilterConfig(),
    getSizeFilterConfig({
      sizes: ['35', '36', '37', '38', '39', '40', '41', '42'],
    }),
  ],
};
