/* eslint-disable import/prefer-default-export */
import { getBrandDirectFilterConfig } from '../filters/brandDirect';
import { Conditions, getConditionsFilterConfig } from '../filters/condition';
import { getDefaultShopConfig, getDefaultTradeInShopConfig } from './default';
import * as custom from '../filters/marketplace-custom-config';
import { ListingItemType } from '../../types/sharetribe/listing';

const jujubeConditionOptions = [
  {
    key: Conditions.NewWithTags,
    label: 'New With Tags',
    description:
      'Your item has never been worn and the original hanging tags are still on. For this option, you’ll need to take pictures of the retail tags.',
  },
  {
    key: Conditions.NewWithoutTags,
    label: 'New Without Tags',
    description:
      'Your item has never been worn (other than perhaps trying it on), but the tags have been removed. There are no quirks or issues whatsoever.',
    includedTypes: [ListingItemType.Marketplace],
  },
  {
    key: Conditions.Excellent,
    label: 'Excellent Condition',
    description:
      'Your item has only been lightly used and has been extremely well maintained. There may be some small signs of wear, but nothing major.',
  },
  {
    key: Conditions.Good,
    label: 'Good Condition',
    description:
      'You’ve worn this item and it’s well maintained. There may be some quirks like a small stain, a button missing, or a loose thread, but it’s overall in good shape. You will need to take pictures of any quirks the item has.',
  },
];

export const jujubeShopConfig = {
  ...getDefaultShopConfig(),
  ...getDefaultTradeInShopConfig(),
  filters: [
    ...custom.filters,
    getConditionsFilterConfig({ options: jujubeConditionOptions }),
    getBrandDirectFilterConfig(),
  ],
  conditionOptionsOverride: jujubeConditionOptions,
};
