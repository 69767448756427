export enum ModalType {
  Subscribe = 'SUBSCRIBE',
  ISO = 'ISO',
  CancelItemRequest = 'CANCEL_ITEM_REQUEST',
  ShoppingBag = 'SHOPPING_BAG',
  CancelBundle = 'CANCEL_BUNDLE',
  VerifyBundle = 'VERIFY_BUNDLE',
  DisputeOrVerifyTradeInBundle = 'DISPUTE_OR_VERIFY_TRADE_IN_BUNDLE',
  GetPaidForBundle = 'GET_PAID_FOR_BUNDLE',
  MarkBundleAsFulfilled = 'MARK_BUNDLE_AS_FULFILLED',
  MarkBundleAsDelivered = 'MARK_BUNDLE_AS_DELIVERED',
  ShippingAddress = 'SHIPPING_ADDRESS',
  PhotoTips = 'PHOTO_TIPS',
  BackgroundRemoval = 'BACKGROUND_REMOVAL',
  CoverPhotoBackgroundRemoval = 'COVER_PHOTO_BACKGROUND_REMOVAL',
  EditPhoto = 'EDIT_PHOTO',
  FiltersMobile = 'FILTERS_MOBILE',
  ComingSoon = 'COMING_SOON',
  ReferBrand = 'REFER_BRAND',
  ChooseCoverPhoto = 'CHOOSE_COVER_PHOTO',
  UpdateQuantity = 'UPDATE_QUANTITY',
  ConfirmDelete = 'CONFIRM_DELETE',
  ManageNotifications = 'MANAGE_NOTIFICATIONS',
  CountryWarning = 'COUNTRY_WARNING',
  SellOrTradeInEducational = 'SELL_OR_TRADE_IN_EDUCATIONAL',
  TradeInAcknowledgement = 'TRADE_IN_ACKNOWLEDGEMENT',
  ListingFeedback = 'LISTING_FEEDBACK',
  SearchModal = 'SEARCH_MODAL',
}

// ================ Action types ================ //

const SET_ACTIVE_MODAL = 'app/modal/SET_ACTIVE_MODAL';
const RESET_ACTIVE_MODAL = 'app/modal/RESET_ACTIVE_MODAL';

interface SetActiveModal {
  type: typeof SET_ACTIVE_MODAL;
  modal: ModalType | string;
}

interface ResetActiveModal {
  type: typeof RESET_ACTIVE_MODAL;
}

type ModalActionType = SetActiveModal | ResetActiveModal;

// ================ Reducer ================ //

export interface ModalState {
  activeModal: ModalType | string | null;
}

const initialState: ModalState = {
  activeModal: null,
};

export default function reducer(
  state: ModalState = initialState,
  action: ModalActionType
): ModalState {
  switch (action.type) {
    case SET_ACTIVE_MODAL: {
      return { ...state, activeModal: action.modal };
    }
    case RESET_ACTIVE_MODAL: {
      return { ...state, activeModal: null };
    }
    default:
      return state;
  }
}

// ================ Action creators ================ //

export const setActiveModal = (modal: ModalType | string): SetActiveModal => ({
  type: SET_ACTIVE_MODAL,
  modal,
});

export const resetActiveModal = (): ResetActiveModal => ({ type: RESET_ACTIVE_MODAL });
