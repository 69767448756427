import { capitalize, omit } from 'lodash';
import {
  Filter,
  FilterId,
  SearchFilterType,
  SearchNavBarSections,
} from '../../types/filters/filters';
import { FilterQueryParam } from '../../types/search/search';

interface Category {
  key: string;
  tags?: string[];
  label: string;
  count?: number;
  disabled?: boolean;
}

interface GetCategoryFilterConfigParams {
  options: Category[];
  id?: FilterId;
  shouldUseTreetOptions?: boolean;
}

interface GetCategoryFilterHelperParams extends GetCategoryFilterConfigParams {
  queryParamName: string;
  configParams?: { [searchMode: string]: string };
}

const getCategoryFilterHelper = (params: GetCategoryFilterHelperParams) => {
  const {
    queryParamName,
    options,
    configParams,
    // This filter id will control which FrenzyFacet to map to when retrieving filter options
    // in frenzyHelpers.ts
    id = FilterId.Category,
    shouldUseTreetOptions = false,
  } = params;
  return {
    config: {
      options: options.map((option) => omit(option, ['tags'])),
      ...configParams,
    },
    group: 'primary',
    id,
    label: capitalize(id),
    queryParamNames: [queryParamName],
    type: SearchFilterType.Checkbox,
    searchNavBarSection: SearchNavBarSections.Filter,
    shouldUseTreetOptions,
  };
};

// For future style/category filters, we should use the 'pub_categories' field since listings can
// have multiple categories.
export const getCategoriesFilterConfig = (params: GetCategoryFilterConfigParams): Filter =>
  getCategoryFilterHelper({
    ...params,
    queryParamName: 'pub_categories',
    configParams: { searchMode: 'has_any' },
  });

export const getCategoryFilterConfig = (params: GetCategoryFilterConfigParams): Filter =>
  getCategoryFilterHelper({ ...params, queryParamName: 'pub_category' });

export const getStyleFilterConfig = (params: GetCategoryFilterConfigParams): Filter =>
  // FilterQueryParam.style is the search query param that maps to the 'style' FrenzyFacet and is only
  // used for Frenzy enabled searches. This is not stored on Sharetribe listings data.
  getCategoryFilterHelper({
    ...params,
    id: FilterId.Style,
    queryParamName: FilterQueryParam.style,
  });

export const getTagsFilterConfig = (params: GetCategoryFilterConfigParams): Filter => {
  const { id = FilterId.Category, options, shouldUseTreetOptions = false } = params;
  return {
    config: {
      options: options.map((option) => omit(option, ['tags'])),
    },
    group: 'primary',
    id,
    label: capitalize(id),
    queryParamNames: ['pub_tags'],
    type: SearchFilterType.Checkbox,
    searchNavBarSection: SearchNavBarSections.Filter,
    shouldUseTreetOptions,
  };
};
