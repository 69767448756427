import { getDefaultShopConfig } from './default';
import * as custom from '../filters/marketplace-custom-config';
import { getBrandDirectFilterConfig } from '../filters/brandDirect';
import { getConditionsFilterConfig } from '../filters/condition';
import { getSizeFilterConfig } from '../filters/size';
import { BuilderSections } from '../../util/builder';

const shopfavoritedaughterSizes: string[] = [
  'XS',
  'S',
  'M',
  'L',
  'XL',
  '0',
  '2',
  '4',
  '6',
  '8',
  '10',
  '12',
  '14',
  '16',
  '18',
  '23',
  '24',
  '25',
  '26',
  '27',
  '28',
  '29',
  '30',
  '31',
  '32',
  '33',
  '34',
];

export const shopfavoritedaughterShopConfig = {
  ...getDefaultShopConfig(),
  filters: [
    ...custom.filters,
    getConditionsFilterConfig(),
    getBrandDirectFilterConfig(),
    getSizeFilterConfig({ sizes: shopfavoritedaughterSizes }),
  ],
  builderConfig: {
    sections: {
      [BuilderSections.LandingPageContent]: 'f5b6deaa3cac47c1a5e83db6ca442eb9',
    },
  },
};
