import { getDefaultShopConfig } from './default';
import * as custom from '../filters/marketplace-custom-config';
import { getBrandDirectFilterConfig } from '../filters/brandDirect';
import { getConditionsFilterConfig } from '../filters/condition';
import { getSizeFilterConfig } from '../filters/size';

const tuesdayofcaliforniaSizes = [
  'OS',
  'XXS',
  'XS',
  'S',
  'M',
  'L',
  'XL',
  '1X',
  '2X',
  '3X',
  '4X',
  '5X',
  '6X',
  '7X',
];

export const tuesdayofcaliforniaShopConfig = {
  ...getDefaultShopConfig(),
  filters: [
    ...custom.filters,
    getConditionsFilterConfig(),
    getBrandDirectFilterConfig(),
    getSizeFilterConfig({ sizes: tuesdayofcaliforniaSizes }),
  ],
};
