import { getBrandDirectFilterConfig } from '../filters/brandDirect';
import { getConditionsFilterConfig } from '../filters/condition';
import { getSizeFilterConfig } from '../filters/size';
import { getDefaultShopConfig } from './default';
import * as custom from '../filters/marketplace-custom-config';

const dreamlandbabycoSizes = [
  '0 - 3 Months',
  '0 - 6 Months',
  '3 - 6 Months',
  '6 - 9 Months',
  '6 - 12 Months',
  '9 - 12 Months',
  '12 - 18 Months',
  '12 - 24 Months',
  '18 - 24 Months',
  '24 - 36 Months',
  '2T',
  '3T',
  '4T',
  '5T',
  'One Size',
];

export const dreamlandbabycoShopConfig = {
  ...getDefaultShopConfig(),
  filters: [
    ...custom.filters,
    getConditionsFilterConfig(),
    getSizeFilterConfig({
      sizes: dreamlandbabycoSizes,
    }),
    getBrandDirectFilterConfig(),
  ],
};
