import { Box } from '@material-ui/core';
import React from 'react';
import { IconAlertCircle, TypographyWrapper } from '../../components';
import { defaultTreetStyles } from '../../shopConfig/config';
import { useIsMobile } from '../../hooks/useIsMobile';

const IncompleteAddressErrorMessage = () => {
  const isMobile = useIsMobile();
  const fontSize = isMobile ? '12px' : '16px';

  return (
    <Box display="flex" alignItems="center">
      <IconAlertCircle height="21" width="21" />
      <TypographyWrapper
        variant="body1"
        typographyOverrides={{
          style: { color: defaultTreetStyles.red80, marginLeft: 5, fontSize, fontWeight: 'bold' },
        }}
      >
        Fill out shipping information before proceeding
      </TypographyWrapper>
    </Box>
  );
};

export default IncompleteAddressErrorMessage;
