import React, { FC } from 'react';
import { useIconColor } from '../../../hooks/useIconColor';

interface IconReportProps {
  className?: string;
  color?: string;
}

const IconReport: FC<IconReportProps> = (props: IconReportProps) => {
  const { className, color } = props;

  const { iconColor } = useIconColor();
  const fillColor = color || iconColor;

  return (
    <svg
      className={className}
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18 3H6V29H26V11H18V3ZM25.1716 9L20 3.8284V9H25.1716ZM4 1H20L28 9V31H4V1ZM9 20H11V26H9V20ZM15 17H13V26H15V17ZM19 19V26H17V19H19ZM23 14H21V26H23V14Z"
        fill={fillColor}
      />
    </svg>
  );
};

export default IconReport;
