import React, { FC } from 'react';
import { useIconColor } from '../../../hooks/useIconColor';

interface IconAdminProps {
  className?: string;
  color?: string;
}

const IconAdmin: FC<IconAdminProps> = (props: IconAdminProps) => {
  const { className, color } = props;

  const { iconColor } = useIconColor();
  const fillColor = color || iconColor;

  return (
    <svg
      className={className}
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5 7.32902C5 5.97629 5.9053 4.79089 7.21032 4.43481L8.89286 3.97573C13.5459 2.70615 18.4541 2.70615 23.1071 3.97573L24.7897 4.43481C26.0947 4.79089 27 5.97629 27 7.32902V12.5975C27 17.0954 24.9816 21.3558 21.5011 24.2048L17.9002 27.1523C16.7949 28.057 15.2051 28.057 14.0998 27.1523L10.4989 24.2048C7.01837 21.3558 5 17.0953 5 12.5975V7.32902Z"
        stroke={fillColor}
        fill="none"
        strokeWidth="2"
      />
      <path
        d="M17.8838 11.9072L16 8L14.1162 11.9072L10 12.5836L12.952 15.6748L12.2918 20L16 18.0032L19.7082 20L19.048 15.6748L22 12.5836L17.8838 11.9072Z"
        fill={fillColor}
      />
    </svg>
  );
};

export default IconAdmin;
