/* eslint-disable import/prefer-default-export */
import { makeStyles } from '@material-ui/core/styles';

interface TypographyStylesProps {
  defaultStyles?: any;
  hoverStyles?: any;
  activeStyles?: any;
  focusStyles?: any;
  disabledStyles?: any;
  selectedStyles?: any;
  toggledStyles?: any;
}

export const useTypographyStyles = (props: TypographyStylesProps) =>
  makeStyles(() => ({
    root: {
      ...props.defaultStyles,

      '&:hover': {
        ...props.hoverStyles,
      },

      '&:active': {
        ...props.activeStyles,
      },

      '&:focus': {
        ...props.focusStyles,
      },

      '&:disabled': {
        ...props.disabledStyles,
      },

      // Used when the hover state is affected by external factors
      // rather than the default mouse over event on the Typography component
      '&.hover': {
        ...props.hoverStyles,
      },

      // Used when the click state is affected by external factors
      // rather than the default mouse down event on the Typography component
      '&.active': {
        ...props.activeStyles,
      },

      // Used when the disabled state is affected by external factors
      '&.disabled': {
        ...props.disabledStyles,
      },

      '&.selected': {
        ...props.selectedStyles,
      },

      '&.toggled': {
        ...props.toggledStyles,
      },
    },
  }));
