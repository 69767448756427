import React, { FC } from 'react';
import { useIconColor } from '../../../hooks/useIconColor';

interface IconCashProps {
  className?: string;
  color?: string;
}

const IconCash: FC<IconCashProps> = (props: IconCashProps) => {
  const { className, color } = props;

  const { iconColor } = useIconColor();
  const fillColor = color || iconColor;

  return (
    <svg
      className={className}
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 9H26V23H6V9ZM4 25V7H28V25H4ZM16.9385 13.7849C17.1627 13.9713 17.3544 14.2222 17.5136 14.5376L18.8915 13.7527C18.6962 13.4086 18.4358 13.0896 18.1103 12.7957C17.792 12.5018 17.3508 12.3082 16.7866 12.2151L16.83 11H15.4846L15.528 12.2043C15.1085 12.276 14.7432 12.4086 14.4322 12.6022C14.1212 12.7957 13.8788 13.0323 13.7052 13.3118C13.5316 13.5842 13.4448 13.8781 13.4448 14.1935C13.4448 14.5591 13.5136 14.8746 13.651 15.1398C13.7957 15.405 13.9729 15.6308 14.1826 15.8172C14.3996 15.9964 14.6239 16.147 14.8553 16.2688C15.094 16.3835 15.3074 16.4767 15.4955 16.5484C15.792 16.6631 16.0597 16.7706 16.2984 16.871C16.5371 16.9713 16.7251 17.086 16.8626 17.2151C17 17.3441 17.0687 17.509 17.0687 17.7097C17.0687 18.2043 16.7577 18.4516 16.1356 18.4516C15.7378 18.4516 15.3978 18.3405 15.1157 18.1183C14.8336 17.8889 14.5624 17.5484 14.302 17.0968L13 18C13.1374 18.2939 13.3291 18.5699 13.575 18.828C13.8282 19.086 14.1212 19.3047 14.4539 19.4839C14.7866 19.6631 15.1447 19.7849 15.528 19.8495L15.4846 21H16.83L16.7866 19.8602C17.4738 19.767 18.0127 19.534 18.4033 19.1613C18.8011 18.7885 19 18.2975 19 17.6882C19 17.2509 18.9096 16.8817 18.7288 16.5806C18.5552 16.2796 18.3201 16.0287 18.0235 15.828C17.7342 15.6272 17.4159 15.466 17.0687 15.3441C16.7649 15.2294 16.4792 15.1254 16.2116 15.0323C15.9512 14.9391 15.7378 14.828 15.5714 14.6989C15.4123 14.5627 15.3327 14.3907 15.3327 14.1828C15.3327 13.9892 15.3978 13.828 15.528 13.6989C15.6655 13.5699 15.8825 13.5054 16.179 13.5054C16.4611 13.5054 16.7143 13.5986 16.9385 13.7849Z"
        fill={fillColor}
      />
    </svg>
  );
};

export default IconCash;
