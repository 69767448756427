/* eslint-disable camelcase */
export type SeelAddress = {
  address_1?: string;
  address_2?: string;
  city?: string;
  state?: string;
  zipcode?: string;
  country?: string;
};

type SeelCreateQuoteItem = {
  product_url: string;
  quantity: number;
  price: number;
  title: string;
  description: string;
  extra_info?: {
    product_id?: string;
    shipping_origin?: string;
    condition?: string;
    treet_shop_id?: string;
    shop_url?: string;
    category_1?: string;
    retail_price?: number;
    size?: string;
    color?: string;
  };
};

type SeelCreateQuoteExtraInfo = {
  customer_id?: string;
  customer_email?: string;
  seller_id?: string;
  seller_username?: string;
  is_verified_direct_brand?: boolean;
};

export type SeelCreateQuoteParams = {
  items: SeelCreateQuoteItem[];
  shippingAddress?: SeelAddress;
  extra_info?: SeelCreateQuoteExtraInfo;
};

export enum SeelCreateQuoteResponseStatus {
  Accepted = 'accepted',
  Rejected = 'rejected',
}

export type SeelCreateQuoteResponse = {
  extraInfo: any;
  price: number; // Formatted in $ amounts, e.g. 1.23
  quoteId: string;
  status: SeelCreateQuoteResponseStatus;
};
