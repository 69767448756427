import { getDefaultShopConfig } from './default';
import * as custom from '../filters/marketplace-custom-config';
import { getBrandDirectFilterConfig } from '../filters/brandDirect';
import { getConditionsFilterConfig } from '../filters/condition';
import { getSizeFilterConfigFromOptions } from '../filters/size';

const esemblybabySizeOptions = [
  { key: 'One', label: 'Size 1 (7-17lbs)' },
  { key: 'Two', label: 'Size 2 (18-35lbs)' },
];

export const esemblybabyShopConfig = {
  ...getDefaultShopConfig(),
  filters: [
    ...custom.filters,
    getConditionsFilterConfig(),
    getBrandDirectFilterConfig(),
    getSizeFilterConfigFromOptions({ options: esemblybabySizeOptions }),
  ],
};
