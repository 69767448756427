import { getDefaultShopConfig } from './default';
import * as custom from '../filters/marketplace-custom-config';
import { getBrandDirectFilterConfig } from '../filters/brandDirect';
import { getConditionsFilterConfig } from '../filters/condition';
import { getSizeFilterConfig } from '../filters/size';

const romanandrileySizes = [
  '3-6m',
  '6M',
  '9-12m',
  '12M',
  '12-18m',
  '18M',
  '18-24m',
  '2-3y',
  '3-4y',
  '4-5y',
  '1T',
  '2T',
  '3T',
  '4T',
  '5T',
  'XS',
  'S',
  'M',
  'L',
  'XL',
  '2XL',
  '3XL',
];

export const romanandrileyShopConfig = {
  ...getDefaultShopConfig(),
  filters: [
    ...custom.filters,
    getConditionsFilterConfig(),
    getBrandDirectFilterConfig(),
    getSizeFilterConfig({ sizes: romanandrileySizes }),
  ],
};
