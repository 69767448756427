import { getDefaultShopConfig } from './default';
import * as custom from '../filters/marketplace-custom-config';
import { getConditionsFilterConfig } from '../filters/condition';
import { getBrandDirectFilterConfig } from '../filters/brandDirect';
import { getSizeFilterConfig } from '../filters/size';

const briarbabySizes = [
  'Newborn',
  '0-3 Months',
  '0-6 Months',
  '3-6 Months',
  '6-12 Months',
  '12-18 Months',
  '12-24 Months',
  '18-24 Months',
  '2-4 Years',
  '4+ Years',
  'Adult Small/Medium',
  'Adult Medium/Large',
];

export const briarbabyShopConfig = {
  ...getDefaultShopConfig(),
  filters: [
    ...custom.filters,
    getConditionsFilterConfig(),
    getBrandDirectFilterConfig(),
    getSizeFilterConfig({ sizes: briarbabySizes }),
  ],
};
