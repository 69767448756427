import { FilterId } from '../../types/filters/filters';
import { getBrandDirectFilterConfig } from '../filters/brandDirect';
import { getConditionsFilterConfig } from '../filters/condition';
import { getSizeFilterConfig } from '../filters/size';
import { getDefaultShopConfig } from './default';
import * as custom from '../filters/marketplace-custom-config';
import { getTagsFilterConfig } from '../filters/styles';

export const minnowTags = [
  { key: 'girls', label: 'girls', tags: ['girls'] },
  { key: 'boys', label: 'boys', tags: ['boys'] },
  { key: 'womens', label: 'womens', tags: ['womens'] },
  { key: 'mens', label: 'mens', tags: ['mens'] },
];

export const minnowShopConfig = {
  ...getDefaultShopConfig(),
  filters: [
    ...custom.filters,
    getConditionsFilterConfig(),
    getBrandDirectFilterConfig(),
    getTagsFilterConfig({ id: FilterId.Style, options: minnowTags }),
    getSizeFilterConfig({
      sizes: ['S', 'M', 'L', '1', '2T', '3/4T', '5/6T', '7/8', '9/10', '11/12'],
    }),
  ],
};
