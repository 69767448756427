import React, { FC } from 'react';
import { useIconColor } from '../../../hooks/useIconColor';

interface IconIntegrationsProps {
  className?: string;
  color?: string;
}

const IconIntegrations: FC<IconIntegrationsProps> = (props: IconIntegrationsProps) => {
  const { className, color } = props;

  const { iconColor } = useIconColor();
  const fillColor = color || iconColor;

  return (
    <svg
      className={className}
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17 3.88672L25.9904 9.07732C26.6092 9.43459 26.9904 10.0948 26.9904 10.8094V21.1906C26.9904 21.9051 26.6092 22.5654 25.9904 22.9226L17 28.1132C16.3812 28.4705 15.6188 28.4705 15 28.1132L6.00964 22.9226C5.39084 22.5654 5.00964 21.9051 5.00964 21.1906V10.8094C5.00964 10.0948 5.39084 9.43459 6.00964 9.07732L15 3.88672C15.6188 3.52946 16.3812 3.52946 17 3.88672ZM14 2.15467C15.2376 1.44014 16.7624 1.44014 18 2.15467L26.9904 7.34527C28.228 8.0598 28.9904 9.38031 28.9904 10.8094V21.1906C28.9904 22.6196 28.228 23.9401 26.9904 24.6547L18 29.8453C16.7624 30.5598 15.2376 30.5598 14 29.8453L5.00964 24.6547C3.77204 23.9401 3.00964 22.6196 3.00964 21.1906V10.8094C3.00964 9.38031 3.77204 8.0598 5.00964 7.34527L14 2.15467ZM16 21C18.7614 21 21 18.7614 21 16C21 13.2385 18.7614 11 16 11C13.2386 11 11 13.2385 11 16C11 18.7614 13.2386 21 16 21ZM19 16C19 17.6568 17.6569 19 16 19C14.3431 19 13 17.6568 13 16C13 14.3431 14.3431 13 16 13C17.6569 13 19 14.3431 19 16Z"
        fill={fillColor}
      />
    </svg>
  );
};

export default IconIntegrations;
