import classNames from 'classnames';
import React, { FC } from 'react';
import { useIntl } from 'react-intl';
import { useLocation } from 'react-router-dom';
import { IconPoweredByTreet, Logo, NamedLink } from '..';
import { POWERED_BY_TREET_TYPES } from '../../shopConfig/config';
import { useShopConfig } from '../../hooks/shopConfig';

import css from './TopbarLogo.module.css';

const TopbarLogo: FC = () => {
  const intl = useIntl();
  const {
    css: shopConfigV2Css,
    poweredByTreetType,
    hideHeaderLogoAtPathnames,
    logoPage,
  } = useShopConfig();
  const location = useLocation();
  const hideHeaderLogo =
    hideHeaderLogoAtPathnames &&
    hideHeaderLogoAtPathnames.some((pathname: string) => location.pathname.startsWith(pathname));
  const topbarLogoStyle = shopConfigV2Css?.topbarLogoStyle;
  return (
    <div className={hideHeaderLogo ? css.hideTopbarLogo : css.topbarLogo}>
      <NamedLink
        className={css.logoLink}
        name={logoPage || 'LandingPage'}
        title={intl.formatMessage({ id: 'Topbar.logoIcon' })}
      >
        <Logo
          className={classNames(css.logo, {
            [css.fullHeightLogo]: poweredByTreetType === POWERED_BY_TREET_TYPES.NONE,
          })}
          style={topbarLogoStyle}
        />
      </NamedLink>
      {poweredByTreetType === POWERED_BY_TREET_TYPES.ICON && (
        <IconPoweredByTreet className={css.poweredByIcon} />
      )}
    </div>
  );
};

export default TopbarLogo;
