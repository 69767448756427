// ================ Action types ================ //
const SET_SUBSCRIBE_SIZES = 'app/manageNotificationsModal/SET_SUBSCRIBE_SIZES';

interface SetSubscribeSizes {
  type: typeof SET_SUBSCRIBE_SIZES;
  subscribeSizes: string[];
}

type ManageNotificationsModalActionType = SetSubscribeSizes;

// ================ Reducer ================ //

export interface ManageNotificationsModalState {
  isManageNotificationsModalOpen: boolean;
  subscribeSizes: string[];
}

const initialState: ManageNotificationsModalState = {
  isManageNotificationsModalOpen: false,
  subscribeSizes: [],
};

export default function reducer(
  state: ManageNotificationsModalState = initialState,
  action: ManageNotificationsModalActionType
): ManageNotificationsModalState {
  switch (action.type) {
    case SET_SUBSCRIBE_SIZES: {
      return {
        ...state,
        subscribeSizes: action.subscribeSizes,
      };
    }
    default:
      return state;
  }
}

// ================ Action creators ================ //

export const setSubscribeSizes = (sizes: string[]): SetSubscribeSizes => ({
  type: SET_SUBSCRIBE_SIZES,
  subscribeSizes: sizes,
});
