import { defaultShoesConditionsOptions, getConditionsFilterConfig } from '../filters/condition';
import { getSizeFilterConfig } from '../filters/size';
import { getDefaultShopConfig } from './default';
import * as custom from '../filters/marketplace-custom-config';
import { getColorFilterConfig } from '../filters/color';

export const pontoShopConfig = {
  ...getDefaultShopConfig(),
  filters: [
    ...custom.filters,
    getColorFilterConfig(),
    getConditionsFilterConfig({ options: defaultShoesConditionsOptions }),
    getSizeFilterConfig({
      sizes: [
        '6',
        '6.5',
        '7',
        '7.5',
        '8',
        '8.5',
        '9',
        '9.5',
        '10',
        '10.5',
        '11',
        '11.5',
        '12',
        '12.5',
        '13',
        '13.5',
        '14',
        'S',
        'M',
        'L',
        'XL',
      ],
    }),
  ],
};
