/* eslint-disable import/prefer-default-export */
import { getSizeFilterConfig } from '../filters/size';
import { getDefaultShopConfig, getDefaultTradeInShopConfig } from './default';
import * as custom from '../filters/marketplace-custom-config';
import { Conditions, getConditionsFilterConfig } from '../filters/condition';

const sijoSizeOptions = [
  'XS',
  'S',
  'M',
  'L',
  'XL',
  'XXL',
  'Crib',
  'Twin',
  'Twin / Twin XL',
  'Twin XL',
  'Standard',
  'Full',
  'Full / Queen',
  'Queen',
  'King',
  'King / Cal King',
  'Cal King',
];

const sijoAdditionalPayoutSettings = {
  additionalShopifyDiscountCodeRequirements: {
    // TODO extract into a fragment
    mutation: { minimumRequirement: { subtotal: { greaterThanOrEqualToSubtotal: '200.00' } } },
  },
  creditCodeHelperText:
    'Code can only be used on sijohome.com for purchases over $200. Limit one code per checkout.',
  tradeInCreditPayoutBundleAmount: 5000,
};

const sijoConditions = [
  {
    key: Conditions.Excellent,
    shortLabel: 'New',
    label:
      'New. Item may be brand new or an unused returned item, and has been inspected and cleaned. Item may or may not have a minor defect such as loose threads, or slight color variation which do not affect the integrity of the product.',
    description: 'Item will be inspected upon receipt.',
  },
  {
    key: Conditions.Good,
    shortLabel: 'Pre-Loved',
    label:
      'Pre-Loved. Item has some gentle wear but has been well-maintained. Item has been inspected and cleaned.',
    description: 'Item will be inspected upon receipt.',
  },
];

export const sijoShopConfig = {
  ...getDefaultShopConfig(),
  ...getDefaultTradeInShopConfig(),
  filters: [
    ...custom.filters,
    getConditionsFilterConfig({ options: sijoConditions }),
    getSizeFilterConfig({ sizes: sijoSizeOptions }),
  ],
  additionalPayoutSettings: sijoAdditionalPayoutSettings,
};
