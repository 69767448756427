/* eslint-disable import/prefer-default-export */
import { useCallback, useState } from 'react';

// Define a type for the hook's return values
interface UseLazyApiResponseType {
  lazyQuery: (params: any) => void;
  called: boolean;
  loading: boolean;
  data: any | null;
  error: Error | null;
}

export const useLazyApi = (
  apiFunction: (params: any) => Promise<{ data: any }>,
  updateQuery?: (previousResult: any, fetchMoreResult: any) => any
): UseLazyApiResponseType => {
  const [called, setCalled] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);

  const executeQuery = async (params: any, previousData: any) => {
    try {
      setCalled(true);
      setLoading(true);
      setError(null);

      const response = await apiFunction(params);
      const responseData = updateQuery ? updateQuery(previousData, response?.data) : response?.data;
      setData(responseData);
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  };

  const lazyQuery = useCallback(
    (params: any) => {
      executeQuery(params, data);
    },
    [data]
  );

  return { lazyQuery, called, loading, data, error };
};
