/* eslint-disable import/prefer-default-export */
import { getSizeFilterConfig } from '../filters/size';
import { getDefaultShopConfig } from './default';
import * as custom from '../filters/marketplace-custom-config';
import { getConditionsFilterConfig } from '../filters/condition';
import { getBrandDirectFilterConfig } from '../filters/brandDirect';

const jennylemonsSizeOptions = [
  'One Size',
  'X Small',
  'Small',
  'Medium',
  'Large',
  'X Large',
  '2X Large',
  '3X Large',
];

export const jennylemonsShopConfig = {
  ...getDefaultShopConfig(),
  filters: [
    ...custom.filters,
    getConditionsFilterConfig(),
    getBrandDirectFilterConfig(),
    getSizeFilterConfig({ sizes: jennylemonsSizeOptions }),
  ],
};
