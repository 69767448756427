/* eslint-disable import/prefer-default-export */
import { useShopConfig } from './shopConfig';
import { useActivePromos } from './useActivePromos';

export const useCreditPayoutPercentage = (
  overrideCreditPayoutPercentage?: number,
  isTradeIn?: boolean
) => {
  const { creditPayoutPercentage: configCreditPayoutPercentage } = useShopConfig();
  const { promoCreditPayoutPercentage } = useActivePromos();

  // Override configCreditPayoutPercentage if this is a trade-in item. Trade-in items should
  // always get paid out at 100% credit, unless there is an active promo.
  const defaultCreditPayoutOption = isTradeIn ? 1 : configCreditPayoutPercentage;

  // Override should be used when there is an existing payout percentage published with the listing
  // and it shouldn't be updated to a new value.
  return overrideCreditPayoutPercentage || promoCreditPayoutPercentage || defaultCreditPayoutOption;
};
