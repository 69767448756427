/* eslint-disable import/prefer-default-export */
import { getDefaultShopConfig, getDefaultTradeInShopConfig } from './default';
import * as custom from '../filters/marketplace-custom-config';
import { Conditions, getConditionsFilterConfig } from '../filters/condition';
import { getSizeFilterConfig } from '../filters/size';
import { AboutTreetPages } from '../../types/shopConfig/shopConfigV2';

const doenConditionsOptions = [
  {
    key: Conditions.NewWithTags,
    label: 'New With Tags',
    description: 'Your item has never been worn and the original hanging tags are still on.',
  },
  {
    key: Conditions.NewWithoutTags,
    label: 'New Without Tags',
    description:
      'Your item has never been worn (other than perhaps trying it on), but the tags have been removed. There are no quirks or issues whatsoever.',
  },
  {
    key: Conditions.Excellent,
    label: 'Excellent Condition',
    description: 'Your item has been worn but is well cared for and generally like-new quality.',
  },
];

export const doenShopConfig = {
  ...getDefaultShopConfig(),
  ...getDefaultTradeInShopConfig(),
  filters: [
    ...custom.filters,
    getSizeFilterConfig({
      sizes: ['XXS', 'XS', 'S', 'M', 'L', 'XL', 'XXL', 'OS', '0', '2', '4', '6', '8', '10', '12'],
    }),
    getConditionsFilterConfig({ options: doenConditionsOptions }),
  ],
  enabledAboutTreetPages: [AboutTreetPages.AboutTreet],
};
