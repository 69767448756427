import React, { FC } from 'react';
import { useIntl } from 'react-intl';
import { FieldCheckbox, FormattedMessage, TypographyWrapper } from '../../components';
import { defaultTreetStyles } from '../../shopConfig/config';
import { PAYMENT_ADDRESS_FIELD_ID } from '../../util/constants';

import css from './StripePaymentForm.module.css';
import { useIsMobile } from '../../hooks/useIsMobile';
import { TypographyWeight } from '../../components/TypographyWrapper/TypographyWrapper';

interface OneTimePaymentWithCardProps {
  cardClasses: string;
  handleStripeElementRef: (el: any) => void;
  hasCardError: boolean;
  error?: string;
  label?: string;
}

const OneTimePaymentWithCard: FC<OneTimePaymentWithCardProps> = (
  props: OneTimePaymentWithCardProps
) => {
  const { cardClasses, handleStripeElementRef, hasCardError, error, label } = props;

  const intl = useIntl();
  const isMobile = useIsMobile();

  const labelText =
    label || intl.formatMessage({ id: 'StripePaymentForm.saveAfterOnetimePayment' });

  return (
    <>
      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
      <label className={css.paymentLabel} htmlFor={`${PAYMENT_ADDRESS_FIELD_ID}-card`}>
        <TypographyWrapper
          variant="body1"
          weight={TypographyWeight.Bold}
          typographyOverrides={{ style: { marginTop: isMobile ? '10px' : 0 } }}
        >
          <FormattedMessage id="StripePaymentForm.paymentCardDetails" />
        </TypographyWrapper>
      </label>
      <div
        className={cardClasses}
        id={`${PAYMENT_ADDRESS_FIELD_ID}-card`}
        ref={handleStripeElementRef}
      />
      {hasCardError && (
        <span className={css.error}>
          <TypographyWrapper
            variant="body1"
            typographyOverrides={{ style: { color: defaultTreetStyles.red80 } }}
          >
            {error}
          </TypographyWrapper>
        </span>
      )}
      <div className={css.saveForLaterUse}>
        <FieldCheckbox
          className={css.saveForLaterUseCheckbox}
          textClassName={css.saveForLaterUseLabel}
          id="saveAfterOnetimePayment"
          name="saveAfterOnetimePayment"
          value="saveAfterOnetimePayment"
          label={labelText}
          useSuccessColor
        />
        <span className={css.saveForLaterUseLegalInfo}>
          <TypographyWrapper
            variant="body2"
            typographyOverrides={{ style: { color: defaultTreetStyles.gray20 } }}
          >
            <FormattedMessage id="StripePaymentForm.saveforLaterUseLegalInfo" />
          </TypographyWrapper>
        </span>
      </div>
    </>
  );
};

export default OneTimePaymentWithCard;
