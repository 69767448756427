/* eslint-disable import/prefer-default-export */
import {
  LISTING_STATE_CLOSED,
  LISTING_STATE_PENDING_APPROVAL,
  LISTING_STATE_PUBLISHED,
} from '../../util/types';
import { ListingState } from '../apollo/generated/types.generated';

export const ST_LISTING_STATE_TO_LISTING_STATUS: {
  [sharetribeState: string]: ListingState;
} = {
  // TODO: add ListingState.DRAFT
  [LISTING_STATE_PENDING_APPROVAL]: ListingState.PendingApproval,
  [LISTING_STATE_PUBLISHED]: ListingState.Open,
  [LISTING_STATE_CLOSED]: ListingState.Closed,
};
