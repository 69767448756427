import { getSizeFilterConfig } from '../filters/size';
import { getDefaultShopConfig } from './default';
import * as custom from '../filters/marketplace-custom-config';
import { getConditionsFilterConfig } from '../filters/condition';
import { getBrandDirectFilterConfig } from '../filters/brandDirect';
import { BuilderSections } from '../../util/builder';

const industryofallnationsSizes = [
  'P',
  'XS',
  'S',
  'M',
  'L',
  'XL',
  'XXL',
  '26',
  '28',
  '30',
  '32',
  '34',
  '36',
];

export const industryofallnationsShopConfig = {
  ...getDefaultShopConfig(),
  filters: [
    ...custom.filters,
    getBrandDirectFilterConfig(),
    getConditionsFilterConfig(),
    getSizeFilterConfig({
      sizes: industryofallnationsSizes,
    }),
  ],
  builderConfig: {
    sections: {
      [BuilderSections.TopbarContent]: 'df0383bee095477babb8e089f9107f16',
      [BuilderSections.TopbarMobileContent]: '03d305ce7e0543bb8f49d5b4035290f4',
      [BuilderSections.AboutPageInfoTabContent]: 'd992fb02e0e74b469b0baadec33093fb',
    },
  },
};
