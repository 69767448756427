import { getConditionsFilterConfig } from '../filters/condition';
import { getBrandDirectFilterConfig } from '../filters/brandDirect';
import { getSizeFilterConfig } from '../filters/size';
import { getDefaultShopConfig } from './default';
import * as custom from '../filters/marketplace-custom-config';

const kpopexchangeSizes = ['XXS', 'XS', 'S', 'M', 'L', 'XL', 'XXL', 'XXXL', '4XL', 'One Size'];

export const kpopexchangeShopConfig = {
  ...getDefaultShopConfig(),
  filters: [
    ...custom.filters,
    getBrandDirectFilterConfig(),
    getConditionsFilterConfig(),
    getSizeFilterConfig({ sizes: kpopexchangeSizes }),
  ],
};
