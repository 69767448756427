import React from 'react';

interface ExternalLinkProps {
  children: React.ReactNode;
  href: string;
  className?: string;
  title?: string;
}

// External link that opens in a new tab/window, ensuring that the
// opened page doesn't have access to the current page.
const ExternalLink: React.FC<ExternalLinkProps> = ({ children, href, className, title }) => (
  <a href={href} target="_blank" rel="noopener noreferrer" className={className} title={title}>
    {children}
  </a>
);

export default ExternalLink;
