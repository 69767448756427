/* eslint-disable import/prefer-default-export */
import { isEmpty } from 'lodash';
import { Currency } from '../../types/apollo/generated/types.generated';
import { ITradeInRestriction } from '../../types/contentful/types.generated';
import { Listing, ListingItemType } from '../../types/sharetribe/listing';
import { TradeInRestrictionType } from '../../types/shopConfig/shopConfigV2';
import { getTotalPayoutValueForListings } from '../../util/listings/listing';

export enum SubmitTradeInsFormFields {
  ListingIds = 'listingIds',
}

export const getPotentialTradeInCreditValueSumForListings = (
  listings: Listing[]
): { amount: number; currency: Currency } | null => {
  if (isEmpty(listings)) {
    return null;
  }

  const areAllTradeInListings = listings.every(
    (listing) => listing?.attributes?.publicData?.itemType !== ListingItemType.TradeIn
  );
  if (!areAllTradeInListings) {
    return null;
  }

  // TODO: update if brand credit currency can differ from listing's currency.
  const defaultCurrency = listings[0].attributes.price.currency;

  const { credit } = getTotalPayoutValueForListings(listings);

  return { amount: credit, currency: defaultCurrency };
};

export const validateTradeIn = (
  tradeInRestrictions: ITradeInRestriction[],
  listingIds: string[]
) => {
  let restrictionErrorMessage = null;
  tradeInRestrictions.forEach((tradeInRestriction) => {
    switch (tradeInRestriction.type) {
      case TradeInRestrictionType.MinimumNumberOfItems: {
        if (listingIds.length < tradeInRestriction.value) {
          restrictionErrorMessage = `Trade-in submission requires a minimum of ${tradeInRestriction.value} items.`;
        }
        break;
      }
      case TradeInRestrictionType.MaximumNumberOfItems: {
        if (listingIds.length > tradeInRestriction.value) {
          restrictionErrorMessage = `Trade-in submission requires a maximum of ${tradeInRestriction.value} items.`;
        }
        break;
      }
      default:
    }
  });
  return restrictionErrorMessage;
};
