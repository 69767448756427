import React, { FC } from 'react';
import { useIconColor } from '../../../hooks/useIconColor';

interface IconStarUnfilledProps {
  className?: string;
  color?: string;
}

const IconStarUnfilled: FC<IconStarUnfilledProps> = (props: IconStarUnfilledProps) => {
  const { className, color } = props;

  const { iconColor } = useIconColor();
  const fillColor = color || iconColor;

  return (
    <svg
      className={className}
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        xmlns="http://www.w3.org/2000/svg"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.4951 11.2463L16.3148 3L12.1345 11.2463L3 12.6738L9.55088 19.1977L8.0858 28.3262L16.3148 24.112L24.5438 28.3262L23.0787 19.1977L29.6296 12.6738L20.4951 11.2463ZM25.4228 14.0406L19.1744 13.0641L16.3148 7.42326L13.4552 13.0641L7.20677 14.0406L11.6879 18.5034L10.6857 24.7477L16.3148 21.865L21.9439 24.7477L20.9417 18.5034L25.4228 14.0406Z"
        fill={fillColor}
      />
    </svg>
  );
};

export default IconStarUnfilled;
