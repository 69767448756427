import React, { FC } from 'react';
import { Box } from '@material-ui/core';
import { Field, useFormState } from 'react-final-form';
import ISOHeader from './ISOHeader';
import TypographyWrapper from '../TypographyWrapper/TypographyWrapper';
import { IconGemFilled, ShopifyProducts, ShopifyProductSearch } from '..';
import { ShopifyProductsSearchFormField } from '../Shopify/Shopify.utils';
import { required } from '../../util/validators';

const ISOSearchPane: FC = () => {
  const { values } = useFormState();

  const isSearchActive = values[ShopifyProductsSearchFormField.IsSearchActive];

  return (
    <Box display="flex" flexDirection="column" height="100%">
      <ISOHeader headerCopy="Submit An Item Request" />
      <Box display="flex" flexDirection="column" alignItems="center" pt={{ xs: 10, md: 5 }} px={2}>
        <Box pb={1}>
          <IconGemFilled />
        </Box>
        <TypographyWrapper variant="body1" typographyOverrides={{ align: 'center' }}>
          We’ll find the exact item you have in mind and let you know when it lists!
        </TypographyWrapper>
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        flexGrow={1}
        px={{ xs: 2, sm: 4 }}
        pt={{ xs: 5, md: 3 }}
        pb={{ xs: 12, md: 3 }}
        position="relative"
      >
        <ShopifyProductSearch />
        {!isSearchActive && <ShopifyProducts />}
        <Field
          // TODO: May need to change to a different field that is shared between shopify product
          // AND cannot find my item flow
          id="shopifyProduct"
          name="shopifyProduct"
          input={<input type="hidden" />}
          validate={required('Please select an item.')}
          hidden
          component="input"
        />
      </Box>
    </Box>
  );
};

export default ISOSearchPane;
