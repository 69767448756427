import React, { FC } from 'react';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Box } from '@material-ui/core';
import { useShopConfig } from '../../hooks/shopConfig';
import { FAQ, FontConfigSection } from '../../types/shopConfig/shopConfigV2';
import { ShipDeadline } from '../../util/shipping';
import ExternalLink from '../ExternalLink/ExternalLink';
import { StyledAccordion, TypographyWrapper } from '..';
import css from './TradeInFAQ.module.css';
import { usePageTitles } from '../../hooks/usePageTitles';
import { isGiftCardOption } from '../../shopConfig/shops/default';
import { useActionCopy } from '../../hooks/useActionCopy';
import { useYotpo } from '../../hooks/useYotpo';

interface FAQTextProps {
  children: string;
}

const FAQTitle: FC<FAQTextProps> = ({ children }) => {
  const { fontConfig } = useShopConfig();
  const typographyVariant = fontConfig[FontConfigSection.AboutPageFAQTitle];

  return (
    <TypographyWrapper
      variant={typographyVariant}
      typographyOverrides={{ style: { fontWeight: 'bold' } }}
    >
      {children}
    </TypographyWrapper>
  );
};

const FAQDescription: FC<FAQTextProps> = ({ children }) => (
  <TypographyWrapper variant="body1" typographyOverrides={{ style: { whiteSpace: 'pre-wrap' } }}>
    {children}
  </TypographyWrapper>
);

const WhyTradeIn: FC = () => {
  const {
    treetShopName,
    shopName,
    copy: { faqCopy },
  } = useShopConfig();

  return (
    <StyledAccordion name="why-trade-in">
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel5a-content"
        id="panel5a-header"
      >
        <p>
          <FAQTitle>{faqCopy[FAQ.WhyTradeIn]?.title || 'Why should I trade in my items?'}</FAQTitle>
        </p>
      </AccordionSummary>
      <AccordionDetails>
        <span>
          <p>
            <FAQDescription>
              {faqCopy[FAQ.WhyTradeIn]?.description ||
                `This is the official destination for trading in your ${shopName} items and we make it easy, fast and fair to get credit for your old items.\n\n1. ${treetShopName} is the easiest way to pass along your ${shopName} items. You can simply select items from your order history for trade-in, or search for an item with a description such as, “white top” – there’s no need to post and manage a sales listing.\n2. We offer clear, transparent and immediate trade-in values. Once you’ve selected your item, we’ll immediately tell you what you will receive in credit for each item if it is in acceptable condition.\n3. And there is almost no waiting – once you ship your items to us and we approve them, we’ll send you a credit for your acceptable items that you can use towards new or pre-loved ${shopName} items – there’s no need to wait until your item sells.\n\nThank you for helping us to pursue responsibility in all aspects of our business – including creating a more circular shopping experience.`}
            </FAQDescription>
          </p>
        </span>
      </AccordionDetails>
    </StyledAccordion>
  );
};

const HowToTradeIn: FC = () => {
  const {
    shopName,
    copy: { faqCopy },
  } = useShopConfig();
  const { listActionCopy } = useActionCopy();

  return (
    <StyledAccordion name="how-to-trade-in">
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel6a-content"
        id="panel6a-header"
      >
        <p>
          <FAQTitle>{faqCopy[FAQ.HowToTradeIn]?.title || 'How do I trade in an item?'}</FAQTitle>
        </p>
      </AccordionSummary>
      <AccordionDetails>
        <span>
          {faqCopy[FAQ.HowToTradeIn]?.description ? (
            <p>
              <FAQDescription>{faqCopy[FAQ.HowToTradeIn].description}</FAQDescription>
            </p>
          ) : (
            <>
              <p>
                <TypographyWrapper variant="body1">
                  Listing your item is easy, simply click “{listActionCopy}” in the upper right-hand
                  corner, create an account (tip: use the same email you’ve used to purchase from{' '}
                  {shopName} so you can see your order history), and go through the trade-in
                  process. Once you’ve added all the applicable information, click “Submit” and
                  print out your shipping label to mail the item back to us.
                </TypographyWrapper>
              </p>
            </>
          )}
        </span>
      </AccordionDetails>
    </StyledAccordion>
  );
};

const HowToShipTradeIn: FC = () => {
  const {
    copy: { faqCopy },
  } = useShopConfig();

  return (
    <StyledAccordion name="how-to-ship-trade-in">
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel8a-content"
        id="panel8a-header"
      >
        <p>
          <FAQTitle>{faqCopy[FAQ.HowToShip]?.title || 'How do I ship my item?'}</FAQTitle>
        </p>
      </AccordionSummary>
      <AccordionDetails>
        <p>
          <FAQDescription>
            {faqCopy[FAQ.HowToShip]?.description ||
              `After you’ve submitted your items for trade-in, you’ll receive an email that includes a prepaid USPS shipping label for you to print and drop off your item at a verified USPS location within ${ShipDeadline.TradeIn} days. Your shipping label will expire after the ${ShipDeadline.TradeIn}th day.`}
          </FAQDescription>
        </p>
      </AccordionDetails>
    </StyledAccordion>
  );
};

const TradeInReviewProcess: FC = () => {
  const {
    copy: { faqCopy },
  } = useShopConfig();

  return (
    <StyledAccordion name="trade-in-review-process">
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel7a-content"
        id="panel7a-header"
      >
        <p>
          <FAQTitle>
            {faqCopy[FAQ.TradeInReviewProcess]?.title ||
              'How does the review process work after my item has been received?'}
          </FAQTitle>
        </p>
      </AccordionSummary>
      <AccordionDetails>
        <p>
          <FAQDescription>
            {faqCopy[FAQ.TradeInReviewProcess]?.description ||
              'Once your item has been received, we review the item to make sure that it is accurately described.'}
          </FAQDescription>
        </p>
      </AccordionDetails>
    </StyledAccordion>
  );
};

const TradeInHowToGetPaid: FC = () => {
  const {
    shopName,
    treetShopName,
    creditPayoutOption,
    copy: { faqCopy },
  } = useShopConfig();
  const { salesTitle } = usePageTitles();
  const isPayoutGiftCard = isGiftCardOption(creditPayoutOption);
  const { doesBrandUseLoyaltyPoints } = useYotpo();
  const brandLoyaltyPointsDescription = `and your ${shopName} rewards account will be credited with loyalty points. You can also view these details by logging into your account and going to “${salesTitle}”.`;
  const brandCreditDescription = `with your credit amount and ${
    isPayoutGiftCard ? 'gift card' : 'discount'
  } code. You can also view these details by logging into your account and going to “${salesTitle}”.`;

  return (
    <StyledAccordion name="trade-in-get-paid">
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel14a-content"
        id="panel4a-header"
      >
        <p>
          <FAQTitle>{faqCopy[FAQ.TradeInGetPaid]?.title || 'How and when do I get paid?'}</FAQTitle>
        </p>
      </AccordionSummary>
      <AccordionDetails>
        <Box>
          <p>
            <FAQDescription>
              {faqCopy[FAQ.TradeInGetPaid]?.description ||
                `Each item is priced through our ${treetShopName} portal — when going through ` +
                  `the process, you will be able to see an estimated payout before submitting ` +
                  `your item for sale. Once your item is delivered and verified by ${shopName}, ` +
                  `you’ll receive an email ${
                    doesBrandUseLoyaltyPoints
                      ? brandLoyaltyPointsDescription
                      : brandCreditDescription
                  }`}
            </FAQDescription>
          </p>
        </Box>
      </AccordionDetails>
    </StyledAccordion>
  );
};

const TradeInPayoutDetails: FC = () => {
  const {
    shopName,
    mainSite,
    creditPayoutOption,
    copy: { faqCopy },
    additionalPayoutSettings = {},
  } = useShopConfig();
  const isPayoutGiftCard = isGiftCardOption(creditPayoutOption);
  const { doesBrandUseLoyaltyPoints, linkToBrandLoyaltyPointsPage } = useYotpo();
  const { creditCodeHelperText } = additionalPayoutSettings;

  const shopNameHyperlinked = (
    <ExternalLink className={css.externalLink} href={mainSite}>
      {shopName}
    </ExternalLink>
  );

  return (
    <StyledAccordion name="trade-in-payout-details">
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel9a-content"
        id="panel9a-header"
      >
        <p>
          <FAQTitle>
            {faqCopy[FAQ.TradeInPayoutDetails]?.title || 'How does the store credit work?'}
          </FAQTitle>
        </p>
      </AccordionSummary>
      <AccordionDetails>
        <p>
          <FAQDescription>
            {faqCopy[FAQ.TradeInPayoutDetails] ? (
              faqCopy[FAQ.TradeInPayoutDetails]?.description
            ) : (
              <>
                {doesBrandUseLoyaltyPoints && (
                  <TypographyWrapper variant="body1">
                    We issue store credit as loyalty points to be redeemed on {shopNameHyperlinked}.
                    Your {shopName} rewards account will be credited after {shopName} verifies your
                    item(s). Learn more about the {shopName} loyalty program{' '}
                    <ExternalLink className={css.externalLink} href={linkToBrandLoyaltyPointsPage}>
                      <TypographyWrapper
                        variant="body1"
                        typographyOverrides={{ display: 'inline' }}
                      >
                        here.
                      </TypographyWrapper>
                    </ExternalLink>{' '}
                    {creditCodeHelperText}
                  </TypographyWrapper>
                )}
                {!doesBrandUseLoyaltyPoints && (
                  <TypographyWrapper variant="body1">
                    We issue store credit as {isPayoutGiftCard ? 'gift cards' : 'discount codes'} to
                    be redeemed on {shopNameHyperlinked}. You’ll receive the{' '}
                    {isPayoutGiftCard ? 'gift card' : 'discount'} code via email after {shopName}{' '}
                    verifies your item(s). {creditCodeHelperText}
                  </TypographyWrapper>
                )}
              </>
            )}
          </FAQDescription>
        </p>
      </AccordionDetails>
    </StyledAccordion>
  );
};

const MisrepresentationClaim: FC = () => {
  const {
    copy: { faqCopy },
  } = useShopConfig();

  return (
    <StyledAccordion name="misrepresentation-claim">
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel10a-content"
        id="panel10a-header"
      >
        <p>
          <FAQTitle>
            {faqCopy[FAQ.TradeInRejected]?.title || 'What happens if my item is rejected?'}
          </FAQTitle>
        </p>
      </AccordionSummary>
      <AccordionDetails>
        <span>
          <p>
            <FAQDescription>
              {faqCopy[FAQ.TradeInRejected]?.description ||
                'We will accept items that are in good condition. Should an item be rejected, we do not intend to resell it, but rather remain committed to our environmental responsibilities by repurposing the item through transparent, domestic opportunities such as remaking/repurposing. Items will never be exported overseas, incinerated, or sent to a landfill.'}
            </FAQDescription>
          </p>
        </span>
      </AccordionDetails>
    </StyledAccordion>
  );
};

const TradeInCreditAmount: FC = () => {
  const {
    copy: { faqCopy },
  } = useShopConfig();
  return (
    <>
      <StyledAccordion name="trade-in-credit-amount">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel10a-content"
          id="panel10a-header"
        >
          <p>
            <FAQTitle>
              {faqCopy[FAQ.TradeInCreditAmount]?.title ||
                'How much will I get paid for items I trade in?'}
            </FAQTitle>
          </p>
        </AccordionSummary>
        <AccordionDetails>
          <span>
            <p>
              <FAQDescription>
                {faqCopy[FAQ.TradeInCreditAmount]?.description ||
                  'We offer clear, transparent and immediate trade-in values. Once you’ve selected your item, we’ll immediately tell you what you will receive in credit for each item if it is in acceptable condition. You can simply select items from your order history for trade-in, or search for an item with a description.'}
              </FAQDescription>
            </p>
          </span>
        </AccordionDetails>
      </StyledAccordion>
    </>
  );
};

const TradeInFAQ: FC = () => (
  <div className={css.root}>
    <WhyTradeIn />
    <HowToTradeIn />
    <HowToShipTradeIn />
    <TradeInReviewProcess />
    <TradeInHowToGetPaid />
    <TradeInPayoutDetails />
    <MisrepresentationClaim />
    <TradeInCreditAmount />
  </div>
);

export default TradeInFAQ;
