import { getDefaultBrandDirectOnlyShopConfig, getDefaultShopConfig } from './default';
import * as custom from '../filters/marketplace-custom-config';
import { getConditionsFilterConfig } from '../filters/condition';
import { getSizeFilterConfig } from '../filters/size';
import { FooterLogo, HeroAction } from '../../types/shopConfig/shopConfigV2';
import { getGenderFilterConfig } from '../filters/gender';
import { BuilderSections } from '../../util/builder';

export const tentreeShopConfig = {
  ...getDefaultShopConfig(),
  ...getDefaultBrandDirectOnlyShopConfig('tentree'),
  filters: [
    ...custom.filters,
    getGenderFilterConfig(),
    getConditionsFilterConfig(),
    getSizeFilterConfig({
      sizes: ['XS', 'S', 'M', 'L', 'XL', 'XXL', 'ONE'],
    }),
  ],
  heroActions: [
    { type: HeroAction.Shop },
    {
      type: HeroAction.ExternalLink,
      config: { label: 'Send In', url: 'https://tentree.supercircle.world/landing/' },
    },
  ],
  footerLogos: [FooterLogo.Brand, FooterLogo.Treet, FooterLogo.SuperCircle],
  builderConfig: {
    sections: {
      [BuilderSections.LandingPageContent]: 'e311718e8acc41419ae746bdb0c32456',
    },
  },
};
