import React from 'react';
import { Box } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { IconSearch } from '..';
import { ModalType, setActiveModal } from '../../ducks/modal.duck';

const TopbarSearch = (props) => {
  const { className } = props;

  const dispatch = useDispatch();

  const toggleSearchModal = () => {
    dispatch(setActiveModal(ModalType.SearchModal));
  };

  return (
    // Classname used for Heap tracking
    <Box
      className={`${className || ''} topbar-search`}
      onClick={toggleSearchModal}
      role="button"
      tabIndex={0}
      aria-label="Search"
      minWidth="24px"
    >
      <IconSearch />
    </Box>
  );
};

export default TopbarSearch;
