import React, { FC } from 'react';

interface IconShipmentProps {
  className?: string;
}

const IconShipment: FC<IconShipmentProps> = (props: IconShipmentProps) => {
  const { className } = props;

  return (
    <svg className={className} viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g opacity="0.15" clipPath="url(#clip0_940_3590)">
        <path d="M-3.12598 17.8945H50.1485V66.462H-3.12598V17.8945Z" fill="#F8F8F8" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-3.98242 17.8949C-3.98242 17.4222 -3.59926 17.0391 -3.12661 17.0391H50.1479C50.6205 17.0391 51.0037 17.4222 51.0037 17.8949V66.4624C51.0037 66.935 50.6205 67.3182 50.1479 67.3182H-3.12661C-3.59926 67.3182 -3.98242 66.935 -3.98242 66.4624V17.8949ZM-2.27079 18.7507V65.6066H49.2921V18.7507H-2.27079Z"
          fill="#292929"
        />
        <rect
          x="33.8877"
          y="17.8945"
          width="16.2605"
          height="48.5675"
          fill="#D1D1D1"
          stroke="#292929"
          strokeWidth="1.81861"
          strokeLinejoin="round"
        />
        <path d="M33.8877 17.8945H50.0227L55.497 32.2294H39.3621L33.8877 17.8945Z" fill="#D1D1D1" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M33.1822 17.4095C33.3419 17.1776 33.6055 17.0391 33.8871 17.0391H50.022C50.3769 17.0391 50.6949 17.2581 50.8215 17.5896L56.2959 31.9245C56.3964 32.1875 56.361 32.4832 56.2013 32.7151C56.0416 32.9471 55.778 33.0856 55.4964 33.0856H39.3614C39.0066 33.0856 38.6885 32.8666 38.5619 32.5351L33.0876 18.2002C32.9871 17.9371 33.0225 17.6415 33.1822 17.4095ZM35.13 18.7507L39.9507 31.374H54.2535L49.4328 18.7507H35.13Z"
          fill="#292929"
        />
        <rect x="2.43555" y="45.4961" width="17.5442" height="15.8326" fill="#292929" />
        <rect x="4.5752" y="48.2773" width="6.63257" height="1.49768" fill="#F8F8F8" />
        <rect x="4.5752" y="51.2734" width="9.41397" height="1.49768" fill="#F8F8F8" />
        <rect x="4.5752" y="54.2695" width="7.91629" height="1.49768" fill="#F8F8F8" />
        <rect x="4.5752" y="57.4766" width="10.2698" height="1.49768" fill="#F8F8F8" />
        <path
          d="M-3.11133 17.8945H33.8882L28.7387 32.2294H-8.26074L-3.11133 17.8945Z"
          fill="#F8F8F8"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-3.91738 17.6056C-3.7953 17.2657 -3.47306 17.0391 -3.11195 17.0391H33.8875C34.1661 17.0391 34.4273 17.1747 34.5875 17.4025C34.7478 17.6304 34.7871 17.922 34.693 18.1842L29.5435 32.5191C29.4215 32.8589 29.0992 33.0856 28.7381 33.0856H-8.26137C-8.53995 33.0856 -8.80111 32.95 -8.96138 32.7221C-9.12165 32.4943 -9.16097 32.2026 -9.06679 31.9405L-3.91738 17.6056ZM-2.51002 18.7507L-7.04458 31.374H28.1362L32.6707 18.7507H-2.51002Z"
          fill="#292929"
        />
        <rect
          x="39.4502"
          y="39.2891"
          width="5.13489"
          height="10.6977"
          rx="2.56745"
          fill="#F8F8F8"
        />
      </g>
      <defs>
        <clipPath id="clip0_940_3590">
          <rect width="64" height="64" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default IconShipment;
