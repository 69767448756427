import { getDefaultShopConfig, getDefaultTradeInShopConfig } from './default';
import * as custom from '../filters/marketplace-custom-config';
import { Conditions, getConditionsFilterConfig } from '../filters/condition';

const mignonnegaviganConditionsOptions = [
  {
    key: Conditions.NewWithoutTags,
    label: 'Like New',
    description:
      'Your item is brand new or has only been lightly used, has been extremely well maintained and has no signs of wear.',
  },
  {
    key: Conditions.Good,
    label: 'Good',
    description:
      'Your item has been well worn with some small signs of wear, and is in overall good + working condition (i.e. No broken or missing parts, tarnishes, rips or tears).',
  },
];

export const mignonnegaviganShopConfig = {
  ...getDefaultShopConfig(),
  ...getDefaultTradeInShopConfig(),
  filters: [
    ...custom.filters,
    getConditionsFilterConfig({ options: mignonnegaviganConditionsOptions }),
  ],
};
