import { Grid } from '@material-ui/core';
import React, { FC, useState } from 'react';
import { Form as FinalForm } from 'react-final-form';
import { useSelector } from 'react-redux';
import { Button, FieldTextInput, InlineTextButton, TypographyWrapper } from '../../components';
import { validateVoucher, VoucherifyState } from '../../ducks/voucherify.duck';
import { useAppDispatch } from '../../hooks/appDispatch';
import { RequestStatus } from '../../types/requestStatus';
import { handle } from '../../util/helpers';
import { CheckoutPageState, fetchOrderLineItems, setVoucher } from './CheckoutPage.duck';

import css from './DiscountCodeInput.module.css';

const DiscountCodeInput: FC = () => {
  const dispatch = useAppDispatch();

  const { fetchLineItemsStatus } = useSelector<any>(
    (state) => state.CheckoutPage
  ) as CheckoutPageState;
  const { validateVoucherStatus } = useSelector<any>(
    (state) => state.voucherify
  ) as VoucherifyState;

  const [shouldShowInput, setShouldShowInput] = useState<boolean>(false);
  const [discountCodeError, setDiscountCodeError] = useState<string | undefined>(undefined);

  const onApplyDiscountCode = async (values: { discountCode?: string }) => {
    const { discountCode } = values;
    if (!discountCode) return;

    const [voucherResponse, voucherError] = await handle(dispatch(validateVoucher(discountCode)));
    await dispatch(setVoucher(voucherResponse));

    if (voucherError) {
      setDiscountCodeError('This discount code is not valid.');
    } else {
      setDiscountCodeError(undefined);
    }
    dispatch(fetchOrderLineItems());
  };

  if (!shouldShowInput) {
    return (
      <InlineTextButton onClick={() => setShouldShowInput(true)}>
        <h5 className={css.addLink}>
          <TypographyWrapper variant="body2">Add a discount code</TypographyWrapper>
        </h5>
      </InlineTextButton>
    );
  }

  const isDiscountApplicationLoading = validateVoucherStatus === RequestStatus.Pending;
  const shouldDisableApplication =
    isDiscountApplicationLoading || fetchLineItemsStatus !== RequestStatus.Success;

  return (
    <FinalForm
      onSubmit={onApplyDiscountCode}
      render={(formRenderProps) => {
        const { handleSubmit } = formRenderProps;
        return (
          <form id="discountCodeForm" onSubmit={handleSubmit}>
            <Grid
              className={css.discountCodeWrapper}
              container
              direction="row"
              alignItems="center"
              spacing={3}
            >
              <Grid item xs={9}>
                <FieldTextInput
                  id="discountCode"
                  name="discountCode"
                  placeholder="TREET20OFF"
                  label="Discount Code"
                  customErrorText={discountCodeError}
                />
              </Grid>
              <Grid item xs={3} className={css.applyButtonWrapper}>
                <Button
                  type="submit"
                  form="discountCodeForm"
                  inProgress={isDiscountApplicationLoading}
                  disabled={shouldDisableApplication}
                  className={css.applyButton}
                >
                  Apply
                </Button>
              </Grid>
            </Grid>
          </form>
        );
      }}
    />
  );
};

export default DiscountCodeInput;
