/* eslint-disable import/prefer-default-export */
import { getConditionsFilterConfig } from '../filters/condition';
import { getDefaultShopConfig, getDefaultTradeInShopConfig } from './default';
import * as custom from '../filters/marketplace-custom-config';
import { getSizeFilterConfig } from '../filters/size';
import { getBrandDirectFilterConfig } from '../filters/brandDirect';
import { BuilderSections } from '../../util/builder';

const joyfolieSizes = [
  'XXS',
  'XS',
  'S',
  'M',
  'L',
  'XL',
  'XXL',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  '10',
  '12',
  '14',
  '6.5',
  '7.5',
  '8.5',
  '9.5',
  '10.5',
  '8T',
  '9T',
  '10T',
  '11T',
  '12T',
  '13T',
  '1Y',
  '2Y',
  '3Y',
  '4Y',
  '6Y',
  '5Y',
];

export const joyfolieShopConfig = {
  ...getDefaultShopConfig(),
  ...getDefaultTradeInShopConfig(),
  filters: [
    ...custom.filters,
    getBrandDirectFilterConfig(),
    getConditionsFilterConfig(),
    getSizeFilterConfig({ sizes: joyfolieSizes }),
  ],
  builderConfig: {
    sections: {
      [BuilderSections.LandingPageContent]: 'ec201607180a4f46b1e1a4521c3184af',
    },
  },
};
