import { getDefaultShopConfig } from './default';
import * as custom from '../filters/marketplace-custom-config';
import { getBrandDirectFilterConfig } from '../filters/brandDirect';
import { Conditions, getConditionsFilterConfig } from '../filters/condition';
import { getSizeFilterConfig } from '../filters/size';

const baabukSizes: string[] = [
  'Small',
  'Medium',
  'Large',
  'X-Large',
  'One Size',
  '22',
  '23',
  '24',
  '25',
  '26',
  '27',
  '28',
  '29',
  '30',
  '31',
  '32',
  '33',
  '34',
  '35',
  '36',
  '37',
  '38',
  '39',
  '40',
  '41',
  '42',
  '43',
  '44',
  '45',
  '46',
  '47',
  '48',
];

const baabukConditionOptions = [
  {
    key: Conditions.NewWithTags,
    label: 'New With Box',
    description:
      'Your item has never been worn and you still have the original box. For this option, you’ll need to take pictures of the shoes in the box.',
  },
  {
    key: Conditions.NewWithoutTags,
    label: 'New Without Box',
    description:
      'Your item has never been worn (other than perhaps trying it on), but you no longer have the box. There are no quirks or issues whatsoever.',
  },
  {
    key: Conditions.Good,
    label: 'Good',
    description:
      'You’ve worn this item and it’s well maintained. There may be some quirks like a small stain/tear or faded color, but it’s overall in good shape. You will need to take pictures of any quirks the item has.',
  },
];

export const baabukShopConfig = {
  ...getDefaultShopConfig(),
  filters: [
    ...custom.filters,
    getConditionsFilterConfig({
      options: baabukConditionOptions,
    }),
    getBrandDirectFilterConfig(),
    getSizeFilterConfig({ sizes: baabukSizes }),
  ],
};
