import { Box, BoxProps, Typography } from '@material-ui/core';
import React, { FC, ReactElement } from 'react';
import { NamedLink } from '../../components';
import IconArrowTopRight from '../../components/Icons/IconArrowTopRight/IconArrowTopRight';
import IconLightningBolt from '../../components/Icons/IconLightningBolt/IconLightningBolt';
import IconSell from '../../components/Icons/IconSell/IconSell';
import IconShipment from '../../components/Icons/IconShipment/IconShipment';
import IconSparklesLeft from '../../components/Icons/IconSparklesLeft/IconSparklesLeft';
import IconSparklesRight from '../../components/Icons/IconSparklesRight/IconSparklesRight';
import { useShopConfig } from '../../hooks/shopConfig';
import { useIsMobile } from '../../hooks/useIsMobile';
import { defaultTreetStyles } from '../../shopConfig/config';
import { getDarkerBrandCssBackgroundColor } from '../../util/colors/colors';
import { formatPayoutPercentage } from '../../util/currency';
import css from './ManageClosetPage.module.css';

interface InfoCardContentProps {
  title: string;
  description: string;
  tagline: string;
  taglineIconLeft: ReactElement;
  taglineIconRight: ReactElement;
}

const InfoCardContent: FC<InfoCardContentProps> = (props) => {
  const { title, description, taglineIconLeft, tagline, taglineIconRight } = props;
  const isMobile = useIsMobile();

  return (
    <>
      <Typography
        variant={isMobile ? 'body1' : 'subtitle1'}
        style={{ fontWeight: 'bold', padding: '0 0 8px 0' }}
      >
        {title}
      </Typography>
      <Typography
        variant="body1"
        style={{
          whiteSpace: 'pre-line',
          textAlign: 'center',
          ...(isMobile && { fontSize: '12px' }),
        }}
      >
        {description}
      </Typography>
      <Box display="flex" alignItems="center" justifyContent="center" mt={2}>
        {taglineIconLeft}
        <Typography
          variant="body1"
          style={{
            fontSize: '12px',
            fontWeight: 'bold',
            textTransform: 'uppercase',
            padding: '0 8px',
          }}
        >
          {tagline}
        </Typography>
        {taglineIconRight}
      </Box>
    </>
  );
};

interface InfoCardProps {
  title: string;
  description: string;
  tagline: string;
  icon: ReactElement;
  taglineIconLeft: ReactElement;
  taglineIconRight: ReactElement;
  boxOverrides?: Partial<BoxProps>;
}

const InfoCard: FC<InfoCardProps> = (props) => {
  const { title, description, tagline, boxOverrides, icon, taglineIconLeft, taglineIconRight } =
    props;
  const isMobile = useIsMobile();
  const { css: brandCss } = useShopConfig();
  const darkerBrandCssBackgroundColor = getDarkerBrandCssBackgroundColor(brandCss);

  return (
    <NamedLink name="NewListingPage" className={css.infoCard}>
      <Box
        className={css.banner}
        display="flex"
        flex={1}
        height="160px"
        {...(isMobile && { px: '4px' })}
        alignItems="center"
        justifyContent="flex-end"
        style={{
          backgroundColor: darkerBrandCssBackgroundColor,
          border: `1px solid ${defaultTreetStyles.gray20}`,
        }}
        {...boxOverrides}
      >
        {!isMobile && (
          <Box display="flex" flex={1} justifyContent="left" mt="auto">
            {icon}
          </Box>
        )}
        <Box
          display="flex"
          flex={4}
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
        >
          <InfoCardContent
            title={title}
            description={description}
            tagline={tagline}
            taglineIconLeft={taglineIconLeft}
            taglineIconRight={taglineIconRight}
          />
        </Box>
        {!isMobile && (
          <Box display="flex" flex={1} mb="auto" justifyContent="right" pt="4px" pr="4px">
            <IconArrowTopRight className={css.infoCardsArrow} color={defaultTreetStyles.gray40} />
          </Box>
        )}
      </Box>
    </NamedLink>
  );
};

interface SellOrTradeInInfoCardsProps {
  hasActiveTradeIn: boolean;
}

export const SellOrTradeInInfoCards: FC<SellOrTradeInInfoCardsProps> = (props) => {
  const { hasActiveTradeIn } = props;
  const isMobile = useIsMobile();
  const { creditPayoutPercentage } = useShopConfig();
  const formattedCreditPayout = formatPayoutPercentage(creditPayoutPercentage);

  return (
    <Box mx={isMobile ? 0 : 4} my={2} display="flex">
      <InfoCard
        title="Sell"
        description={`Get ${formattedCreditPayout}% brand credit\nof your item’s sale price when it sells`}
        tagline="Earn More"
        boxOverrides={{ mr: 1 }}
        icon={<IconSell className={css.infoCardsIcon} />}
        taglineIconLeft={<IconSparklesLeft />}
        taglineIconRight={<IconSparklesRight />}
      />
      <InfoCard
        title={hasActiveTradeIn ? 'Add To Trade In' : 'Trade In'}
        description={`Send everything in one shipment\nand get paid once they’re received`}
        tagline="Fast Payout"
        boxOverrides={{ ml: 1 }}
        icon={<IconShipment className={css.infoCardsIcon} />}
        taglineIconLeft={<IconLightningBolt />}
        taglineIconRight={<IconLightningBolt />}
      />
    </Box>
  );
};

export default SellOrTradeInInfoCards;
