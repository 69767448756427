import { Conditions, getConditionsFilterConfig } from '../filters/condition';
import { getSizeFilterConfig } from '../filters/size';
import { getBrandDirectFilterConfig } from '../filters/brandDirect';
import { getDefaultShopConfig } from './default';
import * as custom from '../filters/marketplace-custom-config';
import { BuilderSections } from '../../util/builder';

const pfflyersSizes = [
  'MENS 4 / WMNS 5.5',
  'MENS 4.5 / WMNS 6',
  'MENS 5 / WMNS 6.5',
  'MENS 5.5 / WMNS 7',
  'MENS 6 / WMNS 7.5',
  'MENS 6.5 / WMNS 8',
  'MENS 7 / WMNS 8.5',
  'MENS 7.5 / WMNS 9',
  'MENS 8 / WMNS 9.5',
  'MENS 8.5 / WMNS 10',
  'MENS 9 / WMNS 10.5',
  'MENS 9.5 / WMNS 11',
  'MENS 10 / WMNS 11.5',
  'MENS 10.5 / WMNS 12',
  'MENS 11 / WMNS 12.5',
  'MENS 11.5 / WMNS 13',
  'MENS 12 / WMNS 13.5',
  'MENS 12.5 / WMNS 14',
  'MENS 13 / WMNS 14.5',
  'MENS 14 / WMNS 15.5',
  'MENS 15 / WMNS 16.5',
  'MENS 16 / WMNS 17.5',
];

const pfflyersConditionOptions = [
  {
    key: Conditions.NewWithTags,
    label: 'New With Box',
    description:
      'Your shoes have never been worn and they’re still in the original box. For this option, you’ll need to take pictures of your shoes in the box.',
  },
  {
    key: Conditions.NewWithoutTags,
    label: 'New Without Box',
    description:
      'Your shoes have never been worn (other than perhaps trying it on), but you no longer have the box. There are no quirks or issues whatsoever.',
  },
  {
    key: Conditions.Excellent,
    label: 'Excellent',
    description:
      'Your shoes have only been lightly used and have been extremely well maintained. There may be some small signs of wear, but nothing major.',
  },

  {
    key: Conditions.Good,
    label: 'Good',
    description:
      'You’ve worn the shoes and they’re well maintained. There may be some quirks like a small stain or a scuff mark, but they’re overall in good shape. You will need to take pictures of any quirks your shoes have.',
  },
];

export const pfflyersShopConfig = {
  ...getDefaultShopConfig(),
  filters: [
    ...custom.filters,
    getConditionsFilterConfig({
      options: pfflyersConditionOptions,
    }),
    getBrandDirectFilterConfig(),
    getSizeFilterConfig({ sizes: pfflyersSizes }),
  ],
  builderConfig: {
    sections: {
      [BuilderSections.LandingPageContent]: '4f2b26e6b84a4c2c8e71cc2660aefb15',
      [BuilderSections.TopbarContent]: 'c53168a40f0f438d976b49241d08fe3f',
    },
  },
};
