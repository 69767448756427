import { Conditions, getConditionsFilterConfig } from '../filters/condition';
import { getDefaultShopConfig } from './default';
import * as custom from '../filters/marketplace-custom-config';
import { getBrandDirectFilterConfig } from '../filters/brandDirect';

export const roughandtumbleConditionOptions = [
  {
    key: Conditions.NewWithTags,
    label: 'New With Original Packaging',
    description:
      'Your item has never been used with the exception of test packing, and it contains original packaging.',
  },
  {
    key: Conditions.Excellent,
    label: 'Excellent Condition',
    description:
      'You’ve used this item a handful of times, or possibly to only test pack. There are no signs of wear, and has been extremely well maintained. Also, no damages or issues with the custom.',
  },
];

export const roughandtumbleShopConfig = {
  ...getDefaultShopConfig(),
  filters: [
    ...custom.filters,
    getConditionsFilterConfig({ options: roughandtumbleConditionOptions }),
    getBrandDirectFilterConfig(),
  ],
};
