/**
 * Error logging
 *
 * Can be used to log errors to console or and external
 * error logging system, like Sentry for example.
 *
 */

import * as Sentry from '@sentry/browser';
import { ExtraErrorData } from '@sentry/integrations';
import { responseApiErrorInfo } from './errors';
import { getSubdomain } from './envHelpers';
import { getSessionId } from './heapSession';

/**
 * Set up error handling. If a Sentry DSN is
 * provided a Sentry client will be installed.
 */
export const setup = () => {
  const sentryDsn = process.env.REACT_APP_SENTRY_DSN;
  if (sentryDsn) {
    // Configures the Sentry client. Adds a handler for
    // any uncaught exception.
    Sentry.init({
      dsn: sentryDsn,
      integrations: [new Sentry.BrowserTracing(), new ExtraErrorData({ depth: 10 })],
      normalizeDepth: 11,
      environment: process.env.REACT_APP_ENV,
      release: process.env.HEROKU_RELEASE_VERSION,
      initialScope: {
        tags: { logSource: 'src' },
      },
      denyUrls: [
        // Chrome extensions
        /extensions\//i,
        /^chrome:\/\//i,
        // 3rd Party (i.e. GTM)
        /googletagmanager.com/,
        /js.refiner.io/,
      ],
    });
  }
};

/**
 * Set user ID for the logger so that it
 * can be attached to Sentry issues.
 *
 * @param {String} userId ID of current user
 */
export const setUserId = (userId) => {
  Sentry.configureScope((scope) => {
    scope.setUser({ id: userId });
  });
};

/**
 * Clears the user ID.
 */

export const clearUserId = () => {
  Sentry.configureScope((scope) => {
    scope.setUser(null);
  });
};

const printAPIErrorsAsConsoleTable = (apiErrors) => {
  if (apiErrors != null && apiErrors.length > 0 && typeof console.table === 'function') {
    console.log('Errors returned by Marketplace API call:');
    console.table(apiErrors.map((err) => ({ status: err.status, code: err.code, ...err.meta })));
  }
};

/**
 * Logs an execption. If Sentry is configured
 * sends the error information there. Otherwise
 * prints the error to the console.
 *
 * @param {Error} e Error that occurred
 * @param {String} code Error code
 * @param {Object} data Additional data to be sent to Sentry
 */
export const error = (e, code, data) => {
  const apiErrors = responseApiErrorInfo(e);
  const sentryDsn = process.env.REACT_APP_SENTRY_DSN;
  const sessionId = getSessionId();
  const statusCode = data?.status || e?.status;
  const { treetId } = data || {};

  if (sentryDsn) {
    const extra = {
      ...data,
      apiErrorData: apiErrors,
      rawErrorData: e,
      sessionId,
      userId: typeof window !== 'undefined' ? window.heap?.userId : '',
      identity: typeof window !== 'undefined' ? window.heap?.identity : '',
    };

    const subdomain = getSubdomain();

    Sentry.withScope((scope) => {
      scope.setTag('code', code);
      // Set subdomain since treet ID might not always be passed in.
      scope.setTag('subdomain', subdomain);

      if (statusCode) {
        scope.setTag('status_code', statusCode);
      }
      if (treetId) {
        scope.setTag('treet_id', treetId);
      }
      Object.keys(extra).forEach((key) => {
        scope.setExtra(key, extra[key]);
      });
      Sentry.captureException(e);
    });

    printAPIErrorsAsConsoleTable(apiErrors);
  } else {
    console.error(e);
    console.error('Error code:', code, 'data:', data, 'Status code:', statusCode);
    printAPIErrorsAsConsoleTable(apiErrors);
  }
};
