import { getConditionsFilterConfig } from '../filters/condition';
import { getSizeFilterConfig } from '../filters/size';
import { getDefaultShopConfig } from './default';
import * as custom from '../filters/marketplace-custom-config';

const polejunkieSizes = [
  'XXS',
  'XS',
  'S',
  'M',
  'L',
  'XL',
  'XXL',
  'XXXL',
  'UK 6/EU 34',
  'UK 8/EU 36',
  'UK 10/EU 38',
  'UK 12/EU 40',
  'UK 14/EU 42',
  'UK 16/EU 44',
  'UK 18/EU 46',
  'UK 20/EU 48',
  'Shoes: UK 2/EU 35',
  'Shoes: UK 3/EU 36',
  'Shoes: UK 4/EU 37',
  'Shoes: UK 5/EU 38',
  'Shoes: UK 6/EU 39',
  'Shoes: UK 7/EU 40',
  'Shoes: UK 8/EU 41',
  'Shoes: UK 9/EU 42',
  'Shoes: UK 10/EU 43',
  'O/S',
];

export const polejunkieShopConfig = {
  ...getDefaultShopConfig(),
  filters: [
    ...custom.filters,
    getConditionsFilterConfig(),
    getSizeFilterConfig({ sizes: polejunkieSizes }),
  ],
};
