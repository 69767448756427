/* eslint-disable import/prefer-default-export */
import { BuilderSections } from '../../util/builder';
import { getConditionsFilterConfig } from '../filters/condition';
import { getBrandDirectFilterConfig } from '../filters/brandDirect';
import { getSizeFilterConfig } from '../filters/size';
import { getDefaultShopConfig } from './default';
import * as custom from '../filters/marketplace-custom-config';

const electricyogaSizes = ['X-Small', 'Small', 'Medium', 'Large', 'X-Large'];

export const electricyogaShopConfig = {
  ...getDefaultShopConfig(),
  filters: [
    ...custom.filters,
    getBrandDirectFilterConfig(),
    getConditionsFilterConfig(),
    getSizeFilterConfig({ sizes: electricyogaSizes }),
  ],
  builderConfig: {
    sections: {
      [BuilderSections.LandingPageContent]: 'ec6c7390c830473db8e893b3c9074c31',
    },
  },
};
