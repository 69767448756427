import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { compose } from 'redux';
import { Box } from '@material-ui/core';
import {
  FormattedMessage,
  LimitedAccessBanner,
  Modal,
  SearchModal,
  ShoppingBag,
  ShoppingBagModal,
  TopbarDesktop,
  TopbarMobile,
  TopbarMobileMenu,
} from '..';
import AppContext from '../../context/AppContext';
import config from '../../shopConfig/config';
import { useShopConfig } from '../../hooks/shopConfig';
import { useRouteConfiguration } from '../../hooks/useRouteConfiguration';
import { withViewport } from '../../util/contextHelpers';
import { injectIntl, intlShape } from '../../util/reactIntl';
import { createResourceLocatorString } from '../../util/routes';
import { propTypes } from '../../util/types';
import { ModalParams, parse } from '../../util/urlHelpers';
import { MAX_SMALL_SCREEN_WIDTH, redirectToURLWithoutModalState } from '../../util/window';
import TopbarTransparencyContext from '../../context/TopbarTransparencyContext';
import AdminAccountMismatchBanner from '../AdminAccountMismatchBanner/AdminAccountMismatchBanner';
import { ModalType } from '../../ducks/modal.duck';
import GeneralShopConfigHighlightBanner from '../HighlightBanner/GeneralShopConfigHighlightBanner';
import PromoHighlightBanner from '../HighlightBanner/PromoHighlightBanner';
import css from './Topbar.module.css';

export const TOPBAR_ELEM_ID = 'topbar';

const GenericError = (props) => {
  const { show } = props;
  const classes = classNames(css.genericError, {
    [css.genericErrorVisible]: show,
  });
  return (
    <div className={classes}>
      <div className={css.genericErrorContent}>
        <p className={css.genericErrorText}>
          <FormattedMessage id="Topbar.genericError" />
        </p>
      </div>
    </div>
  );
};

const { bool } = PropTypes;

GenericError.propTypes = {
  show: bool.isRequired,
};

const TopbarComponent = (props) => {
  const {
    className,
    rootClassName,
    desktopClassName,
    mobileRootClassName,
    mobileClassName,
    isAuthenticated,
    authInProgress,
    currentSearchParams,
    currentUser,
    currentPage,
    history,
    viewport,
    intl,
    location,
    onManageDisableScrolling,
    showGenericError,
  } = props;

  const { css: brandCss } = useShopConfig();
  const routes = useRouteConfiguration();
  const { treetId } = useContext(AppContext);
  const isTransparentTopbar = useContext(TopbarTransparencyContext);

  const { activeModal } = useSelector((state) => state.modal);

  const handleMobileMenuClose = () => {
    redirectToURLWithoutModalState(history, location, ModalParams.MobileMenu);
  };

  const handleMobileShoppingBagClose = () => {
    redirectToURLWithoutModalState(history, location, ModalParams.MobileShoppingBag);
  };

  const handleSubmit = (values) => {
    const { search, selectedPlace } = values.location;
    const { origin, bounds } = selectedPlace;
    const originMaybe = config.sortSearchByDistance ? { origin } : {};
    const searchParams = {
      ...currentSearchParams,
      ...originMaybe,
      address: search,
      bounds,
    };
    history.push(createResourceLocatorString('SearchPage', routes, {}, searchParams));
  };

  const {
    [ModalParams.MobileMenu]: mobileMenuParam,
    [ModalParams.MobileShoppingBag]: mobileShoppingBagParam,
    address,
    origin,
    bounds,
  } = parse(location.search);

  const isMobileLayout = viewport.width <= MAX_SMALL_SCREEN_WIDTH;
  const isMobileMenuOpen = isMobileLayout && mobileMenuParam === 'open';
  const isMobileShoppingBagOpen = isMobileLayout && mobileShoppingBagParam === 'open';

  const mobileMenu = (
    <TopbarMobileMenu
      isAuthenticated={isAuthenticated}
      currentUser={currentUser}
      currentPage={currentPage}
    />
  );

  // Only render current search if full place object is available in the URL params
  const locationFieldsPresent = config.sortSearchByDistance
    ? address && origin && bounds
    : address && bounds;
  const initialSearchFormValues = {
    location: locationFieldsPresent
      ? {
          search: address,
          selectedPlace: { address, origin, bounds },
        }
      : null,
  };

  const classes = classNames(rootClassName || css.root, className);

  const backgroundStyles = {
    bgcolor: isTransparentTopbar ? '' : brandCss?.backgroundColor || 'white',
    style: {
      transition: 'background .2s ease-out',
    },
  };

  return (
    <div id={TOPBAR_ELEM_ID} className={classes}>
      <LimitedAccessBanner currentUser={currentUser} currentPage={currentPage} />
      <AdminAccountMismatchBanner currentUser={currentUser} />
      <GeneralShopConfigHighlightBanner currentPage={currentPage} />
      <PromoHighlightBanner />
      <Box
        className={classNames(mobileRootClassName || css.container, mobileClassName, {
          [css.transparentTopbarBackground]: isTransparentTopbar,
          [css.transparentTopbarForeground]: isTransparentTopbar,
        })}
        {...backgroundStyles}
      >
        <TopbarMobile />
      </Box>
      <Box
        className={classNames(css.desktop, {
          [css.transparentTopbarBackground]: isTransparentTopbar,
        })}
        {...backgroundStyles}
      >
        <TopbarDesktop
          className={desktopClassName}
          initialSearchFormValues={initialSearchFormValues}
          intl={intl}
          isAuthenticated={isAuthenticated}
          onSearchSubmit={handleSubmit}
          subdomain={treetId}
        />
      </Box>
      <Modal
        id="TopbarMobileMenu"
        isOpen={isMobileMenuOpen}
        onClose={handleMobileMenuClose}
        usePortal
        onManageDisableScrolling={onManageDisableScrolling}
      >
        {authInProgress ? null : mobileMenu}
      </Modal>
      <Modal
        id="ShoppingBag"
        containerClassName={css.modalContainer}
        isOpen={isMobileShoppingBagOpen}
        onClose={handleMobileShoppingBagClose}
        usePortal
        onManageDisableScrolling={onManageDisableScrolling}
      >
        <div className={css.mobileShoppingBagContainer}>
          <ShoppingBag isOpen={isMobileShoppingBagOpen} className={css.mobileShoppingBag} />
        </div>
      </Modal>
      <ShoppingBagModal isOpen={activeModal === ModalType.ShoppingBag} />
      <SearchModal isOpen={activeModal === ModalType.SearchModal} />
      <GenericError show={showGenericError} />
    </div>
  );
};

TopbarComponent.defaultProps = {
  className: null,
  rootClassName: null,
  desktopClassName: null,
  mobileRootClassName: null,
  mobileClassName: null,
  currentUser: null,
  currentPage: null,
};

const { func, number, shape, string } = PropTypes;

TopbarComponent.propTypes = {
  className: string,
  rootClassName: string,
  desktopClassName: string,
  mobileRootClassName: string,
  mobileClassName: string,
  isAuthenticated: bool.isRequired,
  authInProgress: bool.isRequired,
  currentUser: propTypes.currentUser,
  currentPage: string,
  onManageDisableScrolling: func.isRequired,
  // TODO(TREET-) Renable the missing info modal for unverified emails
  // onResendVerificationEmail: func.isRequired,
  // sendVerificationEmailInProgress: bool.isRequired,
  // sendVerificationEmailError: propTypes.error,
  showGenericError: bool.isRequired,

  // These are passed from Page to keep Topbar rendering aware of location changes
  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({
    search: string.isRequired,
  }).isRequired,

  // from withViewport
  viewport: shape({
    width: number.isRequired,
    height: number.isRequired,
  }).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const Topbar = compose(withViewport, injectIntl)(TopbarComponent);

Topbar.displayName = 'Topbar';

export default Topbar;
