import {
  Filter,
  FilterId,
  GenericFilterOption,
  SearchFilterType,
  SearchNavBarSections,
} from '../../types/filters/filters';

interface GetSizeFilterConfigParams {
  sizes: string[];
}

interface GetSizeFilterHelperParams {
  options: GenericFilterOption[];
  shouldHideNoResultOptions?: boolean;
}

const getSizeFilterHelper = (params: GetSizeFilterHelperParams) => {
  const { options, shouldHideNoResultOptions = false } = params;

  return {
    config: {
      options,
      shouldHideNoResultOptions,
    },
    group: 'primary',
    id: FilterId.Size,
    label: 'Size',
    queryParamNames: ['pub_size'],
    type: SearchFilterType.Checkbox,
    searchNavBarSection: SearchNavBarSections.Filter,
  };
};

export const getSizeFilterConfig = (params: GetSizeFilterConfigParams): Filter => {
  const { sizes } = params;
  return getSizeFilterHelper({
    options: sizes.map((size) => ({ key: size, label: size })),
  });
};

export const getSizeFilterConfigFromOptions = (params: GetSizeFilterHelperParams): Filter =>
  getSizeFilterHelper(params);
