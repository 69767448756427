import React, { FC } from 'react';
import { useIconColor } from '../../../hooks/useIconColor';

interface IconEmailProps {
  className?: string;
  color?: string;
}

const IconEmail: FC<IconEmailProps> = (props) => {
  const { className, color } = props;

  const { iconColor } = useIconColor();
  const fillColor = color || iconColor;

  return (
    <svg
      className={className}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 9H42V39H6V9ZM9 13.8685V32.5322L16.3207 20.1434L9 13.8685ZM18.6338 22.126L10.4355 36H37.5645L29.3662 22.126L24 26.7256L18.6338 22.126ZM31.6793 20.1434L39 32.5322V13.8685L31.6793 20.1434ZM36.5701 12H11.4299L24 22.7744L36.5701 12Z"
        fill={fillColor}
      />
    </svg>
  );
};

export default IconEmail;
