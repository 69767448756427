import { getDefaultShopConfig } from './default';
import * as custom from '../filters/marketplace-custom-config';
import { getBrandDirectFilterConfig } from '../filters/brandDirect';
import { getSizeFilterConfig } from '../filters/size';
import { getConditionsFilterConfig } from '../filters/condition';

const mikimietteSizes = [
  'OS',
  '7',
  '8',
  '10',
  '12',
  '14',
  '16',
  'Infant',
  'Youth',
  'Small',
  'Medium',
  'Large',
  'X Large',
  'nb',
  '3m',
  '6m',
  '9m',
  '12m',
  '18m',
  '24m',
  '2T',
  '3T',
  '4T',
  '5Y',
  '6Y',
  '7Y',
  '8Y',
  '10Y',
  '14Y',
  '12Y',
];

export const mikimietteShopConfig = {
  ...getDefaultShopConfig(),
  filters: [
    ...custom.filters,
    getBrandDirectFilterConfig(),
    getConditionsFilterConfig(),
    getSizeFilterConfig({ sizes: mikimietteSizes }),
  ],
};
