import React from 'react';
import classNames from 'classnames';
import { useIconColor } from '../../../hooks/useIconColor';

interface IconArrowLeftProps {
  rootClassName?: string;
  className?: string;
  color?: string;
}

const IconArrowLeft: React.FC<IconArrowLeftProps> = (props) => {
  const { className, rootClassName, color } = props;

  const { iconColor } = useIconColor();
  const fillColor = color || iconColor;

  const classes = classNames(rootClassName, className);

  return (
    <svg
      className={classes}
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        xmlns="http://www.w3.org/2000/svg"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.63604 7.94975L9.58579 3L11 4.41421L7.46447 7.94975L11 11.4853L9.58579 12.8995L4.63604 7.94975Z"
        fill={fillColor}
      />
    </svg>
  );
};

export default IconArrowLeft;
