import { getDefaultShopConfig } from './default';
import * as custom from '../filters/marketplace-custom-config';
import { getConditionsFilterConfig } from '../filters/condition';
import { getSizeFilterConfig } from '../filters/size';
import { getBrandDirectFilterConfig } from '../filters/brandDirect';

export const theheygangShopConfig = {
  ...getDefaultShopConfig(),
  filters: [
    ...custom.filters,
    getConditionsFilterConfig(),
    getSizeFilterConfig({
      sizes: [
        '6-12 mo',
        '12-18 mo',
        '18-24 mo',
        '2/3',
        '4/5',
        '6/7',
        '8/9',
        '0',
        '1',
        '2',
        '3',
        '4',
      ],
    }),
    getBrandDirectFilterConfig(),
  ],
};
