import classNames from 'classnames';
import React, { FC, useState } from 'react';
import { IconAdmin, IconAdminFilled, NamedLink } from '..';
import { useIconColor } from '../../hooks/useIconColor';
import css from './AdminButton.module.css';

interface AdminButtonProps {
  isSelected: boolean;
  className?: string;
  iconClassName?: string;
  params?: Record<any, any>;
}

const AdminButton: FC<AdminButtonProps> = (props: AdminButtonProps) => {
  const { className, iconClassName, isSelected, params } = props;
  const [isHovered, setIsHovered] = useState(false);

  const { iconHoverColor } = useIconColor();
  const classes = classNames(css.root, className);

  let icon, hoverIcon;
  if (isSelected) {
    icon = <IconAdminFilled className={iconClassName} />;
    hoverIcon = <IconAdminFilled className={iconClassName} color={iconHoverColor} />;
  } else {
    icon = <IconAdmin className={iconClassName} />;
    hoverIcon = <IconAdmin className={iconClassName} color={iconHoverColor} />;
  }

  return (
    <NamedLink
      className={classes}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      role="button"
      tabIndex={0}
      name="AdminPage"
      params={params}
      title="Admin Page"
    >
      {isHovered && hoverIcon ? hoverIcon : icon}
    </NamedLink>
  );
};

export default AdminButton;
