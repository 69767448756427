import React from 'react';
import { useIconColor } from '../../../hooks/useIconColor';
import { CustomIconType, useCustomIcon } from '../../../hooks/useCustomIcon';
import CustomIcon from '../../CustomIcon/CustomIcon';

interface IconFavoriteFilledProps {
  className?: string;
  color?: string;
}

const IconFavoriteFilled: React.FC<IconFavoriteFilledProps> = (props) => {
  const { className, color } = props;

  const customFavoriteFilledIcon = useCustomIcon(CustomIconType.FavoriteFilled);
  const { iconColor } = useIconColor();

  if (customFavoriteFilledIcon) {
    return (
      <CustomIcon
        url={customFavoriteFilledIcon.icon.url}
        altText="favorite-filled"
        className={className}
      />
    );
  }

  const fillColor = color || iconColor;

  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.1127 16.9384C27.6291 14.4361 27.6291 10.379 25.1127 7.87673C22.5963 5.37442 18.5164 5.37442 16 7.87673C13.4836 5.37443 9.4037 5.37443 6.8873 7.87673C4.3709 10.379 4.3709 14.4361 6.8873 16.9384L16 26L25.1127 16.9384Z"
        fill={fillColor}
      />
    </svg>
  );
};

export default IconFavoriteFilled;
