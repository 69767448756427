import React, { FC } from 'react';
import { Box } from '@material-ui/core';
import { useCurrentUserPermissions } from '../../hooks/useUserPermissions';
import { useLogout } from '../../hooks/useLogout';
import { CurrentUser } from '../../types/sharetribe/currentUser';
import { ensureCurrentUser } from '../../util/data';
import TypographyWrapper, { TypographyFormat } from '../TypographyWrapper/TypographyWrapper';
import { InlineTextButton } from '../Button/Button';
import { defaultTreetStyles } from '../../shopConfig/config';

const AdminAccountMismatchBanner: FC<{ currentUser: CurrentUser }> = (props) => {
  const { currentUser } = props;

  const { isBrand: isBrandForCurrentShop, isAnyBrand } = useCurrentUserPermissions();
  const { handleLogout } = useLogout();

  const user = ensureCurrentUser(currentUser) as CurrentUser;
  const { firstName, lastName, protectedData } = user.attributes.profile || {};
  const { accountCreatedFromShopInfo } = protectedData || {};

  const showBanner = isAnyBrand && !isBrandForCurrentShop;

  return showBanner ? (
    <Box bgcolor={defaultTreetStyles.red10}>
      <Box
        style={{
          padding: '10px',
          textAlign: 'center',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
          margin: '0 auto',
        }}
      >
        <TypographyWrapper
          variant="body1"
          typographyOverrides={{ style: { color: defaultTreetStyles.red100 } }}
        >
          You are logged in as {firstName} {lastName}. Any listings created will be authored by the{' '}
          <b>{accountCreatedFromShopInfo?.treetShopName}</b> Brand Direct account.
        </TypographyWrapper>
        <Box m={1}>
          <InlineTextButton onClick={handleLogout}>
            <TypographyWrapper
              variant="body1"
              format={TypographyFormat.Underlined}
              typographyOverrides={{ style: { color: defaultTreetStyles.red100 } }}
            >
              Log out
            </TypographyWrapper>
          </InlineTextButton>
        </Box>
      </Box>
    </Box>
  ) : null;
};

export default AdminAccountMismatchBanner;
