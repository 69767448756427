import classNames from 'classnames';
import React, { FC } from 'react';
import { IconButton, IconShoppingBag, IconWithCount } from '..';
import { useIconColor } from '../../hooks/useIconColor';

const getShoppingBagIcons = (isOpen: boolean) => {
  const { iconHoverColor } = useIconColor();

  let icon, hoverIcon;
  if (isOpen) {
    icon = <IconShoppingBag />;
    hoverIcon = <IconShoppingBag color={iconHoverColor} />;
  } else {
    icon = <IconShoppingBag />;
    hoverIcon = <IconShoppingBag color={iconHoverColor} />;
  }
  return [icon, hoverIcon];
};

interface ShoppingBagButtonProps {
  count: number | undefined;
  isOpen: boolean;
  onClick: () => void;
  className?: string;
  rootClassName?: string;
  isTransparentTopbar?: boolean;
}

const ShoppingBagButton: FC<ShoppingBagButtonProps> = (props: ShoppingBagButtonProps) => {
  const { className, count, isOpen, onClick, rootClassName, isTransparentTopbar } = props;

  let [icon, hoverIcon] = getShoppingBagIcons(isOpen);

  icon = !count ? (
    icon
  ) : (
    <IconWithCount count={count} icon={icon} isTransparentTopbar={isTransparentTopbar} />
  );
  hoverIcon = !count ? (
    hoverIcon
  ) : (
    <IconWithCount count={count} icon={hoverIcon} isTransparentTopbar={isTransparentTopbar} />
  );

  return (
    <IconButton
      ariaLabel="Cart"
      className={classNames(rootClassName, className)}
      icon={icon}
      hoverIcon={hoverIcon}
      onClick={onClick}
    />
  );
};

export default ShoppingBagButton;
