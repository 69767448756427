/* eslint-disable import/prefer-default-export */
import { getConditionsFilterConfig } from '../filters/condition';
import { getBrandDirectFilterConfig } from '../filters/brandDirect';
import { getDefaultShopConfig } from './default';
import { getSizeFilterConfig } from '../filters/size';
import * as custom from '../filters/marketplace-custom-config';

const saguaroshowpadsSizeOptions = [
  'X SMALL',
  'SMALL',
  'MEDIUM',
  'LARGE',
  'X LARGE',
  '1XL',
  '2XL',
  '3XL',
  '34X38',
  '34X40',
  '34X42',
];

export const saguaroshowpadsShopConfig = {
  ...getDefaultShopConfig(),
  filters: [
    ...custom.filters,
    getConditionsFilterConfig(),
    getBrandDirectFilterConfig(),
    getSizeFilterConfig({
      sizes: saguaroshowpadsSizeOptions,
    }),
  ],
};
