import { Listing } from '../../types/sharetribe/listing';
import { Uuid } from '../../types/sharetribe/uuid';
import { fetchSoldListings } from '../api';

// Default number of sold listings to fetch per page.
export const DEFAULT_SOLD_LISTINGS_PAGE_SIZE = 4;
// Base indices used to determine where to display sold listings on the
// page.
const SOLD_LISTINGS_INDICES = [5, 11, 17, 23];

// Returns sold listings for the given page, sorted from most recently sold to least recent.
export const getTopSoldListings = (
  page: number,
  perPage: number,
  origin?: string
): Promise<Listing[]> => fetchSoldListings({ page, perPage, sort: 'pub_soldAt' }, origin);

// Simple algorithm to "randomize" the insertion index for sold listings.
// We start off with base indices and then offset those indices depending on the page number.
// Example when base indices are [5, 11, 17, 23]:
// - Page 1: [5, 11, 17, 23]
// - Page 2: [6, 12, 18, 24]
// - Page 3: [3, 9, 15, 21]
// - Page 4: [6, 14, 18, 26]
export const getIndexToInsertSoldListing = (soldListingIdx: number, page: number): number => {
  const baseIdx = SOLD_LISTINGS_INDICES[soldListingIdx];
  const offset = baseIdx % page;
  return page % 2 === 0 ? baseIdx + offset : baseIdx - offset;
};

// Combines the open listing UUIDs with sold listing UUIDs, the latter of which are
// inserted at "random".
export const getCombinedSoldAndOpenListingUuids = (
  listingIds: Uuid[],
  soldListingIds: Uuid[],
  numSoldListingsToInclude: number,
  page: number,
  totalPages: number
): Uuid[] => {
  const soldListingsIdsToInclude = soldListingIds.slice(0, numSoldListingsToInclude);
  const isSinglePage = totalPages === 1 || totalPages === 0;
  const isLastPage = totalPages === page || isSinglePage;
  const isFirstPage = page === 1;
  const isPageFullWithoutSoldListings = isFirstPage
    ? (listingIds.length + 1) % DEFAULT_SOLD_LISTINGS_PAGE_SIZE === 0
    : listingIds.length % DEFAULT_SOLD_LISTINGS_PAGE_SIZE === 0;
  const shouldShowSoldListings =
    (!isPageFullWithoutSoldListings &&
      soldListingsIdsToInclude.length === numSoldListingsToInclude) ||
    isLastPage;

  // If we have less than the required number of sold listings to fill the page, we don't
  // insert any sold listings. This is to avoid, for example, having a page of 27 listings
  // when the previous pages all had 28.
  // Exceptions:
  //   1) If there's a list an item card present, show 1 less sold listing.
  //   2) If the number of open listings already fills the entire page, don't show the
  //   sold listings. This prevents sold listings from obscuring open listings.
  //   3) If we're on the last page, it's okay if the last page has less total listings.
  if (!shouldShowSoldListings) return listingIds;

  const resultListingIds = [...listingIds];
  soldListingsIdsToInclude.forEach((listingId, idx) => {
    const idxToInsert = getIndexToInsertSoldListing(idx, page);
    // If we're on the last page, we want to avoid having too many sold listings bunched at the end,
    // which could happen if a lot of the sold listing indices fall beyond the length of the open
    // listings. However, if there's only a single page of listings, we'll allow them to overflow.
    const isLastPageOverflowing = isLastPage && listingIds.length < idxToInsert;
    if (isSinglePage || !isLastPageOverflowing) {
      resultListingIds.splice(idxToInsert, 0, listingId);
    }
  });
  return resultListingIds;
};
