import { getDefaultShopConfig } from './default';
import * as custom from '../filters/marketplace-custom-config';
import { getBrandDirectFilterConfig } from '../filters/brandDirect';
import { Conditions, getConditionsFilterConfig } from '../filters/condition';
import { getSizeFilterConfig } from '../filters/size';

const theslowwardrobeSizes = ['00', '0', '1', '2', '3', 'Small', 'Medium', 'Large', 'XLarge'];

export const theslowwardrobeConditionUkOptions = [
  {
    key: Conditions.NewWithTags,
    label: 'New',
    description: 'Your item has never been worn. There are no quirks or issues whatsoever.',
  },
  {
    key: Conditions.Excellent,
    label: 'Excellent',
    description:
      'Your item has only been lightly used and has been extremely well maintained. There may be some small signs of wear, but nothing major.',
  },
  {
    key: Conditions.Good,
    label: 'Good',
    description:
      'You’ve worn this item and it’s well maintained. There may be some quirks like a small stain, a button missing, or a loose thread, but it’s overall in good shape. You will need to take pictures of any quirks the item has.',
  },
];

export const theslowwardrobeUkShopConfig = {
  ...getDefaultShopConfig(),
  filters: [
    ...custom.filters,
    getConditionsFilterConfig({ options: theslowwardrobeConditionUkOptions }),
    getBrandDirectFilterConfig(),
    getSizeFilterConfig({ sizes: theslowwardrobeSizes }),
  ],
};
