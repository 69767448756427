import { Dispatch } from 'redux';
import * as log from '../util/log';
import { Listing, ListingItemType } from '../types/sharetribe/listing';
import { RequestStatus } from '../types/requestStatus';
import { addMarketplaceEntities } from './marketplaceData.duck';
import { denormalisedResponseEntities } from '../util/data';
import { getShopConfig } from '../shopConfig/configHelper';

// ================ Action types ================ //

export const FETCH_RECENT_LISTINGS_REQUEST =
  'app/recentArrivalsCarousel/FETCH_RECENT_LISTINGS_REQUEST';
export const FETCH_RECENT_LISTINGS_SUCCESS =
  'app/recentArrivalsCarousel/FETCH_RECENT_LISTINGS_SUCCESS';
export const FETCH_RECENT_LISTINGS_ERROR = 'app/recentArrivalsCarousel/FETCH_RECENT_LISTINGS_ERROR';

interface FetchRecentListingsRequest {
  type: typeof FETCH_RECENT_LISTINGS_REQUEST;
}

interface FetchRecentListingsSuccess {
  type: typeof FETCH_RECENT_LISTINGS_SUCCESS;
  recentListings: Listing[];
}

interface FetchRecentListingsError {
  type: typeof FETCH_RECENT_LISTINGS_ERROR;
}

type RecentArrivalsCarouselActionType =
  | FetchRecentListingsRequest
  | FetchRecentListingsSuccess
  | FetchRecentListingsError;

// ================ Reducer ================ //

export interface RecentArrivalsCarouselState {
  fetchRecentListingsStatus: RequestStatus;
  recentListings: Listing[];
}

const initialState: RecentArrivalsCarouselState = {
  fetchRecentListingsStatus: RequestStatus.Ready,
  recentListings: [],
};

export default function recentArrivalsCarouselReducer(
  state: RecentArrivalsCarouselState = initialState,
  action: RecentArrivalsCarouselActionType
) {
  switch (action.type) {
    case FETCH_RECENT_LISTINGS_REQUEST: {
      return { ...state, fetchRecentListingsStatus: RequestStatus.Pending };
    }
    case FETCH_RECENT_LISTINGS_SUCCESS: {
      return {
        ...state,
        recentListings: action.recentListings,
        fetchRecentListingsStatus: RequestStatus.Success,
      };
    }
    case FETCH_RECENT_LISTINGS_ERROR: {
      return { ...state, fetchRecentListingsStatus: RequestStatus.Error };
    }
    default: {
      return state;
    }
  }
}

// ================ Action creators ================ //

const fetchRecentListingsRequest = () => ({ type: FETCH_RECENT_LISTINGS_REQUEST });

const fetchRecentListingsSuccess = (recentListings: Listing[]) => ({
  type: FETCH_RECENT_LISTINGS_SUCCESS,
  recentListings,
});

const fetchRecentListingsError = () => ({ type: FETCH_RECENT_LISTINGS_ERROR });

// ================ Thunks ================ //

export const fetchRecentListings =
  () => async (dispatch: Dispatch, getState: () => any, sdk: any) => {
    const { treetId, shopConfig: shopConfigV2 } = getState().initial;
    const { shopName } = getShopConfig(treetId, shopConfigV2);

    dispatch(fetchRecentListingsRequest());

    let recentListings;
    try {
      const recentListingsResponse = await sdk.listings.query({
        page: 1,
        perPage: 4,
        pub_shopName: shopName,
        pub_listingItemType: ListingItemType.Marketplace,
        include: ['author', 'images'],
        'fields.listing': ['title', 'price', 'publicData'],
        'fields.image': [
          'variants.landscape-crop',
          'variants.landscape-crop2x',
          'variants.default',
        ],
        'limit.images': 1,
        sort: 'pub_updatedAt',
      });
      dispatch(addMarketplaceEntities(recentListingsResponse));
      recentListings = denormalisedResponseEntities(recentListingsResponse);
      dispatch(fetchRecentListingsSuccess(recentListings));
    } catch (e) {
      log.error(e, 'fetch-recent-listings-failed', {});
      dispatch(fetchRecentListingsError());
    }
    return recentListings;
  };
