import React from 'react';
import classNames from 'classnames';
import { useIconColor } from '../../../hooks/useIconColor';

interface IconLightningBoltProps {
  rootClassName?: string;
  className?: string;
  color?: string;
  height?: string;
  width?: string;
}

const IconLightningBolt: React.FC<IconLightningBoltProps> = (props) => {
  const { className, rootClassName, color, height = '11', width = '6' } = props;

  const { iconColor } = useIconColor();
  const fillColor = color || iconColor;

  const classes = classNames(rootClassName, className);

  return (
    <svg
      className={classes}
      width={width}
      height={height}
      viewBox="0 0 6 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.66346 0L0 6.22788L2.1991 6.22788V10.6238L5.86256 4.39595H3.66346L3.66346 0Z"
        fill={fillColor}
      />
    </svg>
  );
};

export default IconLightningBolt;
