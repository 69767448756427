import { Box, Typography } from '@material-ui/core';
import React, { FC } from 'react';
import { Button, NamedLink } from '../../components';
import { ButtonVariant } from '../../components/Button/Button';
import IconSparklesRight from '../../components/Icons/IconSparklesRight/IconSparklesRight';
import { defaultTreetStyles } from '../../shopConfig/config';
import css from './ManageClosetPage.module.css';

export const EmptyCloset: FC = () => (
  <Box
    mt={4}
    mb={8}
    display="flex"
    flexDirection="column"
    justifyContent="center"
    alignItems="center"
  >
    <IconSparklesRight className={css.emptyStateIcon} color={defaultTreetStyles.gray40} />
    <Box my={2}>
      <Typography variant="body1">No Active Listings</Typography>
    </Box>
    <NamedLink name="NewListingPage" style={{ textDecoration: 'none' }}>
      <Button variant={ButtonVariant.Primary} style={{ width: '240px' }}>
        Sell Or Trade In
      </Button>
    </NamedLink>
  </Box>
);

export default EmptyCloset;
