import React, { FC } from 'react';
import { useIconColor } from '../../../hooks/useIconColor';

interface IconCameraProps {
  className?: string;
  color?: string;
}

const IconCamera: FC<IconCameraProps> = (props: IconCameraProps) => {
  const { className, color } = props;

  const { iconColor } = useIconColor();
  const fillColor = color || iconColor;

  return (
    <svg
      className={className}
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.4862 5H14.5138C13.1179 5 11.8417 5.7887 11.2174 7.03728C10.616 8.24017 9.38655 9 8.04169 9H7.41641C6.63415 9 6 9.63415 6 10.4164V21C6 22.1046 6.89543 23 8 23H24C25.1046 23 26 22.1046 26 21V10.4164C26 9.63415 25.3659 9 24.5836 9H23.9583C22.6135 9 21.384 8.24017 20.7826 7.03728C20.1583 5.7887 18.8821 5 17.4862 5ZM17.4862 3C19.6397 3 21.6084 4.21671 22.5714 6.14286C22.8341 6.66817 23.371 7 23.9583 7H24.5836C26.4704 7 28 8.52958 28 10.4164V21C28 23.2091 26.2091 25 24 25H8C5.79086 25 4 23.2091 4 21V10.4164C4 8.52958 5.52958 7 7.41641 7H8.04169C8.629 7 9.16591 6.66817 9.42857 6.14286C10.3916 4.21671 12.3603 3 14.5138 3H17.4862ZM16 19C18.2091 19 20 17.2091 20 15C20 12.7909 18.2091 11 16 11C13.7909 11 12 12.7909 12 15C12 17.2091 13.7909 19 16 19ZM16 21C19.3137 21 22 18.3137 22 15C22 11.6863 19.3137 9 16 9C12.6863 9 10 11.6863 10 15C10 18.3137 12.6863 21 16 21ZM24 12C24.5523 12 25 11.5523 25 11C25 10.4477 24.5523 10 24 10C23.4477 10 23 10.4477 23 11C23 11.5523 23.4477 12 24 12Z"
        fill={fillColor}
      />
    </svg>
  );
};

export default IconCamera;
