import { getDefaultShopConfig } from './default';
import * as custom from '../filters/marketplace-custom-config';
import { getBrandDirectFilterConfig } from '../filters/brandDirect';
import { getConditionsFilterConfig } from '../filters/condition';
import { getSizeFilterConfigFromOptions } from '../filters/size';

const kyrgiesSizeOptions = [
  // Womens
  { key: '5', label: 'Womens 5 (35 EU)' },
  { key: '5.5 - 6', label: 'Womens 5.5 - 6 (36 EU)' },
  { key: '6.5 - 7', label: 'Womens 6.5 - 7 (37 EU)' },
  { key: '7.5 - 8', label: 'Womens 7.5 - 8 (38 EU)' },
  { key: '8.5 - 9', label: 'Womens 8.5 - 9 (39 EU)' },
  { key: '9.5 - 10', label: 'Womens 9.5 - 10 (40 EU)' },
  { key: '10.5 - 11', label: 'Womens 10.5 - 11 (41 EU)' },
  { key: '11.5 - 12', label: 'Womens 11.5 - 12 (42 EU)' },
  { key: '12.5 - 13', label: 'Womens 12.5 - 13 (43 EU)' },
  { key: '13.5 - 14', label: 'Womens 13.5 - 14 (44 EU)' },

  // Mens
  { key: '7-7.5 (Euro 40)', label: 'Mens 7-7.5 (40 EU)' },
  { key: '8-8.5 (Euro 41)', label: 'Mens 8-8.5 (41 EU)' },
  { key: '9-9.5 (Euro 42)', label: 'Mens 9-9.5 (42 EU)' },
  { key: '10-10.5 (Euro 43)', label: 'Mens 10-10.5 (43 EU)' },
  { key: '11-11.5 (Euro 44)', label: 'Mens 11-11.5 (44 EU)' },
  { key: '12-12.5 (Euro 45)', label: 'Mens 12-12.5 (45 EU)' },
  { key: '13-13.5 (Euro 46)', label: 'Mens 13-13.5 (46 EU)' },
  { key: '14-14.5 (Euro 47)', label: 'Mens 14-14.5 (47 EU)' },
  { key: '15-15.5 (Euro 48)', label: 'Mens 15-15.5 (48 EU)' },

  // Kids
  { key: 'Toddler 12.5 (29 EU)', label: 'Kids (29 EU)' },
  { key: 'Toddler 13 (30 EU)', label: 'Kids (30 EU)' },
  { key: "Kid's 1 (31 EU)", label: 'Kids (31 EU)' },
  { key: "Kid's 1.5 (32 EU)", label: 'Kids (32 EU)' },
  { key: "Kid's 2 (33 EU)", label: 'Kids (33 EU)' },
  { key: "Kid's 2.5 (34 EU)", label: 'Kids (34 EU)' },
];

export const kyrgiesShopConfig = {
  ...getDefaultShopConfig(),
  filters: [
    ...custom.filters,
    getBrandDirectFilterConfig(),
    getSizeFilterConfigFromOptions({ options: kyrgiesSizeOptions }),
    getConditionsFilterConfig(),
  ],
};
