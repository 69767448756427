import { Dispatch } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { CheckoutLineItem } from '../../types/models/lineItem';
import { fetchCurrentUser } from '../../ducks/user.duck';
import { StorableError } from '../../types/error';
import { AppThunk } from '../../types/redux/AppThunk';
import { RequestStatus } from '../../types/requestStatus';
import { ListingWithAuthorAndImages } from '../../types/sharetribe/listing';
import {
  finishCheckout as finishCheckoutApiRequest,
  initiateCheckout as initiateCheckoutApiRequest,
  orderLineItems,
  submitCheckout as submitCheckoutApiRequest,
  updateListingsAvailability as updateListingsAvailabilityApiRequest,
} from '../../util/api';
import { ITEM_AVAILABILITY_AVAILABLE } from '../../util/constants';
import { storableError } from '../../util/errors';
import * as log from '../../util/log';
import { SharetribeAddress } from '../../types/sharetribe/address';
import { ShippoAddress } from '../../types/shippo/address';
import { FulfillmentMethod } from '../../types/shopConfig/shopConfigV2';
import {
  SeelCreateQuoteParams,
  SeelCreateQuoteResponse,
  SeelCreateQuoteResponseStatus,
} from '../../types/seel/seel';
import { LineItem, LineItemCode } from '../../types/apollo/generated/types.generated';
import { IBrandPromos } from '../../types/contentful/types.generated';

type ValidatedVoucher = {
  code: string;
  discount?: {
    type: 'AMOUNT' | 'PERCENT';
    // eslint-disable-next-line camelcase
    amount_off?: number;
    // eslint-disable-next-line camelcase
    percent_off?: number;
  };
  metadata: {
    isBrandCovered?: boolean;
  };
};

// ================ Action types ================ //

const SET_INITIAL_VALUES = 'app/CheckoutPage/SET_INITIAL_VALUES';
const SET_SHIP_TO_COUNTRY = 'app/CheckoutPage/SET_SHIP_TO_COUNTRY';
const SET_VOUCHER = 'app/CheckoutPage/SET_VOUCHER';
const SET_ACTIVE_PROMOS = 'app/CheckoutPage/SET_ACTIVE_PROMOS';

const SET_SHOW_RETURN_INSURANCE_LINE_ITEM = 'app/CheckoutPage/SET_SHOW_RETURN_INSURANCE_LINE_ITEM';
const SET_RETURN_INSURANCE_QUOTE = 'app/CheckoutPage/SET_RETURN_INSURANCE_QUOTE';

const FETCH_LINE_ITEMS_REQUEST = 'app/CheckoutPage/FETCH_LINE_ITEMS_REQUEST';
const FETCH_LINE_ITEMS_SUCCESS = 'app/CheckoutPage/FETCH_LINE_ITEMS_SUCCESS';
const FETCH_LINE_ITEMS_ERROR = 'app/CheckoutPage/FETCH_LINE_ITEMS_ERROR';

const FETCH_STRIPE_CUSTOMER_REQUEST = 'app/CheckoutPage/FETCH_STRIPE_CUSTOMER_REQUEST';
const FETCH_STRIPE_CUSTOMER_SUCCESS = 'app/CheckoutPage/FETCH_STRIPE_CUSTOMER_SUCCESS';
const FETCH_STRIPE_CUSTOMER_ERROR = 'app/CheckoutPage/FETCH_STRIPE_CUSTOMER_ERROR';

const INITIALIZE_STRIPE_EXPRESS_ELEMENTS_REQUEST =
  'app/CheckoutPage/INITIALIZE_STRIPE_EXPRESS_ELEMENTS_REQUEST';
const INITIALIZE_STRIPE_EXPRESS_ELEMENTS_SUCCESS =
  'app/CheckoutPage/INITIALIZE_STRIPE_EXPRESS_ELEMENTS_SUCCESS';
const INITIALIZE_STRIPE_EXPRESS_ELEMENTS_ERROR =
  'app/CheckoutPage/INITIALIZE_STRIPE_EXPRESS_ELEMENTS_ERROR';

const INITIATE_CHECKOUT_REQUEST = 'app/CheckoutPage/INITIATE_CHECKOUT_REQUEST';
const INITIATE_CHECKOUT_SUCCESS = 'app/CheckoutPage/INITIATE_CHECKOUT_SUCCESS';
const INITIATE_CHECKOUT_ERROR = 'app/CheckoutPage/INITIATE_CHECKOUT_ERROR';

const SUBMIT_CHECKOUT_REQUEST = 'app/CheckoutPage/SUBMIT_CHECKOUT_REQUEST';
const SUBMIT_CHECKOUT_SUCCESS = 'app/CheckoutPage/SUBMIT_CHECKOUT_SUCCESS';
const SUBMIT_CHECKOUT_ERROR = 'app/CheckoutPage/SUBMIT_CHECKOUT_ERROR';

const FINISH_CHECKOUT_REQUEST = 'app/CheckoutPage/FINISH_CHECKOUT_REQUEST';
const FINISH_CHECKOUT_SUCCESS = 'app/CheckoutPage/FINISH_CHECKOUT_SUCCESS';
const FINISH_CHECKOUT_ERROR = 'app/CheckoutPage/FINISH_CHECKOUT_ERROR';

const SET_LISTINGS_AVAILABLE_REQUEST = 'app/CheckoutPage/SET_LISTINGS_AVAILABLE_REQUEST';
const SET_LISTINGS_AVAILABLE_SUCCESS = 'app/CheckoutPage/SET_LISTINGS_AVAILABLE_SUCCESS';
const SET_LISTINGS_AVAILABLE_ERROR = 'app/CheckoutPage/SET_LISTINGS_AVAILABLE_ERROR';

const GET_RETURN_INSURANCE_QUOTE_REQUEST = 'app/CheckoutPage/GET_RETURN_INSURANCE_QUOTE_REQUEST';
const GET_RETURN_INSURANCE_QUOTE_SUCCESS = 'app/CheckoutPage/GET_RETURN_INSURANCE_QUOTE_SUCCESS';
const GET_RETURN_INSURANCE_QUOTE_ERROR = 'app/CheckoutPage/GET_RETURN_INSURANCE_QUOTE_ERROR';

const SET_IS_EXPRESS_CHECKOUT_ERROR = 'app/CheckoutPage/SET_IS_EXPRESS_CHECKOUT_ERROR';

interface SetInitialValues {
  type: typeof SET_INITIAL_VALUES;
  listings: ListingWithAuthorAndImages[];
}

interface SetShipToCountry {
  type: typeof SET_SHIP_TO_COUNTRY;
  country: string;
}

interface SetVoucher {
  type: typeof SET_VOUCHER;
  voucher: ValidatedVoucher;
}

interface SetActivePromos {
  type: typeof SET_ACTIVE_PROMOS;
  activePromos: IBrandPromos[];
}

interface SetShowReturnInsuranceLineItem {
  type: typeof SET_SHOW_RETURN_INSURANCE_LINE_ITEM;
  isReturnInsuranceLineItemEnabled: boolean;
}

interface SetReturnInsuranceQuote {
  type: typeof SET_RETURN_INSURANCE_QUOTE;
  returnInsuranceQuote: SeelCreateQuoteResponse;
  isReturnInsuranceLineItemEnabled: boolean;
}

interface FetchLineItemsRequest {
  type: typeof FETCH_LINE_ITEMS_REQUEST;
}

interface FetchLineItemsSuccess {
  type: typeof FETCH_LINE_ITEMS_SUCCESS;
  lineItems: CheckoutLineItem[];
  authorIdToLineItems: { [author: string]: CheckoutLineItem[] };
}

interface FetchLineItemsError {
  type: typeof FETCH_LINE_ITEMS_ERROR;
  error: StorableError;
}

interface FetchStripeCustomerRequest {
  type: typeof FETCH_STRIPE_CUSTOMER_REQUEST;
}

interface FetchStripeCustomerSuccess {
  type: typeof FETCH_STRIPE_CUSTOMER_SUCCESS;
}

interface FetchStripeCustomerError {
  type: typeof FETCH_STRIPE_CUSTOMER_ERROR;
  error: StorableError;
}

interface InitializeStripeExpressElementsRequest {
  type: typeof INITIALIZE_STRIPE_EXPRESS_ELEMENTS_REQUEST;
}

interface InitializeStripeExpressElementsSuccess {
  type: typeof INITIALIZE_STRIPE_EXPRESS_ELEMENTS_SUCCESS;
}

interface InitializeStripeExpressElementsError {
  type: typeof INITIALIZE_STRIPE_EXPRESS_ELEMENTS_ERROR;
}

interface InitiateCheckoutRequest {
  type: typeof INITIATE_CHECKOUT_REQUEST;
}

interface InitiateCheckoutSuccess {
  type: typeof INITIATE_CHECKOUT_SUCCESS;
}

interface InitiateCheckoutError {
  type: typeof INITIATE_CHECKOUT_ERROR;
  error: any;
}

interface SubmitCheckoutRequest {
  type: typeof SUBMIT_CHECKOUT_REQUEST;
}

interface SubmitCheckoutSuccess {
  type: typeof SUBMIT_CHECKOUT_SUCCESS;
}

interface SubmitCheckoutError {
  type: typeof SUBMIT_CHECKOUT_ERROR;
  error: any;
}

interface FinishCheckoutRequest {
  type: typeof FINISH_CHECKOUT_REQUEST;
}

interface FinishCheckoutSuccess {
  type: typeof FINISH_CHECKOUT_SUCCESS;
}

interface FinishCheckoutError {
  type: typeof FINISH_CHECKOUT_ERROR;
  error: any;
}

interface SetListingsAvailableRequest {
  type: typeof SET_LISTINGS_AVAILABLE_REQUEST;
}

interface SetListingsAvailableSuccess {
  type: typeof SET_LISTINGS_AVAILABLE_SUCCESS;
}

interface SetListingsAvailableError {
  type: typeof SET_LISTINGS_AVAILABLE_ERROR;
  error: any;
}

interface GetReturnInsuranceQuoteRequest {
  type: typeof GET_RETURN_INSURANCE_QUOTE_REQUEST;
}

interface GetReturnInsuranceQuoteSuccess {
  type: typeof GET_RETURN_INSURANCE_QUOTE_SUCCESS;
  quote: SeelCreateQuoteResponse | null;
}

interface GetReturnInsuranceQuoteError {
  type: typeof GET_RETURN_INSURANCE_QUOTE_ERROR;
  error: any;
}

interface SetIsExpressCheckoutError {
  type: typeof SET_IS_EXPRESS_CHECKOUT_ERROR;
  isExpressCheckoutError: boolean;
}

type CheckoutPageActionType =
  | SetInitialValues
  | SetShipToCountry
  | SetVoucher
  | SetActivePromos
  | SetShowReturnInsuranceLineItem
  | SetReturnInsuranceQuote
  | FetchLineItemsRequest
  | FetchLineItemsSuccess
  | FetchLineItemsError
  | FetchStripeCustomerRequest
  | FetchStripeCustomerSuccess
  | FetchStripeCustomerError
  | InitializeStripeExpressElementsRequest
  | InitializeStripeExpressElementsSuccess
  | InitializeStripeExpressElementsError
  | InitiateCheckoutRequest
  | InitiateCheckoutSuccess
  | InitiateCheckoutError
  | SubmitCheckoutRequest
  | SubmitCheckoutSuccess
  | SubmitCheckoutError
  | FinishCheckoutRequest
  | FinishCheckoutSuccess
  | FinishCheckoutError
  | SetListingsAvailableRequest
  | SetListingsAvailableSuccess
  | SetListingsAvailableError
  | GetReturnInsuranceQuoteRequest
  | GetReturnInsuranceQuoteSuccess
  | GetReturnInsuranceQuoteError
  | SetIsExpressCheckoutError;

// ================ Reducer ================ //

export interface CheckoutPageState {
  shipToCountry: string | null;
  voucher: ValidatedVoucher | null;
  isReturnInsuranceLineItemEnabled: boolean;
  returnInsuranceQuote: SeelCreateQuoteResponse | null;
  fetchLineItemsError: StorableError | null;
  fetchLineItemsStatus: RequestStatus;
  fetchStripeCustomerError: StorableError | null;
  fetchStripeCustomerStatus: RequestStatus;
  initializeStripeExpressElementsStatus: RequestStatus;
  initiateCheckoutError: any | null;
  initiateCheckoutStatus: RequestStatus;
  submitCheckoutError: any | null;
  submitCheckoutStatus: RequestStatus;
  finishCheckoutError: any | null;
  finishCheckoutStatus: RequestStatus;
  setListingsAvailableError: any | null;
  setListingsAvailableStatus: RequestStatus;
  getReturnInsuranceQuoteStatus: RequestStatus;
  getReturnInsuranceQuoteError: any | null;
  lineItems: CheckoutLineItem[];
  authorIdToLineItems: { [author: string]: CheckoutLineItem[] };
  listings: ListingWithAuthorAndImages[];
  activePromos: IBrandPromos[];
  isExpressCheckoutError: boolean;
}

const initialState: CheckoutPageState = {
  shipToCountry: null,
  voucher: null,
  isReturnInsuranceLineItemEnabled: false,
  returnInsuranceQuote: null,
  fetchLineItemsError: null,
  fetchLineItemsStatus: RequestStatus.Ready,
  fetchStripeCustomerError: null,
  fetchStripeCustomerStatus: RequestStatus.Ready,
  initializeStripeExpressElementsStatus: RequestStatus.Ready,
  initiateCheckoutError: null,
  initiateCheckoutStatus: RequestStatus.Ready,
  submitCheckoutError: null,
  submitCheckoutStatus: RequestStatus.Ready,
  finishCheckoutError: null,
  finishCheckoutStatus: RequestStatus.Ready,
  setListingsAvailableError: null,
  setListingsAvailableStatus: RequestStatus.Ready,
  getReturnInsuranceQuoteStatus: RequestStatus.Ready,
  getReturnInsuranceQuoteError: null,
  lineItems: [],
  authorIdToLineItems: {},
  listings: [],
  activePromos: [],
  isExpressCheckoutError: false,
};

export default function checkoutPageReducer(
  state: CheckoutPageState = initialState,
  action: CheckoutPageActionType
): CheckoutPageState {
  switch (action.type) {
    case SET_INITIAL_VALUES: {
      return {
        ...initialState,
        // Don't reset the fetched stripe customer error/status since they shouldn't be affected
        // by new initial values (e.g. listings)
        fetchStripeCustomerError: state.fetchStripeCustomerError,
        fetchStripeCustomerStatus: state.fetchStripeCustomerStatus,
        shipToCountry: state.shipToCountry,
        listings: action.listings,
      };
    }
    case SET_SHIP_TO_COUNTRY: {
      return { ...state, shipToCountry: action.country };
    }
    case SET_VOUCHER: {
      return { ...state, voucher: action.voucher };
    }
    case SET_ACTIVE_PROMOS: {
      return { ...state, activePromos: action?.activePromos };
    }
    case SET_SHOW_RETURN_INSURANCE_LINE_ITEM: {
      return {
        ...state,
        isReturnInsuranceLineItemEnabled: action.isReturnInsuranceLineItemEnabled,
      };
    }
    case SET_RETURN_INSURANCE_QUOTE: {
      return {
        ...state,
        returnInsuranceQuote: action.returnInsuranceQuote,
        isReturnInsuranceLineItemEnabled: action.isReturnInsuranceLineItemEnabled,
      };
    }
    case FETCH_LINE_ITEMS_REQUEST: {
      return {
        ...state,
        fetchLineItemsStatus: RequestStatus.Pending,
        fetchLineItemsError: null,
      };
    }
    case FETCH_LINE_ITEMS_SUCCESS: {
      return {
        ...state,
        fetchLineItemsStatus: RequestStatus.Success,
        lineItems: action.lineItems,
        authorIdToLineItems: action.authorIdToLineItems,
      };
    }
    case FETCH_LINE_ITEMS_ERROR: {
      return {
        ...state,
        fetchLineItemsStatus: RequestStatus.Error,
        fetchLineItemsError: action.error,
      };
    }
    case FETCH_STRIPE_CUSTOMER_REQUEST: {
      return {
        ...state,
        fetchStripeCustomerStatus: RequestStatus.Pending,
        fetchStripeCustomerError: null,
      };
    }
    case FETCH_STRIPE_CUSTOMER_SUCCESS: {
      return {
        ...state,
        fetchStripeCustomerStatus: RequestStatus.Success,
      };
    }
    case FETCH_STRIPE_CUSTOMER_ERROR: {
      return {
        ...state,
        fetchStripeCustomerStatus: RequestStatus.Error,
        fetchStripeCustomerError: action.error,
      };
    }
    case INITIALIZE_STRIPE_EXPRESS_ELEMENTS_REQUEST: {
      return {
        ...state,
        initializeStripeExpressElementsStatus: RequestStatus.Pending,
      };
    }
    case INITIALIZE_STRIPE_EXPRESS_ELEMENTS_SUCCESS: {
      return {
        ...state,
        initializeStripeExpressElementsStatus: RequestStatus.Success,
      };
    }
    case INITIALIZE_STRIPE_EXPRESS_ELEMENTS_ERROR: {
      return {
        ...state,
        initializeStripeExpressElementsStatus: RequestStatus.Error,
      };
    }
    case INITIATE_CHECKOUT_REQUEST: {
      return {
        ...state,
        initiateCheckoutStatus: RequestStatus.Pending,
        initiateCheckoutError: null,
      };
    }
    case INITIATE_CHECKOUT_SUCCESS: {
      return {
        ...state,
        initiateCheckoutStatus: RequestStatus.Success,
      };
    }
    case INITIATE_CHECKOUT_ERROR: {
      return {
        ...state,
        initiateCheckoutStatus: RequestStatus.Error,
        initiateCheckoutError: action.error,
      };
    }
    case SUBMIT_CHECKOUT_REQUEST: {
      return {
        ...state,
        submitCheckoutStatus: RequestStatus.Pending,
        submitCheckoutError: null,
      };
    }
    case SUBMIT_CHECKOUT_SUCCESS: {
      return {
        ...state,
        submitCheckoutStatus: RequestStatus.Success,
      };
    }
    case SUBMIT_CHECKOUT_ERROR: {
      return {
        ...state,
        submitCheckoutStatus: RequestStatus.Error,
        submitCheckoutError: action.error,
      };
    }
    case FINISH_CHECKOUT_REQUEST: {
      return {
        ...state,
        finishCheckoutStatus: RequestStatus.Pending,
        finishCheckoutError: null,
      };
    }
    case FINISH_CHECKOUT_SUCCESS: {
      return {
        ...state,
        finishCheckoutStatus: RequestStatus.Success,
      };
    }
    case FINISH_CHECKOUT_ERROR: {
      return {
        ...state,
        finishCheckoutStatus: RequestStatus.Error,
        finishCheckoutError: action.error,
      };
    }
    case SET_LISTINGS_AVAILABLE_REQUEST: {
      return {
        ...state,
        setListingsAvailableStatus: RequestStatus.Pending,
        setListingsAvailableError: null,
      };
    }
    case SET_LISTINGS_AVAILABLE_SUCCESS: {
      return {
        ...state,
        setListingsAvailableStatus: RequestStatus.Success,
      };
    }
    case SET_LISTINGS_AVAILABLE_ERROR: {
      return {
        ...state,
        setListingsAvailableStatus: RequestStatus.Error,
        setListingsAvailableError: action.error,
      };
    }
    case GET_RETURN_INSURANCE_QUOTE_REQUEST: {
      return {
        ...state,
        getReturnInsuranceQuoteStatus: RequestStatus.Pending,
        getReturnInsuranceQuoteError: null,
      };
    }
    case GET_RETURN_INSURANCE_QUOTE_SUCCESS: {
      return {
        ...state,
        getReturnInsuranceQuoteStatus: RequestStatus.Success,
        returnInsuranceQuote: action.quote,
      };
    }
    case GET_RETURN_INSURANCE_QUOTE_ERROR: {
      return {
        ...state,
        getReturnInsuranceQuoteStatus: RequestStatus.Error,
        getReturnInsuranceQuoteError: action.error,
        isReturnInsuranceLineItemEnabled: false,
        lineItems: state.lineItems.filter((li) => li.code !== LineItemCode.ReturnInsurance),
      };
    }
    case SET_IS_EXPRESS_CHECKOUT_ERROR: {
      return {
        ...state,
        isExpressCheckoutError: action.isExpressCheckoutError,
      };
    }
    default:
      return state;
  }
}

// ================ Action creators ================ //

export const setInitialValues = (listings: ListingWithAuthorAndImages[]): SetInitialValues => ({
  type: SET_INITIAL_VALUES,
  listings,
});

export const setShipToCountry = (country: string): SetShipToCountry => ({
  type: SET_SHIP_TO_COUNTRY,
  country,
});

export const setVoucher = (voucher: ValidatedVoucher): SetVoucher => ({
  type: SET_VOUCHER,
  voucher,
});

export const setActivePromos = (activePromos: IBrandPromos[]): SetActivePromos => ({
  type: SET_ACTIVE_PROMOS,
  activePromos,
});

export const setShowReturnInsuranceLineItem = (
  isReturnInsuranceLineItemEnabled: boolean
): SetShowReturnInsuranceLineItem => ({
  type: SET_SHOW_RETURN_INSURANCE_LINE_ITEM,
  isReturnInsuranceLineItemEnabled,
});

export const setReturnInsuranceQuote = (
  returnInsuranceQuote: SeelCreateQuoteResponse,
  isReturnInsuranceLineItemEnabled: boolean
): SetReturnInsuranceQuote => ({
  type: SET_RETURN_INSURANCE_QUOTE,
  returnInsuranceQuote,
  isReturnInsuranceLineItemEnabled,
});

export const setIsExpressCheckoutError = (isExpressCheckoutError: boolean) => ({
  type: SET_IS_EXPRESS_CHECKOUT_ERROR,
  isExpressCheckoutError,
});

const fetchLineItemsRequest = () => ({ type: FETCH_LINE_ITEMS_REQUEST });

const fetchLineItemsSuccess = (
  lineItems: CheckoutLineItem[],
  authorIdToLineItems: { [author: string]: CheckoutLineItem[] }
) => ({
  type: FETCH_LINE_ITEMS_SUCCESS,
  lineItems,
  authorIdToLineItems,
});

const fetchLineItemsError = (error: StorableError) => ({ type: FETCH_LINE_ITEMS_ERROR, error });

const fetchStripeCustomerRequest = () => ({ type: FETCH_STRIPE_CUSTOMER_REQUEST });

const fetchStripeCustomerSuccess = () => ({ type: FETCH_STRIPE_CUSTOMER_SUCCESS });

const fetchStripeCustomerError = (error: StorableError) => ({
  type: FETCH_STRIPE_CUSTOMER_ERROR,
  error,
});

export const initializeStripeExpressElementsRequest = () => ({
  type: INITIALIZE_STRIPE_EXPRESS_ELEMENTS_REQUEST,
});

export const initializeStripeExpressElementsSuccess = () => ({
  type: INITIALIZE_STRIPE_EXPRESS_ELEMENTS_SUCCESS,
});

export const initializeStripeExpressElementsError = () => ({
  type: INITIALIZE_STRIPE_EXPRESS_ELEMENTS_ERROR,
});

const initiateCheckoutRequest = () => ({ type: INITIATE_CHECKOUT_REQUEST });

const initiateCheckoutSuccess = () => ({ type: INITIATE_CHECKOUT_SUCCESS });

const initiateCheckoutError = (error: any) => ({ type: INITIATE_CHECKOUT_ERROR, error });

const submitCheckoutRequest = () => ({ type: SUBMIT_CHECKOUT_REQUEST });

const submitCheckoutSuccess = () => ({ type: SUBMIT_CHECKOUT_SUCCESS });

const submitCheckoutError = (error: any) => ({ type: SUBMIT_CHECKOUT_ERROR, error });

const finishCheckoutRequest = () => ({ type: FINISH_CHECKOUT_REQUEST });

const finishCheckoutSuccess = () => ({ type: FINISH_CHECKOUT_SUCCESS });

const finishCheckoutError = (error: any) => ({ type: FINISH_CHECKOUT_ERROR, error });

const setListingsAvailableRequest = () => ({ type: SET_LISTINGS_AVAILABLE_REQUEST });

const setListingsAvailableSuccess = () => ({ type: SET_LISTINGS_AVAILABLE_SUCCESS });

const setListingsAvailableError = (error: any) => ({
  type: SET_LISTINGS_AVAILABLE_ERROR,
  error,
});

const getReturnInsuranceQuoteRequest = () => ({ type: GET_RETURN_INSURANCE_QUOTE_REQUEST });

const getReturnInsuranceQuoteSuccess = (quote: SeelCreateQuoteResponse) => ({
  type: GET_RETURN_INSURANCE_QUOTE_SUCCESS,
  quote,
});

const getReturnInsuranceQuoteError = (error: any) => ({
  type: GET_RETURN_INSURANCE_QUOTE_ERROR,
  error,
});

// ================ Thunks ================ //

const getOrderData = (checkoutPageState: CheckoutPageState) => {
  const {
    shipToCountry,
    voucher,
    returnInsuranceQuote,
    isReturnInsuranceLineItemEnabled,
    activePromos,
  } = checkoutPageState;

  return {
    shipToAddress: { country: shipToCountry },
    voucher,
    enabledReturnInsurancePrice:
      returnInsuranceQuote && isReturnInsuranceLineItemEnabled
        ? Math.round(returnInsuranceQuote.price * 100)
        : undefined,
    returnInsuranceQuoteId: returnInsuranceQuote?.quoteId,
    activePromos,
  };
};

export const fetchOrderLineItems =
  (passedListingIds?: string[]) => async (dispatch: Dispatch, getState: () => any) => {
    dispatch(fetchLineItemsRequest());

    const checkoutPageState = getState().CheckoutPage as CheckoutPageState;
    const { listings } = checkoutPageState;
    const listingIds = passedListingIds || listings.map((l) => l.id.uuid);
    const orderData = getOrderData(checkoutPageState);

    try {
      const response = await orderLineItems({ listingIds, orderData });
      const { all: allLineItems, ...authorIdToLineItems } = response.data;
      dispatch(fetchLineItemsSuccess(allLineItems, authorIdToLineItems));
      return response;
    } catch (e) {
      dispatch(fetchLineItemsError(storableError(e)));
      log.error(e, 'fetching-line-items-failed', { listingIds, orderData });
      return null;
    }
  };

export const fetchStripeCustomer = () => async (dispatch: ThunkDispatch<any, any, any>) => {
  dispatch(fetchStripeCustomerRequest());

  try {
    await dispatch(fetchCurrentUser({ include: ['stripeCustomer.defaultPaymentMethod'] }));
    dispatch(fetchStripeCustomerSuccess());
  } catch (e) {
    dispatch(fetchStripeCustomerError(storableError(e)));
    log.error(e, 'fetching-stripe-customer-failed', {});
  }
};

interface InitiateCheckoutResponse {
  validatedAddress: ShippoAddress;
}

export const initiateCheckout =
  (
    shippingAddress: SharetribeAddress,
    blockedLocations?: { [x: string]: { states: string[] } }
  ): AppThunk<Promise<InitiateCheckoutResponse | undefined>> =>
  async (dispatch: Dispatch, getState: () => any) => {
    dispatch(initiateCheckoutRequest());

    const checkoutPageState = getState().CheckoutPage as CheckoutPageState;
    const { listings } = checkoutPageState;
    const listingIds = listings.map((l) => l.id.uuid);
    const orderData = getOrderData(checkoutPageState);
    const initiateParams = { shippingAddress, listingIds, orderData };

    const blockedListingBasedOnListingTags =
      blockedLocations &&
      listings.find((listing) => {
        const { tags = [] } = listing.attributes.publicData;
        const blockedTags = tags.filter((tag: string) =>
          Object.keys(blockedLocations).find(
            (blockedLocation) => blockedLocation.toLowerCase().trim() === tag.toLowerCase().trim()
          )
        );
        return blockedTags.find((blockedTag: string) => {
          const blockedStates = blockedLocations[blockedTag]?.states || [];
          return blockedStates.includes(shippingAddress.state);
        });
      });

    if (blockedListingBasedOnListingTags) {
      const e = `${blockedListingBasedOnListingTags.attributes.title} cannot be shipped to ${shippingAddress.state}`;
      dispatch(
        initiateCheckoutError({
          apiErrors: [{ code: 'invalid-address-for-item', message: e }],
        })
      );
      throw e;
    }

    try {
      const initiateCheckoutResponse = await initiateCheckoutApiRequest(initiateParams);
      dispatch(initiateCheckoutSuccess());
      return initiateCheckoutResponse;
    } catch (e) {
      const error = e.error || storableError(e);
      dispatch(initiateCheckoutError(error));
      throw e;
    }
  };

interface SubmitCheckoutParams {
  authorIdToListingIds: { [authorId: string]: string[] };
  buyerId: string;
  stripeCustomerId: string;
  shippingAddress: ShippoAddress;
  paymentMethod?: any;
  fullServiceStripeAccountId?: string;
  shouldExcludeShippingAddressOnStripePaymentIntent?: boolean;
}

export interface SubmitCheckoutResponse {
  paymentIntent?: any;
  bundleIds: string[];
  orderId: string;
  bundleLineItems: LineItem[];
}

export const submitCheckout =
  (params: SubmitCheckoutParams): AppThunk<Promise<SubmitCheckoutResponse | undefined>> =>
  async (dispatch: Dispatch, getState: () => any) => {
    dispatch(submitCheckoutRequest());

    const checkoutPageState = getState().CheckoutPage as CheckoutPageState;
    const orderData = getOrderData(checkoutPageState);
    const submitParams = { ...params, orderData };

    try {
      const response = (await submitCheckoutApiRequest(submitParams)) as SubmitCheckoutResponse;
      dispatch(submitCheckoutSuccess());
      return response;
    } catch (e) {
      const error = e.error || storableError(e);
      dispatch(submitCheckoutError(error));
      throw e;
    }
  };

interface FinishCheckoutParams {
  shippingAddress: ShippoAddress;
  stripePaymentIntentId: string;
  bundleIds: string[];
  authorIdToFulfillmentMethod: { [authorId: string]: FulfillmentMethod };
  authorIdToListingIds: { [authorId: string]: string[] };
}

export const finishCheckout =
  (params: FinishCheckoutParams) => async (dispatch: Dispatch, getState: () => any) => {
    dispatch(finishCheckoutRequest());

    const checkoutPageState = getState().CheckoutPage as CheckoutPageState;
    const orderData = getOrderData(checkoutPageState);
    const finishParams = { ...params, orderData };

    try {
      await finishCheckoutApiRequest(finishParams);
      dispatch(finishCheckoutSuccess());
    } catch (e) {
      const error = e.error || storableError(e);
      dispatch(finishCheckoutError(error));
      throw e;
    }
  };

export const setListingsAvailable = () => async (dispatch: Dispatch, getState: () => any) => {
  const { listings } = getState().CheckoutPage as CheckoutPageState;
  const listingIds = listings.map((l) => l.id.uuid);
  dispatch(setListingsAvailableRequest());

  try {
    await updateListingsAvailabilityApiRequest({
      listingIds,
      availability: ITEM_AVAILABILITY_AVAILABLE,
    });
    dispatch(setListingsAvailableSuccess());
  } catch (e) {
    const error = e.error || storableError(e);
    dispatch(setListingsAvailableError(error));
    // Swallow error since we don't handle on the frontend
  }
};

export const getReturnInsuranceQuote =
  (params?: SeelCreateQuoteParams) => async (dispatch: Dispatch) => {
    dispatch(getReturnInsuranceQuoteRequest());

    try {
      if (typeof window !== 'undefined' && (window as any).SeelSDK) {
        const quote = await (window as any).SeelSDK.seelSDK.createQuote(params);

        if (!quote) throw new Error('Could not retrieve a return insurance quote from Seel.');
        if (quote.status === SeelCreateQuoteResponseStatus.Rejected) {
          throw new Error('Return insurance quote was rejected by Seel.');
        }

        dispatch(getReturnInsuranceQuoteSuccess(quote));
      }
    } catch (e) {
      const error = e.error || storableError(e);
      dispatch(getReturnInsuranceQuoteError(error));
      // Swallow error since we don't want to prevent checkout if we can't get a return insurance quote
    }
  };
