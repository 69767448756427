import { getColorFilterConfig } from '../filters/color';
import { defaultClothesConditionsOptions, getConditionsFilterConfig } from '../filters/condition';
import { getGenderFilterConfig } from '../filters/gender';
import * as custom from '../filters/marketplace-custom-config';
import { getSizeFilterConfigFromOptions } from '../filters/size';
import { getDefaultShopConfig } from './default';

const doppleSizeOptions = [
  { key: 'One Size', label: 'One Size' },
  { key: '0-3M', label: 'Clothing: 0-3M' },
  { key: '3-6M', label: 'Clothing: 3-6M' },
  { key: '6-9M', label: 'Clothing: 6-9M' },
  { key: '9-12M', label: 'Clothing: 9-12M' },
  { key: '12-18M', label: 'Clothing: 12-18M' },
  { key: '18-24M', label: 'Clothing: 18-24M' },
  { key: '2T', label: 'Clothing: 2T' },
  { key: '3T', label: 'Clothing: 3T' },
  { key: '4T', label: 'Clothing: 4T' },
  { key: '5T', label: 'Clothing: 5T' },
  { key: '6Y', label: 'Clothing: 6Y' },
  { key: '7Y', label: 'Clothing: 7Y' },
  { key: '8Y', label: 'Clothing: 8Y' },
  { key: '9Y', label: 'Clothing: 9Y' },
  { key: '10Y', label: 'Clothing: 10Y' },
  { key: '11Y', label: 'Clothing: 11Y' },
  { key: '12Y', label: 'Clothing: 12Y' },
  { key: '13Y', label: 'Clothing: 13Y' },
  { key: '14Y', label: 'Clothing: 14Y' },
  { key: '1', label: 'Shoes: 1' },
  { key: '1.5', label: 'Shoes: 1.5' },
  { key: '2', label: 'Shoes: 2' },
  { key: '2.5', label: 'Shoes: 2.5' },
  { key: '3', label: 'Shoes: 3' },
  { key: '3.5', label: 'Shoes: 3.5' },
  { key: '4', label: 'Shoes: 4' },
  { key: '4.5', label: 'Shoes: 4.5' },
  { key: '5', label: 'Shoes: 5' },
  { key: '5.5', label: 'Shoes: 5.5' },
  { key: '6', label: 'Shoes: 6' },
  { key: '6.5', label: 'Shoes: 6.5' },
  { key: '7', label: 'Shoes: 7' },
  { key: '7.5', label: 'Shoes: 7.5' },
  { key: '8', label: 'Shoes: 8' },
  { key: '8.5', label: 'Shoes: 8.5' },
  { key: '9', label: 'Shoes: 9' },
  { key: '9.5', label: 'Shoes: 9.5' },
  { key: '10', label: 'Shoes: 10' },
  { key: '10.5', label: 'Shoes: 10.5' },
  { key: '11', label: 'Shoes: 11' },
  { key: '11.5', label: 'Shoes: 11.5' },
  { key: '12', label: 'Shoes: 12' },
  { key: '12.5', label: 'Shoes: 12.5' },
  { key: '13', label: 'Shoes: 13' },
  { key: '13.5', label: 'Shoes: 13.5' },
  { key: 'K1', label: 'Shoes: K1' },
  { key: 'K1.5', label: 'Shoes: K1.5' },
  { key: 'K2', label: 'Shoes: K2' },
  { key: 'K2.5', label: 'Shoes: K2.5' },
  { key: 'K3', label: 'Shoes: K3' },
  { key: 'K3.5', label: 'Shoes: K3.5' },
  { key: 'K4', label: 'Shoes: K4' },
  { key: 'K4.5', label: 'Shoes: K4.5' },
  { key: 'K5', label: 'Shoes: K5' },
  { key: 'K5.5', label: 'Shoes: K5.5' },
  { key: 'K6', label: 'Shoes: K6' },
  { key: 'K6.5', label: 'Shoes: K6.5' },
  { key: 'K7', label: 'Shoes: K7' },
];

export const redoppleShopConfig = {
  ...getDefaultShopConfig(),
  filters: [
    ...custom.filters,
    getConditionsFilterConfig({ options: defaultClothesConditionsOptions }),
    getSizeFilterConfigFromOptions({
      options: doppleSizeOptions,
      shouldHideNoResultOptions: true,
    }),
    getColorFilterConfig(),
    getGenderFilterConfig(),
  ],
};
