/* eslint-disable import/prefer-default-export */
import { getConditionsFilterConfig } from '../filters/condition';
import { getBrandDirectFilterConfig } from '../filters/brandDirect';
import { getSizeFilterConfig } from '../filters/size';
import { getDefaultShopConfig } from './default';
import * as custom from '../filters/marketplace-custom-config';

const brooklynclothSizes: string[] = [
  'Small',
  'Medium',
  'Large',
  'X-Large',
  '2XL',
  '3XL',
  '4XL',
  '5XL',
  'One Size',
];

export const brooklynclothShopConfig = {
  ...getDefaultShopConfig(),
  filters: [
    ...custom.filters,
    getBrandDirectFilterConfig(),
    getConditionsFilterConfig(),
    getSizeFilterConfig({ sizes: brooklynclothSizes }),
  ],
};
