import { Dispatch } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { fetchStripeAccount } from '../../ducks/stripeConnectAccount.duck';
import { RequestStatus } from '../../types/requestStatus';
import { Listing, ListingItemType } from '../../types/sharetribe/listing';
import { Uuid } from '../../types/sharetribe/uuid';
import { fetchOwnListings as fetchOwnListingsApiRequest } from '../../util/api';
import { denormalisedEntities } from '../../util/data';
import * as log from '../../util/log';
import { LISTING_STATE_DRAFT, LISTING_STATE_PUBLISHED, LISTING_STATES } from '../../util/types';
import { parse } from '../../util/urlHelpers';

// ================ Action types ================ //

export const FETCH_LISTINGS_REQUEST = 'app/ManageClosetPage/FETCH_LISTINGS_REQUEST';
export const FETCH_LISTINGS_SUCCESS = 'app/ManageClosetPage/FETCH_LISTINGS_SUCCESS';
export const FETCH_LISTINGS_ERROR = 'app/ManageClosetPage/FETCH_LISTINGS_ERROR';

export const FETCH_DRAFT_LISTINGS_REQUEST = 'app/ManageClosetPage/FETCH_DRAFT_LISTINGS_REQUEST';
export const FETCH_DRAFT_LISTINGS_SUCCESS = 'app/ManageClosetPage/FETCH_DRAFT_LISTINGS_SUCCESS';
export const FETCH_DRAFT_LISTINGS_ERROR = 'app/ManageClosetPage/FETCH_DRAFT_LISTINGS_ERROR';

export const FETCH_TRADE_IN_LISTINGS_REQUEST =
  'app/ManageClosetPage/FETCH_TRADE_IN_LISTINGS_REQUEST';
export const FETCH_TRADE_IN_LISTINGS_SUCCESS =
  'app/ManageClosetPage/FETCH_TRADE_IN_LISTINGS_SUCCESS';
export const FETCH_TRADE_IN_LISTINGS_ERROR = 'app/ManageClosetPage/FETCH_TRADE_IN_LISTINGS_ERROR';

interface FetchListingsRequest {
  type: typeof FETCH_LISTINGS_REQUEST;
}
interface FetchListingsSuccess {
  type: typeof FETCH_LISTINGS_SUCCESS;
  payload: { data: any };
}
interface FetchListingsError {
  type: typeof FETCH_LISTINGS_ERROR;
  error: boolean;
  payload: any;
}

interface FetchDraftListingsRequest {
  type: typeof FETCH_DRAFT_LISTINGS_REQUEST;
}
interface FetchDraftListingsSuccess {
  type: typeof FETCH_DRAFT_LISTINGS_SUCCESS;
  payload: { data: any };
}
interface FetchDraftListingsError {
  type: typeof FETCH_DRAFT_LISTINGS_ERROR;
  error: boolean;
  payload: any;
}

interface FetchTradeInListingsRequest {
  type: typeof FETCH_TRADE_IN_LISTINGS_REQUEST;
}
interface FetchTradeInListingsSuccess {
  type: typeof FETCH_TRADE_IN_LISTINGS_SUCCESS;
  payload: { data: any };
}
interface FetchTradeInListingsError {
  type: typeof FETCH_TRADE_IN_LISTINGS_ERROR;
  error: boolean;
  payload: any;
}

type ManageClosetPageActionType =
  | FetchListingsRequest
  | FetchListingsSuccess
  | FetchListingsError
  | FetchDraftListingsRequest
  | FetchDraftListingsSuccess
  | FetchDraftListingsError
  | FetchTradeInListingsRequest
  | FetchTradeInListingsSuccess
  | FetchTradeInListingsError;

// ================ Reducer ================ //

export interface ManageClosetPageState {
  fetchListingsStatus: RequestStatus;
  fetchListingsError: any | null;
  listingResultIds: Uuid[];
  fetchDraftListingsStatus: RequestStatus;
  draftListingResultIds: Uuid[];
  fetchDraftListingsError: any | null;
  fetchTradeInListingsStatus: RequestStatus;
  tradeInListingResultIds: Uuid[];
  fetchTradeInListingsError: any | null;
}

const initialState = {
  fetchListingsStatus: RequestStatus.Ready,
  listingResultIds: [],
  fetchListingsError: null,
  fetchDraftListingsStatus: RequestStatus.Ready,
  draftListingResultIds: [],
  fetchDraftListingsError: null,
  fetchTradeInListingsStatus: RequestStatus.Ready,
  tradeInListingResultIds: [],
  fetchTradeInListingsError: null,
};

const resultIds = (data: any) => data.data.map((l: Listing) => l.id);

const manageClosetPageReducer = (
  state: ManageClosetPageState = initialState,
  action: ManageClosetPageActionType
) => {
  switch (action.type) {
    case FETCH_LISTINGS_REQUEST:
      return {
        ...state,
        fetchListingsStatus: RequestStatus.Pending,
        fetchListingsError: null,
        listingResultIds: [],
      };
    case FETCH_LISTINGS_SUCCESS:
      return {
        ...state,
        listingResultIds: resultIds(action.payload.data),
        fetchListingsStatus: RequestStatus.Success,
      };
    case FETCH_LISTINGS_ERROR:
      return {
        ...state,
        fetchListingsStatus: RequestStatus.Error,
        fetchListingsError: action.payload,
      };
    case FETCH_DRAFT_LISTINGS_REQUEST:
      return {
        ...state,
        fetchDraftListingsStatus: RequestStatus.Pending,
        fetchDraftListingsError: null,
        draftListingResultIds: [],
      };
    case FETCH_DRAFT_LISTINGS_SUCCESS:
      return {
        ...state,
        draftListingResultIds: resultIds(action.payload.data),
        fetchDraftListingsStatus: RequestStatus.Success,
      };
    case FETCH_DRAFT_LISTINGS_ERROR:
      return {
        ...state,
        fetchDraftListingsStatus: RequestStatus.Error,
        fetchDraftListingsError: action.payload,
      };
    case FETCH_TRADE_IN_LISTINGS_REQUEST:
      return {
        ...state,
        fetchTradeInListingsStatus: RequestStatus.Pending,
        fetchTradeInListingsError: null,
        tradeInListingResultIds: [],
      };
    case FETCH_TRADE_IN_LISTINGS_SUCCESS:
      return {
        ...state,
        tradeInListingResultIds: resultIds(action.payload.data),
        fetchTradeInListingsStatus: RequestStatus.Success,
      };
    case FETCH_TRADE_IN_LISTINGS_ERROR:
      return {
        ...state,
        fetchTradeInListingsStatus: RequestStatus.Error,
        fetchTradeInListingsError: action.payload,
      };
    default:
      return state;
  }
};

export default manageClosetPageReducer;

// ================ Selectors ================ //

/**
 * Get the denormalised own listing entities with the given IDs
 *
 * @param {Object} state the full Redux store
 * @param {Array<UUID>} listingIds listing IDs to select from the store
 */
export const getListingsFromMarketplaceData = (state: any, listingIds: Uuid[]) => {
  const { entities } = state.marketplaceData;
  const resources = listingIds.map((id) => ({
    id,
    type: 'listing',
  }));
  const throwIfNotFound = false;
  return denormalisedEntities(entities, resources, throwIfNotFound);
};

// ================ Action creators ================ //

export const fetchListingsRequest = () => ({
  type: FETCH_LISTINGS_REQUEST,
});

export const fetchListingsSuccess = (response: any) => ({
  type: FETCH_LISTINGS_SUCCESS,
  payload: { data: response.data },
});

export const fetchListingsError = (e: any) => ({
  type: FETCH_LISTINGS_ERROR,
  error: true,
  payload: e,
});

export const fetchDraftListingsRequest = () => ({
  type: FETCH_DRAFT_LISTINGS_REQUEST,
});

export const fetchDraftListingsSuccess = (response: any) => ({
  type: FETCH_DRAFT_LISTINGS_SUCCESS,
  payload: { data: response.data },
});

export const fetchDraftListingsError = (e: any) => ({
  type: FETCH_DRAFT_LISTINGS_ERROR,
  error: true,
  payload: e,
});

export const fetchTradeInListingsRequest = () => ({
  type: FETCH_TRADE_IN_LISTINGS_REQUEST,
});

export const fetchTradeInListingsSuccess = (response: any) => ({
  type: FETCH_TRADE_IN_LISTINGS_SUCCESS,
  payload: { data: response.data },
});

export const fetchTradeInListingsError = (e: any) => ({
  type: FETCH_TRADE_IN_LISTINGS_ERROR,
  error: true,
  payload: e,
});

// ================ Thunks ================ //

const listingParams = { include: ['images', 'privateData', 'currentStock'] };

// Calls backend API which allows for refining the ST own listings query by public data fields via
// the integration API. Otherwise Sharetribe's marketplace ownListings API does not allow for public
// data filtering.
const queryOwnListingsFromApi = (queryParams: any) => async (dispatch: Dispatch) => {
  dispatch(fetchListingsRequest());

  const { perPage, ...rest } = queryParams;
  const params = {
    ...rest,
    per_page: perPage,
  };

  try {
    const response = await fetchOwnListingsApiRequest({ params });
    dispatch(addMarketplaceEntities(response));
    dispatch(fetchListingsSuccess(response));
    return response;
  } catch (e) {
    dispatch(fetchListingsError(e));
    log.error(e, 'fetching-own-closet-listings-api-failed', { queryParams });
    return null;
  }
};

const queryOwnDraftListings = (queryParams: any) => async (dispatch: Dispatch) => {
  dispatch(fetchDraftListingsRequest());

  const { perPage, ...rest } = queryParams;
  const params = {
    ...rest,
    per_page: perPage,
  };

  try {
    const response = await fetchOwnListingsApiRequest({ params });
    dispatch(addMarketplaceEntities(response));
    dispatch(fetchDraftListingsSuccess(response));
    return response;
  } catch (e) {
    dispatch(fetchDraftListingsError(e));
    log.error(e, 'fetching-own-closet-draft-listings-api-failed', { queryParams });
    return null;
  }
};

const queryOwnTradeInListings = (queryParams: any) => async (dispatch: Dispatch) => {
  dispatch(fetchTradeInListingsRequest());

  const { perPage, ...rest } = queryParams;
  const params = {
    ...rest,
    per_page: perPage,
  };

  try {
    const response = await fetchOwnListingsApiRequest({ params });
    dispatch(addMarketplaceEntities(response));
    dispatch(fetchTradeInListingsSuccess(response));
    return response;
  } catch (e) {
    dispatch(fetchTradeInListingsError(e));
    log.error(e, 'fetching-own-closet-trade-in-listings-api-failed', { queryParams });
    return null;
  }
};

export const loadData =
  (params: any, search: string) =>
  async (dispatch: ThunkDispatch<any, any, any>, getState: () => any) => {
    const queryParams = parse(search);
    const page = queryParams.page || 1;

    const queryOwnListingsParams = {
      ...queryParams,
      ...listingParams,
      page,
      perPage: 100,
      'fields.image': ['variants.landscape-crop', 'variants.landscape-crop2x', 'variants.default'],
      'limit.images': 1,
    };
    return Promise.all([
      dispatch(
        queryOwnListingsFromApi({
          ...queryOwnListingsParams,
          pub_listingItemType: ListingItemType.Marketplace,
          states: LISTING_STATES.filter((listingState) => listingState !== LISTING_STATE_DRAFT),
        })
      ),
      dispatch(
        queryOwnDraftListings({
          ...queryOwnListingsParams,
          states: LISTING_STATE_DRAFT,
        })
      ),
      dispatch(
        queryOwnTradeInListings({
          ...queryOwnListingsParams,
          pub_listingItemType: ListingItemType.TradeIn,
          states: LISTING_STATE_PUBLISHED,
        })
      ),
    ]).then((response) => {
      const { currentUser } = getState().user;
      if (
        currentUser?.stripeAccount ||
        currentUser?.attributes.profile.protectedData?.stripeAccountId
      ) {
        dispatch(fetchStripeAccount());
      }
      return response;
    });
  };
