import { getDefaultShopConfig } from './default';
import * as custom from '../filters/marketplace-custom-config';
import { getBrandDirectFilterConfig } from '../filters/brandDirect';
import { getConditionsFilterConfig } from '../filters/condition';
import { getSizeFilterConfig } from '../filters/size';
import { getGenderFilterConfig } from '../filters/gender';

const musethreadsSizes = [
  'XS',
  'S',
  'M',
  'L',
  'XL',
  'XXL',
  '0-3',
  '3-6',
  '6-9',
  '6-12',
  '9-12',
  '12-18',
  '18-24',
  'XXS(12-24)',
  'XS(2/3)',
  'S(4/5)',
  'M(6/7)',
  'L(8/9)',
  'XL(10/11)',
];

export const musethreadsShopConfig = {
  ...getDefaultShopConfig(),
  filters: [
    ...custom.filters,
    getGenderFilterConfig(),
    getConditionsFilterConfig(),
    getBrandDirectFilterConfig(),
    getSizeFilterConfig({ sizes: musethreadsSizes }),
  ],
};
