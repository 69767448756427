import { isEmpty } from 'lodash';
import { IInternationalConfig } from '../types/contentful/types.generated';
import { Listing } from '../types/sharetribe/listing';
import { Currency } from '../types/apollo/generated/types.generated';

export interface ReturnShippingPrice {
  price: number;
  currency: Currency;
}

export const doesShopAllowBrandDirectReturns = (
  internationalConfig?: IInternationalConfig,
  returnShippingPrice?: ReturnShippingPrice
) => {
  // The UK and EU enforces a 14-day return policy on items sold from brands.
  // TODO: add isEU countries in the future as we enable more international sites return isUK
  if (!internationalConfig || !returnShippingPrice) return false;
  const isUKandHasReturnShippingPrice =
    !isEmpty(internationalConfig.allowedOriginToDestinationCountries?.GB) &&
    !isEmpty(returnShippingPrice);

  return isUKandHasReturnShippingPrice;
};

export const isListingReturnEligible = (
  isBrandDirect: boolean,
  internationalConfig?: IInternationalConfig,
  returnShippingPrice?: ReturnShippingPrice
) => doesShopAllowBrandDirectReturns(internationalConfig, returnShippingPrice) && isBrandDirect;

export const isBundleReturnEligible = (
  bundleListings: Listing[],
  internationalConfig?: IInternationalConfig,
  returnShippingPrice?: ReturnShippingPrice
) => {
  const areAllListingsReturnable =
    !isEmpty(bundleListings) &&
    bundleListings.every((listing) => listing?.attributes.publicData?.isReturnable === true);

  return (
    doesShopAllowBrandDirectReturns(internationalConfig, returnShippingPrice) &&
    areAllListingsReturnable
  );
};
