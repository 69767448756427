/**
 * This is a wrapper component for different Layouts. Footer content should be added to this wrapper.
 */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Box } from '@material-ui/core';
import { useShopConfig } from '../../hooks/shopConfig';

import css from './LayoutWrapperFooter.module.css';

const LayoutWrapperFooter = (props) => {
  const { className, rootClassName, children } = props;
  const { css: brandCss } = useShopConfig();

  const classes = classNames(rootClassName || css.root, className);

  return (
    <Box className={classes} bgcolor={brandCss?.backgroundColor}>
      {children}
    </Box>
  );
};

LayoutWrapperFooter.defaultProps = {
  className: null,
  rootClassName: null,
};

const { node, string } = PropTypes;

LayoutWrapperFooter.propTypes = {
  children: node.isRequired,
  className: string,
  rootClassName: string,
};

export default LayoutWrapperFooter;
