import { getDefaultShopConfig } from './default';
import * as custom from '../filters/marketplace-custom-config';
import { getBrandDirectFilterConfig } from '../filters/brandDirect';
import { getConditionsFilterConfig } from '../filters/condition';
import { getSizeFilterConfigFromOptions } from '../filters/size';

const jamesstreetcoSizeOptions = [
  { key: 'X-Small', label: 'X-Small' },
  { key: 'Small', label: 'Small' },
  { key: 'Medium', label: 'Medium' },
  { key: 'Large', label: 'Large' },
  { key: 'X-Large', label: 'X-Large' },
  { key: 'one-size', label: 'One Size' },
  { key: '0', label: '0' },
  { key: '2', label: '2' },
  { key: '4', label: '4' },
  { key: '6', label: '6' },
  { key: '8', label: '8' },
  { key: '10', label: '10' },
  { key: '12', label: '12' },
  { key: '14', label: '14' },
  { key: '16', label: '16' },
  { key: '24W', label: '24W' },
  { key: '25W', label: '25W' },
  { key: '26W', label: '26W' },
  { key: '27W', label: '27W' },
  { key: '28W', label: '28W' },
  { key: '29W', label: '29W' },
  { key: '30W', label: '30W' },
  { key: '31W', label: '31W' },
  { key: '32W', label: '32W' },
  { key: '33W', label: '33W' },
  { key: '34W', label: '34W' },
  { key: '35W', label: '35W' },
  { key: '36W', label: '36W' },
];

export const jamesstreetcoShopConfig = {
  ...getDefaultShopConfig(),
  filters: [
    ...custom.filters,
    getBrandDirectFilterConfig(),
    getSizeFilterConfigFromOptions({ options: jamesstreetcoSizeOptions }),
    getConditionsFilterConfig(),
  ],
};
