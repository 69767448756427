import React from 'react';
import { useIconColor } from '../../../hooks/useIconColor';

interface IconRulerProps {
  className?: string;
  color?: string;
}

const IconRuler: React.FC<IconRulerProps> = (props) => {
  const { className, color } = props;

  const { iconColor } = useIconColor();
  const fillColor = color || iconColor;

  return (
    <svg
      className={className}
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 0H3V12H9V0ZM4.5 10.5V1.5H7.5V2.25H6V3.75H7.5V5.25H5.25V6.75H7.5V8.25H6V9.75H7.5V10.5H4.5Z"
        fill={fillColor}
      />
    </svg>
  );
};

export default IconRuler;
