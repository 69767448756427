import { BundleType, ListingUpdateType } from '@prisma/client';
import omit from 'lodash/omit';
import { Dispatch } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { fetchStripeAccount } from '../../ducks/stripeConnectAccount.duck';
import { fetchCurrentUser } from '../../ducks/user.duck';
import { getEnabledCustomerExperiences, getShopConfig } from '../../shopConfig/configHelper';
import { StorableError } from '../../types/error';
import { RequestStatus } from '../../types/requestStatus';
import { Listing, ListingItemType } from '../../types/sharetribe/listing';
import { Pagination } from '../../types/sharetribe/pagination';
import { Uuid } from '../../types/sharetribe/uuid';
import { getSharetribeAddressFromShippoAddress } from '../../util/address';
import {
  createTradeInOrder,
  fetchOwnListings as fetchOwnListingsApiRequest,
  setInventory,
  updateListing as updateListingApiRequest,
} from '../../util/api';
import { ITEM_AVAILABILITY_AVAILABLE, ITEM_AVAILABILITY_PURCHASED } from '../../util/constants';
import { denormalisedEntities, updatedEntities } from '../../util/data';
import { storableError } from '../../util/errors';
import { handle } from '../../util/helpers';
import * as log from '../../util/log';
import { LISTING_STATE_DRAFT, LISTING_STATES } from '../../util/types';
import { parse } from '../../util/urlHelpers';

// ================ Action types ================ //

export const FETCH_LISTINGS_REQUEST = 'app/ManageTradeInsPage/FETCH_LISTINGS_REQUEST';
export const FETCH_LISTINGS_SUCCESS = 'app/ManageTradeInsPage/FETCH_LISTINGS_SUCCESS';
export const FETCH_LISTINGS_ERROR = 'app/ManageTradeInsPage/FETCH_LISTINGS_ERROR';

export const OPEN_LISTING_REQUEST = 'app/ManageTradeInsPage/OPEN_LISTING_REQUEST';
export const OPEN_LISTING_SUCCESS = 'app/ManageTradeInsPage/OPEN_LISTING_SUCCESS';
export const OPEN_LISTING_ERROR = 'app/ManageTradeInsPage/OPEN_LISTING_ERROR';

export const CLOSE_LISTING_REQUEST = 'app/ManageTradeInsPage/CLOSE_LISTING_REQUEST';
export const CLOSE_LISTING_SUCCESS = 'app/ManageTradeInsPage/CLOSE_LISTING_SUCCESS';
export const CLOSE_LISTING_ERROR = 'app/ManageTradeInsPage/CLOSE_LISTING_ERROR';

export const RELIST_LISTING_REQUEST = 'app/ManageTradeInsPage/RELIST_LISTING_REQUEST';
export const RELIST_LISTING_SUCCESS = 'app/ManageTradeInsPage/RELIST_LISTING_SUCCESS';
export const RELIST_LISTING_ERROR = 'app/ManageTradeInsPage/RELIST_LISTING_ERROR';

export const DELETE_DRAFT_REQUEST = 'app/ManageTradeInsPage/DELETE_DRAFT_REQUEST';
export const DELETE_DRAFT_SUCCESS = 'app/ManageTradeInsPage/DELETE_DRAFT_SUCCESS';
export const DELETE_DRAFT_ERROR = 'app/ManageTradeInsPage/DELETE_DRAFT_ERROR';

export const DELETE_LISTING_REQUEST = 'app/ManageTradeInsPage/DELETE_LISTING_REQUEST';
export const DELETE_LISTING_SUCCESS = 'app/ManageTradeInsPage/DELETE_LISTING_SUCCESS';
export const DELETE_LISTING_ERROR = 'app/ManageTradeInsPage/DELETE_LISTING_ERROR';

export const SUBMIT_TRADE_IN_REQUEST = 'app/ManageTradeInsPage/SUBMIT_TRADE_IN_REQUEST';
export const SUBMIT_TRADE_IN_SUCCESS = 'app/ManageTradeInsPage/SUBMIT_TRADE_IN_SUCCESS';
export const SUBMIT_TRADE_IN_ERROR = 'app/ManageTradeInsPage/SUBMIT_TRADE_IN_ERROR';

export const ADD_OWN_ENTITIES = 'app/ManageTradeInsPage/ADD_OWN_ENTITIES';

interface FetchListingsRequest {
  type: typeof FETCH_LISTINGS_REQUEST;
  payload: { queryParams: any };
}
interface FetchListingsSuccess {
  type: typeof FETCH_LISTINGS_SUCCESS;
  payload: { data: any };
}
interface FetchListingsError {
  type: typeof FETCH_LISTINGS_ERROR;
  error: boolean;
  payload: any;
}

interface OpenListingRequest {
  type: typeof OPEN_LISTING_REQUEST;
  payload: { listingId: Uuid };
}
interface OpenListingSuccess {
  type: typeof OPEN_LISTING_SUCCESS;
  payload: any;
}
interface OpenListingError {
  type: typeof OPEN_LISTING_ERROR;
  error: boolean;
  payload: any;
}

interface CloseListingRequest {
  type: typeof CLOSE_LISTING_REQUEST;
  payload: { listingId: Uuid };
}
interface CloseListingSuccess {
  type: typeof CLOSE_LISTING_SUCCESS;
  payload: any;
}
interface CloseListingError {
  type: typeof CLOSE_LISTING_ERROR;
  error: boolean;
  payload: any;
}

interface RelistListingRequest {
  type: typeof RELIST_LISTING_REQUEST;
  relistListingId: Uuid;
}
interface RelistListingSuccess {
  type: typeof RELIST_LISTING_SUCCESS;
  payload: any;
}
interface RelistListingError {
  type: typeof RELIST_LISTING_ERROR;
  error: StorableError;
}

interface DeleteDraftRequest {
  type: typeof DELETE_DRAFT_REQUEST;
  payload: { listingId: Uuid };
}
interface DeleteDraftSuccess {
  type: typeof DELETE_DRAFT_SUCCESS;
  payload: { listingId: Uuid };
}
interface DeleteDraftError {
  type: typeof DELETE_DRAFT_ERROR;
  error: boolean;
  payload: any;
}

interface SubmitTradeInRequest {
  type: typeof SUBMIT_TRADE_IN_REQUEST;
}
interface SubmitTradeInSuccess {
  type: typeof SUBMIT_TRADE_IN_SUCCESS;
}
interface SubmitTradeInError {
  type: typeof SUBMIT_TRADE_IN_ERROR;
  error: boolean;
  payload: any;
}

interface DeleteListingRequest {
  type: typeof DELETE_LISTING_REQUEST;
  payload: { listingId: Uuid };
}
interface DeleteListingSuccess {
  type: typeof DELETE_LISTING_SUCCESS;
  payload: { listingId: Uuid };
}
interface DeleteListingError {
  type: typeof DELETE_LISTING_ERROR;
  error: boolean;
  payload: any;
}

interface AddOwnEntities {
  type: typeof ADD_OWN_ENTITIES;
  payload: any;
}

type ManageTradeInsPageActionType =
  | FetchListingsRequest
  | FetchListingsSuccess
  | FetchListingsError
  | OpenListingRequest
  | OpenListingSuccess
  | OpenListingError
  | CloseListingRequest
  | CloseListingSuccess
  | CloseListingError
  | RelistListingRequest
  | RelistListingSuccess
  | RelistListingError
  | DeleteDraftRequest
  | DeleteDraftSuccess
  | DeleteDraftError
  | SubmitTradeInRequest
  | SubmitTradeInSuccess
  | SubmitTradeInError
  | DeleteListingRequest
  | DeleteListingSuccess
  | DeleteListingError
  | AddOwnEntities;

// ================ Reducer ================ //

export interface ManageTradeInsPageState {
  pagination: Pagination | null;
  queryParams: any | null;
  fetchListingsStatus: RequestStatus;
  fetchListingsError: any | null;
  currentPageResultIds: Uuid[];
  ownEntities: any | null;
  openingListing: Uuid | null;
  openingListingError: any | null;
  closingListing: Uuid | null;
  closingListingError: any | null;
  relistListing: Uuid | null;
  relistListingError: StorableError | null;
  relistListingId: Uuid | null;
  deleteDraftId: Uuid | null;
  deleteDraftError: any | null;
  submitTradeInStatus: RequestStatus;
  submitTradeInError: any | null;
  deleteListingId: Uuid | null;
  deleteListingError: any | null;
}

const initialState = {
  pagination: null,
  queryParams: null,
  fetchListingsStatus: RequestStatus.Ready,
  fetchListingsError: null,
  currentPageResultIds: [],
  ownEntities: {},
  openingListing: null,
  openingListingError: null,
  closingListing: null,
  closingListingError: null,
  relistListing: null,
  relistListingError: null,
  relistListingId: null,
  deleteDraftId: null,
  deleteDraftError: null,
  submitTradeInStatus: RequestStatus.Ready,
  submitTradeInError: null,
  deleteListingId: null,
  deleteListingError: null,
};

const resultIds = (data: any) => data.data.map((l: Listing) => l.id);

const merge = (state: any, sdkResponse: any) => {
  const apiResponse = sdkResponse.data;
  return {
    ...state,
    ownEntities: updatedEntities({ ...state.ownEntities }, apiResponse),
  };
};

const updateListingAttributes = (state: any, listingEntity: Listing) => {
  const oldListing = state.ownEntities.listing[listingEntity.id.uuid];
  const updatedListing = { ...oldListing, attributes: listingEntity.attributes };
  const listingEntities = {
    ...state.ownEntities.listing,
    [listingEntity.id.uuid]: updatedListing,
  };
  return {
    ...state,
    ownEntities: { ...state.ownEntities, listing: listingEntities },
  };
};

const manageTradeInsPageReducer = (
  state: ManageTradeInsPageState = initialState,
  action: ManageTradeInsPageActionType
) => {
  switch (action.type) {
    case FETCH_LISTINGS_REQUEST:
      return {
        ...state,
        queryParams: action.payload.queryParams,
        fetchListingsStatus: RequestStatus.Pending,
        fetchListingsError: null,
        currentPageResultIds: [],
      };
    case FETCH_LISTINGS_SUCCESS:
      return {
        ...state,
        currentPageResultIds: resultIds(action.payload.data),
        pagination: action.payload.data.meta,
        fetchListingsStatus: RequestStatus.Success,
      };
    case FETCH_LISTINGS_ERROR:
      // eslint-disable-next-line no-console
      console.error(action.payload);
      return {
        ...state,
        fetchListingsStatus: RequestStatus.Error,
        fetchListingsError: action.payload,
      };
    case OPEN_LISTING_REQUEST:
      return {
        ...state,
        openingListing: action.payload.listingId,
        openingListingError: null,
      };
    case OPEN_LISTING_SUCCESS:
      return {
        ...updateListingAttributes(state, action.payload.data),
        openingListing: null,
      };
    case OPEN_LISTING_ERROR: {
      // eslint-disable-next-line no-console
      console.error(action.payload);
      return {
        ...state,
        openingListing: null,
        openingListingError: {
          listingId: state.openingListing,
          error: action.payload,
        },
      };
    }
    case CLOSE_LISTING_REQUEST:
      return {
        ...state,
        closingListing: action.payload.listingId,
        closingListingError: null,
      };
    case CLOSE_LISTING_SUCCESS:
      return {
        ...updateListingAttributes(state, action.payload.data),
        closingListing: null,
      };
    case CLOSE_LISTING_ERROR: {
      // eslint-disable-next-line no-console
      console.error(action.payload);
      return {
        ...state,
        closingListing: null,
        closingListingError: {
          listingId: state.closingListing,
          error: action.payload,
        },
      };
    }
    case RELIST_LISTING_REQUEST:
      return {
        ...state,
        relistListingId: action.relistListingId,
        relistListingError: null,
      };
    case RELIST_LISTING_SUCCESS:
      return {
        ...updateListingAttributes(state, action.payload.data),
        relistListingId: null,
      };
    case RELIST_LISTING_ERROR: {
      return {
        ...state,
        relistListingId: null,
        relistListingError: action.error,
      };
    }
    case DELETE_DRAFT_REQUEST:
      return {
        ...state,
        deleteDraftId: action.payload.listingId,
        deleteDraftError: null,
      };
    case DELETE_DRAFT_SUCCESS: {
      const listingEntities = omit(state.ownEntities.listing, [action.payload.listingId.uuid]);
      const totalItems = state.pagination?.totalItems;
      return {
        ...state,
        deleteDraftId: null,
        ownEntities: {
          ...state.ownEntities,
          listing: listingEntities,
        },
        pagination: {
          ...state.pagination,
          totalItems: totalItems ? totalItems - 1 : 0,
        },
      };
    }
    case DELETE_DRAFT_ERROR:
      return {
        ...state,
        deleteDraftId: null,
        deleteDraftError: action.payload,
      };
    case SUBMIT_TRADE_IN_REQUEST:
      return {
        ...state,
        submitTradeInStatus: RequestStatus.Pending,
        submitTradeInError: null,
      };
    case SUBMIT_TRADE_IN_SUCCESS:
      return {
        ...state,
        submitTradeInStatus: RequestStatus.Success,
      };
    case SUBMIT_TRADE_IN_ERROR:
      return {
        ...state,
        submitTradeInStatus: RequestStatus.Error,
        submitTradeInError: action.payload,
      };
    case DELETE_LISTING_REQUEST:
      return {
        ...state,
        deleteListingId: action.payload.listingId,
        deleteListingError: null,
      };
    case DELETE_LISTING_SUCCESS: {
      const listingEntities = omit(state.ownEntities.listing, [action.payload.listingId.uuid]);
      const totalItems = state.pagination?.totalItems;
      return {
        ...state,
        deleteListingId: null,
        ownEntities: {
          ...state.ownEntities,
          listing: listingEntities,
        },
        pagination: {
          ...state.pagination,
          totalItems: totalItems ? totalItems - 1 : 0,
        },
      };
    }
    case DELETE_LISTING_ERROR:
      return {
        ...state,
        deleteListingId: null,
        deleteListingError: action.payload,
      };
    case ADD_OWN_ENTITIES:
      return merge(state, action.payload);
    default:
      return state;
  }
};

export default manageTradeInsPageReducer;

// ================ Selectors ================ //

/**
 * Get the denormalised own listing entities with the given IDs
 *
 * @param {Object} state the full Redux store
 * @param {Array<UUID>} listingIds listing IDs to select from the store
 */
export const getOwnListingsById = (state: any, listingIds: Uuid[]) => {
  const { ownEntities } = state.ManageTradeInsPage;
  const resources = listingIds.map((id) => ({
    id,
    type: 'listing',
  }));
  const throwIfNotFound = false;
  return denormalisedEntities(ownEntities, resources, throwIfNotFound);
};

// ================ Action creators ================ //

// This works the same way as addMarketplaceEntities,
// but we don't want to mix own listings with searched listings
// (own listings data contains different info - e.g. exact location etc.)
export const addOwnEntities = (sdkResponse: any) => ({
  type: ADD_OWN_ENTITIES,
  payload: sdkResponse,
});

export const openListingRequest = (listingId: Uuid) => ({
  type: OPEN_LISTING_REQUEST,
  payload: { listingId },
});

export const openListingSuccess = (response: any) => ({
  type: OPEN_LISTING_SUCCESS,
  payload: response.data,
});

export const openListingError = (e: any) => ({
  type: OPEN_LISTING_ERROR,
  error: true,
  payload: e,
});

export const closeListingRequest = (listingId: Uuid) => ({
  type: CLOSE_LISTING_REQUEST,
  payload: { listingId },
});

export const closeListingSuccess = (response: any) => ({
  type: CLOSE_LISTING_SUCCESS,
  payload: response.data,
});

export const closeListingError = (e: any) => ({
  type: CLOSE_LISTING_ERROR,
  error: true,
  payload: e,
});

export const relistListingRequest = (listingId: Uuid) => ({
  type: RELIST_LISTING_REQUEST,
  relistListingId: listingId,
});

export const relistListingSuccess = (response: any) => ({
  type: RELIST_LISTING_SUCCESS,
  payload: response.data,
});

export const relistListingError = (e: StorableError) => ({
  type: RELIST_LISTING_ERROR,
  error: e,
});

export const deleteDraftRequest = (listingId: Uuid) => ({
  type: DELETE_DRAFT_REQUEST,
  payload: { listingId },
});

export const deleteDraftSuccess = (listingId: Uuid) => ({
  type: DELETE_DRAFT_SUCCESS,
  payload: { listingId },
});

export const deleteDraftError = (e: any) => ({
  type: DELETE_DRAFT_ERROR,
  error: true,
  payload: e,
});

export const fetchListingsRequest = (queryParams: any) => ({
  type: FETCH_LISTINGS_REQUEST,
  payload: { queryParams },
});

export const fetchListingsSuccess = (response: any) => ({
  type: FETCH_LISTINGS_SUCCESS,
  payload: { data: response.data },
});

export const fetchListingsError = (e: any) => ({
  type: FETCH_LISTINGS_ERROR,
  error: true,
  payload: e,
});

export const submitTradeInRequest = () => ({
  type: SUBMIT_TRADE_IN_REQUEST,
});

export const submitTradeInSuccess = () => ({
  type: SUBMIT_TRADE_IN_SUCCESS,
});

export const submitTradeInError = (e: any) => ({
  type: SUBMIT_TRADE_IN_ERROR,
  error: true,
  payload: e,
});

export const deleteListingRequest = (listingId: Uuid) => ({
  type: DELETE_LISTING_REQUEST,
  payload: { listingId },
});

export const deleteListingSuccess = (listingId: Uuid) => ({
  type: DELETE_LISTING_SUCCESS,
  payload: { listingId },
});

export const deleteListingError = (e: any) => ({
  type: DELETE_LISTING_ERROR,
  error: true,
  payload: e,
});

// ================ Thunks ================ //

const listingParams = { include: ['images', 'privateData', 'currentStock'] };

// Calls backend API which allows for refining the ST own listings query by public data fields via
// the integration API. Otherwise Sharetribe's marketplace ownListings API does not allow for public
// data filtering.
export const queryOwnListingsFromApi = (queryParams: any) => async (dispatch: Dispatch) => {
  dispatch(fetchListingsRequest(queryParams));

  const { perPage, ...rest } = queryParams;
  const params = {
    ...rest,
    per_page: perPage,
  };

  try {
    const response = await fetchOwnListingsApiRequest({ params });
    dispatch(addOwnEntities(response));
    dispatch(addMarketplaceEntities(response));
    dispatch(fetchListingsSuccess(response));
    return response;
  } catch (e) {
    dispatch(fetchListingsError(e));
    log.error(e, 'fetching-own-trade-in-listings-api-failed', { queryParams });
    return null;
  }
};

export const closeListing = (listingId: Uuid) => async (dispatch: Dispatch) => {
  dispatch(closeListingRequest(listingId));

  try {
    const response = await updateListingApiRequest(listingId.uuid, {
      action: ListingUpdateType.CLOSE,
    });
    await dispatch(closeListingSuccess(response));
    return response;
  } catch (e) {
    return dispatch(closeListingError(storableError(e)));
  }
};

export const deleteDraft =
  (listingId: Uuid) => (dispatch: Dispatch, getState: () => any, sdk: any) => {
    dispatch(deleteDraftRequest(listingId));

    return sdk.ownListings
      .discardDraft({ id: listingId }, { expand: true })
      .then((response: any) => {
        dispatch(deleteDraftSuccess(listingId));
        return response;
      })
      .catch((e: any) => {
        dispatch(deleteDraftError(storableError(e)));
        throw e;
      });
  };

export const deleteListing =
  (listingId: Uuid, title: string, isPublished: boolean) => async (dispatch: Dispatch) => {
    dispatch(deleteListingRequest(listingId));

    try {
      if (isPublished) {
        // Cannot close pending approval or already closed listings. Will just update the public data.
        await updateListingApiRequest(listingId.uuid, { action: ListingUpdateType.CLOSE });
      }
      await updateListingApiRequest(listingId.uuid, {
        deletedAt: new Date(),
        stOnlyPublicData: { deleted: true },
      });
      dispatch(deleteListingSuccess(listingId));
    } catch (e) {
      log.error(e, 'delete-listings-failed', { listingId, e });
      dispatch(deleteListingError(storableError(e)));
      throw e;
    }
  };

export const openListing = (listingId: Uuid) => async (dispatch: Dispatch) => {
  dispatch(openListingRequest(listingId));

  try {
    const openListingResponse = await updateListingApiRequest(listingId.uuid, {
      action: ListingUpdateType.OPEN,
    });
    // forceUpdate the inventory total to 1
    await setInventory({
      listingId: listingId.uuid,
      oldTotal: 0,
      newTotal: 1,
      forceUpdate: true,
    });
    dispatch(openListingSuccess(openListingResponse));
    return openListingResponse;
  } catch (e) {
    dispatch(openListingError(storableError(e)));
    return null;
  }
};

export const relistListing = (listingId: Uuid) => async (dispatch: Dispatch) => {
  dispatch(relistListingRequest(listingId));

  const [reopenListingResponse, reopenListingError] = await handle(
    updateListingApiRequest(listingId.uuid, { action: ListingUpdateType.OPEN })
  );
  if (reopenListingError) {
    log.error(reopenListingError, 'relist-trade-in-open-error', { listingId });
    dispatch(relistListingError(storableError(reopenListingError)));
    return null;
  }

  const [, updateListingError] = await handle(
    updateListingApiRequest(listingId.uuid, {
      stOnlyPublicData: { availability: ITEM_AVAILABILITY_AVAILABLE },
    })
  );
  if (updateListingError) {
    log.error(updateListingError, 'relist-trade-in-update-error', { listingId });
    updateListingApiRequest(listingId, { action: ListingUpdateType.CLOSE });
    dispatch(relistListingError(storableError(updateListingError)));
    return null;
  }

  const [, updateStockError] = await handle(
    setInventory({ listingId: listingId.uuid, oldTotal: 0, newTotal: 1, forceUpdate: true })
  );
  if (updateStockError) {
    updateListingApiRequest(listingId.uuid, {
      action: ListingUpdateType.CLOSE,
      stOnlyPublicData: {
        availability: ITEM_AVAILABILITY_PURCHASED,
      },
    });
    dispatch(relistListingError(storableError(updateStockError)));
    return null;
  }

  dispatch(relistListingSuccess(reopenListingResponse));

  return reopenListingResponse;
};

export const submitTradeIn =
  (listingIds: string[], tradeInConfig: any) => async (dispatch: Dispatch, getState: () => any) => {
    dispatch(submitTradeInRequest());
    const { currentUser } = getState().user;
    const { treetId } = getState().initial;
    const tradeInBuyerVar = `REACT_APP_${String(treetId)
      .replace(/-/g, '_')
      .toUpperCase()}_TRADE_IN_BUYER`;
    // Use default trade in buyer for demos
    const tradeInBuyer =
      process.env[tradeInBuyerVar] || process.env.REACT_APP_DEFAULT_TRADE_IN_BUYER;

    const tradeInShipToAddress = tradeInConfig?.tradeInShipToAddress;

    if (!(tradeInShipToAddress && tradeInBuyer)) {
      const missingConfigError = new Error('Missing trade in configs for shop');
      log.error(missingConfigError, 'missing-trade-in-configs', {
        tradeInShipToAddress,
        tradeInBuyerVar,
        treetId,
      });
      return dispatch(submitTradeInError(missingConfigError));
    }

    const [tradeInOrder, tradeInOrderError] = await handle(
      createTradeInOrder({
        shippingAddress: tradeInShipToAddress,
        buyerId: tradeInBuyer,
        authorIdToListingIds: { [currentUser.id.uuid]: listingIds },
        listingIds,
        orderData: {
          shipToAddress: getSharetribeAddressFromShippoAddress(tradeInShipToAddress),
        },
        type: BundleType.TRADE_IN,
      })
    );

    if (tradeInOrderError) {
      log.error(tradeInOrderError, 'submit-trade-in-order-models-failed', {
        listingIds,
      });
      return dispatch(submitTradeInError(tradeInOrderError));
    }

    dispatch(submitTradeInSuccess());
    return tradeInOrder;
  };

export const loadData =
  (params: any, search: string) =>
  async (dispatch: ThunkDispatch<any, any, any>, getState: () => any) => {
    const { treetId, shopConfig: shopConfigV2 } = getState().initial;
    const { enabledCustomerExperiences } = getShopConfig(treetId, shopConfigV2);
    const { allowTradeInAndMarketplace } = getEnabledCustomerExperiences(
      enabledCustomerExperiences
    );

    const queryParams = parse(search);
    const page = queryParams.page || 1;

    const queryOwnListingsParams = {
      ...queryParams,
      ...listingParams,
      page,
      perPage: 100,
      'fields.image': ['variants.landscape-crop', 'variants.landscape-crop2x', 'variants.default'],
      'limit.images': 1,
    };
    return Promise.all([
      dispatch(fetchCurrentUser()),
      dispatch(
        queryOwnListingsFromApi({
          ...queryOwnListingsParams,
          // Combined sites show drafts separately
          ...(allowTradeInAndMarketplace && {
            states: LISTING_STATES.filter((listingState) => listingState !== LISTING_STATE_DRAFT),
          }),
          pub_listingItemType: ListingItemType.TradeIn,
        })
      ),
    ]).then((response) => {
      const { currentUser } = getState().user;
      if (
        currentUser?.stripeAccount ||
        currentUser?.attributes.profile.protectedData?.stripeAccountId
      ) {
        dispatch(fetchStripeAccount());
      }
      return response;
    });
  };
